/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { PageTitle } from '../../../../../_layout/core'
import { getAllBannerDatatable } from './BannerAction'
import { Suspense, lazy } from 'react'
import CustomLoading from '../../../../../_component/customLoading'
import { useOutletContext } from 'react-router-dom'
import { BaseDatatableResponse } from '../../../../../_data/dataSource/response/BaseDatatableResponse'
import { Banner } from '../../../../../_domain/model/Banner'
import BannerDelete from './BannerDelete'
import { useAuth } from '../../../../../_context/AuthContext'
import { checkAuthActionMenu } from '../../../../../helpers/AuthHelpers'
import { SLUG_TRANSACTION_SUB_MENU } from '../../../../../helpers/UIAuthGroupConstanta'

const BannerListTable = React.lazy(() => import('./BannerListComponent/BannerListTable'))
const BannerCreate = React.lazy(() => import('./BannerCreate'))
const BannerEdit = React.lazy(() => import('./BannerEdit'))

const checkReduxData = () => {
  const outletContext = useOutletContext<any>().queryParams
  const { queryParams } = outletContext

  const [bannerListData, setBannerListData] = useState<any>(null)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch<any>(getAllBannerDatatable(queryParams)).then((response: BaseDatatableResponse<Banner>) => {
      setBannerListData(response.entities)
    })
  }, [queryParams, getAllBannerDatatable])

  return bannerListData
  // detect redux first before fetch API END
}

const BannerList = () => {
  let data = checkReduxData()
  const { currentUser, authorization } = useAuth()
  return checkAuthActionMenu(SLUG_TRANSACTION_SUB_MENU.TRANSACTION_BANNER_MENU, "view", currentUser, authorization) ? (
    <>
      <BannerDelete />
      <BannerCreate />
      <BannerEdit />
      <PageTitle breadcrumbs={[]}>{'Banner List'}</PageTitle>
      <Suspense fallback={<CustomLoading />}>
        {data ? <BannerListTable /> : null}
      </Suspense>
    </>
  ) : null
}

export { BannerList }
