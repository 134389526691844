const STUDENT_DELETE_TITLE = 'Student Delete'
const STUDENT_DELETE_LOADING = 'Student is deleting...'
const STUDENT_DELETE_CONFIRM = 'Are you sure to permanently delete this student ?'
const STUDENT_DELETE_SUCCESS = 'Successful delete student'
const STUDENT_DELETE_FAILED = "Can't delete student"
const STUDENT_CREATE_TITLE = 'Student Create'
const STUDENT_CREATE_FAILED = "Can't create student"
const STUDENT_CREATE_SUCCESS = 'Successful create student'
const STUDENT_EDIT_TITLE = 'Student Edit'
const STUDENT_EDIT_SUCCESS = 'Successful update student'
const STUDENT_EDIT_FAILED = "Can't update student"
const STUDENT_GET_FAILED = "Can't get student"
const STUDENT_ROLE_GET_FAILED = "Can't get student role"
const STUDENT_PRODI_GET_FAILED = "Can't get student prodi"
const STUDENT_LIST_TITLE = 'Student List'
const STUDENT_RESET_TITLE = 'Student Reset Password'
const STUDENT_RESET_LOADING = 'Student is resetting password...'
const STUDENT_RESET_CONFIRM = 'Are you sure to permanently reset password this student ?'
const STUDENT_RESET_SUCCESS = 'Successful reset password student'
const STUDENT_RESET_FAILED = "Can't reset password student"
const STUDENT_DETAIL_TITLE = 'Student Detail'
export {
  STUDENT_DELETE_TITLE,
  STUDENT_DELETE_CONFIRM,
  STUDENT_DELETE_LOADING,
  STUDENT_DELETE_SUCCESS,
  STUDENT_DELETE_FAILED,
  STUDENT_CREATE_TITLE,
  STUDENT_CREATE_SUCCESS,
  STUDENT_CREATE_FAILED,
  STUDENT_EDIT_TITLE,
  STUDENT_EDIT_SUCCESS,
  STUDENT_EDIT_FAILED,
  STUDENT_GET_FAILED,
  STUDENT_LIST_TITLE,
  STUDENT_DETAIL_TITLE,
  STUDENT_ROLE_GET_FAILED,
  STUDENT_RESET_TITLE,
  STUDENT_RESET_LOADING,
  STUDENT_RESET_CONFIRM,
  STUDENT_RESET_SUCCESS,
  STUDENT_RESET_FAILED,
  STUDENT_PRODI_GET_FAILED
}
