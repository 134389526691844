import { useCallback, useState } from "react"
import { BaseDatatableRequest, defaultQueryParams, prepareFilterBase } from "../_data/dataSource/request/BaseDatatableRequest"
import { isEqual, isFunction } from "lodash"

const init = defaultQueryParams()
type Props = {
  initQueryParams?: BaseDatatableRequest
}
const useFilterTable = (props?: Props) => {
  const initQueryParams = props?.initQueryParams
  const [queryParams, setQueryParamsBase] = useState(initQueryParams ? initQueryParams : init)

  const setQueryParams = useCallback((nextQueryParams: any) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  // queryParams, setQueryParams,
  const applyFilter = (
    values: object
  ) => {
    const newQueryParams = prepareFilterBase(queryParams, values)

    if (!isEqual(newQueryParams, queryParams)) {
      newQueryParams.pageNumber = 1
      // update list by queryParams
      setQueryParams(newQueryParams)
    }
  }

  const handlePageChange = (page: number, perPage: number) => {
    const tmp = { ...queryParams }
    tmp.pageNumber = page
    setQueryParams(tmp)
  }

  const handleRowPerPageChange = (currentRowsPerPage: number, currentPage: number) => {
    const tmp = { ...queryParams }
    tmp.pageSize = currentRowsPerPage
    setQueryParams(tmp)
  }

  const handleSortChange = (column: any, sortDirection: any) => {
    const tmp = { ...queryParams }
    tmp.sortOrder = sortDirection
    tmp.sortField = column.id
    setQueryParams(tmp)
  }

  return {
    queryParams,
    setQueryParams,
    applyFilter,
    handlePageChange,
    handleSortChange,
    handleRowPerPageChange
  }
}

export default useFilterTable