import ResultTryoutDataSourceImpl from '../../../../../_data/dataSource/ResultTryoutDataSourceImpl'
import { BaseDatatableRequest } from '../../../../../_data/dataSource/request/BaseDatatableRequest'
import { RESULT_TRYOUT_CALCULATE_FAILED, RESULT_TRYOUT_CALCULATE_SUCCESS, RESULT_TRYOUT_DELETE_FAILED, RESULT_TRYOUT_DELETE_SUCCESS, RESULT_TRYOUT_GET_FAILED } from '../../../../../_domain/constanta'
import { ResultTryoutRepositoryImpl } from '../../../../../_domain/repository/ResultTryoutRepositoryImpl'
import { ResultTryoutServiceImpl } from '../../../../../_domain/service/ResultTryoutService'
import { result } from '../../../../../_redux_slice'
import { alertError, alertSuccess, errorHandling } from '../../../../../helpers/FuncHelpers'
import { AlertPromise } from '../../../../../helpers/classHelpers'

const { actions } = result.slice
// const controlAction = control.slice.actions
const callTypes = result.callTypes
const dataSource = new ResultTryoutDataSourceImpl()
const repository = new ResultTryoutRepositoryImpl(dataSource)
const service = new ResultTryoutServiceImpl(repository)

const alertPromise = new AlertPromise()
export const getAllTryoutScheduleDatatable = (queryParams: BaseDatatableRequest) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({ callType: callTypes.list }))
  return service
    .getDatatableResultTryout(queryParams)
    .then((response) => {
      const { totalCount, entities } = response
      dispatch(actions.saveList({ totalCount, entities }))

      alertPromise.stop()
      return response
    })
    .catch((err) => {
      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, RESULT_TRYOUT_GET_FAILED)
      } else {
        alertError()
      }
      dispatch(actions.catchError({ err, callType: callTypes.action }))
    })
}

export const getAllTryoutScheduleBatchDatatable = (queryParams: BaseDatatableRequest) => (dispatch: any) => {
  dispatch(actions.startCall({ callType: callTypes.list }))
  return service
    .getDatatableResultTryoutBatch(queryParams)
    .then((response) => {
      const { totalCount, entities } = response
      
      dispatch(actions.saveListBatch({ totalCount, entities }))

      return response
    })
    .catch((err) => {
      if (err.response) {
        errorHandling(err.response, RESULT_TRYOUT_GET_FAILED)
      } else {
        alertError()
      }
      dispatch(actions.catchError({ err, callType: callTypes.action }))
    })
}

export const getAllTryoutScheduleBatchScoreDatatable = (queryParams: BaseDatatableRequest) => (dispatch: any) => {
  dispatch(actions.startCall({ callType: callTypes.list }))
  return service
    .getDatatableResultTryoutBatchScore(queryParams)
    .then((response) => {
      const { totalCount, entities } = response
      dispatch(actions.saveListScore({ totalCount, entities }))

      return response
    })
    .catch((err) => {
      if (err.response) {
        errorHandling(err.response, RESULT_TRYOUT_GET_FAILED)
      } else {
        console.error(err);
        alertError()
      }
      dispatch(actions.catchError({ err, callType: callTypes.action }))
    })
}

export const deleteOneResult = (id: string) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({ callType: callTypes.action }))

  return service
    .deleteOneResultTryoutByID(id)
    .then(() => {
      alertPromise.stop()
      alertSuccess(RESULT_TRYOUT_DELETE_SUCCESS)
      dispatch(actions.deleteObject({ id }))
    })
    .catch((err) => {
      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, RESULT_TRYOUT_DELETE_FAILED)
      } else {
        console.error(err)
        alertError()
      }
      dispatch(actions.catchError({ err, callType: callTypes.action }))
    })
}

export const getOneResult = (scheduleId?: string, userId?: string) => (dispatch: any) => {
  if (!scheduleId) {
    return dispatch(actions.saveObject({ data: undefined }))
  }
  if (!userId) {
    return dispatch(actions.saveObject({ data: undefined }))
  }

  dispatch(actions.startCall({ callType: callTypes.action }))
  return service
    .getOneResult(scheduleId, userId)
    .then((response) => {
      const result = response
      dispatch(actions.saveObject({ data: result }))
      return result
    })
    .catch((err) => {
      if (err.response) {
        errorHandling(err.response, RESULT_TRYOUT_GET_FAILED)
      } else {
        alertError()
      }
      dispatch(actions.catchError({ err, callType: callTypes.action }))
    })
}

export const calculateOneResult = (id: string) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({ callType: callTypes.action }))

  return service
    .calculate(id)
    .then(() => {
      alertPromise.stop()
      alertSuccess(RESULT_TRYOUT_CALCULATE_SUCCESS)
      dispatch(actions.deleteObject({ id }))
    })
    .catch((err) => {
      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, RESULT_TRYOUT_CALCULATE_FAILED)
      } else {
        console.error(err)
        alertError()
      }
      dispatch(actions.catchError({ err, callType: callTypes.action }))
    })
}

export const calculateBatchResult = (id: string) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({ callType: callTypes.action }))

  return service
    .calculateBatch(id)
    .then(() => {
      alertPromise.stop()
      alertSuccess(RESULT_TRYOUT_CALCULATE_SUCCESS)
      dispatch(actions.deleteObject({ id }))
    })
    .catch((err) => {
      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, RESULT_TRYOUT_CALCULATE_FAILED)
      } else {
        console.error(err)
        alertError()
      }
      dispatch(actions.catchError({ err, callType: callTypes.action }))
    })
}
