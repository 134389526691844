import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  postLoading: false,
}

const slice = createSlice({
  name: 'control',
  initialState: initialState,
  reducers: {
    // Redux for starting
    setLoading: (state, action) => {
      state.postLoading = action.payload
    },
  },
})

export const control = {
  slice,
}
