/* eslint-disable jsx-a11y/anchor-is-valid */

import { Navigate, Outlet, Route, Routes } from 'react-router'
import { PageTitle } from '../../../../../_layout/core'
import { TryoutQuestionList } from './TryoutQuestionList'
import {
  deleteSectionContext,
  queryParamsContext,
} from './_outletContext'
import { TryoutQuestionCreate } from './TryoutQuestionCreate'
import { SubjectUIProvider } from '../../../../../_context/SubjectUIContext'
import { QuestionTryoutUIProvider } from '../../../../../_context/QuestionTryoutUIContext'
import { ChapterUIProvider } from '../../../../../_context/ChapterUIContext'
import { TryoutQuestionEdit } from './TryoutQuestionEdit'

const TryoutQuestionContent = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>{'TryoutQuestion'}</PageTitle>
      <Outlet
        context={{
          deleteSection: deleteSectionContext(),
          filter: queryParamsContext(),
          // edit: tryoutQuestionEditContext(),
        }}
      />
    </>
  )
}

const TryoutQuestion = () => {
  return (
    <>
      <SubjectUIProvider>
        <QuestionTryoutUIProvider>
          <ChapterUIProvider>
            <Routes>
              <Route element={<TryoutQuestionContent />}>
                <Route index element={<TryoutQuestionList />} />
                <Route path='create' element={<TryoutQuestionCreate />} />
                <Route path='edit/:chapterId/:tryoutQuestionId' element={<TryoutQuestionEdit />} />
                <Route path='*' element={<Navigate to='/error/404' />} />
              </Route>
            </Routes>
          </ChapterUIProvider>
        </QuestionTryoutUIProvider>
      </SubjectUIProvider>
    </>
  )
}

export { TryoutQuestion }
