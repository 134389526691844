import React from 'react'
import { Parent } from '../../../../../../_domain/model/Parent'

type Props = {
  entities: Parent
}

export default function ParentOverview(props: Props) {
  const { entities } = props;
  
  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Overview</span>
        </h3>
        <div className='card-toolbar'></div>
      </div>
      <div className='card-body py-3'>
        <div className='row mb-7'>
          <label className='col-lg-4 fw-bold text-muted'>Full Name</label>

          <div className='col-lg-8'>
            <span className='fw-bolder fs-6 text-gray-900'>{entities.name}</span>
          </div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-4 fw-bold text-muted'>ID Qonstanta</label>

          <div className='col-lg-8 fv-row'>
            <span className='fw-bold fs-6'>{entities.id_qonstanta}</span>
          </div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-4 fw-bold text-muted'>
            Phone Number
            {/* <i
              className='fas fa-exclamation-circle ms-1 fs-7'
              data-bs-toggle='tooltip'
              title='Phone number must be active'
            ></i> */}
          </label>

          <div className='col-lg-8 d-flex align-items-center'>
            <span className='fw-bolder fs-6 me-2'>{entities.phone_number}</span>
          </div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-4 fw-bold text-muted'>Email</label>

          <div className='col-lg-8'>
            <a href='#' className='fw-bold fs-6 text-gray-900 text-hover-primary'>
              {entities.email}
            </a>
          </div>
        </div>

        <div className='row mb-10'>
          <label className='col-lg-4 fw-bold text-muted'>Parent Type</label>

          <div className='col-lg-8'>
            <span className='fw-bold fs-6'>{entities.type}</span>
          </div>
        </div>
      </div>
    </div>
  )
}
