import {ResultResponse} from '../../_data/dataSource/response/ResultResponse'
import {MAJOR} from '../constanta'
import {Schedule} from './Schedule'

export type Result = {
  id: string
  user_id: {
    id: number
    name: string
    email: string
  }
  schedule_detail: Pick<
    Schedule,
    'id' | 'exam' | 'type_tryout' | 'chapter_detail' | 'name' | 'is_batch'
  >
  result_questions: any[]
  score?: number
  date_result: string
  time_start: string
  time_end: string
  date_result_now: string
  time_start_schedule: string
  time_end_schedule: string
  result_questions_list?: any[]
  student_data?: any
  bunch_data?: any
  aggregationCount?: {
    totalTrue: number
    totalFalse: number
    totalDoubtful: number
    totalEmpty: number
  }
}

// type Questions = {
//   question?: string
//   answer_key?: string
// }

export class ResultBuilder {
  SingleResult!: Result
  ResultListData!: Result[]
  constructor() {}

  SingleData(resultResponse: ResultResponse): ResultBuilder {
    const result = mapingResult(resultResponse)
    this.SingleResult = result
    return this
  }
  ListData(resultResponse: ResultResponse[]): ResultBuilder {
    const results = mapingResults(resultResponse)
    this.ResultListData = results
    return this
  }
  AddAggregationDataSingle(): ResultBuilder {
    let totalTrue = 0
    let totalFalse = 0
    let totalDoubtful = 0
    let totalEmpty = 0
    this.SingleResult.result_questions_list?.map((item) => {
      switch (item) {
        case 0:
          totalFalse += 1
          break
        case 1:
          totalTrue += 1
          break
        case 'D':
          totalDoubtful += 1
          break
        default:
          totalEmpty += 1
          break
      }
    })
    this.SingleResult = {
      ...this.SingleResult,
      aggregationCount: {
        totalTrue,
        totalFalse,
        totalDoubtful,
        totalEmpty,
      },
    }
    return this
  }
  Result(): Result {
    return this.SingleResult
  }
  ResultList(): Result[] {
    return this.ResultListData
  }
}

function mapingResult(resultResponse: ResultResponse): Result {
  const result: Result = {
    id: resultResponse._id,
    user_id: {
      id: resultResponse.user_id.id,
      name: resultResponse.user_id.name,
      email: resultResponse.user_id.email,
    },
    schedule_detail: {
      id: resultResponse.schedule_id._id ?? '',
      exam: {
        _id: resultResponse?.schedule_id?.exam?._id ?? '',
        name: resultResponse?.schedule_id?.exam?.name ?? '',
      },
      type_tryout: {
        _id: resultResponse?.schedule_id?.type_tryout?._id ?? '',
        name: resultResponse?.schedule_id?.type_tryout?.name ?? '',
      },
      name: resultResponse.schedule_id.name,
      is_batch: resultResponse.schedule_id.is_batch,
      chapter_detail: {
        id: resultResponse.schedule_id.chapter_ids?.at(0)?._id ?? '',
        name: resultResponse.schedule_id.chapter_ids?.at(0)?.name ?? '',
        grade: 0,
        major: MAJOR.ALL,
        semester: 0,
      },
    },
    result_questions: resultResponse.resulttests,
    // resultResponse?.resulttests?.map((item) => {
    //   return {
    //     _id: item._id,
    //     type_question: typeQuestionSwitch(item.type_question),
    //     role: roleQuestionSwitch(item.role),
    //     number_question: item.nomor,
    //     sub_question: item.sub_question,
    //     nomor: item.nomor,
    //     answer_user: item.answer_user,
    //     result: item.result,
    //     point: item.point,
    //     question_text: item.question_text,
    //     opsi_a: item.opsi_a,
    //     opsi_b: item.opsi_b,
    //     opsi_c: item.opsi_c,
    //     opsi_d: item.opsi_d,
    //     opsi_e: item.opsi_e,
    //     answer_key: item.answer_key,
    //     answer_desc: item.answer_desc,
    //   }
    // }) ?? [],
    date_result: resultResponse.date_result,
    time_start: resultResponse.time_start,
    time_end: resultResponse.time_end,
    date_result_now: resultResponse.date_result_now,
    time_start_schedule: resultResponse.time_start_schedule,
    time_end_schedule: resultResponse.time_start_schedule,
    score: resultResponse.score,
    result_questions_list: resultResponse.result_questions,
    student_data: resultResponse.student_data,
    bunch_data: resultResponse.bunch_data,
  }
  return result
}

function mapingResults(resultsResponse: ResultResponse[]): Result[] {
  const results = resultsResponse.map((item) => {
    return mapingResult(item)
  })
  return results
}
