import {BaseDatatableRequest} from '../../_data/dataSource/request/BaseDatatableRequest'
import {
  mappingInputFormToProductCreateRequest,
  mappingInputFormToProductUpdateRequest,
} from '../../_data/dataSource/request/ProductRequest'
import {BaseDatatableResponse} from '../../_data/dataSource/response/BaseDatatableResponse'
import {ProductFormInput} from '../../helpers/UIProductConstanta'
import {Product} from '../model/Product'
import {ProductRepository} from '../repository/ProductRepository'

export interface ProductService {
  getDatatableProduct(req: BaseDatatableRequest): Promise<BaseDatatableResponse<Product>>
  getAllProduct(): Promise<Product[]>
  getDetailProductById(id: string): Promise<Product>
  deleteOneProductByID(id: string): Promise<string>
  createOneProduct(req: ProductFormInput): Promise<Product>
  updateOneProduct(req: ProductFormInput, id: string): Promise<Product>
}

export class ProductServiceImpl implements ProductService {
  private repo: ProductRepository
  constructor(repo: ProductRepository) {
    this.repo = repo
  }

  async updateOneProduct(req: ProductFormInput, id: string): Promise<Product> {
    const body = mappingInputFormToProductUpdateRequest(req)
    const product = await this.repo.updateOne(body, id)
    if (req.imageFile) {
      this.repo.updateImage(product.id, req.imageFile)
    }
    return product
  }

  async createOneProduct(req: ProductFormInput): Promise<Product> {
    const body = mappingInputFormToProductCreateRequest(req)
    const product = await this.repo.createOne(body)
    if (req.imageFile) {
      this.repo.updateImage(product.id, req.imageFile)
    }
    return product
  }

  async deleteOneProductByID(id: string): Promise<string> {
    const message = await this.repo.deleteOneById(id)
    return message
  }

  async getDatatableProduct(req: BaseDatatableRequest): Promise<BaseDatatableResponse<Product>> {
    const products = await this.repo.getDatatable(req)
    return products
  }

  async getDetailProductById(id: string): Promise<Product> {
    const product = await this.repo.getOneById(id)
    return product
  }

  async getAllProduct(): Promise<Product[]> {
    const products = await this.repo.getAll()
    return products
  }
}
