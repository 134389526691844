import React, { useEffect, useState } from 'react'
import { KTIcon, toAbsoluteUrl } from '../../../../../_layout/helpers'
import { Link, useParams } from 'react-router-dom'
import { PageTitle } from '../../../../../_layout/core'
import { PARTNER_DETAIL_TITLE } from '../../../../../_domain/constanta'
import PartnerOverview from './PartnerDetailComponent/PartnerOverview'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { getOnePartner, getOnePartnerUser } from './PartnerAction'
import PartnerReferral from './PartnerDetailComponent/PartnerReferral'
import PartnerOrder from './PartnerDetailComponent/PartnerOrder'

export default function PartnerDetail() {
  const { id } = useParams()
  const [tab, setTab] = useState("overview")
  const dispatch = useDispatch()

  const { actionsLoading, entity, entityUser } = useSelector(
    (state: any) => ({
      actionsLoading: state.partner.actionsLoading,
      entity: state.partner.entity,
      entityUser: state.partner.entityUser,
    }),
    shallowEqual
  );

  // if !id we should close modal
  useEffect(() => {
    dispatch<any>(getOnePartner(id))
    dispatch<any>(getOnePartnerUser(id))
    return () => {
      dispatch<any>(getOnePartner(undefined))
      dispatch<any>(getOnePartnerUser(undefined))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <>
      <PageTitle breadcrumbs={[]}>{PARTNER_DETAIL_TITLE}</PageTitle>
      {entity && (
        <div className='card mb-5 mb-xl-10'>
          <div className='card-body pt-9 pb-0'>
            <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
              <div className='me-7 mb-4'>
                <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                  <img src={toAbsoluteUrl('/media/svg/avatars/004-boy-1.svg')} alt={entity.name ?? "Default"} />
                  <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div>
                </div>
              </div>

              <div className='flex-grow-1'>
                <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                  <div className='d-flex flex-column'>
                    <div className='d-flex align-items-center mb-2'>
                      <a href='#' className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                        {entity.name ?? ""}
                      </a>
                      {entity.is_active && (
                        <a href='#'>
                          <KTIcon iconName='verify' className='fs-1 text-primary' />
                        </a>
                      )}
                      <a
                        href='#'
                        className='btn btn-sm btn-light-success fw-bolder ms-2 fs-8 py-1 px-3'
                        data-bs-toggle='modal'
                        data-bs-target='#kt_modal_upgrade_plan'
                      >
                        {entity.id_qonstanta ?? ""}
                      </a>
                    </div>

                    <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                      <a
                        href='#'
                        className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                      >
                        <KTIcon iconName='profile-circle' className='fs-4 me-1' />
                        Partner/Mitra
                      </a>
                      <a
                        href='#'
                        className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                      >
                        <KTIcon iconName='sms' className='fs-4 me-1' />
                        {entity.email ?? ""}
                      </a>
                      <a
                        href='#'
                        className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                      >
                        <KTIcon iconName='telephone-geolocation' className='fs-4 me-1' />
                        {entity.phone_number ?? ""}
                      </a>
                    </div>
                  </div>
                </div>

                <div className='d-flex flex-wrap flex-stack'>
                  <div className='d-flex flex-column flex-grow-1 pe-8'>
                    <div className='d-flex flex-wrap'>

                      <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                        <div className='d-flex align-items-center'>
                          <KTIcon iconName='basket' className='fs-3 text-primary me-2' />
                          <div className='fs-2 fw-bolder'>75</div>
                        </div>

                        <div className='fw-bold fs-6 text-gray-400'>Order</div>
                      </div>

                      <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                        <div className='d-flex align-items-center'>
                          <KTIcon iconName='dollar' className='fs-3 text-success me-2' />
                          <div className='fs-2 fw-bolder'>3/4</div>
                        </div>

                        <div className='fw-bold fs-6 text-gray-400'>Complete Payment</div>
                      </div>
                    </div>
                  </div>

                  <div className='d-flex align-items-center w-200px w-sm-300px flex-column mt-3'>
                    <div className='d-flex justify-content-between w-100 mt-auto mb-2'>
                      <span className='fw-bold fs-6 text-gray-400'>Progress Payment</span>
                      <span className='fw-bolder fs-6'>50%</span>
                    </div>
                    <div className='h-5px mx-3 w-100 bg-light mb-3'>
                      <div
                        className='bg-success rounded h-5px'
                        role='progressbar'
                        style={{ width: '50%' }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='d-flex overflow-auto h-55px'>
              <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                <li className='nav-item'>
                  <Link
                    className={`nav-link text-active-primary me-6 ${tab === "overview" ? "active" : ""}`} onClick={() => {
                      setTab("overview")
                    }}
                    to='#'
                  >
                    Overview
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link
                    className={`nav-link text-active-primary me-6 ${tab === "referral" ? "active" : ""}`} onClick={() => {
                      setTab("referral")
                    }}
                    to='#'
                  >
                    Referral
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link
                    className={`nav-link text-active-primary me-6 ${tab === "order" ? "active" : ""}`} onClick={() => {
                      setTab("order")
                    }}
                    to='#'
                  >
                    Order's Referral
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link
                    className={`nav-link text-active-primary me-6 ${tab === "setting" ? "active" : ""}`}
                    onClick={() => {
                      setTab("setting")
                    }}
                    to='#'
                  >
                    Settings
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}
      {tab === "overview" && (
        <PartnerOverview />
      )}
      {tab === "referral" && (
        <PartnerReferral entityUser={entityUser} />
      )}
      {tab === "order" && (
        <PartnerOrder entityUser={entityUser} />
      )}
    </>
  )
}
