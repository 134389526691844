const BANNER_DELETE_TITLE = 'Banner Delete'
const BANNER_DELETE_LOADING = 'Banner is deleting...'
const BANNER_DELETE_CONFIRM = 'Are you sure to permanently delete this banner ?'
const BANNER_DELETE_SUCCESS = 'Successful delete banner'
const BANNER_DELETE_FAILED = "Can't delete banner"
const BANNER_CREATE_TITLE = 'Banner Create'
const BANNER_UPLOAD_BATCH_TITLE = 'Student Upload Batch'
const BANNER_CREATE_FAILED = "Can't create banner"
const BANNER_CREATE_SUCCESS = 'Successful create banner'
const BANNER_EDIT_TITLE = 'Banner Edit'
const BANNER_EDIT_SUCCESS = 'Successful update banner'
const BANNER_EDIT_FAILED = "Can't update banner"
const BANNER_GET_FAILED = "Can't get banner"
export {
  BANNER_DELETE_TITLE,
  BANNER_UPLOAD_BATCH_TITLE,
  BANNER_DELETE_CONFIRM,
  BANNER_DELETE_LOADING,
  BANNER_DELETE_SUCCESS,
  BANNER_DELETE_FAILED,
  BANNER_CREATE_TITLE,
  BANNER_CREATE_SUCCESS,
  BANNER_CREATE_FAILED,
  BANNER_EDIT_TITLE,
  BANNER_EDIT_SUCCESS,
  BANNER_EDIT_FAILED,
  BANNER_GET_FAILED,
}
