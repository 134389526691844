import {GroupRoleFormInput} from '../../../helpers/UIGroupRoleConstanta'
import {StudentAccessUpdateFormInput} from '../../../helpers/UIStudentConstanta'

export type StudenRoleAppendRequest = {
  user_id: number
  group_id: string
  roles: {
    _id: string
    short_name: string
    name: string
    slug: string
    special_limitations?: any
  }[]
}

export type GroupRoleCreateRequest = {
  group_name: string
  roles: {
    _id: string
    short_name: string
    name: string
    slug: string
    special_limitations?: any
  }[]
}

export function mappingInputFormToStudenRoleAppendRequest(
  user_id: number,
  req: StudentAccessUpdateFormInput
): StudenRoleAppendRequest {
  return {
    user_id: user_id,
    group_id: req.groupIdInput ?? '',
    roles:
      req.studentRole?.map((item) => {
        return {
          _id: item._id ?? '',
          short_name: item.short_name ?? '',
          name: item.name ?? '',
          slug: item.slug ?? '',
          special_limitations: item.isLimitInput === 'false' ? undefined : item.special_limitations,
        }
      }) ?? [],
  }
}

export function mappingInputFormToGroupRoleCreateRequest(
  req: GroupRoleFormInput
): GroupRoleCreateRequest {
  return {
    group_name: req.groupNameInput!,
    roles:
      req.studentRole?.map((item) => {
        return {
          _id: item._id ?? '',
          short_name: item.short_name ?? '',
          name: item.name ?? '',
          slug: item.slug ?? '',
          special_limitations: item.isLimitInput === 'false' ? undefined : item.special_limitations,
        }
      }) ?? [],
  }
}
