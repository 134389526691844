import { PageTitle } from '../../../../../_layout/core'
import React, { Suspense, useMemo } from 'react'
import CustomLoading from '../../../../../_component/customLoading'
import { CHAPTER_CREATE_TITLE } from '../../../../../_domain/constanta/ChapterConstant'
import { useDispatch } from 'react-redux'
import { createChapter, getAllChapterDatatable } from './ChapterAction'
import { Link, useNavigate, useOutletContext } from 'react-router-dom'
import { ChapterFormInput } from '../../../../../helpers/UIChapterConstanta'

const ChapterCreateForm = React.lazy(() => import('./ChapterCreateComponent/ChapterCreateForm'))

const ChapterCreate = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch();

  const filterContext = useOutletContext<any>().queryParams
  const { queryParams } = useMemo(() => {
    return {
      queryParams: filterContext.queryParams,
    };
  }, [filterContext]);

  const saveAction = (data: ChapterFormInput) => {
    dispatch<any>(createChapter(data)).then(() => {
      // refresh list after deletion
      dispatch<any>(getAllChapterDatatable(queryParams));
      // Back
      navigate("/master/chapter")
    })
  }
  return (
    <>
      <PageTitle breadcrumbs={[]}>{CHAPTER_CREATE_TITLE}</PageTitle>
      <div className='d-flex justify-content-between align-items-center mb-4'>
        <div className='w-full'></div>
        <div>
          <Link to={"/master/chapter"}>
            <button type='button'
              className='btn btn-secondary'>
              Back
            </button>
          </Link>
        </div>
      </div>
      <Suspense fallback={<CustomLoading />}>
        <ChapterCreateForm onSubmit={saveAction} />
      </Suspense>
    </>
  )
}

export { ChapterCreate }
