/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { PageTitle } from '../../../../../_layout/core'
import { getAllTryoutQuestionDatatable } from './TryoutQuestionAction'
import { Suspense } from 'react'
import CustomLoading from '../../../../../_component/customLoading'
import { Link, useOutletContext } from 'react-router-dom'
import { QuestionTryout } from '../../../../../_domain/model/QuestionTryout'
import { BaseDatatableResponse } from '../../../../../_data/dataSource/response/BaseDatatableResponse'
import { QUESTION_TRYOUT_LIST_TITLE } from '../../../../../_domain/constanta/QuestionTryoutConstant'
import TryoutQuestionListFilter from './TryoutQuestionListComponent/TryoutQuestionListFilter'
import { useAuth } from '../../../../../_context/AuthContext'
import { checkAuthActionMenu } from '../../../../../helpers/AuthHelpers'
import { SLUG_TRYOUT_SUB_MENU } from '../../../../../helpers/UIAuthGroupConstanta'
const TryoutQuestionListTable = React.lazy(() => import('./TryoutQuestionListComponent/TryoutQuestionListTable'))
const TryoutQuestionDelete = React.lazy(() => import('./TryoutQuestionDelete'))

const checkReduxData = () => {
  const outletContext = useOutletContext<any>().filter
  const { queryParams } = outletContext

  // detect redux first before fetch API
  const [tryoutQuestionListData, setTryoutQuestionListData] = useState<QuestionTryout[] | null>(null)
  const dispatch = useDispatch()

  useEffect(() => {
    // fetch from API if data is not available in the redux
    dispatch<any>(getAllTryoutQuestionDatatable(queryParams)).then((response: BaseDatatableResponse<QuestionTryout>) => {
      setTryoutQuestionListData(response.entities)
    })
  }, [queryParams, getAllTryoutQuestionDatatable])

  return tryoutQuestionListData
  // detect redux first before fetch API END
}

const TryoutQuestionList = () => {
  let data = checkReduxData()
  const { currentUser, authorization } = useAuth()
  return checkAuthActionMenu(SLUG_TRYOUT_SUB_MENU.TRYOUT_QUESTION_MENU, "view", currentUser, authorization) ? (
    <>
      <PageTitle breadcrumbs={[]}>{QUESTION_TRYOUT_LIST_TITLE}</PageTitle>
      <div className='d-flex justify-content-between align-items-center mb-4'>
        <TryoutQuestionListFilter />
        <div style={{ width: "150px" }}>
          {checkAuthActionMenu(SLUG_TRYOUT_SUB_MENU.TRYOUT_QUESTION_MENU, "create", currentUser, authorization) && (
            <Link to={"/tryout/question/create"}>
              <button type='button'
                className='btn btn-sm btn-primary'>
                Add New
              </button>
            </Link>
          )}
        </div>
      </div>
      <TryoutQuestionDelete />
      <Suspense fallback={<CustomLoading />}>
        {data ? <TryoutQuestionListTable /> : null}
      </Suspense>
    </>
  ) : null
}

export { TryoutQuestionList }
