import {BaseDatatableRequest} from '../../_data/dataSource/request/BaseDatatableRequest'
import {
  mappingInputFormToBannerCreateRequest,
  mappingInputFormToBannerUpdateRequest,
} from '../../_data/dataSource/request/BannerRequest'
import {BaseDatatableResponse} from '../../_data/dataSource/response/BaseDatatableResponse'
import {BannerFormInput} from '../../helpers/UIBannerConstanta'
import {Banner} from '../model/Banner'
import {BannerRepository} from '../repository/BannerRepository'

export interface BannerService {
  getDatatableBanner(req: BaseDatatableRequest): Promise<BaseDatatableResponse<Banner>>
  getAllBanner(): Promise<Banner[]>
  getDetailBannerById(id: string): Promise<Banner>
  deleteOneBannerByID(id: string): Promise<string>
  createOneBanner(req: BannerFormInput): Promise<Banner>
  updateOneBanner(req: BannerFormInput, id: string): Promise<Banner>
}

export class BannerServiceImpl implements BannerService {
  private repo: BannerRepository
  constructor(repo: BannerRepository) {
    this.repo = repo
  }

  async updateOneBanner(req: BannerFormInput, id: string): Promise<Banner> {
    const body = mappingInputFormToBannerUpdateRequest(req)
    const banner = await this.repo.updateOne(body, id)
    if (req.imagesFile && req.imagesFile.length > 0) {
      this.repo.updateImage(banner.id, req.imagesFile, req.indexesInput.sort())
    }
    return banner
  }

  async createOneBanner(req: BannerFormInput): Promise<Banner> {
    const body = mappingInputFormToBannerCreateRequest(req)
    const banner = await this.repo.createOne(body)
    if (req.imagesFile && req.imagesFile.length > 0) {
      this.repo.updateImage(banner.id, req.imagesFile, req.indexesInput.sort())
    }
    return banner
  }

  async deleteOneBannerByID(id: string): Promise<string> {
    const message = await this.repo.deleteOneById(id)
    return message
  }

  async getDatatableBanner(req: BaseDatatableRequest): Promise<BaseDatatableResponse<Banner>> {
    const banners = await this.repo.getDatatable(req)
    return banners
  }

  async getDetailBannerById(id: string): Promise<Banner> {
    const banner = await this.repo.getOneById(id)
    return banner
  }

  async getAllBanner(): Promise<Banner[]> {
    const banners = await this.repo.getAll()
    return banners
  }
}
