import React, { useCallback, useEffect, useState } from 'react'
import { defaultQueryParams, prepareFilterBase } from '../../../_data/dataSource/request/BaseDatatableRequest'
import { isEqual, isFunction } from 'lodash'
import QuestionTryoutDataSourceImpl from '../../../_data/dataSource/QuestionTryoutDataSourceImpl'
import { QuestionTryoutRepositoryImpl } from '../../../_domain/repository/QuestionTryoutRepositoryImpl'
import { QuestionTryout } from '../../../_domain/model/QuestionTryout'
import { QuestionTryoutFilterV2Request } from '../../../_data/dataSource/request/QuestionTryoutRequest'

const init = defaultQueryParams()

const useQuestionTryoutTable = () => {
  const [queryParams, setQueryParamsBase] = useState(init)
  const [entities, setEntities] = useState<QuestionTryout[]>([])
  const [totalCount, setTotalCount] = useState<number>(0);

  const dataSource = new QuestionTryoutDataSourceImpl()
  const repo = new QuestionTryoutRepositoryImpl(dataSource)

  const setQueryParams = useCallback((nextQueryParams: any) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams)
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams
      }

      return nextQueryParams
    })
  }, [])

  const getListQuestionTryout = useCallback(
    async () => {
      const response = await repo.getDatatable(queryParams)
      setEntities(response.entities)
      setTotalCount(response.totalCount)
    },
    [setEntities, setTotalCount, queryParams],
  )

  // queryParams, setQueryParams,
  const applyFilter = (values: QuestionTryoutFilterV2Request) => {

    const newQueryParams = prepareFilterBase(queryParams, values)

    if (!isEqual(newQueryParams, queryParams)) {
      newQueryParams.pageNumber = 1

      // update list by queryParams
      setQueryParams(newQueryParams)
    }
  }

  const handlePageChange = (page: number, perPage: number) => {
    const tmp = { ...queryParams }
    tmp.pageNumber = page
    setQueryParams(tmp)
  }

  const handleSortChange = (column: any, sortDirection: any) => {
    const tmp = { ...queryParams }
    tmp.sortOrder = sortDirection
    tmp.sortField = column.id
    setQueryParams(tmp)
  }


  useEffect(() => {
    if (queryParams.filter.chapter_id && queryParams.filter.role) {
      getListQuestionTryout()
    } else {
      setEntities([])
      setTotalCount(0)
    }
  }, [queryParams])


  const value = {
    queryParams,
    entities,
    setEntities,
    totalCount,
    setTotalCount,
    setQueryParamsBase,
    setQueryParams,
    applyFilter,
    handlePageChange,
    handleSortChange,
  }

  return value
}
export default useQuestionTryoutTable