import {CLASS_STUDENT} from '../../../helpers/UIConstanta'
import {
  QuestionTryoutExistFormInput,
  QuestionTryoutFormInput,
} from '../../../helpers/UIQuestionTryoutConstanta'

export type QuestionTryoutCreateRequest = {
  subject_id: string
  grade: number
  semester: number
  major: string
  sub_question?: {
    title: string
    description: string
    titleTrueFalse: string[]
    questions: {
      question: string
      answer_key: string
    }[]
  }
  type_question?: string
  role: string
  type_tryout: string
  nomor: number
  group_id: string
  chapterId: string
  question_text?: string
  opsi_a?: string
  opsi_b?: string
  opsi_c?: string
  opsi_d?: string
  opsi_e?: string
  answer_key?: string
  answer_desc?: string
  answer_sentence_text?: string
  answer_sentence_key?: string[]
  answer_videos?: {
    url: string
  }[]
}

export type QuestionTryoutAddRequest = {
  chapterId?: string
  question_ids: string[]
  role: string
  type_tryout: string
  nomor: number
}

export type QuestionTryoutEditExistRequest = {
  chapterId?: string
  question_id_new: string
  question_id_old: string
  role: string
  type_tryout: string
  nomor: number
}

export type QuestionTryoutUpdateRequest = {
  chapterId: string
  question_id: string
  chapter_id: string
  nomor: number
  group_id: string
  question_text?: string
  answer_desc?: string
  role: string
  type_tryout: string
  type_question?: string
  opsi_a?: string
  opsi_b?: string
  opsi_c?: string
  opsi_d?: string
  opsi_e?: string
  answer_key?: string
  answer_sentence_text?: string
  answer_sentence_key?: string[]
  answer_videos?: {
    url: string
  }[]
  sub_question?: {
    title: string
    description: string
    titleTrueFalse: string[]
    questions: {
      question: string
      answer_key: string
    }[]
  }
}

export type QuestionTryoutFilterRequest = {
  chapter_id: string
  grade: number
  major: string
  role: string
  semester: number
  subject_id: string
  class_index: number
}

export type QuestionTryoutFilterV2Request = {
  chapter_id: string
  role: string
}

export type QuestionTryoutNumberAvailableRequest = {
  role: string
  chapterId: string
}

export function mappingInputFormToQuestionTryoutUpdateExistRequest(
  req: QuestionTryoutExistFormInput,
  chapterId: string,
  question_id: string
): QuestionTryoutEditExistRequest {
  return {
    question_id_new: req.question_new ? req.question_new[0].id : '',
    question_id_old: question_id,
    role: req.seriesInput ?? '',
    type_tryout: req.typeTOInput ?? '',
    nomor: req.numberInput ? +req.numberInput : 0,
    chapterId: chapterId,
  }
}

export function mappingInputFormToQuestionTryoutUpdateRequest(
  req: QuestionTryoutFormInput,
  chapterId: string,
  question_id: string
): QuestionTryoutUpdateRequest {
  let body: QuestionTryoutUpdateRequest = {
    chapterId: chapterId,
    question_id: question_id,
    role: req.seriesInput ?? '',
    type_tryout: req.typeTOInput ?? '',
    nomor: req.numberInput ? +req.numberInput : 0,
    group_id: req.groupIdInput?.toUpperCase().trim() ?? '-',
    chapter_id: req.chapterInput ?? '',
    question_text: req.questionTextInput,
    opsi_a: req.opsiAInput,
    opsi_b: req.opsiBInput,
    opsi_c: req.opsiCInput,
    opsi_d: req.opsiDInput,
    opsi_e: req.visibleOpsiE === 'true' ? req.opsiEInput : undefined,
    answer_key: req.answerKeyInput,
    answer_desc: req.answerDescInput,
    answer_sentence_key: req.answerSentenceKeyInput,
    answer_sentence_text: req.answerSentenceTextInput,
    type_question: req.typeInput,
    answer_videos: [
      {
        url: req.answerVideoUrlInput ?? '',
      },
    ],
  }
  if (req.typeInput === 'multiple' || req.typeInput === 'TF') {
    body.sub_question = {
      title: '',
      description: req.descriptionMultipleInput ?? '',
      titleTrueFalse: [req.titleTrueInput ?? "", req.titleFalseInput ?? ""],
      questions:
        req.questionsInput?.map((item) => {
          return {
            question: item.question ?? '',
            answer_key: item.answer_key ?? '',
          }
        }) ?? [],
    }
  }
  return body
}

export function mappingInputFormToQuestionTryoutCreateRequest(
  req: QuestionTryoutFormInput
): QuestionTryoutCreateRequest {
  const {major, grade, level} = CLASS_STUDENT[req.classInput ? +req.classInput : 0]

  let body: QuestionTryoutCreateRequest = {
    subject_id: req.subjectInput ?? '',
    grade: grade === 'ALUMNI' ? 13 : +grade,
    semester: req.semesterInput ? +req.semesterInput : 1,
    major: major,
    role: req.seriesInput ?? '',
    type_tryout: req.typeTOInput ?? '',
    nomor: req.numberInput ? +req.numberInput : 0,
    group_id: req.groupIdInput?.toUpperCase().trim() ?? '-',
    chapterId: req.chapterInput ?? '',
    question_text: req.questionTextInput,
    opsi_a: req.opsiAInput,
    opsi_b: req.opsiBInput,
    opsi_c: req.opsiCInput,
    opsi_d: req.opsiDInput,
    opsi_e: req.visibleOpsiE === 'true' ? req.opsiEInput : undefined,
    answer_key: req.answerKeyInput,
    answer_desc: req.answerDescInput,
    answer_sentence_key: req.answerSentenceKeyInput,
    answer_sentence_text: req.answerSentenceTextInput,
    type_question: req.typeInput,
    answer_videos: [
      {
        url: req.answerVideoUrlInput ?? '',
      },
    ],
  }
  if (req.typeInput === 'multiple' || req.typeInput === 'TF') {
    body.sub_question = {
      title: '',
      description: req.descriptionMultipleInput ?? '',
      titleTrueFalse: [req.titleTrueInput ?? "", req.titleFalseInput ?? ""],
      questions:
        req.questionsInput?.map((item) => {
          return {
            question: item.question ?? '',
            answer_key: item.answer_key ?? '',
          }
        }) ?? [],
    }
  }
  return body
}

export function mappingInputFormToQuestionTryoutCreateExistRequest(
  req: QuestionTryoutExistFormInput
): QuestionTryoutAddRequest {
  return {
    chapterId: req.chapterInput ?? undefined,
    question_ids: req.questionsInput?.map((item) => item.id) ?? [],
    role: req.seriesInput ?? '',
    type_tryout: req.typeTOInput ?? '',
    nomor: req.numberInput ? +req.numberInput : 0,
  }
}
