import {BaseDatatableRequest} from '../../_data/dataSource/request/BaseDatatableRequest'
import {
  mappingInputFormToQuestionTryoutCreateExistRequest,
  mappingInputFormToQuestionTryoutCreateRequest,
  mappingInputFormToQuestionTryoutUpdateExistRequest,
  mappingInputFormToQuestionTryoutUpdateRequest,
} from '../../_data/dataSource/request/QuestionTryoutRequest'
import {BaseDatatableResponse} from '../../_data/dataSource/response/BaseDatatableResponse'
import {getIndexClassStudent} from '../../helpers/FuncHelpers'
import {
  QuestionTryoutExistFormInput,
  QuestionTryoutFormInput,
} from '../../helpers/UIQuestionTryoutConstanta'
import {QuestionTryout} from '../model/QuestionTryout'
import {QuestionTryoutRepository} from '../repository/QuestionTryoutRepository'

export interface QuestionTryoutService {
  getDatatableQuestionTryout(
    req: BaseDatatableRequest
  ): Promise<BaseDatatableResponse<QuestionTryout>>
  // getAllQuestionTryout(): Promise<QuestionTryout[]>
  getDetailQuestionTryoutById(
    id: string,
    chapterId: string,
    question_id: string
  ): Promise<QuestionTryout>
  deleteOneQuestionTryoutByID(chapterId: string, question_id: string): Promise<string>
  createOneQuestionTryout(req: QuestionTryoutFormInput): Promise<QuestionTryout>
  addOneQuestionTryout(req: QuestionTryoutExistFormInput): Promise<QuestionTryout>
  updateOneQuestionTryout(
    req: QuestionTryoutFormInput,
    chapterId: string,
    question_id: string
  ): Promise<QuestionTryout>
  updateExistOneQuestionTryout(
    req: QuestionTryoutExistFormInput,
    chapterId: string,
    question_id: string
  ): Promise<QuestionTryout>
}

export class QuestionTryoutServiceImpl implements QuestionTryoutService {
  private repo: QuestionTryoutRepository
  constructor(repo: QuestionTryoutRepository) {
    this.repo = repo
  }

  async updateExistOneQuestionTryout(
    req: QuestionTryoutExistFormInput,
    chapterId: string,
    question_id: string
  ): Promise<QuestionTryout> {
    const body = mappingInputFormToQuestionTryoutUpdateExistRequest(req, chapterId, question_id)
    const question = await this.repo.updateExistOne(body)
    return question
  }

  async updateOneQuestionTryout(
    req: QuestionTryoutFormInput,
    chapterId: string,
    question_id: string
  ): Promise<QuestionTryout> {
    const body = mappingInputFormToQuestionTryoutUpdateRequest(req, chapterId, question_id)

    const question = await this.repo.updateOne(body)
    return question
  }

  async createOneQuestionTryout(req: QuestionTryoutFormInput): Promise<QuestionTryout> {
    const body = mappingInputFormToQuestionTryoutCreateRequest(req)
    
    const questionTryout = await this.repo.createOne(body)
    return questionTryout
  }

  async addOneQuestionTryout(req: QuestionTryoutExistFormInput): Promise<QuestionTryout> {
    // const {major, grade, level} = CLASS_STUDENT[req.classInput ? +req.classInput : 0]

    const body = mappingInputFormToQuestionTryoutCreateExistRequest(req)

    const questionTryout = await this.repo.addOne(body)
    return questionTryout
  }

  async deleteOneQuestionTryoutByID(chapterId: string, question_id: string): Promise<string> {
    const message = await this.repo.deleteOneById(question_id, chapterId)
    return message
  }

  async getDatatableQuestionTryout(
    req: BaseDatatableRequest
  ): Promise<BaseDatatableResponse<QuestionTryout>> {
    const chapters = await this.repo.getDatatable(req)
    return chapters
  }

  async getDetailQuestionTryoutById(
    questionId: string,
    chapterId: string
  ): Promise<QuestionTryout> {
    let question = await this.repo.getOneById(questionId, chapterId)
    const class_index = getIndexClassStudent(
      +question.chapter_detail?.grade!,
      question.chapter_detail?.major!
    )
    question.class_index = class_index
    return question
  }

  // async getAllQuestionTryout(): Promise<QuestionTryout[]> {
  //   const chapters = await this.repo.getAll()
  //   return chapters
  // }
}
