import axios from 'axios'
import {SchoolResponse} from './response/SchoolResponse'
import {ApiResponse} from './response/BaseResponse'
import {BaseDatatableRequest} from './request/BaseDatatableRequest'
import {BaseDatatableResponse} from './response/BaseDatatableResponse'
import {MessageResponse} from './response/MessageResponse'
import {SchoolCreateRequest, SchoolGetAllParams, SchoolUpdateRequest} from './request/SchoolRequest'
import SchoolDataSource from './SchoolDataSoruce'

export default class SchoolDataSourceImpl implements SchoolDataSource {
  private SCHOOOL_URL: string

  constructor() {
    const service_student_url = process.env.REACT_APP_STUDENT_SERVICE_URL || 'api/'
    this.SCHOOOL_URL = `${service_student_url}schools`
  }

  async update(req: SchoolUpdateRequest, id: string): Promise<SchoolResponse> {
    const response = await axios.put<ApiResponse<SchoolResponse>>(`${this.SCHOOOL_URL}/${id}`, req)

    const data = response.data.data
    return data
  }

  async deleteOne(id: string): Promise<string> {
    const response = await axios.delete<MessageResponse>(`${this.SCHOOOL_URL}/${id}`)
    return response.data.message
  }

  async getDatatable(req: BaseDatatableRequest): Promise<BaseDatatableResponse<SchoolResponse>> {
    const response = await axios.post<ApiResponse<BaseDatatableResponse<SchoolResponse>>>(
      `${this.SCHOOOL_URL}/datatable`,
      req
    )

    const data = response.data.data

    return data
  }

  async getOne(id: string): Promise<SchoolResponse> {
    const response = await axios.get<ApiResponse<SchoolResponse>>(`${this.SCHOOOL_URL}/${id}`)
    const data = response.data.data
    return data
  }

  async getAll(params?: SchoolGetAllParams): Promise<SchoolResponse[]> {
    let url = `${this.SCHOOOL_URL}`
    if (params?.city_id) {
      url = `${url}?city_id=${params.city_id}`
    }
    const response = await axios.get<ApiResponse<SchoolResponse[]>>(url)
    const data = response.data.data
    return data
  }

  async create(req: SchoolCreateRequest): Promise<SchoolResponse> {
    const response = await axios.post<ApiResponse<SchoolResponse>>(`${this.SCHOOOL_URL}`, req)
    const data = response.data.data

    return data
  }
}
