const SCHEDULE_LIVE_CLASS_DELETE_TITLE = 'Schedule Live Class Delete'
const SCHEDULE_LIVE_CLASS_DELETE_LOADING = 'Schedule Live Class is deleting...'
const SCHEDULE_LIVE_CLASS_DELETE_CONFIRM =
  'Are you sure to permanently delete this schedule live class ?'
const SCHEDULE_LIVE_CLASS_DELETE_SUCCESS = 'Successful delete schedule live class'
const SCHEDULE_LIVE_CLASS_DELETE_FAILED = "Can't delete schedule live class"
const SCHEDULE_LIVE_CLASS_CREATE_TITLE = 'Schedule Live Class Create'
const SCHEDULE_LIVE_CLASS_CREATE_FAILED = "Can't create schedule live class"
const SCHEDULE_LIVE_CLASS_CREATE_SUCCESS = 'Successful create schedule live class'
const SCHEDULE_LIVE_CLASS_EDIT_TITLE = 'Schedule Live Class Edit'
const SCHEDULE_LIVE_CLASS_EDIT_SUCCESS = 'Successful update schedule live class'
const SCHEDULE_LIVE_CLASS_EDIT_FAILED = "Can't update schedule live class"
const SCHEDULE_LIVE_CLASS_GET_FAILED = "Can't get schedule live class"
const SCHEDULE_LIVE_CLASS_LIST_TITLE = 'Schedule Live Class List'
const SCHEDULE_LIVE_CLASS_ATTENDANCE_TITLE = 'Schedule Live Class Attendance'
const SCHEDULE_LIVE_CLASS_ATTENDANCE_SUCCESS = 'Successful append schedule live class attendance'
const SCHEDULE_LIVE_CLASS_ATTENDANCE_FAILED = "Can't append schedule live class attendance"
const SCHEDULE_LIVE_CLASS_ATTENDANCE_GET_FAILED = "Can't get schedule live class attendance"
export {
  SCHEDULE_LIVE_CLASS_DELETE_TITLE,
  SCHEDULE_LIVE_CLASS_DELETE_CONFIRM,
  SCHEDULE_LIVE_CLASS_DELETE_LOADING,
  SCHEDULE_LIVE_CLASS_DELETE_SUCCESS,
  SCHEDULE_LIVE_CLASS_DELETE_FAILED,
  SCHEDULE_LIVE_CLASS_CREATE_TITLE,
  SCHEDULE_LIVE_CLASS_CREATE_SUCCESS,
  SCHEDULE_LIVE_CLASS_CREATE_FAILED,
  SCHEDULE_LIVE_CLASS_EDIT_TITLE,
  SCHEDULE_LIVE_CLASS_EDIT_SUCCESS,
  SCHEDULE_LIVE_CLASS_EDIT_FAILED,
  SCHEDULE_LIVE_CLASS_GET_FAILED,
  SCHEDULE_LIVE_CLASS_LIST_TITLE,
  SCHEDULE_LIVE_CLASS_ATTENDANCE_TITLE,
  SCHEDULE_LIVE_CLASS_ATTENDANCE_SUCCESS,
  SCHEDULE_LIVE_CLASS_ATTENDANCE_FAILED,
  SCHEDULE_LIVE_CLASS_ATTENDANCE_GET_FAILED,
}
