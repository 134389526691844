import {Controller, useForm} from 'react-hook-form'
import Form from 'react-bootstrap/Form'
import {FC} from 'react'
import {Schedule} from '../../../../../../_domain/model/Schedule'
import StudentClassTable from '../../../../../../_component/Table/StudentClassTable/StudentClassTable'
import {defaultQueryParams} from '../../../../../../_data/dataSource/request/BaseDatatableRequest'
import {Student} from '../../../../../../_domain/model/Student'

type props = {
  submitBtnRef: any
  onSubmit: (req: any) => void
  dataLiveClass: Schedule
  dataStudent: Student[]
}

const initQueryParamsGroupClass = defaultQueryParams()

const LiveClassSelectedForm: FC<props> = ({submitBtnRef, onSubmit, dataLiveClass, dataStudent}) => {
  initQueryParamsGroupClass.filter.id = dataLiveClass.group_class_id
  const {
    control,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm<any>({
    mode: 'onChange',
  })

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group className='mb-3'>
        <Form.Label>Select User Attendees</Form.Label>
        <br />
        <Controller
          name='groupClassInput'
          control={control}
          render={({field: {value}}) => (
            <div className='mt-6'>
              <StudentClassTable
                selectedCallback={(val: any) => {
                  setValue('groupClassInput', val)
                }}
                selectedStudentCallback={(values: Student[]) => {
                  setValue("studentInput", values)
                }}
                values={dataStudent}
                singleSelected={false}
                initFilter={initQueryParamsGroupClass}
                disableFilter={true}
                isSelected={true}
              />
            </div>
          )}
        />
      </Form.Group>

      <div className='mt-4 d-flex justify-content-center d-none'>
        <button ref={submitBtnRef} className='btn btn-primary' type='submit'>
          Submit
        </button>
      </div>
    </Form>
  )
}

export default LiveClassSelectedForm
