import {GroupClassResponse} from '../../_data/dataSource/response/GroupClassResponse'
import {getIndexClassStudent, methodStudySwitch, typeMajorSwitch} from '../../helpers/FuncHelpers'
import {MAJOR, METHOD_STUDY} from '../constanta'

export type GroupClass = {
  id: number
  code: string
  schoolId: string
  name: string
  grade: number
  major: MAJOR
  classIndex?: number
  method_study: METHOD_STUDY
  section: string
  branch: string
  school?: {
    id: number
    name: string
    city_name?: string
    city_id?: string
    province_name?: string
    province_id?: string
  }
}

export class GroupClassBuilder {
  private SingleGroupClass!: GroupClass
  private GroupClassList!: GroupClass[]
  constructor() {}

  SingleData(groupClassResponse: GroupClassResponse): GroupClassBuilder {
    const groupClass = mapingGroupClass(groupClassResponse)
    this.SingleGroupClass = groupClass
    return this
  }
  ListData(groupClassResponse: GroupClassResponse[]): GroupClassBuilder {
    const groupClasss = mapingGroupClasss(groupClassResponse)
    this.GroupClassList = groupClasss
    return this
  }
  Result(): GroupClass {
    return this.SingleGroupClass
  }
  ResultList(): GroupClass[] {
    return this.GroupClassList
  }
}

function mapingGroupClass(groupClassResponse: GroupClassResponse): GroupClass {
  const groupClass: GroupClass = {
    id: groupClassResponse.id,
    code: groupClassResponse.code,
    schoolId: groupClassResponse.school_id,
    classIndex: getIndexClassStudent(groupClassResponse.grade, groupClassResponse.major),
    name: groupClassResponse.name,
    grade: groupClassResponse.grade,
    major: typeMajorSwitch(groupClassResponse.major),
    method_study: methodStudySwitch(groupClassResponse.method_study),
    section: groupClassResponse.section,
    branch: groupClassResponse.branch,
  }
  groupClass.classIndex = getIndexClassStudent(groupClass.grade, groupClassResponse.major)
  return groupClass
}

function mapingGroupClasss(groupClasssResponse: GroupClassResponse[]): GroupClass[] {
  const groupClasss = groupClasssResponse.map((item) => {
    return mapingGroupClass(item)
  })
  return groupClasss
}
