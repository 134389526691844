import {initializeApp} from 'firebase/app'
import {
  DocumentData,
  QueryDocumentSnapshot,
  collection,
  count,
  deleteDoc,
  doc,
  endBefore,
  getCountFromServer,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  startAfter,
  startAt,
  where,
} from 'firebase/firestore'
import ExamProgressDataSource from './ExamProgressDataSource'
import {BaseDatatableRequest} from './request/BaseDatatableRequest'
import {BaseDatatableResponse} from './response/BaseDatatableResponse'
import ResultTryoutDataSource from './ResultTryoutDataSoruce'
import ResultTryoutDataSourceImpl from './ResultTryoutDataSourceImpl'
import {addHours, formatDateRawToYMD} from '../../helpers/FuncHelpers'
// import { Auth, getAuth } from 'firebase/auth';

// const transform = (response: AxiosResponse): Promise<SubjectResponse[]> => {
//   return new Promise((resolve, reject) => {
//     const result: SubjectResponse[] = response.data.data
//     resolve(result)
//   })
// }

var lastDoc: any = null
var startDoc: any = null

export default class ExamProgresstDataSourceImpl implements ExamProgressDataSource {
  db: any
  collection_name: string
  private datasourceResult!: ResultTryoutDataSource
  // lastDoc!: QueryDocumentSnapshot<DocumentData, DocumentData>
  // auth: Auth
  constructor(collection_name: string) {
    const firebaseApp = initializeApp({
      apiKey: 'AIzaSyDrXQM2rigdyaJO-9jMGA_I6drxEWa6jdA',
      authDomain: 'dark-depth-381308.firebaseapp.com',
      projectId: 'dark-depth-381308',
      storageBucket: 'dark-depth-381308.appspot.com',
      messagingSenderId: '764616535885',
      appId: '1:764616535885:web:d323c5daf81f147d3c5104',
      measurementId: 'G-W6KDRGYBLR',
    })
    this.db = getFirestore(firebaseApp)
    // this.auth = getAuth()
    this.collection_name = collection_name
    this.datasourceResult = new ResultTryoutDataSourceImpl()
  }
  async submitAllData(): Promise<void> {
    // Maybe to use sometimes
    // const data = await getDocs(
    //   query(
    //     collection(this.db, this.collection_name),
    //     // where('examProgressData.endDate', '<', 1700438400000),
    //     orderBy('examProgressData', 'desc'),
    //     limit(10)
    //   )
    // )
    // let response: any[] = []
    // data.forEach((doc) => {
    //   response.push({...doc.data(), id: doc.id})
    // })
    // console.log(response)
    // for (const item of response) {
    //   await this.submitData(item.id)
    // }
  }
  async submitData(id: string): Promise<void> {
    const data = await getDoc(doc(this.db, this.collection_name, id))
    if (data.exists()) {
      const req = {...data.data()}
      const id = data.id
      const schedule_id = id.split('_')
      let questionsIds: any = req.examProgressData?.questions?.map((questionItem: any) => ({
        _id: questionItem?._id,
        chapter_id: questionItem?.detail_chapter._id,
        nomor: questionItem?.nomor,
        answer: questionItem?.answer_user,
        not_sure: questionItem?.not_sure,
      }))
      let YMDHISFormatTimeStart = formatDateRawToYMD(
        addHours(new Date(req.examProgressData.startTime), 7).toISOString()
      ).split(' ')

      let YMDHISFormatDateNow = formatDateRawToYMD(addHours(new Date(), 7).toISOString()).split(' ')

      const submitBody = {
        schedule_id: schedule_id.at(1),
        user_id: req.userId,
        questions: questionsIds,
        date_tryout_test: YMDHISFormatTimeStart[0],
        time_start: YMDHISFormatTimeStart[1],
        time_end: YMDHISFormatDateNow[1],
        device: 'by System',
      }
      console.log(submitBody)

      this.datasourceResult
        .submitOneResult(submitBody)
        .then(() => {
          this.deleteOne(id)
        })
        .catch(async (e: any) => {
          if (![404, 500].includes(e.response.status)) {
            await this.deleteOne(id)
            return
          }
          throw new Error('Backend server internal server error')
        })
    } else {
      console.log('Not Found')
    }

    // return data;
  }

  async getCount(): Promise<number> {
    const data = await getCountFromServer(query(collection(this.db, this.collection_name)))
    return data.data().count
  }

  async getSearchDatatable(req: BaseDatatableRequest): Promise<BaseDatatableResponse<any>> {
    const data = await getDocs(
      query(collection(this.db, this.collection_name), where('email', '==', req.filter.search))
    )
    lastDoc = null
    startDoc = null

    let response: any[] = []
    data.forEach((doc) => {
      response.push({...doc.data(), id: doc.id})
    })

    return {
      entities: response,
      errorMessage: '',
      totalCount: 100,
    }
  }
  async getPrevDatatable(req: BaseDatatableRequest): Promise<BaseDatatableResponse<any>> {
    if (startDoc) {
      const data = await getDocs(
        query(
          collection(this.db, this.collection_name),
          orderBy(req.sortField, req.sortOrder === 'asc' ? 'asc' : 'desc'),
          limit(req.pageSize),
          endBefore(startDoc)
        )
      )
      lastDoc = data.docs[data.docs.length - 1]
      startDoc = data.docs[0]

      let response: any[] = []
      data.forEach((doc) => {
        response.push({...doc.data(), id: doc.id})
      })

      return {
        entities: response,
        errorMessage: '',
        totalCount: 100,
      }
    }
    return this.getDatatable(req)
  }
  async getNextDatatable(req: BaseDatatableRequest): Promise<BaseDatatableResponse<any>> {
    if (lastDoc) {
      const data = await getDocs(
        query(
          collection(this.db, this.collection_name),
          orderBy(req.sortField, req.sortOrder === 'asc' ? 'asc' : 'desc'),
          limit(req.pageSize),
          startAfter(lastDoc)
        )
      )
      lastDoc = data.docs[data.docs.length - 1]
      startDoc = data.docs[0]

      let response: any[] = []
      data.forEach((doc) => {
        response.push({...doc.data(), id: doc.id})
      })
      return {
        entities: response,
        errorMessage: '',
        totalCount: 100,
      }
    }

    return this.getDatatable(req)
  }
  async getDatatable(req: BaseDatatableRequest): Promise<BaseDatatableResponse<any>> {
    const data = await getDocs(
      query(
        collection(this.db, this.collection_name),
        orderBy(req.sortField, req.sortOrder === 'asc' ? 'asc' : 'desc'),
        limit(req.pageSize)
      )
    )
    lastDoc = data.docs[data.docs.length - 1]
    startDoc = data.docs[0]

    let response: any[] = []
    data.forEach((doc) => {
      response.push({...doc.data(), id: doc.id})
    })

    return {
      entities: response,
      errorMessage: '',
      totalCount: 100,
    }
    // throw new Error('Method not implemented.')
  }

  async getAll(params?: any): Promise<any[]> {
    const data = await getDocs(
      query(
        collection(this.db, this.collection_name),
        limit(10),
        orderBy('examProgressData', 'desc')
      )
    )
    let response: any[] = []
    data.forEach((doc) => {
      response.push(doc.data())
    })
    console.log(response)

    throw new Error('Method not implemented.')
    // return new Promise<any[]>((resolve, reject) => {
    //   onSnapshot(query(collection(this.db, this.collection_name)), (item) => {
    //     const data = item.docs.map((doc) => doc.data())
    //     console.log(data)
    //     resolve(data)
    //   })
    // })
  }
  getAllTmp(params?: any): any {
    throw new Error('Method not implemented.')
    // return onSnapshot(query(collection(this.db, this.collection_name)), (item) => {
    //   const data = item.docs.map((doc) => doc.data())
    //   return data
    // })
  }
  async getOne(id: string): Promise<any> {
    // const data = await getDoc(query(collection(this.db, this.collection_name), where('email', '==', req.filter.search)));

    //   return data;
    throw new Error('Method not implemented.')
    // const docRef = doc(this.db, 'users', 'nama')
    // const docSnap = await getDoc(docRef)
    // if (docSnap.exists()) {
    //   console.log(docSnap.data())
    // } else {
    //   console.log('Not Found')
    // }
    // return {}
  }
  async deleteOne(id: string): Promise<string> {
    deleteDoc(doc(this.db, this.collection_name, id))
      .then(() => {
        console.log('Success delete')
      })
      .catch((e) => {
        console.log('Fail delete')
      })
    return 'finish'
  }
  create(req: any): Promise<any> {
    throw new Error('Method not implemented.')
  }
  update(req: any, id?: string | number | undefined): Promise<any> {
    throw new Error('Method not implemented.')
  }
}
