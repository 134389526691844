import React, { FC } from 'react'
import StudentProfile from './partial/StudentProfile'
import { StudentAccessUpdateFormInput, StudentAccountUpdateFormInput, StudentUpdateFormInput } from '../../../../../../helpers/UIStudentConstanta'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { updateAccessStudent, updateAccountStudent, updateExtendRoleStudent, updateRoleStudent, updateStudent } from '../StudentAction'
import StudentAccount from './partial/StudentAccount'
import StudentAccess from './partial/StudentAccess'
import { Student } from '../../../../../../_domain/model/Student'
import { UserModel } from '../../../../../../_domain/model/Auth'
import { StudentRole } from '../../../../../../_domain/model/StudentRole'
// import StudentExtendRole from './partial/StudentExtendRole'
import StudentRoleForm from './partial/StudentRole'

type props = {
  entity?: Student
  entityUser?: UserModel
  entityRole?: StudentRole
}
const StudentSetting: FC<props> = ({ entity, entityUser, entityRole }) => {
  const { id } = useParams()
  const dispatch = useDispatch()

  function saveStudentProfile(data: StudentUpdateFormInput) {
    if (id) {
      dispatch<any>(updateStudent(id, data));
    }
  }

  function saveStudentAccess(data: StudentAccessUpdateFormInput) {
    if (id) {
      dispatch<any>(updateAccessStudent(id ? +id : 0, data))
    }
  }

  function saveStudentEmail(data: StudentAccountUpdateFormInput) {
    if (id) {
      dispatch<any>(updateAccountStudent(id, data));
    }
  }

  function saveStudentPhoneNumber(data: StudentAccountUpdateFormInput) {
    if (id) {
      dispatch<any>(updateAccountStudent(id, data));
    }
  }

  function saveStudentPassword(data: StudentAccountUpdateFormInput) {
    if (id) {
      dispatch<any>(updateAccountStudent(id, data));
    }
  }

  function saveStudentExtendRole(data: any) {
    if (id) {
      dispatch<any>(updateExtendRoleStudent(id, data))
    }
  }

  function saveStudentRole(data: any) {
    if (confirm("Are you sure ?")) {
      if (id) {
        if (data.extendRolesInput.findIndex((item: string) => item === "student") === -1) {
          data.extendRolesInput.push("student")
        }
        dispatch<any>(updateRoleStudent(id, data))
      }
    }
  }

  return (
    <>
      {entity && (
        <StudentProfile onSubmit={saveStudentProfile} studentData={entity} />
      )}
      {entityUser && (
        <StudentAccount userData={entityUser} onSubmitEmail={saveStudentEmail} onSubmitPhone={saveStudentPhoneNumber} onSubmitPassword={saveStudentPassword} />
      )}
      {/* {entityUser && (
        <StudentExtendRole userData={entityUser} onSubmit={saveStudentExtendRole} />
      )} */}
      {entityUser && (
        <StudentRoleForm userData={entityUser} onSubmit={saveStudentRole} />
      )}
      {entityRole && (
        <StudentAccess studentRole={entityRole} onSubmit={saveStudentAccess} />
      )}
    </>
  )
}

export default StudentSetting