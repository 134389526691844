import {StudentClassFormInput} from '../../../helpers/UIStudentClassConstanta'

export type StudenClassRequest = {
  student_user_ids: number[]
}

export function mappingInputFormToStudentClassRequest(
  req: StudentClassFormInput
): StudenClassRequest {
  return {
    student_user_ids: req.studentUserInput?.map((item: any) => item.user_id) ?? [],
  }
}
