export const MESSAGE_STATUS_400 = 'Bad Request'
export const MESSAGE_STATUS_404 = 'Not Found'

export enum TYPE_SCHEDULE {
  LIVE_CLASS = 'live_class',
  TRYOUT = 'tryout',
  CONSULTATION = 'consultation',
}

export enum TYPE_CHAPTER {
  umum = 'umum',
  pmq = 'pmq',
  tryout = 'tryout',
}
export enum MAJOR {
  ALL = 'ALL',
  IPA = 'IPA',
  IPS = 'IPS',
}

export enum BANNER_TYPE {
  PROMO = 'Promo',
}

export enum TRYOUT_MAJOR {
  ALL = 'ALL',
  IPA = 'IPA',
  IPS = 'IPS',
}
export enum LEVEL_STUDENT {
  SD = 'SD',
  SMP = 'SMP',
  SMA = 'SMA',
}

export enum TYPE_QUESTION {
  SINGLE = 'single',
  MULTIPLE = 'multiple',
  TRUE_FALSE = 'TF',
  INCOMPLETE = 'incomplete',
}

export enum ROLE_QUESTION {
  POST_TEST = 'post_test',
  BANK_SOAL = 'bank_soal',
  TRYOUT = 'tryout',
}

export enum TARGET_USER {
  ALL_SELECTED = 'all',
  CLASS_SELECTED = 'class',
  ATTENDEE_SELECTED = 'selected',
  GROUP_SELECTED = 'group',
  MIX_SELECTED = 'mix',
  SCHOOL_SELECTED = 'school',
}

export enum GENDER {
  MALE = 'male',
  FEMALE = 'female',
}

export enum CALCULATE_TYPE {
  IRT = 'IRT',
  SIMAK = 'SIMAK',
  NONE = 'none',
}

export enum FACILITY_STATUS {
  ACTIVE = 'active',
  NON_ACTIVE = 'non_active',
}

export enum ORDER_STATUS {
  ACTIVE = 'active',
  NON_ACTIVE = 'non_active',
}

export enum PAYMENT_STATUS {
  PAID = 'paid',
  UNPAID = 'unpaid',
  FAILED = 'failed',
  WAITING = 'waiting',
}

export enum METHOD_STUDY {
  ONLINE = 'online',
  OFFLINE = 'offline',
}

export enum TYPE_PRODUCT {
  MAIN = 'main',
  ADDONS = 'addons',
}

export enum PRODUCT_MODEL {
  PREMIUM = 'premium',
  PMQ = 'pmq',
  FREE = 'free',
}

export enum PRODUCT_STATUS {
  PUBLISH = 'publish',
  DRAFT = 'draft',
}

export enum TYPE_PARENT {
  FATHER = 'father',
  MOTHER = 'mother',
}

export * from './SubjectConstant'
export * from './ChapterConstant'
export * from './QuestionTryoutConstant'
export * from './ResultTryoutConstant'
export * from './ScheduleTryoutConstant'
export * from './SchedulePostTestConstant'
export * from './SchoolConstant'
export * from './SeriesConstant'
export * from './StudentConstant'
export * from './TypeTryoutConstant'
export * from './FacilityConstant'
export * from './ProductConstant'
export * from './OrderConstant'
export * from './BannerConstant'
export * from './TeacherConstant'
export * from './ScheduleLiveClassConstant'
export * from './AuthGroupConstant'
export * from './TeamConstant'
export * from './GroupRoleConstant'
export * from './GroupClassConstant'
export * from './PartnerConstant'
export * from './ParentConstant'
export * from "./StudentClassConstant"
