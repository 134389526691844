/* eslint-disable jsx-a11y/anchor-is-valid */
import {Outlet, Route, Routes} from 'react-router'
import {PageTitle} from '../../../../../_layout/core'
import {ParentList} from './ParentList'
import {
  addStudentDialog,
  createSectionContext,
  deleteSectionContext,
  queryParamsContext,
} from './_outletContext'
import ParentDetail from './ParentDetail'

const ParentContent = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>{'Parent'}</PageTitle>
      <Outlet
        context={{
          queryParams: queryParamsContext(),
          createSection: createSectionContext(),
          deleteSection: deleteSectionContext(),
          addStudentSection: addStudentDialog(),
        }}
      />
    </>
  )
}

const Parent = () => {
  return (
    <>
      <Routes>
        <Route element={<ParentContent />}>
          <Route index element={<ParentList />} />
          {/* <Route path='create' element={<ParentCreate />} /> */}
          <Route path='detail/:id' element={<ParentDetail />} />
        </Route>
      </Routes>
    </>
  )
}

export {Parent}
