import ProductDataSource from '../../_data/dataSource/ProductDataSoruce'
import {BaseDatatableRequest} from '../../_data/dataSource/request/BaseDatatableRequest'
import {
  ProductCreateRequest,
  ProductUpdateRequest,
} from '../../_data/dataSource/request/ProductRequest'
import {BaseDatatableResponse} from '../../_data/dataSource/response/BaseDatatableResponse'
import {Product, ProductBuilder} from '../model/Product'
import {ProductRepository} from './ProductRepository'

export class ProductRepositoryImpl implements ProductRepository {
  datasource: ProductDataSource
  constructor(datasource: ProductDataSource) {
    this.datasource = datasource
  }

  async updateOne(req: ProductUpdateRequest, id?: string): Promise<Product> {
    const response = await this.datasource.update(req, id)
    const initBuilder = new ProductBuilder()
    const product = initBuilder.SingleData(response).Result()
    return product
  }

  async updateImage(id: string, file: any): Promise<void> {
    this.datasource.uploadImage(id, file)
    // const initBuilder = new ProductBuilder()
    // const product = initBuilder.SingleData(response).Result()
    // return product
  }

  async createOne(req: ProductCreateRequest): Promise<Product> {
    const response = await this.datasource.create(req)
    const initBuilder = new ProductBuilder()
    const product = initBuilder.SingleData(response).Result()
    return product
  }

  deleteOneById(id: string): Promise<string> {
    return this.datasource.deleteOne(id)
  }

  async getDatatable(req: BaseDatatableRequest): Promise<BaseDatatableResponse<Product>> {
    const data = await this.datasource.getDatatable(req)
    const initBuilder = new ProductBuilder()
    const entities = initBuilder.ListData(data.entities).ResultList()
    const totalCount = data.totalCount
    return {
      entities,
      totalCount,
      errorMessage: data.errorMessage,
    }
  }

  async getOneById(id: string): Promise<Product> {
    const resposne = await this.datasource.getOne(id)
    const initBuilder = new ProductBuilder()
    const product = initBuilder.SingleData(resposne).Result()
    return product
  }

  async getAll(): Promise<Product[]> {
    const response = await this.datasource.getAll()
    const initBuilder = new ProductBuilder()
    const products = initBuilder.ListData(response).ResultList()
    return products
  }
}
