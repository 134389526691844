/* eslint-disable jsx-a11y/anchor-is-valid */

import {Outlet, Route, Routes} from 'react-router'
import {PageTitle} from '../../../../../_layout/core'
import {ChapterList} from './ChapterList'

// outlet context bank
import {deleteSectionContext, queryParamsContext} from './_outletContext'
import {ChapterCreate} from './ChapterCreate'
import {ChapterEdit} from './ChapterEdit'

const ChapterContent = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>{'Chapter'}</PageTitle>
      <Outlet
        context={{queryParams: queryParamsContext(), deleteSection: deleteSectionContext()}}
      />
    </>
  )
}

const Chapter = () => {
  return (
    <>
      <Routes>
        <Route element={<ChapterContent />}>
          <Route index element={<ChapterList />} />
          <Route path='create' element={<ChapterCreate />} />
          <Route path='edit/:chapterId' element={<ChapterEdit />} />
          {/* <Route path='*' element={<Navigate to='/error/404' />} /> */}
        </Route>
      </Routes>
    </>
  )
}

export {Chapter}
