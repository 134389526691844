import {ProductResponse} from '../../_data/dataSource/response/ProductResponse'
import {methodStudySwitch, productModelSwitch, typeProductSwitch} from '../../helpers/FuncHelpers'
import {METHOD_STUDY, PRODUCT_MODEL, TYPE_PRODUCT} from '../constanta'

export type Product = {
  id: string
  code_product: string
  name: string
  method_study: METHOD_STUDY
  type_product: TYPE_PRODUCT
  class_product: number[]
  schools_ids: number[]
  amount_total: number
  date_active_start: any
  date_active_end: any
  amount_installment: {
    installment: number
    amounts: number[]
    active_status: boolean
  }[]
  commission_percent: number
  group_role_id: string
  group_role_detail?: any
  amount_discount: number
  product_model: PRODUCT_MODEL
  installment_count: number
  description: string
  status: string
  image_url: string
}

export class ProductBuilder {
  private SingleProduct!: Product
  private ProductList!: Product[]
  constructor() {}

  SingleData(productResponse: ProductResponse): ProductBuilder {
    const product = mapingProduct(productResponse)
    this.SingleProduct = product
    return this
  }
  ListData(productResponse: ProductResponse[]): ProductBuilder {
    const products = mapingProducts(productResponse)
    this.ProductList = products
    return this
  }
  Result(): Product {
    return this.SingleProduct
  }
  ResultList(): Product[] {
    return this.ProductList
  }
}

function mapingProduct(productResponse: ProductResponse): Product {
  const product: Product = {
    id: productResponse.id,
    code_product: productResponse.code_product,
    name: productResponse.name,
    method_study: methodStudySwitch(productResponse.method_study),
    type_product: typeProductSwitch(productResponse.type_product),
    class_product: productResponse.class_product,
    schools_ids: productResponse.schools_ids,
    amount_total: productResponse.amount_total,
    date_active_end: productResponse.date_active_end,
    date_active_start: productResponse.date_active_start,
    amount_installment: productResponse.amount_installment,
    amount_discount: productResponse.amount_discount,
    product_model: productModelSwitch(productResponse.product_model),
    installment_count: productResponse.installment_count,
    description: productResponse.description,
    status: productResponse.status,
    group_role_id: productResponse.group_role_id,
    image_url: productResponse.image_url,
    commission_percent: productResponse.commission_percent * 100,
  }
  return product
}

function mapingProducts(productsResponse: ProductResponse[]): Product[] {
  const products = productsResponse.map((item) => {
    return mapingProduct(item)
  })
  return products
}
