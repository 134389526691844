import {MenuItem} from './MenuItem'

export function MenuInner() {
  return (
    <>
      {/* <MenuItem title={'Dashboard'} to='/dashboard' />
      <MenuItem title={'Tryout'} to='/tryout' /> */}
    </>
  )
}
