const PRODUCT_DELETE_TITLE = 'Product Delete'
const PRODUCT_DELETE_LOADING = 'Product is deleting...'
const PRODUCT_DELETE_CONFIRM = 'Are you sure to permanently delete this product ?'
const PRODUCT_DELETE_SUCCESS = 'Successful delete product'
const PRODUCT_DELETE_FAILED = "Can't delete product"
const PRODUCT_CREATE_TITLE = 'Product Create'
const PRODUCT_UPLOAD_BATCH_TITLE = 'Student Upload Batch'
const PRODUCT_CREATE_FAILED = "Can't create product"
const PRODUCT_CREATE_SUCCESS = 'Successful create product'
const PRODUCT_EDIT_TITLE = 'Product Edit'
const PRODUCT_EDIT_SUCCESS = 'Successful update product'
const PRODUCT_EDIT_FAILED = "Can't update product"
const PRODUCT_GET_FAILED = "Can't get product"
export {
  PRODUCT_DELETE_TITLE,
  PRODUCT_UPLOAD_BATCH_TITLE,
  PRODUCT_DELETE_CONFIRM,
  PRODUCT_DELETE_LOADING,
  PRODUCT_DELETE_SUCCESS,
  PRODUCT_DELETE_FAILED,
  PRODUCT_CREATE_TITLE,
  PRODUCT_CREATE_SUCCESS,
  PRODUCT_CREATE_FAILED,
  PRODUCT_EDIT_TITLE,
  PRODUCT_EDIT_SUCCESS,
  PRODUCT_EDIT_FAILED,
  PRODUCT_GET_FAILED,
}
