/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {PageTitle} from '../../../../../_layout/core'
import {getAllLiveClassDatatable, uploadBatchLiveClass} from './LiveClassAction'
import {Suspense, lazy} from 'react'
import CustomLoading from '../../../../../_component/customLoading'
import {useNavigate, useOutletContext} from 'react-router-dom'
import {BaseDatatableResponse} from '../../../../../_data/dataSource/response/BaseDatatableResponse'
import {Schedule} from '../../../../../_domain/model/Schedule'
import LiveClassDelete from './LiveClassDelete'
import {SCHEDULE_LIVE_CLASS_LIST_TITLE} from '../../../../../_domain/constanta'
import {useAuth} from '../../../../../_context/AuthContext'
import {checkAuthActionMenu} from '../../../../../helpers/AuthHelpers'
import {SLUG_EDULIVE_SUB_MENU} from '../../../../../helpers/UIAuthGroupConstanta'
import LiveClassAttendance from './LiveClassAttendance'
import LiveClassSelected from './LiveClassSelected'

const LiveClassListTable = React.lazy(() => import('./LiveClassListComponent/LiveClassListTable'))

const checkReduxData = () => {
  const outletContext = useOutletContext<any>().queryParams
  const {queryParams} = outletContext

  const [liveClassListData, setLiveClassListData] = useState<any>(null)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch<any>(getAllLiveClassDatatable(queryParams)).then(
      (response: BaseDatatableResponse<Schedule>) => {
        setLiveClassListData(response.entities)
      }
    )
  }, [queryParams, getAllLiveClassDatatable])

  return liveClassListData
}

const LiveClassList = () => {
  let data = checkReduxData()
  const outletContext = useOutletContext<any>().queryParams
  const dispatch = useDispatch()

  const {currentUser, authorization} = useAuth()

  const uploadAction = (file: any) => {
    dispatch<any>(uploadBatchLiveClass(file)).then(() => {
      window.location.reload()
    })
  }

  return checkAuthActionMenu(
    SLUG_EDULIVE_SUB_MENU.EDULIVE_CLASS_MENU,
    'view',
    currentUser,
    authorization
  ) ? (
    <>
      <LiveClassDelete />
      <LiveClassAttendance />
      <LiveClassSelected />
      <PageTitle breadcrumbs={[]}>{SCHEDULE_LIVE_CLASS_LIST_TITLE}</PageTitle>
      <Suspense fallback={<CustomLoading />}>
        {data ? <LiveClassListTable onSubmit={uploadAction} /> : null}
      </Suspense>
    </>
  ) : null
}

export {LiveClassList}
