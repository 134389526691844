import React, { useCallback, useState } from 'react'
import { defaultQueryParams, prepareFilterBase } from '../../../../../_data/dataSource/request/BaseDatatableRequest'
import { isEqual, isFunction } from 'lodash'
import useFilterTable from '../../../../../_hooks/useFilterTable'
import useDeleteSelectionDialog from '../../../../../_hooks/useDeleteSelectionDialog'
import useEditSelectionDialog from '../../../../../_hooks/useEditSelectionDialog'

const init = defaultQueryParams()
init.pageSize = 10
init.sortField = "id"
init.sortOrder = "desc"
const initBatch = defaultQueryParams()
initBatch.pageSize = 10
initBatch.sortField = "id"
initBatch.sortOrder = "desc"

export const queryParamsContext = () => {
  const value = useFilterTable({ initQueryParams: init })
  const [queryParamsScore, setQueryParamsScoreBase] = useState({ ...init })
  const [queryParamsBatch, setQueryParamsBatchBase] = useState(initBatch)

  // queryParams, setQueryParams,
  const applyFilterBatch = (
    values: object
  ) => {
    const newQueryParams = prepareFilterBase(queryParamsBatch, values)

    if (!isEqual(newQueryParams, queryParamsBatch)) {
      newQueryParams.pageNumber = 1
      // update list by queryParams
      setQueryParamsBatch(newQueryParams)
    }
  }

  const setQueryParamsScore = useCallback((nextQueryParams: any) => {
    setQueryParamsScoreBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams)
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams
      }

      return nextQueryParams
    })
  }, [])

  const setQueryParamsBatch = useCallback((nextQueryParams: any) => {
    setQueryParamsBatchBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams)
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams
      }

      return nextQueryParams
    })
  }, [])

  const handlePageScoreChange = (page: number, perPage: number) => {
    const tmp = { ...queryParamsScore }
    tmp.pageNumber = page
    setQueryParamsScore(tmp)
  }

  const handlePageBatchChange = (page: number, perPage: number) => {
    const tmp = { ...queryParamsBatch }
    tmp.pageNumber = page
    setQueryParamsBatch(tmp)
  }

  const handleRowPerScorePageChange = (currentRowsPerPage: number, currentPage: number) => {
    const tmp = { ...queryParamsScore }
    tmp.pageSize = currentRowsPerPage
    setQueryParamsScore(tmp)
  }

  const handleRowPerBatchPageChange = (currentRowsPerPage: number, currentPage: number) => {
    const tmp = { ...queryParamsBatch }
    tmp.pageSize = currentRowsPerPage
    setQueryParamsBatch(tmp)
  }

  const handleSortScoreChange = (column: any, sortDirection: any) => {
    const tmp = { ...queryParamsScore }
    tmp.sortOrder = sortDirection
    tmp.sortField = column.id
    setQueryParamsScore(tmp)
  }

  const handleSortBatchChange = (column: any, sortDirection: any) => {
    const tmp = { ...queryParamsBatch }
    tmp.sortOrder = sortDirection
    tmp.sortField = column.id
    setQueryParamsBatch(tmp)
  }

  return {
    ...value,
    applyFilterBatch,
    queryParamsScore,
    queryParamsBatch,
    setQueryParamsScore,
    setQueryParamsBatch,
    handlePageScoreChange,
    handlePageBatchChange,
    handleSortScoreChange,
    handleSortBatchChange,
    handleRowPerScorePageChange,
    handleRowPerBatchPageChange,
  }

}

export const deleteSectionContext = () => {
  const value = useDeleteSelectionDialog()

  return value
}

export const calculateSectionContext = () => {
  const [propsForCalculate, setPropsForCalculate] = useState({
    show: false,
  });

  function showingCalculateModal() {
    setPropsForCalculate({
      show: true
    })
  }

  function closingCalculateModal() {
    setPropsForCalculate({
      show: false
    })
  }

  const value = {
    propsForCalculate,
    setPropsForCalculate,
    showingCalculateModal,
    closingCalculateModal
  }

  return value
}

export const detailSectionContext = () => {
  const [propsForDetail, setPropsForDetail] = useState({
    scheduleId: '',
    userId: '',
    show: false,
  })

  function showingDetailModal(scheduleId: string, userId: string) {
    setPropsForDetail({
      scheduleId,
      userId,
      show: true,
    })
  }

  function closingDetailModal() {
    setPropsForDetail({
      scheduleId: "",
      userId: "",
      show: false,
    })
  }

  return {
    propsForDetail,
    setPropsForDetail,
    showingDetailModal,
    closingDetailModal,
  }
}