import React, { FC, useEffect, useMemo, useRef } from 'react'

import { getAllStudentDatatable, getOneStudent, updateClassStudent } from './StudentAction'
import { useOutletContext } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { StudentUpdateClassFormInput } from '../../../../../helpers/UIStudentConstanta'
import { STUDENT_EDIT_TITLE } from '../../../../../_domain/constanta'
import { ModalBase } from '../../../../../_component/Modal'

const StudentEditFormClass = React.lazy(() =>
  import('./StudentEditComponent/StudentEditFormClass')
)

// const checkReduxData = () => {
//   // detect redux first before fetch API
//   // const [studentDetailData, setStudentDetailData] = useState(null)
//   // const [userStudentDetailData, setUserStudentDetailData] = useState(null)
//   // const dispatch = useDispatch()
//   // let {studentId} = useParams()
//   // let reduxData = getOneReduxData('student', [
//   //   {
//   //     key: 'user_id',
//   //     value: +studentId,
//   //   },
//   // ])

//   // let reduxDataUser = getOneReduxData('userStudent', [
//   //   {
//   //     key: 'user_id',
//   //     value: +studentId,
//   //   },
//   // ])

//   // useEffect(() => {
//   //   if (reduxData.length === 0) {
//   //     // fetch from API if data is not available in the redux
//   //     dispatch(getOneStudent(studentId)).then((response) => {
//   //       setStudentDetailData(response)
//   //     })
//   //   } else {
//   //     // set State if the redux have the specific data
//   //     setStudentDetailData(reduxData[0])
//   //   }

//   //   if (reduxDataUser.length === 0) {
//   //     // fetch from API if data is not available in the redux
//   //     dispatch(getOneUserStudent(studentId)).then((response) => {
//   //       setUserStudentDetailData(response)
//   //     })
//   //   } else {
//   //     // set State if the redux have the specific data
//   //     setUserStudentDetailData(reduxData[0])
//   //   }
//   // }, [studentId, getOneStudent])

//   // return {studentDetailData, userStudentDetailData}
//   // detect redux first before fetch API END
// }

const StudentEdit = () => {
  const editContext = useOutletContext<any>().editSection
  const { propsForEdit, closingEditModal } = useMemo(() => {
    return {
      propsForEdit: editContext.propsForEdit,
      closingEditModal: editContext.closingEditModal
    };
  }, [editContext]);

  const filterContext = useOutletContext<any>().filter
  const { queryParams } = useMemo(() => {
    return {
      queryParams: filterContext.queryParams,
    };
  }, [filterContext]);

  const submitBtnRef = useRef<any>();
  const submitClick = () => {
    if (submitBtnRef && submitBtnRef.current) {
      submitBtnRef.current.click();
    }
  };

  const dispatch = useDispatch();
  const { actionsLoading, entity } = useSelector(
    (state: any) => ({
      actionsLoading: state.student.actionsLoading,
      entity: state.student.entity,
    }),
    shallowEqual
  );

  // if !id we should close modal
  useEffect(() => {
    if (!propsForEdit.id) {
      closingEditModal()
    } else {
      dispatch<any>(getOneStudent(propsForEdit.id))
    }
    return () => {
      dispatch<any>(getOneStudent(undefined))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propsForEdit.id]);

  const saveAction = (data: StudentUpdateClassFormInput) => {
    if (propsForEdit.id) {
      dispatch<any>(updateClassStudent(propsForEdit.id, data)).then(() => {
        // refresh list after deletion
        dispatch<any>(getAllStudentDatatable(queryParams));
        // closing delete modal
        closingEditModal()
      })
    }
  }
  return (
    <ModalBase size='lg' title={STUDENT_EDIT_TITLE} show={propsForEdit.show} onHide={closingEditModal} onAction={submitClick} >
      {entity && (
        <StudentEditFormClass submitBtnRef={submitBtnRef} onSubmit={saveAction} dataStudent={entity} />
      )}
    </ModalBase>
  )
  // let {studentId} = useParams()
  // const location = useLocation()
  // let {studentDetailData, userStudentDetailData} = checkReduxData()
  // console.log(studentDetailData)
  // console.log({userStudentDetailData})

  // return (
  //   <>
  //     <PageTitle breadcrumbs={[]}>{'Student Edit'}</PageTitle>
  //     <div className='d-flex gap-2 mb-4'>
  //       <Link to={`/master/student/edit/${studentId}`}>
  //         <Button
  //           variant={location.pathname === `/master/student/edit/${studentId}` ? 'success' : ''}
  //         >
  //           General
  //         </Button>
  //       </Link>
  //       <Link to={`/master/student/edit/${studentId}/credentials`}>
  //         <Button
  //           variant={
  //             location.pathname === `/master/student/edit/${studentId}/credentials` ? 'success' : ''
  //           }
  //         >
  //           Credentials
  //         </Button>
  //       </Link>
  //       <Link to={`/master/student/edit/${studentId}/class`}>
  //         <Button
  //           variant={
  //             location.pathname === `/master/student/edit/${studentId}/class` ? 'success' : ''
  //           }
  //         >
  //           Class
  //         </Button>
  //       </Link>
  //     </div>

  //     <Suspense fallback={<CustomLoading />}>
  //       <Routes>
  //         <Route
  //           index
  //           element={
  //             studentDetailData ? <StudentEditFormGeneral dataStudent={studentDetailData} /> : null
  //           }
  //         />
  //         <Route
  //           path='credentials'
  //           element={
  //             userStudentDetailData ? (
  //               <StudentEditFormCredential dataUser={userStudentDetailData} />
  //             ) : null
  //           }
  //         />
  //         <Route
  //           path='class'
  //           element={
  //             studentDetailData ? <StudentEditFormClass dataUser={studentDetailData} /> : null
  //           }
  //         />
  //       </Routes>
  //     </Suspense>
  //   </>
  // )
}

export default StudentEdit
