/* eslint-disable jsx-a11y/anchor-is-valid */
import {Outlet, Route, Routes} from 'react-router'
import {PageTitle} from '../../../../../_layout/core'
import {deleteSectionContext, queryParamsContext} from './_outletContext'
import {StudentClassList} from './StudentClassList'
import StudentClassAdd from './StudentClassAdd'

const StudentClassContent = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>{'Student Class'}</PageTitle>
      <Outlet
        context={{queryParams: queryParamsContext(), deleteSection: deleteSectionContext()}}
      />
    </>
  )
}

const StudentClass = () => {
  return (
    <>
      <Routes>
        <Route element={<StudentClassContent />}>
          <Route index element={<StudentClassList />} />
          <Route path='add/:id' element={<StudentClassAdd />} />
        </Route>
      </Routes>
    </>
  )
}

export {StudentClass}
