import TeamDataSource from '../../_data/dataSource/TeamDataSoruce'
import {BaseDatatableRequest} from '../../_data/dataSource/request/BaseDatatableRequest'
import {TeamCreateRequest, TeamUpdateRequest} from '../../_data/dataSource/request/TeamRequest'
import {BaseDatatableResponse} from '../../_data/dataSource/response/BaseDatatableResponse'
import {Team, TeamBuilder} from '../model/Team'
import {TeamRepository} from './TeamRepository'

export class TeamRepositoryImpl implements TeamRepository {
  datasource: TeamDataSource

  constructor(datasource: TeamDataSource) {
    this.datasource = datasource
  }
  async updateAuthGroup(req: any, team_id: string): Promise<void> {
    await this.datasource.updateAuthGroup(req, team_id)
  }

  async updateOne(req: TeamUpdateRequest, id?: string): Promise<Team> {
    const response = await this.datasource.update(req, id)
    const initBuilder = new TeamBuilder()
    const team = initBuilder.SingleData(response).Result()
    return team
  }

  async createOne(req: TeamCreateRequest): Promise<Team> {
    const resposne = await this.datasource.create(req)
    const initBuilder = new TeamBuilder()
    const team = initBuilder.SingleData(resposne).Result()
    return team
  }

  deleteOneById(id: number): Promise<string> {
    return this.datasource.deleteOne(id)
  }

  async getDatatable(req: BaseDatatableRequest): Promise<BaseDatatableResponse<Team>> {
    const data = await this.datasource.getDatatable(req)
    const initBuilder = new TeamBuilder()
    const entities = initBuilder.ListData(data.entities).ResultList()
    const totalCount = data.totalCount
    return {
      entities,
      totalCount,
      errorMessage: data.errorMessage,
    }
  }

  async getOneById(id: number): Promise<Team> {
    const response = await this.datasource.getOne(id)
    const initBuilder = new TeamBuilder()
    const team = initBuilder.SingleData(response).Result()
    return team
  }

  async getOneByUserId(user_id: number, token?: string): Promise<Team> {
    const response = await this.datasource.getOneByUserId(user_id, token)
    const initBuilder = new TeamBuilder()
    const team = initBuilder.SingleData(response).Result()
    return team
  }

  async getAll(params?: any): Promise<Team[]> {
    const response = await this.datasource.getAll()
    const initBuilder = new TeamBuilder()
    const teams = initBuilder.ListData(response).ResultList()
    return teams
  }
}
