import {toast} from 'react-toastify'
import {
  CALCULATE_TYPE,
  FACILITY_STATUS,
  GENDER,
  LEVEL_STUDENT,
  MAJOR,
  MESSAGE_STATUS_400,
  MESSAGE_STATUS_404,
  METHOD_STUDY,
  ORDER_STATUS,
  PAYMENT_STATUS,
  PRODUCT_MODEL,
  PRODUCT_STATUS,
  ROLE_QUESTION,
  TARGET_USER,
  TRYOUT_MAJOR,
  TYPE_CHAPTER,
  TYPE_PARENT,
  TYPE_PRODUCT,
  TYPE_QUESTION,
} from '../_domain/constanta'
import {CLASS_STUDENT} from './UIConstanta'

export const snakeCaseTotitleCase = (s: string) =>
  s.replace(/^_*(.)|_+(.)/g, (s, c, d) => (c ? c.toUpperCase() : ' ' + d.toUpperCase()))

export const alertSuccess = (title: string) => {
  toast.success(title, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  })
}

export const alertError = (title = 'Something went wrong !') => {
  toast.error(title)
}

export const alertWarning = (title = '') => {
  toast.warning(title)
}

export const alertBadRequest = (title = MESSAGE_STATUS_400) => {
  toast.warning(title)
}

export const alertNotFound = (title = MESSAGE_STATUS_404) => {
  toast.warning(title)
}

export function errorHandling(err: any, msgFailed: string) {
  switch (err.status) {
    case 404:
      alertNotFound()
      break
    case 400:
      alertBadRequest()
      break
    case 500:
      alertError(msgFailed)
      break
    default:
      alertError()
      break
  }
}

export function msgValidationError(errType: string, fieldName: string) {
  switch (errType) {
    case 'required':
      return `${fieldName} is required`
    case 'maxLength':
      return `${fieldName} max length is not match`
    case 'minLength':
      return `${fieldName} min length is not match`
    case 'max':
      return `${fieldName} max length is not match`
    default:
      break
  }
}

export function typeChapterSwitch(val: string) {
  switch (val) {
    case 'pmq':
      return TYPE_CHAPTER.pmq
    case 'umum':
      return TYPE_CHAPTER.umum
    case 'tryout':
      return TYPE_CHAPTER.tryout
    default:
      return TYPE_CHAPTER.umum
  }
}

export function typeMajorSwitch(val: string) {
  switch (val) {
    case 'IPA':
      return MAJOR.IPA
    case 'IPS':
      return MAJOR.IPS
    case 'ALL':
      return MAJOR.ALL
    default:
      return MAJOR.ALL
  }
}

export function typeTryoutMajorSwitch(val: string) {
  switch (val) {
    case 'IPA':
      return TRYOUT_MAJOR.IPA
    case 'IPS':
      return TRYOUT_MAJOR.IPS
    case 'ALL':
      return TRYOUT_MAJOR.ALL
    default:
      return TRYOUT_MAJOR.ALL
  }
}

export function typeLevelSwitch(val: string) {
  switch (val) {
    case 'SD':
      return LEVEL_STUDENT.SD
    case 'SMP':
      return LEVEL_STUDENT.SMP
    case 'SMA':
      return LEVEL_STUDENT.SMA
    default:
      return LEVEL_STUDENT.SMA
  }
}

export function typeQuestionSwitch(val: string) {
  switch (val) {
    case 'single':
      return TYPE_QUESTION.SINGLE
    case 'multiple':
      return TYPE_QUESTION.MULTIPLE
    case 'TF':
      return TYPE_QUESTION.TRUE_FALSE
    case 'incomplete':
      return TYPE_QUESTION.INCOMPLETE
    default:
      return TYPE_QUESTION.SINGLE
  }
}

export function roleQuestionSwitch(val: string) {
  switch (val) {
    case 'post_test':
      return ROLE_QUESTION.POST_TEST
    case 'bank_soal':
      return ROLE_QUESTION.BANK_SOAL
    case 'tryout':
      return ROLE_QUESTION.TRYOUT
    default:
      return ROLE_QUESTION.TRYOUT
  }
}

export function targetUserSwitch(val: string) {
  switch (val) {
    case 'all':
      return TARGET_USER.ALL_SELECTED
    case 'class':
      return TARGET_USER.CLASS_SELECTED
    case 'mix':
      return TARGET_USER.MIX_SELECTED
    case 'school':
      return TARGET_USER.SCHOOL_SELECTED
    case 'selected':
      return TARGET_USER.ATTENDEE_SELECTED
    case 'group':
      return TARGET_USER.GROUP_SELECTED
    default:
      return TARGET_USER.CLASS_SELECTED
  }
}

export function genderSwitch(val: string) {
  switch (val) {
    case 'male':
      return GENDER.MALE
    case 'female':
      return GENDER.FEMALE
    default:
      return GENDER.MALE
  }
}

export function getIndexClassStudent(grade: number, major: string) {
  const class_index = CLASS_STUDENT.findIndex(
    (item, index) => (item.grade === 'ALUMNI' ? 13 : +item.grade) === grade && item.major === major
  )
  return class_index
}

export function calculateTypeSwitch(val: string) {
  switch (val) {
    case 'IRT':
      return CALCULATE_TYPE.IRT
    case 'SIMAK':
      return CALCULATE_TYPE.SIMAK
    case 'none':
      return CALCULATE_TYPE.NONE
    default:
      return CALCULATE_TYPE.IRT
  }
}

export function facilityStatusSwitch(val: string) {
  switch (val) {
    case 'active':
      return FACILITY_STATUS.ACTIVE
    case 'non_active':
      return FACILITY_STATUS.NON_ACTIVE
    default:
      return FACILITY_STATUS.NON_ACTIVE
  }
}

export function orderStatusSwitch(val: string) {
  switch (val) {
    case 'active':
      return ORDER_STATUS.ACTIVE
    case 'non_active':
      return ORDER_STATUS.NON_ACTIVE
    default:
      return ORDER_STATUS.NON_ACTIVE
  }
}
export function paymentStatusSwitch(val: string) {
  switch (val) {
    case 'paid':
      return PAYMENT_STATUS.PAID
    case 'unpaid':
      return PAYMENT_STATUS.UNPAID
    case 'failed':
      return PAYMENT_STATUS.FAILED
    case 'waiting':
      return PAYMENT_STATUS.WAITING
    default:
      return PAYMENT_STATUS.UNPAID
  }
}

export function productStatusSwitch(val: string) {
  switch (val) {
    case 'publish':
      return PRODUCT_STATUS.PUBLISH
    case 'draft':
      return PRODUCT_STATUS.DRAFT
    default:
      return PRODUCT_STATUS.PUBLISH
  }
}

export function methodStudySwitch(val: string) {
  switch (val) {
    case 'online':
      return METHOD_STUDY.ONLINE
    case 'offline':
      return METHOD_STUDY.OFFLINE
    default:
      return METHOD_STUDY.ONLINE
  }
}

export function typeProductSwitch(val: string) {
  switch (val) {
    case 'main':
      return TYPE_PRODUCT.MAIN
    case 'addons':
      return TYPE_PRODUCT.ADDONS
    default:
      return TYPE_PRODUCT.MAIN
  }
}

export function productModelSwitch(val: string) {
  switch (val) {
    case 'premium':
      return PRODUCT_MODEL.PREMIUM
    case 'pmq':
      return PRODUCT_MODEL.PMQ
    case 'free':
      return PRODUCT_MODEL.FREE
    default:
      return PRODUCT_MODEL.PREMIUM
  }
}

export function parentTypeSwitch(val: string) {
  switch (val) {
    case 'mother':
      return TYPE_PARENT.MOTHER
    case 'father':
      return TYPE_PARENT.FATHER
    default:
      return TYPE_PARENT.MOTHER
  }
}

export function formatCurrency(value: any): string {
  const formattedInput = value.replace(/\D/g, '')

  // Mengubah input menjadi format mata uang Rupiah
  const result = formatNumber(formattedInput)

  return result
}

function formatNumber(number: any): string {
  // Mengonversi angka menjadi format mata uang Rupiah
  const formattedNumber = Number(number).toLocaleString('id-ID', {
    style: 'currency',
    currency: 'IDR',
  })
  return formattedNumber
    .substring(0, formattedNumber.length - 3)
    .replace('Rp', '')
    .trimStart()
}

export function validationNotNolForm(value: string) {
  return value !== '0'
}

export function dateFormatString(val: string): string {
  const date = new Date(val)
  let year = new Intl.DateTimeFormat('en', {year: 'numeric'}).format(date)
  let month = new Intl.DateTimeFormat('en', {month: '2-digit'}).format(date)
  let day = new Intl.DateTimeFormat('en', {day: '2-digit'}).format(date)
  return `${year}-${month}-${day}`
}

export function dateFormatStringV2(val: string | number): string {
  if (val) {
    const date = new Date(val)
    let year = new Intl.DateTimeFormat('en', {year: 'numeric'}).format(date)
    let month = new Intl.DateTimeFormat('en', {month: 'long'}).format(date)
    let day = new Intl.DateTimeFormat('en', {day: '2-digit'}).format(date)
    return `${day}-${month}-${year}`
  }
  return '-'
}
export function timeFormatStringV2(val: string | number): string {
  if (val) {
    const date = new Date(val)
    let minute = new Intl.DateTimeFormat('en', {minute: '2-digit'}).format(date)
    let hour = new Intl.DateTimeFormat('en', {hour: 'numeric', hourCycle: 'h24'}).format(date)
    return `${hour}:${minute}`
  }
  return '-'
}

export function checkingImageUpload(file: any): boolean {
  if (file) {
    var type = file.type
    var typeSplit = type.split('/')
    if (typeSplit[0] != 'image') {
      alertWarning('File format is not must be image')
      return false
    }
    if (file.size >= 2048000) {
      alertWarning('File must be lower than 2MB')
      return false
    }
  }
  return true
}

export function currencyToNumberFormat(currency: string): number {
  const currencyArr = currency.trim().split('.')
  let currencyResult = ''
  currencyArr.map((item) => {
    currencyResult += item
  })
  return +currencyResult
}

export function epochToDateFormatString(val: number): string {
  if (val) {
    const date = new Date(val * 1000)
    let year = new Intl.DateTimeFormat('en', {year: 'numeric'}).format(date)
    let month = new Intl.DateTimeFormat('en', {month: 'long'}).format(date)
    let day = new Intl.DateTimeFormat('en', {day: '2-digit'}).format(date)
    return `${day}-${month}-${year}`
  }
  return '-'
}

export function epochToDateFormatStringV2(val: number): string {
  if (val) {
    const date = new Date(val * 1000)
    let year = new Intl.DateTimeFormat('en', {year: 'numeric'}).format(date)
    let month = new Intl.DateTimeFormat('en', {month: '2-digit'}).format(date)
    let day = new Intl.DateTimeFormat('en', {day: '2-digit'}).format(date)
    return `${year}-${month}-${day}`
  }
  return '-'
}

export function epochToTimeFormatString(val: number): string {
  if (val) {
    const date = new Date(val * 1000)
    let minute = new Intl.DateTimeFormat('en', {minute: '2-digit'}).format(date)
    if (+minute < 10) {
      minute = '0' + minute
    }
    let hour = new Intl.DateTimeFormat('en', {hour: 'numeric', hourCycle: 'h24'}).format(date)
    if (+hour === 24) {
      hour = '00'
    }
    return `${hour}:${minute}`
  }
  return '-'
}

export function epochToTimeFormatStringDashboard(val: number): string {
  if (val) {
    const date = new Date(val)
    let year = new Intl.DateTimeFormat('en', {year: 'numeric'}).format(date)
    let month = new Intl.DateTimeFormat('en', {month: '2-digit'}).format(date)
    let day = new Intl.DateTimeFormat('en', {day: '2-digit'}).format(date)

    let minute = new Intl.DateTimeFormat('en', {minute: '2-digit'}).format(date)
    if (+minute < 10) {
      minute = '0' + minute
    }
    let hour = new Intl.DateTimeFormat('en', {hour: 'numeric', hourCycle: 'h24'}).format(date)
    return `${day}-${month}-${year} ${hour}:${minute}`
  }
  return '-'
}

export function formatDateRawToYMD(dateRaw: any) {
  return dateRaw.replace(/T/, ' ').replace(/\..+/, '').replace('00:00:00', '')
}

export function addHours(date: any, hours: any) {
  // 👇 Make copy with "Date" constructor.
  const dateCopy = new Date(date)

  dateCopy.setHours(dateCopy.getHours() + hours)

  return dateCopy
}
