/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { PageTitle } from '../../../../../_layout/core'
import { getAllAuthGroupDatatable } from './AuthGroupAction'
import { Suspense, lazy } from 'react'
import CustomLoading from '../../../../../_component/customLoading'
import { useOutletContext } from 'react-router-dom'
import { BaseDatatableResponse } from '../../../../../_data/dataSource/response/BaseDatatableResponse'
import { AuthGroup } from '../../../../../_domain/model/AuthGroup'
import AuthGroupListTable from './AuthGroupListComponent/AuthGroupListTable'
import { checkAuthActionMenu } from '../../../../../helpers/AuthHelpers'
import { SLUG_MASTER_SUB_MENU } from '../../../../../helpers/UIAuthGroupConstanta'
import { useAuth } from '../../../../../_context/AuthContext'


const checkReduxData = () => {
  const outletContext = useOutletContext<any>().queryParams
  const { queryParams } = outletContext

  const [authGroupListData, setAuthGroupListData] = useState<any>(null)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch<any>(getAllAuthGroupDatatable(queryParams)).then((response: BaseDatatableResponse<AuthGroup>) => {
      setAuthGroupListData(response.entities)
    })
  }, [queryParams, getAllAuthGroupDatatable])

  return authGroupListData
  // detect redux first before fetch API END
}

const AuthGroupList = () => {
  let data = checkReduxData()
  const { currentUser, authorization } = useAuth()

  return checkAuthActionMenu(SLUG_MASTER_SUB_MENU.MASTER_AUTH_GROUP_MENU, "view", currentUser, authorization) ? (
    <>
      {/* <SubjectDelete />
      <SubjectCreate />
      <SubjectEdit /> */}
      <PageTitle breadcrumbs={[]}>{'Subject List'}</PageTitle>
      <Suspense fallback={<CustomLoading />}>
        {data ? <AuthGroupListTable /> : null}
      </Suspense>
    </>
  ) : null
}

export { AuthGroupList }
