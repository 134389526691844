import axios from 'axios'
import CityDataSource from './CityDataSoruce'
import {BaseDatatableRequest} from './request/BaseDatatableRequest'
import {BaseDatatableResponse} from './response/BaseDatatableResponse'
import {CityResponse} from './response/CityResponse'
import {ApiResponse} from './response/BaseResponse'
import {CityGetAllParams} from './request/CityRequest'

export default class CityDataSourceImpl implements CityDataSource {
  private CITY_URL: string
  constructor() {
    const service_student_url = process.env.REACT_APP_STUDENT_SERVICE_URL || 'api/'
    this.CITY_URL = `${service_student_url}cities`
  }
  getDatatable(req: BaseDatatableRequest): Promise<BaseDatatableResponse<CityResponse>> {
    throw new Error('Method not implemented.')
  }
  async getAll(params?: CityGetAllParams): Promise<CityResponse[]> {
    if (params && params.province_id) {
      const response = await axios.get<ApiResponse<CityResponse[]>>(`${this.CITY_URL}`, {
        params: params,
      })
      const data = response.data.data
      return data
    } else {
      return []
    }
  }
  getOne(id: string | number): Promise<CityResponse> {
    throw new Error('Method not implemented.')
  }
  deleteOne(id: string | number): Promise<string> {
    throw new Error('Method not implemented.')
  }
  create(req: any): Promise<CityResponse> {
    throw new Error('Method not implemented.')
  }
  update(req: any, id: string | number): Promise<CityResponse> {
    throw new Error('Method not implemented.')
  }
}
