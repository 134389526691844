import React, { FC } from 'react'
import { Alert, Form } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { StudentUploadFormInput } from '../../../../../../helpers/UIStudentConstanta'
import { msgValidationError } from '../../../../../../helpers/FuncHelpers'

type Props = {
  submitBtnRef: any
  onSubmit: (req: any) => void
}

const StudentUploadBatchFrom: FC<Props> = ({ onSubmit, submitBtnRef }) => {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<StudentUploadFormInput>({
    mode: "onChange",
  })
  return (
    <>
      <Alert variant="warning">
        <Alert.Heading>Attention!</Alert.Heading>
        <p className='text-dark'>
          Please Download the template first before uploading the batch file.
          After downloading the template then entering data into the template
          file, please save the file in CSV file format. System only read file
          that is format CSV file.
        </p>
      </Alert>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {/* Name Field */}
        <Controller
          name="fileInput"
          defaultValue=''
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <>
              <Form.Group className="mb-3">
                <Form.Label>Upload File CSV</Form.Label>
                <Form.Control {...field} type="file" accept='.csv' onChange={(e: any) => {
                  field.onChange(e)
                  const file = e.target.files[0];
                  setValue("file", file)
                }} isInvalid={errors.fileInput ? true : false} />
                {errors.fileInput && (
                  <Form.Control.Feedback type='invalid'>
                    {msgValidationError(errors.fileInput.type, 'File')}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </>
          )} />
        <div className='mt-4 d-flex justify-content-center'>
          <div className="btn-group" role="group">
            <button type="button" className="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
              Download Template
            </button>
            <ul className="dropdown-menu">
              <li><a className="dropdown-item" href="/media/template/Template_Import_Daftar_Siswa_Tryout_Trial.xlsx">Excel Format</a></li>
              <li><a className="dropdown-item" href="/media/template/Template_Import_Daftar_Siswa_Tryout_Trial.csv">CSV Format</a></li>
            </ul>
          </div>
        </div>
        <div className='mt-4 d-flex justify-content-center d-none'>
          <button ref={submitBtnRef} className='btn btn-primary' type='submit'>
            Submit
          </button>
        </div>
      </Form>
    </>
  )
}

export default StudentUploadBatchFrom