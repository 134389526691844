import axios from 'axios'
import ProductDataSource from './ProductDataSoruce'
import {ProductResponse} from './response/ProductResponse'
import {ApiResponse} from './response/BaseResponse'
import {BaseDatatableRequest} from './request/BaseDatatableRequest'
import {BaseDatatableResponse} from './response/BaseDatatableResponse'
import {MessageResponse} from './response/MessageResponse'
import {ProductCreateRequest, ProductUpdateRequest} from './request/ProductRequest'

export default class ProductDataSourceImpl implements ProductDataSource {
  private PRODUCTS_URL: string
  constructor() {
    const service_product_url = process.env.REACT_APP_PRODUCT_SERVICE_URL || 'api/'
    this.PRODUCTS_URL = `${service_product_url}products`
  }

  async update(req: ProductUpdateRequest, id: string): Promise<ProductResponse> {
    const response = await axios.put<ApiResponse<ProductResponse>>(
      `${this.PRODUCTS_URL}/${id}`,
      req
    )

    const data = response.data.data
    return data
  }

  async deleteOne(id: string): Promise<string> {
    const response = await axios.delete<MessageResponse>(`${this.PRODUCTS_URL}/${id}`)
    return response.data.message
  }

  async getDatatable(req: BaseDatatableRequest): Promise<BaseDatatableResponse<ProductResponse>> {
    const response = await axios.post<ApiResponse<BaseDatatableResponse<ProductResponse>>>(
      `${this.PRODUCTS_URL}/datatable`,
      req
    )

    const data = response.data.data

    return data
  }

  async getOne(id: string): Promise<ProductResponse> {
    const response = await axios.get<ApiResponse<ProductResponse>>(`${this.PRODUCTS_URL}/${id}`)
    const data = response.data.data
    return data
  }

  async getAll(): Promise<ProductResponse[]> {
    const response = await axios.get<ApiResponse<ProductResponse[]>>(`${this.PRODUCTS_URL}`)
    const data = response.data.data
    return data
  }

  async create(req: ProductCreateRequest): Promise<ProductResponse> {
    const response = await axios.post<ApiResponse<ProductResponse>>(`${this.PRODUCTS_URL}/`, req)
    const data = response.data.data

    return data
  }

  async uploadImage(id: string, file: any): Promise<void> {
    const payload = new FormData()
    payload.append('image', file)
    axios.put<ApiResponse<void>>(`${this.PRODUCTS_URL}/image/${id}`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    // return response.data.data
  }
}
