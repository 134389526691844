import { ParentResponse } from '../../_data/dataSource/response/ParentResponse'

export type Parent = ParentResponse

export class ParentBuilder {
  private SingleParent!: Parent
  private ParentList!: Parent[]
  constructor() {}

  SingleData(parentResponse: ParentResponse): ParentBuilder {
    const parent = mapingParent(parentResponse)
    this.SingleParent = parent
    return this
  }
  ListData(parentResponse: ParentResponse[]): ParentBuilder {
    const parents = mapingParents(parentResponse)
    this.ParentList = parents
    return this
  }
  Result(): Parent {
    return this.SingleParent
  }
  ResultList(): Parent[] {
    return this.ParentList
  }
}

function mapingParent(parentResponse: ParentResponse): Parent {
  const parent: Parent = parentResponse
  return parent
}

function mapingParents(parentsResponse: ParentResponse[]): Parent[] {
  const parents = parentsResponse.map((item) => {
    return mapingParent(item)
  })
  return parents
}
