import { useOutletContext } from "react-router-dom"
import { STUDENT_RESET_CONFIRM, STUDENT_RESET_LOADING, STUDENT_RESET_TITLE } from "../../../../../_domain/constanta"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { useEffect, useMemo } from "react"
import { ModalConfirm } from "../../../../../_component/Modal/ModalConfirm"
import { getAllStudentDatatable, resetPasswordStudent } from "./StudentAction"

const StudentResetPassword = () => {
    const resetSectionContext = useOutletContext<any>().selectedIds
    const { closingResetModal, propsForReset } = useMemo(() => {
        return {
            closingResetModal: resetSectionContext.closingResetModal,
            propsForReset: resetSectionContext.propsForReset
        };
    }, [resetSectionContext]);

    const outletContext = useOutletContext<any>().filter
    const { queryParams } = useMemo(() => {
        return {
            queryParams: outletContext.queryParams,
        };
    }, [outletContext]);

    // Schedule Custom Redux state
    const dispatch = useDispatch();
    const { isLoading } = useSelector(
        (state: any) => ({ isLoading: state.student.actionsLoading }),
        shallowEqual
    );

    const resetAction = () => {
        try {
            dispatch<any>(resetPasswordStudent(propsForReset.ids)).then(() => {
                dispatch<any>(getAllStudentDatatable(queryParams));
                closingResetModal()
            });
        } catch (error) {
            closingResetModal();
        }
    };

    return (
        <ModalConfirm title={STUDENT_RESET_TITLE} confirmText={STUDENT_RESET_CONFIRM} loadingText={STUDENT_RESET_LOADING} show={propsForReset.show} onHide={closingResetModal} isLoading={isLoading} submitAction={resetAction} />
    )
}

export default StudentResetPassword