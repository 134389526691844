import {getAuth, removeAuth} from '../helpers/AuthHelpers'

export default function setupAxios(axios: any) {
  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    (config: {headers: {Authorization: string}}) => {
      const auth = getAuth()

      if (auth && auth.access_token) {
        config.headers.Authorization = `Bearer ${auth.access_token}`
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )

  axios.interceptors.response.use(
    (response: any) => response,
    (error: any) => {
      // Reject promise if usual error
      if (error.response.status !== 401) {
        return Promise.reject(error)
      }

      if (error.response.status === 401) {
        removeAuth()
        window.location.href = '/'
      }
    }
  )
}
