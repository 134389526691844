import {BaseDatatableRequest} from '../../_data/dataSource/request/BaseDatatableRequest'
import {mappingInputFormToStudentAccessUpdateRequest, mappingInputFormToStudentUpdateClassRequest, mappingInputFormToStudentUpdateRequest} from '../../_data/dataSource/request/StudentRequest'
import {BaseDatatableResponse} from '../../_data/dataSource/response/BaseDatatableResponse'
import {UploadBatchResponse} from '../../_data/dataSource/response/StudentResponse'
import {getIndexClassStudent} from '../../helpers/FuncHelpers'
import {StudentAccessUpdateFormInput, StudentUpdateClassFormInput, StudentUpdateFormInput} from '../../helpers/UIStudentConstanta'
import {Student} from '../model/Student'
import { StudentProdi } from '../model/StudentProdi'
import {StudentRepository} from '../repository/StudentRepository'

export interface StudentService {
  getDatatableStudent(req: BaseDatatableRequest): Promise<BaseDatatableResponse<Student>>
  uploadBatchStudent(file: any): Promise<UploadBatchResponse>
  getDetailStudentByUserId(userId: string): Promise<Student>
  updateclassStudent(userId: string, req: StudentUpdateClassFormInput): Promise<Student>
  updateOneStudent(userId: string | number, req: StudentUpdateFormInput): Promise<Student>
  updateAccessStudent(userId: string | number, req: StudentAccessUpdateFormInput): Promise<Student>
}

export class StudentServiceImpl implements StudentService {
  private repo: StudentRepository
  constructor(repo: StudentRepository) {
    this.repo = repo
  }
  
  async updateAccessStudent(userId: string | number, req: StudentAccessUpdateFormInput): Promise<Student> {
    const body = mappingInputFormToStudentAccessUpdateRequest(req)
    const student = await this.repo.updateAccess(body, userId)
    return student
  }

  async updateOneStudent(userId: string | number, req: StudentUpdateFormInput): Promise<Student> {
    const body = mappingInputFormToStudentUpdateRequest(req)
    const student = await this.repo.updateOne(body, userId)
    return student
  }

  async updateclassStudent(userId: string, req: StudentUpdateClassFormInput): Promise<Student> {
    const body = mappingInputFormToStudentUpdateClassRequest(req)
    const student = await this.repo.updateClass(userId, body)
    return student
  }
  async getDetailStudentByUserId(userId: string): Promise<Student> {
    const student = await this.repo.getOneByUserId(userId)
    const class_index = getIndexClassStudent(student.grade, student.major)
    student.class_index = class_index
    return student
  }
  async uploadBatchStudent(file: any): Promise<UploadBatchResponse> {
    return await this.repo.uploadBatch(file)
  }

  async getDatatableStudent(req: BaseDatatableRequest): Promise<BaseDatatableResponse<Student>> {
    const students = await this.repo.getDatatable(req)
    return students
  }

  async getProdiByUserId(user_id: string|number): Promise<StudentProdi[]> {
    const prodis = await this.repo.getProdi(user_id)
    return prodis
  }
}
