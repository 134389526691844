import {order} from '../../../../../_redux_slice'
import {OrderRepositoryImpl} from '../../../../../_domain/repository/OrderRepositoryImpl'
import OrderDataSourceImpl from '../../../../../_data/dataSource/OrderDataSourceImpl'
import {OrderServiceImpl} from '../../../../../_domain/service/OrderService'
import {BaseDatatableRequest} from '../../../../../_data/dataSource/request/BaseDatatableRequest'
import {
  ORDER_CANCEL_FAILED,
  ORDER_CANCEL_SUCCESS,
  ORDER_CREATE_FAILED,
  ORDER_CREATE_SUCCESS,
  ORDER_DELETE_FAILED,
  ORDER_DELETE_SUCCESS,
  ORDER_EDIT_FAILED,
  ORDER_EDIT_SUCCESS,
  ORDER_GET_FAILED,
  SUBJECT_GET_FAILED,
} from '../../../../../_domain/constanta'
import {alertError, alertSuccess, errorHandling} from '../../../../../helpers/FuncHelpers'
import {AlertPromise} from '../../../../../helpers/classHelpers'
import {
  OrderCreateFormInput,
  OrderEditFormInput,
  OrderEditInstallmentFormInput,
  OrderEditStatusInstallmentFormInput,
} from '../../../../../helpers/UIOrderConstanta'

const {actions} = order.slice
// const controlAction = control.slice.actions
const callTypes = order.callTypes
const dataSource = new OrderDataSourceImpl()
const repository = new OrderRepositoryImpl(dataSource)
const service = new OrderServiceImpl(repository)

const alertPromise = new AlertPromise()
export const getAllOrderDatatable = (queryParams: BaseDatatableRequest) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.list}))

  return service
    .getDatatableOrder(queryParams)
    .then((response) => {
      const {totalCount, entities} = response
      dispatch(actions.saveList({totalCount, entities}))

      alertPromise.success()

      return response
    })
    .catch((err) => {
      if (err.response) {
        errorHandling(err.response, SUBJECT_GET_FAILED)
      } else {
        alertPromise.error()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const getOneOrder = (id?: string) => (dispatch: any) => {
  if (!id) {
    return dispatch(actions.saveObject({data: undefined}))
  }

  dispatch(actions.startCall({callType: callTypes.action}))
  return service
    .getOneOrder(id)
    .then((response) => {
      const order = response
      dispatch(actions.saveObject({obj: order}))
      return order
    })
    .catch((err) => {
      if (err.response) {
        errorHandling(err.response, ORDER_GET_FAILED)
      } else {
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const createOrder = (req: OrderCreateFormInput) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.action}))
  return service
    .createOneOrder(req)
    .then((response) => {
      alertPromise.stop()
      const order = response
      dispatch(actions.createObject({data: order}))
      alertSuccess(ORDER_CREATE_SUCCESS)
    })
    .catch((err) => {
      console.log(err)

      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, ORDER_CREATE_FAILED)
      } else {
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const updateOrder = (req: OrderEditFormInput, id: string) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.action}))
  return service
    .updateOneOrder(req, id)
    .then((response) => {
      alertPromise.stop()
      const order = response
      dispatch(actions.updateObject({data: order}))
      alertSuccess(ORDER_EDIT_SUCCESS)
    })
    .catch((err) => {
      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, ORDER_EDIT_FAILED)
      } else {
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const editInstallmentOrder =
  (req: OrderEditInstallmentFormInput, order_id: string) => (dispatch: any) => {
    alertPromise.start()
    dispatch(actions.startCall({callType: callTypes.action}))
    return service
      .editInstallmentOrder(req, order_id)
      .then((response) => {
        alertPromise.stop()
        const order = response
        dispatch(actions.updateObject({data: order}))
        alertSuccess(ORDER_EDIT_SUCCESS)
      })
      .catch((err) => {
        alertPromise.stop()
        if (err.response) {
          errorHandling(err.response, ORDER_EDIT_FAILED)
        } else {
          alertError()
        }
        dispatch(actions.catchError({err, callType: callTypes.action}))
      })
  }

export const editStatusInstallmentOrder =
  (req: OrderEditStatusInstallmentFormInput, order_id: string) => (dispatch: any) => {
    alertPromise.start()
    dispatch(actions.startCall({callType: callTypes.action}))
    return service
      .editStatusInstallmentOrder(req, order_id)
      .then((response) => {
        alertPromise.stop()
        const order = response
        dispatch(actions.updateObject({data: order}))
        alertSuccess(ORDER_EDIT_SUCCESS)
      })
      .catch((err) => {
        alertPromise.stop()
        if (err.response) {
          errorHandling(err.response, ORDER_EDIT_FAILED)
        } else {
          alertError()
        }
        dispatch(actions.catchError({err, callType: callTypes.action}))
      })
  }

export const deleteInstallmentOrder = (id: string, order_id: string) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.action}))
  return service
    .deleteInstallmentOrder(id, order_id)
    .then((response) => {
      alertPromise.stop()
      const order = response
      dispatch(actions.updateObject({data: order}))
      alertSuccess(ORDER_DELETE_SUCCESS)
    })
    .catch((err) => {
      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, ORDER_DELETE_FAILED)
      } else {
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const cancelInstallmentOrder = (id: string, order_id: string) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.action}))
  return service
    .cancelInstallmentOrder(id, order_id)
    .then((response) => {
      alertPromise.stop()
      const order = response
      dispatch(actions.updateObject({data: order}))
      alertSuccess(ORDER_CANCEL_SUCCESS)
    })
    .catch((err) => {
      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, ORDER_CANCEL_FAILED)
      } else {
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const deleteOneOrder = (id: string) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.action}))
  return service
    .deleteOneOrderByID(id)
    .then(() => {
      alertPromise.stop()
      alertSuccess(ORDER_DELETE_SUCCESS)
      dispatch(actions.delete({id}))
    })
    .catch((err) => {
      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, ORDER_DELETE_FAILED)
      } else {
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

// export const deleteBatchOrder = (ids) => (dispatch) => {
//   dispatch(controlAction.setLoading(true))
//   return orderAPI
//     .deleteMany(ids)
//     .then(() => {
//       alertSuccess('Successful delete order')
//       dispatch(actions.deleteMany({ids}))
//       dispatch(controlAction.setLoading(false))
//     })
//     .catch((error) => {
//       error.clientMessage = "Can't delete orders"
//       alertError(error.clientMessage)
//       dispatch(actions.catchError({error, callType: callTypes.action}))
//       dispatch(controlAction.setLoading(false))
//     })
// }
