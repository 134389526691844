import {useEffect, useState} from 'react'
import FacilityDataSourceImpl from '../_data/dataSource/FacilityDataSourceImpl'
import {Facility} from '../_domain/model/Facility'
import { FacilityRepositoryImpl } from '../_domain/repository/FacilityRepositoryImpl'

const useFacility = () => {
  const [list, setList] = useState<Facility[]>([])

  const dataSource = new FacilityDataSourceImpl()
  const repo = new FacilityRepositoryImpl(dataSource)

  async function getAllFacility() {
    const response = await repo.getAll()
    setList(response)
  }

  useEffect(() => {
    getAllFacility()
  }, [])

  return {
    listFacility: list,
    getAllFacility,
  }
}

export default useFacility
