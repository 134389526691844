import React, { useCallback, useState } from 'react'
// import { prepareFilterDefault } from '../../../../../helpers/ShareVar'
import { isEqual, isFunction } from 'lodash'
import { defaultQueryParams, prepareFilterBase } from '../../../../../_data/dataSource/request/BaseDatatableRequest'
import useFilterTable from '../../../../../_hooks/useFilterTable'
import useDeleteSelectionDialog from '../../../../../_hooks/useDeleteSelectionDialog'
import useCreateDialog from '../../../../../_hooks/useCreateDialog'
import useEditSelectionDialog from '../../../../../_hooks/useEditSelectionDialog'

const init = defaultQueryParams()

export const queryParamsContext = () => {
  const value = useFilterTable()

  return value
}

export const deleteSectionContext = () => {
  const value = useDeleteSelectionDialog()

  return value
}

export const createSectionContext = () => {
  const value = useCreateDialog()

  return value
}

export const editSectionContext = () => {
  const value = useEditSelectionDialog()

  return value
}