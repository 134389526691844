import axios from 'axios'
import OrderDataSource from './OrderDataSoruce'
import {OrderResponse} from './response/OrderResponse'
import {ApiResponse} from './response/BaseResponse'
import {BaseDatatableRequest} from './request/BaseDatatableRequest'
import {BaseDatatableResponse} from './response/BaseDatatableResponse'
import {
  OrderCreateRequest,
  OrderEditInstallmentRequest,
  OrderEditStatusInstallmentRequest,
  OrderUpdateRequest,
} from './request/OrderRequest'
import {MessageResponse} from './response/MessageResponse'

export default class OrderDataSourceImpl implements OrderDataSource {
  private ORDER_URL: string

  constructor() {
    const service_chapter_url = process.env.REACT_APP_ORDER_SERVICE_URL || 'api/'
    this.ORDER_URL = `${service_chapter_url}orders`
  }

  async cancelInstallment(id: string, order_id: string): Promise<OrderResponse> {
    const response = await axios.put<ApiResponse<OrderResponse>>(`${this.ORDER_URL}/cancel-invoice/${order_id}`, {
      installment_id: id,
    })
    const data = response.data.data
    return data
  }

  async editStatusInstallment(
    body: OrderEditStatusInstallmentRequest,
    order_id: string
  ): Promise<OrderResponse> {
    const response = await axios.put<ApiResponse<OrderResponse>>(
      `${this.ORDER_URL}/installment_status/${order_id}`,
      body
    )

    const data = response.data.data
    return data
  }

  async deleteInstallment(id: string, order_id: string): Promise<OrderResponse> {
    const response = await axios.post<ApiResponse<OrderResponse>>(
      `${this.ORDER_URL}/installment/${order_id}`,
      {
        installment_id: id,
      }
    )

    const data = response.data.data
    return data
  }

  async editInstallment(
    body: OrderEditInstallmentRequest,
    order_id: string
  ): Promise<OrderResponse> {
    const response = await axios.put<ApiResponse<OrderResponse>>(
      `${this.ORDER_URL}/installment/${order_id}`,
      body
    )

    const data = response.data.data
    return data
  }

  async getOne(id: string | number): Promise<OrderResponse> {
    const response = await axios.get<ApiResponse<OrderResponse>>(`${this.ORDER_URL}/${id}`)
    const data = response.data.data
    return data
  }

  async deleteOne(id: string | number): Promise<string> {
    const response = await axios.delete<MessageResponse>(`${this.ORDER_URL}/${id}`)
    return response.data.message
  }

  async create(req: OrderCreateRequest): Promise<OrderResponse> {
    const response = await axios.post<ApiResponse<OrderResponse>>(`${this.ORDER_URL}/create`, req)

    const data = response.data.data
    return data
  }

  async update(req: OrderUpdateRequest, id: string | number | undefined): Promise<OrderResponse> {
    const response = await axios.put<ApiResponse<OrderResponse>>(
      `${this.ORDER_URL}/product/${id}`,
      req
    )

    const data = response.data.data
    return data
  }

  async getDatatable(req: BaseDatatableRequest): Promise<BaseDatatableResponse<OrderResponse>> {
    const response = await axios.post<ApiResponse<BaseDatatableResponse<OrderResponse>>>(
      `${this.ORDER_URL}/datatable`,
      req
    )

    const data = response.data.data

    return data
  }

  async getAll(): Promise<OrderResponse[]> {
    const response = await axios.get<ApiResponse<OrderResponse[]>>(`${this.ORDER_URL}`)
    const data = response.data.data
    return data
  }
}
