import {BaseDatatableRequest} from '../../_data/dataSource/request/BaseDatatableRequest'
import {
  mappingInputFormToTeacherCreateRequest,
  mappingInputFormToTeacherUpdateRequest,
} from '../../_data/dataSource/request/TeacherRequest'
import {BaseDatatableResponse} from '../../_data/dataSource/response/BaseDatatableResponse'
import {TeacherFormInput} from '../../helpers/UITeacherConstanta'
// import {TeacherFormInput} from '../../helpers/UITeacherConstanta'
import {Teacher} from '../model/Teacher'
import {TeacherRepository} from '../repository/TeacherRepository'

export interface TeacherService {
  getDatatableTeacher(req: BaseDatatableRequest): Promise<BaseDatatableResponse<Teacher>>
  getAllTeacher(): Promise<Teacher[]>
  getDetailTeacherById(id: number): Promise<Teacher>
  deleteOneTeacherByID(id: number): Promise<string>
  createOneTeacher(req: TeacherFormInput): Promise<Teacher>
  updateOneTeacher(req: TeacherFormInput, id?: string): Promise<Teacher>
}

export class TeacherServiceImpl implements TeacherService {
  private repo: TeacherRepository
  constructor(repo: TeacherRepository) {
    this.repo = repo
  }

  async updateOneTeacher(req: TeacherFormInput, id?: string): Promise<Teacher> {
    const body = mappingInputFormToTeacherUpdateRequest(req)
    const teacher = await this.repo.updateOne(body, id)
    if (req.photoFile) {
      this.repo.updatePhoto(teacher.id, req.photoFile)
    }
    return teacher
  }

  async createOneTeacher(req: TeacherFormInput): Promise<Teacher> {
    const body = mappingInputFormToTeacherCreateRequest(req)
    const teacher = await this.repo.createOne(body)
    if (req.photoFile) {
      this.repo.updatePhoto(teacher.id, req.photoFile)
    }
    return teacher
  }

  async deleteOneTeacherByID(id: number): Promise<string> {
    const message = await this.repo.deleteOneById(id)
    return message
  }
  async getDatatableTeacher(req: BaseDatatableRequest): Promise<BaseDatatableResponse<Teacher>> {
    const teachers = await this.repo.getDatatable(req)
    return teachers
  }
  async getDetailTeacherById(id: number): Promise<Teacher> {
    const teacher = await this.repo.getOneById(id)
    return teacher
  }
  async getAllTeacher(): Promise<Teacher[]> {
    const teachers = await this.repo.getAll()
    return teachers
  }
}
