import ParentDataSource from '../../_data/dataSource/ParentDataSoruce'
import {BaseDatatableRequest} from '../../_data/dataSource/request/BaseDatatableRequest'
import {
  ParentCreateRequest,
  ParentUpdateRequest,
} from '../../_data/dataSource/request/ParentRequest'
import {BaseDatatableResponse} from '../../_data/dataSource/response/BaseDatatableResponse'
import {ParentResponse} from '../../_data/dataSource/response/ParentResponse'
import {Parent, ParentBuilder} from '../model/Parent'
import {Student, StudentBuilder} from '../model/Student'
import {ParentRepository} from './ParentRepository'

export class ParentRepositoryImpl implements ParentRepository {
  datasource: ParentDataSource
  constructor(datasource: ParentDataSource) {
    this.datasource = datasource
  }

  async addStudent(parent_user_id: number, userIds: number[]): Promise<string> {
    return this.datasource.addStudent(parent_user_id, userIds)
  }

  async getOneStudentByUserId(userId: string | number): Promise<ParentResponse> {
    const response = await this.datasource.getOneStudentsByUserId(userId)
    const initBuilder = new ParentBuilder()
    const parent = initBuilder.SingleData(response).Result()
    return parent
  }

  async getOneByUserId(userId: string | number): Promise<ParentResponse> {
    const response = await this.datasource.getOneByUserId(userId)
    const initBuilder = new ParentBuilder()
    const parent = initBuilder.SingleData(response).Result()
    return parent
  }

  async updateOne(req: ParentUpdateRequest, id?: string): Promise<Parent> {
    const response = await this.datasource.update(req, id)
    const initBuilder = new ParentBuilder()
    const parent = initBuilder.SingleData(response).Result()
    return parent
  }

  async createOne(req: ParentCreateRequest): Promise<Parent> {
    const response = await this.datasource.create(req)
    const initBuilder = new ParentBuilder()
    const parent = initBuilder.SingleData(response).Result()
    return parent
  }

  deleteOneById(id: string): Promise<string> {
    return this.datasource.deleteOne(id)
  }

  async getDatatable(req: BaseDatatableRequest): Promise<BaseDatatableResponse<Parent>> {
    const data = await this.datasource.getDatatable(req)
    const initBuilder = new ParentBuilder()
    const entities = initBuilder.ListData(data.entities).ResultList()
    const totalCount = data.totalCount
    return {
      entities,
      totalCount,
      errorMessage: data.errorMessage,
    }
  }

  async getDatatableSearchStudent(
    req: BaseDatatableRequest,
    userId?: number
  ): Promise<BaseDatatableResponse<Student>> {
    const data = await this.datasource.getDatatableSearchStudent(req, userId)
    const initBuilder = new StudentBuilder()
    const entities = initBuilder.ListData(data.entities).ResultList()
    const totalCount = data.totalCount
    return {
      entities,
      totalCount,
      errorMessage: data.errorMessage,
    }
  }

  async getOneById(id: string): Promise<Parent> {
    const resposne = await this.datasource.getOne(id)
    const initBuilder = new ParentBuilder()
    const parent = initBuilder.SingleData(resposne).Result()
    return parent
  }

  async getAll(): Promise<Parent[]> {
    const response = await this.datasource.getAll()
    const initBuilder = new ParentBuilder()
    const parents = initBuilder.ListData(response).ResultList()
    return parents
  }
}
