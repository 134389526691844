import axios from 'axios'
import {
  ScheduleBatchResponse,
  ScheduleResponse,
} from './response/ScheduleResponse'
import {ApiResponse} from './response/BaseResponse'
import {BaseDatatableRequest} from './request/BaseDatatableRequest'
import {BaseDatatableResponse} from './response/BaseDatatableResponse'
import ScheduleTryoutDataSource from './ScheduleTryoutDataSoruce'
import {
  ScheduleTryoutCreateBatchRequest,
  ScheduleTryoutFilterRequest,
  ScheduleTryoutUpdateBatchRequest,
  ScheduleTryoutUpdateRequest,
} from './request/ScheduleTryoutRequest'
import {MessageResponse} from './response/MessageResponse'

export default class ScheduleTryoutDataSourceImpl implements ScheduleTryoutDataSource {
  private SCHEDULE_TO_URL: string
  private SCHEDULE_BATCH_URL: string
  private query_param = '?type_schedule=tryout'

  constructor() {
    const service_schedule_url = process.env.REACT_APP_SCHEDULE_SERVICE_URL || 'api/'
    this.SCHEDULE_TO_URL = `${service_schedule_url}schedules`
    this.SCHEDULE_BATCH_URL = `${service_schedule_url}schedules/batch`
  }

  async getAllFilterBatch(req: ScheduleTryoutFilterRequest): Promise<ScheduleResponse[]> {
    const response = await axios.post<ApiResponse<ScheduleResponse[]>>(
      `${this.SCHEDULE_BATCH_URL}/filter-v2${this.query_param}`,
      req
    )

    const data = response.data.data

    return data
  }

  async getAllFilter(req: ScheduleTryoutFilterRequest): Promise<ScheduleResponse[]> {
    const response = await axios.post<ApiResponse<ScheduleResponse[]>>(
      `${this.SCHEDULE_TO_URL}/filter-v2${this.query_param}`,
      req
    )

    const data = response.data.data

    return data
  }

  async updateBatch(
    req: ScheduleTryoutUpdateBatchRequest,
    scheduleBatchId: string
  ): Promise<ScheduleResponse | undefined> {
    const response = await axios.put<ApiResponse<ScheduleResponse>>(
      `${this.SCHEDULE_BATCH_URL}/${scheduleBatchId}${this.query_param}`,
      req
    )

    return undefined
  }

  async getOneBatch(scheduleBatchId: string): Promise<ScheduleBatchResponse> {
    const response = await axios.get<ApiResponse<ScheduleBatchResponse>>(
      `${this.SCHEDULE_BATCH_URL}/${scheduleBatchId}${this.query_param}`
    )

    return response.data.data
  }

  async getDatatableBatch(
    req: BaseDatatableRequest
  ): Promise<BaseDatatableResponse<ScheduleBatchResponse>> {
    const response = await axios.post<
      ApiResponse<BaseDatatableResponse<ScheduleBatchResponse>>
    >(`${this.SCHEDULE_BATCH_URL}/datatable${this.query_param}`, req)

    const data = response.data.data

    return data
  }

  async getDatatable(
    req: BaseDatatableRequest
  ): Promise<BaseDatatableResponse<ScheduleResponse>> {
    const response = await axios.post<ApiResponse<BaseDatatableResponse<ScheduleResponse>>>(
      `${this.SCHEDULE_TO_URL}/datatable${this.query_param}`,
      req
    )

    const data = response.data.data
    return data
  }

  getAll(params?: any): Promise<ScheduleResponse[]> {
    throw new Error('Method not implemented.')
  }

  async getOne(id: string | number): Promise<ScheduleResponse> {
    const response = await axios.get<ApiResponse<ScheduleResponse>>(
      `${this.SCHEDULE_TO_URL}/${id}${this.query_param}`
    )

    return response.data.data
  }

  async deleteOne(id: string | number): Promise<string> {
    const response = await axios.delete<MessageResponse>(
      `${this.SCHEDULE_TO_URL}/${id}${this.query_param}`
    )
    return response.data.message
  }

  async deleteOneBatch(scheduleBatchId: string | number): Promise<string> {
    const response = await axios.delete<MessageResponse>(
      `${this.SCHEDULE_BATCH_URL}/${scheduleBatchId}${this.query_param}`
    )
    return response.data.message
  }

  async create(req: any): Promise<ScheduleResponse> {
    const response = await axios.post<ApiResponse<ScheduleResponse>>(
      `${this.SCHEDULE_TO_URL}${this.query_param}`,
      req
    )

    return response.data.data
  }

  async createBatch(
    req: ScheduleTryoutCreateBatchRequest
  ): Promise<ScheduleResponse | undefined> {
    const response = await axios.post<ApiResponse<ScheduleResponse>>(
      `${this.SCHEDULE_BATCH_URL}${this.query_param}`,
      req
    )

    return undefined
  }

  async update(
    req: ScheduleTryoutUpdateRequest,
    id: string | number | undefined
  ): Promise<ScheduleResponse> {
    const response = await axios.put<ApiResponse<ScheduleResponse>>(
      `${this.SCHEDULE_TO_URL}/${req.scheduleId}${this.query_param}`,
      req
    )

    return response.data.data
  }
}
