import {studentClass} from '../../../../../_redux_slice'
import {alertError, alertSuccess, errorHandling} from '../../../../../helpers/FuncHelpers'
import {AlertPromise} from '../../../../../helpers/classHelpers'
import StudentClassDataSourceImpl from '../../../../../_data/dataSource/StudentClassDataSourceImpl'
import {StudentClassRepositoryImpl} from '../../../../../_domain/repository/StudentClassRepositoryImpl'
import {StudentClassServiceImpl} from '../../../../../_domain/service/StudentClassService'
import {
  STUDENT_CLASS_CREATE_FAILED,
  STUDENT_CLASS_CREATE_SUCCESS,
  STUDENT_CLASS_DELETE_FAILED,
  STUDENT_CLASS_DELETE_SUCCESS,
  STUDENT_CLASS_GET_FAILED,
} from '../../../../../_domain/constanta'
import {StudentClassFormInput} from '../../../../../helpers/UIStudentClassConstanta'

const {actions} = studentClass.slice
const callTypes = studentClass.callTypes

const dataSource = new StudentClassDataSourceImpl()
const repository = new StudentClassRepositoryImpl(dataSource)
const service = new StudentClassServiceImpl(repository)

const alertPromise = new AlertPromise()

export const getOneStudentClass = (id?: number) => (dispatch: any) => {
  if (!id) {
    dispatch(actions.saveObject({data: undefined}))
    dispatch(actions.saveList({totalCount: 0, entities: []}))
    return
  }

  dispatch(actions.startCall({callType: callTypes.action}))
  return service
    .getDetailStudentClassById(id)
    .then((response) => {
      const studentClass = response
      dispatch(actions.saveObject({data: studentClass}))
      dispatch(
        actions.saveList({
          totalCount: studentClass.students.length,
          entities: studentClass.students,
        })
      )
      return studentClass
    })
    .catch((err) => {
      if (err.response) {
        errorHandling(err.response, STUDENT_CLASS_GET_FAILED)
      } else {
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const createStudentClass = (id: number, req: StudentClassFormInput) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.action}))
  return service
    .createOneStudentClass(id, req)
    .then(() => {
      alertPromise.stop()
      alertSuccess(STUDENT_CLASS_CREATE_SUCCESS)
    })
    .catch((err) => {
      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, STUDENT_CLASS_CREATE_FAILED)
      } else {
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const deleteStudentClass = (id: number, req: StudentClassFormInput) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.action}))
  return service
    .deleteOneStudentClassByID(id, req)
    .then(() => {
      alertPromise.stop()
      alertSuccess(STUDENT_CLASS_DELETE_SUCCESS)
    })
    .catch((err) => {
      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, STUDENT_CLASS_DELETE_FAILED)
      } else {
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}