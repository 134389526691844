import React, { FC, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import Form from 'react-bootstrap/Form'
import { UserModel } from '../../../../../../../_domain/model/Auth'

type props = {
  onSubmit: (req: any) => void
  userData: UserModel
}

const StudentRole: FC<props> = ({ onSubmit, userData }) => {
  const {
    handleSubmit,
    getValues,
    setValue,
    control,
    formState: { errors },
  } = useForm<any>({
    mode: "onChange",
    defaultValues: useMemo(() => {
      return {
        roleInput: userData?.role ?? "",
        extendRolesInput: userData?.user_extend_role ?? []
      }
    }, [userData])
  })

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_connected_accounts'
          aria-expanded='true'
          aria-controls='kt_account_connected_accounts'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Role Account</h3>
          </div>
        </div>

        <div id='kt_account_connected_accounts' className='collapse show'>
          <div className='card-body border-top p-9'>

            <div className='py-2'>
              <div className='d-flex flex-stack'>
                <div className='d-flex'>

                  <div className='d-flex flex-column'>
                    <a href='#' className='fs-5 text-gray-900 text-hover-primary fw-bolder'>
                      Team (Internal Administration)
                    </a>
                    <div className='fs-6 fw-bold text-gray-500'>Mainly user will has access admin page</div>
                  </div>
                </div>
                <div className='d-flex justify-content-end'>
                  <div className='form-check form-check-solid form-switch'>
                    <Controller
                      name="roleInput"
                      defaultValue=''
                      control={control}
                      render={({ field }) => (
                        <input
                          {...field}
                          className='form-check-input w-45px h-30px'
                          checked={field.value === "team"}
                          type='radio'
                          onChange={() => {
                            setValue("roleInput", "team")
                          }}
                        />

                      )} />
                    <label className='form-check-label' htmlFor='githubswitch'></label>
                  </div>
                </div>
              </div>

              <div className='separator separator-dashed my-5'></div>

              <div className='d-flex flex-stack'>
                <div className='d-flex'>

                  <div className='d-flex flex-column'>
                    <a href='#' className='fs-5 text-gray-900 text-hover-primary fw-bolder'>
                      Teacher
                    </a>
                    <div className='fs-6 fw-bold text-gray-500'>Mainly user will has access teacher page</div>
                  </div>
                </div>
                <div className='d-flex justify-content-end'>
                  <div className='form-check form-check-solid form-switch'>
                    <Controller
                      name="roleInput"
                      defaultValue=''
                      control={control}
                      render={({ field }) => (
                        <input
                          {...field}
                          className='form-check-input w-45px h-30px'
                          checked={field.value === "teacher"}
                          type='radio'
                          onChange={() => {
                            setValue("roleInput", "teacher")
                          }}
                        />

                      )} />
                    <label className='form-check-label' htmlFor='githubswitch'></label>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary'>
            Save Change
          </button>
        </div>
      </div>

    </Form>
  )
}
export default StudentRole