import {BaseDatatableRequest} from '../../_data/dataSource/request/BaseDatatableRequest'
import {
  mappingInputFormToParentCreateRequest,
  mappingInputFormToParentUpdateRequest,
} from '../../_data/dataSource/request/ParentRequest'
import {BaseDatatableResponse} from '../../_data/dataSource/response/BaseDatatableResponse'
import {ParentFormInput, ParentStudentAddInput} from '../../helpers/UIParentConstanta'
import {Parent} from '../model/Parent'
import {ParentRepository} from '../repository/ParentRepository'

export interface ParentService {
  getDatatableParent(req: BaseDatatableRequest): Promise<BaseDatatableResponse<Parent>>
  getAllParent(): Promise<Parent[]>
  getDetailParentByUserId(userId: string): Promise<Parent>
  deleteOneParentByID(id: string): Promise<string>
  createOneParent(req: ParentFormInput): Promise<Parent>
  updateOneParent(req: ParentFormInput, id: number): Promise<Parent>
  addParentStudent(parent_userId: number, req: ParentStudentAddInput): Promise<string>
}

export class ParentServiceImpl implements ParentService {
  private repo: ParentRepository
  constructor(repo: ParentRepository) {
    this.repo = repo
  }

  async addParentStudent(parent_userId: number, req: ParentStudentAddInput): Promise<string> {
    const userIds = req.students?.map((item) => item.user_id) ?? []
    const message = await this.repo.addStudent(parent_userId, userIds)
    return message
  }

  async updateOneParent(req: ParentFormInput, id: number): Promise<Parent> {
    const body = mappingInputFormToParentUpdateRequest(req)
    const parent = await this.repo.updateOne(body, id)
    return parent
  }

  async createOneParent(req: ParentFormInput): Promise<Parent> {
    const body = mappingInputFormToParentCreateRequest(req)
    const parent = await this.repo.createOne(body)
    return parent
  }

  async deleteOneParentByID(id: string): Promise<string> {
    const message = await this.repo.deleteOneById(id)
    return message
  }

  async getDatatableParent(req: BaseDatatableRequest): Promise<BaseDatatableResponse<Parent>> {
    const parents = await this.repo.getDatatable(req)
    return parents
  }

  async getDetailParentByUserId(userId: string): Promise<Parent> {
    const parent = await this.repo.getOneStudentByUserId(userId)
    return parent
  }

  async getAllParent(): Promise<Parent[]> {
    const parents = await this.repo.getAll()
    return parents
  }
}
