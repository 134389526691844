/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { PageTitle } from '../../../../../_layout/core'
import { getAllTeacherDatatable } from './TeacherAction'
import { Suspense, lazy } from 'react'
import CustomLoading from '../../../../../_component/customLoading'
import { useOutletContext } from 'react-router-dom'
import { Teacher } from '../../../../../_domain/model/Teacher'
import { BaseDatatableResponse } from '../../../../../_data/dataSource/response/BaseDatatableResponse'
import TeacherDelete from './TeacherDelete'
import { TEACHER_LIST_TITLE } from '../../../../../_domain/constanta'
import { useAuth } from '../../../../../_context/AuthContext'
import { checkAuthActionMenu } from '../../../../../helpers/AuthHelpers'
import { SLUG_MASTER_SUB_MENU } from '../../../../../helpers/UIAuthGroupConstanta'

const TeacherListTable = React.lazy(() => import('./TeacherListComponent/TeacherListTable'))
const TeacherCreate = React.lazy(() => import('./TeacherCreate'))
const TeacherEdit = React.lazy(() => import('./TeacherEdit'))

const checkReduxData = () => {
  const filterContext = useOutletContext<any>().queryParams
  const { queryParams } = useMemo(() => {
    return {
      queryParams: filterContext.queryParams,
    };
  }, [filterContext]);

  const [teacherListData, setTeacherListData] = useState<any>(null)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch<any>(getAllTeacherDatatable(queryParams)).then((response: BaseDatatableResponse<Teacher>) => {
      setTeacherListData(response.entities)
    })
  }, [queryParams, getAllTeacherDatatable])

  return teacherListData
}

const TeacherList = () => {
  let data = checkReduxData()
  const { currentUser, authorization } = useAuth()
  return checkAuthActionMenu(SLUG_MASTER_SUB_MENU.MASTER_TEACHER_MENU, "view", currentUser, authorization) ? (
    <>
      <TeacherCreate />
      <TeacherEdit />
      <TeacherDelete />
      <PageTitle breadcrumbs={[]}>{TEACHER_LIST_TITLE}</PageTitle>
      <Suspense fallback={<CustomLoading />}>
        {data ? <TeacherListTable /> : null}
      </Suspense>
    </>
  ) : null
}

export { TeacherList }
