/* eslint-disable jsx-a11y/anchor-is-valid */

import { Outlet, Route, Routes } from 'react-router'
import { PageTitle } from '../../../../../_layout/core'
import { TryoutResultList } from './TryoutResultList'
// import { TryoutResultCreate } from './TryoutResultCreate'
// import { TryoutResultEdit } from './TryoutResultEdit'

// outlet context bank
import { calculateSectionContext, deleteSectionContext, detailSectionContext, queryParamsContext } from './_outletContext'

const TryoutResultContent = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>{'Tryout Result'}</PageTitle>
      <Outlet context={{ queryParams: queryParamsContext(), deleteSection: deleteSectionContext(), calculateSection: calculateSectionContext(), detailSection: detailSectionContext() }} />
    </>
  )
}

const TryoutResult = () => {
  return (
    <>
      <Routes>
        <Route element={<TryoutResultContent />}>
          <Route index element={<TryoutResultList />} />
          {/* <Route path='create' element={<TryoutResultCreate />} /> */}
          {/* <Route path='edit/:chapterId' element={<TryoutResultEdit />} /> */}
          {/* <Route path='*' element={<Navigate to='/error/404' />} /> */}
        </Route>
      </Routes>
    </>
  )
}

export { TryoutResult }
