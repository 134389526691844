import {SubjectFormInput} from '../../../helpers/UISubjectConstanta'

export type SubjectCreateRequest = {
  name: string
  code: string
  major: string
}

export type SubjectUpdateRequest = {
  name: string
  code: string
  major: string
}

export type SubjectFilterRequest = {
  major: string
  text: string
}

export function mappingInputFormToSubjectCreateRequest(
  req: SubjectFormInput
): SubjectCreateRequest {
  return {
    name: req.nameInput ?? '',
    code: req.codeInput ?? '',
    major: req.majorInput ?? '',
  }
}

export function mappingInputFormToSubjectUpdateRequest(
  req: SubjectFormInput
): SubjectUpdateRequest {
  return {
    name: req.nameInput ?? '',
    code: req.codeInput ?? '',
    major: req.majorInput ?? '',
  }
}
