import {StudentResponse} from '../../_data/dataSource/response/StudentResponse'
import {genderSwitch, typeMajorSwitch} from '../../helpers/FuncHelpers'
import {GENDER, MAJOR} from '../constanta'
import { UserModel } from './Auth'
import {School} from './School'

export type Student = {
  id: number
  name: string
  email?: string
  gender: GENDER
  user_id: number
  student_class: number
  id_qonstanta: string
  phone_number: string
  grade: number
  sub_class: string
  major: MAJOR
  address: string
  birth_date: string
  is_active: boolean
  is_active_tryout: boolean
  is_paid: boolean
  dummy: boolean
  class_name: string
  type_to: string
  trial_to: boolean
  school_name?: string
  school_detail?: School
  class_index?: number
  user?: {
    id: number
    password: string
    name: string
    email: string
    role: string
    verification_date?: string
    referral_code_regis?: string
  }
}

export class StudentBuilder {
  SingleStudent!: Student
  StudentList!: Student[]
  constructor() {}

  SingleData(studentResponse: StudentResponse): StudentBuilder {
    const student = mapingStudent(studentResponse)
    this.SingleStudent = student
    return this
  }
  ListData(studentResponse: StudentResponse[]): StudentBuilder {
    const students = mapingStudents(studentResponse)
    this.StudentList = students
    return this
  }
  Result(): Student {
    return this.SingleStudent
  }
  ResultList(): Student[] {
    return this.StudentList
  }
}

function mapingStudent(studentResponse: StudentResponse): Student {
  let student: Student = {
    id: studentResponse.id,
    name: studentResponse.name,
    email: studentResponse.email,
    gender: genderSwitch(studentResponse.gender),
    user_id: studentResponse.user_id,
    student_class: studentResponse.student_class,
    id_qonstanta: studentResponse.id_qonstanta,
    phone_number: studentResponse.phone_number,
    grade: studentResponse.grade,
    sub_class: studentResponse.sub_class,
    major: typeMajorSwitch(studentResponse.major),
    address: studentResponse.address,
    birth_date: studentResponse.birth_date,
    is_active: studentResponse.is_active,
    is_active_tryout: studentResponse.is_active_tryout,
    is_paid: studentResponse.is_paid,
    dummy: studentResponse.dummy,
    class_name: studentResponse.class_name,
    type_to: studentResponse.type_to,
    trial_to: studentResponse.trial_to,
    school_name: studentResponse.school_name,
  }
  student.user = {
    id: studentResponse.user?.id ?? 0,
    password: '',
    name: studentResponse.user?.name ?? '',
    email: studentResponse.user?.email ?? '',
    role: studentResponse.user?.role ?? 'student',
    verification_date: studentResponse.user?.verification_date,
    referral_code_regis: studentResponse.user?.referral_code_regis,
  }
  return student
}

function mapingStudents(studentsResponse: StudentResponse[]): Student[] {
  const students = studentsResponse.map((item) => {
    return mapingStudent(item)
  })
  return students
}
