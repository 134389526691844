import React, { FC, useMemo } from 'react'
import BaseTable from '../BaseTable'
import { Order } from '../../../_domain/model/Order'
import useOrderTable from './useOrderTable'
import { BaseDatatableRequest } from '../../../_data/dataSource/request/BaseDatatableRequest'
import { dateFormatStringV2, formatCurrency } from '../../../helpers/FuncHelpers'

type Props = {
  selectedOrderCallback: (val: any) => void
  singleSelected?: boolean
  initFilter?: BaseDatatableRequest
  values?: Order[]
  selectableRows?: boolean
}

const OrderTable: FC<Props> = ({ selectedOrderCallback, singleSelected, initFilter, values, selectableRows = true }) => {

  const orderTable = useOrderTable({
    initQueryParams: initFilter
  })
  const { entities, queryParams, totalCount, handlePageChange, handleSortChange, handleRowPerPageChange, applyFilter } = useMemo(() => {
    return {
      entities: orderTable.entities,
      queryParams: orderTable.queryParams,
      totalCount: orderTable.totalCount,
      handlePageChange: orderTable.handlePageChange,
      handleSortChange: orderTable.handleSortChange,
      handleRowPerPageChange: orderTable.handleRowPerPageChange,
      applyFilter: orderTable.applyFilter,
    }
  }, [orderTable])

  let mutableTableData = [...entities]
  const columns = [
    {
      name: 'Product',
      id: "name",
      selector: (row: Order) => {
        return (
          <>
            <span className='text-gray-800 fs-6 fw-bold'>
              {row.product_detail.name}
            </span>
            <br />
            <span className='text-gray-500 fw-semibold'>{row.product_detail.code_product}</span>
          </>
        )
      },
      sortable: true,
    },
    {
      name: 'Total Amount',
      selector: (row: Order) => "Rp. " + formatCurrency(row.amount.toString()),
      maxWidth: '200px',
    },
    {
      name: 'Installment Count',
      selector: (row: Order) => {
        return `${row.installment} ${row.installment > 1 ? "times" : "time"}`
      },
      maxWidth: '200px',
    },
    {
      name: 'User Name',
      selector: (row: Order) => row.user_detail?.name ?? row.user_id,
      maxWidth: '200px',
    },
    {
      name: 'Order Date Submit',
      selector: (row: Order) => dateFormatStringV2(row.created_at),
      maxWidth: '200px',
    },
  ]


  return (
    <>
      <div className='customized-datatable-style'>
        <BaseTable
          columns={columns}
          selectableRows={selectableRows}
          data={mutableTableData}
          countPerPage={queryParams.pageSize}
          totalRows={totalCount}
          handlePageChange={handlePageChange}
          handleSortChange={handleSortChange}
          handleSelectedRowsChange={(selectedItem: any) => {
            const { selectedRows, selectedCount } = selectedItem

            const valuesOtherPage: any[] = values?.filter((item) => {
              if (entities.some((val: any) => val.id === item.id)) {
                return false
              }
              return true
            }) ?? [];

            const selectedMerge: any[] = [...selectedRows, ...valuesOtherPage]

            if (singleSelected) {
              if (selectedMerge?.at(0)?.id !== values?.at(0)?.id && selectedCount !== 0) {

                selectedOrderCallback(selectedMerge)
              }
            } else {
              if (selectedMerge.length !== values?.length && selectedCount !== 0) {

                selectedOrderCallback(selectedMerge)
              }
            }
          }}
          initSelected={(row: Order) => {
            let initSelected: any[] = []

            if (values) {
              initSelected = [...values, ...initSelected]
            }
            return initSelected.some((obj) => obj.id === row.id)
          }}
          handleRowPerPageChange={handleRowPerPageChange}
          singleSelected={singleSelected}
        />
      </div>
    </>
  )
}

export default OrderTable;