import TypeTryoutDataSource from '../../_data/dataSource/TypeTryoutDataSoruce'
import {BaseDatatableRequest} from '../../_data/dataSource/request/BaseDatatableRequest'
import {
  TypeTryoutCreateRequest,
  TypeTryoutUpdateRequest,
} from '../../_data/dataSource/request/TypeTryoutRequest'
import {BaseDatatableResponse} from '../../_data/dataSource/response/BaseDatatableResponse'
import {TypeTryout, TypeTryoutBuilder} from '../model/TypeTryout'
import {TypeTryoutRepository} from './TypeTryoutRepository'

export class TypeTryoutRepositoryImpl implements TypeTryoutRepository {
  datasource: TypeTryoutDataSource
  constructor(datasource: TypeTryoutDataSource) {
    this.datasource = datasource
  }

  async updateOne(req: TypeTryoutUpdateRequest, id: string): Promise<TypeTryout> {
    const response = await this.datasource.update(req, id)
    const initBuilder = new TypeTryoutBuilder()
    const typeTryout = initBuilder.SingleData(response).Result()
    return typeTryout
  }

  async createOne(req: TypeTryoutCreateRequest): Promise<TypeTryout> {
    const response = await this.datasource.create(req)
    const initBuilder = new TypeTryoutBuilder()
    const typeTryout = initBuilder.SingleData(response).Result()
    return typeTryout
  }

  deleteOneById(id: string): Promise<string> {
    return this.datasource.deleteOne(id)
  }

  async getDatatable(req: BaseDatatableRequest): Promise<BaseDatatableResponse<TypeTryout>> {
    const data = await this.datasource.getDatatable(req)
    const initBuilder = new TypeTryoutBuilder()
    const entities = initBuilder.ListData(data.entities).ResultList()
    const totalCount = data.totalCount
    return {
      entities,
      totalCount,
      errorMessage: data.errorMessage,
    }
  }

  async getOneById(id: string): Promise<TypeTryout> {
    const response = await this.datasource.getOne(id)
    const initBuilder = new TypeTryoutBuilder()
    const typeTryout = initBuilder.SingleData(response).Result()
    return typeTryout
  }

  async getAll(): Promise<TypeTryout[]> {
    const response = await this.datasource.getAll()
    const initBuilder = new TypeTryoutBuilder()
    const typeTryouts = initBuilder.ListData(response).ResultList()
    return typeTryouts
  }
}
