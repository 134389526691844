import SVG from 'react-inlinesvg'
import { Link, useOutletContext } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../../../_layout/helpers'
import { Badge, OverlayTrigger, Tooltip } from 'react-bootstrap'
import BaseTable from '../../../../../../_component/Table/BaseTable'
import { shallowEqual, useSelector } from 'react-redux'
import { AuthGroup } from '../../../../../../_domain/model/AuthGroup'
import { useMemo } from 'react'
import { useAuth } from '../../../../../../_context/AuthContext'
import { checkAuthActionMenu } from '../../../../../../helpers/AuthHelpers'
import { SLUG_MASTER_SUB_MENU } from '../../../../../../helpers/UIAuthGroupConstanta'

const AuthGroupListTable = () => {
  const { currentUser, authorization } = useAuth()

  const filterContext = useOutletContext<any>().queryParams
  const { handlePageChange, handleSortChange, queryParams, handleRowPerPageChange } = useMemo(() => {
    return {
      queryParams: filterContext.queryParams,
      handlePageChange: filterContext.handlePageChange,
      handleSortChange: filterContext.handleSortChange,
      handleRowPerPageChange: filterContext.handleRowPerPageChange
    };
  }, [filterContext]);

  const deleteSectionContext = useOutletContext<any>().deleteSection
  const { showingDeleteModal } = useMemo(() => {
    return {
      showingDeleteModal: deleteSectionContext.showingDeleteModal,
    };
  }, [deleteSectionContext]);


  const { currentState } = useSelector((state: any) => ({ currentState: state.authGroup }), shallowEqual)
  const { totalCount, entities } = currentState

  let mutableTableData = [...entities]

  const columns = [
    {
      name: 'Name',
      id: "name",
      selector: (row: AuthGroup) => row.name,
      sortable: true,
    },
    {
      name: 'Role',
      selector: (row: AuthGroup) => {
        return <Badge bg='primary' text='white'>{row.main_role}</Badge>
      },
      maxWidth: '200px',
    },
    {
      name: 'Action',
      // selector: (row: any) => (row.actionButton),
      cell: (row: AuthGroup) => (
        <>
          {checkAuthActionMenu(SLUG_MASTER_SUB_MENU.MASTER_AUTH_GROUP_MENU, "edit", currentUser, authorization) && (
            <OverlayTrigger
              overlay={<Tooltip id='products-edit-tooltip'>Edit this Auth Group ?</Tooltip>}
            >
              <Link to={`/master/auth-group/edit/${row.id}`}>
                <button className='btn btn-icon btn-light btn-sm mx-3'>
                  <span className='svg-icon svg-icon-md svg-icon-primary'>
                    <SVG src={toAbsoluteUrl('/media/svg/edit.svg')} />
                  </span>
                </button>
              </Link>
            </OverlayTrigger>
          )}
          {" "}
          {checkAuthActionMenu(SLUG_MASTER_SUB_MENU.MASTER_AUTH_GROUP_MENU, "delete", currentUser, authorization) && (
            <OverlayTrigger
              overlay={<Tooltip id='products-edit-tooltip'>Delete this Auth Group?</Tooltip>}
            >
              <button
                onClick={() => showingDeleteModal(row.id)}
                className='btn btn-icon btn-danger btn-sm mx-3'
              >
                <span className='svg-icon svg-icon-md svg-icon-primary'>
                  <SVG src={toAbsoluteUrl('/media/svg/delete.svg')} />
                </span>
              </button>
            </OverlayTrigger>
          )}
        </>
      )
    },
  ]
  // Use the state and functions returned from useTable to build your UI

  return (
    <>
      <div className='d-flex justify-content-between align-items-center mb-4'>
        {checkAuthActionMenu(SLUG_MASTER_SUB_MENU.MASTER_AUTH_GROUP_MENU, "create", currentUser, authorization) && (
          <Link to={"/master/auth-group/create"}>
            <button type='button' className='btn btn-sm btn-primary'>
              Add New
            </button>
          </Link>
        )}
      </div>
      <div className='customized-datatable-style'>
        <BaseTable
          columns={columns}
          data={mutableTableData}
          countPerPage={queryParams.pageSize}
          totalRows={totalCount}
          handlePageChange={handlePageChange}
          handleSortChange={handleSortChange}
          handleRowPerPageChange={handleRowPerPageChange}
        />
      </div>
    </>
  )
}

export default AuthGroupListTable
