import React, { FC } from 'react'
import { useParams } from 'react-router-dom'
import useStudentAttendance from '../../../../../../_hooks/useStudentAttendance'
import AttendanceTable from '../../../../../../_component/Table/AttendanceTable/AttendanceTable'

type props = {
  // entity: Student
}
const StudentAttendance: FC<props> = ({ }) => {
  const { id } = useParams()
  const { listAttendance } = useStudentAttendance({ user_id: id ? +id : 0 })

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>List Attendance</span>
        </h3>
        <div className='card-toolbar'>

        </div>
      </div>
      <div className='card-body py-3'>
        <AttendanceTable entities={listAttendance ?? []} totalCount={listAttendance ? listAttendance.length : 0} />
      </div>
    </div>
  )
  // if (entity) {
  // } else {
  //   return null
  // }
}

export default StudentAttendance