import SeriesDataSource from '../../_data/dataSource/SeriesDataSoruce'
import {BaseDatatableRequest} from '../../_data/dataSource/request/BaseDatatableRequest'
import {
  SeriesCreateRequest,
  SeriesUpdateRequest,
} from '../../_data/dataSource/request/SeriesRequest'
import {BaseDatatableResponse} from '../../_data/dataSource/response/BaseDatatableResponse'
import {Series, SeriesBuilder} from '../model/Series'
import {SeriesRepository} from './SeriesRepository'

export class SeriesRepositoryImpl implements SeriesRepository {
  datasource: SeriesDataSource
  
  constructor(datasource: SeriesDataSource) {
    this.datasource = datasource
  }

  async updateOne(req: SeriesUpdateRequest, id?: string): Promise<Series> {
    const response = await this.datasource.update(req, id)
    const initBuilder = new SeriesBuilder()
    const series = initBuilder.SingleData(response).Result()
    return series
  }

  async createOne(req: SeriesCreateRequest): Promise<Series> {
    const response = await this.datasource.create(req)
    const initBuilder = new SeriesBuilder()
    const series = initBuilder.SingleData(response).Result()
    return series
  }

  deleteOneById(id: string): Promise<string> {
    return this.datasource.deleteOne(id)
  }

  async getDatatable(req: BaseDatatableRequest): Promise<BaseDatatableResponse<Series>> {
    const response = await this.datasource.getDatatable(req)
    const initBuilder = new SeriesBuilder()
    const entities = initBuilder.ListData(response.entities).ResultList()
    const totalCount = response.totalCount
    return {
      entities,
      totalCount,
      errorMessage: response.errorMessage,
    }
  }

  async getOneById(id: string): Promise<Series> {
    const response = await this.datasource.getOne(id)
    const initBuilder = new SeriesBuilder()
    const series = initBuilder.SingleData(response).Result()
    return series
  }

  async getAll(): Promise<Series[]> {
    const response = await this.datasource.getAll()
    const initBuilder = new SeriesBuilder()
    const series = initBuilder.ListData(response).ResultList()
    return series
  }
}
