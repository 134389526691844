import {SeriesFormInput} from '../../../helpers/UISeriesConstanta'

export type SeriesCreateRequest = {
  name: string
  description?: string
}

export type SeriesUpdateRequest = {
  name: string
  description?: string
}

export function mappingInputFormToSeriesCreateRequest(req: SeriesFormInput): SeriesCreateRequest {
  return {
    name: req.nameInput ?? '',
    description: req.descriptionInput,
  }
}
export function mappingInputFormToSeriesUpdateRequest(req: SeriesFormInput): SeriesUpdateRequest {
  return {
    name: req.nameInput ?? '',
    description: req.descriptionInput,
  }
}
