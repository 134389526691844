import {team} from '../../../../../_redux_slice'
import TeamDataSourceImpl from '../../../../../_data/dataSource/TeamDataSourceImpl'
import {TeamRepositoryImpl} from '../../../../../_domain/repository/TeamRepositoryImpl'
import {TeamServiceImpl} from '../../../../../_domain/service/TeamService'
import {BaseDatatableRequest} from '../../../../../_data/dataSource/request/BaseDatatableRequest'
import {
  TEAM_CREATE_FAILED,
  TEAM_CREATE_SUCCESS,
  TEAM_DELETE_FAILED,
  TEAM_DELETE_SUCCESS,
  TEAM_EDIT_FAILED,
  TEAM_EDIT_SUCCESS,
  TEAM_GET_FAILED,
} from '../../../../../_domain/constanta'
import {TeamFormInput} from '../../../../../helpers/UITeamConstanta'
import {alertError, alertSuccess, errorHandling} from '../../../../../helpers/FuncHelpers'
import {AlertPromise} from '../../../../../helpers/classHelpers'
import AuthDataSourceImpl from '../../../../../_data/dataSource/AuthDataSourceImpl'
import {UserRepositoryImpl} from '../../../../../_domain/repository/UserRepositoryImpl'
import {UserServiceImpl} from '../../../../../_domain/service/UserService'

const {actions} = team.slice
const callTypes = team.callTypes
const dataSource = new TeamDataSourceImpl()
const repository = new TeamRepositoryImpl(dataSource)
const service = new TeamServiceImpl(repository)

const dataSourceUser = new AuthDataSourceImpl()
const repositoryUser = new UserRepositoryImpl(dataSourceUser)
const serviceUser = new UserServiceImpl(repositoryUser)

const alertPromise = new AlertPromise()
export const getAllTeamDatatable = (queryParams: BaseDatatableRequest) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.list}))

  return service
    .getDatatableTeam(queryParams)
    .then((response) => {
      const {totalCount, entities} = response
      dispatch(actions.saveList({totalCount, entities}))

      alertPromise.success()

      return response
    })
    .catch((err) => {
      if (err.response) {
        errorHandling(err.response, TEAM_GET_FAILED)
      } else {
        alertPromise.error()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const getOneTeam = (id: number | undefined) => (dispatch: any) => {
  if (!id) {
    return dispatch(actions.saveObject({data: undefined}))
  }

  dispatch(actions.startCall({callType: callTypes.action}))
  return service
    .getDetailTeamById(id)
    .then((response) => {
      const team = response
      dispatch(actions.saveObject({obj: team}))
      return team
    })
    .catch((err) => {
      if (err.response) {
        errorHandling(err.response, TEAM_GET_FAILED)
      } else {
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const getOneUserTeam = (id: number | undefined) => (dispatch: any) => {
  if (!id) {
    return dispatch(actions.saveObject({data: undefined}))
  }

  dispatch(actions.startCall({callType: callTypes.action}))
  return serviceUser
    .getOneUser(id)
    .then((response) => {
      const user = response
      // dispatch(actions.saveObject({obj: team}))
      return user
    })
    .catch((err) => {
      if (err.response) {
        errorHandling(err.response, TEAM_GET_FAILED)
      } else {
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const createTeam = (req: TeamFormInput) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.action}))
  return service
    .createOneTeam(req)
    .then((response) => {
      const team = response
      dispatch(actions.createObject({data: team}))
      alertPromise.stop()
      alertSuccess(TEAM_CREATE_SUCCESS)
    })
    .catch((err) => {
      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, TEAM_CREATE_FAILED)
      } else {
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const updateTeam = (req: TeamFormInput, id: string) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.action}))
  return service
    .updateOneTeam(req, id)
    .then((response) => {
      const team = response
      dispatch(actions.updateObject({data: team}))
      alertPromise.stop()
      alertSuccess(TEAM_EDIT_SUCCESS)
    })
    .catch((err) => {
      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, TEAM_EDIT_FAILED)
      } else {
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const updateRoleTeam = (req: any, id: string) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.action}))
  return serviceUser
    .updateRoleUser(id, req)
    .then((response) => {
      alertPromise.stop()
      alertSuccess(TEAM_EDIT_SUCCESS)
    })
    .catch((err) => {
      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, TEAM_EDIT_FAILED)
      } else {
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const updateCustomRoleTeam = (req: any, id: string) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.action}))
  return service
    .updateCustomRoleTeam(id, req)
    .then((response) => {
      alertPromise.stop()
      alertSuccess(TEAM_EDIT_SUCCESS)
    })
    .catch((err) => {
      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, TEAM_EDIT_FAILED)
      } else {
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const deleteOneTeam = (id: number) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.action}))
  return service
    .deleteOneTeamByID(id)
    .then(() => {
      alertPromise.stop()
      alertSuccess(TEAM_DELETE_SUCCESS)
      dispatch(actions.delete({id}))
    })
    .catch((err) => {
      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, TEAM_DELETE_FAILED)
      } else {
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}
