import {SchoolFormInput} from '../../../helpers/UISchoolConstanta'

export type SchoolCreateRequest = {
  name: string
  city_id: string
}

export type SchoolUpdateRequest = {
  name: string
  city_id: string
}

export type SchoolFilterRequest = {
  province_id?: string
  city_id?: string
  search_text?: string
}

export type SchoolGetAllParams = {
  province_id?: string
  city_id?: string
}

export function mappingInputFormToSchoolCreateRequest(req: SchoolFormInput): SchoolCreateRequest {
  return {
    name: req.nameInput ?? '',
    city_id: req.cityInput ?? '',
  }
}
export function mappingInputFormToSchoolUpdateRequest(req: SchoolFormInput): SchoolUpdateRequest {
  return {
    name: req.nameInput ?? '',
    city_id: req.cityInput ?? '',
  }
}
