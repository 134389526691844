import axios from 'axios'
import PartnerDataSource from './PartnerDataSoruce'
import {PartnerResponse} from './response/PartnerResponse'
import {ApiResponse} from './response/BaseResponse'
import {BaseDatatableRequest} from './request/BaseDatatableRequest'
import {BaseDatatableResponse} from './response/BaseDatatableResponse'
import {MessageResponse} from './response/MessageResponse'
import {PartnerCreateRequest, PartnerUpdateRequest} from './request/PartnerRequest'

export default class PartnerDataSourceImpl implements PartnerDataSource {
  private PARTNER_URL: string
  constructor() {
    const service_partner_url = process.env.REACT_APP_PARTNER_SERVICE_URL || 'api/'
    this.PARTNER_URL = `${service_partner_url}partners`
  }
  async getOneByUserId(userId: string | number): Promise<PartnerResponse> {
    const response = await axios.get<ApiResponse<PartnerResponse>>(`${this.PARTNER_URL}/${userId}/user`)
    const data = response.data.data
    return data
  }

  async update(req: PartnerUpdateRequest, id: string): Promise<PartnerResponse> {
    const response = await axios.put<ApiResponse<PartnerResponse>>(
      `${this.PARTNER_URL}/${id}`,
      req
    )

    const data = response.data.data
    return data
  }

  async deleteOne(id: string): Promise<string> {
    const response = await axios.delete<MessageResponse>(`${this.PARTNER_URL}/${id}`)
    return response.data.message
  }

  async getDatatable(req: BaseDatatableRequest): Promise<BaseDatatableResponse<PartnerResponse>> {
    const response = await axios.post<ApiResponse<BaseDatatableResponse<PartnerResponse>>>(
      `${this.PARTNER_URL}/datatable`,
      req
    )

    const data = response.data.data

    return data
  }

  async getOne(id: string): Promise<PartnerResponse> {
    const response = await axios.get<ApiResponse<PartnerResponse>>(`${this.PARTNER_URL}/${id}`)
    const data = response.data.data
    return data
  }

  async getAll(): Promise<PartnerResponse[]> {
    const response = await axios.get<ApiResponse<PartnerResponse[]>>(`${this.PARTNER_URL}`)
    const data = response.data.data
    return data
  }

  async create(req: PartnerCreateRequest): Promise<PartnerResponse> {
    const response = await axios.post<ApiResponse<PartnerResponse>>(`${this.PARTNER_URL}`, req)
    const data = response.data.data

    return data
  }
}
