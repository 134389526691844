import AuthDataSource from '../../_data/dataSource/AuthDataSoruce'
import AuthDataSourceImpl from '../../_data/dataSource/AuthDataSourceImpl'
import StorageDataSource from '../../_data/dataSource/StorageDataSource'
import StudentDataSource from '../../_data/dataSource/StudentDataSoruce'
import {BaseDatatableRequest} from '../../_data/dataSource/request/BaseDatatableRequest'
import {saveObjectRequest} from '../../_data/dataSource/request/StorageRequest'
import {
  StudenUpdateClassRequest,
  StudenUpdateRequest,
} from '../../_data/dataSource/request/StudentRequest'
import {BaseDatatableResponse} from '../../_data/dataSource/response/BaseDatatableResponse'
import {StorageSaveObjectResponse} from '../../_data/dataSource/response/StorageResponse'
import {UploadBatchResponse} from '../../_data/dataSource/response/StudentResponse'
import {Student, StudentBuilder} from '../model/Student'
import {StorageRepository} from './StorageRepository'
import {StudentRepository} from './StudentRepository'

export class StorageRepositoryImpl implements StorageRepository {
  datasource: StorageDataSource

  constructor(datasource: StorageDataSource) {
    this.datasource = datasource
  }

  async saveObject(body: saveObjectRequest): Promise<StorageSaveObjectResponse | undefined> {
    const response = await this.datasource.saveObject(body)
    return response
    // const initBuilder = new StudentBuilder()
    // const student = initBuilder.SingleData(response).Result()
    // return student
  }
}
