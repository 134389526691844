import React from 'react'

export default function PartnerOverview() {
  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Overview</span>
        </h3>
        <div className='card-toolbar'>

        </div>
      </div>
      <div className='card-body py-3'>

      </div>
    </div>
  )
}