import {Suspense} from 'react'
import {Outlet} from 'react-router-dom'
import {LayoutProvider, LayoutSplashScreen} from '../_layout/core'
import {MasterInit} from '../_layout/MasterInit'
import {AuthInit} from '../_context/AuthContext'
import {Provider} from 'react-redux'
import store from '../redux/store'

const App = () => {
  return (
    <Provider store={store}>
      <Suspense fallback={<LayoutSplashScreen />}>
        <LayoutProvider>
          <AuthInit>
            <Outlet />
            <MasterInit />
          </AuthInit>
        </LayoutProvider>
      </Suspense>
    </Provider>
  )
}

export {App}
