const CHAPTER_DELETE_TITLE = 'Chapter Delete'
const CHAPTER_LIST_TITLE = 'Chapter List'
const CHAPTER_DELETE_LOADING = 'Chapter is deleting...'
const CHAPTER_DELETE_CONFIRM = 'Are you sure to permanently delete this chapter ?'
const CHAPTER_DELETE_SUCCESS = 'Successful delete chapter'
const CHAPTER_DELETE_FAILED = "Can't delete chapter"
const CHAPTER_CREATE_TITLE = 'Chapter Create'
const CHAPTER_CREATE_FAILED = "Can't create chapter"
const CHAPTER_CREATE_SUCCESS = 'Successful create chapter'
const CHAPTER_EDIT_TITLE = 'Chapter Edit'
const CHAPTER_EDIT_SUCCESS = 'Successful update chapter'
const CHAPTER_EDIT_FAILED = "Can't update chapter"
const CHAPTER_GET_FAILED = "Can't get chapter"
export {
  CHAPTER_DELETE_TITLE,
  CHAPTER_LIST_TITLE,
  CHAPTER_DELETE_CONFIRM,
  CHAPTER_DELETE_LOADING,
  CHAPTER_DELETE_SUCCESS,
  CHAPTER_DELETE_FAILED,
  CHAPTER_CREATE_TITLE,
  CHAPTER_CREATE_SUCCESS,
  CHAPTER_CREATE_FAILED,
  CHAPTER_EDIT_TITLE,
  CHAPTER_EDIT_SUCCESS,
  CHAPTER_EDIT_FAILED,
  CHAPTER_GET_FAILED,
}
