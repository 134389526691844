import { PartnerResponse } from '../../_data/dataSource/response/PartnerResponse'

export type Partner = PartnerResponse

export class PartnerBuilder {
  private SinglePartner!: Partner
  private PartnerList!: Partner[]
  constructor() {}

  SingleData(partnerResponse: PartnerResponse): PartnerBuilder {
    const partner = mapingPartner(partnerResponse)
    this.SinglePartner = partner
    return this
  }
  ListData(partnerResponse: PartnerResponse[]): PartnerBuilder {
    const partners = mapingPartners(partnerResponse)
    this.PartnerList = partners
    return this
  }
  Result(): Partner {
    return this.SinglePartner
  }
  ResultList(): Partner[] {
    return this.PartnerList
  }
}

function mapingPartner(partnerResponse: PartnerResponse): Partner {
  const partner: Partner = partnerResponse
  return partner
}

function mapingPartners(partnersResponse: PartnerResponse[]): Partner[] {
  const partners = partnersResponse.map((item) => {
    return mapingPartner(item)
  })
  return partners
}
