const GROUP_ROLE_DELETE_TITLE = 'Group Role Delete'
const GROUP_ROLE_DELETE_LOADING = 'Group Role is deleting...'
const GROUP_ROLE_DELETE_CONFIRM = 'Are you sure to permanently delete this group role ?'
const GROUP_ROLE_DELETE_SUCCESS = 'Successful delete group role'
const GROUP_ROLE_DELETE_FAILED = "Can't delete group role"
const GROUP_ROLE_CREATE_TITLE = 'Group Role Create'
const GROUP_ROLE_CREATE_FAILED = "Can't create group role"
const GROUP_ROLE_CREATE_SUCCESS = 'Successful create group role'
const GROUP_ROLE_EDIT_TITLE = 'Group Role Edit'
const GROUP_ROLE_EDIT_SUCCESS = 'Successful update group role'
const GROUP_ROLE_EDIT_FAILED = "Can't update group role"
const GROUP_ROLE_GET_FAILED = "Can't get group role"
export {
  GROUP_ROLE_DELETE_TITLE,
  GROUP_ROLE_DELETE_CONFIRM,
  GROUP_ROLE_DELETE_LOADING,
  GROUP_ROLE_DELETE_SUCCESS,
  GROUP_ROLE_DELETE_FAILED,
  GROUP_ROLE_CREATE_TITLE,
  GROUP_ROLE_CREATE_SUCCESS,
  GROUP_ROLE_CREATE_FAILED,
  GROUP_ROLE_EDIT_TITLE,
  GROUP_ROLE_EDIT_SUCCESS,
  GROUP_ROLE_EDIT_FAILED,
  GROUP_ROLE_GET_FAILED,
}
