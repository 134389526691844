/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {PageTitle} from '../../../../../_layout/core'
import {Suspense} from 'react'
import CustomLoading from '../../../../../_component/customLoading'
import {Link, useOutletContext} from 'react-router-dom'
import {Result} from '../../../../../_domain/model/Result'
import {BaseDatatableResponse} from '../../../../../_data/dataSource/response/BaseDatatableResponse'
import {Nav} from 'react-bootstrap'
import {RESULT_TRYOUT_LIST_TITLE} from '../../../../../_domain/constanta/ResultTryoutConstant'
import {
  getAllTryoutScheduleBatchDatatable,
  getAllTryoutScheduleDatatable,
} from './TryoutResultAction'
import TryoutResultListFilter from './TryoutResultListComponent/TryoutResultListFilter'
// import { ResultTryoutScoreResponse } from '../../../../../_data/dataSource/response/ResultTryoutResponse'
import TryoutResultBatchListFilter from './TryoutResultListComponent/TryoutResultBatchListFilter'
import {useAuth} from '../../../../../_context/AuthContext'
import {checkAuthActionMenu} from '../../../../../helpers/AuthHelpers'
import {SLUG_TRYOUT_SUB_MENU} from '../../../../../helpers/UIAuthGroupConstanta'
import ResultTryoutDataSourceImpl from '../../../../../_data/dataSource/ResultTryoutDataSourceImpl'

const TryoutResultListTable = React.lazy(
  () => import('./TryoutResultListComponent/TryoutResultListTable')
)
const TryoutResultBatchListTable = React.lazy(
  () => import('./TryoutResultListComponent/TryoutResultBatchListTable')
)
const TryoutResultDetail = React.lazy(() => import('./TryoutResultDetail'))
// const TryoutResultScoreListTable = React.lazy(() =>
//   import('./TryoutResultListComponent/TryoutResultScoreListTable')
// )
const TryoutResultDelete = React.lazy(() => import('./TryoutResultDelete'))
const TryoutResultCalculate = React.lazy(() => import('./TryoutResultCalculate'))

const checkReduxData = () => {
  const outletContext = useOutletContext<any>().queryParams
  const {queryParams} = outletContext

  // detect redux first before fetch API
  const [tryoutResultListData, setTryoutResultListData] = useState<Result[] | null>(null)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch<any>(getAllTryoutScheduleDatatable(queryParams)).then(
      (response: BaseDatatableResponse<Result>) => {
        setTryoutResultListData(response.entities)
      }
    )
  }, [queryParams, getAllTryoutScheduleDatatable])

  return tryoutResultListData
  // detect redux first before fetch API END
}

const checkReduxDataBatch = () => {
  const outletContext = useOutletContext<any>().queryParams
  const {queryParamsBatch} = outletContext

  // detect redux first before fetch API
  const [tryoutResultListDataBatch, setTryoutResultListDataBatch] = useState<Result[] | null>(null)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch<any>(getAllTryoutScheduleBatchDatatable(queryParamsBatch)).then(
      (response: BaseDatatableResponse<Result>) => {
        setTryoutResultListDataBatch(response.entities)
      }
    )
  }, [queryParamsBatch, getAllTryoutScheduleDatatable])

  return tryoutResultListDataBatch
  // detect redux first before fetch API END
}

// const checkReduxDataScore = () => {
//   const outletContext = useOutletContext<any>().queryParams
//   const { queryParamsScore } = outletContext

//   const [tryoutResultScoreListData, setTryoutResultScoreListData] = useState<ResultTryoutScoreResponse[] | null>(null)
//   const dispatch = useDispatch()

//   useEffect(() => {
//     dispatch<any>(getAllTryoutScheduleBatchScoreDatatable(queryParamsScore)).then((response: BaseDatatableResponse<ResultTryoutScoreResponse>) => {
//       setTryoutResultScoreListData(response.entities)
//     })
//   }, [queryParamsScore, getAllTryoutScheduleBatchScoreDatatable])

//   return tryoutResultScoreListData
// }

const TryoutResultList = () => {
  const [tab, setTab] = useState('single')

  const outletContext = useOutletContext<any>().calculateSection
  const {showingCalculateModal} = outletContext

  let data = checkReduxData()
  let dataBatch = checkReduxDataBatch()
  const {currentUser, authorization} = useAuth()
  // let dataScore = checkReduxDataScore()
  return checkAuthActionMenu(
    SLUG_TRYOUT_SUB_MENU.TRYOUT_RESULT_MENU,
    'view',
    currentUser,
    authorization
  ) ? (
    <>
      <TryoutResultDetail />
      <PageTitle breadcrumbs={[]}>{RESULT_TRYOUT_LIST_TITLE}</PageTitle>
      <Suspense fallback={<CustomLoading />}>
        <Nav variant='pills' activeKey={tab} className='mb-4'>
          <Nav.Item>
            <Nav.Link eventKey={'single'} title='Single Result' onClick={() => setTab('single')}>
              Single Result
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey={'batch'} title='Batch Result' onClick={() => setTab('batch')}>
              Batch Result
            </Nav.Link>
          </Nav.Item>
          {/* <Nav.Item>
            <Nav.Link eventKey={"score"} title="Score Result" onClick={() => setTab("score")}>
              Score Result
            </Nav.Link>
          </Nav.Item> */}
        </Nav>
        <TryoutResultDelete />
        <TryoutResultCalculate />
        <div className='d-flex justify-content-between align-items-center mb-4'>
          {tab === 'single' && <TryoutResultListFilter />}
          {tab === 'batch' && (
            <>
              <TryoutResultBatchListFilter />
            </>
          )}

          <button
            type='button'
            onClick={() => showingCalculateModal()}
            className='btn btn-sm btn-primary'
          >
            Calculate Schedule
          </button>
        </div>
        {tab === 'single' && data && (
          <>
            <TryoutResultListTable />
          </>
        )}
        {tab === 'batch' && dataBatch && (
          <>
            <TryoutResultBatchListTable />
          </>
        )}
        {/* {tab === "score" && dataScore && (
          <TryoutResultScoreListTable />
        )} */}
      </Suspense>
    </>
  ) : null
}

export {TryoutResultList}
