import {series} from '../../../../../_redux_slice'
import SeriesDataSourceImpl from '../../../../../_data/dataSource/SeriesDataSourceImpl'
import {SeriesRepositoryImpl} from '../../../../../_domain/repository/SeriesRepositoryImpl'
import {SeriesServiceImpl} from '../../../../../_domain/service/SeriesService'
import {BaseDatatableRequest} from '../../../../../_data/dataSource/request/BaseDatatableRequest'
import {
  SERIES_CREATE_FAILED,
  SERIES_CREATE_SUCCESS,
  SERIES_DELETE_SUCCESS,
  SERIES_EDIT_FAILED,
  SERIES_EDIT_SUCCESS,
  SERIES_GET_FAILED,
} from '../../../../../_domain/constanta/SeriesConstant'
import {SeriesFormInput} from '../../../../../helpers/UISeriesConstanta'
import {alertError, alertSuccess, errorHandling} from '../../../../../helpers/FuncHelpers'
import {AlertPromise} from '../../../../../helpers/classHelpers'

const {actions} = series.slice
// const controlAction = control.slice.actions
const callTypes = series.callTypes
const dataSource = new SeriesDataSourceImpl()
const repository = new SeriesRepositoryImpl(dataSource)
const service = new SeriesServiceImpl(repository)

const alertPromise = new AlertPromise()
export const getAllDatatableSeries = (queryParams: BaseDatatableRequest) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.list}))
  return service
    .getDatatableSeries(queryParams)
    .then((response) => {
      const {totalCount, entities} = response
      dispatch(actions.saveList({totalCount, entities}))

      alertPromise.success()
      return response
    })
    .catch((err) => {
      if (err.response) {
        errorHandling(err.response, SERIES_GET_FAILED)
      } else {
        alertPromise.error()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const getOneSeries = (id?: string) => (dispatch: any) => {
  if (!id) {
    return dispatch(actions.saveObject({data: undefined}))
  }

  dispatch(actions.startCall({callType: callTypes.action}))
  return service
    .getDetailSeriesById(id)
    .then((response) => {
      const series = response
      dispatch(actions.saveObject({data: series}))
      return series
    })
    .catch((err) => {
      if (err.response) {
        errorHandling(err.response, SERIES_GET_FAILED)
      } else {
        console.error(err)
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const createSeries = (req: SeriesFormInput) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.action}))
  return service
    .createOneSeries(req)
    .then((response) => {
      const series = response
      dispatch(actions.createObject({data: series}))
      alertPromise.stop()
      alertSuccess(SERIES_CREATE_SUCCESS)
    })
    .catch((err) => {
      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, SERIES_CREATE_FAILED)
      } else {
        console.error(err)
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const updateSeries = (req: SeriesFormInput, id: string) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.action}))
  return service
    .updateOneSeries(req, id)
    .then((response) => {
      const series = response
      dispatch(actions.updateObject({data: series}))
      alertPromise.stop()
      alertSuccess(SERIES_EDIT_SUCCESS)
    })
    .catch((err) => {
      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, SERIES_EDIT_FAILED)
      } else {
        console.error(err)
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const deleteOneSeries = (id: string) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.action}))
  return service
    .deleteOneSeriesByID(id)
    .then(() => {
      alertPromise.stop()
      alertSuccess(SERIES_DELETE_SUCCESS)
      dispatch(actions.delete({id}))
    })
    .catch((err) => {
      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, SERIES_EDIT_FAILED)
      } else {
        console.error(err)
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

// export const deleteBatchSeries = (ids) => (dispatch) => {
//   dispatch(actions.startCall({callType: callTypes.action}))
//   return seriesAPI
//     .deleteMany(ids)
//     .then(() => {
//       alertSuccess('Successful delete series')
//       dispatch(actions.deleteMany({ids}))
//     })
//     .catch((error) => {
//       error.clientMessage = "Can't delete series"
//       alertError(error.clientMessage)
//       dispatch(actions.catchError({error, callType: callTypes.action}))
//     })
// }
