import {useCallback, useEffect, useState} from 'react'
import CityDataSourceImpl from '../_data/dataSource/CityDataSourceImpl'
import {City} from '../_domain/model/City'
import {CityGetAllParams} from '../_data/dataSource/request/CityRequest'
import { CityRepositoryImpl } from '../_domain/repository/CityRepositoryImpl'

type Props = {
  params?: CityGetAllParams
}

const useCity = (props?: Props) => {
  const [list, setList] = useState<City[]>([])

  const dataSource = new CityDataSourceImpl()
  const repo = new CityRepositoryImpl(dataSource)

  async function getAllCity(params?: CityGetAllParams) {
    const response = await repo.getAll(params)
    setList(response)
  }

  const changeProvinceId = useCallback(
    (params?: CityGetAllParams) => {
      getAllCity(params)
    },
    [getAllCity]
  )

  useEffect(() => {
    if (props?.params) {
      changeProvinceId(props.params)
    }
  }, [])

  return {
    listCity: list,
    getAllCity,
    changeProvinceId,
  }
}

export default useCity
