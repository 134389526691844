import {Controller, useForm} from 'react-hook-form'
import Form from 'react-bootstrap/Form'
import {useMemo} from 'react'
import {useOutletContext} from 'react-router-dom'
import {QuestionTryoutFilterRequest} from '../../../../../../_data/dataSource/request/QuestionTryoutRequest'
import {useSubjectUIContext} from '../../../../../../_context/SubjectUIContext'
import {useQuestionTryoutUIContext} from '../../../../../../_context/QuestionTryoutUIContext'
import {useChapterUIContext} from '../../../../../../_context/ChapterUIContext'
import {CLASS_STUDENT, SEMESTER} from '../../../../../../helpers/UIConstanta'

const TryoutQuestionListFilter = () => {
  const {listSubject} = useSubjectUIContext()
  const {listSeries} = useQuestionTryoutUIContext()
  const {listChapter, getListChapterFilter} = useChapterUIContext()
  const outletContext = useOutletContext<any>().filter
  const {applyFilter, queryParams} = outletContext

  const {
    control,
    setValue,
    getValues,
    formState: {errors},
  } = useForm({
    mode: 'onChange',
    defaultValues: useMemo(() => {
      return {
        classInput: queryParams.filter?.class_index ?? '',
        semesterInput: queryParams.filter?.semester ?? '1',
        subjectInput: queryParams.filter?.subject_id ?? '',
        seriesInput: queryParams.filter?.role ?? '',
        chapterInput: queryParams.filter?.chapter_id ?? '',
      }
    }, []),
  })

  // watching realtime form and set to outletcontext state
  // useEffect(() => {
  //   const formValueWatch = watch((value, { name, type }) => {

  //   }
  //     // outletContextFilter.setFormValue(value, name, type)
  //   )
  //   return () => formValueWatch.unsubscribe()
  // }, [watch])
  // watching realtime form and set to outletcontext state END

  function changeFilterState() {
    const classInput = getValues('classInput')
    const semesterInput = getValues('semesterInput')
    const subjectInput = getValues('subjectInput')
    const chapterInput = getValues('chapterInput')
    const seriesInput = getValues('seriesInput')

    if (classInput && semesterInput && subjectInput) {
      getListChapterFilter(+classInput + 1, +semesterInput, subjectInput, 'tryout')
    }
    if (classInput && semesterInput && subjectInput && chapterInput && seriesInput) {
      const {major, grade} = CLASS_STUDENT[+classInput]
      const filter: QuestionTryoutFilterRequest = {
        chapter_id: chapterInput,
        grade: grade === 'ALUMNI' ? 13 : +grade,
        major: major,
        role: seriesInput,
        semester: +semesterInput,
        subject_id: subjectInput,
        class_index: +classInput,
      }
      applyFilter(filter)
    }
  }

  return (
    <>
      <Form>
        <Form.Group className='row w-100'>
          {/* Class Field */}
          <div className='col-4'>
            <Controller
              name='classInput'
              control={control}
              render={({field}) => (
                <>
                  <Form.Label>Select Class</Form.Label>
                  <Form.Select
                    {...field}
                    className='mb-3'
                    onChange={(e) => {
                      setValue('classInput', e.target.value)
                      setValue('chapterInput', '')
                      changeFilterState()
                    }}
                  >
                    <option value=''>--Please Select Class--</option>
                    {CLASS_STUDENT.map((item, index) => (
                      <option key={index} value={index}>
                        {item.grade}-{item.level}
                        {item.major !== 'ALL' ? `-${item.major}` : ''}
                      </option>
                    ))}
                  </Form.Select>
                </>
              )}
            />
          </div>
          {/* Semester Field */}
          <div className='col-4'>
            <Controller
              name='semesterInput'
              control={control}
              render={({field}) => (
                <>
                  <Form.Label>Select Semester</Form.Label>
                  <Form.Select
                    {...field}
                    className='mb-3'
                    onChange={(e) => {
                      setValue('semesterInput', e.target.value)
                      setValue('chapterInput', '')
                      changeFilterState()
                    }}
                  >
                    <option value=''>--Please Select Semester--</option>
                    {SEMESTER.map((item, index) => {
                      return (
                        <option key={index} value={index + 1}>
                          {item}
                        </option>
                      )
                    })}
                  </Form.Select>
                </>
              )}
            />
          </div>
          {/* Subject Field */}
          <div className='col-4'>
            <Controller
              name='subjectInput'
              control={control}
              render={({field}) => (
                <>
                  <Form.Label>Select Subject</Form.Label>
                  <Form.Select
                    {...field}
                    className='mb-3'
                    onChange={(e) => {
                      setValue('subjectInput', e.target.value)
                      setValue('chapterInput', '')
                      changeFilterState()
                    }}
                  >
                    <option value=''>--Please Select Subject--</option>
                    {listSubject.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </Form.Select>
                </>
              )}
            />
          </div>
          {/* Series Field */}
          <div className='col-6'>
            <Controller
              name='seriesInput'
              control={control}
              render={({field}) => (
                <>
                  <Form.Label>Select Series</Form.Label>
                  <Form.Select
                    {...field}
                    className='mb-3'
                    onChange={(e) => {
                      setValue('seriesInput', e.target.value)
                      changeFilterState()
                    }}
                  >
                    <option value=''>--Please Select Series--</option>
                    {listSeries.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </Form.Select>
                </>
              )}
            />
          </div>
          {/* Chapter Field */}
          <div className='col-6'>
            <Controller
              name='chapterInput'
              control={control}
              render={({field}) => (
                <>
                  <Form.Label>Select Chapter</Form.Label>
                  <Form.Select
                    {...field}
                    className='mb-3'
                    onChange={(e) => {
                      setValue('chapterInput', e.target.value)
                      changeFilterState()
                    }}
                  >
                    {listChapter.length === 0 ? (
                      <option value='' disabled>
                        --Please Fill the Class,subject & semester Field First--
                      </option>
                    ) : (
                      <option value='' disabled>
                        --Please Select Chapter--
                      </option>
                    )}

                    {listChapter.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </Form.Select>
                </>
              )}
            />
          </div>
        </Form.Group>
      </Form>
    </>
  )
}

export default TryoutQuestionListFilter
