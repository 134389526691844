import React, { FC, useEffect, useMemo } from 'react'
import { PageTitle } from '../../../../../_layout/core'
import { Suspense } from 'react'
import CustomLoading from '../../../../../_component/customLoading'
import { Link, useNavigate, useOutletContext, useParams } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { getAllTryoutScheduleDatatable, getOneTryoutSchedule, updateTryoutSchedule } from './TryoutScheduleAction'
import { QuestionTryoutTableProvider } from '../../../../../_component/Table/QuestionTryoutTable/QuestionTryoutTableContext'
import { ScheduleTryoutFormInput } from '../../../../../helpers/UIScheduleTryoutConstanta'
import { SCHEDULE_TRYOUT_EDIT_TITLE } from '../../../../../_domain/constanta'

const TryoutScheduleEditForm = React.lazy(() =>
  import('./TryoutScheduleEditComponent/TryoutScheduleEditForm')
)

const TryoutScheduleEdit = () => {
  const { tryoutScheduleId } = useParams()
  const navigate = useNavigate();

  const filterContext = useOutletContext<any>().filter
  const { queryParams } = useMemo(() => {
    return {
      queryParams: filterContext.queryParams,
    };
  }, [filterContext]);

  const dispatch = useDispatch();
  const { actionsLoading, entity } = useSelector(
    (state: any) => ({
      actionsLoading: state.tryoutSchedule.actionsLoading,
      entity: state.tryoutSchedule.entity,
    }),
    shallowEqual
  );
  // if !id we should close modal
  useEffect(() => {
    dispatch<any>(getOneTryoutSchedule(tryoutScheduleId))
    return () => {
      dispatch<any>(getOneTryoutSchedule(undefined))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tryoutScheduleId]);

  const saveAction = (data: ScheduleTryoutFormInput) => {
    if (tryoutScheduleId) {
      dispatch<any>(updateTryoutSchedule(data, tryoutScheduleId)).then(() => {
        // refresh list after deletion
        dispatch<any>(getAllTryoutScheduleDatatable(queryParams));
        // Back
        navigate("/tryout/schedule")
      })

    }
  }

  return (
    <QuestionTryoutTableProvider>
      <PageTitle breadcrumbs={[]}>{SCHEDULE_TRYOUT_EDIT_TITLE}</PageTitle>
      <div className='d-flex justify-content-between align-items-center mb-4'>
        <div className='w-full'></div>
        <div>
          <Link to={"/tryout/schedule"}>
            <button type='button'
              className='btn btn-secondary'>
              Back
            </button>
          </Link>
        </div>
      </div>
      <Suspense fallback={<CustomLoading />}>
        {entity ? <TryoutScheduleEditForm dataSchedule={entity} onSubmit={saveAction} /> : null}
      </Suspense>
    </QuestionTryoutTableProvider>
  )
}

export { TryoutScheduleEdit }
