import React, { Suspense, useMemo } from 'react'
import { GROUP_CLASS_CREATE_TITLE } from '../../../../../_domain/constanta'
import { Link, useNavigate, useOutletContext } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { PageTitle } from '../../../../../_layout/core'
import CustomLoading from '../../../../../_component/customLoading'
import { createGroupClass, getAllGroupClassDatatable } from './GroupClassAction'
import { GroupClassFormInput } from '../../../../../helpers/UIGroupClassConstanta'

const GroupClassCreateForm = React.lazy(() => import('./GroupClassCreateComponent/GroupClassCreateForm'))

const GroupClassCreate = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch();

  const filterContext = useOutletContext<any>().queryParams
  const { queryParams } = useMemo(() => {
    return {
      queryParams: filterContext.queryParams,
    };
  }, [filterContext]);

  const saveAction = (data: GroupClassFormInput) => {
    dispatch<any>(createGroupClass(data)).then(() => {
      // refresh list after deletion
      dispatch<any>(getAllGroupClassDatatable(queryParams));
      // Back
      navigate("/master/group-class")
    })
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>{GROUP_CLASS_CREATE_TITLE}</PageTitle>
      <div className='d-flex justify-content-between align-items-center mb-4'>
        <div className='w-full'></div>
        <div>
          <Link to={"/master/group-class"}>
            <button type='button'
              className='btn btn-secondary'>
              Back
            </button>
          </Link>
        </div>
      </div>
      <Suspense fallback={<CustomLoading />}>
        <GroupClassCreateForm onSubmit={saveAction} />
      </Suspense>
    </>
  )
}

export default GroupClassCreate 
