import React, { FC, useMemo, useState } from 'react'
import { toAbsoluteUrl } from '../../../../../../../_layout/helpers'
import { Control, Controller, UseFormGetValues, UseFormSetValue, useForm } from 'react-hook-form'
import { Accordion, Alert, Form, InputGroup } from 'react-bootstrap'
import { StudentAccessUpdateFormInput } from '../../../../../../../helpers/UIStudentConstanta'
import useFacility from '../../../../../../../_hooks/useFacility'
import { StudentRole } from '../../../../../../../_domain/model/StudentRole'
import { Facility } from '../../../../../../../_domain/model/Facility'
import useGroupRole from '../../../../../../../_hooks/useGroupRole'

type props = {
  studentRole: StudentRole
  onSubmit: (req: any) => void
}

function getIndexRole(roles: any[], slug: string): number {
  const idx = roles?.findIndex((item) => item.slug === slug)
  if (idx !== undefined && idx > -1) {
    return idx
  }
  return -1
}

const StudentAccess: FC<props> = ({ studentRole, onSubmit }) => {
  const { listFacility } = useFacility()
  const { listGroupRole } = useGroupRole();

  const [rolesSelected, setRolesSelected] = useState<any[] | undefined>(studentRole.roles);
  const [isCustom, setIsCustom] = useState(studentRole.group_id === "" ? true : false);
  const { handleSubmit, control, getValues, setValue, formState: { errors } } = useForm<StudentAccessUpdateFormInput>({
    mode: "onChange",
    defaultValues: useMemo(() => {
      return {
        studentRole: studentRole.roles,
        groupIdInput: studentRole.group_id,
      }
    }, [])
  });

  function appendRole(role: Facility, value: boolean) {
    let roles = getValues("studentRole");
    if (value) {
      roles?.push({
        ...role,
        _id: role.id,
        id: undefined,
      })
    } else {
      roles = roles?.filter((item) => item._id !== role.id)
    }
    setRolesSelected(roles);
    setValue("studentRole", roles)
  }

  function getGroupRoleData(id: string) {
    const groupRole = listGroupRole.find((item) => item.id === id)
    setRolesSelected(groupRole?.roles);
    setValue("studentRole", groupRole?.roles)
  }

  function onChangeGroupRole(value: string) {
    setValue("groupIdInput", value);
    if (value !== "custom") {
      getGroupRoleData(value);
      setIsCustom(false);
    }
  }

  function middlewareSubmit(values: any) {
    let groupIdInput = values.groupIdInput;
    if (listGroupRole.findIndex((item) => item.id === groupIdInput) === -1) {
      groupIdInput = ""
    }
    const tmp = {
      ...values,
      groupIdInput
    }
    onSubmit(tmp);
  }

  function onChangeCustom() {
    if (!isCustom) {
      setIsCustom(true);
      setValue("groupIdInput", "custom");
    }
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_connected_accounts'
        aria-expanded='true'
        aria-controls='kt_account_connected_accounts'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Student Access</h3>
        </div>
      </div>

      <div id='kt_account_connected_accounts' className='collapse show'>
        <Form onSubmit={handleSubmit(middlewareSubmit)}>
          <div className='card-body border-top p-9'>
            {/* Group Role Field */}
            <Controller
              name="groupIdInput"
              control={control}
              defaultValue='custom'
              render={({ field }) => (
                <Form.Group className='mb-3'>
                  <Form.Label>Group Role</Form.Label>
                  <Form.Select
                    {...field}
                    className='mb-3'
                    isInvalid={errors.groupIdInput ? true : false}
                    onChange={(e) => {
                      const value = e.target.value;
                      onChangeGroupRole(value);
                    }}
                  >
                    <option value="custom"> Custom </option>
                    {listGroupRole.map((val, idx) => (
                      <option key={idx} value={val.id}>{val.role_group_name}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
              )} />

            <div className='py-2'>
              {listFacility.map((item, index) => {
                const indexrole = getIndexRole(rolesSelected!, item.slug);
                const val = indexrole === -1 ? false : true;
                return (
                  <div key={item.id}>
                    <div className='d-flex flex-stack'>
                      <div className='d-flex'>
                        <img
                          src={toAbsoluteUrl('/media/svg/illustrations/sigma/volume-1.svg')}
                          className='w-30px me-6'
                          alt=''
                        />

                        <div className='d-flex flex-column'>
                          <a href='#' className='fs-5 text-dark text-hover-primary fw-bolder'>
                            {item.name}
                          </a>
                          <div className='fs-6 fw-bold text-gray-400'>Access for {item.name}</div>
                        </div>
                      </div>

                      <div className='d-flex justify-content-end'>
                        {/* Student Default Activation Field */}
                        <Controller
                          name={`studentActivationInput.${index}.slug`}
                          defaultValue={val}
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <div className='form-check form-check-solid form-switch'>
                              <input
                                className='form-check-input w-45px h-30px'
                                type='checkbox'
                                checked={val}
                                onChange={(e) => {
                                  onChange(e)
                                  appendRole(item, !val)
                                  onChangeCustom()
                                }}
                              />
                              <label className='form-check-label'></label>
                            </div>

                          )} />
                      </div>
                    </div>
                    <Alert variant="warning">
                      <p className='text-dark'>
                        If the field limitation is not filled in that mean, limit is <span className='text-gray-800 fw-bold'>UNLIMETED</span>. otherwise that mean is <span className='text-gray-800 fw-bold'>limited.</span>
                      </p>
                    </Alert>
                    <Limitation indexRole={indexrole} currentData={rolesSelected} setCurrentData={setRolesSelected} index={index} key={item.id} facility={item} control={control} setValue={setValue} getValues={getValues} onChangeCustom={onChangeCustom} />
                    <div className='separator separator-dashed my-5'></div>
                  </div>
                )
              })}

              <div className='d-flex flex-stack'>
              </div>
            </div>
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary'>
              Save Changes
            </button>
          </div>
        </Form>
      </div>
    </div>
  )
}

type propsLimitation = {
  index: number
  indexRole: number
  currentData: any
  facility?: any
  setCurrentData: (req: any) => void
  control: Control<StudentAccessUpdateFormInput, any>
  setValue: UseFormSetValue<StudentAccessUpdateFormInput>
  getValues: UseFormGetValues<StudentAccessUpdateFormInput>
  onChangeCustom: () => void
}
const Limitation: FC<propsLimitation> = ({ facility, indexRole, index, currentData, control, setValue, getValues, setCurrentData, onChangeCustom }) => {
  let defaultIsLimit = currentData[indexRole]?.isLimitInput === "true" ? true : false;
  const defaultValueLimit = indexRole !== -1 ? (currentData[indexRole]?.special_limitations?.limit ?? "0") : "0";

  function appendLimit(role: any, limit: string) {
    let roles = getValues("studentRole");

    roles = roles?.map((item) => {
      if (item._id === role.id) {

        return {
          ...item,
          special_limitations: {
            limit: +limit
          }
        }
      }
      return item
    })
    setCurrentData(roles);
    setValue("studentRole", roles)
  }

  function appendIsLimit(role: any, islimit: string) {
    let roles = getValues("studentRole");
    roles = roles?.map((item) => {
      if (item._id === role.id) {
        return {
          ...item,
          isLimitInput: islimit,
        }
      }
      return item
    })
    setCurrentData(roles);
    setValue("studentRole", roles)
  }

  return (
    <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header>Limitation</Accordion.Header>
        <Accordion.Body>
          {/* Status Limit */}
          <div className='mt-3'>
            <Controller
              name="isLimitInput"
              control={control}
              render={({ field }) => (
                <Form.Check // prettier-ignore
                  {...field}
                  type="switch"
                  checked={defaultIsLimit}
                  label="Have Limitation ?"
                  onChange={(e) => {
                    appendIsLimit(facility!, (!defaultIsLimit).toString())
                    onChangeCustom()
                  }}
                />
              )} />
          </div>

          {/* Limitation */}
          {defaultIsLimit && (
            <Controller
              name={`limitInput.${index}`}
              defaultValue={defaultValueLimit}
              control={control}
              render={({ field }) => (
                <InputGroup className="mb-3">
                  <InputGroup.Text>Max Limit</InputGroup.Text>
                  <Form.Control
                    {...field}
                    type='number'
                    value={defaultValueLimit}
                    placeholder='0'
                    onChange={(e) => {
                      appendLimit(facility!, e.target.value)
                      field.onChange(e)
                      onChangeCustom()
                    }}
                  />
                </InputGroup>

              )}
            />
          )}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}
export default StudentAccess