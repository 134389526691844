/* eslint-disable jsx-a11y/anchor-is-valid */
import { Outlet, Route, Routes } from 'react-router'
import { PageTitle } from '../../../../../_layout/core'
import { BannerList } from './BannerList'
import { createSectionContext, deleteSectionContext, editSectionContext, queryParamsContext } from './_outletContext'


const BannerContent = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>{'Banner'}</PageTitle>
      <Outlet context={{ queryParams: queryParamsContext(), deleteSection: deleteSectionContext(), createSection: createSectionContext(), editSection: editSectionContext() }} />
    </>
  )
}

const Banner = () => {
  return (
    <>
      <Routes>
        <Route element={<BannerContent />}>
          <Route index element={<BannerList />} />
        </Route>
      </Routes>
    </>
  )
}

export { Banner }
