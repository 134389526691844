const TYPE_TRYOUT_DELETE_TITLE = 'Type Tryout Delete'
const TYPE_TRYOUT_DELETE_LOADING = 'Type Tryout is deleting...'
const TYPE_TRYOUT_DELETE_CONFIRM = 'Are you sure to permanently delete this type tryout ?'
const TYPE_TRYOUT_DELETE_SUCCESS = 'Successful delete type tryout'
const TYPE_TRYOUT_DELETE_FAILED = "Can't delete type tryout"
const TYPE_TRYOUT_LIST_TITLE = 'Type Tryout List'
const TYPE_TRYOUT_CREATE_TITLE = 'Type Tryout Create'
const TYPE_TRYOUT_CREATE_FAILED = "Can't create type tryout"
const TYPE_TRYOUT_CREATE_SUCCESS = 'Successful create type tryout'
const TYPE_TRYOUT_EDIT_TITLE = 'Type Tryout Edit'
const TYPE_TRYOUT_EDIT_SUCCESS = 'Successful update type tryout'
const TYPE_TRYOUT_EDIT_FAILED = "Can't update type tryout"
const TYPE_TRYOUT_GET_FAILED = "Can't get type tryout"
export {
  TYPE_TRYOUT_DELETE_TITLE,
  TYPE_TRYOUT_DELETE_CONFIRM,
  TYPE_TRYOUT_DELETE_LOADING,
  TYPE_TRYOUT_DELETE_SUCCESS,
  TYPE_TRYOUT_DELETE_FAILED,
  TYPE_TRYOUT_CREATE_TITLE,
  TYPE_TRYOUT_CREATE_SUCCESS,
  TYPE_TRYOUT_CREATE_FAILED,
  TYPE_TRYOUT_EDIT_TITLE,
  TYPE_TRYOUT_EDIT_SUCCESS,
  TYPE_TRYOUT_EDIT_FAILED,
  TYPE_TRYOUT_GET_FAILED,
  TYPE_TRYOUT_LIST_TITLE,
}
