/* eslint-disable jsx-a11y/anchor-is-valid */
import { Outlet, Route, Routes } from 'react-router'
import { PageTitle } from '../../../../../_layout/core'
import { TeamList } from './TeamList'
import { createSectionContext, customAuthSectionContext, deleteSectionContext, editSectionContext, extendRoleSectionContext, queryParamsContext } from './_outletContext'

const TeamContent = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>{'Team'}</PageTitle>
      <Outlet context={{
        queryParams: queryParamsContext(),
        deleteSection: deleteSectionContext(),
        createSection: createSectionContext(),
        editSection: editSectionContext(),
        customAuthSection: customAuthSectionContext(),
        extendRoleSection: extendRoleSectionContext()
      }} />
    </>
  )
}

const Team = () => {
  return (
    <>
      <Routes>
        <Route element={<TeamContent />}>
          <Route index element={<TeamList />} />
        </Route>
      </Routes>
    </>
  )
}

export { Team }
