import {ChapterResponse} from '../../_data/dataSource/response/ChapterResponse'
import {typeChapterSwitch, typeMajorSwitch} from '../../helpers/FuncHelpers'
import {IChapterfiles} from '../../types'
import {MAJOR, TYPE_CHAPTER} from '../constanta'
import {Subject} from './Subject'

export type Chapter = {
  id: string
  subject_detail?: Pick<Subject, 'id' | 'name'>
  number_chapter: number
  name: string
  code: string
  type_chapter: TYPE_CHAPTER
  semester: number
  grade: number
  major: MAJOR
  status: 'publish' | 'draft'
  class_index?: number
  files?: IChapterfiles[]
  videos?: [
    {
      code_file: string
      description: string
      file_id: string
      path: string
      title: string
      type_file: 'video'
      url: string
      url_public: string
      _id: string
    }
  ]
}

export class ChapterBuilder {
  private SingleChapter!: Chapter
  private ChapterList!: Chapter[]
  private ChapterFilesList!: IChapterfiles[]
  constructor() {}

  SingleData(chapterResponse: ChapterResponse): ChapterBuilder {
    const chapter = mapingChapter(chapterResponse)
    this.SingleChapter = chapter
    return this
  }
  ListData(chapterResponse: ChapterResponse[]): ChapterBuilder {
    const chapters = mapingChapters(chapterResponse)
    this.ChapterList = chapters
    return this
  }
  Result(): Chapter {
    return this.SingleChapter
  }
  ResultList(): Chapter[] {
    return this.ChapterList
  }
  FileList(): IChapterfiles[] {
    return this.ChapterFilesList
  }
}

function mapingChapter(chapterResponse: ChapterResponse): Chapter {
  const chapter: Chapter = {
    id: chapterResponse._id,
    subject_detail: {
      id: chapterResponse.subject_id._id,
      name: chapterResponse.subject_id.name,
    },
    number_chapter: chapterResponse.number_chapter,
    name: chapterResponse.name,
    code: chapterResponse.code,
    type_chapter: typeChapterSwitch(chapterResponse.type_chapter),
    semester: chapterResponse.semester,
    grade: chapterResponse.grade,
    major: typeMajorSwitch(chapterResponse.major),
    status: chapterResponse.status,
    files: chapterResponse.files,
    videos: chapterResponse.videos,
  }
  return chapter
}

function mapingChapters(chaptersResponse: ChapterResponse[]): Chapter[] {
  const chapters = chaptersResponse.map((item) => {
    return mapingChapter(item)
  })
  return chapters
}
