import React, { useCallback, useEffect, useState } from 'react'
import { BaseDatatableRequest, defaultQueryParams, prepareFilterBase } from '../../../_data/dataSource/request/BaseDatatableRequest'
import { isEqual, isFunction } from 'lodash'
import StudentDataSourceImpl from '../../../_data/dataSource/StudentDataSourceImpl'
import { StudentRepositoryImpl } from '../../../_domain/repository/StudentRepositoryImpl'
import { Student } from '../../../_domain/model/Student'
import { AlertPromise } from '../../../helpers/classHelpers'
import { alertError } from '../../../helpers/FuncHelpers'
import ExcelJS from 'exceljs';
import { number } from 'yup'

const init = defaultQueryParams()
init.pageSize = 25
type Props = {
  initQueryParams?: BaseDatatableRequest
}
const useStudentTable = (props: Props) => {
  const { initQueryParams } = props
  const [queryParams, setQueryParamsBase] = useState(initQueryParams ? initQueryParams : init)
  const [entities, setEntities] = useState<Student[]>([])
  const [selected, setSelected] = useState<Student[]>([])
  const [rejectMessages, setRejectMessages] = useState<any[]>([])
  const [totalCount, setTotalCount] = useState<number>(0);

  const dataSource = new StudentDataSourceImpl()
  const repo = new StudentRepositoryImpl(dataSource)

  const setQueryParams = useCallback((nextQueryParams: any) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams)
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams
      }

      return nextQueryParams
    })
  }, [])

  const getListStudent = useCallback(
    async () => {
      const response = await repo.getDatatable(queryParams)
      setEntities(response.entities)
      setTotalCount(response.totalCount)
    },
    [setEntities, setTotalCount, queryParams],
  )

  const getListStudentSelected = useCallback(
    async (file: any) => {
      const alertPromise = new AlertPromise()
      alertPromise.start()
      try {
        const { students, reject } = await repo.uploadSelected(file)

        alertPromise.success()
        setSelected(students)
        setRejectMessages(reject)
      } catch (error) {
        console.error(error);
        alertPromise.stop()
        alertError()
      }
    },
    [setSelected, entities],
  )

  const getListStudentChecked = useCallback(
    async (file: any) => {
      const emails = await readExcel(file)
      console.log(emails);

      const alertPromise = new AlertPromise()
      alertPromise.start()
      try {
        const students = await repo.getAllFilter({
          params: [
            {
              key: "email",
              values: emails,
            },
          ],
        })
        alertPromise.success()
        setSelected(students)
      } catch (error) {
        console.error(error);
        alertPromise.stop()
        alertError()
      }
    },
    [setSelected, entities],
  )

  const readExcel = async (file: any): Promise<any[]> => {
    const workbook = new ExcelJS.Workbook();
    await workbook.xlsx.load(file);
    const worksheet = workbook.getWorksheet(1);
    const emails: any[] = []

    worksheet!.eachRow((row, rowNumber) => {
      if (rowNumber > 2) {
        emails.push(row.getCell(2).value?.toString().trim())
      }
    });
    return emails;
  }


  // queryParams, setQueryParams,
  const applyFilter = (values: any) => {
    const newQueryParams = prepareFilterBase(queryParams, values)

    if (!isEqual(newQueryParams, queryParams)) {
      newQueryParams.pageNumber = 1
      // update list by queryParams
      setQueryParams(newQueryParams)
    }
  }

  const handlePageChange = (page: number, perPage: number) => {
    const tmp = { ...queryParams }
    tmp.pageNumber = page
    setQueryParams(tmp)
  }

  const handleSortChange = (column: any, sortDirection: any) => {
    const tmp = { ...queryParams }
    tmp.sortOrder = sortDirection
    tmp.sortField = column.id
    setQueryParams(tmp)
  }

  const handleUploadSelected = (file: any) => {
    getListStudentSelected(file)
  }

  const handleUploadCheck = (file: any) => {
    getListStudentChecked(file)
  }

  useEffect(() => {
    getListStudent()
  }, [queryParams])


  const value = {
    queryParams,
    entities,
    setEntities,
    totalCount,
    setTotalCount,
    setQueryParamsBase,
    setQueryParams,
    applyFilter,
    handlePageChange,
    handleSortChange,
    handleUploadSelected,
    handleUploadCheck,
    selected,
    setSelected,
    rejectMessages
  }

  return value
}
export default useStudentTable