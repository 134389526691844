/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { PageTitle } from '../../../../../_layout/core'
import { getAllSchoolDatatable } from './SchoolAction'
import { Suspense, lazy } from 'react'
import CustomLoading from '../../../../../_component/customLoading'
import { useOutletContext } from 'react-router-dom'
import { School } from '../../../../../_domain/model/School'
import { BaseDatatableResponse } from '../../../../../_data/dataSource/response/BaseDatatableResponse'
import SchoolDelete from './SchoolDelete'
import { SCHOOL_LIST_TITLE } from '../../../../../_domain/constanta'
import { checkAuthActionMenu } from '../../../../../helpers/AuthHelpers'
import { SLUG_MASTER_SUB_MENU } from '../../../../../helpers/UIAuthGroupConstanta'
import { useAuth } from '../../../../../_context/AuthContext'
const SchoolListTable = React.lazy(() => import('./SchoolListComponent/SchoolListTable'))
const SchoolCreate = React.lazy(() => import('./SchoolCreate'))
const SchoolEdit = React.lazy(() => import('./SchoolEdit'))

const checkReduxData = () => {
  const filterContext = useOutletContext<any>().queryParams
  const { queryParams } = useMemo(() => {
    return {
      queryParams: filterContext.queryParams,
    };
  }, [filterContext]);

  const [schoolListData, setSchoolListData] = useState<any>(null)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch<any>(getAllSchoolDatatable(queryParams)).then((response: BaseDatatableResponse<School>) => {
      setSchoolListData(response.entities)
    })
  }, [queryParams, getAllSchoolDatatable])

  return schoolListData
  // detect redux first before fetch API END
}

const SchoolList = () => {
  let data = checkReduxData()
  const { currentUser, authorization } = useAuth()

  return checkAuthActionMenu(SLUG_MASTER_SUB_MENU.MASTER_SCHOOL_MENU, "view", currentUser, authorization) ? (<>
    <SchoolCreate />
    <SchoolEdit />
    <SchoolDelete />
    <PageTitle breadcrumbs={[]}>{SCHOOL_LIST_TITLE}</PageTitle>
    <Suspense fallback={<CustomLoading />}>
      {data ? <SchoolListTable /> : null}
    </Suspense>
  </>) : null
}

export { SchoolList }
