import { useState } from "react"

const useEditSelectionDialog = () => {
  const [propsForEdit, setPropsForEdit] = useState({
    id: '',
    show: false,
  })

  function showingEditModal(id: string) {
    setPropsForEdit({
      id: id,
      show: true,
    })
  }

  function closingEditModal() {
    setPropsForEdit({
      id: '',
      show: false,
    })
  }

  return {
    propsForEdit,
    setPropsForEdit,
    showingEditModal,
    closingEditModal,
  }
}

export default useEditSelectionDialog