import {UserResponse} from '../../_data/dataSource/response/UserResponse'

export interface AuthModel {
  access_token: string
  refreshToken?: string
}

// export interface UserSocialNetworksModel {
//   linkedIn: string
//   facebook: string
//   twitter: string
//   instagram: string
// }

// export interface UserCommunicationModel {
//   email: boolean
//   sms: boolean
//   phone: boolean
// }

// export interface UserAddressModel {
//   addressLine: string
//   city: string
//   state: string
//   postCode: string
// }

// export interface UserEmailSettingsModel {
//   emailNotification?: boolean
//   sendCopyToPersonalEmail?: boolean
//   activityRelatesEmail?: {
//     youHaveNewNotifications?: boolean
//     youAreSentADirectMessage?: boolean
//     someoneAddsYouAsAsAConnection?: boolean
//     uponNewOrder?: boolean
//     newMembershipApproval?: boolean
//     memberRegistration?: boolean
//   }
//   updatesFromKeenthemes?: {
//     newsAboutKeenthemesProductsAndFeatureUpdates?: boolean
//     tipsOnGettingMoreOutOfKeen?: boolean
//     thingsYouMissedSindeYouLastLoggedIntoKeen?: boolean
//     newsAboutStartOnPartnerProductsAndOtherServices?: boolean
//     tipsOnStartBusinessProducts?: boolean
//   }
// }

export interface UserModel {
  id: number
  name: string
  password: string | undefined
  email: string
  phone_number?: string
  verification_date?: string
  referral_code_regis?: string
  role: string
  fullname?: string
  occupation?: string
  companyName?: string
  roles?: Array<number>
  pic?: string
  language?: 'en' | 'de' | 'es' | 'fr' | 'ja' | 'zh' | 'ru'
  timeZone?: string
  website?: 'https://keenthemes.com'
  // emailSettings?: UserEmailSettingsModel
  auth?: AuthModel
  referral?: {
    id: number
    user_id: number
    referral_code: string
  }
  user_extend_role: any[]
  // communication?: UserCommunicationModel
  // address?: UserAddressModel
}

export class UserBuilder {
  private SingleUser!: UserModel
  private UserList!: UserModel[]
  constructor() {}

  SingleData(userResponse: UserResponse): UserBuilder {
    const user = mapingUser(userResponse)
    this.SingleUser = user
    return this
  }
  ListData(userResponse: UserResponse[]): UserBuilder {
    const users = mapingUsers(userResponse)
    this.UserList = users
    return this
  }
  Result(): UserModel {
    return this.SingleUser
  }
  ResultList(): UserModel[] {
    return this.UserList
  }
}

function mapingUser(userResponse: UserResponse): UserModel {
  const user: UserModel = {
    id: userResponse.id,
    name: userResponse.name,
    password: undefined,
    email: userResponse.email,
    role: userResponse.role,
    phone_number: userResponse.phone_number,
    verification_date: userResponse.verification_date,
    user_extend_role: userResponse.user_extend_role,
  }
  if (userResponse.referral) {
    user.referral = userResponse.referral
  }
  return user
}

function mapingUsers(usersResponse: UserResponse[]): UserModel[] {
  const users = usersResponse.map((item) => {
    return mapingUser(item)
  })
  return users
}
