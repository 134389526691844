import {OrderResponse} from '../../_data/dataSource/response/OrderResponse'
import {
  methodStudySwitch,
  orderStatusSwitch,
  paymentStatusSwitch,
  productModelSwitch,
  typeProductSwitch,
} from '../../helpers/FuncHelpers'
import {METHOD_STUDY, ORDER_STATUS, PAYMENT_STATUS, PRODUCT_MODEL, TYPE_PRODUCT} from '../constanta'

export type Order = {
  id: string
  installment: number
  amount: number
  status: ORDER_STATUS
  installment_detail: {
    id?: string
    installment: number
    amount: number
    status: PAYMENT_STATUS
    snap_url: string
    invoice_id?: number
    invoice_number?: string
    date_deadline?: any
    date_deadline_extend?: any
  }[]
  role_product: {
    name: string
    status: boolean
  }[]
  product_detail: {
    code_product: string
    name: string
    type_product: TYPE_PRODUCT
    class_product: number
    method_study: METHOD_STUDY
    product_model: PRODUCT_MODEL
  }
  user_id: number
  user_detail?: {
    id: number
    name: string
    role: string
    phone_number: string
    email: string
  }
  created_at?: any
}

export class OrderBuilder {
  private SingleOrder!: Order
  private OrderList!: Order[]
  constructor() {}

  SingleData(orderResponse: OrderResponse): OrderBuilder {
    const order = mapingOrder(orderResponse)
    this.SingleOrder = order
    return this
  }
  ListData(orderResponse: OrderResponse[]): OrderBuilder {
    const orders = mapingOrders(orderResponse)
    this.OrderList = orders
    return this
  }
  Result(): Order {
    return this.SingleOrder
  }
  ResultList(): Order[] {
    return this.OrderList
  }
}

function mapingOrder(orderResponse: OrderResponse): Order {
  const order: Order = {
    id: orderResponse.id,
    installment: orderResponse.installment,
    amount: orderResponse.amount,
    status: orderStatusSwitch(orderResponse.status),
    installment_detail: orderResponse.installment_detail.map((item) => {
      return {
        id: item.id,
        installment: item.installment,
        amount: item.amount,
        status: paymentStatusSwitch(item.status),
        snap_url: item.snap_url,
        invoice_id: item.invoice_id,
        invoice_number: item.invoice_number,
        date_deadline: item.date_deadline,
        date_deadline_extend: item.date_deadline_extend,
      }
    }),
    role_product: orderResponse.role_product,
    product_detail: {
      code_product: orderResponse.product_detail.code_product,
      name: orderResponse.product_detail.name,
      type_product: typeProductSwitch(orderResponse.product_detail.type_product),
      class_product: orderResponse.product_detail.class_product,
      method_study: methodStudySwitch(orderResponse.product_detail.method_study),
      product_model: productModelSwitch(orderResponse.product_detail.product_model),
    },
    user_id: orderResponse.user_id,
    user_detail: orderResponse.user_detail,
    created_at: orderResponse.created_at,
  }
  return order
}

function mapingOrders(ordersResponse: OrderResponse[]): Order[] {
  const orders = ordersResponse.map((item) => {
    return mapingOrder(item)
  })
  return orders
}
