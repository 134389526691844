import {TeacherResponse} from '../../_data/dataSource/response/TeacherResponse'

export type Teacher = TeacherResponse

export class TeacherBuilder {
  private SingleTeacher!: Teacher
  private TeacherList!: Teacher[]
  constructor() {}

  SingleData(teacherResponse: TeacherResponse): TeacherBuilder {
    const teacher = mapingTeacher(teacherResponse)
    this.SingleTeacher = teacher
    return this
  }
  ListData(teacherResponse: TeacherResponse[]): TeacherBuilder {
    const teachers = mapingTeachers(teacherResponse)
    this.TeacherList = teachers
    return this
  }
  Result(): Teacher {
    return this.SingleTeacher
  }
  ResultList(): Teacher[] {
    return this.TeacherList
  }
}

function mapingTeacher(teacherResponse: TeacherResponse): Teacher {
  const teacher: Teacher = teacherResponse
  return teacher
}

function mapingTeachers(teachersResponse: TeacherResponse[]): Teacher[] {
  const teachers = teachersResponse.map((item) => {
    return mapingTeacher(item)
  })
  return teachers
}
