import {postTest} from '../../../../../_redux_slice'
import {SchedulePostTestRepositoryImpl} from '../../../../../_domain/repository/SchedulePostTestRepositoryImpl'
import SchedulePostTestDataSourceImpl from '../../../../../_data/dataSource/SchedulePostTestDataSourceImpl'
import {SchedulePostTestServiceImpl} from '../../../../../_domain/service/SchedulePostTestService'
import {BaseDatatableRequest} from '../../../../../_data/dataSource/request/BaseDatatableRequest'
import {
  SCHEDULE_POST_TEST_CREATE_FAILED,
  SCHEDULE_POST_TEST_CREATE_SUCCESS,
  SCHEDULE_POST_TEST_DELETE_FAILED,
  SCHEDULE_POST_TEST_DELETE_SUCCESS,
  SCHEDULE_POST_TEST_EDIT_FAILED,
  SCHEDULE_POST_TEST_EDIT_SUCCESS,
  SCHEDULE_POST_TEST_GET_FAILED,
} from '../../../../../_domain/constanta'
import {ScheduleFormInput} from '../../../../../helpers/UIScheduleConstanta'
import {alertError, alertSuccess, errorHandling} from '../../../../../helpers/FuncHelpers'
import {AlertPromise} from '../../../../../helpers/classHelpers'
import ChapterDataSourceImpl from '../../../../../_data/dataSource/ChapterDataSourceImpl'
import {ChapterRepositoryImpl} from '../../../../../_domain/repository/ChapterRepositoryImpl'

const {actions} = postTest.slice
// const controlAction = control.slice.actions
const callTypes = postTest.callTypes
const dataSource = new SchedulePostTestDataSourceImpl()
const dataSourceChapter = new ChapterDataSourceImpl()
const repository = new SchedulePostTestRepositoryImpl(dataSource)
const repoChapter = new ChapterRepositoryImpl(dataSourceChapter)
const service = new SchedulePostTestServiceImpl(repository, repoChapter)

const alertPromise = new AlertPromise()
export const getAllPostTestDatatable = (queryParams: BaseDatatableRequest) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.list}))

  return service
    .getDatatableSchedulePostTest(queryParams)
    .then((response) => {
      const {totalCount, entities} = response
      dispatch(actions.saveList({totalCount, entities}))

      alertPromise.success()

      return response
    })
    .catch((err) => {
      if (err.response) {
        errorHandling(err.response, SCHEDULE_POST_TEST_GET_FAILED)
      } else {
        alertPromise.error()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const getOnePostTest = (id: string | undefined) => (dispatch: any) => {
  if (!id) {
    return dispatch(actions.saveObject({data: undefined}))
  }

  dispatch(actions.startCall({callType: callTypes.action}))
  return service
    .getDetailSchedulePostTestById(id)
    .then((response) => {
      const postTest = response
  
      dispatch(actions.saveObject({data: postTest}))
      return postTest
    })
    .catch((err) => {
      if (err.response) {
        errorHandling(err.response, SCHEDULE_POST_TEST_GET_FAILED)
      } else {
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const createPostTest = (req: ScheduleFormInput) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.action}))
  return service
    .createOneSchedulePostTest(req)
    .then((response) => {
      alertPromise.stop()
      const postTest = response
      dispatch(actions.createObject({data: postTest}))
      alertSuccess(SCHEDULE_POST_TEST_CREATE_SUCCESS)
    })
    .catch((err) => {
      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, SCHEDULE_POST_TEST_CREATE_FAILED)
      } else {
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const updatePostTest = (req: ScheduleFormInput, id: string) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.action}))
  return service
    .updateOneSchedulePostTest(req, id)
    .then((response) => {
      alertPromise.stop()
      const postTest = response
      dispatch(actions.updateObject({data: postTest}))
      alertSuccess(SCHEDULE_POST_TEST_EDIT_SUCCESS)
    })
    .catch((err) => {
      console.log(err)

      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, SCHEDULE_POST_TEST_EDIT_FAILED)
      } else {
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const deleteOnePostTest = (id: string) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.action}))
  return service
    .deleteOneSchedulePostTestByID(id)
    .then(() => {
      alertPromise.stop()
      alertSuccess(SCHEDULE_POST_TEST_DELETE_SUCCESS)
      dispatch(actions.delete({id}))
    })
    .catch((err) => {
      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, SCHEDULE_POST_TEST_DELETE_FAILED)
      } else {
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}
