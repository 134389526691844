import axios from 'axios'
import ParentDataSource from './ParentDataSoruce'
import {ParentResponse} from './response/ParentResponse'
import {ApiResponse} from './response/BaseResponse'
import {BaseDatatableRequest} from './request/BaseDatatableRequest'
import {BaseDatatableResponse} from './response/BaseDatatableResponse'
import {MessageResponse} from './response/MessageResponse'
import {ParentCreateRequest, ParentUpdateRequest} from './request/ParentRequest'
import {StudentResponse} from './response/StudentResponse'

export default class ParentDataSourceImpl implements ParentDataSource {
  private PARENT_URL: string
  constructor() {
    const service_parent_url = process.env.REACT_APP_STUDENT_SERVICE_URL || 'api/'
    this.PARENT_URL = `${service_parent_url}parents`
  }

  async addStudent(parent_user_id: number,userIds: number[]): Promise<string> {
    const response = await axios.post<MessageResponse>(`${this.PARENT_URL}/${parent_user_id}/student`, {
      student_user_ids: userIds,
    })
    return response.data.message
  }

  async getOneStudentsByUserId(userId: string | number): Promise<ParentResponse> {
    const response = await axios.get<ApiResponse<ParentResponse>>(
      `${this.PARENT_URL}/${userId}/student`
    )
    const data = response.data.data
    return data
  }

  async getOneByUserId(userId: string | number): Promise<ParentResponse> {
    const response = await axios.get<ApiResponse<ParentResponse>>(`${this.PARENT_URL}/${userId}`)
    const data = response.data.data
    return data
  }

  async update(req: ParentUpdateRequest, id: string): Promise<ParentResponse> {
    const response = await axios.put<ApiResponse<ParentResponse>>(`${this.PARENT_URL}/${id}`, req)

    const data = response.data.data
    return data
  }

  async deleteOne(id: string): Promise<string> {
    const response = await axios.delete<MessageResponse>(`${this.PARENT_URL}/${id}`)
    return response.data.message
  }

  async getDatatable(req: BaseDatatableRequest): Promise<BaseDatatableResponse<ParentResponse>> {
    const response = await axios.post<ApiResponse<BaseDatatableResponse<ParentResponse>>>(
      `${this.PARENT_URL}/datatable`,
      req
    )

    const data = response.data.data

    return data
  }

  async getDatatableSearchStudent(
    req: BaseDatatableRequest,
    userId?: number
  ): Promise<BaseDatatableResponse<StudentResponse>> {
    const response = await axios.post<ApiResponse<BaseDatatableResponse<StudentResponse>>>(
      `${this.PARENT_URL}/search-student-datatable/${userId}`,
      req
    )

    const data = response.data.data

    return data
  }

  async getOne(id: string): Promise<ParentResponse> {
    const response = await axios.get<ApiResponse<ParentResponse>>(`${this.PARENT_URL}/${id}`)
    const data = response.data.data
    return data
  }

  async getAll(): Promise<ParentResponse[]> {
    const response = await axios.get<ApiResponse<ParentResponse[]>>(`${this.PARENT_URL}`)
    const data = response.data.data
    return data
  }

  async create(req: ParentCreateRequest): Promise<ParentResponse> {
    const response = await axios.post<ApiResponse<ParentResponse>>(`${this.PARENT_URL}`, req)
    const data = response.data.data

    return data
  }
}
