import {MAJOR, METHOD_STUDY} from '../../../_domain/constanta'
import {methodStudySwitch, typeMajorSwitch} from '../../../helpers/FuncHelpers'
import {CLASS_STUDENT} from '../../../helpers/UIConstanta'
import {GroupClassFormInput} from '../../../helpers/UIGroupClassConstanta'

export type GroupClassCreateRequest = {
  name: string
  grade: number
  major: MAJOR
  method_study: METHOD_STUDY
  school_id: number
  section: string
  branch: string
}

export type GroupClassUpdateRequest = {
  name: string
  grade: number
  major: MAJOR
  method_study: METHOD_STUDY
  school_id: number
  section: string
  branch: string
}

// export type GroupClassFilterRequest = {
//   major?: MAJOR
//   grade?: string
//   method_study?: METHOD_STUDY
//   type_product?: PRODUCT_MODEL
//   status?: ORDER_STATUS
//   search_text?: string
// }

// export type GroupClassGetAllParams = {
//   major?: MAJOR
//   grade?: string
//   method_study?: METHOD_STUDY
//   type_product?: PRODUCT_MODEL
//   status?: ORDER_STATUS
// }

export function mappingInputFormToGroupClassCreateRequest(
  req: GroupClassFormInput
): GroupClassCreateRequest {
  const {major, grade} = CLASS_STUDENT[+req.classInput!]

  return {
    name: req.nameInput ?? '',
    major: typeMajorSwitch(major),
    grade: grade === 'ALUMNI' ? 13 : +grade,
    school_id: req.schoolInput?.at(0)?.id ?? 0,
    method_study: methodStudySwitch(req.methodStudyInput ?? ''),
    section: req.sectionInput || 'A',
    branch: req.branchInput || 'TEBET',
  }
}

export function mappingInputFormToGroupClassUpdateRequest(
  req: GroupClassFormInput
): GroupClassUpdateRequest {
  const {major, grade} = CLASS_STUDENT[+req.classInput!]

  return {
    name: req.nameInput ?? '',
    major: typeMajorSwitch(major),
    grade: grade === 'ALUMNI' ? 13 : +grade,
    school_id: req.schoolInput?.at(0)?.id ?? 0,
    method_study: methodStudySwitch(req.methodStudyInput ?? ''),
    section: req.sectionInput || 'A',
    branch: req.branchInput || 'TEBET',
  }
}

// export function mappingInputFormToGroupClassFilterRequest(
//   req: GroupClassFilterTableFormInput
// ): GroupClassFilterRequest {
//   const result: GroupClassFilterRequest = {}
//   if (req.classInput) {
//     const {major, grade} = CLASS_STUDENT[+req.classInput!]
//     result.major = typeMajorSwitch(major)
//     result.grade = grade === 'ALUMNI' ? '13' : grade
//   }
//   if (req.methodStudyInput) {
//     result.method_study = methodStudySwitch(req.methodStudyInput)
//   }
//   if (req.typeProductInput) {
//     result.type_product = productModelSwitch(req.typeProductInput)
//   }
//   if (req.statusInput) {
//     result.status = orderStatusSwitch(req.statusInput)
//   }
//   if (req.textInput) {
//     result.search_text = req.textInput
//   }
//   return result
// }

// export function mappingInputFormToGroupClassFilterParamRequest(
//   req: StudentClassFilterTableFormInput
// ): GroupClassGetAllParams {
//   const result: GroupClassFilterRequest = {}
//   if (req.classInput) {
//     const {major, grade} = CLASS_STUDENT[+req.classInput!]
//     result.major = typeMajorSwitch(major)
//     result.grade = grade === 'ALUMNI' ? '13' : grade
//   }
//   if (req.methodStudyInput) {
//     result.method_study = methodStudySwitch(req.methodStudyInput)
//   }
//   if (req.typeProductInput) {
//     result.type_product = productModelSwitch(req.typeProductInput)
//   }
//   if (req.statusInput) {
//     result.status = orderStatusSwitch(req.statusInput)
//   }
//   return result
// }
