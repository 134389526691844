import React, { useMemo, useRef } from 'react'
import { useDispatch } from 'react-redux';
import { useOutletContext } from 'react-router-dom';
import { ModalBase } from '../../../../../_component/Modal';
import { SUBJECT_UPLOAD_BATCH_TITLE } from '../../../../../_domain/constanta';
import StudentUploadBatchFrom from './StudentCreateComponent/StudentUploadBatchFrom';
import { getAllStudentDatatable, trialTryoutAction } from './StudentAction';

const StudentUploadBatch = () => {
  const outletContext = useOutletContext<any>().uploadBatch
  const { propsForDialog, closingBaseModal } = useMemo(() => {
    return {
      propsForDialog: outletContext.propsForDialog,
      closingBaseModal: outletContext.closingBaseModal
    };
  }, [outletContext]);

  const filterContext = useOutletContext<any>().filter
  const { queryParams } = useMemo(() => {
    return {
      queryParams: filterContext.queryParams,
    };
  }, [filterContext]);


  const submitBtnRef = useRef<any>();
  const submitClick = () => {
    if (submitBtnRef && submitBtnRef.current) {
      submitBtnRef.current.click();
    }
  };
  const dispatch = useDispatch();

  const saveAction = (data: any) => {
    dispatch<any>(trialTryoutAction(data.file)).then(() => {
      // refresh list after deletion
      dispatch<any>(getAllStudentDatatable(queryParams));
      // closing delete modal
      closingBaseModal()
    })
  }

  return (
    <ModalBase size='lg' title={SUBJECT_UPLOAD_BATCH_TITLE} show={propsForDialog.show} onHide={closingBaseModal} onAction={submitClick} >
      <StudentUploadBatchFrom submitBtnRef={submitBtnRef} onSubmit={saveAction} />
    </ModalBase>
  )
}
export default StudentUploadBatch