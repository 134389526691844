import { useState } from "react"

const useDeleteSelectionDialog = () => {
  const [propsForDelete, setPropsForDelete] = useState({
    id: '',
    show: false,
  })

  function showingDeleteModal(id: string) {
    setPropsForDelete({
      id: id,
      show: true,
    })
  }

  function closingDeleteModal() {
    setPropsForDelete({
      id: '',
      show: false,
    })
  }

  return {
    propsForDelete,
    setPropsForDelete,
    showingDeleteModal,
    closingDeleteModal,
  }
}

export default useDeleteSelectionDialog
