import React, { Suspense, useEffect, useMemo, useRef } from 'react'
import { ORDER_EDIT_TITLE } from '../../../../../_domain/constanta'
import { Link, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { OrderEditFormInput } from '../../../../../helpers/UIOrderConstanta';
import { getAllOrderDatatable, getOneOrder, updateOrder } from './OrderAction';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { PageTitle } from '../../../../../_layout/core';
import CustomLoading from '../../../../../_component/customLoading';

const OrderEditForm = React.lazy(() => import('./OrderEditComponent/OrderEditForm'))

export default function OrderEdit() {
  const { orderId } = useParams()
  const navigate = useNavigate();

  const filterContext = useOutletContext<any>().queryParams
  const { queryParams } = useMemo(() => {
    return {
      queryParams: filterContext.queryParams,
    };
  }, [filterContext]);

  const dispatch = useDispatch();
  const { actionsLoading, entity } = useSelector(
    (state: any) => ({
      actionsLoading: state.orders.actionsLoading,
      entity: state.orders.entity,
    }),
    shallowEqual
  );

  // if !id we should close modal
  useEffect(() => {
    dispatch<any>(getOneOrder(orderId))
    return () => {
      dispatch<any>(getOneOrder(undefined))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  const saveAction = (data: OrderEditFormInput) => {
    if (orderId) {
      dispatch<any>(updateOrder(data, orderId)).then(() => {
        // refresh list after deletion
        dispatch<any>(getAllOrderDatatable(queryParams));
        // Back
        navigate("/transaction/order")
      })
    }
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>{ORDER_EDIT_TITLE}</PageTitle>
      <div className='d-flex justify-content-between align-items-center mb-4'>
        <div className='w-full'></div>
        <div>
          <Link to={"/transaction/order"}>
            <button type='button'
              className='btn btn-secondary'>
              Back
            </button>
          </Link>
        </div>
      </div>
      <Suspense fallback={<CustomLoading />}>
        {entity ? <OrderEditForm dataOrder={entity} onSubmit={saveAction} /> : null}
      </Suspense>
    </>
  )
}
