import {SchoolResponse} from '../../_data/dataSource/response/SchoolResponse'
import {City} from './City'
import {Province} from './Province'

export type School = {
  id: number
  name: string
  nspn?: string
  city_name?: string
  city_id: string
  city_detail?: City | any
  province_detail?: Province | any
}

export class SchoolBuilder {
  private SingleSchool!: School
  private SchoolList!: School[]
  constructor() {}

  SingleData(schoolResponse: SchoolResponse): SchoolBuilder {
    const school = mapingSchool(schoolResponse)
    this.SingleSchool = school
    return this
  }
  ListData(schoolResponse: SchoolResponse[]): SchoolBuilder {
    const schools = mapingSchools(schoolResponse)
    this.SchoolList = schools
    return this
  }
  Result(): School {
    return this.SingleSchool
  }
  ResultList(): School[] {
    return this.SchoolList
  }
}

function mapingSchool(schoolResponse: SchoolResponse): School {
  const school: School = {
    id: schoolResponse.id,
    name: schoolResponse.name,
    nspn: schoolResponse.nspn,
    city_id: schoolResponse.city_id,
    city_name: schoolResponse.city_name,
    city_detail: {
      id: schoolResponse.city?.id ?? 0,
      province_id: schoolResponse.city?.province_id ?? 0,
      name: schoolResponse.city?.name ?? '',
    },
    province_detail: {
      id: schoolResponse.city?.province?.id ?? 0,
      name: schoolResponse.city?.province?.name ?? '',
    },
  }
  return school
}

function mapingSchools(schoolsResponse: SchoolResponse[]): School[] {
  const schools = schoolsResponse.map((item) => {
    return mapingSchool(item)
  })
  return schools
}
