/* eslint-disable jsx-a11y/anchor-is-valid */

import { Navigate, Outlet, Route, Routes } from 'react-router'
import { PageTitle } from '../../../../../_layout/core'
import { TryoutScheduleList } from './TryoutScheduleList'
import { TryoutScheduleCreate } from './TryoutScheduleCreate'
import {TryoutScheduleEdit} from './TryoutScheduleEdit'
import { deleteSectionContext, queryParamsContext } from './_outletContext'
import { QuestionTryoutUIProvider } from '../../../../../_context/QuestionTryoutUIContext'
import { TryoutScheduleBacthEdit } from './TryoutScheduleBatchEdit'

const TryoutScheduleContent = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>{'TryoutSchedule'}</PageTitle>
      <Outlet
        context={{
          // create: tryoutScheduleCreateContext(),
          filter: queryParamsContext(),
          deleteSection: deleteSectionContext(),
        }}
      />
    </>
  )
}

const TryoutSchedule = () => {
  return (
    <QuestionTryoutUIProvider>
      <Routes>
        <Route element={<TryoutScheduleContent />}>
          <Route index element={<TryoutScheduleList />} />
          <Route path='create' element={<TryoutScheduleCreate />} />
          <Route path='edit/:tryoutScheduleId' element={<TryoutScheduleEdit />} />
          <Route path='edit/:tryoutScheduleId/batch' element={<TryoutScheduleBacthEdit />} />
          <Route path='*' element={<Navigate to='/error/404' />} />
        </Route>
      </Routes>
    </QuestionTryoutUIProvider>
  )
}

export { TryoutSchedule }
