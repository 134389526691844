import {AuthGroupResponse} from '../../_data/dataSource/response/AuthGroupResponse'

export type AuthGroup = {
  id: string
  name: string
  authorization: any
  main_role: string
}

export class AuthGroupBuilder {
  private SingleAuthGroup!: AuthGroup
  private AuthGroupList!: AuthGroup[]
  constructor() {}

  SingleData(authGroupResponse: AuthGroupResponse): AuthGroupBuilder {
    const authGroup = mapingAuthGroup(authGroupResponse)
    this.SingleAuthGroup = authGroup
    return this
  }
  ListData(authGroupResponse: AuthGroupResponse[]): AuthGroupBuilder {
    const authGroups = mapingAuthGroups(authGroupResponse)
    this.AuthGroupList = authGroups
    return this
  }
  Result(): AuthGroup {
    return this.SingleAuthGroup
  }
  ResultList(): AuthGroup[] {
    return this.AuthGroupList
  }
}

function mapingAuthGroup(authGroupResponse: AuthGroupResponse): AuthGroup {
  const authGroup: AuthGroup = {
    id: authGroupResponse.id,
    name: authGroupResponse.name,
    main_role: authGroupResponse.main_role,
    authorization: authGroupResponse.authorization,
  }
  return authGroup
}

function mapingAuthGroups(authGroupsResponse: AuthGroupResponse[]): AuthGroup[] {
  const authGroups = authGroupsResponse.map((item) => {
    return mapingAuthGroup(item)
  })
  return authGroups
}
