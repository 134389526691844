/* eslint-disable jsx-a11y/anchor-is-valid */
import {Outlet, Route, Routes} from 'react-router'
import {PageTitle} from '../../../../../_layout/core'
import {SchoolList} from './SchoolList'
import { createSectionContext, deleteSectionContext, editSectionContext, queryParamsContext } from './_outletContext'

const SchoolContent = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>{'School'}</PageTitle>
      <Outlet context={{ queryParams: queryParamsContext(), deleteSection: deleteSectionContext(), createSection: createSectionContext(), editSection: editSectionContext() }} />
    </>
  )
}

const School = () => {
  return (
    <>
      <Routes>
        <Route element={<SchoolContent />}>
          <Route index element={<SchoolList />} />
          {/* <Route path='create' element={<SchoolCreate />} />
          <Route path='edit/:schoolId' element={<SchoolEdit />} /> */}
        </Route>
      </Routes>
    </>
  )
}

export {School}
