import {tryoutQuestion} from '../../../../../_redux_slice'
import QuestionTryoutDataSourceImpl from '../../../../../_data/dataSource/QuestionTryoutDataSourceImpl'
import {QuestionTryoutRepositoryImpl} from '../../../../../_domain/repository/QuestionTryoutRepositoryImpl'
import {QuestionTryoutServiceImpl} from '../../../../../_domain/service/QuestionTryoutService'
import {BaseDatatableRequest} from '../../../../../_data/dataSource/request/BaseDatatableRequest'
import {
  QUESTION_TRYOUT_CREATE_FAILED,
  QUESTION_TRYOUT_CREATE_SUCCESS,
  QUESTION_TRYOUT_DELETE_FAILED,
  QUESTION_TRYOUT_DELETE_SUCCESS,
  QUESTION_TRYOUT_EDIT_FAILED,
  QUESTION_TRYOUT_EDIT_SUCCESS,
  QUESTION_TRYOUT_GET_FAILED,
} from '../../../../../_domain/constanta'
import {alertError, alertSuccess, errorHandling} from '../../../../../helpers/FuncHelpers'
import {
  QuestionTryoutExistFormInput,
  QuestionTryoutFormInput,
} from '../../../../../helpers/UIQuestionTryoutConstanta'
import {AlertPromise} from '../../../../../helpers/classHelpers'

const {actions} = tryoutQuestion.slice
// const controlAction = control.slice.actions
const callTypes = tryoutQuestion.callTypes
const dataSource = new QuestionTryoutDataSourceImpl()
const repository = new QuestionTryoutRepositoryImpl(dataSource)
const service = new QuestionTryoutServiceImpl(repository)

const alertPromise = new AlertPromise()
export const getAllTryoutQuestionDatatable =
  (queryParams: BaseDatatableRequest) => (dispatch: any) => {
    alertPromise.start()
    dispatch(actions.startCall({callType: callTypes.list}))
    return service
      .getDatatableQuestionTryout(queryParams)
      .then((response) => {
        const {totalCount, entities} = response
        dispatch(actions.saveList({totalCount, entities}))

        alertPromise.success()
        return response
      })
      .catch((err) => {
        if (err.response) {
          // errorHandling(err.response, QUESTION_TRYOUT_GET_FAILED)
        } else {
          console.error(err)
          alertPromise.stop()
          alertError()
        }
        dispatch(actions.catchError({err, callType: callTypes.action}))
      })
  }

export const getOneTryoutQuestion =
  (questionId?: string, chapterId?: string) => (dispatch: any) => {
    if (!questionId || !chapterId) {
      return dispatch(actions.saveObject({data: undefined}))
    }

    dispatch(actions.startCall({callType: callTypes.action}))
    return service
      .getDetailQuestionTryoutById(questionId, chapterId)
      .then((response) => {
        const tryoutQuestion = response
        dispatch(actions.saveObject({data: tryoutQuestion}))
        return tryoutQuestion
      })
      .catch((err) => {
        if (err.response) {
          errorHandling(err.response, QUESTION_TRYOUT_GET_FAILED)
        } else {
          console.error(err)
          alertError()
        }
        dispatch(actions.catchError({err, callType: callTypes.action}))
      })
  }

export const createTryoutQuestion = (request: QuestionTryoutFormInput) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.action}))
  return service
    .createOneQuestionTryout(request)
    .then((response) => {
      // if (request.file_pdf || request.file_video) {
      //   tryoutQuestionAPI.upload_files({
      //     file_pdf: request.file_pdf,
      //     file_video: request.file_video,
      //     _id: tryoutQuestion._id,
      //   })
      // }

      const question = response
      dispatch(actions.createObject({data: question}))
      alertPromise.stop()
      alertSuccess(QUESTION_TRYOUT_CREATE_SUCCESS)
    })
    .catch((err) => {
      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, QUESTION_TRYOUT_CREATE_FAILED)
      } else {
        console.error(err)
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const addTryoutQuestion = (request: QuestionTryoutExistFormInput) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.action}))
  return service
    .addOneQuestionTryout(request)
    .then((response) => {
      const question = response
      dispatch(actions.createObject({data: question}))
      alertPromise.stop()
      alertSuccess(QUESTION_TRYOUT_CREATE_SUCCESS)
    })
    .catch((err) => {
      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, QUESTION_TRYOUT_CREATE_FAILED)
      } else {
        console.error(err)
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const updateExistingTryoutQuestion =
  (request: QuestionTryoutExistFormInput, chapterId: string, questionId: string) =>
  (dispatch: any) => {
    alertPromise.start()
    dispatch(actions.startCall({callType: callTypes.action}))
    return service
      .updateExistOneQuestionTryout(request, chapterId, questionId)
      .then(() => {
        alertPromise.stop()
        alertSuccess(QUESTION_TRYOUT_EDIT_SUCCESS)
      })
      .catch((err) => {
        alertPromise.stop()
        if (err.response) {
          errorHandling(err.response, QUESTION_TRYOUT_EDIT_FAILED)
        } else {
          console.error(err)
          alertError()
        }
        dispatch(actions.catchError({err, callType: callTypes.action}))
      })
  }

export const updateNewTryoutQuestion =
  (request: QuestionTryoutFormInput, chapterId: string, questionId: string) => (dispatch: any) => {
    alertPromise.start()
    dispatch(actions.startCall({callType: callTypes.action}))
    return service
      .updateOneQuestionTryout(request, chapterId, questionId)
      .then(() => {
        alertPromise.stop()
        alertSuccess(QUESTION_TRYOUT_EDIT_SUCCESS)
      })
      .catch((err) => {
        alertPromise.stop()
        if (err.response) {
          errorHandling(err.response, QUESTION_TRYOUT_EDIT_FAILED)
        } else {
          console.error(err)
          alertError()
        }
        dispatch(actions.catchError({err, callType: callTypes.action}))
      })
  }

export const deleteOneTryoutQuestion =
  (questionId: string, chapterId: string) => (dispatch: any) => {
    alertPromise.start()
    dispatch(actions.startCall({callType: callTypes.action}))
    return service
      .deleteOneQuestionTryoutByID(chapterId, questionId)
      .then(() => {
        alertPromise.stop()
        alertSuccess(QUESTION_TRYOUT_DELETE_SUCCESS)
        dispatch(actions.deleteObject({questionId}))
      })
      .catch((err) => {
        alertPromise.stop()
        if (err.response) {
          errorHandling(err.response, QUESTION_TRYOUT_DELETE_FAILED)
        } else {
          console.error(err)
          alertError()
        }
        dispatch(actions.catchError({err, callType: callTypes.action}))
      })
  }

// export const duplicateTryoutQuestion = (body, chapterId) => (dispatch) => {
//   dispatch(actions.startCall({ callType: callTypes.action }));
//   return requestFromServer
//     .duplicateTryoutQuestion(body, chapterId)
//     .then((response) => {
//       alertSuccess("Successful duplicate post test question");
//       dispatch(actions.catchSuccess({ callType: callTypes.action }));
//     })
//     .catch((error) => {
//       error.clientMessage = "Can't duplicate postTest";
//       alertError(error.clientMessage);
//       dispatch(actions.catchError({ error, callType: callTypes.action }));
//     });
// };

// export const deletePDFTryoutQuestion = (questionId, chapterId) => (
//   dispatch
// ) => {
//   dispatch(actions.startCall({ callType: callTypes.action }));
//   return requestFromServer
//     .deletePDFTryoutQuestion(questionId, chapterId)
//     .then((response) => {
//       const tryoutQuestion = response.data.data;
//       alertSuccess("Successful delete PDF tryout question");
//       dispatch(actions.tryoutQuestionUpdated({ tryoutQuestion }));
//     })
//     .catch((error) => {
//       error.clientMessage = "Can't update tryoutQuestion";
//       alertError(error.clientMessage);
//       dispatch(actions.catchError({ error, callType: callTypes.action }));
//     });
// };

// export const deleteVideoTryoutQuestion = (questionId, chapterId) => (
//   dispatch
// ) => {
//   dispatch(actions.startCall({ callType: callTypes.action }));
//   return requestFromServer
//     .deleteVideoTryoutQuestion(questionId, chapterId)
//     .then((response) => {
//       const tryoutQuestion = response.data.data;
//       alertSuccess("Successful delete Video tryout question");
//       dispatch(actions.tryoutQuestionUpdated({ tryoutQuestion }));
//     })
//     .catch((error) => {
//       error.clientMessage = "Can't update tryoutQuestion";
//       alertError(error.clientMessage);
//       dispatch(actions.catchError({ error, callType: callTypes.action }));
//     });
// };
