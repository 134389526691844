import axios from 'axios'
import {ScheduleResponse, UserAttendanceResponse} from './response/ScheduleResponse'
import {ApiResponse} from './response/BaseResponse'
import {BaseDatatableRequest} from './request/BaseDatatableRequest'
import {BaseDatatableResponse} from './response/BaseDatatableResponse'
import {MessageResponse} from './response/MessageResponse'
// import {ScheduleCreateRequest, ScheduleGetAllParams, ScheduleUpdateRequest} from './request/ScheduleRequest'
import ScheduleDataSource from './ScheduleLiveClassDataSoruce'

export default class ScheduleLiveClassDataSourceImpl implements ScheduleDataSource {
  private LIVE_CLASS_URL: string
  private ATTENDANCE_URL: string

  constructor() {
    const service_schedule_url = process.env.REACT_APP_SCHEDULE_SERVICE_URL || 'api/'
    this.LIVE_CLASS_URL = `${service_schedule_url}schedules`
    this.ATTENDANCE_URL = `${service_schedule_url}attendees`
  }

  async getListAttendanceByUserId(userId: number): Promise<UserAttendanceResponse[]> {
    const response = await axios.get<ApiResponse<UserAttendanceResponse[]>>(
      `${this.ATTENDANCE_URL}/user/${userId}`
    )
    const data = response.data.data
    return data
  }

  async getOneAttendeesByScheduleId(schedule_id: string): Promise<UserAttendanceResponse> {
    const response = await axios.get<ApiResponse<UserAttendanceResponse>>(
      `${this.ATTENDANCE_URL}/schedule/${schedule_id}`
    )
    const data = response.data.data
    return data
  }

  async update(req: any, id: string): Promise<ScheduleResponse> {
    const response = await axios.put<ApiResponse<ScheduleResponse>>(
      `${this.LIVE_CLASS_URL}/${id}?type_schedule=live_class`,
      req
    )

    const data = response.data.data
    return data
  }

  async deleteOne(id: string): Promise<string> {
    const response = await axios.delete<MessageResponse>(
      `${this.LIVE_CLASS_URL}/${id}?type_schedule=live_class`
    )
    return response.data.message
  }

  async getDatatable(req: BaseDatatableRequest): Promise<BaseDatatableResponse<ScheduleResponse>> {
    const response = await axios.post<ApiResponse<BaseDatatableResponse<ScheduleResponse>>>(
      `${this.LIVE_CLASS_URL}/datatable?type_schedule=live_class`,
      req
    )

    const data = response.data.data

    return data
  }

  async getOne(id: string): Promise<ScheduleResponse> {
    const response = await axios.get<ApiResponse<ScheduleResponse>>(
      `${this.LIVE_CLASS_URL}/${id}?type_schedule=live_class`
    )
    const data = response.data.data
    return data
  }

  async getAll(params?: any): Promise<ScheduleResponse[]> {
    let url = `${this.LIVE_CLASS_URL}`
    if (params?.city_id) {
      url = `${url}?city_id=${params.city_id}`
    }
    const response = await axios.get<ApiResponse<ScheduleResponse[]>>(url)
    const data = response.data.data
    return data
  }

  async create(req: any): Promise<ScheduleResponse> {
    const response = await axios.post<ApiResponse<ScheduleResponse>>(
      `${this.LIVE_CLASS_URL}?type_schedule=live_class`,
      req
    )
    const data = response.data.data

    return data
  }

  async appendAttendees(req: any): Promise<void> {
    await axios.post<ApiResponse<ScheduleResponse>>(`${this.ATTENDANCE_URL}/`, req)
  }

  async uploadBatch(file: any): Promise<string> {
    const payload = new FormData()
    payload.append('file', file)
    const response = await axios.post<MessageResponse>(
      `${this.LIVE_CLASS_URL}/batch-file?type_schedule=live_class`,
      payload,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    return response.data.message
  }
}
