import {BaseDatatableRequest} from '../../_data/dataSource/request/BaseDatatableRequest'
import {
  mappingInputFormToGroupRoleCreateRequest,
  mappingInputFormToStudenRoleAppendRequest,
} from '../../_data/dataSource/request/StudentRoleRequest'
import {BaseDatatableResponse} from '../../_data/dataSource/response/BaseDatatableResponse'
import {GroupRoleFormInput} from '../../helpers/UIGroupRoleConstanta'
import {StudentAccessUpdateFormInput} from '../../helpers/UIStudentConstanta'
import {StudentGroupRole, StudentRole} from '../model/StudentRole'
import {StudentRoleRepository} from '../repository/StudentRoleRepository'

export interface StudentRoleService {
  getDatatableGroupRole(req: BaseDatatableRequest): Promise<BaseDatatableResponse<StudentGroupRole>>
  createGroupRole(reqInput: GroupRoleFormInput): Promise<StudentGroupRole>
  updateByUserId(user_id: number, reqInput: StudentAccessUpdateFormInput): Promise<StudentRole>
  updateCustomByUserId(
    user_id: number,
    reqInput: StudentAccessUpdateFormInput
  ): Promise<StudentRole>
  updateGroupIdByUserId(
    user_id: number,
    reqInput: StudentAccessUpdateFormInput
  ): Promise<StudentRole>
  updateGroupRole(id: string, reqInput: GroupRoleFormInput): Promise<StudentGroupRole>
  getRoleByUserId(user_id: number): Promise<StudentRole>
  getOneGroupRole(id: string): Promise<StudentGroupRole>
}

export class StudentRoleServiceImpl implements StudentRoleService {
  private repo: StudentRoleRepository
  constructor(repo: StudentRoleRepository) {
    this.repo = repo
  }

  async updateGroupIdByUserId(
    user_id: number,
    reqInput: StudentAccessUpdateFormInput
  ): Promise<StudentRole> {
    return await this.repo.changeGroupRole(user_id, reqInput.groupIdInput!)
  }

  async updateCustomByUserId(
    user_id: number,
    reqInput: StudentAccessUpdateFormInput
  ): Promise<StudentRole> {
    try {
      const req = mappingInputFormToStudenRoleAppendRequest(user_id, reqInput)
      return await this.repo.updateRole(user_id, req)
    } catch (error: any) {
      if (error.response) {
        const {status, data} = error.response
        if (status === 404) {
          const req = mappingInputFormToStudenRoleAppendRequest(user_id, reqInput)
          return await this.repo.createRole(req)
        }
      }
      throw new Error("Can't update role")
    }
  }

  async updateGroupRole(id: string, reqInput: GroupRoleFormInput): Promise<StudentGroupRole> {
    const req = mappingInputFormToGroupRoleCreateRequest(reqInput)
    return await this.repo.updateGroupRole(id, req)
  }

  async getOneGroupRole(id: string): Promise<StudentGroupRole> {
    return await this.repo.getOneGroupRole(id)
  }

  async createGroupRole(reqInput: GroupRoleFormInput): Promise<StudentGroupRole> {
    const req = mappingInputFormToGroupRoleCreateRequest(reqInput)
    return await this.repo.createGroupRole(req)
  }

  async getDatatableGroupRole(
    req: BaseDatatableRequest
  ): Promise<BaseDatatableResponse<StudentGroupRole>> {
    const roles = await this.repo.getDatatable(req)
    return roles
  }

  async updateByUserId(
    user_id: number,
    reqInput: StudentAccessUpdateFormInput
  ): Promise<StudentRole> {
    return this.updateCustomByUserId(user_id, reqInput)
  }

  async getRoleByUserId(user_id: number): Promise<StudentRole> {
    return await this.repo.getOneByUserId(user_id)
  }
}
