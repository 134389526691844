import OrderDataSource from '../../_data/dataSource/OrderDataSoruce'
import {BaseDatatableRequest} from '../../_data/dataSource/request/BaseDatatableRequest'
import {
  OrderCreateRequest,
  OrderEditInstallmentRequest,
  OrderEditStatusInstallmentRequest,
  OrderUpdateRequest,
} from '../../_data/dataSource/request/OrderRequest'
import {BaseDatatableResponse} from '../../_data/dataSource/response/BaseDatatableResponse'
import {Order, OrderBuilder} from '../model/Order'
import {OrderRepository} from './OrderRepository'

export class OrderRepositoryImpl implements OrderRepository {
  datasource: OrderDataSource
  constructor(datasource: OrderDataSource) {
    this.datasource = datasource
  }
  async cancelInstallmentOrder(id: string, order_id: string): Promise<Order> {
    const data = await this.datasource.cancelInstallment(id, order_id)
    const initBuilder = new OrderBuilder()
    const order = initBuilder.SingleData(data).Result()
    return order
  }
  async editStatusInstallmentOrder(
    body: OrderEditStatusInstallmentRequest,
    order_id: string
  ): Promise<Order> {
    const data = await this.datasource.editStatusInstallment(body, order_id)
    const initBuilder = new OrderBuilder()
    const order = initBuilder.SingleData(data).Result()
    return order
  }

  async deleteInstallmentOrder(id: string, order_id: string): Promise<Order> {
    const data = await this.datasource.deleteInstallment(id, order_id)
    const initBuilder = new OrderBuilder()
    const order = initBuilder.SingleData(data).Result()
    return order
  }

  async editInstallmentOrder(body: OrderEditInstallmentRequest, order_id: string): Promise<Order> {
    const data = await this.datasource.editInstallment(body, order_id)
    const initBuilder = new OrderBuilder()
    const order = initBuilder.SingleData(data).Result()
    return order
  }

  async getOneById(id: string | number): Promise<Order> {
    const data = await this.datasource.getOne(id)
    const initBuilder = new OrderBuilder()
    const order = initBuilder.SingleData(data).Result()
    return order
  }

  async deleteOneById(id: string | number): Promise<string> {
    const msg = await this.datasource.deleteOne(id)
    return msg
  }

  async createOne(req: OrderCreateRequest): Promise<Order> {
    const data = await this.datasource.create(req)
    const initBuilder = new OrderBuilder()
    const order = initBuilder.SingleData(data).Result()
    return order
  }

  async updateOne(req: OrderUpdateRequest, id: string): Promise<Order> {
    const data = await this.datasource.update(req, id)
    const initBuilder = new OrderBuilder()
    const order = initBuilder.SingleData(data).Result()
    return order
  }

  async getDatatable(req: BaseDatatableRequest): Promise<BaseDatatableResponse<Order>> {
    const data = await this.datasource.getDatatable(req)
    const initBuilder = new OrderBuilder()
    const entities = initBuilder.ListData(data.entities).ResultList()
    const totalCount = data.totalCount
    return {
      entities,
      totalCount,
      errorMessage: data.errorMessage,
    }
  }

  async getAll(): Promise<Order[]> {
    const response = await this.datasource.getAll()
    const initBuilder = new OrderBuilder()
    const orders = initBuilder.ListData(response).ResultList()
    return orders
  }
}
