import { ReactNode, createContext, useContext } from 'react'
import useQuestionTryoutTable from './useQuestionTryoutTable'
import { QuestionTryout } from '../../../_domain/model/QuestionTryout'
import { BaseDatatableRequest } from '../../../_data/dataSource/request/BaseDatatableRequest'
import { QuestionTryoutFilterV2Request } from '../../../_data/dataSource/request/QuestionTryoutRequest'

type QuestionTryoutTableContextType = {
  entities: QuestionTryout[],
  queryParams: BaseDatatableRequest,
  totalCount: number,
  handlePageChange: (page: number, perPage: number) => void,
  handleSortChange: (column: any, sortDirection: any) => void,
  applyFilter: (values: QuestionTryoutFilterV2Request) => void
}

type props = {
  children: ReactNode
}

const QuestionTryoutTableContext = createContext<QuestionTryoutTableContextType>({
  entities: [],
  queryParams: {
    filter: undefined,
    sortOrder: '',
    sortField: '',
    pageNumber: 0,
    pageSize: 0
  },
  totalCount: 0,
  handlePageChange: function (page: number, perPage: number): void {
    throw new Error('Function not implemented.')
  },
  handleSortChange: function (column: any, sortDirection: any): void {
    throw new Error('Function not implemented.')
  },
  applyFilter: function (values: QuestionTryoutFilterV2Request): void {
    throw new Error('Function not implemented.')
  }
})

export function useQuestionTryoutTableContext() {
  return useContext(QuestionTryoutTableContext)
}

export const QuestionTryoutUIConsumer = QuestionTryoutTableContext.Consumer

export function QuestionTryoutTableProvider({ children }: props) {
  const { entities, queryParams, totalCount, handlePageChange, handleSortChange, applyFilter } = useQuestionTryoutTable()

  const value: any = {
    entities,
    queryParams,
    totalCount,
    handlePageChange,
    handleSortChange,
    applyFilter
  }
  return <QuestionTryoutTableContext.Provider value={value}>{children}</QuestionTryoutTableContext.Provider>
}
