import {useEffect, useState} from 'react'
import { StudentGroupRole } from '../_domain/model/StudentRole'
import StudentRoleDataSourceImpl from '../_data/dataSource/StudentRoleDataSourceImpl'
import { StudentRoleRepositoryImpl } from '../_domain/repository/StudentRoleRepositoryImpl'

const useGroupRole = () => {
  const [list, setList] = useState<StudentGroupRole[]>([])

  const dataSource = new StudentRoleDataSourceImpl()
  const repo = new StudentRoleRepositoryImpl(dataSource)

  async function getAllGroupRole() {
    const response = await repo.getAll()
    setList(response)
  }

  useEffect(() => {
    getAllGroupRole()
  }, [])

  return {
    listGroupRole: list,
    getAllGroupRole,
  }
}

export default useGroupRole
