import { FC } from 'react'
import { UserAttendance } from '../../../_domain/model/Schedule'
import BaseTable from '../BaseTable'
import { epochToDateFormatString, epochToTimeFormatString } from '../../../helpers/FuncHelpers'

type Props = {
  entities: UserAttendance[],
  totalCount: number,
}
const AttendanceTable: FC<Props> = ({ entities, totalCount }) => {
  let mutableTableData = [...entities ?? []]

  let columns = [
    {
      name: 'Schedule Name',
      selector: (row: UserAttendance) => row.schedule_id.name,
      // sortable: true,
    },
    {
      name: 'Date',
      selector: (row: UserAttendance) => (
        <>
          {epochToDateFormatString(row.schedule_id.time_start_schedule)}
          <br /> {epochToDateFormatString(row.schedule_id.time_end_schedule)}
        </>
      ),
      sortable: true,
      maxWidth: '200px',
    },
    {
      name: 'Time',
      selector: (row: UserAttendance) => (
        <>
          {epochToTimeFormatString(row.schedule_id.time_start_schedule)}
          <br /> {epochToTimeFormatString(row.schedule_id.time_end_schedule)}
        </>
      ),
      sortable: true,
      width: '200px',
    },
  ]

  return (
    <div className='customized-datatable-style'>
      <BaseTable
        columns={columns}
        data={mutableTableData}
        countPerPage={100}
        totalRows={totalCount}
        selectableRows={false}
      />
    </div>
  )
}

export default AttendanceTable
