import React from 'react'
import {Parent} from '../../../../../../_domain/model/Parent'
import {ParentUpdateFormInput} from '../../../../../../helpers/UIParentConstanta'
import Profile from './partial/Profile'
import { useDispatch } from 'react-redux'
import { updateParent } from '../ParentAction'

type Props = {
  entity?: Parent
}
export default function ParentSetting(props: Props) {
  const {entity} = props

  const dispatch = useDispatch();

  function saveParentProfile(data: ParentUpdateFormInput) {
    if (entity?.user_id) {
      dispatch<any>(updateParent(data, entity.user_id));
    }
  }

  return <>{entity && <Profile onSubmit={saveParentProfile} parentData={entity} />}</>
}
