import React, { FC, useState } from 'react'
import { StudentAccountUpdateFormInput } from '../../../../../../../helpers/UIStudentConstanta'
import { Controller, useForm } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import { msgValidationError } from '../../../../../../../helpers/FuncHelpers'

type props = {
  phoneNumberData: string,
  onSubmit: (req: any) => void
}

const StudentPhoneForm: FC<props> = ({ phoneNumberData, onSubmit }) => {
  const [showPhoneForm, setShowPhoneForm] = useState(false)
  const { handleSubmit, control, formState: { errors } } = useForm<StudentAccountUpdateFormInput>({
    mode: "onChange",
  })
  return (
    <div className='d-flex flex-wrap align-items-center mb-10'>
      <div className={' ' + (showPhoneForm && 'd-none')}>
        <div className='fs-6 fw-bolder mb-1'>Phone Number</div>
        <div className='fw-bold text-gray-600'>{phoneNumberData}</div>
      </div>

      <div
        className={'flex-row-fluid ' + (!showPhoneForm && 'd-none')}
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className='row mb-1'>
            {/* Phone Number Field */}
            <Controller
              name="phoneNumberInput"
              defaultValue={phoneNumberData.replace("+62", "")}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <div className='col-lg-12'>
                  <div className='fv-row mb-0'>
                    <label htmlFor='newphone' className='form-label fs-6 fw-bolder mb-3'>
                      New Phone Number
                    </label>
                    <div className="input-group mb-3">
                      <span className="input-group-text">+62</span>
                      <input
                        {...field}
                        type='text'
                        className='form-control form-control-lg form-control-solid '
                        id='newphone'
                      />
                    </div>
                    {errors.phoneNumberInput && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          {msgValidationError(errors.phoneNumberInput.type, 'Phone Number')}
                        </div>
                      </div>
                    )}
                  </div>
                </div>

              )} />
          </div>

          <div className='d-flex'>
            <button
              type='submit'
              className='btn btn-primary me-2 px-6'
            >
              Update Phone Number
            </button>
            <button
              onClick={() => {
                setShowPhoneForm(false)
              }}
              type='button'
              className='btn btn-color-gray-400 btn-active-light-primary px-6'
            >
              Cancel
            </button>
          </div>
        </Form>
      </div>

      <div
        className={'ms-auto ' + (showPhoneForm && 'd-none')}
      >
        <button
          onClick={() => {
            setShowPhoneForm(true)
          }}
          className='btn btn-light btn-active-light-primary'
        >
          Change Phone Number
        </button>
      </div>
    </div>
  )
}
export default StudentPhoneForm