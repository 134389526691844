import useFilterTable from '../../../../../_hooks/useFilterTable'
import useDeleteSelectionDialog from '../../../../../_hooks/useDeleteSelectionDialog'


export const queryParamsContext = () => {
  const value = useFilterTable()

  return value
}

export const deleteSectionContext = () => {
  const value = useDeleteSelectionDialog()

  return value
}