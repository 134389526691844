/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { Navigate, Outlet, Route, Routes } from 'react-router'
import { PageTitle } from '../../../_layout/core'
import { Banner } from './pages/Banner/Banner'
import Order from './pages/Order/Order'
import { useAuth } from '../../../_context/AuthContext'
import { checkAuthLinkMenu } from '../../../helpers/AuthHelpers'
import { SLUG_TRANSACTION_SUB_MENU } from '../../../helpers/UIAuthGroupConstanta'

const TransactionWrapperContent: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>{'Transaction Main Menu'}</PageTitle>
      <Outlet />
    </>
  )
}

const TransactionWrapper: FC = () => {
  const { currentUser, authorization } = useAuth()

  return (
    <>
      <Routes>
        <Route element={<TransactionWrapperContent />}>
          <Route index element={<TransactionWrapperContent />} />
          {checkAuthLinkMenu(SLUG_TRANSACTION_SUB_MENU.TRANSACTION_ORDER_MENU, currentUser, authorization) && (
            <Route path='order/*' element={<Order />} />
          )}
          {checkAuthLinkMenu(SLUG_TRANSACTION_SUB_MENU.TRANSACTION_BANNER_MENU, currentUser, authorization) && (
            <Route path='banner/*' element={<Banner />} />
          )}
          {/* <Route path='new-registrant/*' element={<NewRegistrant />} /> */}
          <Route path='*' element={<Navigate to='/error/404' />} />
        </Route>
      </Routes>
    </>
  )
}

export { TransactionWrapper }
