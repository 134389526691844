import React, { Suspense, useEffect, useMemo } from 'react'
import { AUTH_GROUP_EDIT_TITLE } from '../../../../../_domain/constanta'
import { Link, useNavigate, useOutletContext, useParams } from 'react-router-dom'
import { getAllAuthGroupDatatable, getOneAuthGroup, updateAuthGroup } from './AuthGroupAction'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { AuthGroupFormInput } from '../../../../../helpers/UIAuthGroupConstanta'
import { PageTitle } from '../../../../../_layout/core'
import CustomLoading from '../../../../../_component/customLoading'

const AuthGroupEditForm = React.lazy(() => import('./AuthGroupEditComponent/AuthGroupEditForm'))

const AuthGroupEdit = () => {
  const { authGroupId } = useParams()
  const navigate = useNavigate();

  const filterContext = useOutletContext<any>().queryParams
  const { queryParams } = useMemo(() => {
    return {
      queryParams: filterContext.queryParams,
    };
  }, [filterContext]);

  const dispatch = useDispatch();
  const { actionsLoading, entity } = useSelector(
    (state: any) => ({
      actionsLoading: state.authGroup.actionsLoading,
      entity: state.authGroup.entity,
    }),
    shallowEqual
  );

  // if !id we should close modal
  useEffect(() => {
    dispatch<any>(getOneAuthGroup(authGroupId))
    return () => {
      dispatch<any>(getOneAuthGroup(undefined))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authGroupId]);

  const saveAction = (data: AuthGroupFormInput) => {
    if (authGroupId) {
      dispatch<any>(updateAuthGroup(data, authGroupId)).then(() => {
        // refresh list after deletion
        dispatch<any>(getAllAuthGroupDatatable(queryParams));
        // Back
        navigate("/master/auth-group")
      })
    }
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>{AUTH_GROUP_EDIT_TITLE}</PageTitle>
      <div className='d-flex justify-content-between align-items-center mb-4'>
        <div className='w-full'></div>
        <div>
          <Link to={"/master/auth-group"}>
            <button type='button'
              className='btn btn-secondary'>
              Back
            </button>
          </Link>
        </div>
      </div>
      <Suspense fallback={<CustomLoading />}>
        {entity ? <AuthGroupEditForm dataAuthGroup={entity} onSubmit={saveAction} /> : null}
      </Suspense>
    </>
  )
}

export default AuthGroupEdit 
