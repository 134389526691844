import {BaseDatatableRequest} from '../../_data/dataSource/request/BaseDatatableRequest'
import {
  SeriesCreateRequest,
  SeriesUpdateRequest,
  mappingInputFormToSeriesCreateRequest,
  mappingInputFormToSeriesUpdateRequest,
} from '../../_data/dataSource/request/SeriesRequest'
import {BaseDatatableResponse} from '../../_data/dataSource/response/BaseDatatableResponse'
import {SeriesFormInput} from '../../helpers/UISeriesConstanta'
import {Series} from '../model/Series'
import {SeriesRepository} from '../repository/SeriesRepository'

export interface SeriesService {
  getDatatableSeries(req: BaseDatatableRequest): Promise<BaseDatatableResponse<Series>>
  getAllSeries(): Promise<Series[]>
  getDetailSeriesById(id: string): Promise<Series>
  deleteOneSeriesByID(id: string): Promise<string>
  createOneSeries(req: SeriesFormInput): Promise<Series>
  updateOneSeries(req: SeriesFormInput, id: string): Promise<Series>
}

export class SeriesServiceImpl implements SeriesService {
  private repo: SeriesRepository
  constructor(repo: SeriesRepository) {
    this.repo = repo
  }

  async updateOneSeries(req: SeriesFormInput, id: string): Promise<Series> {
    const body = mappingInputFormToSeriesUpdateRequest(req)
    const series = await this.repo.updateOne(body, id)
    return series
  }

  async createOneSeries(req: SeriesFormInput): Promise<Series> {
    const body = mappingInputFormToSeriesCreateRequest(req)
    const series = await this.repo.createOne(body)
    return series
  }

  async deleteOneSeriesByID(id: string): Promise<string> {
    const message = await this.repo.deleteOneById(id)
    return message
  }

  async getDatatableSeries(req: BaseDatatableRequest): Promise<BaseDatatableResponse<Series>> {
    const serieses = await this.repo.getDatatable(req)
    return serieses
  }

  async getDetailSeriesById(id: string): Promise<Series> {
    const series = await this.repo.getOneById(id)
    return series
  }

  async getAllSeries(): Promise<Series[]> {
    const serieses = await this.repo.getAll()
    return serieses
  }
}
