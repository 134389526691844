import React, { useState } from 'react'
import useFilterTable from '../../../../../_hooks/useFilterTable'

export const queryParamsContext = () => {
  const value = useFilterTable()
  const [currentTypeTO, setCurrentTypeTO] = useState("")

  return {
    ...value,
    currentTypeTO,
    setCurrentTypeTO
  }
}

export const deleteSectionContext = () => {
  const [propsForDelete, setPropsForDelete] = useState({
    id: "",
    chapterId: "",
    show: false,
  });

  function showingDeleteModal(id: string, chapterId: string) {
    setPropsForDelete({
      id: id,
      chapterId: chapterId,
      show: true
    })
  }

  function closingDeleteModal() {
    setPropsForDelete({
      id: "",
      chapterId: "",
      show: false
    })
  }

  const value = {
    propsForDelete,
    setPropsForDelete,
    showingDeleteModal,
    closingDeleteModal
  }

  return value
}
// export const tryoutQuestionCreateContext = () => {
//   const [typeTryoutList, setTypeTryoutList] = useState([])
//   const [roleQuestionList, setRoleQuestionList] = useState([])
//   const [subjectList, setSubjectList] = useState([])
//   const [chapterList, setChapterList] = useState([])
//   const [numberList, setNumberList] = useState([])
//   const [subQuestionCounter, setSubQuestionCounter] = useState(1)

//   //   set default value only for prevent error because the form is initially empty
//   const [formValue, setFormValue] = useState({
//     classInput: null,
//     subjectInput: null,
//     semesterInput: null,
//     chapterInput: null,
//     roleInput: null,
//   })
//   //   set default value only prevent error because the form is still empty END

//   //   fetch Type TO for initial form option
//   useEffect(() => {
//     typeTOAPI.getAll().then((res) => {
//       const data = res.data.data
//       setTypeTryoutList([...data])
//     })

//     examAPI.getAll().then((res) => {
//       const data = res.data.data
//       setRoleQuestionList([...data])
//     })
//   }, [])
//   //   fetch Type TO for initial form option END

//   //   find subject by class input trigger
//   useEffect(() => {
//     if (formValue.classInput) {
//       const index = formValue.classInput ? formValue.classInput : 0
//       const {major} = CLASS_STUDENT[index]
//       subjectAPI.getAll(major).then((res) => {
//         const data = res.data.data
//         setSubjectList([...data])
//       })
//     }
//   }, [formValue.classInput])
//   //   find subject by class input trigger END

//   //   find Chapter by class,subject,semester input trigger
//   useEffect(() => {
//     if ((formValue.classInput, formValue.semesterInput, formValue.subjectInput)) {
//       const class_student = formValue.classInput
//       const semester = formValue.semesterInput
//       const subject_id = formValue.subjectInput
//       const {major, grade} = CLASS_STUDENT[class_student]

//       chapterAPI.getAllFilter({major, grade, subject_id, semester}).then((res) => {
//         const data = res.data.data
//         setChapterList([...data])
//       })
//     }
//   }, [formValue.classInput, formValue.semesterInput, formValue.subjectInput])
//   //   find Chapter by class,subject,semester input trigger END

//   // find number list by chapter,role input , semester input change
//   useEffect(() => {
//     if (formValue.chapterInput && formValue.roleInput) {
//       const chapterId = formValue.chapterInput
//       const role = formValue.roleInput

//       tryoutQuestionAPI
//         .findNumber({
//           chapterId: chapterId,
//           role: role,
//         })
//         .then((response) => setNumberList(response.data.data))
//     }
//   }, [formValue.chapterInput, formValue.roleInput])
//   // find number list by chapter,role input , semester input change END

//   const value = {
//     formValue,
//     setFormValue,
//     typeTryoutList,
//     roleQuestionList,
//     subjectList,
//     chapterList,
//     numberList,
//     setSubQuestionCounter,
//     subQuestionCounter,
//   }

//   return value
// }

// export const tryoutQuestionFilterContext = () => {
//   const [subjectList, setSubjectList] = useState([])
//   const [roleQuestionList, setRoleQuestionList] = useState([])
//   const [chapterList, setChapterList] = useState([])
//   //   set default value only for prevent error because the form is initially empty
//   const [formValue, setFormValue] = useState({
//     classInput: null,
//     semesterInput: null,
//     chapterInput: null,
//     roleInput: null,
//   })
//   //   set default value only prevent error because the form is still empty END
//   const [filter, setFilter] = useState({
//     filter: {},
//     sortOrder: 'asc',
//     sortField: 'id',
//     pageNumber: 1,
//     pageSize: 100,
//   })

//   // initial value for field who can filled without chaining action from other field
//   useEffect(() => {
//     examAPI.getAll().then((res) => {
//       const data = res.data.data
//       setRoleQuestionList([...data])
//     })
//   }, [])
//   // initial value for field who can filled without chaining action from other field END

//   // get Subject data based on class and semester Input
//   useEffect(() => {
//     if (formValue.classInput && formValue.semesterInput) {
//       const index = formValue.classInput ? formValue.classInput : 0
//       const {major} = CLASS_STUDENT[index]
//       subjectAPI.getAll(major).then((res) => {
//         const data = res.data.data
//         setSubjectList([...data])
//       })
//     }
//   }, [formValue.classInput, formValue.semesterInput])
//   // get Subject data based on class and semester Input END

//   // get Chapter data based on class,semester,subject & role input
//   useEffect(() => {
//     if (
//       formValue.classInput &&
//       formValue.semesterInput &&
//       formValue.subjectInput &&
//       formValue.roleInput
//     ) {
//       const class_student = formValue.classInput
//       const semester = formValue.semesterInput
//       const subject_id = formValue.subjectInput
//       const {major, grade} = CLASS_STUDENT[class_student]

//       chapterAPI.getAllFilter({major, grade, subject_id, semester}).then((res) => {
//         const data = res.data.data
//         setChapterList([...data])
//       })
//     }
//   }, [formValue.classInput, formValue.semesterInput, formValue.roleInput])
//   // get Chapter data based on class,semester,subject & role input END

//   useEffect(() => {
//     if (formValue.chapterInput) {
//       const {major, grade} = CLASS_STUDENT[formValue.classInput]
//       let mutableFilter = {
//         chapter_id: formValue.chapterInput,
//         grade: grade,
//         major: major,
//         role: formValue.roleInput,
//         semester: formValue.semesterInput,
//         subject_id: formValue.subjectInput,
//       }

//       setFilter({...filter, filter: mutableFilter})
//     }
//   }, [formValue.chapterInput, formValue.classInput, formValue.semesterInput, formValue.roleInput])

//   const value = {
//     filter,
//     setFilter,
//     formValue,
//     setFormValue,
//     subjectList,
//     roleQuestionList,
//     chapterList,
//   }

//   return value
// }

// export const tryoutQuestionEditContext = () => {
//   const [typeTryoutList, setTypeTryoutList] = useState([])
//   const [roleQuestionList, setRoleQuestionList] = useState([])
//   const [subjectList, setSubjectList] = useState([])
//   const [chapterList, setChapterList] = useState([])
//   const [numberList, setNumberList] = useState([])
//   const [subQuestionCounter, setSubQuestionCounter] = useState(1)

//   //   set default value only for prevent error because the form is initially empty
//   const [formValue, setFormValue] = useState({
//     classInput: null,
//     subjectInput: null,
//     semesterInput: null,
//     chapterInput: null,
//     roleInput: null,
//   })
//   //   set default value only prevent error because the form is still empty END

//   //   fetch Type TO for initial form option
//   useEffect(() => {
//     typeTOAPI.getAll().then((res) => {
//       const data = res.data.data
//       setTypeTryoutList([...data])
//     })

//     examAPI.getAll().then((res) => {
//       const data = res.data.data
//       setRoleQuestionList([...data])
//     })
//   }, [])
//   //   fetch Type TO for initial form option END

//   //   find subject by class input trigger
//   useEffect(() => {
//     if (formValue.classInput !== null) {
//       const index = formValue.classInput ? formValue.classInput : 0
//       const {major} = CLASS_STUDENT[index]
//       subjectAPI.getAll(major).then((res) => {
//         const data = res.data.data
//         setSubjectList([...data])
//       })
//     }
//   }, [formValue.classInput])
//   //   find subject by class input trigger END

//   //   find Chapter by class,subject,semester input trigger
//   useEffect(() => {
//     if (
//       formValue.classInput !== null &&
//       formValue.semesterInput !== null &&
//       formValue.subjectInput !== null
//     ) {
//       const class_student = formValue.classInput
//       const semester = formValue.semesterInput
//       const subject_id = formValue.subjectInput
//       const {major, grade} = CLASS_STUDENT[class_student]

//       chapterAPI.getAllFilter({major, grade, subject_id, semester}).then((res) => {
//         const data = res.data.data
//         setChapterList([...data])
//       })
//     }
//   }, [formValue.classInput, formValue.semesterInput, formValue.subjectInput])
//   //   find Chapter by class,subject,semester input trigger END

//   // find number list by chapter,role input , semester input change
//   useEffect(() => {
//     if (formValue.chapterInput && formValue.roleInput) {
//       const chapterId = formValue.chapterInput
//       const role = formValue.roleInput

//       tryoutQuestionAPI
//         .findNumber({
//           chapterId: chapterId,
//           role: role,
//         })
//         .then((response) => setNumberList(response.data.data))
//     }
//   }, [formValue.chapterInput, formValue.roleInput])
//   // find number list by chapter,role input , semester input change END

//   const value = {
//     formValue,
//     setFormValue,
//     typeTryoutList,
//     roleQuestionList,
//     subjectList,
//     chapterList,
//     numberList,
//     setSubQuestionCounter,
//     subQuestionCounter,
//   }

//   return value
// }
