const SUBJECT_DELETE_TITLE = 'Subject Delete'
const SUBJECT_DELETE_LOADING = 'Subject is deleting...'
const SUBJECT_DELETE_CONFIRM = 'Are you sure to permanently delete this subject ?'
const SUBJECT_DELETE_SUCCESS = 'Successful delete subject'
const SUBJECT_DELETE_FAILED = "Can't delete subject"
const SUBJECT_CREATE_TITLE = 'Subject Create'
const SUBJECT_UPLOAD_BATCH_TITLE = 'Student Upload Batch'
const SUBJECT_CREATE_FAILED = "Can't create subject"
const SUBJECT_CREATE_SUCCESS = 'Successful create subject'
const SUBJECT_EDIT_TITLE = 'Subject Edit'
const SUBJECT_EDIT_SUCCESS = 'Successful update subject'
const SUBJECT_EDIT_FAILED = "Can't update subject"
const SUBJECT_GET_FAILED = "Can't get subject"
export {
  SUBJECT_DELETE_TITLE,
  SUBJECT_UPLOAD_BATCH_TITLE,
  SUBJECT_DELETE_CONFIRM,
  SUBJECT_DELETE_LOADING,
  SUBJECT_DELETE_SUCCESS,
  SUBJECT_DELETE_FAILED,
  SUBJECT_CREATE_TITLE,
  SUBJECT_CREATE_SUCCESS,
  SUBJECT_CREATE_FAILED,
  SUBJECT_EDIT_TITLE,
  SUBJECT_EDIT_SUCCESS,
  SUBJECT_EDIT_FAILED,
  SUBJECT_GET_FAILED,
}
