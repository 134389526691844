import {ScheduleBatchResponse} from '../../_data/dataSource/response/ScheduleResponse'
import {getIndexClassStudent} from '../../helpers/FuncHelpers'
import {Schedule} from './Schedule'
import {Series} from './Series'
import {TypeTryout} from './TypeTryout'

type TypeTryoutDetail = {
  type_tryout: Pick<TypeTryout, 'id' | 'name'>
}

type SeriesDetail = {
  series: Pick<Series, 'id' | 'name'>
}

// type QuestionDetail = {
//   questions_detail?: {
//     id: string
//     chapter_id: string
//     nomor: number
//   }[]
// }

type Schedules = {
  schedules: Partial<Schedule & TypeTryoutDetail & SeriesDetail>[]
}

export type ScheduleBatch = Omit<ScheduleBatchResponse, 'schedule_ids' | '_id'> & Schedules

// export type ScheduleBatch = {
//   id: string
//   name: string
//   schedules?:
// }

export class ScheduleBatchBuilder {
  SingleScheduleBatch!: ScheduleBatch
  ScheduleBatchList!: ScheduleBatch[]
  constructor() {}

  SingleData(scheduleResponse: ScheduleBatchResponse): ScheduleBatchBuilder {
    const schedule = mapingScheduleBatch(scheduleResponse)
    this.SingleScheduleBatch = schedule
    return this
  }
  ListData(scheduleResponse: ScheduleBatchResponse[]): ScheduleBatchBuilder {
    const schedules = mapingScheduleBatchs(scheduleResponse)
    this.ScheduleBatchList = schedules
    return this
  }
  Result(): ScheduleBatch {
    return this.SingleScheduleBatch
  }
  ResultList(): ScheduleBatch[] {
    return this.ScheduleBatchList
  }
}

function mapingScheduleBatch(scheduleResponse: ScheduleBatchResponse): ScheduleBatch {
  const schedule: ScheduleBatch = {
    id: scheduleResponse._id,
    name: scheduleResponse.name,
    schedules: scheduleResponse.schedule_ids.map((item) => {
      const chapter = item.schedule_id.chapter_ids?.at(0)
      if (item) {
        let schedule = {
          ...item.schedule_id,
          id: item.schedule_id?._id,
          chapter_detail: {
            id: chapter?._id ?? '',
            name: chapter?.name ?? '',
          },
          type_tryout: {
            id: item.schedule_id?.type_tryout?._id ?? '',
            name: item.schedule_id?.type_tryout?.name ?? '',
          },
          series: {
            id: item.schedule_id?.exam?._id ?? '',
            name: item.schedule_id?.exam?.name ?? '',
          },
          class_student: item.schedule_id?.class_student?.map((itm: any) => {
            return {
              major: itm.major,
              level: itm.level,
              grade: itm.grade,
              index: getIndexClassStudent(itm.grade, itm.major) ?? 0,
            }
          }),
          schools: item.schedule_id?.school_ids?.map((obj: any) => {
            return {
              id: obj.id,
              name: obj.name,
            }
          }),
          teacher_ids:
            item.schedule_id?.teacher_ids?.map((obj: any) => {
              return {
                _id: obj._id,
                id: obj._id,
                name: obj.name,
              }
            }) ?? [],
          isIncreaseStudent: item?.schedule_id?.target_user === 'mix',
          question_ids:
            item?.schedule_id?.question_ids?.map((item: any) => {
              return {
                ...item,
                id: item._id ?? '',
                // chapter_id: item.chapter_id ?? '',
                // nomor: item.nomor ?? 0,
              }
            }) ?? [],
          is_continue: item?.schedule_id?.is_continue ?? false,
        }
        schedule.target_user = targetUserTab(schedule.target_user, schedule.schools)
        return schedule
      } else {
        return {}
      }
    }),
  }
  return schedule
}

function mapingScheduleBatchs(schedulesResponse: ScheduleBatchResponse[]): ScheduleBatch[] {
  const schedules = schedulesResponse.map((item) => {
    return mapingScheduleBatch(item)
  })
  return schedules
}

function targetUserTab(target_user?: string, schools?: any[]): string {
  if (target_user === 'mix') {
    if (schools && schools.length > 0) {
      return 'school'
    }
    return 'class'
  }
  return target_user ?? 'class'
}
