import React, { FC } from 'react'
import StudentEmailForm from './StudentEmailForm'
import StudentPhoneForm from './StudentPhoneForm'
import StudentPasswordForm from './StudentPasswordForm'
import { UserModel } from '../../../../../../../_domain/model/Auth'

type props = {
  userData: UserModel
  onSubmitEmail: (req: any) => void
  onSubmitPhone: (req: any) => void
  onSubmitPassword: (req: any) => void
}
const StudentAccount: FC<props> = ({ userData, onSubmitEmail, onSubmitPhone, onSubmitPassword }) => {
  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_signin_method'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Sign-in Method</h3>
        </div>
      </div>

      <div id='kt_account_signin_method' className='collapse show'>
        <div className='card-body border-top p-9'>

          <StudentEmailForm emailData={userData.email} onSubmit={onSubmitEmail} />

          <div className='separator separator-dashed my-6'></div>

          <StudentPhoneForm phoneNumberData={userData.phone_number ?? ""} onSubmit={onSubmitPhone} />

          <div className='separator separator-dashed my-6'></div>
          <StudentPasswordForm onSubmit={onSubmitPassword} />
        </div>
      </div>
    </div>
  )
}
export default StudentAccount