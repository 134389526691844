import {TeamResponse} from '../../_data/dataSource/response/TeamResponse'

export type Team = TeamResponse

export class TeamBuilder {
  private SingleTeam!: Team
  private TeamList!: Team[]
  constructor() {}

  SingleData(teamResponse: TeamResponse): TeamBuilder {
    const team = mapingTeam(teamResponse)
    this.SingleTeam = team
    return this
  }
  ListData(teamResponse: TeamResponse[]): TeamBuilder {
    const teams = mapingTeams(teamResponse)
    this.TeamList = teams
    return this
  }
  Result(): Team {
    return this.SingleTeam
  }
  ResultList(): Team[] {
    return this.TeamList
  }
}

function mapingTeam(teamResponse: TeamResponse): Team {
  const team: Team = teamResponse
  return team
}

function mapingTeams(teamsResponse: TeamResponse[]): Team[] {
  const teams = teamsResponse.map((item) => {
    return mapingTeam(item)
  })
  return teams
}
