import {TypeTryoutResponse} from '../../_data/dataSource/response/TypeTryoutResponse'
import {calculateTypeSwitch} from '../../helpers/FuncHelpers'
import {CALCULATE_TYPE} from '../constanta'

export type TypeTryout = {
  id: string
  name: string
  description?: string
  calculate_type?: CALCULATE_TYPE
}

export class TypeTryoutBuilder {
  private SingleTypeTryout!: TypeTryout
  private TypeTryoutList!: TypeTryout[]
  constructor() {}

  SingleData(typeTryoutResponse: TypeTryoutResponse): TypeTryoutBuilder {
    const typeTryout = mapingTypeTryout(typeTryoutResponse)
    this.SingleTypeTryout = typeTryout
    return this
  }
  ListData(typeTryoutResponse: TypeTryoutResponse[]): TypeTryoutBuilder {
    const typeTryouts = mapingTypeTryouts(typeTryoutResponse)
    this.TypeTryoutList = typeTryouts
    return this
  }
  Result(): TypeTryout {
    return this.SingleTypeTryout
  }
  ResultList(): TypeTryout[] {
    return this.TypeTryoutList
  }
}

function mapingTypeTryout(typeTryoutResponse: TypeTryoutResponse): TypeTryout {
  const typeTryout: TypeTryout = {
    id: typeTryoutResponse._id,
    name: typeTryoutResponse.name,
    description: typeTryoutResponse.description,
    calculate_type: calculateTypeSwitch(typeTryoutResponse.calculate_type),
  }
  return typeTryout
}

function mapingTypeTryouts(typeTryoutsResponse: TypeTryoutResponse[]): TypeTryout[] {
  const typeTryouts = typeTryoutsResponse.map((item) => {
    return mapingTypeTryout(item)
  })
  return typeTryouts
}
