import {StudentProdiResponse} from '../../_data/dataSource/response/StudentProdiResponse'

export type StudentProdi = StudentProdiResponse

export class StudentProdiBuilder {
  SingleStudentProdi!: StudentProdi
  StudentProdiList!: StudentProdi[]
  constructor() {}

  SingleData(studentResponse: StudentProdiResponse): StudentProdiBuilder {
    const student = mapingStudentProdi(studentResponse)
    this.SingleStudentProdi = student
    return this
  }
  ListData(studentResponse: StudentProdiResponse[]): StudentProdiBuilder {
    const students = mapingStudentProdis(studentResponse)
    this.StudentProdiList = students
    return this
  }
  Result(): StudentProdi {
    return this.SingleStudentProdi
  }
  ResultList(): StudentProdi[] {
    return this.StudentProdiList
  }
}

function mapingStudentProdi(studentResponse: StudentProdiResponse): StudentProdi {
  let studentProdi: StudentProdi = studentResponse
  return studentProdi
}

function mapingStudentProdis(studentsResponse: StudentProdiResponse[]): StudentProdi[] {
  const students = studentsResponse.map((item) => {
    return mapingStudentProdi(item)
  })
  return students
}
