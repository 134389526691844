import axios from 'axios'
import ProvinceDataSource from './ProvinceDataSoruce'
import {BaseDatatableRequest} from './request/BaseDatatableRequest'
import {BaseDatatableResponse} from './response/BaseDatatableResponse'
import {ProvinceResponse} from './response/ProvinceResponse'
import {ApiResponse} from './response/BaseResponse'

export default class ProvinceDataSourceImpl implements ProvinceDataSource {
  private PROVINCE_URL: string
  constructor() {
    const service_student_url = process.env.REACT_APP_STUDENT_SERVICE_URL || 'api/'
    this.PROVINCE_URL = `${service_student_url}provinces`
  }
  getDatatable(req: BaseDatatableRequest): Promise<BaseDatatableResponse<ProvinceResponse>> {
    throw new Error('Method not implemented.')
  }
  async getAll(): Promise<ProvinceResponse[]> {
    const response = await axios.get<ApiResponse<ProvinceResponse[]>>(`${this.PROVINCE_URL}`)
    const data = response.data.data
    return data
  }
  getOne(id: string | number): Promise<ProvinceResponse> {
    throw new Error('Method not implemented.')
  }
  deleteOne(id: string | number): Promise<string> {
    throw new Error('Method not implemented.')
  }
  create(req: any): Promise<ProvinceResponse> {
    throw new Error('Method not implemented.')
  }
  update(req: any, id: string | number): Promise<ProvinceResponse> {
    throw new Error('Method not implemented.')
  }
}
