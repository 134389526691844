import {FACILITY_STATUS} from '../../../_domain/constanta'
import {facilityStatusSwitch} from '../../../helpers/FuncHelpers'
import {FacilityFormInput} from '../../../helpers/UIFacilityConstanta'

export type FacilityCreateRequest = {
  short_name: string
  name: string
  status: FACILITY_STATUS
  special_limitations?: any
}

export type FacilityUpdateRequest = {
  short_name: string
  name: string
  status: FACILITY_STATUS
  special_limitations?: any
}

export function mappingInputFormToFacilityCreateRequest(
  req: FacilityFormInput
): FacilityCreateRequest {
  let reqData: FacilityCreateRequest = {
    short_name: req.shortNameInput ?? '',
    name: req.nameInput ?? '',
    status: facilityStatusSwitch(req.statusInput ?? ''),
  }
  if (req.isLimitInput && req.isLimitInput === 'true') {
    reqData = {
      ...reqData,
      special_limitations: {
        limit: req.limitInput ? +req.limitInput : 0,
      },
    }
  }
  return reqData
}

export function mappingInputFormToFacilityUpdateRequest(
  req: FacilityFormInput
): FacilityUpdateRequest {
  let reqData: FacilityUpdateRequest = {
    short_name: req.shortNameInput ?? '',
    name: req.nameInput ?? '',
    status: facilityStatusSwitch(req.statusInput ?? ''),
  }
  if (req.isLimitInput && req.isLimitInput === 'true') {
    reqData = {
      ...reqData,
      special_limitations: {
        limit: req.limitInput ? +req.limitInput : 0,
      },
    }
  }
  return reqData
}
