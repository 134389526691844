import {BaseDatatableRequest} from '../../_data/dataSource/request/BaseDatatableRequest'
import {
  mappingInputFormToScheduleTryoutCreateBatchRequest,
  mappingInputFormToScheduleTryoutCreateRequest,
  mappingInputFormToScheduleTryoutUpdateBatchRequest,
  mappingInputFormToScheduleTryoutUpdateRequest,
} from '../../_data/dataSource/request/ScheduleTryoutRequest'
import {BaseDatatableResponse} from '../../_data/dataSource/response/BaseDatatableResponse'
import {getIndexClassStudent} from '../../helpers/FuncHelpers'
import {
  ScheduleTryoutBatchFormInput,
  ScheduleTryoutFormInput,
} from '../../helpers/UIScheduleTryoutConstanta'
import {MAJOR} from '../constanta'
import {Schedule} from '../model/Schedule'
import {ScheduleBatch} from '../model/ScheduleBatch'
import {ChapterRepository} from '../repository/ChapterRepository'
import {ScheduleTryoutRepository} from '../repository/ScheduleTryoutRepository'
import {SchoolRepository} from '../repository/SchoolRepository'

export interface ScheduleTryoutService {
  getDatatableScheduleTryout(req: BaseDatatableRequest): Promise<BaseDatatableResponse<Schedule>>
  getDatatableScheduleTryoutBatch(
    req: BaseDatatableRequest
  ): Promise<BaseDatatableResponse<ScheduleBatch>>
  // getAllScheduleTryout(): Promise<ScheduleTryout[]>
  getDetailScheduleTryoutById(id: string): Promise<Schedule>
  getDetailScheduleBatchTryoutById(scheduleBatchId: string): Promise<ScheduleBatch>
  deleteOneScheduleTryoutByID(id: string): Promise<string>
  deleteOneScheduleTryoutBatchByID(scheduleBatchId: string): Promise<string>
  createOneScheduleTryout(req: ScheduleTryoutFormInput): Promise<Schedule>
  createBatchScheduleTryout(req: ScheduleTryoutBatchFormInput): Promise<Schedule | undefined>
  updateOneScheduleTryout(req: ScheduleTryoutFormInput, id: string): Promise<Schedule>
  updateBatchScheduleTryout(
    req: ScheduleTryoutBatchFormInput,
    scheduleBatchId: string
  ): Promise<Schedule | undefined>
}

export class ScheduleTryoutServiceImpl implements ScheduleTryoutService {
  private repo: ScheduleTryoutRepository
  private repoChapter: ChapterRepository
  private repoSchool: SchoolRepository
  constructor(
    repo: ScheduleTryoutRepository,
    repoChapter: ChapterRepository,
    repoSchool: SchoolRepository
  ) {
    this.repo = repo
    this.repoChapter = repoChapter
    this.repoSchool = repoSchool
  }

  async deleteOneScheduleTryoutByID(id: string): Promise<string> {
    return await this.repo.deleteOneById(id)
  }

  async deleteOneScheduleTryoutBatchByID(scheduleBatchId: string): Promise<string> {
    return await this.repo.deleteOneBatchById(scheduleBatchId)
  }

  async updateBatchScheduleTryout(
    req: ScheduleTryoutBatchFormInput,
    scheduleBatchId: string
  ): Promise<Schedule | undefined> {
    const body = mappingInputFormToScheduleTryoutUpdateBatchRequest(req)

    const schedule = await this.repo.updateBatch(body, scheduleBatchId)
    return schedule
  }

  async getDetailScheduleBatchTryoutById(scheduleBatchId: string): Promise<ScheduleBatch> {
    let schedule = await this.repo.getOneBatchById(scheduleBatchId)
    for (let scheduleItem of schedule.schedules ?? []) {
      const chapter_id = scheduleItem.chapter_ids?.at(0)?._id
      if (chapter_id) {
        const chapter = await this.repoChapter.getOneById(chapter_id)
        scheduleItem.chapter_detail!.id = chapter?.id ?? ''
        scheduleItem.chapter_detail!.name = chapter?.name ?? ''
        scheduleItem.chapter_detail!.grade = chapter?.grade ?? 0
        scheduleItem.chapter_detail!.major = chapter?.major ?? MAJOR.ALL
        scheduleItem.chapter_detail!.semester = chapter?.semester ?? 1
        scheduleItem.chapter_detail!.subject_detail = chapter.subject_detail
        scheduleItem.chapter_detail!.class_index =
          getIndexClassStudent(chapter.grade, chapter.major) ?? 0
      }

      if (scheduleItem.schools?.at(0)?.id) {
        const school = await this.repoSchool.getOneById(scheduleItem.schools?.at(0)?.id ?? 0)
        scheduleItem.schools!.at(0)!.city_id = school.city_id
        scheduleItem.schools!.at(0)!.province_id = school.province_detail.id
      }
    }

    return schedule
  }

  async getDatatableScheduleTryoutBatch(
    req: BaseDatatableRequest
  ): Promise<BaseDatatableResponse<ScheduleBatch>> {
    const schedules = await this.repo.getDatatableBatch(req)
    return schedules
  }

  async createBatchScheduleTryout(
    req: ScheduleTryoutBatchFormInput
  ): Promise<Schedule | undefined> {
    const body = mappingInputFormToScheduleTryoutCreateBatchRequest(req)

    const schedule = await this.repo.createBatch(body)
    return schedule
  }

  async updateOneScheduleTryout(req: ScheduleTryoutFormInput, id: string): Promise<Schedule> {
    const body = mappingInputFormToScheduleTryoutUpdateRequest(req, id)

    const schedule = await this.repo.updateOne(body)
    return schedule
  }

  async getDetailScheduleTryoutById(id: string): Promise<Schedule> {
    let schedule = await this.repo.getOneById(id)

    if (schedule.chapter_ids?.at(0)?._id) {
      let chapter = await this.repoChapter.getOneById(schedule.chapter_ids?.at(0)?._id!)
      schedule.chapter_detail = {
        id: chapter.id,
        name: chapter.name,
        grade: chapter.grade,
        major: chapter.major,
        semester: chapter.semester,
        subject_detail: chapter.subject_detail,
        class_index: getIndexClassStudent(chapter.grade, chapter.major),
      }
    }
    // let chapter = await this.repoChapter.getOneById(schedule.chapter_detail.id)
    // schedule.chapter_detail.grade = chapter.grade
    // schedule.chapter_detail.major = chapter.major
    // schedule.chapter_detail.semester = chapter.semester
    // schedule.chapter_detail.class_index = getIndexClassStudent(chapter.grade, chapter.major)

    for (let school of schedule.schools ?? []) {
      const schoolDetail = await this.repoSchool.getOneById(school.id)
      if (schoolDetail.id) {
        school.city_id = schoolDetail.city_id
        school.city_name = schoolDetail.city_name
        school.province_id = schoolDetail.province_detail.id
      }
    }

    return schedule
  }

  async createOneScheduleTryout(req: ScheduleTryoutFormInput): Promise<Schedule> {
    const body = mappingInputFormToScheduleTryoutCreateRequest(req)

    const schedule = await this.repo.createOne(body)
    return schedule
  }

  async getDatatableScheduleTryout(
    req: BaseDatatableRequest
  ): Promise<BaseDatatableResponse<Schedule>> {
    const schedules = await this.repo.getDatatable(req)
    return schedules
  }
}
