import { FC } from "react";
import { Modal } from "react-bootstrap";

type props = {
    title: string,
    confirmText: string,
    loadingText: string
    show: boolean,
    btnActionName?: string
    isLoading: boolean,
    onHide: () => void
    submitAction: () => void
}
export const ModalConfirm: FC<props> = ({ title, confirmText, loadingText, show, isLoading, btnActionName = "Confirm", onHide, submitAction }) => {
    return (
        <Modal
            show={show}
            onHide={onHide}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!isLoading && (
                    <span>{confirmText}</span>
                )}
                {isLoading && <span>{loadingText}</span>}
            </Modal.Body>
            <Modal.Footer>
                <div>
                    <button
                        type="button"
                        onClick={onHide}
                        className="btn btn-light btn-elevate"
                    >
                        Cancel
                    </button>
                    <> </>
                    <button
                        type="button"
                        onClick={submitAction}
                        className="btn btn-primary btn-elevate"
                    >
                        {btnActionName}
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}