import React, { Suspense, useEffect, useMemo } from 'react'
import { GROUP_CLASS_EDIT_TITLE } from '../../../../../_domain/constanta'
import { Link, useNavigate, useOutletContext, useParams } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { PageTitle } from '../../../../../_layout/core'
import CustomLoading from '../../../../../_component/customLoading'
import { getAllGroupClassDatatable, getOneGroupClass, updateGroupClass } from './GroupClassAction'
import { GroupClassFormInput } from '../../../../../helpers/UIGroupClassConstanta'

const GroupClassUpdateForm = React.lazy(() => import('./GroupClassUpdateComponent/GroupClassUpdateForm'))

const GroupClassEdit = () => {
  const { id } = useParams()
  const navigate = useNavigate();

  const filterContext = useOutletContext<any>().queryParams
  const { queryParams } = useMemo(() => {
    return {
      queryParams: filterContext.queryParams,
    };
  }, [filterContext]);

  const dispatch = useDispatch();
  const { actionsLoading, entity } = useSelector(
    (state: any) => ({
      actionsLoading: state.groupClass.actionsLoading,
      entity: state.groupClass.entity,
    }),
    shallowEqual
  );

  // if !id we should close modal
  useEffect(() => {
    dispatch<any>(getOneGroupClass(id))
    return () => {
      dispatch<any>(getOneGroupClass(undefined))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const saveAction = (data: GroupClassFormInput) => {
    // console.log(data);

    if (id) {
      dispatch<any>(updateGroupClass(data, id)).then(() => {
        // refresh list after deletion
        dispatch<any>(getAllGroupClassDatatable(queryParams));
        // Back
        navigate("/master/group-class")
      })
    }
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>{GROUP_CLASS_EDIT_TITLE}</PageTitle>
      <div className='d-flex justify-content-between align-items-center mb-4'>
        <div className='w-full'></div>
        <div>
          <Link to={"/master/group-class"}>
            <button type='button'
              className='btn btn-secondary'>
              Back
            </button>
          </Link>
        </div>
      </div>
      <Suspense fallback={<CustomLoading />}>
        {entity ? <GroupClassUpdateForm dataGroupClass={entity} onSubmit={saveAction} /> : null}
      </Suspense>
    </>
  )
}

export default GroupClassEdit 
