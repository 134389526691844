import { useOutletContext } from "react-router-dom"
import { ModalDelete } from "../../../../../_component/Modal"
import { SUBJECT_DELETE_CONFIRM, SUBJECT_DELETE_LOADING, SUBJECT_DELETE_TITLE } from "../../../../../_domain/constanta"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { useEffect, useMemo } from "react"
import { deleteOneSubject, getAllSubjectDatatable } from "./SubjectAction"

const SubjectDelete = () => {
    const deleteSectionContext = useOutletContext<any>().deleteSection
    const { closingDeleteModal, propsForDelete } = useMemo(() => {
        return {
            closingDeleteModal: deleteSectionContext.closingDeleteModal,
            propsForDelete: deleteSectionContext.propsForDelete
        };
    }, [deleteSectionContext]);

    const outletContext = useOutletContext<any>().queryParams
    const { queryParams } = useMemo(() => {
        return {
            queryParams: outletContext.queryParams,
        };
    }, [outletContext]);

    // Schedule Custom Redux state
    const dispatch = useDispatch();
    const { isLoading } = useSelector(
        (state: any) => ({ isLoading: state.subjects.actionsLoading }),
        shallowEqual
    );

    // if !id we should close modal
    useEffect(() => {
        if (!propsForDelete.id) {
            closingDeleteModal()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [propsForDelete.id]);

    const deleteAction = () => {
        try {
            // server request for deleting scheduleCustomTest by id
            dispatch<any>(deleteOneSubject(propsForDelete.id)).then(() => {
                // refresh list after deletion
                dispatch<any>(getAllSubjectDatatable(queryParams));
                // closing delete modal
                closingDeleteModal()
            });
        } catch (error) {
            closingDeleteModal();
        }
    };

    return (
        <ModalDelete title={SUBJECT_DELETE_TITLE} confirmText={SUBJECT_DELETE_CONFIRM} loadingText={SUBJECT_DELETE_LOADING} show={propsForDelete.show} onHide={closingDeleteModal} deleteAction={deleteAction} isLoading={isLoading} />
    )
}

export default SubjectDelete