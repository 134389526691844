const ORDER_DELETE_TITLE = 'Order Delete'
const ORDER_DELETE_INSTALLMENT_TITLE = 'Order Installment Delete'
const ORDER_DELETE_LOADING = 'Order is deleting...'
const ORDER_CANCEL_LOADING = 'Order is canceling...'
const ORDER_DELETE_CONFIRM = 'Are you sure to permanently delete this order ?'
const ORDER_CANCEL_CONFIRM = 'Are you sure to permanently cancel this order ?'
const ORDER_DELETE_SUCCESS = 'Successful delete order'
const ORDER_CANCEL_SUCCESS = 'Successful cancel order'
const ORDER_DELETE_FAILED = "Can't delete order"
const ORDER_CANCEL_FAILED = "Can't cancel order"
const ORDER_CREATE_TITLE = 'Order Create'
const ORDER_CANCEL_TITLE = 'Order Cancel'
const ORDER_UPLOAD_BATCH_TITLE = 'Student Upload Batch'
const ORDER_CREATE_FAILED = "Can't create order"
const ORDER_CREATE_SUCCESS = 'Successful create order'
const ORDER_EDIT_TITLE = 'Order Edit'
const ORDER_EDIT_INSTALLMENT_TITLE = 'Order Edit Installment'
const ORDER_EDIT_STATUS_INSTALLMENT_TITLE = 'Order Edit Status Installment'
const ORDER_EDIT_STATUS_LOADING = 'Order is changing...'
const ORDER_EDIT_STATUS_CONFIRM = 'Are you sure to permanently edit status this order ?'
const ORDER_EDIT_SUCCESS = 'Successful update order'
const ORDER_EDIT_FAILED = "Can't update order"
const ORDER_GET_FAILED = "Can't get order"
const ORDER_LIST_TITLE = "Order List"
export {
  ORDER_CANCEL_LOADING,
  ORDER_CANCEL_CONFIRM,
  ORDER_CANCEL_SUCCESS,
  ORDER_CANCEL_TITLE,
  ORDER_CANCEL_FAILED,
  ORDER_EDIT_INSTALLMENT_TITLE,
  ORDER_DELETE_INSTALLMENT_TITLE,
  ORDER_EDIT_STATUS_CONFIRM,
  ORDER_EDIT_STATUS_LOADING,
  ORDER_EDIT_STATUS_INSTALLMENT_TITLE,
  ORDER_LIST_TITLE,
  ORDER_DELETE_TITLE,
  ORDER_UPLOAD_BATCH_TITLE,
  ORDER_DELETE_CONFIRM,
  ORDER_DELETE_LOADING,
  ORDER_DELETE_SUCCESS,
  ORDER_DELETE_FAILED,
  ORDER_CREATE_TITLE,
  ORDER_CREATE_SUCCESS,
  ORDER_CREATE_FAILED,
  ORDER_EDIT_TITLE,
  ORDER_EDIT_SUCCESS,
  ORDER_EDIT_FAILED,
  ORDER_GET_FAILED,
}
