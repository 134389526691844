import useFilterTable from '../../../../../_hooks/useFilterTable'
import useDeleteSelectionDialog from '../../../../../_hooks/useDeleteSelectionDialog'
import useCreateDialog from '../../../../../_hooks/useCreateDialog'
import {useState} from 'react'

export const queryParamsContext = () => {
  const value = useFilterTable()

  return value
}

export const createSectionContext = () => {
  const value = useCreateDialog()

  return value
}

export const deleteSectionContext = () => {
  const value = useDeleteSelectionDialog()

  return value
}

export const addStudentDialog = () => {
  const [propsForAddStudent, setPropsForAddStudent] = useState({
    show: false,
  })

  function showingAddStudentModal() {
    setPropsForAddStudent({
      show: true,
    })
  }

  function closingAddStudentModal() {
    setPropsForAddStudent({
      show: false,
    })
  }

  return {
    propsForAddStudent,
    setPropsForAddStudent,
    showingAddStudentModal,
    closingAddStudentModal,
  }
}
