const RESULT_TRYOUT_DELETE_TITLE = 'Result Tryout Reset'
const RESULT_TRYOUT_DELETE_LOADING = 'Result Tryout is resetting...'
const RESULT_TRYOUT_DELETE_CONFIRM = 'Are you sure to permanently reset this result tryout ?'
const RESULT_TRYOUT_DELETE_SUCCESS = 'Successful reset result tryout'
const RESULT_TRYOUT_DELETE_FAILED = "Can't reset result tryout"
const RESULT_TRYOUT_CALCULATE_TITLE = 'Result Tryout Calculate'
const RESULT_TRYOUT_CALCULATE_FAILED = "Can't calculate result tryout"
const RESULT_TRYOUT_CALCULATE_SUCCESS = 'Successful calculate result tryout'
const RESULT_TRYOUT_EDIT_TITLE = 'Result Tryout Edit'
const RESULT_TRYOUT_EDIT_SUCCESS = 'Successful update result tryout'
const RESULT_TRYOUT_EDIT_FAILED = "Can't update result tryout"
const RESULT_TRYOUT_GET_FAILED = "Can't get result tryout"
const RESULT_TRYOUT_LIST_TITLE = 'Result Tryout List'
export {
  RESULT_TRYOUT_DELETE_TITLE,
  RESULT_TRYOUT_DELETE_CONFIRM,
  RESULT_TRYOUT_DELETE_LOADING,
  RESULT_TRYOUT_DELETE_SUCCESS,
  RESULT_TRYOUT_DELETE_FAILED,
  RESULT_TRYOUT_CALCULATE_TITLE,
  RESULT_TRYOUT_CALCULATE_SUCCESS,
  RESULT_TRYOUT_CALCULATE_FAILED,
  RESULT_TRYOUT_EDIT_TITLE,
  RESULT_TRYOUT_EDIT_SUCCESS,
  RESULT_TRYOUT_EDIT_FAILED,
  RESULT_TRYOUT_GET_FAILED,
  RESULT_TRYOUT_LIST_TITLE,
}
