import {CityResponse} from '../../_data/dataSource/response/CityResponse'
import {Province} from './Province'

export type City = {
  id: string
  province_id: string
  name: string
  province_detail?: Province
}

export class CityBuilder {
  private SingleCity!: City
  private CityList!: City[]
  constructor() {}

  SingleData(cityResponse: CityResponse): CityBuilder {
    const city = mapingCity(cityResponse)
    this.SingleCity = city
    return this
  }
  ListData(cityResponse: CityResponse[]): CityBuilder {
    const citys = mapingCitys(cityResponse)
    this.CityList = citys
    return this
  }
  Result(): City {
    return this.SingleCity
  }
  ResultList(): City[] {
    return this.CityList
  }
}

function mapingCity(cityResponse: CityResponse): City {
  const city: City = {
    id: cityResponse.id,
    province_id: cityResponse.province_id,
    name: cityResponse.name,
  }
  return city
}

function mapingCitys(citysResponse: CityResponse[]): City[] {
  const citys = citysResponse.map((item) => {
    return mapingCity(item)
  })
  return citys
}
