import { ReactNode, createContext, useContext, useEffect, useState } from 'react'
import { QuestionTryout } from '../_domain/model/QuestionTryout'
import QuestionTryoutDataSourceImpl from '../_data/dataSource/QuestionTryoutDataSourceImpl'
import { Series } from '../_domain/model/Series'
import SeriesDataSourceImpl from '../_data/dataSource/SeriesDataSourceImpl'
import { TypeTryout } from '../_domain/model/TypeTryout'
import TypeTryoutDataSourceImpl from '../_data/dataSource/TypeTryoutDataSourceImpl'
import { SeriesRepositoryImpl } from '../_domain/repository/SeriesRepositoryImpl'
import { TypeTryoutRepositoryImpl } from '../_domain/repository/TypeTryoutRepositoryImpl'

type QuestionTryoutContextType = {
  listQuestionTryout: QuestionTryout[]
  listSeries: Series[]
  listTypeTryout: TypeTryout[]
  currectQuestionTryout: QuestionTryout | {}
  setCurrentQuestionTryout: (val: any) => {}
}

type props = {
  children: ReactNode
}

const QuestionTryoutUIContext = createContext<QuestionTryoutContextType>({
  listQuestionTryout: [],
  listSeries: [],
  setCurrentQuestionTryout: function (val: any): {} {
    throw new Error('Function not implemented.')
  },
  currectQuestionTryout: {},
  listTypeTryout: []
})

export function useQuestionTryoutUIContext() {
  return useContext(QuestionTryoutUIContext)
}

export const QuestionTryoutUIConsumer = QuestionTryoutUIContext.Consumer

export function QuestionTryoutUIProvider({ children }: props) {
  const [list, setList] = useState<Series[]>([])
  const [listTypeTryout, setListTypeTryout] = useState<TypeTryout[]>([])

  const dataSource = new SeriesDataSourceImpl()
  const repoSeries = new SeriesRepositoryImpl(dataSource)
  const dataSourceTypeTryout = new TypeTryoutDataSourceImpl()
  const repoTypeTryout = new TypeTryoutRepositoryImpl(dataSourceTypeTryout)

  async function getAllSeries() {
    const response = await repoSeries.getAll()
    setList(response)
  }

  async function getAllTypeTryout() {
    const response = await repoTypeTryout.getAll()
    setListTypeTryout(response)
  }

  useEffect(() => {
    getAllSeries()
    getAllTypeTryout()
  }, [])

  const value: any = {
    listSeries: list,
    getAllSeries,
    listTypeTryout,
    getAllTypeTryout
  }
  return <QuestionTryoutUIContext.Provider value={value}>{children}</QuestionTryoutUIContext.Provider>
}
