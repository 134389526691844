import axios from 'axios'
import BannerDataSource from './BannerDataSoruce'
import {BannerResponse} from './response/BannerResponse'
import {ApiResponse} from './response/BaseResponse'
import {BaseDatatableRequest} from './request/BaseDatatableRequest'
import {BaseDatatableResponse} from './response/BaseDatatableResponse'
import {MessageResponse} from './response/MessageResponse'
import {BannerCreateRequest, BannerUpdateRequest} from './request/BannerRequest'

export default class BannerDataSourceImpl implements BannerDataSource {
  private BANNERS_URL: string
  constructor() {
    const service_banner_url = process.env.REACT_APP_PRODUCT_SERVICE_URL || 'api/'
    this.BANNERS_URL = `${service_banner_url}banner-promos`
  }

  async update(req: BannerUpdateRequest, id: string): Promise<BannerResponse> {
    const response = await axios.put<ApiResponse<BannerResponse>>(`${this.BANNERS_URL}/${id}`, req)

    const data = response.data.data
    return data
  }

  async deleteOne(id: string): Promise<string> {
    const response = await axios.delete<MessageResponse>(`${this.BANNERS_URL}/${id}`)
    return response.data.message
  }

  async getDatatable(req: BaseDatatableRequest): Promise<BaseDatatableResponse<BannerResponse>> {
    const response = await axios.post<ApiResponse<BaseDatatableResponse<BannerResponse>>>(
      `${this.BANNERS_URL}/datatable`,
      req
    )

    const data = response.data.data

    return data
  }

  async getOne(id: string): Promise<BannerResponse> {
    const response = await axios.get<ApiResponse<BannerResponse>>(`${this.BANNERS_URL}/${id}`)
    const data = response.data.data
    return data
  }

  async getAll(): Promise<BannerResponse[]> {
    const response = await axios.get<ApiResponse<BannerResponse[]>>(`${this.BANNERS_URL}`)
    const data = response.data.data
    return data
  }

  async create(req: BannerCreateRequest): Promise<BannerResponse> {
    const response = await axios.post<ApiResponse<BannerResponse>>(`${this.BANNERS_URL}/`, req)
    const data = response.data.data

    return data
  }

  async uploadImage(id: string, files: any[], indexes: any[]): Promise<void> {
    const payload = new FormData()
    files.forEach((item, index) => {
      if (item) {
        payload.append('images', item)
        payload.append('indexes', indexes.pop())
      }
    })
    axios.put<ApiResponse<void>>(`${this.BANNERS_URL}/${id}/image`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    // return response.data.data
  }
}
