import {CLASS_STUDENT} from '../../../helpers/UIConstanta'
import {
  ScheduleTryoutBatchFormInput,
  ScheduleTryoutFormInput,
} from '../../../helpers/UIScheduleTryoutConstanta'

export type ScheduleTryoutCreateRequest = {
  chapter_ids: string[]
  name: string
  exam: string
  type_tryout: string
  is_disallow_prev: boolean
  class_student: {
    grade: number
    major: string
    level: string
  }[]
  target_user: string
  is_continue: boolean
  is_shuffle: boolean
  duration: number
  question_ids?: {
    id: string
    is_shuffle: boolean
  }[]
  attendees?: {
    user_id: number
    name: string
  }[]
  school_ids?: {
    id: number
    name: string
  }[]
  total_question: number
  date_start: string
  date_end: string
  time_start: string
  time_end: string
}

export type ScheduleTryoutCreateBatchRequest = {
  name: string
  exam: string
  type_tryout: string
  attendees?: {
    user_id: number
    name: string
  }[]
  school_ids?: {
    id: number
    name: string
  }[]
  class_student: {
    grade: number
    major: string
    level: string
  }[]
  target_user: string
  is_continue: boolean
  is_shuffle: boolean
  is_disallow_prev: boolean
  schedules: {
    name: string
    chapter_ids: string[]
    duration: number
    question_ids: {
      id: string
    }[]
    total_question: number
    date_start: string
    date_end: string
    time_start: string
    time_end: string
  }[]
}

export type ScheduleTryoutUpdateBatchRequest = {
  name: string
  exam: string
  type_tryout: string
  attendees?: {
    user_id: number
    name: string
  }[]
  school_ids?: {
    id: number
    name: string
  }[]
  class_student: {
    grade: number
    major: string
    level: string
  }[]
  target_user: string
  is_continue: boolean
  is_shuffle: boolean
  is_disallow_prev: boolean
  schedules: {
    new?: boolean
    _id?: string
    name: string
    chapter_ids: string[]
    duration: number
    question_ids: {
      id: string
    }[]
    total_question: number
    date_start: string
    date_end: string
    time_start: string
    time_end: string
  }[]
}

export type ScheduleTryoutUpdateRequest = {
  scheduleId: string
  name: string
  exam: string
  type_tryout: string
  duration: number
  date_start: string
  date_end: string
  time_start: string
  time_end: string
  target_user: string
  is_shuffle: boolean
  is_disallow_prev: boolean
  school_ids?: {
    id: number
    name: string
  }[]
  attendees?: {
    user_id: number
    name: string
  }[]
  total_question: number
  class_student: {
    grade: number
    major: string
    level: string
  }[]
  question_ids?: {
    id: string
    is_shuffle: boolean
  }[]
  chapter_ids: string[]
}

// export type ScheduleTryoutListNumberRequest = {
//   major: string
//   grade: number
//   subject_id: string
//   semester: number
// }

export type ScheduleTryoutFilterRequest = {
  exam?: string
  type_tryout?: string
}

export function mappingInputFormToScheduleTryoutCreateRequest(
  req: ScheduleTryoutFormInput
): ScheduleTryoutCreateRequest {
  return {
    chapter_ids: req.chaptersInput?.map((item) => item.id) ?? [],
    name: req.nameInput ?? '',
    exam: req.seriesInput ?? '',
    type_tryout: req.typeTOInput ?? '',
    class_student:
      req.classInput?.map((item) => {
        const {major, grade, level} = CLASS_STUDENT[+item.value]
        return {
          grade: grade === 'ALUMNI' ? 13 : +grade,
          major: major,
          level: level,
        }
      }) ?? [],
    target_user: req.isIncreaseStudent === 'true' ? 'mix' : req.targetUserInput ?? 'class',
    is_continue: req.isContinueInput === 'true' ? true : false,
    is_shuffle: req.isShuffleInput === 'true' ? true : false,
    is_disallow_prev: req.isDisallowPrevInput === 'true' ? true : false,
    duration: req.durationInput ? +req.durationInput : 0,
    question_ids:
      req.questionsInput?.map((item) => {
        const bindIsShuffle = req.questionsShuffleInput?.find((v) => v.id === item.id)
        return {
          id: item.id,
          is_shuffle: bindIsShuffle ? bindIsShuffle.is_shuffle : item?.is_shuffle ?? false,
        }
      }) ?? [],
    attendees: req.studentInput?.map((item) => {
      return {
        user_id: item.user_id,
        name: item.name,
      }
    }),
    school_ids:
      req.schoolInput?.map((item) => {
        return {
          id: item.id,
          name: item.name,
        }
      }) ?? [],
    total_question: req.totalQuestionInput ? +req.totalQuestionInput : 0,
    date_start: req.dateStartInput ?? '',
    date_end: req.dateEndInput ?? '',
    time_start: req.timeStartInput ?? '',
    time_end: req.timeEndInput ?? '',
  }
}

export function mappingInputFormToScheduleTryoutUpdateRequest(
  req: ScheduleTryoutFormInput,
  scheduleId: string
): ScheduleTryoutUpdateRequest {
  return {
    scheduleId: scheduleId,
    name: req.nameInput ?? '',
    exam: req.seriesInput ?? '',
    type_tryout: req.typeTOInput ?? '',
    duration: req.durationInput ? +req.durationInput : 10,
    date_start: req.dateStartInput ?? '',
    date_end: req.dateEndInput ?? '',
    time_start: req.timeStartInput ?? '',
    time_end: req.timeEndInput ?? '',
    target_user: req.isIncreaseStudent === 'true' ? 'mix' : req.targetUserInput ?? 'class',
    is_shuffle: req.isShuffleInput === 'true' ? true : false,
    is_disallow_prev: req.isDisallowPrevInput === 'true' ? true : false,
    total_question: req.totalQuestionInput ? +req.totalQuestionInput : 0,
    class_student:
      req.classInput?.map((item) => {
        const {major, grade, level} = CLASS_STUDENT[+item.value]
        return {
          grade: grade === 'ALUMNI' ? 13 : +grade,
          major: major,
          level: level,
        }
      }) ?? [],
    question_ids:
      req.questionsInput?.map((item) => {
        const bindIsShuffle = req.questionsShuffleInput?.find((v) => v.id === item.id)
        return {
          id: item.id,
          is_shuffle: bindIsShuffle ? bindIsShuffle.is_shuffle : item?.is_shuffle ?? false,
        }
      }) ?? [],
    attendees: req.studentInput?.map((item) => {
      return {
        user_id: item.user_id,
        name: item.name,
      }
    }),
    school_ids:
      req.schoolInput?.map((item) => {
        return {
          id: item.id,
          name: item.name,
        }
      }) ?? [],
    chapter_ids: req.chaptersInput?.map((item) => item.id) ?? [],
  }
}

export function mappingInputFormToScheduleTryoutCreateBatchRequest(
  req: ScheduleTryoutBatchFormInput
): ScheduleTryoutCreateBatchRequest {
  return {
    name: req.nameInput ?? '',
    exam: req.seriesInput ?? '',
    type_tryout: req.typeTOInput ?? '',
    class_student:
      req.classInput?.map((item) => {
        const {major, grade, level} = CLASS_STUDENT[+item.value]
        return {
          grade: grade === 'ALUMNI' ? 13 : +grade,
          major: major,
          level: level,
        }
      }) ?? [],
    target_user: req.isIncreaseStudent === 'true' ? 'mix' : req.targetUserInput ?? 'class',
    is_continue: req.isContinueInput === 'true' ? true : false,
    is_shuffle: req.isShuffleInput === 'true' ? true : false,
    is_disallow_prev: req.isDisallowPrevInput === 'true' ? true : false,
    attendees: req.studentInput?.map((item) => {
      return {
        user_id: item.user_id,
        name: item.name,
      }
    }),
    school_ids:
      req.schoolInput?.map((item) => {
        return {
          id: item.id,
          name: item.name,
        }
      }) ?? [],
    schedules:
      req.schedulesInput?.map((item) => {
        return {
          name: item.name ?? '',
          chapter_ids: item.chapters?.map((item) => item.id) ?? [],
          duration: item.duration ? +item.duration : 0,
          total_question: item.totalQuestion ? +item.totalQuestion : 0,
          date_start: item.dateStart ?? '',
          date_end: item.dateEnd ?? '',
          time_start: item.timeStart ?? '',
          time_end: item.timeEnd ?? '',
          question_ids:
            item.questionsInput?.map((v) => {
              const bindIsShuffle = item.questionsShuffleInput?.find((k) => k.id === v.id)
              return {
                id: v.id,
                is_shuffle: bindIsShuffle ? bindIsShuffle.is_shuffle : v?.is_shuffle ?? false,
              }
            }) ?? [],
        }
      }) ?? [],
  }
}

export function mappingInputFormToScheduleTryoutUpdateBatchRequest(
  req: ScheduleTryoutBatchFormInput
): ScheduleTryoutUpdateBatchRequest {
  return {
    name: req.nameInput ?? '',
    exam: req.seriesInput ?? '',
    type_tryout: req.typeTOInput ?? '',
    class_student:
      req.classInput?.map((item) => {
        const {major, grade, level} = CLASS_STUDENT[+item.value]
        return {
          grade: grade === 'ALUMNI' ? 13 : +grade,
          major: major,
          level: level,
        }
      }) ?? [],
    target_user: req.isIncreaseStudent === 'true' ? 'mix' : req.targetUserInput ?? 'class',
    is_continue: req.isContinueInput === 'true' ? true : false,
    is_shuffle: req.isShuffleInput === 'true' ? true : false,
    is_disallow_prev: req.isDisallowPrevInput === 'true' ? true : false,
    attendees: req.studentInput?.map((item) => {
      return {
        user_id: item.user_id,
        name: item.name,
      }
    }),
    school_ids:
      req.schoolInput?.map((item) => {
        return {
          id: item.id,
          name: item.name,
        }
      }) ?? [],
    schedules:
      req.schedulesInput?.map((item) => {
        return {
          new: item.newInput ?? false,
          _id: item.idScheduleInput,
          name: item.name ?? '',
          chapter_ids: item.chapters?.map((item) => item.id) ?? [],
          duration: item.duration ? +item.duration : 0,
          total_question: item.totalQuestion ? +item.totalQuestion : 0,
          date_start: item.dateStart ?? '',
          date_end: item.dateEnd ?? '',
          time_start: item.timeStart ?? '',
          time_end: item.timeEnd ?? '',
          question_ids:
            item.questionsInput?.map((v) => {
              const bindIsShuffle = item.questionsShuffleInput?.find((k) => k.id === v.id)
              return {
                id: v.id,
                is_shuffle: bindIsShuffle ? bindIsShuffle.is_shuffle : v?.is_shuffle ?? false,
              }
            }) ?? [],
        }
      }) ?? [],
  }
}
