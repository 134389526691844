/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Navigate, Outlet, Route, Routes} from 'react-router'
import {Chapter} from './pages/Chapter/Chapter'
import {School} from './pages/School/School'
import {Student} from './pages/Student/Student'
import {Subject} from './pages/Subject/Subject'
import {Facility} from './pages/Facility/Facility'
import {Product} from './pages/Product/Product'
import {PageTitle} from '../../../_layout/core'
import {Teacher} from './pages/Teacher/Teacher'
import {Team} from './pages/Team/Team'
import {GroupRole} from './pages/GroupRole/GroupRole'
import {AuthGroup} from './pages/AuthGroup/AuthGroup'
import {useAuth} from '../../../_context/AuthContext'
import {checkAuthLinkMenu} from '../../../helpers/AuthHelpers'
import {SLUG_MASTER_SUB_MENU} from '../../../helpers/UIAuthGroupConstanta'
import {Partner} from './pages/Partner/Partner'
import {Parent} from './pages/Parent/Parent'
// import { Ebook } from './pages/Ebook/Ebook'
import {GroupClass} from './pages/GroupClass/GroupClass'

const MasterWrapperContent: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>{'Master Main Menu'}</PageTitle>
      <Outlet />
    </>
  )
}

const MasterWrapper: FC = () => {
  const {currentUser, authorization} = useAuth()

  return (
    <>
      <Routes>
        <Route element={<MasterWrapperContent />}>
          <Route index element={<MasterWrapperContent />} />
          {checkAuthLinkMenu(
            SLUG_MASTER_SUB_MENU.MASTER_SUBJECT_MENU,
            currentUser,
            authorization
          ) && <Route path='subject/*' element={<Subject />} />}
          {checkAuthLinkMenu(
            SLUG_MASTER_SUB_MENU.MASTER_SCHOOL_MENU,
            currentUser,
            authorization
          ) && <Route path='school/*' element={<School />} />}
          {checkAuthLinkMenu(
            SLUG_MASTER_SUB_MENU.MASTER_CHAPTER_MENU,
            currentUser,
            authorization
          ) && <Route path='chapter/*' element={<Chapter />} />}
          {checkAuthLinkMenu(
            SLUG_MASTER_SUB_MENU.MASTER_STUDENT_MENU,
            currentUser,
            authorization
          ) && <Route path='student/*' element={<Student />} />}
          {checkAuthLinkMenu(
            SLUG_MASTER_SUB_MENU.MASTER_FASILITY_MENU,
            currentUser,
            authorization
          ) && <Route path='product_facility/*' element={<Facility />} />}
          {checkAuthLinkMenu(
            SLUG_MASTER_SUB_MENU.MASTER_GROUP_ROLE_MENU,
            currentUser,
            authorization
          ) && <Route path='group-role/*' element={<GroupRole />} />}
          {checkAuthLinkMenu(
            SLUG_MASTER_SUB_MENU.MASTER_PRODUCT_MENU,
            currentUser,
            authorization
          ) && <Route path='product/*' element={<Product />} />}
          {checkAuthLinkMenu(
            SLUG_MASTER_SUB_MENU.MASTER_TEACHER_MENU,
            currentUser,
            authorization
          ) && <Route path='teacher/*' element={<Teacher />} />}
          {checkAuthLinkMenu(SLUG_MASTER_SUB_MENU.MASTER_TEAM_MENU, currentUser, authorization) && (
            <Route path='team/*' element={<Team />} />
          )}
          {checkAuthLinkMenu(
            SLUG_MASTER_SUB_MENU.MASTER_PARTNER_MENU,
            currentUser,
            authorization
          ) && <Route path='partner/*' element={<Partner />} />}
          {checkAuthLinkMenu(
            SLUG_MASTER_SUB_MENU.MASTER_AUTH_GROUP_MENU,
            currentUser,
            authorization
          ) && <Route path='auth-group/*' element={<AuthGroup />} />}
          {checkAuthLinkMenu(
            SLUG_MASTER_SUB_MENU.MASTER_PARENT_MENU,
            currentUser,
            authorization
          ) && <Route path='parents/*' element={<Parent />} />}
          {checkAuthLinkMenu(
            SLUG_MASTER_SUB_MENU.MASTER_GROUP_CLASS_MENU,
            currentUser,
            authorization
          ) && <Route path='group-class/*' element={<GroupClass />} />}
          {/* <Route path='group-class/*' element={<GroupClass />} /> */}
          {/* <Route path='ebook/*' element={<Ebook />} /> */}

          <Route path='*' element={<Navigate to='/error/404' />} />
        </Route>
      </Routes>
    </>
  )
}

export {MasterWrapper}
