import {BannerResponse} from '../../_data/dataSource/response/BannerResponse'

export type Banner = {
  id: string
  name: string
  status: string
  image_urls?: {
    image: string
    link: string
    title: string
    description: string
  }[]
}

export class BannerBuilder {
  private SingleBanner!: Banner
  private BannerList!: Banner[]
  constructor() {}

  SingleData(bannerResponse: BannerResponse): BannerBuilder {
    const banner = mapingBanner(bannerResponse)
    this.SingleBanner = banner
    return this
  }
  ListData(bannerResponse: BannerResponse[]): BannerBuilder {
    const banners = mapingBanners(bannerResponse)
    this.BannerList = banners
    return this
  }
  Result(): Banner {
    return this.SingleBanner
  }
  ResultList(): Banner[] {
    return this.BannerList
  }
}

function mapingBanner(bannerResponse: BannerResponse): Banner {
  const banner: Banner = {
    id: bannerResponse.id,
    name: bannerResponse.name,
    status: bannerResponse.status === 'active' ? 'true' : 'false',
    image_urls: bannerResponse.image_urls,
  }
  return banner
}

function mapingBanners(bannersResponse: BannerResponse[]): Banner[] {
  const banners = bannersResponse.map((item) => {
    return mapingBanner(item)
  })
  return banners
}
