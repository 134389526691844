const SCHEDULE_POST_TEST_DELETE_TITLE = 'Schedule Post Test Delete'
const SCHEDULE_POST_TEST_DELETE_LOADING = 'Schedule Post Test is deleting...'
const SCHEDULE_POST_TEST_DELETE_CONFIRM = 'Are you sure to permanently delete this schedule post test ?'
const SCHEDULE_POST_TEST_DELETE_SUCCESS = 'Successful delete schedule post test'
const SCHEDULE_POST_TEST_DELETE_FAILED = "Can't delete schedule post test"
const SCHEDULE_POST_TEST_CREATE_TITLE = 'Schedule Post Test Create'
const SCHEDULE_POST_TEST_CREATE_FAILED = "Can't create schedule post test"
const SCHEDULE_POST_TEST_CREATE_SUCCESS = 'Successful create schedule post test'
const SCHEDULE_POST_TEST_EDIT_TITLE = 'Schedule Post Test Edit'
const SCHEDULE_POST_TEST_EDIT_SUCCESS = 'Successful update schedule post test'
const SCHEDULE_POST_TEST_EDIT_FAILED = "Can't update schedule post test"
const SCHEDULE_POST_TEST_GET_FAILED = "Can't get schedule post test"
const SCHEDULE_POST_TEST_LIST_TITLE = 'Schedule Post Test List'
export {
  SCHEDULE_POST_TEST_DELETE_TITLE,
  SCHEDULE_POST_TEST_DELETE_CONFIRM,
  SCHEDULE_POST_TEST_DELETE_LOADING,
  SCHEDULE_POST_TEST_DELETE_SUCCESS,
  SCHEDULE_POST_TEST_DELETE_FAILED,
  SCHEDULE_POST_TEST_CREATE_TITLE,
  SCHEDULE_POST_TEST_CREATE_SUCCESS,
  SCHEDULE_POST_TEST_CREATE_FAILED,
  SCHEDULE_POST_TEST_EDIT_TITLE,
  SCHEDULE_POST_TEST_EDIT_SUCCESS,
  SCHEDULE_POST_TEST_EDIT_FAILED,
  SCHEDULE_POST_TEST_GET_FAILED,
  SCHEDULE_POST_TEST_LIST_TITLE,
}
