import React, { Suspense, useMemo } from 'react'
import { SCHEDULE_POST_TEST_CREATE_TITLE } from '../../../../../_domain/constanta'
import { Link, useNavigate, useOutletContext } from 'react-router-dom'
import { createPostTest, getAllPostTestDatatable } from './PostTestAction'
import { useDispatch } from 'react-redux'
import { PageTitle } from '../../../../../_layout/core'
import CustomLoading from '../../../../../_component/customLoading'
import { ScheduleFormInput } from '../../../../../helpers/UIScheduleConstanta'

const PostTestCreateForm = React.lazy(() => import('./PostTestCreateComponent/PostTestCreateForm'))

const PostTestCreate = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch();

  const filterContext = useOutletContext<any>().queryParams
  const { queryParams } = useMemo(() => {
    return {
      queryParams: filterContext.queryParams,
    };
  }, [filterContext]);

  const saveAction = (data: ScheduleFormInput) => {
    dispatch<any>(createPostTest(data)).then(() => {
      dispatch<any>(getAllPostTestDatatable(queryParams));
      navigate("/edulive/post-test")
    })
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>{SCHEDULE_POST_TEST_CREATE_TITLE}</PageTitle>
      <div className='d-flex justify-content-between align-items-center mb-4'>
        <div className='w-full'></div>
        <div>
          <Link to={"/edulive/post-test"}>
            <button type='button'
              className='btn btn-secondary'>
              Back
            </button>
          </Link>
        </div>
      </div>
      <Suspense fallback={<CustomLoading />}>
        <PostTestCreateForm onSubmit={saveAction} />
      </Suspense>
    </>
  )
}

export default PostTestCreate 
