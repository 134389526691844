import PartnerDataSource from '../../_data/dataSource/PartnerDataSoruce'
import {BaseDatatableRequest} from '../../_data/dataSource/request/BaseDatatableRequest'
import {
  PartnerCreateRequest,
  PartnerUpdateRequest,
} from '../../_data/dataSource/request/PartnerRequest'
import {BaseDatatableResponse} from '../../_data/dataSource/response/BaseDatatableResponse'
import { PartnerResponse } from '../../_data/dataSource/response/PartnerResponse'
import {Partner, PartnerBuilder} from '../model/Partner'
import {PartnerRepository} from './PartnerRepository'

export class PartnerRepositoryImpl implements PartnerRepository {
  datasource: PartnerDataSource
  constructor(datasource: PartnerDataSource) {
    this.datasource = datasource
  }
  async getOneByUserId(userId: string | number): Promise<PartnerResponse> {
    const response = await this.datasource.getOneByUserId(userId)
    const initBuilder = new PartnerBuilder()
    const partner = initBuilder.SingleData(response).Result()
    return partner
  }

  async updateOne(req: PartnerUpdateRequest, id?: string): Promise<Partner> {
    const response = await this.datasource.update(req, id)
    const initBuilder = new PartnerBuilder()
    const partner = initBuilder.SingleData(response).Result()
    return partner
  }

  async createOne(req: PartnerCreateRequest): Promise<Partner> {
    const response = await this.datasource.create(req)
    const initBuilder = new PartnerBuilder()
    const partner = initBuilder.SingleData(response).Result()
    return partner
  }

  deleteOneById(id: string): Promise<string> {
    return this.datasource.deleteOne(id)
  }

  async getDatatable(req: BaseDatatableRequest): Promise<BaseDatatableResponse<Partner>> {
    const data = await this.datasource.getDatatable(req)
    const initBuilder = new PartnerBuilder()
    const entities = initBuilder.ListData(data.entities).ResultList()
    const totalCount = data.totalCount
    return {
      entities,
      totalCount,
      errorMessage: data.errorMessage,
    }
  }

  async getOneById(id: string): Promise<Partner> {
    const resposne = await this.datasource.getOne(id)
    const initBuilder = new PartnerBuilder()
    const partner = initBuilder.SingleData(resposne).Result()
    return partner
  }

  async getAll(): Promise<Partner[]> {
    const response = await this.datasource.getAll()
    const initBuilder = new PartnerBuilder()
    const partners = initBuilder.ListData(response).ResultList()
    return partners
  }
}
