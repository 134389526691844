import React, { useEffect, useMemo, useRef } from 'react'
import { getDatatableTypeTryout, getOneTypeTO, updateTypeTO } from './TryoutTypeAction'
import { useOutletContext } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { ModalBase } from '../../../../../_component/Modal'
import { TYPE_TRYOUT_EDIT_TITLE } from '../../../../../_domain/constanta/TypeTryoutConstant'
import TryoutTypeEditForm from './TryoutTypeEditComponent/TryoutTypeEditForm'

const TryoutTypeEdit = () => {
  const editContext = useOutletContext<any>().editSection
  const { propsForEdit, closingEditModal } = useMemo(() => {
    return {
      propsForEdit: editContext.propsForEdit,
      closingEditModal: editContext.closingEditModal
    };
  }, [editContext]);

  const filterContext = useOutletContext<any>().queryParams
  const { queryParams } = useMemo(() => {
    return {
      queryParams: filterContext.queryParams,
    };
  }, [filterContext]);

  const submitBtnRef = useRef<any>();
  const submitClick = () => {
    if (submitBtnRef && submitBtnRef.current) {
      submitBtnRef.current.click();
    }
  };

  const dispatch = useDispatch();
  const { actionsLoading, entity } = useSelector(
    (state: any) => ({
      actionsLoading: state.typeTOs.actionsLoading,
      entity: state.typeTOs.entity,
    }),
    shallowEqual
  );

  // if !id we should close modal
  useEffect(() => {
    if (!propsForEdit.id) {
      closingEditModal()
    } else {
      dispatch<any>(getOneTypeTO(propsForEdit.id))
    }
    return () => {
      dispatch<any>(getOneTypeTO(undefined))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propsForEdit.id]);

  const saveAction = (data: any) => {
    if (propsForEdit.id) {
      dispatch<any>(updateTypeTO(data, propsForEdit.id)).then(() => {
        // refresh list after deletion
        dispatch<any>(getDatatableTypeTryout(queryParams));
        // closing delete modal
        closingEditModal()
      })
    }  
  }

  return (
    <ModalBase size='lg' title={TYPE_TRYOUT_EDIT_TITLE} show={propsForEdit.show} onHide={closingEditModal} onAction={submitClick} >
      {entity && (
        <TryoutTypeEditForm submitBtnRef={submitBtnRef} onSubmit={saveAction} dataTypeTO={entity} />
      )}
    </ModalBase>
  )
}

export { TryoutTypeEdit }
