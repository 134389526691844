import { TYPE_PARENT } from '../../../_domain/constanta'
import { parentTypeSwitch } from '../../../helpers/FuncHelpers'
import {ParentFormInput} from '../../../helpers/UIParentConstanta'

export type ParentCreateRequest = {
  name: string
  email: string
  phone_number: string
  type: TYPE_PARENT
}

export type ParentUpdateRequest = {
  name: string
  phone_number: string
  type: TYPE_PARENT
}

export function mappingInputFormToParentCreateRequest(
  req: ParentFormInput
): ParentCreateRequest {
  return {
    name: req.nameInput ?? '',
    email: req.emailInput ?? '',
    phone_number: req.phoneNumberInput ? '+62' + req.phoneNumberInput : '+628xxxxxxx',
    type: parentTypeSwitch(req.typeParentInput!),
  }
}

export function mappingInputFormToParentUpdateRequest(
  req: ParentFormInput
): ParentUpdateRequest {
  return {
    name: req.nameInput ?? '',
    phone_number: req.phoneNumberInput ? '+62' + req.phoneNumberInput : '+628xxxxxxx',
    type: parentTypeSwitch(req.typeParentInput!),
  }
}
