import SubjectDataSource from '../../_data/dataSource/SubjectDataSoruce'
import {BaseDatatableRequest} from '../../_data/dataSource/request/BaseDatatableRequest'
import {
  SubjectCreateRequest,
  SubjectUpdateRequest,
} from '../../_data/dataSource/request/SubjectRequest'
import {BaseDatatableResponse} from '../../_data/dataSource/response/BaseDatatableResponse'
import {Subject, SubjectBuilder} from '../model/Subject'
import {SubjectRepository} from './SubjectRepository'

export class SubjectRepositoryImpl implements SubjectRepository {
  datasource: SubjectDataSource
  constructor(datasource: SubjectDataSource) {
    this.datasource = datasource
  }

  async updateOne(req: SubjectUpdateRequest, id?: string): Promise<Subject> {
    const response = await this.datasource.update(req, id)
    const initBuilder = new SubjectBuilder()
    const subject = initBuilder.SingleData(response).Result()
    return subject
  }

  async createOne(req: SubjectCreateRequest): Promise<Subject> {
    const response = await this.datasource.create(req)
    const initBuilder = new SubjectBuilder()
    const subject = initBuilder.SingleData(response).Result()
    return subject
  }

  deleteOneById(id: string): Promise<string> {
    return this.datasource.deleteOne(id)
  }

  async getDatatable(req: BaseDatatableRequest): Promise<BaseDatatableResponse<Subject>> {
    const data = await this.datasource.getDatatable(req)
    const initBuilder = new SubjectBuilder()
    const entities = initBuilder.ListData(data.entities).ResultList()
    const totalCount = data.totalCount
    return {
      entities,
      totalCount,
      errorMessage: data.errorMessage,
    }
  }

  async getOneById(id: string): Promise<Subject> {
    const resposne = await this.datasource.getOne(id)
    const initBuilder = new SubjectBuilder()
    const subject = initBuilder.SingleData(resposne).Result()
    return subject
  }

  async getAll(): Promise<Subject[]> {
    const response = await this.datasource.getAll()
    const initBuilder = new SubjectBuilder()
    const subjects = initBuilder.ListData(response).ResultList()
    return subjects
  }
}
