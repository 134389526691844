import {school} from '../../../../../_redux_slice'
import SchoolDataSourceImpl from '../../../../../_data/dataSource/SchoolDataSourceImpl'
import {SchoolRepositoryImpl} from '../../../../../_domain/repository/SchoolRepositoryImpl'
import {SchoolServiceImpl} from '../../../../../_domain/service/SchoolService'
import {BaseDatatableRequest} from '../../../../../_data/dataSource/request/BaseDatatableRequest'
import {
  SCHOOL_CREATE_FAILED,
  SCHOOL_CREATE_SUCCESS,
  SCHOOL_DELETE_FAILED,
  SCHOOL_DELETE_SUCCESS,
  SCHOOL_EDIT_FAILED,
  SCHOOL_EDIT_SUCCESS,
  SCHOOL_GET_FAILED,
} from '../../../../../_domain/constanta'
import {SchoolFormInput} from '../../../../../helpers/UISchoolConstanta'
import {alertError, alertSuccess, errorHandling} from '../../../../../helpers/FuncHelpers'
import {AlertPromise} from '../../../../../helpers/classHelpers'

const {actions} = school.slice
const callTypes = school.callTypes
const dataSource = new SchoolDataSourceImpl()
const repository = new SchoolRepositoryImpl(dataSource)
const service = new SchoolServiceImpl(repository)

const alertPromise = new AlertPromise()
export const getAllSchoolDatatable = (queryParams: BaseDatatableRequest) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.list}))

  return service
    .getDatatableSchool(queryParams)
    .then((response) => {
      const {totalCount, entities} = response
      dispatch(actions.saveList({totalCount, entities}))

      alertPromise.success()

      return response
    })
    .catch((err) => {
      if (err.response) {
        errorHandling(err.response, SCHOOL_GET_FAILED)
      } else {
        alertPromise.error()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const getOneSchool = (id: number | undefined) => (dispatch: any) => {
  if (!id) {
    return dispatch(actions.saveObject({data: undefined}))
  }

  dispatch(actions.startCall({callType: callTypes.action}))
  return service
    .getDetailSchoolById(id)
    .then((response) => {
      const school = response
      dispatch(actions.saveObject({data: school}))
      return school
    })
    .catch((err) => {
      if (err.response) {
        errorHandling(err.response, SCHOOL_GET_FAILED)
      } else {
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const createSchool = (req: SchoolFormInput) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.action}))
  return service
    .createOneSchool(req)
    .then((response) => {
      const school = response
      dispatch(actions.createObject({data: school}))
      alertPromise.stop()
      alertSuccess(SCHOOL_CREATE_SUCCESS)
    })
    .catch((err) => {
      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, SCHOOL_CREATE_FAILED)
      } else {
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const updateSchool = (req: SchoolFormInput, id: string) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.action}))
  return service
    .updateOneSchool(req, id)
    .then((response) => {
      const school = response
      dispatch(actions.updateObject({data: school}))
      alertPromise.stop()
      alertSuccess(SCHOOL_EDIT_SUCCESS)
    })
    .catch((err) => {
      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, SCHOOL_EDIT_FAILED)
      } else {
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const deleteOneSchool = (id: number) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.action}))
  return service
    .deleteOneSchoolByID(id)
    .then(() => {
      alertPromise.stop()
      alertSuccess(SCHOOL_DELETE_SUCCESS)
      dispatch(actions.delete({id}))
    })
    .catch((err) => {
      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, SCHOOL_DELETE_FAILED)
      } else {
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

// export const deleteBatchSchool = (ids) => (dispatch) => {
//   dispatch(actions.startCall({callType: callTypes.action}))
//   return schoolAPI
//     .deleteMany(ids)
//     .then(() => {
//       alertSuccess('Successful delete school')
//       dispatch(actions.deleteMany({ids}))
//     })
//     .catch((error) => {
//       error.clientMessage = "Can't delete schools"
//       alertError(error.clientMessage)
//       dispatch(actions.catchError({error, callType: callTypes.action}))
//     })
// }
