import axios from 'axios'
import {ApiResponse} from './response/BaseResponse'
import StorageDataSource from './StorageDataSource'
import {saveObjectRequest} from './request/StorageRequest'
import {StorageSaveObjectResponse} from './response/StorageResponse'

export default class StorageDataSourceImpl implements StorageDataSource {
  private STORAGE_URL: string

  constructor() {
    const service_storage_url = process.env.REACT_APP_STORAGE_SERVICE_URL || 'api/'
    this.STORAGE_URL = `${service_storage_url}`
  }
  async saveObject(req: saveObjectRequest): Promise<StorageSaveObjectResponse> {
    const formData = new FormData()
    formData.append('file', req.file)
    formData.append('path', req.path)
    formData.append('file_name', req.file_name)

    const response = await axios.post<ApiResponse<StorageSaveObjectResponse>>(
      `${this.STORAGE_URL}/save-object-private`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data', // Important for Axios to recognize it as FormData
        },
      }
    )
    const data = response.data.data

    return data
  }
}
