import {parents} from '../../../../../_redux_slice'
import {ParentRepositoryImpl} from '../../../../../_domain/repository/ParentRepositoryImpl'
import ParentDataSourceImpl from '../../../../../_data/dataSource/ParentDataSourceImpl'
import {ParentServiceImpl} from '../../../../../_domain/service/ParentService'
import {BaseDatatableRequest} from '../../../../../_data/dataSource/request/BaseDatatableRequest'
import {
  PARENT_CREATE_FAILED,
  PARENT_CREATE_SUCCESS,
  PARENT_DELETE_FAILED,
  PARENT_DELETE_SUCCESS,
  PARENT_EDIT_FAILED,
  PARENT_EDIT_SUCCESS,
  PARENT_GET_FAILED,
} from '../../../../../_domain/constanta'
import {ParentFormInput, ParentStudentAddInput} from '../../../../../helpers/UIParentConstanta'
import {alertError, alertSuccess, errorHandling} from '../../../../../helpers/FuncHelpers'
import {AlertPromise} from '../../../../../helpers/classHelpers'
import AuthDataSourceImpl from '../../../../../_data/dataSource/AuthDataSourceImpl'
import {UserRepositoryImpl} from '../../../../../_domain/repository/UserRepositoryImpl'
import {UserServiceImpl} from '../../../../../_domain/service/UserService'

const {actions} = parents.slice
// const controlAction = control.slice.actions
const callTypes = parents.callTypes
const dataSource = new ParentDataSourceImpl()
const repository = new ParentRepositoryImpl(dataSource)
const service = new ParentServiceImpl(repository)

const dataSourceUser = new AuthDataSourceImpl()
const repositoryUser = new UserRepositoryImpl(dataSourceUser)
const serviceUser = new UserServiceImpl(repositoryUser)

const alertPromise = new AlertPromise()
export const getAllParentDatatable = (queryParams: BaseDatatableRequest) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.list}))

  return service
    .getDatatableParent(queryParams)
    .then((response) => {
      const {totalCount, entities} = response
      dispatch(actions.saveList({totalCount, entities}))

      alertPromise.success()

      return response
    })
    .catch((err) => {
      if (err.response) {
        errorHandling(err.response, PARENT_GET_FAILED)
      } else {
        alertPromise.error()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const getOneParent = (userId: string | undefined) => (dispatch: any) => {
  if (!userId) {
    return dispatch(actions.saveObject({data: undefined}))
  }

  dispatch(actions.startCall({callType: callTypes.action}))
  return service
    .getDetailParentByUserId(userId)
    .then(async (response) => {
      let parent = response
      dispatch(actions.saveObject({data: parent}))
      return parent
    })
    .catch((err) => {
      if (err.response) {
        errorHandling(err.response, PARENT_GET_FAILED)
      } else {
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const createParent = (req: ParentFormInput) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.action}))
  return service
    .createOneParent(req)
    .then((response) => {
      alertPromise.stop()
      const parent = response
      dispatch(actions.createObject({data: parent}))
      alertSuccess(PARENT_CREATE_SUCCESS)
    })
    .catch((err) => {
      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, PARENT_CREATE_FAILED)
      } else {
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const addParentStudent =
  (parent_userId: number, req: ParentStudentAddInput) => (dispatch: any) => {
    alertPromise.start()
    dispatch(actions.startCall({callType: callTypes.action}))
    return service
      .addParentStudent(parent_userId, req)
      .then((response) => {
        alertPromise.stop()
        alertSuccess(PARENT_EDIT_SUCCESS)
      })
      .catch((err) => {
        alertPromise.stop()
        if (err.response) {
          errorHandling(err.response, PARENT_EDIT_FAILED)
        } else {
          alertError()
        }
        dispatch(actions.catchError({err, callType: callTypes.action}))
      })
  }

export const updateParent = (req: ParentFormInput, id: number) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.action}))
  return service
    .updateOneParent(req, id)
    .then((response) => {
      alertPromise.stop()
      const parent = response
      dispatch(actions.updateObject({data: parent}))
      alertSuccess(PARENT_EDIT_SUCCESS)
    })
    .catch((err) => {
      console.log(err)

      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, PARENT_EDIT_FAILED)
      } else {
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const deleteOneParent = (id: string) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.action}))
  return service
    .deleteOneParentByID(id)
    .then(() => {
      alertPromise.stop()
      alertSuccess(PARENT_DELETE_SUCCESS)
      dispatch(actions.deleteObject({id}))
    })
    .catch((err) => {
      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, PARENT_DELETE_FAILED)
      } else {
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}
