import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { StudentFilterTableFormInput } from '../../../../../../helpers/UIStudentConstanta'
import { Form } from 'react-bootstrap'
import { useOutletContext } from 'react-router-dom'
import { StudentFilterRequest } from '../../../../../../_data/dataSource/request/StudentRequest'

export default function StudentListFilter() {
  const {
    reset,
    control,
    getValues,
    setValue,
  } = useForm<StudentFilterTableFormInput>({
    mode: "onChange"
  })

  const outletContext = useOutletContext<any>().filter
  const { applyFilter } = outletContext

  function changeFilterState() {
    const textInput = getValues("textInput")
    const filter: StudentFilterRequest = {
      name: textInput
    }
    applyFilter(filter)
  }

  return (
    <Form>
      {/* Major Field */}
      <Form.Group className='row w-100'>
        <div className='col-lg-6'>
          <Controller
            name='textInput'
            defaultValue=''
            control={control}
            render={({ field }) => (
              <>
                <Form.Control {...field} type='text' placeholder='Search' className='mb-3' onChange={(e) => {
                  setValue("textInput", e.target.value)
                  changeFilterState()
                }} />
              </>
            )}
          />
        </div>
        <div className='col-lg-6 mt-2'>
          <button
            type='button'
            onClick={() => { reset({ textInput: '' }), changeFilterState() }}
            className='btn btn-sm btn-secondary'
          >
            Reset
          </button>
        </div>
      </Form.Group>
    </Form>
  )
}
