/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { PageTitle } from '../../../../../_layout/core'
import { getAllStudentDatatable } from './StudentAction'
import { Suspense } from 'react'
import CustomLoading from '../../../../../_component/customLoading'
import { Link, useOutletContext } from 'react-router-dom'
import { Student } from '../../../../../_domain/model/Student'
import { BaseDatatableResponse } from '../../../../../_data/dataSource/response/BaseDatatableResponse'
import { STUDENT_LIST_TITLE } from '../../../../../_domain/constanta/StudentConstant'
import StudentUploadBatch from './StudentUploadBatch'
import { Dropdown, Nav } from 'react-bootstrap'
import StudentRejectListTable from './StudentListComponent/StudentRejectListTable'
import StudentEdit from './StudentEdit'
import StudentListFilter from './StudentListComponent/StudentListFilter'
import AuthDataSourceImpl from '../../../../../_data/dataSource/AuthDataSourceImpl'
import SVG from 'react-inlinesvg'
import { KTIcon, toAbsoluteUrl } from '../../../../../_layout/helpers'
import StudentResetPassword from './StudentResetPassword'
import { useAuth } from '../../../../../_context/AuthContext'
import { checkAuthActionMenu } from '../../../../../helpers/AuthHelpers'
import { SLUG_MASTER_SUB_MENU } from '../../../../../helpers/UIAuthGroupConstanta'

const StudentListTable = React.lazy(() => import('./StudentListComponent/StudentListTable'))
const dataSource = new AuthDataSourceImpl()
const checkReduxData = () => {
  const outletContext = useOutletContext<any>().filter
  const { queryParams } = outletContext

  const [studentListData, setStudentListData] = useState<Student[] | null>(null)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch<any>(getAllStudentDatatable(queryParams)).then((response: BaseDatatableResponse<Student>) => {
      setStudentListData(response.entities)
    })
  }, [queryParams, getAllStudentDatatable])

  return studentListData
  // detect redux first before fetch API END
}

const checkRejectReduxData = () => {
  return JSON.parse(localStorage.getItem("reject_users") ?? "[{}]");
}

const StudentList = () => {
  const [tab, setTab] = useState("list")
  const { currentUser, authorization } = useAuth()

  // Upload Batch
  const uploadBatchContext = useOutletContext<any>().uploadBatch
  const { showingBaseModal } = uploadBatchContext

  const selectedIdsContext = useOutletContext<any>().selectedIds
  const { ids, showingResetModal } = selectedIdsContext

  let data = checkReduxData()
  let dataReject = checkRejectReduxData()

  return checkAuthActionMenu(SLUG_MASTER_SUB_MENU.MASTER_STUDENT_MENU, "view", currentUser, authorization) ? (
    <>
      <StudentUploadBatch />
      <StudentEdit />
      <StudentResetPassword />
      <PageTitle breadcrumbs={[]}>{STUDENT_LIST_TITLE}</PageTitle>
      <div className='d-flex justify-content-between align-items-center mb-4'>
        <StudentListFilter />
        <div>
          {/* <Link to={"/master/student/create"}>
            <button type='button'
              className='btn btn-sm btn-primary'>
              Add New
            </button>
          </Link> */}
          {/* {checkAuthActionMenu(SLUG_MASTER_SUB_MENU.MASTER_STUDENT_MENU, "edit", currentUser, authorization) && (
            <button className='btn btn-sm btn-success me-4' type='button' onClick={() => {
              window.location.href = dataSource.exportExcel();
            }}>
              <span className='svg-icon svg-icon-sm svg-icon-primary'>
                <SVG src={toAbsoluteUrl('/media/svg/icons/Files/Download.svg')} />
              </span>
              Export Excel
            </button>
          )} */}
          {checkAuthActionMenu(SLUG_MASTER_SUB_MENU.MASTER_STUDENT_MENU, "create", currentUser, authorization) && (
            <button onClick={() => showingBaseModal()} type='button'
              className='btn btn-sm btn-success me-4'>
              Upload New Student
            </button>
          )}
          {checkAuthActionMenu(SLUG_MASTER_SUB_MENU.MASTER_STUDENT_MENU, "edit", currentUser, authorization) && (
            <Dropdown className='d-inline-block'>
              <Dropdown.Toggle disabled={ids.length == 0} size='sm' variant="danger" id="dropdown-basic">
                Action
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => showingResetModal()}>Reset Password</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>
      </div>
      <Suspense fallback={<CustomLoading />}>
        <Nav variant="pills" activeKey={tab} className='mb-4'>
          <Nav.Item>
            <Nav.Link eventKey={"list"} title="List Student" onClick={() => setTab("list")}>
              List Student
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey={"reject"} title="List Reject Student" onClick={() => setTab("reject")}>
              List Reject Student
            </Nav.Link>
          </Nav.Item>
        </Nav>
        {tab === "list" && data && (
          <StudentListTable />
        )}
        {tab === "reject" && data && (
          <StudentRejectListTable entities={dataReject} totalCount={dataReject?.length ?? 0} />
        )}
      </Suspense>
    </>
  ) : null
}

export { StudentList }
