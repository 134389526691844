import {BaseDatatableRequest} from '../../_data/dataSource/request/BaseDatatableRequest'
import {
  mappingInputFormToSubjectCreateRequest,
  mappingInputFormToSubjectUpdateRequest,
} from '../../_data/dataSource/request/SubjectRequest'
import {BaseDatatableResponse} from '../../_data/dataSource/response/BaseDatatableResponse'
import {SubjectFormInput} from '../../helpers/UISubjectConstanta'
import {Subject} from '../model/Subject'
import {SubjectRepository} from '../repository/SubjectRepository'

export interface SubjectService {
  getDatatableSubject(req: BaseDatatableRequest): Promise<BaseDatatableResponse<Subject>>
  getAllSubject(): Promise<Subject[]>
  getDetailSubjectById(id: string): Promise<Subject>
  deleteOneSubjectByID(id: string): Promise<string>
  createOneSubject(req: SubjectFormInput): Promise<Subject>
  updateOneSubject(req: SubjectFormInput, id: string): Promise<Subject>
}

export class SubjectServiceImpl implements SubjectService {
  private repo: SubjectRepository
  constructor(repo: SubjectRepository) {
    this.repo = repo
  }

  async updateOneSubject(req: SubjectFormInput, id: string): Promise<Subject> {
    const body = mappingInputFormToSubjectUpdateRequest(req)
    const subject = await this.repo.updateOne(body, id)
    return subject
  }

  async createOneSubject(req: SubjectFormInput): Promise<Subject> {
    const body = mappingInputFormToSubjectCreateRequest(req)
    const subject = await this.repo.createOne(body)
    return subject
  }

  async deleteOneSubjectByID(id: string): Promise<string> {
    const message = await this.repo.deleteOneById(id)
    return message
  }

  async getDatatableSubject(req: BaseDatatableRequest): Promise<BaseDatatableResponse<Subject>> {
    const subjects = await this.repo.getDatatable(req)
    return subjects
  }

  async getDetailSubjectById(id: string): Promise<Subject> {
    const subject = await this.repo.getOneById(id)
    return subject
  }

  async getAllSubject(): Promise<Subject[]> {
    const subjects = await this.repo.getAll()
    return subjects
  }
}
