import {
  StudentAccountUpdateFormInput,
  UserReferralUpdateFormInput,
} from '../../../helpers/UIStudentConstanta'

export type AuthLoginRequest = {
  email: string
  password: string
}

export type UserUpdateRequest = {
  email: string
  password: string
  phone_number: string
}

export type UserFilterRequest = {
  search_text: string
}

export type UserReferraRequest = {
  user_id: number
  referral_code: string
}

export type UserRoleRequest = {
  role: string
  extend_roles: string[]
}

export function mappingInputFormToUserUpdateRequest(
  req: StudentAccountUpdateFormInput
): Partial<UserUpdateRequest> {
  const request: Partial<UserUpdateRequest> = {}
  if (req.emailInput) {
    request.email = req.emailInput
  }
  if (req.passwordInput) {
    request.password = req.passwordInput
  }
  if (req.phoneNumberInput) {
    request.phone_number = '+62' + req.phoneNumberInput
  }
  return request
}

export function mappingInputFormToUserReferralRequest(
  req: UserReferralUpdateFormInput
): UserReferraRequest {
  return {
    user_id: +req.userIdInput,
    referral_code: req.codeReferralInput?.replace(' ', '').toUpperCase() ?? '',
  }
}

export function mappingInputFormToUserRoleRequest(req: any): UserRoleRequest {
  return {
    role: req?.roleInput ?? undefined,
    extend_roles: req?.extendRolesInput ?? undefined,
  }
}
