/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { PageTitle } from '../../../../../_layout/core'
import { getAllPostTestDatatable } from './PostTestAction'
import { Suspense, lazy } from 'react'
import CustomLoading from '../../../../../_component/customLoading'
import { useOutletContext } from 'react-router-dom'
import { BaseDatatableResponse } from '../../../../../_data/dataSource/response/BaseDatatableResponse'
import { Schedule } from '../../../../../_domain/model/Schedule'
import PostTestDelete from './PostTestDelete'
import { SCHEDULE_POST_TEST_LIST_TITLE } from '../../../../../_domain/constanta'
import { useAuth } from '../../../../../_context/AuthContext'
import { checkAuthActionMenu } from '../../../../../helpers/AuthHelpers'
import { SLUG_EDULIVE_SUB_MENU } from '../../../../../helpers/UIAuthGroupConstanta'

const PostTestListTable = React.lazy(() => import('./PostTestListComponent/PostTestListTable'))

const checkReduxData = () => {
  const outletContext = useOutletContext<any>().queryParams
  const { queryParams } = outletContext

  const [postTestListData, setPostTestListData] = useState<any>(null)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch<any>(getAllPostTestDatatable(queryParams)).then((response: BaseDatatableResponse<Schedule>) => {
      setPostTestListData(response.entities)
    })
  }, [queryParams, getAllPostTestDatatable])

  return postTestListData
}

const PostTestList = () => {
  let data = checkReduxData()
  const { currentUser, authorization } = useAuth()
  return checkAuthActionMenu(SLUG_EDULIVE_SUB_MENU.EDULIVE_POST_TEST_MENU, "view", currentUser, authorization) ? (
    <>
      <PostTestDelete />
      <PageTitle breadcrumbs={[]}>{SCHEDULE_POST_TEST_LIST_TITLE}</PageTitle>
      <Suspense fallback={<CustomLoading />}>
        {data ? <PostTestListTable /> : null}
      </Suspense>
    </>
  ) : null
}

export { PostTestList }
