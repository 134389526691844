import { ReactNode, createContext, useContext, useEffect, useState } from 'react'
import { Subject } from '../_domain/model/Subject'
import SubjectDataSourceImpl from '../_data/dataSource/SubjectDataSourceImpl'
import { SubjectRepositoryImpl } from '../_domain/repository/SubjectRepositoryImpl'

type SubjectContextType = {
  listSubject: Subject[]
  currectSubject: Subject
  setCurrentSubject: (val: any) => {}
}

type props = {
  children: ReactNode
}

const SubjectUIContext = createContext<SubjectContextType>({
  listSubject: [],
  currectSubject: {
    id: '',
    name: '',
    code: '',
    major: ''
  },
  setCurrentSubject: function (val: any): {} {
    throw new Error('Function not implemented.')
  }
})

export function useSubjectUIContext() {
  return useContext(SubjectUIContext)
}

export const SubjectUIConsumer = SubjectUIContext.Consumer

export function SubjectUIProvider({ children }: props) {
  const [list, setList] = useState<Subject[]>([])
  const [currectSubject, setCurrentSubject] = useState<Subject | null>(null)

  const dataSource = new SubjectDataSourceImpl()
  const repo = new SubjectRepositoryImpl(dataSource)

  async function getAllSubject() {
    const response = await repo.getAll()
    setList(response)
  }

  useEffect(() => {
    getAllSubject()
  }, [])


  const value: any = {
    listSubject: list,
    currectSubject,
    setCurrentSubject,
  }
  return <SubjectUIContext.Provider value={value}>{children}</SubjectUIContext.Provider>
}
