import {BaseDatatableRequest} from '../../_data/dataSource/request/BaseDatatableRequest'
import {
  mappingInputFormToSchoolCreateRequest,
  mappingInputFormToSchoolUpdateRequest,
} from '../../_data/dataSource/request/SchoolRequest'
import {BaseDatatableResponse} from '../../_data/dataSource/response/BaseDatatableResponse'
import {SchoolFormInput} from '../../helpers/UISchoolConstanta'
import {School} from '../model/School'
import {SchoolRepository} from '../repository/SchoolRepository'

export interface SchoolService {
  getDatatableSchool(req: BaseDatatableRequest): Promise<BaseDatatableResponse<School>>
  getAllSchool(): Promise<School[]>
  getDetailSchoolById(id: number): Promise<School>
  deleteOneSchoolByID(id: number): Promise<string>
  createOneSchool(req: SchoolFormInput): Promise<School>
  updateOneSchool(req: SchoolFormInput, id?: string): Promise<School>
}

export class SchoolServiceImpl implements SchoolService {
  private repo: SchoolRepository
  constructor(repo: SchoolRepository) {
    this.repo = repo
  }

  async updateOneSchool(req: SchoolFormInput, id?: string): Promise<School> {
    const body = mappingInputFormToSchoolUpdateRequest(req)
    const school = await this.repo.updateOne(body, id)
    return school
  }

  async createOneSchool(req: SchoolFormInput): Promise<School> {
    const body = mappingInputFormToSchoolCreateRequest(req)
    const school = await this.repo.createOne(body)
    return school
  }

  async deleteOneSchoolByID(id: number): Promise<string> {
    const message = await this.repo.deleteOneById(id)
    return message
  }
  async getDatatableSchool(req: BaseDatatableRequest): Promise<BaseDatatableResponse<School>> {
    const schools = await this.repo.getDatatable(req)
    return schools
  }
  async getDetailSchoolById(id: number): Promise<School> {
    const school = await this.repo.getOneById(id)
    return school
  }
  async getAllSchool(): Promise<School[]> {
    const schools = await this.repo.getAll()
    return schools
  }
}
