import { PageTitle } from '../../../../../_layout/core'
import React, { Suspense, useMemo, useState } from 'react'
import CustomLoading from '../../../../../_component/customLoading'
import { Link, useNavigate, useOutletContext } from 'react-router-dom'
import { SCHEDULE_TRYOUT_CREATE_TITLE } from '../../../../../_domain/constanta/ScheduleTryoutConstant'
import { useDispatch } from 'react-redux'
import { QuestionTryoutTableProvider } from '../../../../../_component/Table/QuestionTryoutTable/QuestionTryoutTableContext'
import { createTryoutBatchSchedule, createTryoutSchedule, getAllTryoutScheduleDatatable } from './TryoutScheduleAction'
import { Nav } from 'react-bootstrap'
import { ScheduleTryoutFormInput } from '../../../../../helpers/UIScheduleTryoutConstanta'

const TryoutScheduleCreateForm = React.lazy(() =>
  import('./TryoutScheduleCreateComponent/TryoutScheduleCreateForm')
)
const TryoutScheduleCreateBatchForm = React.lazy(() =>
  import('./TryoutScheduleCreateComponent/TryoutScheduleCreateBatchForm')
)

const TryoutScheduleCreate = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const [tab, setTab] = useState("single")

  const filterContext = useOutletContext<any>().filter
  const { queryParams } = useMemo(() => {
    return {
      queryParams: filterContext.queryParams,
    };
  }, [filterContext]);

  const saveAction = (data: ScheduleTryoutFormInput) => {
    dispatch<any>(createTryoutSchedule(data)).then(() => {
      // refresh list after deletion
      dispatch<any>(getAllTryoutScheduleDatatable(queryParams));
      // Back
      navigate("/tryout/schedule")
    })
  }

  const saveBatchAction = (data: any) => {
    dispatch<any>(createTryoutBatchSchedule(data)).then(() => {
      // refresh list after deletion
      dispatch<any>(getAllTryoutScheduleDatatable(queryParams));
      // Back
      navigate("/tryout/schedule")
    })
  }
  return (
    <>
      <PageTitle breadcrumbs={[]}>{SCHEDULE_TRYOUT_CREATE_TITLE}</PageTitle>
      <div className='d-flex justify-content-between align-items-center mb-4'>
        <div className='w-full'></div>
        <div>
          <Link to={"/tryout/schedule"}>
            <button type='button'
              className='btn btn-secondary'>
              Back
            </button>
          </Link>
        </div>
      </div>
      <Suspense fallback={<CustomLoading />}>
        <Nav variant="pills" activeKey={tab} className='mb-4'>
          <Nav.Item>
            <Nav.Link eventKey={"single"} title="Single Schedule" onClick={() => setTab("single")}>
              Single Schedule
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey={"batch"} title="Batch Schedule" onClick={() => setTab("batch")}>
              Batch Schedule
            </Nav.Link>
          </Nav.Item>
        </Nav>
        {tab === "single" && (
          <QuestionTryoutTableProvider>
            <TryoutScheduleCreateForm onSubmit={saveAction} />
          </QuestionTryoutTableProvider>
        )}
        {tab === "batch" && (
          <TryoutScheduleCreateBatchForm onSubmit={saveBatchAction} />
        )}
      </Suspense>
    </>
  )
}

export { TryoutScheduleCreate }
