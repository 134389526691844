/* eslint-disable jsx-a11y/anchor-is-valid */
import {Outlet, Route, Routes} from 'react-router'
import {PageTitle} from '../../../../../_layout/core'
import {LiveClassList} from './LiveClassList'
import {
  attendanceSectionContext,
  attendanceSectionV2Context,
  deleteSectionContext,
  queryParamsContext,
} from './_outletContext'
import LiveClassCreate from './LiveClassCreate'
import LiveClassEdit from './LiveClassEdit'

const LiveClassContent = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>{'LiveClass'}</PageTitle>
      <Outlet
        context={{
          queryParams: queryParamsContext(),
          deleteSection: deleteSectionContext(),
          attendanceSection: attendanceSectionContext(),
          selectedSection: attendanceSectionV2Context(),
        }}
      />
    </>
  )
}

const LiveClass = () => {
  return (
    <>
      <Routes>
        <Route element={<LiveClassContent />}>
          <Route index element={<LiveClassList />} />
          <Route path='create' element={<LiveClassCreate />} />
          <Route path='edit/:liveClassId' element={<LiveClassEdit />} />
        </Route>
      </Routes>
    </>
  )
}

export {LiveClass}
