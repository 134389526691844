import axios from 'axios'
import GroupClassDataSource from './GroupClassDataSoruce'
import {GroupClassResponse} from './response/GroupClassResponse'
import {ApiResponse} from './response/BaseResponse'
import {BaseDatatableRequest} from './request/BaseDatatableRequest'
import {BaseDatatableResponse} from './response/BaseDatatableResponse'
import {MessageResponse} from './response/MessageResponse'
import {GroupClassCreateRequest, GroupClassUpdateRequest} from './request/GroupClassRequest'
import {StudentResponse} from './response/StudentResponse'

export default class GroupClassDataSourceImpl implements GroupClassDataSource {
  private CLASS_URL: string
  constructor() {
    const service_student_url = process.env.REACT_APP_STUDENT_SERVICE_URL || 'api/'
    this.CLASS_URL = `${service_student_url}group-classes`
  }

  async getDatatableSearchStudent(
    req: BaseDatatableRequest,
    id?: number
  ): Promise<BaseDatatableResponse<StudentResponse>> {
    const response = await axios.post<ApiResponse<BaseDatatableResponse<StudentResponse>>>(
      `${this.CLASS_URL}/search-student-datatable/${id}`,
      req
    )

    const data = response.data.data

    return data
  }

  async getAllFilter(params: any[]): Promise<GroupClassResponse[]> {
    const response = await axios.post<ApiResponse<GroupClassResponse[]>>(
      `${this.CLASS_URL}/filter`,
      {
        params: params,
      }
    )

    const data = response.data.data
    return data
  }

  async update(req: GroupClassUpdateRequest, id: string): Promise<GroupClassResponse> {
    const response = await axios.put<ApiResponse<GroupClassResponse>>(
      `${this.CLASS_URL}/${id}`,
      req
    )

    const data = response.data.data
    return data
  }

  async deleteOne(id: string): Promise<string> {
    const response = await axios.delete<MessageResponse>(`${this.CLASS_URL}/${id}`)
    return response.data.message
  }

  async getDatatable(
    req: BaseDatatableRequest
  ): Promise<BaseDatatableResponse<GroupClassResponse>> {
    const response = await axios.post<ApiResponse<BaseDatatableResponse<GroupClassResponse>>>(
      `${this.CLASS_URL}/datatable`,
      req
    )

    const data = response.data.data

    return data
  }

  async getOne(id: string): Promise<GroupClassResponse> {
    const response = await axios.get<ApiResponse<GroupClassResponse>>(`${this.CLASS_URL}/${id}`)
    const data = response.data.data
    return data
  }

  async getAll(params?: any): Promise<GroupClassResponse[]> {
    const response = await axios.get<ApiResponse<GroupClassResponse[]>>(`${this.CLASS_URL}`, {
      params: params,
    })
    const data = response.data.data
    return data
  }

  async create(req: GroupClassCreateRequest): Promise<GroupClassResponse> {
    const response = await axios.post<ApiResponse<GroupClassResponse>>(`${this.CLASS_URL}`, req)
    const data = response.data.data

    return data
  }
}
