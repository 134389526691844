import {StudentClassResponse} from '../../_data/dataSource/response/StudentClassResponse'

export type StudentClass = StudentClassResponse

export class StudentClassBuilder {
  private SingleStudentClass!: StudentClass
  private StudentClassList!: StudentClass[]
  constructor() {}

  SingleData(studentClassResponse: StudentClassResponse): StudentClassBuilder {
    const studentClass = mapingStudentClass(studentClassResponse)
    this.SingleStudentClass = studentClass
    return this
  }
  ListData(studentClassResponse: StudentClassResponse[]): StudentClassBuilder {
    const studentClasss = mapingStudentClasss(studentClassResponse)
    this.StudentClassList = studentClasss
    return this
  }
  Result(): StudentClass {
    return this.SingleStudentClass
  }
  ResultList(): StudentClass[] {
    return this.StudentClassList
  }
}

function mapingStudentClass(studentClassResponse: StudentClassResponse): StudentClass {
  const studentClass: StudentClass = studentClassResponse
  return studentClass
}

function mapingStudentClasss(studentClasssResponse: StudentClassResponse[]): StudentClass[] {
  const studentClasss = studentClasssResponse.map((item) => {
    return mapingStudentClass(item)
  })
  return studentClasss
}
