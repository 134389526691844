import axios from 'axios'
import TypeTryoutDataSource from './TypeTryoutDataSoruce'
import {TypeTryoutResponse} from './response/TypeTryoutResponse'
import {TypeTryout} from '../../_domain/model/TypeTryout'
import {ApiResponse} from './response/BaseResponse'
import {BaseDatatableRequest} from './request/BaseDatatableRequest'
import {BaseDatatableResponse} from './response/BaseDatatableResponse'
import {MessageResponse} from './response/MessageResponse'
import {TypeTryoutCreateRequest} from './request/TypeTryoutRequest'

export default class TypeTryoutDataSourceImpl implements TypeTryoutDataSource {
  private TYPE_URL: string
  constructor() {
    const service_type_to_url = process.env.REACT_APP_CHAPTER_SERVICE_URL || 'api/'
    this.TYPE_URL = `${service_type_to_url}tryout-types`
  }

  async update(req: any, id: string): Promise<TypeTryoutResponse> {
    const response = await axios.put<ApiResponse<TypeTryoutResponse>>(`${this.TYPE_URL}/${id}`, req)

    const data = response.data.data
    return data
  }

  async deleteOne(id: string): Promise<string> {
    const response = await axios.delete<MessageResponse>(`${this.TYPE_URL}/${id}`)
    return response.data.message
  }

  async getDatatable(req: BaseDatatableRequest): Promise<BaseDatatableResponse<TypeTryoutResponse>> {
    const response = await axios.post<ApiResponse<BaseDatatableResponse<TypeTryoutResponse>>>(
      `${this.TYPE_URL}/datatable`,
      req
    )

    const data = response.data.data

    return data
  }

  async getOne(id: string): Promise<TypeTryoutResponse> {
    const response = await axios.get<ApiResponse<TypeTryoutResponse>>(`${this.TYPE_URL}/${id}`)
    const data = response.data.data
    return data
  }

  async getAll(): Promise<TypeTryoutResponse[]> {
    const response = await axios.get<ApiResponse<TypeTryoutResponse[]>>(`${this.TYPE_URL}`)
    const data = response.data.data
    return data
  }

  async create(req: TypeTryoutCreateRequest): Promise<TypeTryoutResponse> {
    const response = await axios.post<ApiResponse<TypeTryoutResponse>>(`${this.TYPE_URL}/`, req)
    const data = response.data.data

    return data
  }
}
