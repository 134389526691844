import SchedulePostTestDataSource from '../../_data/dataSource/SchedulePostTestDataSoruce'
import {BaseDatatableRequest} from '../../_data/dataSource/request/BaseDatatableRequest'
import {
  SchedulePostTestCreateRequest,
  SchedulePostTestUpdateRequest,
} from '../../_data/dataSource/request/SchedulePostTestRequest'
import {BaseDatatableResponse} from '../../_data/dataSource/response/BaseDatatableResponse'
import {Schedule, ScheduleBuilder} from '../model/Schedule'
import {SchedulePostTestRepository} from './SchedulePostTestRepository'

export class SchedulePostTestRepositoryImpl implements SchedulePostTestRepository {
  datasource: SchedulePostTestDataSource

  constructor(datasource: SchedulePostTestDataSource) {
    this.datasource = datasource
  }

  async updateQuestions(id: string, file: any): Promise<void> {
    this.datasource.uploadQuestions(id, file)
  }

  async getDatatable(req: BaseDatatableRequest): Promise<BaseDatatableResponse<Schedule>> {
    const response = await this.datasource.getDatatable(req)
    const initBuilder = new ScheduleBuilder()
    const entities: Schedule[] = initBuilder.ListData(response.entities).ResultList()
    const errorMessage = response.errorMessage
    const totalCount = response.totalCount

    return {
      entities,
      errorMessage,
      totalCount,
    }
  }

  getAll(): Promise<Schedule[]> {
    throw new Error('Method not implemented.')
  }

  async getOneById(id: string | number): Promise<Schedule> {
    const response = await this.datasource.getOne(id)
    const initBuilder = new ScheduleBuilder()
    const data: Schedule = initBuilder.SingleData(response).Result()
    return data
  }

  async deleteOneById(id: string | number): Promise<string> {
    const response = await this.datasource.deleteOne(id)
    return response
  }

  async createOne(req: SchedulePostTestCreateRequest): Promise<Schedule> {
    const response = await this.datasource.create(req)
    const initBuilder = new ScheduleBuilder()
    const data: Schedule = initBuilder.SingleData(response).Result()
    return data
  }

  async updateOne(req: SchedulePostTestUpdateRequest, id?: string | number): Promise<Schedule> {
    const response = await this.datasource.update(req, id)
    const initBuilder = new ScheduleBuilder()
    const data: Schedule = initBuilder.SingleData(response).Result()
    return data
  }
}
