import {useCallback, useEffect, useState} from 'react'
import CityDataSourceImpl from '../_data/dataSource/CityDataSourceImpl'
import {City} from '../_domain/model/City'
import {CityGetAllParams} from '../_data/dataSource/request/CityRequest'
import {CityRepositoryImpl} from '../_domain/repository/CityRepositoryImpl'
import {UserAttendance} from '../_domain/model/Schedule'
import ScheduleLiveClassDataSourceImpl from '../_data/dataSource/ScheduleLiveClassDataSourceImpl'
import {ScheduleLiveClassRepositoryImpl} from '../_domain/repository/ScheduleLiveClassRepositoryImpl'

type Props = {
  user_id: number
}

const useStudentAttendance = (props?: Props) => {
  const [list, setList] = useState<UserAttendance[]>([])

  const dataSource = new ScheduleLiveClassDataSourceImpl()
  const repo = new ScheduleLiveClassRepositoryImpl(dataSource)

  async function getAllAttendance(params?: number) {
    const response = await repo.getListAttendanceByUserId(params!)
    setList(response)
  }

  const changeUserAttendance = useCallback(
    (params?: number) => {
      getAllAttendance(params)
    },
    [getAllAttendance]
  )

  useEffect(() => {
    if (props?.user_id) {
      changeUserAttendance(props.user_id)
    }
  }, [])

  return {
    listAttendance: list,
    getAllAttendance,
    changeUserAttendance,
  }
}

export default useStudentAttendance
