import TeacherDataSource from '../../_data/dataSource/TeacherDataSoruce'
import {BaseDatatableRequest} from '../../_data/dataSource/request/BaseDatatableRequest'
// import {
//   TeacherCreateRequest,
//   TeacherGetAllParams,
//   TeacherUpdateRequest,
// } from '../../_data/dataSource/request/TeacherRequest'
import {BaseDatatableResponse} from '../../_data/dataSource/response/BaseDatatableResponse'
import {Teacher, TeacherBuilder} from '../model/Teacher'
import {TeacherRepository} from './TeacherRepository'

export class TeacherRepositoryImpl implements TeacherRepository {
  datasource: TeacherDataSource

  constructor(datasource: TeacherDataSource) {
    this.datasource = datasource
  }

  async updatePhoto(id: string, file: any): Promise<void> {
    this.datasource.uploadPhoto(id, file)
  }

  async updateOne(req: any, id?: string): Promise<Teacher> {
    const response = await this.datasource.update(req, id)
    const initBuilder = new TeacherBuilder()
    const teacher = initBuilder.SingleData(response).Result()
    return teacher
  }

  async createOne(req: any): Promise<Teacher> {
    const resposne = await this.datasource.create(req)
    const initBuilder = new TeacherBuilder()
    const teacher = initBuilder.SingleData(resposne).Result()
    return teacher
  }

  deleteOneById(id: number): Promise<string> {
    return this.datasource.deleteOne(id)
  }

  async getDatatable(req: BaseDatatableRequest): Promise<BaseDatatableResponse<Teacher>> {
    const data = await this.datasource.getDatatable(req)
    const initBuilder = new TeacherBuilder()
    const entities = initBuilder.ListData(data.entities).ResultList()
    const totalCount = data.totalCount
    return {
      entities,
      totalCount,
      errorMessage: data.errorMessage,
    }
  }

  async getOneById(id: number): Promise<Teacher> {
    const response = await this.datasource.getOne(id)
    const initBuilder = new TeacherBuilder()
    const teacher = initBuilder.SingleData(response).Result()
    return teacher
  }

  async getAll(params?: any): Promise<Teacher[]> {
    const response = await this.datasource.getAll()
    const initBuilder = new TeacherBuilder()
    const teachers = initBuilder.ListData(response).ResultList()
    return teachers
  }
}
