import ChapterDataSourceImpl from '../../../../../_data/dataSource/ChapterDataSourceImpl'
import ScheduleTryoutDataSourceImpl from '../../../../../_data/dataSource/ScheduleTryoutDataSourceImpl'
import SchoolDataSourceImpl from '../../../../../_data/dataSource/SchoolDataSourceImpl'
import { BaseDatatableRequest } from '../../../../../_data/dataSource/request/BaseDatatableRequest'
import { SCHEDULE_TRYOUT_CREATE_FAILED, SCHEDULE_TRYOUT_CREATE_SUCCESS, SCHEDULE_TRYOUT_DELETE_FAILED, SCHEDULE_TRYOUT_DELETE_SUCCESS, SCHEDULE_TRYOUT_EDIT_FAILED, SCHEDULE_TRYOUT_EDIT_SUCCESS, SCHEDULE_TRYOUT_GET_FAILED } from '../../../../../_domain/constanta/ScheduleTryoutConstant'
import { ChapterRepositoryImpl } from '../../../../../_domain/repository/ChapterRepositoryImpl'
import { ScheduleTryoutRepositoryImpl } from '../../../../../_domain/repository/ScheduleTryoutRepositoryImpl'
import { SchoolRepositoryImpl } from '../../../../../_domain/repository/SchoolRepositoryImpl'
import { ScheduleTryoutServiceImpl } from '../../../../../_domain/service/ScheduleTryoutService'
import { scheduleTO } from '../../../../../_redux_slice'
import { alertError, alertSuccess, errorHandling } from '../../../../../helpers/FuncHelpers'
import { ScheduleTryoutBatchFormInput, ScheduleTryoutFormInput } from '../../../../../helpers/UIScheduleTryoutConstanta'
import { AlertPromise } from '../../../../../helpers/classHelpers'

const { actions } = scheduleTO.slice
// const controlAction = control.slice.actions
const callTypes = scheduleTO.callTypes
const dataSource = new ScheduleTryoutDataSourceImpl()
const dataSourceChapter = new ChapterDataSourceImpl()
const dataSourceSchool = new SchoolDataSourceImpl()
const repository = new ScheduleTryoutRepositoryImpl(dataSource)
const repositoryChapter = new ChapterRepositoryImpl(dataSourceChapter)
const repositorySchool = new SchoolRepositoryImpl(dataSourceSchool)
const service = new ScheduleTryoutServiceImpl(repository, repositoryChapter, repositorySchool)

const alertPromise = new AlertPromise()
export const getAllTryoutScheduleDatatable = (queryParams: BaseDatatableRequest) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({ callType: callTypes.list }))
  return service
    .getDatatableScheduleTryout(queryParams)
    .then((response) => {
      const { totalCount, entities } = response
      dispatch(actions.saveList({ totalCount, entities }))

      alertPromise.stop()
      return response
    })
    .catch((err) => {
      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, SCHEDULE_TRYOUT_GET_FAILED)
      } else {
        alertError()
      }
      dispatch(actions.catchError({ err, callType: callTypes.action }))
    })
}

export const getAllTryoutScheduleBatchDatatable = (queryParams: BaseDatatableRequest) => (dispatch: any) => {
  // alertPromise.start()
  dispatch(actions.startCall({ callType: callTypes.list }))
  return service
    .getDatatableScheduleTryoutBatch(queryParams)
    .then((response) => {
      const { totalCount, entities } = response
      dispatch(actions.saveListBatch({ totalCount, entities }))

      // alertPromise.stop()
      return response
    })
    .catch((err) => {
      // alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, SCHEDULE_TRYOUT_GET_FAILED)
      } else {
        console.error(err);
        alertError()
      }
      dispatch(actions.catchError({ err, callType: callTypes.action }))
    })
}

export const getOneTryoutSchedule = (id?: string) => (dispatch: any) => {
  if (!id) {
    return dispatch(
      actions.saveObject({
        data: undefined,
      })
    )
  }

  dispatch(actions.startCall({ callType: callTypes.action }))
  return service
    .getDetailScheduleTryoutById(id)
    .then((response) => {
      const schedule = response
      dispatch(actions.saveObject({ data: schedule }))
      return schedule
    })
    .catch((err) => {
      if (err.response) {
        errorHandling(err.response, SCHEDULE_TRYOUT_GET_FAILED)
      } else {
        console.error(err);
        alertError()
      }
      dispatch(actions.catchError({ err, callType: callTypes.action }))
    })
}

export const getOneTryoutScheduleBatch = (id?: string) => (dispatch: any) => {
  if (!id) {
    return dispatch(
      actions.saveObjectBatch({
        data: undefined,
      })
    )
  }

  dispatch(actions.startCall({ callType: callTypes.action }))
  return service
    .getDetailScheduleBatchTryoutById(id)
    .then((response) => {
      const schedule = response
      dispatch(actions.saveObjectBatch({ data: schedule }))
      return schedule
    })
    .catch((err) => {
      if (err.response) {
        errorHandling(err.response, SCHEDULE_TRYOUT_GET_FAILED)
      } else {
        console.error(err);
        alertError()
      }
      dispatch(actions.catchError({ err, callType: callTypes.action }))
    })
}

export const createTryoutSchedule = (request: ScheduleTryoutFormInput) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({ callType: callTypes.action }))
  return service
    .createOneScheduleTryout(request)
    .then((response) => {
      const schedule = response
      dispatch(actions.createObject({ data: schedule }))
      alertPromise.stop()
      alertSuccess(SCHEDULE_TRYOUT_CREATE_SUCCESS)
    })
    .catch((err) => {
      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, SCHEDULE_TRYOUT_CREATE_FAILED)
      } else {
        console.error(err);
        alertError()
      }
      dispatch(actions.catchError({ err, callType: callTypes.action }))
    })
}

export const createTryoutBatchSchedule = (request: ScheduleTryoutBatchFormInput) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({ callType: callTypes.action }))
  return service
    .createBatchScheduleTryout(request)
    .then(() => {
      alertPromise.stop()
      alertSuccess(SCHEDULE_TRYOUT_CREATE_SUCCESS)
    })
    .catch((err) => {
      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, SCHEDULE_TRYOUT_CREATE_FAILED)
      } else {
        console.error(err);
        alertError()
      }
      dispatch(actions.catchError({ err, callType: callTypes.action }))
    })
}

// export const createBatchTryoutSchedule = (request) => (dispatch) => {
//   dispatch(controlAction.setLoading(true))
//   return scheduleTOAPI
//     .createBatch(request)
//     .then((response) => {
//       alertSuccess('Successful create schedule')
//       dispatch(actions.stopCall({callType: callTypes.action}))
//       return response.data.data
//     })
//     .catch((error) => {
//       error.clientMessage = "Can't create scheduleCustomTest"
//       alertError(error.clientMessage)
//       dispatch(actions.catchError({error, callType: callTypes.action}))
//       dispatch(controlAction.setLoading(false))
//     })
// }

export const updateTryoutSchedule = (request: ScheduleTryoutFormInput, scheduleId: string) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({ callType: callTypes.action }))
  return service
    .updateOneScheduleTryout(request, scheduleId)
    .then((response) => {
      const schedule = response
      dispatch(actions.createObject({ data: schedule }))
      alertPromise.stop()
      alertSuccess(SCHEDULE_TRYOUT_EDIT_SUCCESS)
    })
    .catch((err) => {
      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, SCHEDULE_TRYOUT_EDIT_FAILED)
      } else {
        console.error(err);
        alertError()
      }
      dispatch(actions.catchError({ err, callType: callTypes.action }))
    })
}

export const updateTryoutBatchSchedule = (request: ScheduleTryoutFormInput, scheduleId: string) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({ callType: callTypes.action }))
  return service
    .updateBatchScheduleTryout(request, scheduleId)
    .then(() => {
      alertPromise.stop()
      alertSuccess(SCHEDULE_TRYOUT_EDIT_SUCCESS)
    })
    .catch((err) => {
      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, SCHEDULE_TRYOUT_EDIT_FAILED)
      } else {
        console.error(err);
        alertError()
      }
      dispatch(actions.catchError({ err, callType: callTypes.action }))
    })
}

export const deleteOneTryoutSchedule = (id: string) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({ callType: callTypes.action }))
  return service
    .deleteOneScheduleTryoutByID(id)
    .then(() => {
      alertPromise.stop()
      alertSuccess(SCHEDULE_TRYOUT_DELETE_SUCCESS)
      dispatch(actions.deleteObject({ id }))
    })
    .catch((err) => {
      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, SCHEDULE_TRYOUT_DELETE_FAILED)
      } else {
        console.error(err)
        alertError()
      }
      dispatch(actions.catchError({ err, callType: callTypes.action }))
    })
}

export const deleteOneTryoutScheduleBatch = (id: string) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({ callType: callTypes.action }))
  return service
    .deleteOneScheduleTryoutBatchByID(id)
    .then(() => {
      alertPromise.stop()
      alertSuccess(SCHEDULE_TRYOUT_DELETE_SUCCESS)
      dispatch(actions.deleteObject({ id }))
    })
    .catch((err) => {
      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, SCHEDULE_TRYOUT_DELETE_FAILED)
      } else {
        console.error(err)
        alertError()
      }
      dispatch(actions.catchError({ err, callType: callTypes.action }))
    })
}

// export const deleteBatchTryoutSchedule = (ids) => (dispatch) => {
//   dispatch(controlAction.setLoading(true))
//   return scheduleTOAPI
//     .deleteMany(ids)
//     .then(() => {
//       alertSuccess('Successful delete tryout schedule')
//       dispatch(actions.deleteManyObject({ids}))
//       dispatch(controlAction.setLoading(false))
//     })
//     .catch((error) => {
//       error.clientMessage = "Can't delete schedule tryout"
//       alertError(error.clientMessage)
//       dispatch(actions.catchError({error, callType: callTypes.action}))
//       dispatch(controlAction.setLoading(false))
//     })
// }
