import axios from 'axios'
import {StudentClassResponse} from './response/StudentClassResponse'
import {StudentClassDataSource} from './StudentClassDataSource'
import {ApiResponse} from './response/BaseResponse'
import {MessageResponse} from './response/MessageResponse'

export default class StudentClassDataSourceImpl implements StudentClassDataSource {
  private STUDENT_URL: string

  constructor() {
    const REACT_APP_STUDENT_SERVICE_URL = process.env.REACT_APP_STUDENT_SERVICE_URL || 'api/'
    this.STUDENT_URL = `${REACT_APP_STUDENT_SERVICE_URL}group-classes`
  }

  async getOne(id: number): Promise<StudentClassResponse> {
    const response = await axios.get<ApiResponse<StudentClassResponse>>(
      `${this.STUDENT_URL}/${id}/student`
    )

    const data = response.data.data
    return data
  }

  async deleteOne(id: number, req: any): Promise<string> {
    const response = await axios.delete<MessageResponse>(`${this.STUDENT_URL}/${id}/student`, {
      data: req,
      headers: {
        'Content-Type': 'application/json',
      },
    })
    return response.data.message
  }

  async create(id: number, req: any): Promise<string> {
    const response = await axios.post<MessageResponse>(`${this.STUDENT_URL}/${id}/student`, req)
    return response.data.message
  }

  async update(id: number, req: any): Promise<string> {
    const response = await axios.put<MessageResponse>(`${this.STUDENT_URL}/${id}/student`)
    return response.data.message
  }
}
