import {GENDER, MAJOR, TRYOUT_MAJOR} from '../../../_domain/constanta'
import {genderSwitch, typeMajorSwitch, typeTryoutMajorSwitch} from '../../../helpers/FuncHelpers'
import {CLASS_STUDENT} from '../../../helpers/UIConstanta'
import {
  StudentAccessUpdateFormInput,
  StudentUpdateClassFormInput,
  StudentUpdateFormInput,
} from '../../../helpers/UIStudentConstanta'

export type StudenUpdateClassRequest = {
  major: MAJOR
  grade: number
  // After some nice Styling, separate this field below
  school_name?: string,
  phone_number?: string
}

export type StudenUpdateRequest = {
  name: string
  major: MAJOR
  grade: number
  type_tryout: TRYOUT_MAJOR
  gender: GENDER
  address?: string
  school_id: number
}

export type StudentAccessUpdateRequest = {
  activate?: boolean
  activate_tryout?: boolean
}

export type StudentFilterRequest = {
  name?: string
}

export function mappingInputFormToStudentUpdateClassRequest(
  req: StudentUpdateClassFormInput
): StudenUpdateClassRequest {
  const {major, grade} = CLASS_STUDENT[+req.classInput!]
  return {
    major: typeMajorSwitch(major),
    grade: grade === 'ALUMNI' ? 13 : +grade,
    school_name: req.schoolName,
    phone_number: '+62' + req.phoneNumberInput,
  }
}

export function mappingInputFormToStudentAccessUpdateRequest(
  req: any
): StudentAccessUpdateRequest {
  return {
    activate: req.studentActivationInput,
    activate_tryout: req.studentActivationTryoutInput,
  }
}

export function mappingInputFormToStudentUpdateRequest(
  req: StudentUpdateFormInput
): Partial<StudenUpdateRequest> {
  const result: Partial<StudenUpdateRequest> = {}
  if (req.nameInput) {
    result.name = req.nameInput
  }
  if (req.classInput) {
    const {major, grade} = CLASS_STUDENT[+req.classInput!]
    result.major = typeMajorSwitch(major)
    result.grade = grade === 'ALUMNI' ? 13 : +grade
  }
  if (req.typeTryoutInput) {
    result.type_tryout = typeTryoutMajorSwitch(req.typeTryoutInput)
  }
  if (req.genderInput) {
    result.gender = genderSwitch(req.genderInput)
  }
  if (req.addressInput) {
    result.address = req.addressInput
  }
  if (req.studentSchoolInput) {
    result.school_id = req.studentSchoolInput.at(0).id
  }
  return result
}
