import React, { useEffect, useMemo } from 'react'
import { PageTitle } from '../../../../../_layout/core'
import { getAllChapterDatatable, getOneChapter, updateChapter } from './ChapterAction'
import { Link, useNavigate, useOutletContext, useParams } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Suspense } from 'react'
import CustomLoading from '../../../../../_component/customLoading'
import { CHAPTER_EDIT_TITLE } from '../../../../../_domain/constanta/ChapterConstant'
import { ChapterFormInput } from '../../../../../helpers/UIChapterConstanta'

const ChapterEditForm = React.lazy(() => import('./ChapterEditComponent/ChapterEditForm'))

// const checkReduxData = () => {
//   // detect redux first before fetch API
//   const [chapterDetailData, setChapterDetailData] = useState(null)
//   const dispatch = useDispatch()
//   let { chapterId } = useParams()
//   let reduxData = getOneReduxData('chapter', [
//     {
//       key: '_id',
//       value: chapterId,
//     },
//   ])

//   useEffect(() => {
//     if (reduxData.length === 0) {
//       // fetch from API if data is not available in the redux
//       dispatch(getOneChapter(chapterId)).then((response) => {
//         setChapterDetailData(response)
//       })
//     } else {
//       // set State if the redux have the specific data
//       setChapterDetailData(reduxData[0])
//     }
//   }, [chapterId, getOneChapter])

//   return chapterDetailData
//   // detect redux first before fetch API END
// }

const ChapterEdit = () => {
  const { chapterId } = useParams()
  const navigate = useNavigate();

  const filterContext = useOutletContext<any>().queryParams
  const { queryParams } = useMemo(() => {
    return {
      queryParams: filterContext.queryParams,
    };
  }, [filterContext]);

  const dispatch = useDispatch();
  const { actionsLoading, entity } = useSelector(
    (state: any) => ({
      actionsLoading: state.chapter.actionsLoading,
      entity: state.chapter.entity,
    }),
    shallowEqual
  );

  // if !id we should close modal
  useEffect(() => {
    dispatch<any>(getOneChapter(chapterId))
    return () => {
      dispatch<any>(getOneChapter(undefined))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chapterId]);

  const saveAction = (data: ChapterFormInput) => {
    if (chapterId) {
      dispatch<any>(updateChapter(data, chapterId)).then(() => {
        // refresh list after deletion
        dispatch<any>(getAllChapterDatatable(queryParams));
        // Back
        navigate("/master/chapter")
      })
    }
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>{CHAPTER_EDIT_TITLE}</PageTitle>
      <div className='d-flex justify-content-between align-items-center mb-4'>
        <div className='w-full'></div>
        <div>
          <Link to={"/master/chapter"}>
            <button type='button'
              className='btn btn-secondary'>
              Back
            </button>
          </Link>
        </div>
      </div>
      <Suspense fallback={<CustomLoading />}>
        {entity ? <ChapterEditForm dataChapter={entity} onSubmit={saveAction} /> : null}
      </Suspense>
    </>
  )
}

export { ChapterEdit }
