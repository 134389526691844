import {ScheduleFormInput} from '../../../helpers/UIScheduleConstanta'

export type SchedulePostTestCreateRequest = {
  chapter_ids: string[]
  name: string
  duration: number
  total_question: number
  date_start: string
  date_end: string
  time_start: string
  time_end: string
}

export type SchedulePostTestUpdateRequest = {
  chapter_ids: string[]
  name: string
  duration: number
  total_question: number
  date_start: string
  date_end: string
  time_start: string
  time_end: string
}

export function mappingInputFormToSchedulePostTestCreateRequest(
  req: ScheduleFormInput
): SchedulePostTestCreateRequest {
  return {
    chapter_ids: req.chaptersInput?.map((item) => item.id) ?? [],
    name: req.nameInput ?? '',
    duration: req.durationInput ? +req.durationInput : 0,
    total_question: req.totalQuestionInput ? +req.totalQuestionInput : 0,
    date_start: req.dateStartInput ?? '',
    date_end: req.dateEndInput ?? '',
    time_start: req.timeStartInput ?? '',
    time_end: req.timeEndInput ?? '',
  }
}

export function mappingInputFormToSchedulePostTestUpdateRequest(
  req: ScheduleFormInput
): SchedulePostTestUpdateRequest {
  return {
    chapter_ids: req.chaptersInput?.map((item) => item.id) ?? [],
    name: req.nameInput ?? '',
    duration: req.durationInput ? +req.durationInput : 0,
    total_question: req.totalQuestionInput ? +req.totalQuestionInput : 0,
    date_start: req.dateStartInput ?? '',
    date_end: req.dateEndInput ?? '',
    time_start: req.timeStartInput ?? '',
    time_end: req.timeEndInput ?? '',
  }
}
