import React from 'react'
import { Outlet, Route, Routes } from 'react-router-dom'
import { PageTitle } from '../../../../../_layout/core'
import { cancelInstallmentContext, deleteInstallmentContext, deleteSectionContext, editInstallmentContext, queryParamsContext, statusInstallmentContext } from './_outletContext'
import { OrderList } from './OrdertList'
import OrderEdit from './OrderEdit'
import OrderCreate from './OrderCreate'

const OrderContent = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>{'Order'}</PageTitle>
      <Outlet context={{ queryParams: queryParamsContext(), editInstallment: editInstallmentContext(), deleteInstallment: deleteInstallmentContext(), statusInstallment: statusInstallmentContext(), deleteSection: deleteSectionContext(), cancelInstallment: cancelInstallmentContext() }} />
    </>
  )
}

export default function Order() {
  return (
    <Routes>
      <Route element={<OrderContent />}>
        <Route index element={<OrderList />} />
        <Route path='create' element={<OrderCreate />} />
        <Route path='edit/:orderId' element={<OrderEdit />} />
      </Route>
    </Routes>
  )
}
