import {partners} from '../../../../../_redux_slice'
import {PartnerRepositoryImpl} from '../../../../../_domain/repository/PartnerRepositoryImpl'
import PartnerDataSourceImpl from '../../../../../_data/dataSource/PartnerDataSourceImpl'
import {PartnerServiceImpl} from '../../../../../_domain/service/PartnerService'
import {BaseDatatableRequest} from '../../../../../_data/dataSource/request/BaseDatatableRequest'
import {
  PARTNER_CREATE_FAILED,
  PARTNER_CREATE_SUCCESS,
  PARTNER_DELETE_FAILED,
  PARTNER_DELETE_SUCCESS,
  PARTNER_EDIT_FAILED,
  PARTNER_EDIT_SUCCESS,
  PARTNER_GET_FAILED,
} from '../../../../../_domain/constanta'
import {PartnerFormInput} from '../../../../../helpers/UIPartnerConstanta'
import {alertError, alertSuccess, errorHandling} from '../../../../../helpers/FuncHelpers'
import {AlertPromise} from '../../../../../helpers/classHelpers'
import AuthDataSourceImpl from '../../../../../_data/dataSource/AuthDataSourceImpl'
import {UserRepositoryImpl} from '../../../../../_domain/repository/UserRepositoryImpl'
import {UserServiceImpl} from '../../../../../_domain/service/UserService'
import {UserReferralUpdateFormInput} from '../../../../../helpers/UIStudentConstanta'

const {actions} = partners.slice
// const controlAction = control.slice.actions
const callTypes = partners.callTypes
const dataSource = new PartnerDataSourceImpl()
const repository = new PartnerRepositoryImpl(dataSource)
const service = new PartnerServiceImpl(repository)

const dataSourceUser = new AuthDataSourceImpl()
const repoUser = new UserRepositoryImpl(dataSourceUser)
const serviceUser = new UserServiceImpl(repoUser)

const alertPromise = new AlertPromise()
export const getAllPartnerDatatable = (queryParams: BaseDatatableRequest) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.list}))

  return service
    .getDatatablePartner(queryParams)
    .then((response) => {
      const {totalCount, entities} = response
      dispatch(actions.saveList({totalCount, entities}))

      alertPromise.success()

      return response
    })
    .catch((err) => {
      if (err.response) {
        errorHandling(err.response, PARTNER_GET_FAILED)
      } else {
        alertPromise.error()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const getOnePartner = (userId: string | undefined) => (dispatch: any) => {
  if (!userId) {
    return dispatch(actions.saveObject({data: undefined}))
  }

  dispatch(actions.startCall({callType: callTypes.action}))
  return service
    .getDetailPartnerByUserId(userId)
    .then(async (response) => {
      let partner = response
      dispatch(actions.saveObject({data: partner}))
      return partner
    })
    .catch((err) => {
      if (err.response) {
        errorHandling(err.response, PARTNER_GET_FAILED)
      } else {
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const createPartner = (req: PartnerFormInput) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.action}))
  return service
    .createOnePartner(req)
    .then((response) => {
      alertPromise.stop()
      const partner = response
      dispatch(actions.createObject({data: partner}))
      alertSuccess(PARTNER_CREATE_SUCCESS)
    })
    .catch((err) => {
      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, PARTNER_CREATE_FAILED)
      } else {
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const updatePartner = (req: PartnerFormInput, id: string) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.action}))
  return service
    .updateOnePartner(req, id)
    .then((response) => {
      alertPromise.stop()
      const partner = response
      dispatch(actions.updateObject({data: partner}))
      alertSuccess(PARTNER_EDIT_SUCCESS)
    })
    .catch((err) => {
      console.log(err)

      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, PARTNER_EDIT_FAILED)
      } else {
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const deleteOnePartner = (id: string) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.action}))
  return service
    .deleteOnePartnerByID(id)
    .then(() => {
      alertPromise.stop()
      alertSuccess(PARTNER_DELETE_SUCCESS)
      dispatch(actions.deleteObject({id}))
    })
    .catch((err) => {
      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, PARTNER_DELETE_FAILED)
      } else {
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const getOnePartnerUser = (userId?: string) => (dispatch: any) => {
  if (!userId) {
    return dispatch(actions.saveObjectUser({obj: undefined}))
  }

  dispatch(actions.startCall({callType: callTypes.action}))
  return serviceUser
    .getOneUser(userId)
    .then((response) => {
      const user = response
      dispatch(actions.saveObjectUser({obj: user}))
      return user
    })
    .catch((err) => {
      if (err.response) {
        errorHandling(err.response, PARTNER_GET_FAILED)
      } else {
        console.error(err)
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const updateUserReferral =
  (userId: string, req: UserReferralUpdateFormInput) => (dispatch: any) => {
    alertPromise.start()
    dispatch(actions.startCall({callType: callTypes.action}))
    return serviceUser
      .updateUserReferral(userId, req)
      .then((response) => {
        alertPromise.stop()
        const user = response
        alertSuccess(PARTNER_EDIT_SUCCESS)
        dispatch(actions.saveObjectUser({obj: user}))
      })
      .catch((err) => {
        alertPromise.stop()
        if (err.response) {
          errorHandling(err.response, PARTNER_EDIT_FAILED)
        } else {
          console.error(err)
          alertError()
        }
        dispatch(actions.catchError({err, callType: callTypes.action}))
      })
  }

export const createUserReferral = (req: UserReferralUpdateFormInput) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.action}))
  return serviceUser
    .createUserReferral(req)
    .then((response) => {
      alertPromise.stop()
      const user = response
      alertSuccess(PARTNER_EDIT_SUCCESS)
      dispatch(actions.saveObjectUser({obj: user}))
    })
    .catch((err) => {
      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, PARTNER_EDIT_FAILED)
      } else {
        console.error(err)
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}
