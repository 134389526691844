import {subject} from '../../../../../_redux_slice'
import {SubjectRepositoryImpl} from '../../../../../_domain/repository/SubjectRepositoryImpl'
import SubjectDataSourceImpl from '../../../../../_data/dataSource/SubjectDataSourceImpl'
import {SubjectServiceImpl} from '../../../../../_domain/service/SubjectService'
import {BaseDatatableRequest} from '../../../../../_data/dataSource/request/BaseDatatableRequest'
import {
  SUBJECT_CREATE_FAILED,
  SUBJECT_CREATE_SUCCESS,
  SUBJECT_DELETE_FAILED,
  SUBJECT_DELETE_SUCCESS,
  SUBJECT_EDIT_FAILED,
  SUBJECT_EDIT_SUCCESS,
  SUBJECT_GET_FAILED,
} from '../../../../../_domain/constanta'
import {SubjectFormInput} from '../../../../../helpers/UISubjectConstanta'
import {alertError, alertSuccess, errorHandling} from '../../../../../helpers/FuncHelpers'
import {AlertPromise} from '../../../../../helpers/classHelpers'

const {actions} = subject.slice
// const controlAction = control.slice.actions
const callTypes = subject.callTypes
const dataSource = new SubjectDataSourceImpl()
const repository = new SubjectRepositoryImpl(dataSource)
const service = new SubjectServiceImpl(repository)

const alertPromise = new AlertPromise()
export const getAllSubjectDatatable = (queryParams: BaseDatatableRequest) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.list}))

  return service
    .getDatatableSubject(queryParams)
    .then((response) => {
      const {totalCount, entities} = response
      dispatch(actions.saveList({totalCount, entities}))

      alertPromise.success()

      return response
    })
    .catch((err) => {
      if (err.response) {
        errorHandling(err.response, SUBJECT_GET_FAILED)
      } else {
        alertPromise.error()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const getOneSubject = (id: string | undefined) => (dispatch: any) => {
  if (!id) {
    return dispatch(actions.saveObject({data: undefined}))
  }

  dispatch(actions.startCall({callType: callTypes.action}))
  return service
    .getDetailSubjectById(id)
    .then((response) => {
      const subject = response
      dispatch(actions.saveObject({data: subject}))
      return subject
    })
    .catch((err) => {
      if (err.response) {
        errorHandling(err.response, SUBJECT_GET_FAILED)
      } else {
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const createSubject = (req: SubjectFormInput) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.action}))
  return service
    .createOneSubject(req)
    .then((response) => {
      alertPromise.stop()
      const subject = response
      dispatch(actions.createObject({data: subject}))
      alertSuccess(SUBJECT_CREATE_SUCCESS)
    })
    .catch((err) => {
      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, SUBJECT_CREATE_FAILED)
      } else {
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const updateSubject = (req: SubjectFormInput, id: string) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.action}))
  return service
    .updateOneSubject(req, id)
    .then((response) => {
      alertPromise.stop()
      const subject = response
      dispatch(actions.updateObject({data: subject}))
      alertSuccess(SUBJECT_EDIT_SUCCESS)
    })
    .catch((err) => {
      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, SUBJECT_EDIT_FAILED)
      } else {
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const deleteOneSubject = (id: string) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.action}))
  return service
    .deleteOneSubjectByID(id)
    .then(() => {
      alertPromise.stop()
      alertSuccess(SUBJECT_DELETE_SUCCESS)
      dispatch(actions.delete({id}))
    })
    .catch((err) => {
      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, SUBJECT_DELETE_FAILED)
      } else {
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

// export const deleteBatchSubject = (ids) => (dispatch) => {
//   dispatch(controlAction.setLoading(true))
//   return subjectAPI
//     .deleteMany(ids)
//     .then(() => {
//       alertSuccess('Successful delete subject')
//       dispatch(actions.deleteMany({ids}))
//       dispatch(controlAction.setLoading(false))
//     })
//     .catch((error) => {
//       error.clientMessage = "Can't delete subjects"
//       alertError(error.clientMessage)
//       dispatch(actions.catchError({error, callType: callTypes.action}))
//       dispatch(controlAction.setLoading(false))
//     })
// }
