import {METHOD_STUDY, PRODUCT_MODEL, PRODUCT_STATUS, TYPE_PRODUCT} from '../../../_domain/constanta'
import {
  currencyToNumberFormat,
  dateFormatString,
  methodStudySwitch,
  productModelSwitch,
  productStatusSwitch,
  typeProductSwitch,
} from '../../../helpers/FuncHelpers'
import {ProductFormInput} from '../../../helpers/UIProductConstanta'

export type ProductCreateRequest = {
  facility_ids: string[]
  code_product: string
  name: string
  type_product: TYPE_PRODUCT
  method_study: METHOD_STUDY
  class_product: number[]
  schools_ids: string[]
  date_active_start: string
  date_active_end: string
  amount_total: number
  amount_installment: {
    installment: number
    amounts: number[]
    active_status: boolean
  }[]
  commission_percent: number
  group_role_id: string
  amount_discount: number
  product_model: PRODUCT_MODEL
  installment_count: number
  description: string
  status: PRODUCT_STATUS
}

export type ProductUpdateRequest = {
  facility_ids: string[]
  code_product: string
  name: string
  type_product: TYPE_PRODUCT
  method_study: METHOD_STUDY
  class_product: number[]
  schools_ids: string[]
  date_active_start: string
  date_active_end: string
  amount_total: number
  amount_installment: {
    installment: number
    amounts: number[]
    active_status: boolean
  }[]
  commission_percent: number
  group_role_id: string
  amount_discount: number
  product_model: PRODUCT_MODEL
  installment_count: number
  description: string
  status: PRODUCT_STATUS
  delete_image_current?: boolean
}

export function mappingInputFormToProductCreateRequest(
  req: ProductFormInput
): ProductCreateRequest {
  return {
    facility_ids: req.facility_ids,
    code_product: req.codeProductInput,
    name: req.nameInput,
    type_product: typeProductSwitch(req.typeProductInput),
    method_study: methodStudySwitch(req.methodStudyInput),
    class_product: req.classProductInput.map((item) => item.value),
    schools_ids: [],
    date_active_start: dateFormatString(req.dateActiveStartInput),
    date_active_end: dateFormatString(req.dateActiveEndInput),
    amount_total: currencyToNumberFormat(req.amountTotalInput),
    amount_installment: req.amountInstallmentInput.map((item, index) => {
      return {
        installment: index + 1,
        amounts: item.amounts.map((v) => currencyToNumberFormat(v)),
        active_status: item.activeStatusInput === 'true',
      }
    }),
    commission_percent: +req.commissionPercentInput/100 ?? 0,
    group_role_id: req.groupIdInput,
    amount_discount: currencyToNumberFormat(req.amountDiscountInput),
    product_model: productModelSwitch(req.productModelInput),
    installment_count: +req.installmentCountInput,
    description: req.descriptionInput,
    status: productStatusSwitch(req.statusInput),
  }
}

export function mappingInputFormToProductUpdateRequest(
  req: ProductFormInput
): ProductUpdateRequest {
  return {
    facility_ids: req.facility_ids,
    code_product: req.codeProductInput,
    name: req.nameInput,
    type_product: typeProductSwitch(req.typeProductInput),
    method_study: methodStudySwitch(req.methodStudyInput),
    class_product: req.classProductInput.map((item) => item.value),
    schools_ids: [],
    date_active_start: dateFormatString(req.dateActiveStartInput),
    date_active_end: dateFormatString(req.dateActiveEndInput),
    amount_total: currencyToNumberFormat(req.amountTotalInput),
    amount_installment: req.amountInstallmentInput.map((item, index) => {
      return {
        installment: index + 1,
        amounts: item.amounts.map((v) => currencyToNumberFormat(v)),
        active_status: item.activeStatusInput === 'true',
      }
    }),
    commission_percent: +req.commissionPercentInput/100 ?? 0,
    group_role_id: req.groupIdInput,
    amount_discount: currencyToNumberFormat(req.amountDiscountInput),
    product_model: productModelSwitch(req.productModelInput),
    installment_count: +req.installmentCountInput,
    description: req.descriptionInput,
    status: productStatusSwitch(req.statusInput),
    delete_image_current: req.deleteImageFile,
  }
}
