import {authGroup} from '../../../../../_redux_slice'
import {AuthGroupRepositoryImpl} from '../../../../../_domain/repository/AuthGroupRepositoryImpl'
import AuthGroupDataSourceImpl from '../../../../../_data/dataSource/AuthGroupDataSoruceImpl'
import {AuthGroupServiceImpl} from '../../../../../_domain/service/AuthGroupService'
import {BaseDatatableRequest} from '../../../../../_data/dataSource/request/BaseDatatableRequest'
import { AUTH_GROUP_CREATE_FAILED, AUTH_GROUP_CREATE_SUCCESS, AUTH_GROUP_DELETE_FAILED, AUTH_GROUP_DELETE_SUCCESS, AUTH_GROUP_EDIT_FAILED, AUTH_GROUP_EDIT_SUCCESS, AUTH_GROUP_GET_FAILED } from '../../../../../_domain/constanta'
import {AuthGroupFormInput} from '../../../../../helpers/UIAuthGroupConstanta'
import {alertError, alertSuccess, errorHandling} from '../../../../../helpers/FuncHelpers'
import {AlertPromise} from '../../../../../helpers/classHelpers'

const {actions} = authGroup.slice
// const controlAction = control.slice.actions
const callTypes = authGroup.callTypes
const dataSource = new AuthGroupDataSourceImpl()
const repository = new AuthGroupRepositoryImpl(dataSource)
const service = new AuthGroupServiceImpl(repository)

const alertPromise = new AlertPromise()
export const getAllAuthGroupDatatable = (queryParams: BaseDatatableRequest) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.list}))

  return service
    .getDatatableAuthGroup(queryParams)
    .then((response) => {
      const {totalCount, entities} = response
      dispatch(actions.saveList({totalCount, entities}))

      alertPromise.success()

      return response
    })
    .catch((err) => {
      if (err.response) {
        errorHandling(err.response, AUTH_GROUP_GET_FAILED)
      } else {
        alertPromise.error()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const getOneAuthGroup = (id: string | undefined) => (dispatch: any) => {
  if (!id) {
    return dispatch(actions.saveObject({data: undefined}))
  }

  dispatch(actions.startCall({callType: callTypes.action}))
  return service
    .getDetailAuthGroupById(id)
    .then((response) => {
      const subject = response
      dispatch(actions.saveObject({data: subject}))
      return subject
    })
    .catch((err) => {
      if (err.response) {
        errorHandling(err.response, AUTH_GROUP_GET_FAILED)
      } else {
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const createAuthGroup = (req: AuthGroupFormInput) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.action}))
  return service
    .createOneAuthGroup(req)
    .then((response) => {
      alertPromise.stop()
      const subject = response
      dispatch(actions.createObject({data: subject}))
      alertSuccess(AUTH_GROUP_CREATE_SUCCESS)
    })
    .catch((err) => {
      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, AUTH_GROUP_CREATE_FAILED)
      } else {
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const updateAuthGroup = (req: AuthGroupFormInput, id: string) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.action}))
  return service
    .updateOneAuthGroup(req, id)
    .then((response) => {
      alertPromise.stop()
      const subject = response
      dispatch(actions.updateObject({data: subject}))
      alertSuccess(AUTH_GROUP_EDIT_SUCCESS)
    })
    .catch((err) => {
      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, AUTH_GROUP_EDIT_FAILED)
      } else {
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const deleteOneAuthGroup = (id: string) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.action}))
  return service
    .deleteOneAuthGroupByID(id)
    .then(() => {
      alertPromise.stop()
      alertSuccess(AUTH_GROUP_DELETE_SUCCESS)
      dispatch(actions.delete({id}))
    })
    .catch((err) => {
      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, AUTH_GROUP_DELETE_FAILED)
      } else {
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}
