import {FC} from 'react'
import DataTable, {ExpanderComponentProps} from 'react-data-table-component'
import {ExpandableRowsComponent} from 'react-data-table-component/dist/src/DataTable/types'

type Props = {
  columns: any
  data: any
  countPerPage?: number
  handlePageChange?: (page: any, perPage: any) => void
  handleRowPerPageChange?: (currentRowsPerPage: number, currentPage: number) => void
  handleSortChange?: (column: any, sortDirection: any) => void
  handleSelectedRowsChange?: (selectedItem: any) => void
  initSelected?: (row: any) => boolean
  totalRows: number
  singleSelected?: boolean
  expandableRows?: boolean
  expandedComponent?: ExpandableRowsComponent<any>
  selectableRows?: boolean
  paginationServer?: boolean
  onRowClicked?: (row: any) => void
}

const BaseTable: FC<Props> = ({
  columns,
  data,
  totalRows,
  countPerPage = 100,
  expandableRows,
  singleSelected,
  expandedComponent,
  handlePageChange = () => {},
  handleSortChange = () => {},
  handleRowPerPageChange = () => {},
  handleSelectedRowsChange,
  initSelected,
  selectableRows = true,
  paginationServer = true,
  onRowClicked = () => {},
}) => {
  return (
    <DataTable
      keyField={'1'}
      columns={columns}
      // progressPending={typeTOListData.listLoading}
      onRowClicked={onRowClicked}
      data={data}
      pagination
      paginationServer={paginationServer}
      paginationComponentOptions={{noRowsPerPage: false}}
      onChangePage={handlePageChange}
      onChangeRowsPerPage={handleRowPerPageChange}
      paginationPerPage={countPerPage}
      paginationTotalRows={totalRows}
      paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 100]}
      onSort={handleSortChange}
      onSelectedRowsChange={handleSelectedRowsChange}
      selectableRowSelected={initSelected}
      selectableRowsHighlight
      highlightOnHover
      sortServer
      theme='solarized'
      selectableRows={selectableRows}
      selectableRowsSingle={singleSelected}
      expandableRows={expandableRows}
      expandableRowsComponent={expandedComponent}
    />
  )
}

export function handleSelectedRowsChangeV2(
  entities: any[],
  selectedDomainCallback: (val: any) => void,
  selectedItem: any,
  values?: any[],
  singleSelected?: boolean
) {
  const {selectedRows, selectedCount} = selectedItem

  if (singleSelected) {
    const selectedMerge: any[] = [...selectedRows]
    if (selectedMerge?.at(0)?.id !== values?.at(0)?.id && selectedCount !== 0) {
      selectedDomainCallback(selectedMerge)
    }
  } else {
    const valuesOtherPage: any[] =
      values?.filter((item) => {
        if (entities.some((val: any) => val.id === item.id)) {
          return false
        }
        return true
      }) ?? []
    const selectedMerge: any[] = [...selectedRows, ...valuesOtherPage]
    if (selectedMerge.length !== values?.length && selectedCount !== 0) {
      selectedDomainCallback(selectedMerge)
    }
  }
}

export default BaseTable
