import React, {FC, useMemo} from 'react'
import BaseTable from '../BaseTable'
import {BaseDatatableRequest} from '../../../_data/dataSource/request/BaseDatatableRequest'
import {Badge} from 'react-bootstrap'
import useStudentClassTable from './useStudentClassTable'
import {GENDER} from '../../../_domain/constanta'
import StudentClassFilter from './StudentClassFilter'
import { Student } from '../../../_domain/model/Student'

type Props = {
  selectedCallback: (id: any) => void
  selectedStudentCallback: (val: any) => void
  singleSelected?: boolean
  initFilter?: BaseDatatableRequest
  disableFilter?: boolean
  isSelected?: boolean
  values?: Student[]
}

const StudentClassTable: FC<Props> = ({
  selectedCallback,
  selectedStudentCallback,
  singleSelected,
  initFilter,
  disableFilter,
  isSelected,
  values
}) => {
  const studentClassTable = useStudentClassTable({
    initQueryParams: initFilter,
  })
  const {queryParams, entities, totalCount, applyFilter} = useMemo(() => {
    return {
      entities: studentClassTable.entities,
      totalCount: studentClassTable.totalCount,
      applyFilter: studentClassTable.applyFilter,
      queryParams: studentClassTable.queryParams,
    }
  }, [studentClassTable])

  let mutableTableData = [...entities]

  const columns = [
    {
      name: 'Name',
      selector: (row: any) => {
        return (
          <>
            <span className='text-gray-800 fs-6 fw-bold'>{row.name}</span>
            <br />
            <span className='text-gray-500 fw-semibold'>{row.email}</span>
          </>
        )
      },
      sortable: true,
    },
    {
      name: 'School Name',
      selector: (row: any) => row.school_name,
      sortable: true,
      maxWidth: '200px',
    },
    {
      name: 'ID Qonstanta',
      selector: (row: any) => row.id_qonstanta,
      sortable: true,
      maxWidth: '150px',
    },
    {
      name: 'Phone Number',
      selector: (row: any) => row.phone_number,
      sortable: true,
      maxWidth: '150px',
    },
    {
      name: 'Gender',
      selector: (row: any) => {
        if (row.gender === GENDER.MALE) {
          return (
            <Badge bg='primary' text='white' className='text-capitalize'>
              {row.gender}
            </Badge>
          )
        } else {
          return (
            <Badge bg='warning' text='dark' className='text-capitalize'>
              {row.gender}
            </Badge>
          )
        }
      },
      sortable: true,
      maxWidth: '60px',
    },
    {
      name: 'Class',
      selector: (row: any) => row.grade + '-' + row.major,
      sortable: true,
      maxWidth: '60px',
    },
  ]

  return (
    <>
      <div className='d-flex justify-content-between align-items-center mb-4'>
        <StudentClassFilter
          applyFilter={applyFilter}
          onChange={selectedCallback}
          queryParams={queryParams}
          disableFilter={disableFilter}
        />
      </div>
      <div className='customized-datatable-style'>
        <BaseTable
          columns={columns}
          data={mutableTableData}
          totalRows={totalCount}
          handleSelectedRowsChange={(selectedItem: any) => {
            const { selectedRows, selectedCount } = selectedItem

            const valuesOtherPage: any[] = values?.filter((item) => {
              if (entities.some((val: any) => val.user_id === item.user_id)) {
                return false
              }
              return true
            }) ?? [];

            const selectedMerge: any[] = [...selectedRows, ...valuesOtherPage]


            if (selectedMerge.length !== values?.length && selectedCount !== 0) {

              selectedStudentCallback(selectedMerge)
            }
          }}
          initSelected={(row: Student) => {
            let initSelected:Student[] = []
            if (values) {
              initSelected = [...values]
            }
            return initSelected.some((obj) => obj.user_id === row.user_id);
          }}
          selectableRows={isSelected ?? false}
          singleSelected={false}
        />
      </div>
    </>
  )
}

export default StudentClassTable
