import {BaseDatatableRequest} from '../../_data/dataSource/request/BaseDatatableRequest'
import {
  mappingInputFormToAuthGroupCreateRequest,
  mappingInputFormToAuthGroupUpdateRequest,
} from '../../_data/dataSource/request/AuthGroupRequest'
import {BaseDatatableResponse} from '../../_data/dataSource/response/BaseDatatableResponse'
import {AuthGroupFormInput} from '../../helpers/UIAuthGroupConstanta'
import {AuthGroup} from '../model/AuthGroup'
import {AuthGroupRepository} from '../repository/AuthGroupRepository'

export interface AuthGroupService {
  getDatatableAuthGroup(req: BaseDatatableRequest): Promise<BaseDatatableResponse<AuthGroup>>
  getAllAuthGroup(): Promise<AuthGroup[]>
  getDetailAuthGroupById(id: string): Promise<AuthGroup>
  deleteOneAuthGroupByID(id: string): Promise<string>
  createOneAuthGroup(req: AuthGroupFormInput): Promise<AuthGroup>
  updateOneAuthGroup(req: AuthGroupFormInput, id: string): Promise<AuthGroup>
}

export class AuthGroupServiceImpl implements AuthGroupService {
  private repo: AuthGroupRepository
  constructor(repo: AuthGroupRepository) {
    this.repo = repo
  }

  async updateOneAuthGroup(req: any, id: string): Promise<AuthGroup> {
    const body = mappingInputFormToAuthGroupUpdateRequest(req)
    const authGroup = await this.repo.updateOne(body, id)
    return authGroup
  }

  async createOneAuthGroup(req: any): Promise<AuthGroup> {
    const body = mappingInputFormToAuthGroupCreateRequest(req)
    const authGroup = await this.repo.createOne(body)
    return authGroup
  }

  async deleteOneAuthGroupByID(id: string): Promise<string> {
    const message = await this.repo.deleteOneById(id)
    return message
  }

  async getDatatableAuthGroup(req: BaseDatatableRequest): Promise<BaseDatatableResponse<AuthGroup>> {
    const authGroups = await this.repo.getDatatable(req)
    return authGroups
  }

  async getDetailAuthGroupById(id: string): Promise<AuthGroup> {
    const authGroup = await this.repo.getOneById(id)
    return authGroup
  }

  async getAllAuthGroup(): Promise<AuthGroup[]> {
    const authGroups = await this.repo.getAll()
    return authGroups
  }
}
