/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { PageTitle } from '../../../../../_layout/core'
import { Suspense, lazy } from 'react'
import CustomLoading from '../../../../../_component/customLoading'
import { useOutletContext } from 'react-router-dom'
import { BaseDatatableResponse } from '../../../../../_data/dataSource/response/BaseDatatableResponse'
import { Series } from '../../../../../_domain/model/Series'
import { getAllDatatableSeries } from './TryoutSeriesAction'
import { SERIES_LIST_TITLE } from '../../../../../_domain/constanta'
import { useAuth } from '../../../../../_context/AuthContext'
import { checkAuthActionMenu } from '../../../../../helpers/AuthHelpers'
import { SLUG_TRYOUT_SUB_MENU } from '../../../../../helpers/UIAuthGroupConstanta'

const TryoutSeriesListTable = React.lazy(() =>
  import('./TryoutSeriesListComponent/TryoutSeriesListTable')
)
const TryoutSeriesCreate = React.lazy(() =>
  import('./TryoutSeriesCreate')
)
const TryoutSeriesEdit = React.lazy(() =>
  import('./TryoutSeriesEdit')
)
const TryoutSeriesDelete = React.lazy(() =>
  import('./TryoutSeriesDelete')
)

const checkReduxData = () => {
  const outletContext = useOutletContext<any>().queryParams
  const { queryParams } = outletContext

  const [tryoutSeriesListData, setTryoutSeriesListData] = useState<Series[] | null>(null)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch<any>(getAllDatatableSeries(queryParams)).then((response: BaseDatatableResponse<Series>) => {
      setTryoutSeriesListData(response.entities)
    })
  }, [queryParams, getAllDatatableSeries])

  return tryoutSeriesListData
}

const TryoutSeriesList = () => {
  let data = checkReduxData()
  const { currentUser, authorization } = useAuth()
  return checkAuthActionMenu(SLUG_TRYOUT_SUB_MENU.TRYOUT_SERIES_MENU, "view", currentUser, authorization) ? (
    <>
      <TryoutSeriesCreate />
      <TryoutSeriesEdit />
      <TryoutSeriesDelete />
      <PageTitle breadcrumbs={[]}>{SERIES_LIST_TITLE}</PageTitle>
      <Suspense fallback={<CustomLoading />}>
        {data ? <TryoutSeriesListTable /> : null}
      </Suspense>
    </>
  ) : null
}

export { TryoutSeriesList }
