/* eslint-disable jsx-a11y/anchor-is-valid */
import { Outlet, Route, Routes } from 'react-router'
import { PageTitle } from '../../../../../_layout/core'
import { FacilityList } from './FacilityList'
// import {FacilityEdit} from './FacilityEdit'
import { createSectionContext, deleteSectionContext, editSectionContext, queryParamsContext } from './_outletContext'
// const FacilityCreate = React.lazy(() => import('./FacilityCreate'))


const FacilityContent = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>{'Facility'}</PageTitle>
      <Outlet context={{ queryParams: queryParamsContext(), deleteSection: deleteSectionContext(), createSection: createSectionContext(), editSection: editSectionContext() }} />
    </>
  )
}

const Facility = () => {
  return (
    <>
      <Routes>
        <Route element={<FacilityContent />}>
          <Route index element={<FacilityList />} />
          {/* <Route path='create' element={<FacilityCreate />} /> */}
          {/* <Route path='edit/:facilityId' element={<FacilityEdit />} /> */}
        </Route>
      </Routes>
    </>
  )
}

export { Facility }
