import { FC, ReactNode } from "react";
import { Modal } from "react-bootstrap";

type props = {
    title: string,
    show: boolean,
    isLoading?: boolean,
    children: ReactNode
    btnActionName?: string
    size?: "sm" | "lg" | "xl"
    onHide: () => void
    onAction: () => void
}
export const ModalBase: FC<props> = ({ title, show, children, btnActionName = "Submit", size, onHide, onAction }) => {
    // return (
    //     <Modal
    //         show={show}
    //         onHide={onHide}
    //         size="xl"
    //         // dialogClassName="modal-90w"
    //         aria-labelledby="example-custom-modal-styling-title"
    //     >
    //         <Modal.Header closeButton>
    //             <Modal.Title id="example-custom-modal-styling-title">
    //                 Custom Modal Styling
    //             </Modal.Title>
    //         </Modal.Header>
    //         <Modal.Body>
    //             <p>
    //                 Ipsum molestiae natus adipisci modi eligendi? Debitis amet quae unde
    //                 commodi aspernatur enim, consectetur. Cumque deleniti temporibus
    //                 ipsam atque a dolores quisquam quisquam adipisci possimus
    //                 laboriosam. Quibusdam facilis doloribus debitis! Sit quasi quod
    //                 accusamus eos quod. Ab quos consequuntur eaque quo rem! Mollitia
    //                 reiciendis porro quo magni incidunt dolore amet atque facilis ipsum
    //                 deleniti rem!
    //             </p>
    //         </Modal.Body>
    //     </Modal>
    // )
    return (
        <Modal
            size={size}
            show={show}
            onHide={onHide}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {children}
            </Modal.Body>
            <Modal.Footer>
                <div>
                    <button
                        type="button"
                        onClick={onHide}
                        className="btn btn-light btn-elevate"
                    >
                        Cancel
                    </button>
                    <> </>
                    <button
                        type="button"
                        onClick={onAction}
                        className="btn btn-primary btn-elevate"
                    >
                        {btnActionName}
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}