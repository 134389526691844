/* eslint-disable jsx-a11y/anchor-is-valid */
import { Outlet, Route, Routes } from 'react-router'
import { PageTitle } from '../../../../../_layout/core'
import { GroupClassList } from './GroupClassList'
import { createSectionContext, deleteSectionContext, editSectionContext, queryParamsContext } from './_outletContext'
import GroupClassCreate from './GroupClassCreate'
import GroupClassEdit from './GroupClassEdit'


const GroupClassContent = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>{'Group Class'}</PageTitle>
      <Outlet context={{ queryParams: queryParamsContext(), deleteSection: deleteSectionContext(), createSection: createSectionContext(), editSection: editSectionContext() }} />
    </>
  )
}

const GroupClass = () => {
  return (
    <>
      <Routes>
        <Route element={<GroupClassContent />}>
          <Route index element={<GroupClassList />} />
          <Route path='create' element={<GroupClassCreate />} />
          <Route path='edit/:id' element={<GroupClassEdit />} />
        </Route>
      </Routes>
    </>
  )
}

export { GroupClass }
