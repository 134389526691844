import React, { useEffect, useMemo, useRef } from 'react'
import { ModalBase } from '../../../../../_component/Modal'
import { SCHEDULE_LIVE_CLASS_ATTENDANCE_TITLE } from '../../../../../_domain/constanta'
import { useOutletContext } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import LiveClassAttendanceForm from './LiveClassAttendanceComponent/LiveClassAttendanceForm'
import { appendAttendees, getAllLiveClassDatatable, getOneAttendance } from './LiveClassAction'

const LiveClassAttendance = () => {
  const attendanceContext = useOutletContext<any>().attendanceSection
  const { propsForEdit, closingEditModal } = useMemo(() => {
    return {
      propsForEdit: attendanceContext.propsForEdit,
      closingEditModal: attendanceContext.closingEditModal
    };
  }, [attendanceContext]);

  const filterContext = useOutletContext<any>().queryParams
  const { queryParams } = useMemo(() => {
    return {
      queryParams: filterContext.queryParams,
    };
  }, [filterContext]);

  const submitBtnRef = useRef<any>();
  const submitClick = () => {
    if (submitBtnRef && submitBtnRef.current) {
      submitBtnRef.current.click();
    }
  };

  const dispatch = useDispatch();
  const { actionsLoading, entity } = useSelector(
    (state: any) => ({
      actionsLoading: state.liveClass.actionsLoading,
      entity: state.liveClass.entityAttendance,
    }),
    shallowEqual
  );

  // if !id we should close modal
  useEffect(() => {
    if (!propsForEdit.id) {
      closingEditModal()
    } else {
      dispatch<any>(getOneAttendance(propsForEdit.id))
    }
    return () => {
      dispatch<any>(getOneAttendance(undefined))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propsForEdit.id]);

  const saveAction = (data: any) => {
    data = {
      ...data,
      schedule_id: propsForEdit.id
    }
    if (propsForEdit.id) {
      dispatch<any>(appendAttendees(data)).then(() => {
        // refresh list after deletion
        dispatch<any>(getAllLiveClassDatatable(queryParams));
        // closing delete modal
        closingEditModal()
      })
    }
  }

  return (
    <ModalBase size='xl' title={SCHEDULE_LIVE_CLASS_ATTENDANCE_TITLE} show={propsForEdit.show} onHide={closingEditModal} onAction={submitClick} >
      <LiveClassAttendanceForm submitBtnRef={submitBtnRef} onSubmit={saveAction} entity={entity} />
    </ModalBase>
  )
}

export default LiveClassAttendance 
