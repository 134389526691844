import React, { FC, useMemo } from 'react'
import { BaseDatatableRequest } from '../../../_data/dataSource/request/BaseDatatableRequest'
import { Controller, useForm } from 'react-hook-form'
import { StudentFilterTableFormInput } from '../../../helpers/UIStudentConstanta'
import { Form } from 'react-bootstrap'
import { UserFilterRequest } from '../../../_data/dataSource/request/AuthRequest'

type props = {
  queryParams: BaseDatatableRequest
  applyFilter: (val: UserFilterRequest) => void,
}

const UserTableFilter: FC<props> = ({ queryParams, applyFilter}) => {
  const {
    control,
    setValue,
    getValues,
    reset,
  } = useForm<StudentFilterTableFormInput>({
    mode: "onChange",
    defaultValues: useMemo(() => {
      return {
        textInput: queryParams.filter.search_text ?? "",
      }
    }, [])
  })
  function changeFilterState() {
    const textInput = getValues("textInput")
    const filter: UserFilterRequest = {
      search_text: textInput
    }
    applyFilter(filter)
  }
  return (
    <>
      {/* Major Field */}
      <Form.Group className='row w-100'>
        <div className='col-lg-6'>
          <Controller
            name='textInput'
            defaultValue=''
            control={control}
            render={({ field }) => (
              <>
                <Form.Control {...field} type='text' placeholder='Search' className='mb-3' onChange={(e) => {
                  setValue("textInput", e.target.value)
                  changeFilterState()
                }} />
              </>
            )}
          />
        </div>
        <div className='col-lg-6 mt-2'>
          <button
            type='button'
            onClick={() => { reset({ textInput: '' }), changeFilterState() }}
            className='btn btn-sm btn-secondary'
          >
            Reset
          </button>
        </div>
      </Form.Group>
    </>
  )
}
export default UserTableFilter