import { FC } from 'react'
import BaseTable from '../../../../../../_component/Table/BaseTable'

type Props = {
  entities: any[]
  totalCount: number
}

const StudentRejectListTable: FC<Props> = ({ entities, totalCount }) => {

  let mutableTableData = [...entities ?? []]

  const columns = [
    {
      name: 'Email',
      selector: (row: any) => row.email,
      sortable: false,
      maxWidth: '200px',
    },
    {
      name: 'Messages',
      selector: (row: any) => (
        <>
          {row?.messages?.map((item: any, index: number) => {
            return (
              <div key={index}>
                <span className='badge badge-primary badge-circle w-8px h-8px me-1'></span>
                {item ?? ""}
              </div>
            )
          })}
        </>
      ),
      sortable: false,
    },
  ]

  // Use the state and functions returned from useTable to build your UI

  return (
    <div className='customized-datatable-style'>
      <BaseTable
        columns={columns}
        data={mutableTableData}
        countPerPage={100}
        totalRows={totalCount}
      />
    </div>
  )
}

export default StudentRejectListTable
