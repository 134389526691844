import ResultTryoutDataSource from '../../_data/dataSource/ResultTryoutDataSoruce'
import {BaseDatatableRequest} from '../../_data/dataSource/request/BaseDatatableRequest'
import {BaseDatatableResponse} from '../../_data/dataSource/response/BaseDatatableResponse'
import {ResultTryoutScoreResponse} from '../../_data/dataSource/response/ResultResponse'
import {Result, ResultBuilder} from '../model/Result'
import {ResultTryoutRepository} from './ResultTryoutRepository'

export class ResultTryoutRepositoryImpl implements ResultTryoutRepository {
  datasource: ResultTryoutDataSource

  constructor(datasource: ResultTryoutDataSource) {
    this.datasource = datasource
  }

  async getOneResult(scheduleId: string, userId: string): Promise<Result> {
    const response = await this.datasource.getOneResult(scheduleId, userId)
    const resultTryoutInit = new ResultBuilder()
    const result = resultTryoutInit.SingleData(response).AddAggregationDataSingle().Result()
    return result
  }

  async getDatatableBatch(req: BaseDatatableRequest): Promise<BaseDatatableResponse<Result>> {
    try {
      const response = await this.datasource.getDatatableBatch(req)

      const resultTryoutInit = new ResultBuilder()
      const entities: Result[] = resultTryoutInit.ListData(response.entities).ResultList()
      const totalCount = response.totalCount
      return {
        entities,
        totalCount,
        errorMessage: response.errorMessage,
      }
    } catch (error) {
      console.log(error)

      return {
        entities: [],
        totalCount: 0,
        errorMessage: '',
      }
    }
  }
  async calculate(id: string | number): Promise<string> {
    return await this.datasource.calculateOne(id)
  }
  async calculateBatch(id: string | number): Promise<string> {
    return await this.datasource.calculateBatch(id)
  }
  async getDatatableBatchScore(
    req: BaseDatatableRequest
  ): Promise<BaseDatatableResponse<ResultTryoutScoreResponse>> {
    return await this.datasource.getDatatableBatchScore(req)
  }
  getAll(): Promise<Result[]> {
    throw new Error('Method not implemented.')
  }
  getOneById(id: string | number): Promise<Result> {
    throw new Error('Method not implemented.')
  }
  deleteOneById(id: string | number): Promise<string> {
    return this.datasource.deleteOne(id)
  }
  createOne(req: Result): Promise<Result> {
    throw new Error('Method not implemented.')
  }
  updateOne(req: Result): Promise<Result> {
    throw new Error('Method not implemented.')
  }
  async getDatatable(req: BaseDatatableRequest): Promise<BaseDatatableResponse<Result>> {
    const response = await this.datasource.getDatatable(req)
    const resultTryoutInit = new ResultBuilder()
    const entities: Result[] = resultTryoutInit.ListData(response.entities).ResultList()
    const totalCount = response.totalCount
    return {
      entities,
      totalCount,
      errorMessage: response.errorMessage,
    }
  }
}
