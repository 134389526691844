import axios from 'axios'
import QuestionTryoutDataSource from './QuestionTryoutDataSoruce'
import {QuestionTryoutResponse} from './response/QuestionTryoutResponse'
import {ApiResponse} from './response/BaseResponse'
import {BaseDatatableRequest} from './request/BaseDatatableRequest'
import {BaseDatatableResponse} from './response/BaseDatatableResponse'
import {MessageResponse} from './response/MessageResponse'
import {
  QuestionTryoutAddRequest,
  QuestionTryoutCreateRequest,
  QuestionTryoutEditExistRequest,
  QuestionTryoutNumberAvailableRequest,
  QuestionTryoutUpdateRequest,
} from './request/QuestionTryoutRequest'

export default class QuestionTryoutDataSourceImpl implements QuestionTryoutDataSource {
  private TRYOUT_QUESTION_URL: string
  constructor() {
    const service_chapter_url = process.env.REACT_APP_CHAPTER_SERVICE_URL || 'api/'
    this.TRYOUT_QUESTION_URL = `${service_chapter_url}tryouts`
  }

  async editExist(req: QuestionTryoutEditExistRequest): Promise<QuestionTryoutResponse> {
    const response = await axios.put<ApiResponse<QuestionTryoutResponse>>(
      `${this.TRYOUT_QUESTION_URL}/update_question_exist/${req.chapterId}`,
      req
    )

    const data = response.data.data
    return undefined!
  }

  async getOneByChapter(questionId: string | number, chapterId: string): Promise<QuestionTryoutResponse> {
    const response = await axios.get<ApiResponse<QuestionTryoutResponse>>(
      `${this.TRYOUT_QUESTION_URL}/${questionId}/detail/${chapterId}`
    )
    const data = response.data.data
    return data
  }

  async update(req: QuestionTryoutUpdateRequest, chapterId: string): Promise<QuestionTryoutResponse> {
    const response = await axios.put<ApiResponse<QuestionTryoutResponse>>(
      `${this.TRYOUT_QUESTION_URL}/update_question_new/${chapterId}`,
      req
    )

    const data = response.data.data
    return data!
  }

  async deleteOne(questionId: string, chapterId: string): Promise<string> {
    const response = await axios.delete<MessageResponse>(
      `${this.TRYOUT_QUESTION_URL}/${questionId}/delete/${chapterId}`
    )
    return response.data.message
  }

  async getDatatable(req: BaseDatatableRequest): Promise<BaseDatatableResponse<QuestionTryoutResponse>> {    
    const response = await axios.post<ApiResponse<BaseDatatableResponse<QuestionTryoutResponse>>>(
      `${this.TRYOUT_QUESTION_URL}/datatable`,
      req
    )

    const data = response.data.data
    return data
  }

  async getOne(id: string): Promise<QuestionTryoutResponse> {
    const response = await axios.get<ApiResponse<QuestionTryoutResponse>>(
      `${this.TRYOUT_QUESTION_URL}/${id}`
    )
    const data = response.data.data
    return data
  }

  async getAll(): Promise<QuestionTryoutResponse[]> {
    const response = await axios.get<ApiResponse<QuestionTryoutResponse[]>>(
      `${this.TRYOUT_QUESTION_URL}`
    )
    const data = response.data.data
    return data
  }

  async create(req: QuestionTryoutCreateRequest): Promise<QuestionTryoutResponse> {
    const response = await axios.post<ApiResponse<QuestionTryoutResponse>>(
      `${this.TRYOUT_QUESTION_URL}/add_question_new/${req.chapterId}`,
      req
    )
    const data = response.data.data
    return data!
  }

  async add(req: QuestionTryoutAddRequest): Promise<QuestionTryoutResponse> {
    const response = await axios.post<ApiResponse<QuestionTryoutResponse>>(
      `${this.TRYOUT_QUESTION_URL}/add_question_exist/${req.chapterId}`,
      req
    )
    const data = response.data.data
    return data!
  }

  async getAvailableNumber(req: QuestionTryoutNumberAvailableRequest): Promise<number[]> {
    const response = await axios.post<ApiResponse<number[]>>(
      `${this.TRYOUT_QUESTION_URL}/nomor`,
      req
    )
    const data = response.data.data

    return data
  }
}
