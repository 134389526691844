import { useState } from 'react'
import { defaultQueryParams } from '../../../../../_data/dataSource/request/BaseDatatableRequest'
import useBaseDialog from '../../../../../_hooks/useBaseDialog'
import useCreateDialog from '../../../../../_hooks/useCreateDialog'
import useEditSelectionDialog from '../../../../../_hooks/useEditSelectionDialog'
import useFilterTable from '../../../../../_hooks/useFilterTable'

// export const studentEditClassContext = () => {
//   const [classDetail, setClassDetail] = useState([])
//   //   set default value only for prevent error because the form is initially empty
//   const [formValue, setFormValue] = useState({
//     classInput: null,
//   })
//   //   set default value only prevent error because the form is still empty END

//   //   find subject by class input trigger
//   useEffect(() => {
//     if (formValue.classInput) {
//       const index = formValue.classInput ? formValue.classInput : 0
//       const {major, grade} = CLASS_STUDENT[index]
//       studentClassAPI.getAll(major, grade).then((response) => {
//         let data = response.data.data

//         setClassDetail([...data])
//       })
//     }
//   }, [formValue.classInput])
//   //   find subject by class input trigger END

//   const value = {
//     classDetail,
//     setClassDetail,
//     formValue,
//     setFormValue,
//   }

//   return value
// }

const init = defaultQueryParams()
init.pageSize = 20
init.sortField = "id"
init.sortOrder = "desc"

export const queryParamsContext = () => {
  const value = useFilterTable({ initQueryParams: init })

  return value
}

export const selectedIdscontext = () => {
  const [ids, setIds] = useState([])
  const [propsForReset, setPropsForReset] = useState({
    ids: [],
    show: false,
  })

  function showingResetModal() {
    setPropsForReset({
      ids: ids,
      show: true,
    })
  }

  function closingResetModal() {
    setPropsForReset({
      ids: [],
      show: false,
    })
  }

  return {
    ids,
    setIds,
    propsForReset,
    setPropsForReset,
    showingResetModal,
    closingResetModal,
  }
}

export const uploadBatchContext = () => {
  const value = useBaseDialog()
  return value
}

export const editSectionContext = () => {
  const value = useEditSelectionDialog()

  return value
}