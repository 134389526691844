import SchoolDataSource from '../../_data/dataSource/SchoolDataSoruce'
import {BaseDatatableRequest} from '../../_data/dataSource/request/BaseDatatableRequest'
import {
  SchoolCreateRequest,
  SchoolGetAllParams,
  SchoolUpdateRequest,
} from '../../_data/dataSource/request/SchoolRequest'
import {BaseDatatableResponse} from '../../_data/dataSource/response/BaseDatatableResponse'
import {School, SchoolBuilder} from '../model/School'
import {SchoolRepository} from './SchoolRepository'

export class SchoolRepositoryImpl implements SchoolRepository {
  datasource: SchoolDataSource

  constructor(datasource: SchoolDataSource) {
    this.datasource = datasource
  }

  async updateOne(req: SchoolUpdateRequest, id?: string): Promise<School> {
    const response = await this.datasource.update(req, id)
    const initBuilder = new SchoolBuilder()
    const school = initBuilder.SingleData(response).Result()
    return school
  }

  async createOne(req: SchoolCreateRequest): Promise<School> {
    const resposne = await this.datasource.create(req)
    const initBuilder = new SchoolBuilder()
    const school = initBuilder.SingleData(resposne).Result()
    return school
  }

  deleteOneById(id: number): Promise<string> {
    return this.datasource.deleteOne(id)
  }

  async getDatatable(req: BaseDatatableRequest): Promise<BaseDatatableResponse<School>> {
    const data = await this.datasource.getDatatable(req)
    const initBuilder = new SchoolBuilder()
    const entities = initBuilder.ListData(data.entities).ResultList()
    const totalCount = data.totalCount
    return {
      entities,
      totalCount,
      errorMessage: data.errorMessage,
    }
  }

  async getOneById(id: number): Promise<School> {
    const response = await this.datasource.getOne(id)
    const initBuilder = new SchoolBuilder()
    const school = initBuilder.SingleData(response).Result()
    return school
  }

  async getAll(params?: SchoolGetAllParams): Promise<School[]> {
    const response = await this.datasource.getAll()
    const initBuilder = new SchoolBuilder()
    const schools = initBuilder.ListData(response).ResultList()
    return schools
  }
}
