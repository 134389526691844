import {BaseDatatableRequest} from '../../_data/dataSource/request/BaseDatatableRequest'
import {
  mappingInputFormToSchedulePostTestCreateRequest,
  mappingInputFormToSchedulePostTestUpdateRequest,
} from '../../_data/dataSource/request/SchedulePostTestRequest'
import {BaseDatatableResponse} from '../../_data/dataSource/response/BaseDatatableResponse'
import {getIndexClassStudent} from '../../helpers/FuncHelpers'
import { ScheduleFormInput } from '../../helpers/UIScheduleConstanta'
import {Schedule} from '../model/Schedule'
import {ChapterRepository} from '../repository/ChapterRepository'
import {SchedulePostTestRepository} from '../repository/SchedulePostTestRepository'

export interface SchedulePostTestService {
  getDatatableSchedulePostTest(req: BaseDatatableRequest): Promise<BaseDatatableResponse<Schedule>>
  getDetailSchedulePostTestById(id: string): Promise<Schedule>
  deleteOneSchedulePostTestByID(id: string): Promise<string>
  createOneSchedulePostTest(req: ScheduleFormInput): Promise<Schedule>
  updateOneSchedulePostTest(req: ScheduleFormInput, id: string): Promise<Schedule>
}

export class SchedulePostTestServiceImpl implements SchedulePostTestService {
  private repo: SchedulePostTestRepository
  private repoChapter: ChapterRepository
  constructor(repo: SchedulePostTestRepository, repoChapter: ChapterRepository) {
    this.repo = repo
    this.repoChapter = repoChapter
  }

  async deleteOneSchedulePostTestByID(id: string): Promise<string> {
    return await this.repo.deleteOneById(id)
  }

  async updateOneSchedulePostTest(req: ScheduleFormInput, id: string): Promise<Schedule> {
    const body = mappingInputFormToSchedulePostTestUpdateRequest(req)

    const schedule = await this.repo.updateOne(body, id)
    if (req.questionFile) {
      this.repo.updateQuestions(schedule.id, req.questionFile)
    }
    return schedule
  }

  async getDetailSchedulePostTestById(id: string): Promise<Schedule> {
    let schedule = await this.repo.getOneById(id)
    if (schedule.chapter_ids?.at(0)?._id) {
      let chapter = await this.repoChapter.getOneById(schedule.chapter_ids?.at(0)?._id!)
      schedule.chapter_detail = {
        id: chapter.id,
        name: chapter.name,
        grade: chapter.grade,
        major: chapter.major,
        semester: chapter.semester,
        subject_detail: chapter.subject_detail,
        class_index: getIndexClassStudent(chapter.grade, chapter.major),
      }
    }

    return schedule
  }

  async createOneSchedulePostTest(req: ScheduleFormInput): Promise<Schedule> {
    const body = mappingInputFormToSchedulePostTestCreateRequest(req)

    const schedule = await this.repo.createOne(body)
    if (req.questionFile) {
      this.repo.updateQuestions(schedule.id, req.questionFile)
    }
    return schedule
  }

  async getDatatableSchedulePostTest(
    req: BaseDatatableRequest
  ): Promise<BaseDatatableResponse<Schedule>> {
    const schedules = await this.repo.getDatatable(req)
    return schedules
  }
}
