import {StudenClassRequest} from '../../_data/dataSource/request/StudentClassRequest'
import {StudentClassDataSource} from '../../_data/dataSource/StudentClassDataSource'
import {StudentClass, StudentClassBuilder} from '../model/StudentClass'
import {StudentClassRepository} from './StudentClassRepository'

export class StudentClassRepositoryImpl implements StudentClassRepository {
  datasource: StudentClassDataSource

  constructor(datasource: StudentClassDataSource) {
    this.datasource = datasource
  }

  async getOneById(id: number): Promise<StudentClass> {
    const response = await this.datasource.getOne(id)
    const initBuilder = new StudentClassBuilder()
    const series = initBuilder.SingleData(response).Result()
    return series
  }

  async deleteOneById(id: number, req: StudenClassRequest): Promise<string> {
    return this.datasource.deleteOne(id, req)
  }

  async createOne(id: number, req: StudenClassRequest): Promise<string> {
    return this.datasource.create(id, req)
  }

  async updateOne(id: number, req: StudenClassRequest): Promise<string> {
    return this.datasource.update(id, req)
  }
}
