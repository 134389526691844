import {useEffect, useState} from 'react'
import ProvinceDataSourceImpl from '../_data/dataSource/ProvinceDataSourceImpl'
import {Province} from '../_domain/model/Province'
import { ProvinceRepositoryImpl } from '../_domain/repository/ProvinceRepositoryImpl'

const useProvince = () => {
  const [list, setList] = useState<Province[]>([])

  const dataSource = new ProvinceDataSourceImpl()
  const repo = new ProvinceRepositoryImpl(dataSource)

  async function getAllProvince() {
    const response = await repo.getAll()
    setList(response)
  }

  useEffect(() => {
    getAllProvince()
  }, [])

  return {
    listProvince: list,
    getAllProvince,
  }
}

export default useProvince
