import React, {useCallback, useEffect, useState} from 'react'
import {
  BaseDatatableRequest,
  defaultQueryParams,
  prepareFilterBase,
} from '../../../_data/dataSource/request/BaseDatatableRequest'
import {isEqual, isFunction} from 'lodash'
import {Student} from '../../../_domain/model/Student'
import StudentClassDataSourceImpl from '../../../_data/dataSource/StudentClassDataSourceImpl'
import {StudentClassRepositoryImpl} from '../../../_domain/repository/StudentClassRepositoryImpl'

const init = defaultQueryParams()
init.pageSize = 10
type Props = {
  initQueryParams?: BaseDatatableRequest
}
const useStudentClassTable = (props: Props) => {
  const {initQueryParams} = props
  const [queryParams, setQueryParamsBase] = useState(initQueryParams ? initQueryParams : init)
  const [entities, setEntities] = useState<Student[]>([])
  const [totalCount, setTotalCount] = useState<number>(0)

  const dataSource = new StudentClassDataSourceImpl()
  const repo = new StudentClassRepositoryImpl(dataSource)

  const setQueryParams = useCallback((nextQueryParams: any) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams)
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams
      }

      return nextQueryParams
    })
  }, [])

  const getListStudent = useCallback(async () => {
    if (queryParams.filter.id) {
      const response = await repo.getOneById(queryParams.filter.id)
      setEntities(response.students)
      setTotalCount(response.students.length)
    } else {
      setEntities([])
      setTotalCount(0)
    }
  }, [setEntities, setTotalCount, queryParams])

  // queryParams, setQueryParams,
  const applyFilter = (values: any) => {
    const newQueryParams = prepareFilterBase(queryParams, values)

    if (!isEqual(newQueryParams, queryParams)) {
      newQueryParams.pageNumber = 1
      // update list by queryParams
      setQueryParams(newQueryParams)
    }
  }

  const handlePageChange = (page: number, perPage: number) => {
    const tmp = {...queryParams}
    tmp.pageNumber = page
    setQueryParams(tmp)
  }

  const handleRowPerPageChange = (currentRowsPerPage: number, currentPage: number) => {
    const tmp = {...queryParams}
    tmp.pageSize = currentRowsPerPage
    setQueryParams(tmp)
  }

  const handleSortChange = (column: any, sortDirection: any) => {
    const tmp = {...queryParams}
    tmp.sortOrder = sortDirection
    tmp.sortField = column.id
    setQueryParams(tmp)
  }

  useEffect(() => {
    getListStudent()
  }, [queryParams])

  const value = {
    queryParams,
    entities,
    setEntities,
    totalCount,
    setTotalCount,
    setQueryParamsBase,
    setQueryParams,
    applyFilter,
    handlePageChange,
    handleSortChange,
    handleRowPerPageChange,
  }

  return value
}

export default useStudentClassTable
