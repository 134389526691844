import {liveClass} from '../../../../../_redux_slice'
import {ScheduleLiveClassRepositoryImpl} from '../../../../../_domain/repository/ScheduleLiveClassRepositoryImpl'
import ScheduleLiveClassDataSourceImpl from '../../../../../_data/dataSource/ScheduleLiveClassDataSourceImpl'
import {ScheduleLiveClassServiceImpl} from '../../../../../_domain/service/ScheduleLiveClassService'
import {BaseDatatableRequest} from '../../../../../_data/dataSource/request/BaseDatatableRequest'
import {
  SCHEDULE_LIVE_CLASS_ATTENDANCE_FAILED,
  SCHEDULE_LIVE_CLASS_ATTENDANCE_GET_FAILED,
  SCHEDULE_LIVE_CLASS_ATTENDANCE_SUCCESS,
  SCHEDULE_LIVE_CLASS_CREATE_FAILED,
  SCHEDULE_LIVE_CLASS_CREATE_SUCCESS,
  SCHEDULE_LIVE_CLASS_DELETE_FAILED,
  SCHEDULE_LIVE_CLASS_DELETE_SUCCESS,
  SCHEDULE_LIVE_CLASS_EDIT_FAILED,
  SCHEDULE_LIVE_CLASS_EDIT_SUCCESS,
  SCHEDULE_LIVE_CLASS_GET_FAILED,
} from '../../../../../_domain/constanta'
import {AttendanceFormInput, ScheduleFormInput} from '../../../../../helpers/UIScheduleConstanta'
import {alertError, alertSuccess, errorHandling} from '../../../../../helpers/FuncHelpers'
import {AlertPromise} from '../../../../../helpers/classHelpers'
import ChapterDataSourceImpl from '../../../../../_data/dataSource/ChapterDataSourceImpl'
import {ChapterRepositoryImpl} from '../../../../../_domain/repository/ChapterRepositoryImpl'
import SchoolDataSourceImpl from '../../../../../_data/dataSource/SchoolDataSourceImpl'
import {SchoolRepositoryImpl} from '../../../../../_domain/repository/SchoolRepositoryImpl'
import {SubjectRepositoryImpl} from '../../../../../_domain/repository/SubjectRepositoryImpl'
import SubjectDataSourceImpl from '../../../../../_data/dataSource/SubjectDataSourceImpl'

const {actions} = liveClass.slice
// const controlAction = control.slice.actions
const callTypes = liveClass.callTypes
const dataSource = new ScheduleLiveClassDataSourceImpl()
const dataSourceChapter = new ChapterDataSourceImpl()
const dataSourceSchool = new SchoolDataSourceImpl()
const dataSourceSubject = new SubjectDataSourceImpl()
const repository = new ScheduleLiveClassRepositoryImpl(dataSource)
const repoChapter = new ChapterRepositoryImpl(dataSourceChapter)
const repoSchool = new SchoolRepositoryImpl(dataSourceSchool)
const repoSubject = new SubjectRepositoryImpl(dataSourceSubject)
const service = new ScheduleLiveClassServiceImpl(repository, repoChapter, repoSchool, repoSubject)

const alertPromise = new AlertPromise()
export const getAllLiveClassDatatable = (queryParams: BaseDatatableRequest) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.list}))

  return service
    .getDatatableSchedule(queryParams)
    .then((response) => {
      const {totalCount, entities} = response
      dispatch(actions.saveList({totalCount, entities}))

      alertPromise.success()

      return response
    })
    .catch((err) => {
      if (err.response) {
        errorHandling(err.response, SCHEDULE_LIVE_CLASS_GET_FAILED)
      } else {
        alertPromise.error()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const getOneLiveClass = (id: string | undefined) => (dispatch: any) => {
  if (!id) {
    return dispatch(actions.saveObject({data: undefined}))
  }

  dispatch(actions.startCall({callType: callTypes.action}))
  return service
    .getDetailScheduleById(id)
    .then((response) => {
      const liveClass = response
      dispatch(actions.saveObject({data: liveClass}))
      return liveClass
    })
    .catch((err) => {
      if (err.response) {
        errorHandling(err.response, SCHEDULE_LIVE_CLASS_GET_FAILED)
      } else {
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const getOneAttendance = (schedule_id: string | undefined) => (dispatch: any) => {
  if (!schedule_id) {
    return dispatch(actions.saveAttendance({data: undefined}))
  }

  dispatch(actions.startCall({callType: callTypes.action}))
  return service
    .getOneAttendance(schedule_id)
    .then((response) => {
      const attendance = response
      dispatch(actions.saveAttendance({data: attendance}))
      return attendance
    })
    .catch((err) => {
      if (err.response) {
        errorHandling(err.response, SCHEDULE_LIVE_CLASS_ATTENDANCE_GET_FAILED)
      } else {
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const createLiveClass = (req: ScheduleFormInput) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.action}))
  return service
    .createOneSchedule(req)
    .then((response) => {
      alertPromise.stop()
      const liveClass = response
      dispatch(actions.createObject({data: liveClass}))
      alertSuccess(SCHEDULE_LIVE_CLASS_CREATE_SUCCESS)
    })
    .catch((err) => {
      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, SCHEDULE_LIVE_CLASS_CREATE_FAILED)
      } else {
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const appendAttendees = (req: any) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.action}))
  return service
    .appendOneAttendees(req)
    .then(() => {
      alertPromise.stop()
      alertSuccess(SCHEDULE_LIVE_CLASS_ATTENDANCE_SUCCESS)
    })
    .catch((err) => {
      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, SCHEDULE_LIVE_CLASS_ATTENDANCE_FAILED)
      } else {
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const updateLiveClass = (req: ScheduleFormInput, id: string) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.action}))
  return service
    .updateOneSchedule(req, id)
    .then((response) => {
      alertPromise.stop()
      const liveClass = response
      dispatch(actions.updateObject({data: liveClass}))
      alertSuccess(SCHEDULE_LIVE_CLASS_EDIT_SUCCESS)
    })
    .catch((err) => {
      console.log(err)

      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, SCHEDULE_LIVE_CLASS_EDIT_FAILED)
      } else {
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const deleteOneLiveClass = (id: string) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.action}))
  return service
    .deleteOneScheduleByID(id)
    .then(() => {
      alertPromise.stop()
      alertSuccess(SCHEDULE_LIVE_CLASS_DELETE_SUCCESS)
      dispatch(actions.deleteObject({id}))
    })
    .catch((err) => {
      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, SCHEDULE_LIVE_CLASS_DELETE_FAILED)
      } else {
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const uploadBatchLiveClass = (file: any) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.action}))
  return service
    .uploadBatchSchedule(file)
    .then((response) => {
      alertPromise.stop()
      alertSuccess(SCHEDULE_LIVE_CLASS_CREATE_SUCCESS)
      dispatch(actions.stopCall({callType: callTypes.action}))
    })
    .catch((err) => {
      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, SCHEDULE_LIVE_CLASS_CREATE_FAILED)
      } else {
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}
