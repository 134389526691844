import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  totalCountBatch: 0,
  totalCountScore: 0,
  entities: null,
  entitiesBatch: null,
  entitiesScore: null,
  entity: undefined,
  lastError: null,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const slice = createSlice({
  name: "results",
  initialState: initialState,
  reducers: {
    startCall: (state: any, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    stopCall: (state: any, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    catchError: (state: any, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    saveList: (state: any, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    saveListBatch: (state: any, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entitiesBatch = entities;
      state.totalCountBatch = totalCount;
    },
    saveListScore: (state: any, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entitiesScore = entities;
      state.totalCountScore = totalCount;
    },
    // getSubjectById
    saveObject: (state: any, action) => {
      state.actionsLoading = false;
      state.entity = action.payload.data;
      state.error = null;
    },
    // createSubject
    createObject: (state: any, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.data);
    },
    // updateSubject
    updateObject: (state: any, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity: any) => {
        if (entity.id === action.payload.data.id) {
          return action.payload.data;
        }
        return entity;
      });
    },
    // deleteSubject
    deleteObject: (state: any, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el: any) => el._id !== action.payload._id
      );
    },
    // deleteSubjects
    deleteManyObject: (state: any, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el: any) => !action.payload.ids.includes(el.id)
      );
    },
  },
});

export const result = {
  callTypes,
  slice,
};
