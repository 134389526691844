import React, {Suspense, useMemo} from 'react'
import {SCHEDULE_LIVE_CLASS_CREATE_TITLE} from '../../../../../_domain/constanta'
import {Link, useNavigate, useOutletContext} from 'react-router-dom'
import {createLiveClass, getAllLiveClassDatatable} from './LiveClassAction'
import {useDispatch} from 'react-redux'
import {PageTitle} from '../../../../../_layout/core'
import CustomLoading from '../../../../../_component/customLoading'
import {ScheduleFormInput} from '../../../../../helpers/UIScheduleConstanta'

const LiveClassCreateForm = React.lazy(
  () => import('./LiveClassCreateComponent/LiveClassCreateForm')
)

const LiveClassCreate = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const filterContext = useOutletContext<any>().queryParams
  const {queryParams} = useMemo(() => {
    return {
      queryParams: filterContext.queryParams,
    }
  }, [filterContext])

  const saveAction = (data: ScheduleFormInput) => {    
    dispatch<any>(createLiveClass(data)).then(() => {
      dispatch<any>(getAllLiveClassDatatable(queryParams));
      navigate("/edulive/live-class")
    })
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>{SCHEDULE_LIVE_CLASS_CREATE_TITLE}</PageTitle>
      <div className='d-flex justify-content-between align-items-center mb-4'>
        <div className='w-full'></div>
        <div>
          <Link to={'/edulive/live-class'}>
            <button type='button' className='btn btn-secondary'>
              Back
            </button>
          </Link>
        </div>
      </div>
      <Suspense fallback={<CustomLoading />}>
        <LiveClassCreateForm onSubmit={saveAction} />
      </Suspense>
    </>
  )
}

export default LiveClassCreate
