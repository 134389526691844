const FACILITY_DELETE_TITLE = 'Facility Delete'
const FACILITY_DELETE_LOADING = 'Facility is deleting...'
const FACILITY_DELETE_CONFIRM = 'Are you sure to permanently delete this facility ?'
const FACILITY_DELETE_SUCCESS = 'Successful delete facility'
const FACILITY_DELETE_FAILED = "Can't delete facility"
const FACILITY_CREATE_TITLE = 'Facility Create'
const FACILITY_CREATE_FAILED = "Can't create facility"
const FACILITY_CREATE_SUCCESS = 'Successful create facility'
const FACILITY_EDIT_TITLE = 'Facility Edit'
const FACILITY_EDIT_SUCCESS = 'Successful update facility'
const FACILITY_EDIT_FAILED = "Can't update facility"
const FACILITY_GET_FAILED = "Can't get facility"
export {
  FACILITY_DELETE_TITLE,
  FACILITY_DELETE_CONFIRM,
  FACILITY_DELETE_LOADING,
  FACILITY_DELETE_SUCCESS,
  FACILITY_DELETE_FAILED,
  FACILITY_CREATE_TITLE,
  FACILITY_CREATE_SUCCESS,
  FACILITY_CREATE_FAILED,
  FACILITY_EDIT_TITLE,
  FACILITY_EDIT_SUCCESS,
  FACILITY_EDIT_FAILED,
  FACILITY_GET_FAILED,
}
