import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  entity: undefined,
  lastError: null,
}

const callTypes = {
  list: 'list',
  action: 'action',
}

const slice = createSlice({
  name: 'teams',
  initialState: initialState,
  reducers: {
    // Redux for starting
    startCall: (state: any, action) => {
      state.error = null
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true
      } else {
        state.actionsLoading = true
      }
    },
    stopCall: (state: any, action) => {
      state.error = null
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false
      } else {
        state.actionsLoading = false
      }
    },
    catchError: (state: any, action) => {
      state.error = `${action.type}: ${action.payload.error}`
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false
      } else {
        state.actionsLoading = false
      }
    },
    // GetTeacherMany
    saveList: (state: any, action) => {
      const {totalCount, entities} = action.payload
      state.listLoading = false
      state.error = null
      state.entities = entities
      state.totalCount = totalCount
    },
    // GetTeacherById
    saveObject: (state: any, action) => {
      state.actionsLoading = false
      state.entity = action.payload.obj
      state.error = null
    },
    // CreateTeacher
    createObject: (state: any, action) => {
      state.actionsLoading = false
      state.error = null
      state.entities.push(action.payload.data)
    },
    // UpdateTeacher
    updateObject: (state: any, action) => {
      state.error = null
      state.actionsLoading = false
      if (state.entities.length > 0) {
        let match = false
        match = state.entities.map((entity: any, index: any) => {
          if (entity.id === action.payload.data.id) {
            state.entities[index] = action.payload.data
            return true
          }
        })
        if (!match) {
          state.entities.push(action.payload.data)
        }
      } else {
        state.entities.push(action.payload.data)
      }
    },
    // DeleteTeacher
    delete: (state: any, action) => {
      state.error = null
      state.actionsLoading = false
      let entitiesCopy = [...state.entities]

      if (state.entities.length > 0) {
        state.entities.map((entity: any, index: any) => {
          if (entity.id === action.payload.id) {
            entitiesCopy.splice(index, 1)
          }
        })
      }

      state.entities = entitiesCopy
    },
    // deleteTeacherMany
    deleteMany: (state: any, action) => {
      state.error = null
      state.actionsLoading = false
      state.entities = state.entities.filter((el: any) => !action.payload.ids.includes(el.id))
    },
  },
})

export const team = {
  callTypes,
  slice,
}
