import React, { FC } from 'react'
import { Student } from '../../../../../../_domain/model/Student'
import { StudentUpdateFormInput } from '../../../../../../helpers/UIStudentConstanta'
import { useForm } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import SchoolTable from '../../../../../../_component/Table/SchoolTable/SchoolTable'
import { School } from '../../../../../../_domain/model/School'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { updateStudent } from '../StudentAction'

type props = {
  entity: Student
}
const StudentSchool: FC<props> = ({ entity }) => {
  const { id } = useParams()
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<StudentUpdateFormInput>({
    mode: "onChange",
  })

  function onSubmit(data: StudentUpdateFormInput) {
    if (id) {
      dispatch<any>(updateStudent(id, data));
    }
  }

  if (entity) {
    return (
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>List Schools</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>Current School: {entity.school_name}</span>
          </h3>
          <div className='card-toolbar'>

          </div>
        </div>
        <div className='card-body py-3'>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Label>Select School</Form.Label>
            <SchoolTable selectedSchoolCallback={function (val: School[]): void {
              setValue("studentSchoolInput", val)
            }} singleSelected={true} />
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='submit' className='btn btn-primary'>
                Save Change
              </button>
            </div>
          </Form>
        </div>
      </div>
    )
  } else {
    return null
  }
}

export default StudentSchool