import {BaseDatatableRequest} from '../../_data/dataSource/request/BaseDatatableRequest'
import {
  mappingInputFormToScheduleLiveClassCreateRequest,
  mappingInputFormToScheduleLiveClassUpdateRequest,
} from '../../_data/dataSource/request/ScheduleLiveClassRequest'
import {BaseDatatableResponse} from '../../_data/dataSource/response/BaseDatatableResponse'
import {getIndexClassStudent} from '../../helpers/FuncHelpers'
import {AttendanceFormInput, ScheduleFormInput} from '../../helpers/UIScheduleConstanta'
import {Schedule, UserAttendance} from '../model/Schedule'
import {ChapterRepository} from '../repository/ChapterRepository'
import {ScheduleLiveClassRepository} from '../repository/ScheduleLiveClassRepository'
import {SchoolRepository} from '../repository/SchoolRepository'
import {SubjectRepository} from '../repository/SubjectRepository'

export interface ScheduleLiveClassService {
  getDatatableSchedule(req: BaseDatatableRequest): Promise<BaseDatatableResponse<Schedule>>
  getAllSchedule(): Promise<Schedule[]>
  getDetailScheduleById(id: string): Promise<Schedule>
  getOneAttendance(schedule_id: string): Promise<UserAttendance>
  deleteOneScheduleByID(id: string): Promise<string>
  createOneSchedule(req: ScheduleFormInput): Promise<Schedule>
  appendOneAttendees(req: any): Promise<void>
  updateOneSchedule(req: ScheduleFormInput, id?: string): Promise<Schedule>
  uploadBatchSchedule(file: any): Promise<string>
}

export class ScheduleLiveClassServiceImpl implements ScheduleLiveClassService {
  private repo: ScheduleLiveClassRepository
  private repoChapter: ChapterRepository
  private repoSchool: SchoolRepository
  private repoSubject: SubjectRepository
  constructor(
    repo: ScheduleLiveClassRepository,
    repoChapter: ChapterRepository,
    repoSchool: SchoolRepository,
    repoSubject: SubjectRepository
  ) {
    this.repo = repo
    this.repoChapter = repoChapter
    this.repoSchool = repoSchool
    this.repoSubject = repoSubject
  }
  async getOneAttendance(schedule_id: string): Promise<UserAttendance> {
    return await this.repo.getOneAttendees(schedule_id)
  }

  async appendOneAttendees(req: AttendanceFormInput): Promise<void> {
    await this.repo.appendAttendees(req)
  }

  async updateOneSchedule(req: any, id?: string): Promise<Schedule> {
    const body = mappingInputFormToScheduleLiveClassUpdateRequest(req)
    const schedule = await this.repo.updateOne(body, id)
    return schedule
  }

  async createOneSchedule(req: ScheduleFormInput): Promise<Schedule> {
    const body = mappingInputFormToScheduleLiveClassCreateRequest(req)
    const schedule = await this.repo.createOne(body)
    return schedule
  }

  async deleteOneScheduleByID(id: string): Promise<string> {
    const message = await this.repo.deleteOneById(id)
    return message
  }

  async getDatatableSchedule(req: BaseDatatableRequest): Promise<BaseDatatableResponse<Schedule>> {
    const schedules = await this.repo.getDatatable(req)
    return schedules
  }

  async getDetailScheduleById(id: string): Promise<Schedule> {
    let schedule = await this.repo.getOneById(id)
    if (schedule.chapter_ids?.at(0)?._id) {
      let chapter = await this.repoChapter.getOneById(schedule.chapter_ids?.at(0)?._id!)
      schedule.chapter_detail = {
        id: chapter.id,
        name: chapter.name,
        grade: chapter.grade,
        major: chapter.major,
        semester: chapter.semester,
        subject_detail: chapter.subject_detail,
        class_index: getIndexClassStudent(chapter.grade, chapter.major),
      }
    }
    if (!schedule.chapter_ids?.at(0)?._id && schedule.chapter_ids?.at(0)?.subject) {
      const subject_id = schedule.chapter_ids.at(0)?.subject._id
      let subject = await this.repoSubject.getOneById(subject_id)
      schedule.chapter_detail = {
        subject_detail: subject,
      }
    }
    if (schedule.schools) {
      for (let school of schedule.schools) {
        const schoolDetail = await this.repoSchool.getOneById(school.id)
        if (schoolDetail.id) {
          school.city_id = schoolDetail.city_id
          school.city_name = schoolDetail.city_name
          school.province_id = schoolDetail.province_detail.id
        }
      }
    }

    return schedule
  }

  async getAllSchedule(): Promise<Schedule[]> {
    const schedules = await this.repo.getAll()
    return schedules
  }

  async uploadBatchSchedule(file: any): Promise<string> {
    return await this.repo.uploadBatch(file)
  }
}
