import axios from 'axios'
import {StudentResponse} from './response/StudentResponse'
import {ApiResponse} from './response/BaseResponse'
import {BaseDatatableRequest} from './request/BaseDatatableRequest'
import {BaseDatatableResponse} from './response/BaseDatatableResponse'
import StudentDataSource from './StudentDataSoruce'
import {
  StudenUpdateClassRequest,
  StudenUpdateRequest,
  StudentAccessUpdateRequest,
} from './request/StudentRequest'
import { StudentProdiResponse } from './response/StudentProdiResponse'

export default class StudentDataSourceImpl implements StudentDataSource {
  private STUDENT_URL: string
  private STUDENT_PRODI_URL: string

  constructor() {
    const REACT_APP_STUDENT_SERVICE_URL = process.env.REACT_APP_STUDENT_SERVICE_URL || 'api/'
    this.STUDENT_URL = `${REACT_APP_STUDENT_SERVICE_URL}students`
    this.STUDENT_PRODI_URL = `${REACT_APP_STUDENT_SERVICE_URL}student-prodis`
  }
  
  async getProdi(user_id: string | number): Promise<StudentProdiResponse[]> {
    type wrap = {
      "prodis": StudentProdiResponse[]
    }
    const response = await axios.get<ApiResponse<wrap>>(
      `${this.STUDENT_PRODI_URL}/${user_id}`
    )
    const data = response.data.data
    return data.prodis
  }

  async getAllFilter(filter: any): Promise<StudentResponse[]> {
    const response = await axios.post<ApiResponse<StudentResponse[]>>(
      `${this.STUDENT_URL}/filter`,
      filter
    )
    return response.data.data
  }
  getAll(params?: any): Promise<StudentResponse[]> {
    throw new Error('Method not implemented.')
  }
  getOne(id: string | number): Promise<StudentResponse> {
    throw new Error('Method not implemented.')
  }
  create(req: any): Promise<StudentResponse> {
    throw new Error('Method not implemented.')
  }

  async updateAccess(
    userId: string | number,
    req: StudentAccessUpdateRequest
  ): Promise<StudentResponse> {
    const response = await axios.put<ApiResponse<StudentResponse>>(
      `${this.STUDENT_URL}/activation/${userId}`,
      req
    )
    return response.data.data
  }

  async editClass(userId: string, req: StudenUpdateClassRequest): Promise<StudentResponse> {
    const response = await axios.put<ApiResponse<StudentResponse>>(
      `${this.STUDENT_URL}/class/${userId}`,
      req
    )
    return response.data.data
  }
  async getOneByUserId(userId: string): Promise<StudentResponse> {
    const response = await axios.get<ApiResponse<StudentResponse>>(
      `${this.STUDENT_URL}/user/${userId}`
    )
    const data = response.data.data
    return data
  }
  async getDatatable(req: BaseDatatableRequest): Promise<BaseDatatableResponse<StudentResponse>> {
    const response = await axios.post<ApiResponse<BaseDatatableResponse<StudentResponse>>>(
      `${this.STUDENT_URL}/datatable`,
      req
    )
    return response.data.data
  }
  deleteOne(id: string | number, secondId?: string | undefined): Promise<string> {
    throw new Error('Method not implemented.')
  }

  async update(req: StudenUpdateRequest, userId: string | number): Promise<StudentResponse> {
    const response = await axios.put<ApiResponse<StudentResponse>>(
      `${this.STUDENT_URL}/${userId}`,
      req
    )
    return response.data.data
  }
}
