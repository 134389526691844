import React, { FC, useMemo } from 'react'
import BaseTable from '../BaseTable'
import { UserModel } from '../../../_domain/model/Auth'
import useUserTable from './useUserTable'
import { BaseDatatableRequest } from '../../../_data/dataSource/request/BaseDatatableRequest'
import UserTableFilter from './UserTableFilter'
import { UserFilterRequest } from '../../../_data/dataSource/request/AuthRequest'

type Props = {
  selectedUserCallback: (val: any) => void
  singleSelected?: boolean
  initFilter?: BaseDatatableRequest
  values?: UserModel[]
  selectableRows?: boolean
}

const UserTable: FC<Props> = ({ selectedUserCallback, singleSelected, initFilter, values, selectableRows = true }) => {

  const userTable = useUserTable({
    initQueryParams: initFilter
  })
  const { entities, queryParams, totalCount, handlePageChange, handleSortChange, handleRowPerPageChange, applyFilter } = useMemo(() => {
    return {
      entities: userTable.entities,
      queryParams: userTable.queryParams,
      totalCount: userTable.totalCount,
      handlePageChange: userTable.handlePageChange,
      handleSortChange: userTable.handleSortChange,
      handleRowPerPageChange: userTable.handleRowPerPageChange,
      applyFilter: userTable.applyFilter,
    }
  }, [userTable])

  let mutableTableData = [...entities]
  const columns = [
    {
      name: 'Name',
      selector: (row: UserModel) => row.name,
      // sortable: true,
    },
    {
      name: 'Email',
      selector: (row: UserModel) => row.email,
    },
    {
      name: 'Phone Number',
      selector: (row: UserModel) => row.phone_number,
    },
    {
      name: 'Date Registration',
      selector: (row: UserModel) => row.verification_date,
    },
  ]


  return (
    <>
      <div className='d-flex justify-content-between align-items-center mb-4'>
        <UserTableFilter queryParams={queryParams} applyFilter={(val: UserFilterRequest) => {
          applyFilter(val)
        }} />
      </div>
      <div className='customized-datatable-style'>
        <BaseTable
          columns={columns}
          selectableRows={selectableRows}
          data={mutableTableData}
          countPerPage={queryParams.pageSize}
          totalRows={totalCount}
          handlePageChange={handlePageChange}
          handleSortChange={handleSortChange}
          handleSelectedRowsChange={(selectedItem: any) => {
            const { selectedRows, selectedCount } = selectedItem

            const valuesOtherPage: any[] = values?.filter((item) => {
              if (entities.some((val: any) => val.id === item.id)) {
                return false
              }
              return true
            }) ?? [];

            const selectedMerge: any[] = [...selectedRows, ...valuesOtherPage]

            if (singleSelected) {
              if (selectedMerge?.at(0)?.id !== values?.at(0)?.id && selectedCount !== 0) {

                selectedUserCallback(selectedMerge)
              }
            } else {
              if (selectedMerge.length !== values?.length && selectedCount !== 0) {

                selectedUserCallback(selectedMerge)
              }
            }
          }}
          initSelected={(row: UserModel) => {
            let initSelected: any[] = []

            if (values) {
              initSelected = [...values, ...initSelected]
            }
            return initSelected.some((obj) => obj.id === row.id)
          }}
          handleRowPerPageChange={handleRowPerPageChange}
          singleSelected={singleSelected}
        />
      </div>
    </>
  )
}

export default UserTable;