import React, { useCallback, useEffect, useState } from 'react'
import { BaseDatatableRequest, defaultQueryParams, prepareFilterBase } from '../../../_data/dataSource/request/BaseDatatableRequest'
import { Order } from '../../../_domain/model/Order'
import OrderDataSourceImpl from '../../../_data/dataSource/OrderDataSourceImpl'
import { OrderRepositoryImpl } from '../../../_domain/repository/OrderRepositoryImpl'
import { isEqual, isFunction } from 'lodash'

const init = defaultQueryParams()
init.pageSize = 10
init.filter = {}
type Props = {
  initQueryParams?: BaseDatatableRequest
}
const useOrderTable = (props: Props) => {
  const { initQueryParams } = props
  const [queryParams, setQueryParamsBase] = useState(initQueryParams ? initQueryParams : init)
  const [entities, setEntities] = useState<Order[]>([])
  const [totalCount, setTotalCount] = useState<number>(0);

  const dataSource = new OrderDataSourceImpl()
  const repo = new OrderRepositoryImpl(dataSource)

  const setQueryParams = useCallback((nextQueryParams: any) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams)
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams
      }

      return nextQueryParams
    })
  }, [])

  const getListOrder = useCallback(
    async () => {
      const response = await repo.getDatatable(queryParams)
      setEntities(response.entities)
      setTotalCount(response.totalCount)
    },
    [setEntities, setTotalCount, queryParams],
  )

  // queryParams, setQueryParams,
  const applyFilter = (values: any) => {
    const newQueryParams = prepareFilterBase(queryParams, values)

    if (!isEqual(newQueryParams, queryParams)) {
      newQueryParams.pageNumber = 1
      // update list by queryParams
      setQueryParams(newQueryParams)
    }
  }

  const handlePageChange = (page: number, perPage: number) => {
    const tmp = { ...queryParams }
    tmp.pageNumber = page
    setQueryParams(tmp)
  }

  const handleRowPerPageChange = (currentRowsPerPage: number, currentPage: number) => {
    const tmp = { ...queryParams }
    tmp.pageSize = currentRowsPerPage
    setQueryParams(tmp)
  }

  const handleSortChange = (column: any, sortDirection: any) => {
    const tmp = { ...queryParams }
    tmp.sortOrder = sortDirection
    tmp.sortField = column.id
    setQueryParams(tmp)
  }

  useEffect(() => {
    getListOrder()
  }, [queryParams])

  const value = {
    queryParams,
    entities,
    setEntities,
    totalCount,
    setTotalCount,
    setQueryParamsBase,
    setQueryParams,
    applyFilter,
    handlePageChange,
    handleSortChange,
    handleRowPerPageChange
  }

  return value
}

export default useOrderTable
