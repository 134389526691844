import {Id, toast} from 'react-toastify'

export class AlertPromise {
  private id!: Id
  constructor() {}
  start(): void {
    if (this.id === undefined || !toast.isActive(this.id)) {
      const id = toast.loading('Please wait...', {closeOnClick: true})
      this.id = id
    }
  }
  success(msg?: string): void {
    if (toast.isActive(this.id)) {
      toast.update(this.id, {
        render: msg ?? 'All is Good',
        type: 'success',
        position: 'top-right',
        isLoading: false,
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        progress: undefined,
      })
    }
  }
  error(msg?: string): void {
    if (toast.isActive(this.id)) {
      toast.update(this.id, {
        render: msg ?? 'Something went wrong!',
        type: 'error',
      })
    }
  }
  stop(): void {
    if (toast.isActive(this.id)) {
      toast.dismiss(this.id)
    }
  }
}
