const TEACHER_DELETE_TITLE = 'Teacher Delete'
const TEACHER_LIST_TITLE = 'Teacher List'
const TEACHER_DELETE_LOADING = 'Teacher is deleting...'
const TEACHER_DELETE_CONFIRM = 'Are you sure to permanently delete this teacher ?'
const TEACHER_DELETE_SUCCESS = 'Successful delete teacher'
const TEACHER_DELETE_FAILED = "Can't delete teacher"
const TEACHER_CREATE_TITLE = 'Teacher Create'
const TEACHER_CREATE_FAILED = "Can't create teacher"
const TEACHER_CREATE_SUCCESS = 'Successful create teacher'
const TEACHER_EDIT_TITLE = 'Teacher Edit'
const TEACHER_EDIT_SUCCESS = 'Successful update teacher'
const TEACHER_EDIT_FAILED = "Can't update teacher"
const TEACHER_GET_FAILED = "Can't get teacher"
export {
  TEACHER_LIST_TITLE,
  TEACHER_DELETE_TITLE,
  TEACHER_DELETE_CONFIRM,
  TEACHER_DELETE_LOADING,
  TEACHER_DELETE_SUCCESS,
  TEACHER_DELETE_FAILED,
  TEACHER_CREATE_TITLE,
  TEACHER_CREATE_SUCCESS,
  TEACHER_CREATE_FAILED,
  TEACHER_EDIT_TITLE,
  TEACHER_EDIT_SUCCESS,
  TEACHER_EDIT_FAILED,
  TEACHER_GET_FAILED,
}
