import React, {useEffect, useMemo, useRef} from 'react'
import {ModalBase} from '../../../../../_component/Modal'
import {SCHEDULE_LIVE_CLASS_ATTENDANCE_TITLE} from '../../../../../_domain/constanta'
import {useOutletContext} from 'react-router-dom'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {
  appendAttendees,
  getAllLiveClassDatatable,
  getOneAttendance,
  getOneLiveClass,
} from './LiveClassAction'
import LiveClassSelectedForm from './LiveClassAttendanceComponent/LiveClassSelectedForm'

const LiveClassSelected = () => {
  const attendanceContext = useOutletContext<any>().selectedSection
  const {propsForEdit, closingEditModal} = useMemo(() => {
    return {
      propsForEdit: attendanceContext.propsForEdit,
      closingEditModal: attendanceContext.closingEditModal,
    }
  }, [attendanceContext])

  const filterContext = useOutletContext<any>().queryParams
  const {queryParams} = useMemo(() => {
    return {
      queryParams: filterContext.queryParams,
    }
  }, [filterContext])

  const submitBtnRef = useRef<any>()
  const submitClick = () => {
    if (submitBtnRef && submitBtnRef.current) {
      submitBtnRef.current.click()
    }
  }

  const dispatch = useDispatch()
  const {actionsLoading, entity, entityAttendance} = useSelector(
    (state: any) => ({
      actionsLoading: state.liveClass.actionsLoading,
      entity: state.liveClass.entity,
      entityAttendance: state.liveClass.entityAttendance,
    }),
    shallowEqual
  )

  // if !id we should close modal
  useEffect(() => {
    if (!propsForEdit.id) {
      closingEditModal()
    } else {
      dispatch<any>(getOneLiveClass(propsForEdit.id))
      dispatch<any>(getOneAttendance(propsForEdit.id))
    }
    return () => {
      dispatch<any>(getOneLiveClass(undefined))
      dispatch<any>(getOneAttendance(undefined))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propsForEdit.id])

  const saveAction = (data: any) => {
    data = {
      user_ids: data.studentInput?.map((item: any) => item.user_id) ?? [],
      schedule_id: propsForEdit.id,
    }
    if (propsForEdit.id) {
      dispatch<any>(appendAttendees(data)).then(() => {
        // refresh list after deletion
        dispatch<any>(getAllLiveClassDatatable(queryParams))
        // closing delete modal
        closingEditModal()
      })
    }
  }  

  return (
    <ModalBase
      size='xl'
      title={SCHEDULE_LIVE_CLASS_ATTENDANCE_TITLE}
      show={propsForEdit.show}
      onHide={closingEditModal}
      onAction={submitClick}
    >
      {entity ? (
        <LiveClassSelectedForm
          submitBtnRef={submitBtnRef}
          onSubmit={saveAction}
          dataLiveClass={entity}
          dataStudent={entityAttendance?.attendees ?? []}
        />
      ) : null}
    </ModalBase>
  )
}

export default LiveClassSelected
