import {useEffect, useState} from 'react'
import {GroupClass} from '../_domain/model/GroupClass'
import GroupClassDataSourceImpl from '../_data/dataSource/GroupClassDataSourceImpl'
import {GroupClassRepositoryImpl} from '../_domain/repository/GroupClassRepositoryImpl'

type Props = {
  params?: any[]
}

const useGroupClass = (props?: Props) => {
  const [list, setList] = useState<GroupClass[]>([])

  const dataSource = new GroupClassDataSourceImpl()
  const repo = new GroupClassRepositoryImpl(dataSource)

  async function getAllGroupClass(params: any[]) {
    const response = await repo.getAllFilter(params)
    setList(response)
  }

  useEffect(() => {
    getAllGroupClass(props?.params ?? [])
  }, [])

  return {
    listGroupClass: list,
    getAllGroupClass,
  }
}

export default useGroupClass
