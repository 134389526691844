/* eslint-disable jsx-a11y/anchor-is-valid */
import { Outlet, Route, Routes } from 'react-router'
import { PageTitle } from '../../../../../_layout/core'
import { SubjectList } from './SubjectList'
// import {SubjectEdit} from './SubjectEdit'
import { createSectionContext, deleteSectionContext, editSectionContext, queryParamsContext } from './_outletContext'
// const SubjectCreate = React.lazy(() => import('./SubjectCreate'))


const SubjectContent = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>{'Subject'}</PageTitle>
      <Outlet context={{ queryParams: queryParamsContext(), deleteSection: deleteSectionContext(), createSection: createSectionContext(), editSection: editSectionContext() }} />
    </>
  )
}

const Subject = () => {
  return (
    <>
      <Routes>
        <Route element={<SubjectContent />}>
          <Route index element={<SubjectList />} />
          {/* <Route path='create' element={<SubjectCreate />} /> */}
          {/* <Route path='edit/:subjectId' element={<SubjectEdit />} /> */}
        </Route>
      </Routes>
    </>
  )
}

export { Subject }
