/* eslint-disable jsx-a11y/anchor-is-valid */
import { Outlet, Route, Routes } from 'react-router'
import { PageTitle } from '../../../../../_layout/core'
import { PartnerList } from './PartnerList'
import { createSectionContext, deleteSectionContext, queryParamsContext } from './_outletContext'
import PartnerDetail from './PartnerDetail'


const PartnerContent = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>{'Partner'}</PageTitle>
      <Outlet context={{ 
        queryParams: queryParamsContext(), 
        createSection: createSectionContext(),
        deleteSection: deleteSectionContext() 
        }} />
    </>
  )
}

const Partner = () => {
  return (
    <>
      <Routes>
        <Route element={<PartnerContent />}>
          <Route index element={<PartnerList />} />
          {/* <Route path='create' element={<PartnerCreate />} /> */}
          <Route path='detail/:id' element={<PartnerDetail />} />
        </Route>
      </Routes>
    </>
  )
}

export { Partner }
