import {student} from '../../../../../_redux_slice'
import {BaseDatatableRequest} from '../../../../../_data/dataSource/request/BaseDatatableRequest'
import StudentDataSourceImpl from '../../../../../_data/dataSource/StudentDataSourceImpl'
import {StudentRepositoryImpl} from '../../../../../_domain/repository/StudentRepositoryImpl'
import {StudentServiceImpl} from '../../../../../_domain/service/StudentService'
import {
  STUDENT_CREATE_FAILED,
  STUDENT_CREATE_SUCCESS,
  STUDENT_EDIT_FAILED,
  STUDENT_EDIT_SUCCESS,
  STUDENT_GET_FAILED,
  STUDENT_PRODI_GET_FAILED,
  STUDENT_ROLE_GET_FAILED,
} from '../../../../../_domain/constanta'
import {alertError, alertSuccess, errorHandling} from '../../../../../helpers/FuncHelpers'
import {AlertPromise} from '../../../../../helpers/classHelpers'
import {
  StudentAccessUpdateFormInput,
  StudentAccountUpdateFormInput,
  StudentUpdateClassFormInput,
  StudentUpdateFormInput,
  UserReferralUpdateFormInput,
} from '../../../../../helpers/UIStudentConstanta'
import AuthDataSourceImpl from '../../../../../_data/dataSource/AuthDataSourceImpl'
import {UserRepositoryImpl} from '../../../../../_domain/repository/UserRepositoryImpl'
import {UserServiceImpl} from '../../../../../_domain/service/UserService'
import StudentRoleDataSourceImpl from '../../../../../_data/dataSource/StudentRoleDataSourceImpl'
import {StudentRoleRepositoryImpl} from '../../../../../_domain/repository/StudentRoleRepositoryImpl'
import {StudentRoleServiceImpl} from '../../../../../_domain/service/StudentRoleService'

const {actions} = student.slice
// const controlAction = control.slice.actions
const callTypes = student.callTypes
const dataSource = new StudentDataSourceImpl()
const repo = new StudentRepositoryImpl(dataSource)
const service = new StudentServiceImpl(repo)

const dataSourceUser = new AuthDataSourceImpl()
const repoUser = new UserRepositoryImpl(dataSourceUser)
const serviceUser = new UserServiceImpl(repoUser)

const dataSourceRole = new StudentRoleDataSourceImpl()
const repoRole = new StudentRoleRepositoryImpl(dataSourceRole)
const serviceRole = new StudentRoleServiceImpl(repoRole)

const alertPromise = new AlertPromise()
export const getAllStudentDatatable = (queryParams: BaseDatatableRequest) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.list}))
  return service
    .getDatatableStudent(queryParams)
    .then((response) => {
      const {totalCount, entities} = response
      dispatch(actions.saveList({totalCount, entities}))

      alertPromise.success()
      return response
    })
    .catch((err) => {
      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, STUDENT_GET_FAILED)
      } else {
        console.error(err)
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const trialTryoutAction = (file: any) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.action}))
  return service
    .uploadBatchStudent(file)
    .then((response) => {
      const {message, reject} = response
      if (reject) {
        localStorage.removeItem('reject_users')
        localStorage.setItem('reject_users', JSON.stringify(reject))
      }
      alertPromise.stop()
      alertSuccess(STUDENT_CREATE_SUCCESS)
      dispatch(actions.stopCall({callType: callTypes.action}))
    })
    .catch((err) => {
      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, STUDENT_CREATE_FAILED)
      } else {
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const getOneStudent = (userId?: string) => (dispatch: any) => {
  if (!userId) {
    return dispatch(actions.saveObject({obj: undefined}))
  }

  dispatch(actions.startCall({callType: callTypes.action}))
  return service
    .getDetailStudentByUserId(userId)
    .then((response) => {
      const student = response
      dispatch(actions.saveObject({obj: student}))
      return student
    })
    .catch((err) => {
      if (err.response) {
        errorHandling(err.response, STUDENT_GET_FAILED)
      } else {
        console.error(err)
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const getOneStudentRole = (userId?: number) => (dispatch: any) => {
  if (!userId) {
    return dispatch(actions.saveObjectRole({obj: undefined}))
  }

  dispatch(actions.startCall({callType: callTypes.action}))
  return serviceRole
    .getRoleByUserId(userId)
    .then((response) => {
      const role = response
      dispatch(actions.saveObjectRole({obj: role}))
      return role
    })
    .catch((err) => {
      if (err.response) {
        errorHandling(err.response, STUDENT_ROLE_GET_FAILED)
      } else {
        console.error(err)
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const getOneStudentProdi = (userId?: number) => (dispatch: any) => {
  if (!userId) {
    return dispatch(actions.saveObjectProdi({obj: undefined}))
  }

  dispatch(actions.startCall({callType: callTypes.action}))
  return service
    .getProdiByUserId(userId)
    .then((response) => {
      const prodi = response
      dispatch(actions.saveObjectProdi({obj: prodi}))
      return prodi
    })
    .catch((err) => {
      if (err.response) {
        errorHandling(err.response, STUDENT_PRODI_GET_FAILED)
      } else {
        console.error(err)
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const getOneStudentUser = (userId?: string) => (dispatch: any) => {
  if (!userId) {
    return dispatch(actions.saveObjectUser({obj: undefined}))
  }

  dispatch(actions.startCall({callType: callTypes.action}))
  return serviceUser
    .getOneUser(userId)
    .then((response) => {
      const user = response
      dispatch(actions.saveObjectUser({obj: user}))
      return user
    })
    .catch((err) => {
      if (err.response) {
        errorHandling(err.response, STUDENT_GET_FAILED)
      } else {
        console.error(err)
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

// export const createStudent = (studentForCreation) => (dispatch) => {
//   dispatch(controlAction.setLoading(true))
//   return studentAPI
//     .create(studentForCreation)
//     .then((response) => {
//       const {student} = response.data
//       alertSuccess('Success create student')
//       dispatch(actions.createObject({data: student}))
//       dispatch(controlAction.setLoading(false))
//       return student
//     })
//     .catch((error) => {
//       error.clientMessage = "Can't create student"
//       alertError(error.clientMessage)
//       dispatch(actions.catchError({error, callType: callTypes.action}))
//       dispatch(controlAction.setLoading(false))
//     })
// }

export const updateClassStudent =
  (userId: string, req: StudentUpdateClassFormInput) => (dispatch: any) => {
    dispatch(actions.startCall({callType: callTypes.action}))
    return service
      .updateclassStudent(userId, req)
      .then((response) => {
        const student = response
        alertSuccess(STUDENT_EDIT_SUCCESS)
        dispatch(actions.stopCall({callType: callTypes.action}))
      })
      .catch((err) => {
        if (err.response) {
          errorHandling(err.response, STUDENT_EDIT_FAILED)
        } else {
          console.error(err)
          alertError()
        }
        dispatch(actions.catchError({err, callType: callTypes.action}))
      })
  }

export const updateStudent = (userId: string, req: StudentUpdateFormInput) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.action}))
  return service
    .updateOneStudent(userId, req)
    .then((response) => {
      const student = response
      alertPromise.stop()
      alertSuccess(STUDENT_EDIT_SUCCESS)
      dispatch(actions.saveObject({obj: student}))
    })
    .catch((err) => {
      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, STUDENT_EDIT_FAILED)
      } else {
        console.error(err)
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const resetPasswordStudent = (users: any[]) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.action}))
  return serviceUser
    .resetPasswordManyUser(users)
    .then(() => {
      alertPromise.stop()
      alertSuccess(STUDENT_EDIT_SUCCESS)
    })
    .catch((err) => {
      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, STUDENT_EDIT_FAILED)
      } else {
        console.error(err)
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const updateUserReferral =
  (userId: string, req: UserReferralUpdateFormInput) => (dispatch: any) => {
    alertPromise.start()
    dispatch(actions.startCall({callType: callTypes.action}))
    return serviceUser
      .updateUserReferral(userId, req)
      .then((response) => {
        alertPromise.stop()
        const user = response
        alertSuccess(STUDENT_EDIT_SUCCESS)
        dispatch(actions.saveObjectUser({obj: user}))
      })
      .catch((err) => {
        alertPromise.stop()
        if (err.response) {
          errorHandling(err.response, STUDENT_EDIT_FAILED)
        } else {
          console.error(err)
          alertError()
        }
        dispatch(actions.catchError({err, callType: callTypes.action}))
      })
  }

export const createUserReferral = (req: UserReferralUpdateFormInput) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.action}))
  return serviceUser
    .createUserReferral(req)
    .then((response) => {
      alertPromise.stop()
      const user = response
      alertSuccess(STUDENT_EDIT_SUCCESS)
      dispatch(actions.saveObjectUser({obj: user}))
    })
    .catch((err) => {
      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, STUDENT_EDIT_FAILED)
      } else {
        console.error(err)
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const updateAccessStudent =
  (userId: number, req: StudentAccessUpdateFormInput) => (dispatch: any) => {
    alertPromise.start()
    dispatch(actions.startCall({callType: callTypes.action}))
    return serviceRole
      .updateByUserId(userId, req)
      .then((response) => {
        alertPromise.stop()
        const role = response
        alertSuccess(STUDENT_EDIT_SUCCESS)
        dispatch(actions.saveObjectRole({obj: role}))
      })
      .catch((err) => {
        alertPromise.stop()
        if (err.response) {
          errorHandling(err.response, STUDENT_EDIT_FAILED)
        } else {
          console.error(err)
          alertError()
        }
        dispatch(actions.catchError({err, callType: callTypes.action}))
      })
  }

export const updateAccountStudent =
  (userId: string, req: StudentAccountUpdateFormInput) => (dispatch: any) => {
    alertPromise.start()
    dispatch(actions.startCall({callType: callTypes.action}))
    return serviceUser
      .updateUser(userId, req)
      .then((response) => {
        alertPromise.stop()
        const user = response
        alertSuccess(STUDENT_EDIT_SUCCESS)
        dispatch(actions.saveObjectUser({obj: user}))
      })
      .catch((err) => {
        alertPromise.stop()
        if (err.response) {
          errorHandling(err.response, STUDENT_EDIT_FAILED)
        } else {
          console.error(err)
          alertError()
        }
        dispatch(actions.catchError({err, callType: callTypes.action}))
      })
  }

export const updateExtendRoleStudent = (userId: string, req: any) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.action}))
  return serviceUser
    .updateRoleUser(userId, req)
    .then((response) => {
      alertPromise.stop()
      const user = response
      alertSuccess(STUDENT_EDIT_SUCCESS)
      dispatch(actions.saveObjectUser({obj: user}))
    })
    .catch((err) => {
      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, STUDENT_EDIT_FAILED)
      } else {
        console.error(err)
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const updateRoleStudent = (userId: string, req: any) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.action}))
  return serviceUser
    .updateRoleUser(userId, req)
    .then((response) => {
      alertPromise.stop()
      const user = response
      alertSuccess(STUDENT_EDIT_SUCCESS)
      dispatch(actions.saveObjectUser({obj: user}))
    })
    .catch((err) => {
      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, STUDENT_EDIT_FAILED)
      } else {
        console.error(err)
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

// export const deleteOneStudent = (id) => (dispatch) => {
//   dispatch(controlAction.setLoading(true))
//   return studentAPI
//     .deleteStudent(id)
//     .then((response) => {
//       alertSuccess('Successful delete student')
//       dispatch(actions.delete({id}))
//       dispatch(controlAction.setLoading(false))
//       return response.data.data
//     })
//     .catch((error) => {
//       error.clientMessage = "Can't delete student"
//       alertError(error.clientMessage)
//       dispatch(actions.catchError({error, callType: callTypes.action}))
//       dispatch(controlAction.setLoading(false))
//     })
// }

// export const deleteBatchStudent = (ids) => (dispatch) => {
//   dispatch(controlAction.setLoading(true))
//   return studentAPI
//     .deleteMany(ids)
//     .then(() => {
//       alertSuccess('Successful delete students')
//       dispatch(actions.deleteMany({ids}))
//       dispatch(controlAction.setLoading(false))
//     })
//     .catch((error) => {
//       error.clientMessage = "Can't delete student"
//       alertError(error.clientMessage)
//       dispatch(actions.catchError({error, callType: callTypes.action}))
//       dispatch(controlAction.setLoading(false))
//     })
// }

// export const updateClassAction = (student) => (dispatch) => {
//   dispatch(controlAction.setLoading(true))
//   return studentAPI
//     .updateClass(student)
//     .then((response) => {
//       const student = response.data.data
//       alertSuccess('Successful update class student')
//       dispatch(actions.updateObject({data: student}))
//     })
//     .catch((error) => {
//       error.clientMessage = "Can't update class student"
//       alertError(error.clientMessage)
//       dispatch(actions.catchError({error, callType: callTypes.action}))
//     })
// }
