import AuthDataSource from '../../_data/dataSource/AuthDataSoruce'
import AuthDataSourceImpl from '../../_data/dataSource/AuthDataSourceImpl'
import StudentDataSource from '../../_data/dataSource/StudentDataSoruce'
import {BaseDatatableRequest} from '../../_data/dataSource/request/BaseDatatableRequest'
import {
  StudenUpdateClassRequest,
  StudenUpdateRequest,
} from '../../_data/dataSource/request/StudentRequest'
import {BaseDatatableResponse} from '../../_data/dataSource/response/BaseDatatableResponse'
import {UploadBatchResponse} from '../../_data/dataSource/response/StudentResponse'
import {Student, StudentBuilder} from '../model/Student'
import {StudentProdi, StudentProdiBuilder} from '../model/StudentProdi'
import {StudentRepository} from './StudentRepository'

export class StudentRepositoryImpl implements StudentRepository {
  datasource: StudentDataSource
  authDataSource: AuthDataSource

  constructor(datasource: StudentDataSource) {
    this.datasource = datasource
    this.authDataSource = new AuthDataSourceImpl()
  }

  async getProdi(user_id: string | number): Promise<StudentProdi[]> {
    const response = await this.datasource.getProdi(user_id)
    const studentInit = new StudentProdiBuilder()
    const students: StudentProdi[] = studentInit.ListData(response).ResultList()
    return students
  }

  async getAllFilter(filter: any): Promise<Student[]> {
    const response = await this.datasource.getAllFilter(filter)
    const studentInit = new StudentBuilder()
    const students: Student[] = studentInit.ListData(response).ResultList()
    return students
  }

  async updateAccess(req: any, userId: string | number): Promise<Student> {
    const response = await this.datasource.updateAccess(userId, req)
    const initBuilder = new StudentBuilder()
    const student = initBuilder.SingleData(response).Result()
    return student
  }

  async uploadSelected(file: any): Promise<{students: Student[]; reject: any[]}> {
    const {students, reject} = await this.authDataSource.uploadSelected(file)
    const initBuilder = new StudentBuilder()
    const student = initBuilder.ListData(students).ResultList()
    return {students: student, reject}
  }
  async updateClass(userId: string, req: StudenUpdateClassRequest): Promise<Student> {
    const response = await this.datasource.editClass(userId, req)
    const initBuilder = new StudentBuilder()
    const student = initBuilder.SingleData(response).Result()
    return student
  }
  async uploadBatch(file: any): Promise<UploadBatchResponse> {
    return await this.authDataSource.uploadBatch(file)
  }
  getAll(params?: any): Promise<Student[]> {
    throw new Error('Method not implemented.')
  }
  getOneById(id: string | number, secondId?: string | number | undefined): Promise<Student> {
    throw new Error('Method not implemented.')
  }
  async getOneByUserId(userId: string): Promise<Student> {
    const resposne = await this.datasource.getOneByUserId(userId)
    const initBuilder = new StudentBuilder()
    const student = initBuilder.SingleData(resposne).Result()
    return student
  }
  deleteOneById(id: string | number, secondId?: string | number | undefined): Promise<string> {
    throw new Error('Method not implemented.')
  }
  createOne(req: any): Promise<Student> {
    throw new Error('Method not implemented.')
  }

  async updateOne(req: StudenUpdateRequest, userId?: string | number): Promise<Student> {
    const resposne = await this.datasource.update(req, userId)
    const initBuilder = new StudentBuilder()
    const student = initBuilder.SingleData(resposne).Result()
    return student
  }

  async getDatatable(req: BaseDatatableRequest): Promise<BaseDatatableResponse<Student>> {
    const response = await this.datasource.getDatatable(req)
    const studentInit = new StudentBuilder()
    const entities: Student[] = studentInit.ListData(response.entities).ResultList()
    const totalCount = response.totalCount
    return {
      entities,
      totalCount,
      errorMessage: '',
    }
  }
}
