import {SubjectResponse} from '../../_data/dataSource/response/SubjectResponse'

export type Subject = {
  id: string
  name: string
  code: string
  major: string
}

export class SubjectBuilder {
  private SingleSubject!: Subject
  private SubjectList!: Subject[]
  constructor() {}

  SingleData(subjectResponse: SubjectResponse): SubjectBuilder {
    const subject = mapingSubject(subjectResponse)
    this.SingleSubject = subject
    return this
  }
  ListData(subjectResponse: SubjectResponse[]): SubjectBuilder {
    const subjects = mapingSubjects(subjectResponse)
    this.SubjectList = subjects
    return this
  }
  Result(): Subject {
    return this.SingleSubject
  }
  ResultList(): Subject[] {
    return this.SubjectList
  }
}

function mapingSubject(subjectResponse: SubjectResponse): Subject {
  const subject: Subject = {
    id: subjectResponse._id,
    code: subjectResponse.code,
    name: subjectResponse.name,
    major: subjectResponse.major,
  }
  return subject
}

function mapingSubjects(subjectsResponse: SubjectResponse[]): Subject[] {
  const subjects = subjectsResponse.map((item) => {
    return mapingSubject(item)
  })
  return subjects
}
