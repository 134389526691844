/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { Navigate, Outlet, Route, Routes } from 'react-router'
import { TryoutSeries } from './pages/TryoutSeries/TryoutSeries'
import { TryoutType } from './pages/TryoutType/TryoutType'
import { TryoutQuestion } from './pages/TryoutQuestion/TryoutQuestion'
import { TryoutSchedule } from './pages/TryoutSchedule/TryoutSchedule'
import { TryoutResult } from './pages/TryoutResult/TryoutResult'
import { PageTitle } from '../../../_layout/core'
import { useAuth } from '../../../_context/AuthContext'
import { checkAuthLinkMenu } from '../../../helpers/AuthHelpers'
import { SLUG_TRYOUT_SUB_MENU } from '../../../helpers/UIAuthGroupConstanta'

const TryoutWrapperContent: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>{'Tryout Main Menu'}</PageTitle>
      <Outlet />
    </>
  )
}

const TryoutWrapper: FC = () => {
  const { currentUser, authorization } = useAuth()
  return (
    <>
      <Routes>
        <Route element={<TryoutWrapperContent />}>
          <Route index element={<TryoutWrapperContent />} />
          {checkAuthLinkMenu(SLUG_TRYOUT_SUB_MENU.TRYOUT_TYPE_MENU, currentUser, authorization) && (
            <Route path='type/*' element={<TryoutType />} />
          )}
          {checkAuthLinkMenu(SLUG_TRYOUT_SUB_MENU.TRYOUT_SERIES_MENU, currentUser, authorization) && (
            <Route path='series/*' element={<TryoutSeries />} />
          )}
          {checkAuthLinkMenu(SLUG_TRYOUT_SUB_MENU.TRYOUT_QUESTION_MENU, currentUser, authorization) && (
            <Route path='question/*' element={<TryoutQuestion />} />
          )}
          {checkAuthLinkMenu(SLUG_TRYOUT_SUB_MENU.TRYOUT_SCHEDULE_MENU, currentUser, authorization) && (
            <Route path='schedule/*' element={<TryoutSchedule />} />
          )}
          {checkAuthLinkMenu(SLUG_TRYOUT_SUB_MENU.TRYOUT_RESULT_MENU, currentUser, authorization) && (
            <Route path='result/*' element={<TryoutResult />} />
          )}
          <Route path='*' element={<Navigate to='/error/404' />} />
        </Route>
      </Routes>
    </>
  )
}

export { TryoutWrapper }
