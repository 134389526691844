import axios from 'axios'
import {TeamResponse} from './response/TeamResponse'
import {ApiResponse} from './response/BaseResponse'
import {BaseDatatableRequest} from './request/BaseDatatableRequest'
import {BaseDatatableResponse} from './response/BaseDatatableResponse'
import {MessageResponse} from './response/MessageResponse'
import {TeamCreateRequest, TeamUpdateRequest} from './request/TeamRequest'
import TeamDataSource from './TeamDataSoruce'

export default class TeamDataSourceImpl implements TeamDataSource {
  private TEAM_URL: string

  constructor() {
    const service_teacher_url = process.env.REACT_APP_TEACHER_SERVICE_URL || 'api/'
    this.TEAM_URL = `${service_teacher_url}teams`
  }

  async updateAuthGroup(req: any, team_id: string): Promise<void> {
    const response = await axios.put<ApiResponse<TeamResponse>>(
      `${this.TEAM_URL}/${team_id}/auth`,
      req
    )

    response.data.data
  }

  async update(req: TeamUpdateRequest, id: string): Promise<TeamResponse> {
    const response = await axios.put<ApiResponse<TeamResponse>>(`${this.TEAM_URL}/${id}`, req)

    const data = response.data.data
    return data
  }

  async deleteOne(id: string): Promise<string> {
    const response = await axios.delete<MessageResponse>(`${this.TEAM_URL}/${id}`)
    return response.data.message
  }

  async getDatatable(req: BaseDatatableRequest): Promise<BaseDatatableResponse<TeamResponse>> {
    const response = await axios.post<ApiResponse<BaseDatatableResponse<TeamResponse>>>(
      `${this.TEAM_URL}/datatable`,
      req
    )

    const data = response.data.data

    return data
  }

  async getOne(id: string): Promise<TeamResponse> {
    const response = await axios.get<ApiResponse<TeamResponse>>(`${this.TEAM_URL}/${id}`)
    const data = response.data.data
    return data
  }

  async getOneByUserId(user_id: number, token?: string): Promise<TeamResponse> {
    let response: any
    if (token) {
      response = await axios.get<ApiResponse<TeamResponse>>(`${this.TEAM_URL}/${user_id}/user`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    } else {
      response = await axios.get<ApiResponse<TeamResponse>>(`${this.TEAM_URL}/${user_id}/user`)
    }
    const data = response.data.data
    return data
  }

  async getAll(params?: any): Promise<TeamResponse[]> {
    let url = `${this.TEAM_URL}`
    const response = await axios.get<ApiResponse<TeamResponse[]>>(url)
    const data = response.data.data
    return data
  }

  async create(req: TeamCreateRequest): Promise<TeamResponse> {
    const response = await axios.post<ApiResponse<TeamResponse>>(`${this.TEAM_URL}`, req)
    const data = response.data.data

    return data
  }
}
