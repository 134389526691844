import {
  mappingInputFormToUserReferralRequest,
  mappingInputFormToUserRoleRequest,
  mappingInputFormToUserUpdateRequest,
} from '../../_data/dataSource/request/AuthRequest'
import {BaseDatatableRequest} from '../../_data/dataSource/request/BaseDatatableRequest'
import {BaseDatatableResponse} from '../../_data/dataSource/response/BaseDatatableResponse'
import {
  StudentAccountUpdateFormInput,
  UserReferralUpdateFormInput,
} from '../../helpers/UIStudentConstanta'
import {UserModel} from '../model/Auth'
import {UserRepository} from '../repository/UserRepository'

export interface UserService {
  getDatatableUser(req: BaseDatatableRequest): Promise<BaseDatatableResponse<UserModel>>
  updateUser(id: string | number, req: StudentAccountUpdateFormInput): Promise<UserModel>
  updateRoleUser(id: string | number, req: any): Promise<UserModel>
  updateUserReferral(id: string | number, req: UserReferralUpdateFormInput): Promise<UserModel>
  createUserReferral(req: UserReferralUpdateFormInput): Promise<UserModel>
  getOneUser(id: string | number): Promise<UserModel>
  resetPasswordManyUser(users: any[]): Promise<string>
}

export class UserServiceImpl implements UserService {
  private repo: UserRepository
  constructor(repo: UserRepository) {
    this.repo = repo
  }

  async updateRoleUser(id: string | number, req: any): Promise<UserModel> {
    const body = mappingInputFormToUserRoleRequest(req)
    const user = await this.repo.updateRole(id, body)
    return user
  }

  async resetPasswordManyUser(users: any[]): Promise<string> {
    const user_ids = users.map((item) => item.user_id)
    return this.repo.resetPasswordMany(user_ids)
  }
  async updateUserReferral(
    id: string | number,
    req: UserReferralUpdateFormInput
  ): Promise<UserModel> {
    const body = mappingInputFormToUserReferralRequest(req)
    const user = await this.repo.updateUserReferral(id, body)
    return user
  }

  async createUserReferral(req: UserReferralUpdateFormInput): Promise<UserModel> {
    const body = mappingInputFormToUserReferralRequest(req)
    const user = await this.repo.createUserReferral(body)
    return user
  }

  async getOneUser(id: string | number): Promise<UserModel> {
    const user = await this.repo.getOneById(id)
    return user
  }

  async updateUser(id: string | number, req: StudentAccountUpdateFormInput): Promise<UserModel> {
    const body = mappingInputFormToUserUpdateRequest(req)
    const user = await this.repo.updateOne(body, id)
    return user
  }

  async getDatatableUser(req: BaseDatatableRequest): Promise<BaseDatatableResponse<UserModel>> {
    const users = await this.repo.getDatatable(req)
    return users
  }
}
