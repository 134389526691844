import {mappingInputFormToStudentClassRequest} from '../../_data/dataSource/request/StudentClassRequest'
import {StudentClassFormInput} from '../../helpers/UIStudentClassConstanta'
import {StudentClass} from '../model/StudentClass'
import {StudentClassRepository} from '../repository/StudentClassRepository'

export interface StudentClassService {
  getDetailStudentClassById(id: number): Promise<StudentClass>
  deleteOneStudentClassByID(id: number, req: StudentClassFormInput): Promise<string>
  createOneStudentClass(id: number, req: StudentClassFormInput): Promise<string>
  updateOneStudentClass(id: number, req: StudentClassFormInput): Promise<string>
}

export class StudentClassServiceImpl implements StudentClassService {
  private repo: StudentClassRepository
  constructor(repo: StudentClassRepository) {
    this.repo = repo
  }

  async updateOneStudentClass(id: number, req: StudentClassFormInput): Promise<string> {
    const body = mappingInputFormToStudentClassRequest(req)
    const message = await this.repo.updateOne(id, body)
    return message
  }

  async createOneStudentClass(id: number, req: StudentClassFormInput): Promise<string> {
    const body = mappingInputFormToStudentClassRequest(req)
    const message = await this.repo.createOne(id, body)
    return message
  }

  async deleteOneStudentClassByID(id: number, req: StudentClassFormInput): Promise<string> {
    const body = mappingInputFormToStudentClassRequest(req)    
    const message = await this.repo.deleteOneById(id, body)
    return message
  }

  async getDetailStudentClassById(id: number): Promise<StudentClass> {
    const studentClass = await this.repo.getOneById(id)
    return studentClass
  }
}
