import {teacher} from '../../../../../_redux_slice'
import TeacherDataSourceImpl from '../../../../../_data/dataSource/TeacherDataSourceImpl'
import {TeacherRepositoryImpl} from '../../../../../_domain/repository/TeacherRepositoryImpl'
import {TeacherServiceImpl} from '../../../../../_domain/service/TeacherService'
import {BaseDatatableRequest} from '../../../../../_data/dataSource/request/BaseDatatableRequest'
import {
  TEACHER_CREATE_FAILED,
  TEACHER_CREATE_SUCCESS,
  TEACHER_DELETE_FAILED,
  TEACHER_DELETE_SUCCESS,
  TEACHER_EDIT_FAILED,
  TEACHER_EDIT_SUCCESS,
  TEACHER_GET_FAILED,
} from '../../../../../_domain/constanta'
import {TeacherFormInput} from '../../../../../helpers/UITeacherConstanta'
import {alertError, alertSuccess, errorHandling} from '../../../../../helpers/FuncHelpers'
import {AlertPromise} from '../../../../../helpers/classHelpers'

const {actions} = teacher.slice
const callTypes = teacher.callTypes
const dataSource = new TeacherDataSourceImpl()
const repository = new TeacherRepositoryImpl(dataSource)
const service = new TeacherServiceImpl(repository)

const alertPromise = new AlertPromise()
export const getAllTeacherDatatable = (queryParams: BaseDatatableRequest) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.list}))

  return service
    .getDatatableTeacher(queryParams)
    .then((response) => {
      const {totalCount, entities} = response
      dispatch(actions.saveList({totalCount, entities}))

      alertPromise.success()

      return response
    })
    .catch((err) => {
      if (err.response) {
        errorHandling(err.response, TEACHER_GET_FAILED)
      } else {
        alertPromise.error()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const getOneTeacher = (id: number | undefined) => (dispatch: any) => {
  if (!id) {
    return dispatch(actions.saveObject({data: undefined}))
  }

  dispatch(actions.startCall({callType: callTypes.action}))
  return service
    .getDetailTeacherById(id)
    .then((response) => {
      const teacher = response
      dispatch(actions.saveObject({obj: teacher}))
      return teacher
    })
    .catch((err) => {
      if (err.response) {
        errorHandling(err.response, TEACHER_GET_FAILED)
      } else {
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const createTeacher = (req: TeacherFormInput) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.action}))
  return service
    .createOneTeacher(req)
    .then((response) => {
      const teacher = response
      dispatch(actions.createObject({data: teacher}))
      alertPromise.stop()
      alertSuccess(TEACHER_CREATE_SUCCESS)
    })
    .catch((err) => {
      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, TEACHER_CREATE_FAILED)
      } else {
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const updateTeacher = (req: TeacherFormInput, id: string) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.action}))
  return service
    .updateOneTeacher(req, id)
    .then((response) => {
      const teacher = response
      dispatch(actions.updateObject({data: teacher}))
      alertPromise.stop()
      alertSuccess(TEACHER_EDIT_SUCCESS)
    })
    .catch((err) => {
      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, TEACHER_EDIT_FAILED)
      } else {
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const deleteOneTeacher = (id: number) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.action}))
  return service
    .deleteOneTeacherByID(id)
    .then(() => {
      alertPromise.stop()
      alertSuccess(TEACHER_DELETE_SUCCESS)
      dispatch(actions.delete({id}))
    })
    .catch((err) => {
      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, TEACHER_DELETE_FAILED)
      } else {
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

// export const deleteBatchTeacher = (ids) => (dispatch) => {
//   dispatch(actions.startCall({callType: callTypes.action}))
//   return teacherAPI
//     .deleteMany(ids)
//     .then(() => {
//       alertSuccess('Successful delete teacher')
//       dispatch(actions.deleteMany({ids}))
//     })
//     .catch((error) => {
//       error.clientMessage = "Can't delete teachers"
//       alertError(error.clientMessage)
//       dispatch(actions.catchError({error, callType: callTypes.action}))
//     })
// }
