import {TeamFormInput} from '../../../helpers/UITeamConstanta'

export type TeamCreateRequest = {
  name: string
  email: string
  password: string
  role: string
  status: boolean
}

export type TeamUpdateRequest = {
  name: string
  email: string
  role: string
  status: boolean
}

export function mappingInputFormToTeamCreateRequest(req: TeamFormInput): TeamCreateRequest {
  return {
    name: req.nameInput ?? '',
    email: req.emailInput ?? '',
    password: 'qonstanta',
    role: req.roleInput ?? '',
    status: true,
  }
}
export function mappingInputFormToTeamUpdateRequest(req: TeamFormInput): TeamUpdateRequest {
  return {
    name: req.nameInput ?? '',
    email: req.emailInput ?? '',
    role: req.roleInput ?? '',
    status: req.statusInput === 'true' ? true : false,
  }
}
