import { useState } from "react"

const useBaseDialog = () => {
  const [propsForDialog, setPropsForDialog] = useState({
    show: false,
  })

  function showingBaseModal() {
    setPropsForDialog({
      show: true,
    })
  }

  function closingBaseModal() {
    setPropsForDialog({
      show: false,
    })
  }

  return {
    propsForDialog,
    setPropsForDialog,
    showingBaseModal,
    closingBaseModal,
  }
}

export default useBaseDialog