import axios from 'axios'
import {ChapterResponse, createChapterFileResponse} from './response/ChapterResponse'
import {ApiResponse} from './response/BaseResponse'
import {BaseDatatableRequest} from './request/BaseDatatableRequest'
import {BaseDatatableResponse} from './response/BaseDatatableResponse'
import {
  ChapterCreateRequest,
  ChapterFileRequest,
  ChapterFilterRequest,
  ChapterListNumberRequest,
  ChapterUpdateRequest,
} from './request/ChapterRequest'
import ChapterDataSource from './ChapterDataSoruce'
import {MessageResponse} from './response/MessageResponse'
import {IChapterfiles} from '../../types'

export default class ChapterDataSourceImpl implements ChapterDataSource {
  private CHAPTER_URL: string

  constructor() {
    const service_chapter_url = process.env.REACT_APP_CHAPTER_SERVICE_URL || 'api/'
    this.CHAPTER_URL = `${service_chapter_url}chapters`
  }

  async getAvailableNumber(params: ChapterListNumberRequest): Promise<number[]> {
    const response = await axios.post<ApiResponse<number[]>>(`${this.CHAPTER_URL}/nomor`, params)
    return response.data.data
  }

  async update(req: ChapterUpdateRequest, id: string): Promise<ChapterResponse> {
    const response = await axios.put<ApiResponse<ChapterResponse>>(`${this.CHAPTER_URL}/${id}`, req)

    const data = response.data.data
    return data
  }

  async deleteOne(id: string): Promise<string> {
    const response = await axios.delete<MessageResponse>(`${this.CHAPTER_URL}/${id}`)
    return response.data.message
  }

  async getDatatable(req: BaseDatatableRequest): Promise<BaseDatatableResponse<ChapterResponse>> {
    const response = await axios.post<ApiResponse<BaseDatatableResponse<ChapterResponse>>>(
      `${this.CHAPTER_URL}/datatable`,
      req
    )

    return response.data.data
  }

  async getOne(id: string): Promise<ChapterResponse> {
    const response = await axios.get<ApiResponse<ChapterResponse>>(`${this.CHAPTER_URL}/${id}`)
    const data = response.data.data
    return data
  }

  async getAll(params: ChapterFilterRequest): Promise<ChapterResponse[]> {
    if (params) {
      const response = await axios.post<ApiResponse<ChapterResponse[]>>(
        `${this.CHAPTER_URL}/filter`,
        params
      )
      const data = response.data.data
      return data
    }
    return []
  }

  async create(req: ChapterCreateRequest): Promise<ChapterResponse> {
    const response = await axios.post<ApiResponse<ChapterResponse>>(`${this.CHAPTER_URL}`, req)
    const data = response.data.data

    return data
  }

  async getAllChapterFilesDatatable(
    body: BaseDatatableRequest,
    type: 'ebook' | 'video' | null
  ): Promise<BaseDatatableResponse<IChapterfiles>> {
    const response = await axios.post<ApiResponse<BaseDatatableResponse<IChapterfiles>>>(
      `${process.env.REACT_APP_CHAPTER_SERVICE_URL}files/datatable`,
      body,
      {params: {type_file: type}}
    )

    return response.data.data
  }

  async createChapterFiles(
    body: ChapterFileRequest,
    type: 'ebook' | 'video'
  ): Promise<createChapterFileResponse> {
    const response = await axios.post<ApiResponse<createChapterFileResponse>>(
      `${process.env.REACT_APP_CHAPTER_SERVICE_URL}/files`,
      body,
      {params: {type_file: type}}
    )
    const data = response.data.data

    return data
  }
}
