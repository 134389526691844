import {banner} from '../../../../../_redux_slice'
import {BannerRepositoryImpl} from '../../../../../_domain/repository/BannerRepositoryImpl'
import BannerDataSourceImpl from '../../../../../_data/dataSource/BannerDataSourceImpl'
import {BannerServiceImpl} from '../../../../../_domain/service/BannerService'
import {BaseDatatableRequest} from '../../../../../_data/dataSource/request/BaseDatatableRequest'
import {
  BANNER_CREATE_FAILED,
  BANNER_CREATE_SUCCESS,
  BANNER_DELETE_FAILED,
  BANNER_DELETE_SUCCESS,
  BANNER_EDIT_FAILED,
  BANNER_EDIT_SUCCESS,
  BANNER_GET_FAILED,
} from '../../../../../_domain/constanta'
import {BannerFormInput} from '../../../../../helpers/UIBannerConstanta'
import {alertError, alertSuccess, errorHandling} from '../../../../../helpers/FuncHelpers'
import {AlertPromise} from '../../../../../helpers/classHelpers'

const {actions} = banner.slice
// const controlAction = control.slice.actions
const callTypes = banner.callTypes
const dataSource = new BannerDataSourceImpl()
const repository = new BannerRepositoryImpl(dataSource)
const service = new BannerServiceImpl(repository)

const alertPromise = new AlertPromise()
export const getAllBannerDatatable = (queryParams: BaseDatatableRequest) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.list}))

  return service
    .getDatatableBanner(queryParams)
    .then((response) => {
      const {totalCount, entities} = response
      dispatch(actions.saveList({totalCount, entities}))

      alertPromise.success()

      return response
    })
    .catch((err) => {
      if (err.response) {
        errorHandling(err.response, BANNER_GET_FAILED)
      } else {
        alertPromise.error()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const getOneBanner = (id: string | undefined) => (dispatch: any) => {
  if (!id) {
    return dispatch(actions.saveObject({data: undefined}))
  }

  dispatch(actions.startCall({callType: callTypes.action}))
  return service
    .getDetailBannerById(id)
    .then((response) => {
      const banner = response
      dispatch(actions.saveObject({data: banner}))
      return banner
    })
    .catch((err) => {
      if (err.response) {
        errorHandling(err.response, BANNER_GET_FAILED)
      } else {
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const createBanner = (req: BannerFormInput) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.action}))
  return service
    .createOneBanner(req)
    .then((response) => {
      alertPromise.stop()
      const banner = response
      dispatch(actions.createObject({data: banner}))
      alertSuccess(BANNER_CREATE_SUCCESS)
    })
    .catch((err) => {
      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, BANNER_CREATE_FAILED)
      } else {
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const updateBanner = (req: BannerFormInput, id: string) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.action}))
  return service
    .updateOneBanner(req, id)
    .then((response) => {
      alertPromise.stop()
      const banner = response
      dispatch(actions.updateObject({data: banner}))
      alertSuccess(BANNER_EDIT_SUCCESS)
    })
    .catch((err) => {
      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, BANNER_EDIT_FAILED)
      } else {
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const deleteOneBanner = (id: string) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.action}))
  return service
    .deleteOneBannerByID(id)
    .then(() => {
      alertPromise.stop()
      alertSuccess(BANNER_DELETE_SUCCESS)
      dispatch(actions.delete({id}))
    })
    .catch((err) => {
      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, BANNER_DELETE_FAILED)
      } else {
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

// export const deleteBatchBanner = (ids) => (dispatch) => {
//   dispatch(controlAction.setLoading(true))
//   return bannerAPI
//     .deleteMany(ids)
//     .then(() => {
//       alertSuccess('Successful delete banner')
//       dispatch(actions.deleteMany({ids}))
//       dispatch(controlAction.setLoading(false))
//     })
//     .catch((error) => {
//       error.clientMessage = "Can't delete banners"
//       alertError(error.clientMessage)
//       dispatch(actions.catchError({error, callType: callTypes.action}))
//       dispatch(controlAction.setLoading(false))
//     })
// }
