import axios from 'axios'
import SeriesDataSource from './SeriesDataSoruce'
import {SeriesResponse} from './response/SeriesResponse'
import {ApiResponse} from './response/BaseResponse'
import {BaseDatatableRequest} from './request/BaseDatatableRequest'
import {BaseDatatableResponse} from './response/BaseDatatableResponse'
import {MessageResponse} from './response/MessageResponse'
import {SeriesCreateRequest, SeriesUpdateRequest} from './request/SeriesRequest'

export default class SeriesDataSourceImpl implements SeriesDataSource {
  private EXAMS_URL: string
  constructor() {
    const service_chapter_url = process.env.REACT_APP_CHAPTER_SERVICE_URL || 'api/'
    this.EXAMS_URL = `${service_chapter_url}exams`
  }

  async update(req: SeriesUpdateRequest, id: string): Promise<SeriesResponse> {
    const response = await axios.put<ApiResponse<SeriesResponse>>(`${this.EXAMS_URL}/${id}`, req)

    const data = response.data.data
    return data
  }

  async deleteOne(id: string): Promise<string> {
    const response = await axios.delete<MessageResponse>(`${this.EXAMS_URL}/${id}`)
    return response.data.message
  }

  async getDatatable(req: BaseDatatableRequest): Promise<BaseDatatableResponse<SeriesResponse>> {
    const response = await axios.post<ApiResponse<BaseDatatableResponse<SeriesResponse>>>(
      `${this.EXAMS_URL}/datatable`,
      req
    )

    const data = response.data.data

    return data
  }

  async getOne(id: string): Promise<SeriesResponse> {
    const response = await axios.get<ApiResponse<SeriesResponse>>(`${this.EXAMS_URL}/${id}`)
    const data = response.data.data
    return data
  }

  async getAll(): Promise<SeriesResponse[]> {
    const response = await axios.get<ApiResponse<SeriesResponse[]>>(`${this.EXAMS_URL}`)
    const data = response.data.data
    return data
  }

  async create(req: SeriesCreateRequest): Promise<SeriesResponse> {
    const response = await axios.post<ApiResponse<SeriesResponse>>(`${this.EXAMS_URL}/`, req)
    const data = response.data.data

    return data
  }
}
