import {AuthModel, UserModel} from '../_domain/model/Auth'

const AUTH_LOCAL_STORAGE_KEY = 'qonstanta-admin-auth'
const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

const checkAuthLinkMenu = (
  menu_name: string,
  currentUser?: UserModel,
  authorization?: any
): boolean => {
  return currentUser?.role === 'admin' || authorization[menu_name]
}

const checkAuthActionMenu = (
  menu_name: string,
  action: string,
  currentUser?: UserModel,
  authorization?: any
): boolean => {
  return currentUser?.role === 'admin' || authorization[menu_name].includes(action)
}

export {getAuth, setAuth, removeAuth, checkAuthLinkMenu, checkAuthActionMenu}
