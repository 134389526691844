import React, { FC, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { UserModel } from '../../../../../../_domain/model/Auth'
import { msgValidationError } from '../../../../../../helpers/FuncHelpers'
import { UserReferralUpdateFormInput } from '../../../../../../helpers/UIStudentConstanta'
import { createUserReferral, updateUserReferral } from '../PartnerAction'
import { defaultQueryParams } from '../../../../../../_data/dataSource/request/BaseDatatableRequest'
import AuthDataSourceImpl from '../../../../../../_data/dataSource/AuthDataSourceImpl'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl } from '../../../../../../_layout/helpers'
import UserTable from '../../../../../../_component/Table/UserTable/UserTable'

type props = {
  entityUser: UserModel
}
const init = defaultQueryParams()
init.pageSize = 10
init.filter = {
  role: "student",
  referral_id: "-1"
}
const domain = "tryout.qonstanta.id/?ref="
const dataSource = new AuthDataSourceImpl()

const PartnerReferral: FC<props> = ({ entityUser }) => {
  const [showEmailForm, setShowEmailForm] = useState(false)
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<UserReferralUpdateFormInput>({
    mode: "onChange",
  })

  function onSubmit(data: UserReferralUpdateFormInput) {
    data.userIdInput = entityUser.id.toString()
    if (entityUser.referral) {
      dispatch<any>(updateUserReferral(data.userIdInput, data));
    } else {
      dispatch<any>(createUserReferral(data));
    }
  }

  if (entityUser) {
    init.filter.referral_id = entityUser.referral?.id ?? "-1"
    return (
      <>
        <div className='card mb-5 mb-xl-10'>
          <div className='card-header border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold fs-3 mb-1'>Referral User</span>
            </h3>
            <div className='card-toolbar'>

            </div>
          </div>
          <div className='card-body py-3'>
            <div className='d-flex flex-wrap align-items-center'>
              <div className={' ' + (showEmailForm && 'd-none')}>
                <div className='fs-6 fw-bolder mb-1'>Referral Code</div>
                <div className='fw-bold text-gray-600'>Current Referral Code: {entityUser.referral?.referral_code ?? "-"}</div>
              </div>

              <div className={'flex-row-fluid ' + (!showEmailForm && 'd-none')}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  {/* Referral Field */}
                  <Controller
                    name='codeReferralInput'
                    defaultValue={entityUser.referral?.referral_code}
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <div className='row mb-6'>
                        <div className='col-lg-12 mb-4 mb-lg-0'>
                          <div className='fv-row mb-0'>
                            <label htmlFor='referral' className='form-label fs-6 fw-bolder mb-3'>
                              Enter New Referral Code
                            </label>
                            <input
                              {...field}
                              type='text'
                              className='form-control form-control-lg form-control-solid'
                              id='referral'
                              placeholder='Referral Code'
                            />
                            {errors.codeReferralInput && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                  {msgValidationError(errors.codeReferralInput.type, 'Referral Code')}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  />
                  <div className='d-flex'>
                    <button type='submit' className='btn btn-primary  me-2 px-6'>
                      Update Referral Code
                    </button>
                    <button
                      type='button'
                      onClick={() => {
                        setShowEmailForm(false)
                      }}
                      className='btn btn-color-gray-400 btn-active-light-primary px-6'
                    >
                      Cancel
                    </button>
                  </div>
                </Form>
              </div>

              <div className={'ms-auto ' + (showEmailForm && 'd-none')}>
                <button
                  onClick={() => {
                    setShowEmailForm(true)
                  }}
                  className='btn btn-light btn-active-light-primary'
                >
                  Change Referral
                </button>
              </div>
            </div>
            <div className='d-flex flex-wrap align-items-center mt-3'>
              <div>
                <div className='fs-6 fw-bolder mb-1'>Referral Link</div>
                <div className='fw-bold fw-primary'>
                  <a
                    href='#'
                    className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                  >
                    {`${domain}${entityUser.referral?.referral_code.toLocaleLowerCase() ?? ""}`}
                  </a>
                </div>
              </div>

              <div className="ms-auto">
                {/* <button
                onClick={() => {
                  setShowEmailForm(true)
                }}
                className='btn btn-light btn-active-light-primary'
              >
                Change Email
              </button> */}
              </div>
            </div>
          </div>
        </div>
        <div className='card mb-5 mb-xl-10'>
          <div className='card-header border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold fs-3 mb-1'>List Use Referral</span>
            </h3>
            <div className='card-toolbar'>
              <button className='btn btn-sm btn-success me-4' type='button' onClick={() => {
                dataSource.exportExcel(entityUser.referral?.id ? entityUser.referral.id.toString() : "-1");
              }}>
                <span className='svg-icon svg-icon-sm svg-icon-primary'>
                  <SVG src={toAbsoluteUrl('/media/svg/icons/Files/Download.svg')} />
                </span>
                Export Excel
              </button>
            </div>
          </div>
          <div className='card-body py-3'>
            <UserTable selectableRows={false} initFilter={init} selectedUserCallback={function (val: any[]): void {
            }} singleSelected={true} />
          </div>
        </div>
      </>
    )
  } else {
    return null
  }
}

export default PartnerReferral