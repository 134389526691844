import { useState } from "react"

const useCreateDialog = () => {
  const [propsForCreate, setPropsForCreate] = useState({
    show: false,
  })

  function showingCreateModal() {
    setPropsForCreate({
      show: true,
    })
  }

  function closingCreateModal() {
    setPropsForCreate({
      show: false,
    })
  }

  return {
    propsForCreate,
    setPropsForCreate,
    showingCreateModal,
    closingCreateModal,
  }
}

export default useCreateDialog