import ScheduleLiveClassDataSource from '../../_data/dataSource/ScheduleLiveClassDataSoruce'
import {BaseDatatableRequest} from '../../_data/dataSource/request/BaseDatatableRequest'
import {
  ScheduleLiveClassCreateRequest,
  ScheduleLiveClassUpdateRequest,
} from '../../_data/dataSource/request/ScheduleLiveClassRequest'
import {BaseDatatableResponse} from '../../_data/dataSource/response/BaseDatatableResponse'
import {
  Schedule,
  ScheduleBuilder,
  UserAttendance,
  mappingAttendance,
  mappingAttendees,
} from '../model/Schedule'
import {ScheduleLiveClassRepository} from './ScheduleLiveClassRepository'

export class ScheduleLiveClassRepositoryImpl implements ScheduleLiveClassRepository {
  datasource: ScheduleLiveClassDataSource

  constructor(datasource: ScheduleLiveClassDataSource) {
    this.datasource = datasource
  }
  async uploadBatch(file: any): Promise<string> {
    return this.datasource.uploadBatch(file)
  }

  async getListAttendanceByUserId(userId: number): Promise<UserAttendance[]> {
    const data = await this.datasource.getListAttendanceByUserId(userId)
    return mappingAttendees(data)
  }

  async getOneAttendees(schedule_id: string): Promise<UserAttendance> {
    const response = await this.datasource.getOneAttendeesByScheduleId(schedule_id)
    return mappingAttendance(response)
  }

  async updateOne(req: ScheduleLiveClassUpdateRequest, id?: string): Promise<Schedule> {
    const response = await this.datasource.update(req, id)
    const initBuilder = new ScheduleBuilder()
    const schedule = initBuilder.SingleData(response).Result()
    return schedule
  }

  async appendAttendees(req: any): Promise<void> {
    await this.datasource.appendAttendees(req)
  }

  async createOne(req: ScheduleLiveClassCreateRequest): Promise<Schedule> {
    const resposne = await this.datasource.create(req)
    const initBuilder = new ScheduleBuilder()
    const schedule = initBuilder.SingleData(resposne).Result()
    return schedule
  }

  deleteOneById(id: number): Promise<string> {
    return this.datasource.deleteOne(id)
  }

  async getDatatable(req: BaseDatatableRequest): Promise<BaseDatatableResponse<Schedule>> {
    const data = await this.datasource.getDatatable(req)
    const initBuilder = new ScheduleBuilder()
    const entities = initBuilder.ListData(data.entities).ResultList()
    const totalCount = data.totalCount
    return {
      entities,
      totalCount,
      errorMessage: data.errorMessage,
    }
  }

  async getOneById(id: number): Promise<Schedule> {
    const response = await this.datasource.getOne(id)
    const initBuilder = new ScheduleBuilder()
    const schedule = initBuilder.SingleData(response).Result()
    return schedule
  }

  async getAll(params?: any): Promise<Schedule[]> {
    const response = await this.datasource.getAll()
    const initBuilder = new ScheduleBuilder()
    const schedules = initBuilder.ListData(response).ResultList()
    return schedules
  }
}
