import useFilterTable from '../../../../../_hooks/useFilterTable'
import useDeleteSelectionDialog from '../../../../../_hooks/useDeleteSelectionDialog'
import useEditSelectionDialog from '../../../../../_hooks/useEditSelectionDialog'
import { useState } from 'react'
import { Student } from '../../../../../_domain/model/Student'

export const queryParamsContext = () => {
  const value = useFilterTable()

  const [students, setStudents] = useState<Student[]>([])

  return {
    ...value,
    students,
    setStudents
  }
}

export const deleteSectionContext = () => {
  const value = useDeleteSelectionDialog()

  return value
}
