import {Controller, useForm} from 'react-hook-form'
import Form from 'react-bootstrap/Form'
import useGroupClass from '../../../_hooks/useGroupClass'
import {BaseDatatableRequest} from '../../../_data/dataSource/request/BaseDatatableRequest'

type Props = {
  applyFilter: (values: any) => void
  onChange: (id: any) => void
  queryParams: BaseDatatableRequest
  disableFilter?: boolean
}

const StudentClassFilter = (props: Props) => {
  const {applyFilter, onChange, queryParams, disableFilter} = props

  const {listGroupClass} = useGroupClass()

  const {
    reset,
    control,
    setValue,
    getValues,
    formState: {errors},
  } = useForm<any>({
    mode: 'onChange',
  })

  function changeFilterState() {
    const groupClassIdInput = getValues('groupClassIdInput')

    const filter: any = {
      id: groupClassIdInput ? +groupClassIdInput : 0,
    }
    applyFilter(filter)
    onChange(filter.id)
  }

  return (
    <Form className='row w-50'>
      {/* Type Tryout Field */}
      <div className='col-6'>
        <Controller
          name='groupClassIdInput'
          defaultValue={queryParams.filter?.id ?? ''}
          control={control}
          render={({field}) => (
            <Form.Group>
              <Form.Label>Group Type</Form.Label>
              <Form.Select
                {...field}
                className='mb-3'
                onChange={(e) => {
                  setValue('groupClassIdInput', +e.target.value)
                  changeFilterState()
                }}
                disabled={disableFilter ?? false}
              >
                <option value=''>--Please Select Group Class--</option>
                {listGroupClass.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          )}
        />
      </div>
      {/* Reset Button */}
      <div className='col-6 mt-8'>
        <button
          type='button'
          onClick={() => {
            reset({groupClassIdInput: 0}), changeFilterState()
          }}
          className={`btn btn-sm btn-secondary ${disableFilter ? "d-none" : ""}`}
        >
          Reset
        </button>
      </div>
    </Form>
  )
}

export default StudentClassFilter
