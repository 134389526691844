import {groupClass} from '../../../../../_redux_slice'
import GroupClassDataSourceImpl from '../../../../../_data/dataSource/GroupClassDataSourceImpl'
import {GroupClassRepositoryImpl} from '../../../../../_domain/repository/GroupClassRepositoryImpl'
import {GroupClassServiceImpl} from '../../../../../_domain/service/GroupClassService'
import {BaseDatatableRequest} from '../../../../../_data/dataSource/request/BaseDatatableRequest'
import {
  GROUP_CLASS_CREATE_FAILED,
  GROUP_CLASS_CREATE_SUCCESS,
  GROUP_CLASS_EDIT_FAILED,
  GROUP_CLASS_EDIT_SUCCESS,
  GROUP_CLASS_GET_FAILED,
} from '../../../../../_domain/constanta'
import {alertError, alertSuccess, errorHandling} from '../../../../../helpers/FuncHelpers'
import {AlertPromise} from '../../../../../helpers/classHelpers'
import {GroupClassFormInput} from '../../../../../helpers/UIGroupClassConstanta'
import { SchoolRepositoryImpl } from '../../../../../_domain/repository/SchoolRepositoryImpl'
import SchoolDataSourceImpl from '../../../../../_data/dataSource/SchoolDataSourceImpl'

const {actions} = groupClass.slice
const callTypes = groupClass.callTypes
const dataSource = new GroupClassDataSourceImpl()
const dataSourceSchool = new SchoolDataSourceImpl()
const repository = new GroupClassRepositoryImpl(dataSource)
const repositorySchool = new SchoolRepositoryImpl(dataSourceSchool)
const service = new GroupClassServiceImpl(repository, repositorySchool)

const alertPromise = new AlertPromise()

export const getAllGroupClassDatatable = (queryParams: BaseDatatableRequest) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.list}))

  return service
    .getDatatableGroupClass(queryParams)
    .then((response) => {
      const {totalCount, entities} = response
      dispatch(actions.saveList({totalCount, entities}))

      alertPromise.success()

      return response
    })
    .catch((err) => {
      if (err.response) {
        errorHandling(err.response, GROUP_CLASS_GET_FAILED)
      } else {
        alertPromise.error()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const createGroupClass = (req: GroupClassFormInput) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.action}))
  return service
    .createOneGroupClass(req)
    .then((response) => {
      alertPromise.stop()
      const groupClass = response
      dispatch(actions.createObject({data: groupClass}))
      alertSuccess(GROUP_CLASS_CREATE_SUCCESS)
    })
    .catch((err) => {
      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, GROUP_CLASS_CREATE_FAILED)
      } else {
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const updateGroupClass = (req: GroupClassFormInput, id: string) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.action}))
  return service
    .updateOneGroupClass(req, id)
    .then((response) => {
      alertPromise.stop()
      const groupClass = response
      dispatch(actions.updateObject({data: groupClass}))
      alertSuccess(GROUP_CLASS_EDIT_SUCCESS)
    })
    .catch((err) => {
      console.log(err)

      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, GROUP_CLASS_EDIT_FAILED)
      } else {
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const getOneGroupClass = (id: string | undefined) => (dispatch: any) => {
  if (!id) {
    return dispatch(actions.saveObject({data: undefined}))
  }

  dispatch(actions.startCall({callType: callTypes.action}))
  return service
    .getDetailGroupClassById(id)
    .then((response) => {
      const groupClass = response
      dispatch(actions.saveObject({data: groupClass}))
      return groupClass
    })
    .catch((err) => {
      if (err.response) {
        errorHandling(err.response, GROUP_CLASS_GET_FAILED)
      } else {
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}
