/* eslint-disable jsx-a11y/anchor-is-valid */
import { Outlet, Route, Routes } from 'react-router'
import { PageTitle } from '../../../../../_layout/core'
import { ProductList } from './ProductList'
import { deleteSectionContext, queryParamsContext } from './_outletContext'
import ProductCreate from './ProductCreate'
import ProductEdit from './ProductEdit'


const ProductContent = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>{'Product'}</PageTitle>
      <Outlet context={{ queryParams: queryParamsContext(), deleteSection: deleteSectionContext() }} />
    </>
  )
}

const Product = () => {
  return (
    <>
      <Routes>
        <Route element={<ProductContent />}>
          <Route index element={<ProductList />} />
          <Route path='create' element={<ProductCreate />} />
          <Route path='edit/:productId' element={<ProductEdit />} />
        </Route>
      </Routes>
    </>
  )
}

export { Product }
