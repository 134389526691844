import useFilterTable from '../../../../../_hooks/useFilterTable'
import useDeleteSelectionDialog from '../../../../../_hooks/useDeleteSelectionDialog'
import useCreateDialog from '../../../../../_hooks/useCreateDialog'
import useEditSelectionDialog from '../../../../../_hooks/useEditSelectionDialog'


export const queryParamsContext = () => {
  const value = useFilterTable()

  return value
}

export const deleteSectionContext = () => {
  const value = useDeleteSelectionDialog()

  return value
}

export const createSectionContext = () => {
  const value = useCreateDialog()

  return value
}

export const editSectionContext = () => {
  const value = useEditSelectionDialog()

  return value
}

export const test = () => {
}