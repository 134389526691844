import axios from 'axios'
import SubjectDataSource from './SubjectDataSoruce'
import {SubjectResponse} from './response/SubjectResponse'
import {ApiResponse} from './response/BaseResponse'
import {BaseDatatableRequest} from './request/BaseDatatableRequest'
import {BaseDatatableResponse} from './response/BaseDatatableResponse'
import {MessageResponse} from './response/MessageResponse'
import {SubjectCreateRequest, SubjectUpdateRequest} from './request/SubjectRequest'

// const transform = (response: AxiosResponse): Promise<SubjectResponse[]> => {
//   return new Promise((resolve, reject) => {
//     const result: SubjectResponse[] = response.data.data
//     resolve(result)
//   })
// }

export default class SubjectDataSourceImpl implements SubjectDataSource {
  private SUBJECTS_URL: string
  constructor() {
    const service_chapter_url = process.env.REACT_APP_CHAPTER_SERVICE_URL || 'api/'
    this.SUBJECTS_URL = `${service_chapter_url}subjects`
  }

  async update(req: SubjectUpdateRequest, id: string): Promise<SubjectResponse> {
    const response = await axios.put<ApiResponse<SubjectResponse>>(
      `${this.SUBJECTS_URL}/${id}`,
      req
    )

    const data = response.data.data
    return data
  }

  async deleteOne(id: string): Promise<string> {
    const response = await axios.delete<MessageResponse>(`${this.SUBJECTS_URL}/${id}`)
    return response.data.message
  }

  async getDatatable(req: BaseDatatableRequest): Promise<BaseDatatableResponse<SubjectResponse>> {
    const response = await axios.post<ApiResponse<BaseDatatableResponse<SubjectResponse>>>(
      `${this.SUBJECTS_URL}/datatable`,
      req
    )

    const data = response.data.data

    return data
  }

  async getOne(id: string): Promise<SubjectResponse> {
    const response = await axios.get<ApiResponse<SubjectResponse>>(`${this.SUBJECTS_URL}/${id}`)
    const data = response.data.data
    return data
  }

  async getAll(): Promise<SubjectResponse[]> {
    const response = await axios.get<ApiResponse<SubjectResponse[]>>(`${this.SUBJECTS_URL}`)
    const data = response.data.data
    return data
  }

  async create(req: SubjectCreateRequest): Promise<SubjectResponse> {
    const response = await axios.post<ApiResponse<SubjectResponse>>(`${this.SUBJECTS_URL}/`, req)
    const data = response.data.data

    return data
  }
}
