import React, { Suspense, useEffect, useMemo } from 'react'
import { SCHEDULE_LIVE_CLASS_EDIT_TITLE } from '../../../../../_domain/constanta'
import { Link, useNavigate, useOutletContext, useParams } from 'react-router-dom'
import { getAllLiveClassDatatable, getOneLiveClass, updateLiveClass } from './LiveClassAction'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { ScheduleFormInput } from '../../../../../helpers/UIScheduleConstanta'
import { PageTitle } from '../../../../../_layout/core'
import CustomLoading from '../../../../../_component/customLoading'

const LiveClassEditForm = React.lazy(() => import('./LiveClassEditComponent/LiveClassEditForm'))

const LiveClassEdit = () => {
  const { liveClassId } = useParams()
  const navigate = useNavigate();

  const filterContext = useOutletContext<any>().queryParams
  const { queryParams } = useMemo(() => {
    return {
      queryParams: filterContext.queryParams,
    };
  }, [filterContext]);

  const dispatch = useDispatch();
  const { actionsLoading, entity } = useSelector(
    (state: any) => ({
      actionsLoading: state.liveClass.actionsLoading,
      entity: state.liveClass.entity,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch<any>(getOneLiveClass(liveClassId))
    return () => {
      dispatch<any>(getOneLiveClass(undefined))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [liveClassId]);

  const saveAction = (data: ScheduleFormInput) => {    
    if (liveClassId) {
      dispatch<any>(updateLiveClass(data, liveClassId)).then(() => {
        dispatch<any>(getAllLiveClassDatatable(queryParams));
        navigate("/edulive/live-class")
      })
    }
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>{SCHEDULE_LIVE_CLASS_EDIT_TITLE}</PageTitle>
      <div className='d-flex justify-content-between align-items-center mb-4'>
        <div className='w-full'></div>
        <div>
          <Link to={"/edulive/live-class"}>
            <button type='button'
              className='btn btn-secondary'>
              Back
            </button>
          </Link>
        </div>
      </div>
      <Suspense fallback={<CustomLoading />}>
        {entity ? <LiveClassEditForm dataLiveClass={entity} onSubmit={saveAction} /> : null}
      </Suspense>
    </>
  )
}

export default LiveClassEdit 
