import QuestionTryoutDataSource from '../../_data/dataSource/QuestionTryoutDataSoruce'
import {BaseDatatableRequest} from '../../_data/dataSource/request/BaseDatatableRequest'
import {
  QuestionTryoutAddRequest,
  QuestionTryoutCreateRequest,
  QuestionTryoutEditExistRequest,
  QuestionTryoutUpdateRequest,
} from '../../_data/dataSource/request/QuestionTryoutRequest'
import {BaseDatatableResponse} from '../../_data/dataSource/response/BaseDatatableResponse'
import {QuestionTryout, QuestionTryoutBuilder} from '../model/QuestionTryout'
import {QuestionTryoutRepository} from './QuestionTryoutRepository'

export class QuestionTryoutRepositoryImpl implements QuestionTryoutRepository {
  datasource: QuestionTryoutDataSource

  constructor(datasource: QuestionTryoutDataSource) {
    this.datasource = datasource
  }
  async updateExistOne(req: QuestionTryoutEditExistRequest): Promise<QuestionTryout> {
    const response = await this.datasource.editExist(req)
    const initBuilder = new QuestionTryoutBuilder()
    const question = initBuilder.SingleData(response).Result()
    return question
  }
  async addOne(req: QuestionTryoutAddRequest): Promise<QuestionTryout> {
    const response = await this.datasource.add(req)
    const initBuilder = new QuestionTryoutBuilder()
    const question = initBuilder.SingleData(response).Result()
    return question
  }

  async updateOne(req: QuestionTryoutUpdateRequest): Promise<QuestionTryout> {
    const response = await this.datasource.update(req, req.chapterId)
    const initBuilder = new QuestionTryoutBuilder()
    const question = initBuilder.SingleData(response).Result()
    return question
  }

  async createOne(req: QuestionTryoutCreateRequest): Promise<QuestionTryout> {
    const response = await this.datasource.create(req)
    const initBuilder = new QuestionTryoutBuilder()
    const question = initBuilder.SingleData(response).Result()
    return question
  }

  async deleteOneById(questionId: string, chapterId: string): Promise<string> {
    return this.datasource.deleteOne(questionId, chapterId)
  }

  async getDatatable(req: BaseDatatableRequest): Promise<BaseDatatableResponse<QuestionTryout>> {
    const data = await this.datasource.getDatatable(req)
    const initBuilder = new QuestionTryoutBuilder()
    const entities = initBuilder.ListData(data.entities).ResultList()
    const totalCount = data.totalCount
    return {
      entities,
      totalCount,
      errorMessage: data.errorMessage,
    }
  }

  async getOneById(questionId: string, chapterId: string): Promise<QuestionTryout> {
    const response = await this.datasource.getOneByChapter(questionId, chapterId)
    const initBuilder = new QuestionTryoutBuilder()
    const question = initBuilder.SingleData(response).Result()
    return question
  }

  getAll(): Promise<QuestionTryout[]> {
    throw new Error('Method not implemented.')
    // return this.datasource.getAll()
  }
}
