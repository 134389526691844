import { PageTitle } from '../../../../../_layout/core'
import React, { Suspense, useMemo, useState } from 'react'
import CustomLoading from '../../../../../_component/customLoading'
import { QUESTION_TRYOUT_CREATE_TITLE } from '../../../../../_domain/constanta/QuestionTryoutConstant'
import { Link, useNavigate, useOutletContext } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { addTryoutQuestion, createTryoutQuestion, getAllTryoutQuestionDatatable } from './TryoutQuestionAction'
import { Nav } from 'react-bootstrap'
import { QuestionTryoutExistFormInput, QuestionTryoutFormInput } from '../../../../../helpers/UIQuestionTryoutConstanta'

const TryoutQuestionCreateForm = React.lazy(() =>
  import('./TryoutQuestionCreateComponent/TryoutQuestionCreateForm')
)
const TryoutQuestionExistForm = React.lazy(() =>
  import('./TryoutQuestionCreateComponent/TryoutQuestionExistForm')
)

const TryoutQuestionCreate = () => {
  const [tab, setTab] = useState("new")
  const navigate = useNavigate()
  const dispatch = useDispatch();

  const filterContext = useOutletContext<any>().filter
  const { queryParams, setCurrentTypeTO } = useMemo(() => {
    return {
      queryParams: filterContext.queryParams,
      setCurrentTypeTO: filterContext.setCurrentTypeTO
    };
  }, [filterContext]);

  const saveAction = (data: QuestionTryoutFormInput) => {
    dispatch<any>(createTryoutQuestion(data)).then(() => {
      // refresh list after deletion
      dispatch<any>(getAllTryoutQuestionDatatable(queryParams));
      // Set Current Type TO
      setCurrentTypeTO(data.typeTOInput)
      // Back
      navigate("/tryout/question")
    })
  }

  const addAction = (data: QuestionTryoutExistFormInput) => {
    dispatch<any>(addTryoutQuestion(data)).then(() => {
      // refresh list after deletion
      dispatch<any>(getAllTryoutQuestionDatatable(queryParams));
      // Set Current Type TO
      setCurrentTypeTO(data.typeTOInput)
      // Back
      navigate("/tryout/question")
    })
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>{QUESTION_TRYOUT_CREATE_TITLE}</PageTitle>
      <div className='d-flex justify-content-between align-items-center mb-4'>
        <div className='w-full'></div>
        <div>
          <Link to={"/tryout/question"}>
            <button type='button'
              className='btn btn-secondary'>
              Back
            </button>
          </Link>
        </div>
      </div>
      <Suspense fallback={<CustomLoading />}>
        <Nav variant="pills" activeKey={tab} className='mb-4'>
          <Nav.Item>
            <Nav.Link eventKey={"new"} title="New Question" onClick={() => setTab("new")}>
              New Question
            </Nav.Link>
          </Nav.Item>
          {/* <Nav.Item>
            <Nav.Link eventKey={"exist"} title="Exist Question" onClick={() => setTab("exist")}>
              Exist Question
            </Nav.Link>
          </Nav.Item> */}
        </Nav>
        {tab === "new" && (
          <TryoutQuestionCreateForm onSubmit={saveAction} />
        )}
        {tab === "exist" && (
          null
          // <TryoutQuestionExistForm onSubmit={addAction} />
        )}
      </Suspense>
    </>
  )
}

export { TryoutQuestionCreate }
