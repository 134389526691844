/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { PageTitle } from '../../../../../_layout/core'
import { getAllTeamDatatable } from './TeamAction'
import { Suspense, lazy } from 'react'
import CustomLoading from '../../../../../_component/customLoading'
import { useOutletContext } from 'react-router-dom'
import { Team } from '../../../../../_domain/model/Team'
import { BaseDatatableResponse } from '../../../../../_data/dataSource/response/BaseDatatableResponse'
import TeamDelete from '../Team/TeamDelete'
import { TEAM_LIST_TITLE } from '../../../../../_domain/constanta'
import { useAuth } from '../../../../../_context/AuthContext'
import { checkAuthActionMenu } from '../../../../../helpers/AuthHelpers'
import { SLUG_MASTER_SUB_MENU } from '../../../../../helpers/UIAuthGroupConstanta'

const TeamListTable = React.lazy(() => import('./TeamListComponent/TeamListTable'))
const TeamCreate = React.lazy(() => import('./TeamCreate'))
const TeamEdit = React.lazy(() => import('../Team/TeamEdit'))
const TeamCustumAuth = React.lazy(() => import('./TeamCustumAuth'))
const TeamExtendRole = React.lazy(() => import('./TeamExtendRole'))

const checkReduxData = () => {
  const filterContext = useOutletContext<any>().queryParams
  const { queryParams } = useMemo(() => {
    return {
      queryParams: filterContext.queryParams,
    };
  }, [filterContext]);

  const [teamListData, setTeamListData] = useState<any>(null)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch<any>(getAllTeamDatatable(queryParams)).then((response: BaseDatatableResponse<Team>) => {
      setTeamListData(response.entities)
    })
  }, [queryParams, getAllTeamDatatable])

  return teamListData
}

const TeamList = () => {
  let data = checkReduxData()
  const { currentUser, authorization } = useAuth()
  return checkAuthActionMenu(SLUG_MASTER_SUB_MENU.MASTER_TEAM_MENU, "view", currentUser, authorization) ? (
    <>
      <TeamCreate />
      <TeamEdit />
      <TeamCustumAuth />
      <TeamExtendRole />
      <TeamDelete />
      <PageTitle breadcrumbs={[]}>{TEAM_LIST_TITLE}</PageTitle>
      <Suspense fallback={<CustomLoading />}>
        {data ? <TeamListTable /> : null}
      </Suspense>
    </>
  ) : null
}

export { TeamList }