import {CALCULATE_TYPE} from '../../../_domain/constanta'
import {calculateTypeSwitch} from '../../../helpers/FuncHelpers'
import {TypeTryoutFormInput} from '../../../helpers/UITypeTryoutConstanta'

export type TypeTryoutCreateRequest = {
  name: string
  description?: string
  calculate_type?: CALCULATE_TYPE
}

export type TypeTryoutUpdateRequest = {
  name: string
  description?: string
  calculate_type?: CALCULATE_TYPE
}

export function mappingInputFormToTypeCreateRequest(
  req: TypeTryoutFormInput
): TypeTryoutCreateRequest {
  return {
    name: req.nameInput ?? '',
    description: req.descriptionInput ?? '',
    calculate_type: calculateTypeSwitch(req.calculateTypeInput!),
  }
}

export function mappingInputFormToTypeUpdateRequest(
  req: TypeTryoutFormInput
): TypeTryoutUpdateRequest {
  return {
    name: req.nameInput ?? '',
    description: req.descriptionInput ?? '',
    calculate_type: calculateTypeSwitch(req.calculateTypeInput!),
  }
}

// export type TypeTryoutFilterRequest = {
//   major: string
//   text: string
// }
