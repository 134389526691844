export const CLASS_STUDENT = [
  {
    grade: '01',
    level: 'SD',
    major: 'ALL',
  },
  {
    grade: '02',
    level: 'SD',
    major: 'ALL',
  },
  {
    grade: '03',
    level: 'SD',
    major: 'ALL',
  },
  {
    grade: '04',
    level: 'SD',
    major: 'ALL',
  },
  {
    grade: '05',
    level: 'SD',
    major: 'ALL',
  },
  {
    grade: '06',
    level: 'SD',
    major: 'ALL',
  },
  {
    grade: '07',
    level: 'SMP',
    major: 'ALL',
  },
  {
    grade: '08',
    level: 'SMP',
    major: 'ALL',
  },
  {
    grade: '09',
    level: 'SMP',
    major: 'ALL',
  },
  {
    grade: '10',
    level: 'SMA',
    major: 'IPA',
  },
  {
    grade: '10',
    level: 'SMA',
    major: 'IPS',
  },
  {
    grade: '10',
    level: 'SMA',
    major: 'ALL',
  },
  {
    grade: '11',
    level: 'SMA',
    major: 'IPA',
  },
  {
    grade: '11',
    level: 'SMA',
    major: 'IPS',
  },
  {
    grade: '11',
    level: 'SMA',
    major: 'ALL',
  },
  {
    grade: '12',
    level: 'SMA',
    major: 'IPA',
  },
  {
    grade: '12',
    level: 'SMA',
    major: 'IPS',
  },
  {
    grade: '12',
    level: 'SMA',
    major: 'ALL',
  },
  {
    grade: 'ALUMNI',
    level: 'SMA',
    major: 'IPA',
  },
  {
    grade: 'ALUMNI',
    level: 'SMA',
    major: 'IPS',
  },
  {
    grade: 'ALUMNI',
    level: 'SMA',
    major: 'ALL',
  },
]

export const CLASS_PRODUCT = [
  {
    name: 'Kelas 1 SD',
    value: 1,
  },
  {
    name: 'Kelas 2 SD',
    value: 2,
  },
  {
    name: 'Kelas 3 SD',
    value: 3,
  },
  {
    name: 'Kelas 4 SD',
    value: 4,
  },
  {
    name: 'Kelas 5 SD',
    value: 5,
  },
  {
    name: 'Kelas 6 SD',
    value: 6,
  },
  {
    name: 'Kelas 7 SMP',
    value: 7,
  },
  {
    name: 'Kelas 8 SMP',
    value: 8,
  },
  {
    name: 'Kelas 9 SMP',
    value: 9,
  },
  {
    name: 'Kelas 10 SMA',
    value: 10,
  },
  {
    name: 'Kelas 11 SMA',
    value: 11,
  },
  {
    name: 'Kelas 12 SMA',
    value: 12,
  },
  {
    name: 'Alumni',
    value: 13,
  },
]

export const SEMESTER = ['Semester 1', 'Semester 2', 'Intensif']

export const SUBCLASS = ['A', 'B', 'C', 'D', 'E']

export const BRANCH = ['TEBET', 'SERPONG']

export const TYPE_TRYOUT_MAJOR_UI = [
  {name: 'IPA', value: 'IPA'},
  {name: 'IPS', value: 'IPS'},
  {name: 'IPC', value: 'ALL'},
]

export const ROLE_UI = [
  {name: 'Administration', value: 'administration'},
  {name: 'Finance', value: 'finance'},
  {name: 'Branch', value: 'branch'},
]

export const ANSWER_KEY = ['A', 'B', 'C', 'D', 'E']

export const ClassProductList = [
  {name: '01', value: 1},
  {name: '02', value: 2},
  {name: '03', value: 3},
  {name: '04', value: 4},
  {name: '05', value: 5},
  {name: '06', value: 6},
  {name: '07', value: 7},
  {name: '08', value: 8},
  {name: '09', value: 9},
  {name: '10', value: 10},
  {name: '11', value: 11},
  {name: '12', value: 12},
  {name: 'ALUMNI', value: 13},
]

export const TypeProductList = [
  {name: 'Edu Live Online', value: 'edulive-online'},
  {name: 'Edu Live Offline', value: 'edulive-offline'},
  {name: 'Edu Video', value: 'eduvideo'},
  {name: 'Try Out', value: 'tryout'},
]

export const ProductModelList = [
  {name: 'Premium', value: 'premium'},
  {name: 'PMQ', value: 'PMQ'},
]

export const TypeQuestionlList = [
  {name: 'Single Answer', value: 'single'},
  {name: 'Multiple Answers', value: 'multiple'},
  {name: 'True & False Answers', value: 'TF'},
  {name: 'Incomplete Sentences', value: 'incomplete'},
]

export const TYPE_CHAPTER_UI = [
  {key: 'umum', value: 'Materi Umum'},
  {key: 'pmq', value: 'Materi PMQ'},
  {key: 'tryout', value: 'Materi Tryout'},
]

export const typeOrderConst = [
  {name: 'Alpha', code: 'alpha'},
  {name: 'Betha', code: 'betha'},
  {name: 'Gamma', code: 'gamma'},
]

export const TYPE_SCHEDULE = [
  {name: 'Live Class', value: 'live class'},
  {name: 'Konsultasi Online', value: 'KOS'},
]

export const MasterStatusTitles = [
  {name: 'Active', value: 'active'},
  {name: 'Non Active', value: 'nonactive'},
]
