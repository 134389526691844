import {PAYMENT_STATUS} from '../../../_domain/constanta'
import {currencyToNumberFormat} from '../../../helpers/FuncHelpers'
import {OrderCreateFormInput, OrderEditFormInput} from '../../../helpers/UIOrderConstanta'

export type OrderCreateRequest = {
  user_id?: number
  new_user?: boolean
  name?: string
  email?: string
  phone_number?: string
  password?: string
  product_id: string
  amount: number
  installment: number[]
}

export type OrderUpdateRequest = {
  amount: number
  installment: number[]
}

export type OrderEditInstallmentRequest = {
  installment_id: string
  amount: number
}
export type OrderEditStatusInstallmentRequest = {
  installment_id: string
  status: PAYMENT_STATUS
}

export type OrderFilterRequest = {}

export function mappingInputFormToOrderUpdateRequest(req: OrderEditFormInput): OrderUpdateRequest {
  return {
    amount: currencyToNumberFormat(req.amountInput ?? '0'),
    installment:
      req.installment?.map((item) => {
        return currencyToNumberFormat(item)
      }) ?? [],
  }
}

export function mappingInputFormToOrderCreateRequest(
  req: OrderCreateFormInput
): OrderCreateRequest {
  const body: OrderCreateRequest = {
    user_id: req.userInput?.at(0)?.id ?? undefined,
    product_id: req.productInput.at(0)?.id ?? '',
    amount: currencyToNumberFormat(req.amountInput),
    installment: req.installment.map((item) => currencyToNumberFormat(item)),
  }
  if (req.newUserInput) {
    body.new_user = true
    body.user_id = undefined
    body.name = req.nameInput
    body.email = req.emailInput
    body.phone_number = '+62' + req.phoneNumberInput
    body.password = 'qonstanta'
  }

  return body
}
