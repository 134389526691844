/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { PageTitle } from '../../../../../_layout/core'
import { getAllGroupRoleDatatable } from './GroupRoleAction'
import { Suspense, lazy } from 'react'
import CustomLoading from '../../../../../_component/customLoading'
import { useOutletContext } from 'react-router-dom'
import { BaseDatatableResponse } from '../../../../../_data/dataSource/response/BaseDatatableResponse'
import { useAuth } from '../../../../../_context/AuthContext'
import { checkAuthActionMenu } from '../../../../../helpers/AuthHelpers'
import { SLUG_MASTER_SUB_MENU } from '../../../../../helpers/UIAuthGroupConstanta'
import { StudentGroupRole } from '../../../../../_domain/model/StudentRole'

const GroupRoleListTable = React.lazy(() => import('./GroupRoleListComponent/GroupRoleListTable'))

const checkReduxData = () => {
  const outletContext = useOutletContext<any>().queryParams
  const { queryParams } = outletContext

  const [groupRoleListData, setGroupRoleListData] = useState<any>(null)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch<any>(getAllGroupRoleDatatable(queryParams)).then((response: BaseDatatableResponse<StudentGroupRole>) => {
      setGroupRoleListData(response.entities)
    })
  }, [queryParams, getAllGroupRoleDatatable])

  return groupRoleListData
  // detect redux first before fetch API END
}

const GroupRoleList = () => {
  let data = checkReduxData()
  const { currentUser, authorization } = useAuth()
  return checkAuthActionMenu(SLUG_MASTER_SUB_MENU.MASTER_GROUP_ROLE_MENU, "view", currentUser, authorization) ? (
    <>
      <PageTitle breadcrumbs={[]}>{'Student Group Role List'}</PageTitle>
      <Suspense fallback={<CustomLoading />}>
        {data ? <GroupRoleListTable /> : null}
      </Suspense>
    </>

  ) : null
}

export { GroupRoleList }
