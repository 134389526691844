import axios from 'axios'
import FacilityDataSource from './FacilityDataSoruce'
import {FacilityResponse} from './response/FacilityResponse'
import {ApiResponse} from './response/BaseResponse'
import {BaseDatatableRequest} from './request/BaseDatatableRequest'
import {BaseDatatableResponse} from './response/BaseDatatableResponse'
import {MessageResponse} from './response/MessageResponse'
import {FacilityCreateRequest, FacilityUpdateRequest} from './request/FacilityRequest'

export default class FacilityDataSourceImpl implements FacilityDataSource {
  private FACILITIES_URL: string
  constructor() {
    const service_product_url = process.env.REACT_APP_PRODUCT_SERVICE_URL || 'api/'
    this.FACILITIES_URL = `${service_product_url}facilities`
  }

  async update(req: FacilityUpdateRequest, id: string): Promise<FacilityResponse> {
    const response = await axios.put<ApiResponse<FacilityResponse>>(
      `${this.FACILITIES_URL}/${id}`,
      req
    )

    const data = response.data.data
    return data
  }

  async deleteOne(id: string): Promise<string> {
    const response = await axios.delete<MessageResponse>(`${this.FACILITIES_URL}/${id}`)
    return response.data.message
  }

  async getDatatable(req: BaseDatatableRequest): Promise<BaseDatatableResponse<FacilityResponse>> {
    const response = await axios.post<ApiResponse<BaseDatatableResponse<FacilityResponse>>>(
      `${this.FACILITIES_URL}/datatable`,
      req
    )

    const data = response.data.data

    return data
  }

  async getOne(id: string): Promise<FacilityResponse> {
    const response = await axios.get<ApiResponse<FacilityResponse>>(`${this.FACILITIES_URL}/${id}`)
    const data = response.data.data
    return data
  }

  async getAll(): Promise<FacilityResponse[]> {
    const response = await axios.get<ApiResponse<FacilityResponse[]>>(`${this.FACILITIES_URL}`)
    const data = response.data.data
    return data
  }

  async create(req: FacilityCreateRequest): Promise<FacilityResponse> {
    const response = await axios.post<ApiResponse<FacilityResponse>>(`${this.FACILITIES_URL}/`, req)
    const data = response.data.data

    return data
  }
}
