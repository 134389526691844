import {BaseDatatableRequest} from '../../_data/dataSource/request/BaseDatatableRequest'
import {
  ChapterFileRequest,
  mappingInputFormToChapterCreateRequest,
  mappingInputFormToChapterUpdateRequest,
} from '../../_data/dataSource/request/ChapterRequest'
import {BaseDatatableResponse} from '../../_data/dataSource/response/BaseDatatableResponse'
import {createChapterFileResponse} from '../../_data/dataSource/response/ChapterResponse'
import {ChapterFormInput} from '../../helpers/UIChapterConstanta'
import {CLASS_STUDENT} from '../../helpers/UIConstanta'
import {IChapterfiles} from '../../types'
import {Chapter} from '../model/Chapter'
import {ChapterRepository} from '../repository/ChapterRepository'

export interface ChapterService {
  getDatatableChapter(req: BaseDatatableRequest): Promise<BaseDatatableResponse<Chapter>>
  // getAllChapter(): Promise<Chapter[]>
  getDetailChapterById(id: string): Promise<Chapter>
  deleteOneChapterByID(id: string): Promise<string>
  createOneChapter(req: ChapterFormInput): Promise<Chapter>
  updateOneChapter(req: ChapterFormInput, id: string): Promise<Chapter>
}

export class ChapterServiceImpl implements ChapterService {
  private repo: ChapterRepository
  constructor(repo: ChapterRepository) {
    this.repo = repo
  }

  async updateOneChapter(req: ChapterFormInput, id: string): Promise<Chapter> {
    this.mergeTypeFilesEbook(req)
    const body = mappingInputFormToChapterUpdateRequest(req)

    const chapter = await this.repo.updateOne(body, id)
    return chapter
  }

  async createOneChapter(req: ChapterFormInput): Promise<Chapter> {
    this.mergeTypeFilesEbook(req)
    const body = mappingInputFormToChapterCreateRequest(req)

    const chapter = await this.repo.createOne(body)
    return chapter
  }

  async deleteOneChapterByID(id: string): Promise<string> {
    const message = await this.repo.deleteOneById(id)
    return message
  }

  async getDatatableChapter(req: BaseDatatableRequest): Promise<BaseDatatableResponse<Chapter>> {
    const chapters = await this.repo.getDatatable(req)
    return chapters
  }

  async getDetailChapterById(id: string): Promise<Chapter> {
    let chapter = await this.repo.getOneById(id)
    const class_index = CLASS_STUDENT.findIndex(
      (item, index) =>
        (item.grade === 'ALUMNI' ? 13 : +item.grade) === chapter.grade &&
        item.major === chapter.major
    )
    chapter.class_index = class_index
    return chapter
  }

  async createChapterFiles(
    body: ChapterFileRequest,
    type: 'ebook' | 'video'
  ): Promise<createChapterFileResponse> {
    const chapter = await this.repo.createChapterFiles(body, type)
    return chapter
  }

  async getDatatableChapterFiles(
    req: BaseDatatableRequest,
    type: 'ebook' | 'video' | null
  ): Promise<BaseDatatableResponse<IChapterfiles>> {
    const chapterFiles = await this.repo.getAllChapterFilesDatatable(req, type)
    return chapterFiles
  }

  private mergeTypeFilesEbook(req: ChapterFormInput) {
    let arrayFiles: any[] = []

    if (req.ebookInput) {
      arrayFiles.push(req.ebookInput)
    }
    if (req.explanationInput) {
      arrayFiles.push(req.explanationInput)
    }
    if (req.explanationDetailInput) {
      arrayFiles.push(req.explanationDetailInput)
    }

    req.filesInput = arrayFiles
  }
  // async getAllChapter(): Promise<Chapter[]> {
  //   const chapters = await this.repo.getAll()
  //   return chapters
  // }
}
