/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { PageTitle } from '../../../../../_layout/core'
import { getDatatableTypeTryout } from './TryoutTypeAction'
import { Suspense, lazy } from 'react'
import CustomLoading from '../../../../../_component/customLoading'
import { useOutletContext } from 'react-router-dom'
import { BaseDatatableResponse } from '../../../../../_data/dataSource/response/BaseDatatableResponse'
import { TypeTryout } from '../../../../../_domain/model/TypeTryout'
import { TYPE_TRYOUT_LIST_TITLE } from '../../../../../_domain/constanta/TypeTryoutConstant'
import { TryoutTypeEdit } from './TryoutTypeEdit'
import TryoutTypeDelete from './TryoutTypeDelete'
import { useAuth } from '../../../../../_context/AuthContext'
import { checkAuthActionMenu } from '../../../../../helpers/AuthHelpers'
import { SLUG_TRYOUT_SUB_MENU } from '../../../../../helpers/UIAuthGroupConstanta'

const TryoutTypeListTable = React.lazy(() =>
  import('./TryoutTypeListComponent/TryoutTypeListTable')
)
const TryoutTypeCreate = React.lazy(() => import('./TryoutTypeCreate'))

const checkReduxData = () => {
  const outletContext = useOutletContext<any>().queryParams
  const { queryParams } = outletContext

  const [tryoutListData, setTryoutListData] = useState<TypeTryout[] | null>(null)
  const dispatch = useDispatch()

  // let reduxData = getOneReduxData('typeTOs', [
  //   {
  //     key: 'deleted',
  //     value: false,
  //   },
  // ])

  // useEffect(() => {
  //   if (reduxData.length === 0) {
  //     // fetch from API if data is not available in the redux
  //     dispatch(fetchTypeTOs(filter)).then((response) => {
  //       setTryoutListData(response.entities)
  //     })
  //   } else {
  //     // set State if the redux have the specific data
  //     setTryoutListData(reduxData)
  //   }
  // }, [filter, fetchTypeTOs])

  useEffect(() => {
    dispatch<any>(getDatatableTypeTryout(queryParams)).then((response: BaseDatatableResponse<TypeTryout>) => {
      setTryoutListData(response.entities)
    })
  }, [queryParams, getDatatableTypeTryout])

  return tryoutListData
  // detect redux first before fetch API END
}

const TryoutTypeList = () => {
  let data = checkReduxData()
  const { currentUser, authorization } = useAuth()
  return checkAuthActionMenu(SLUG_TRYOUT_SUB_MENU.TRYOUT_TYPE_MENU, "view", currentUser, authorization) ? (
    <>
      <TryoutTypeCreate />
      <TryoutTypeEdit />
      <TryoutTypeDelete />
      <PageTitle breadcrumbs={[]}>{TYPE_TRYOUT_LIST_TITLE}</PageTitle>
      <Suspense fallback={<CustomLoading />}>
        {data ? <TryoutTypeListTable /> : null}
      </Suspense>
    </>
  ) : null
}

export { TryoutTypeList }
