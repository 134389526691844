import { FC } from 'react'
import BaseTable from '../BaseTable'
import { School } from '../../../_domain/model/School'

type Props = {
  entities: School[]
  totalCount: number
}

const SchoolSelectedTable: FC<Props> = ({ entities, totalCount }) => {

  let mutableTableData = [...entities ?? []]

  const columns = [
    {
      name: 'Name',
      selector: (row: School) => row.name,
      // sortable: true,
    },
    {
      name: 'City',
      selector: (row: School) => row.city_name,
      sortable: true,
    },
  ]

  // Use the state and functions returned from useTable to build your UI

  return (
    <div className='customized-datatable-style'>
      <BaseTable
        columns={columns}
        data={mutableTableData}
        countPerPage={100}
        totalRows={totalCount}
        selectableRows={false}
      />
    </div>
  )
}

export default SchoolSelectedTable
