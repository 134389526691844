import {type} from 'os'
import {
  ScheduleResponse,
  UserAttendanceResponse,
} from '../../_data/dataSource/response/ScheduleResponse'
import {getIndexClassStudent, targetUserSwitch} from '../../helpers/FuncHelpers'
import {TARGET_USER} from '../constanta'
import {Chapter} from './Chapter'

type ChapterDetail = {
  chapter_detail?: Partial<
    Pick<Chapter, 'id' | 'name' | 'grade' | 'major' | 'semester' | 'class_index' | 'subject_detail'>
  >
}

type SchoolsDetail = {
  schools?: {
    id: number
    name: string
    city_name?: string
    city_id?: string
    province_name?: string
    province_id?: string
  }[]
}

export type Schedule = ScheduleResponse & ChapterDetail & SchoolsDetail

export type UserAttendance = Omit<UserAttendanceResponse, '_id'> & {id: string}

export class ScheduleBuilder {
  private SingleSchedule!: Schedule
  private ScheduleList!: Schedule[]
  constructor() {}

  SingleData(scheduleResponse: ScheduleResponse): ScheduleBuilder {
    const schedule = mapingSchedule(scheduleResponse)
    this.SingleSchedule = schedule
    return this
  }
  ListData(scheduleResponse: ScheduleResponse[]): ScheduleBuilder {
    const schedules = mapingSchedules(scheduleResponse)
    this.ScheduleList = schedules
    return this
  }
  Result(): Schedule {
    return this.SingleSchedule
  }
  ResultList(): Schedule[] {
    return this.ScheduleList
  }
}

function mapingSchedule(scheduleResponse: ScheduleResponse): Schedule {
  const chapter = scheduleResponse.chapter_ids?.at(0)
  const schedule: Schedule = {
    ...scheduleResponse,
    id: scheduleResponse._id ?? '',
    question_ids: scheduleResponse.question_ids?.map((item) => {
      return {
        ...item,
        id: item._id,
      }
    }),
    chapter_detail: {
      id: chapter?._id ?? '',
      name: chapter?.name ?? '',
    },
    class_student: scheduleResponse.class_student?.map((itm) => {
      return {
        major: itm.major,
        level: itm.level,
        grade: itm.grade,
        index: getIndexClassStudent(itm.grade, itm.major) ?? 0,
      }
    }),
    schools: scheduleResponse.school_ids?.map((obj) => {
      return {
        id: obj.id,
        name: obj.name,
      }
    }),
    teacher_ids:
      scheduleResponse.teacher_ids?.map((obj) => {
        return {
          _id: obj._id,
          id: obj._id,
          name: obj.name,
          photo: obj.photo,
        }
      }) ?? [],
  }
  schedule.isIncreaseStudent = schedule.target_user === TARGET_USER.MIX_SELECTED
  schedule.target_user = targetUserTab(schedule)
  return schedule
}

function mapingSchedules(schedulesResponse: ScheduleResponse[]): Schedule[] {
  const schedules = schedulesResponse.map((item) => {
    return mapingSchedule(item)
  })
  return schedules
}

function targetUserTab(dataTryoutSchedule: Schedule): TARGET_USER {
  if (dataTryoutSchedule.target_user === TARGET_USER.MIX_SELECTED) {
    if (dataTryoutSchedule.schools && dataTryoutSchedule.schools.length > 0) {
      return TARGET_USER.SCHOOL_SELECTED
    }
    return TARGET_USER.CLASS_SELECTED
  }
  return targetUserSwitch(dataTryoutSchedule.target_user!)
}

export function mappingAttendance(response: UserAttendanceResponse): UserAttendance {
  return {
    ...response,
    id: response._id,
  }
}

export function mappingAttendees(responses: UserAttendanceResponse[]): UserAttendance[] {
  const attendees = responses.map((item) => {
    return mappingAttendance(item)
  })
  return attendees
}
