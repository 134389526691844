import {products} from '../../../../../_redux_slice'
import {ProductRepositoryImpl} from '../../../../../_domain/repository/ProductRepositoryImpl'
import ProductDataSourceImpl from '../../../../../_data/dataSource/ProductDataSourceImpl'
import {ProductServiceImpl} from '../../../../../_domain/service/ProductService'
import {BaseDatatableRequest} from '../../../../../_data/dataSource/request/BaseDatatableRequest'
import {
  PRODUCT_CREATE_FAILED,
  PRODUCT_CREATE_SUCCESS,
  PRODUCT_DELETE_FAILED,
  PRODUCT_DELETE_SUCCESS,
  PRODUCT_EDIT_FAILED,
  PRODUCT_EDIT_SUCCESS,
  PRODUCT_GET_FAILED,
} from '../../../../../_domain/constanta'
import {ProductFormInput} from '../../../../../helpers/UIProductConstanta'
import {alertError, alertSuccess, errorHandling} from '../../../../../helpers/FuncHelpers'
import {AlertPromise} from '../../../../../helpers/classHelpers'
import StudentRoleDataSourceImpl from '../../../../../_data/dataSource/StudentRoleDataSourceImpl'
import {StudentRoleRepositoryImpl} from '../../../../../_domain/repository/StudentRoleRepositoryImpl'

const {actions} = products.slice
// const controlAction = control.slice.actions
const callTypes = products.callTypes
const dataSource = new ProductDataSourceImpl()
const dataSourceRole = new StudentRoleDataSourceImpl()
const repository = new ProductRepositoryImpl(dataSource)
const repositoryRole = new StudentRoleRepositoryImpl(dataSourceRole)
const service = new ProductServiceImpl(repository)

const alertPromise = new AlertPromise()
export const getAllProductDatatable = (queryParams: BaseDatatableRequest) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.list}))

  return service
    .getDatatableProduct(queryParams)
    .then((response) => {
      const {totalCount, entities} = response
      dispatch(actions.saveList({totalCount, entities}))

      alertPromise.success()

      return response
    })
    .catch((err) => {
      if (err.response) {
        errorHandling(err.response, PRODUCT_GET_FAILED)
      } else {
        alertPromise.error()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const getOneProduct = (id: string | undefined) => (dispatch: any) => {
  if (!id) {
    return dispatch(actions.saveObject({data: undefined}))
  }

  dispatch(actions.startCall({callType: callTypes.action}))
  return service
    .getDetailProductById(id)
    .then(async (response) => {
      let product = response
      if (product.group_role_id) {
        const groupRole = await repositoryRole.getOneGroupRole(product.group_role_id)
        product.group_role_detail = groupRole
      }
      dispatch(actions.saveObject({data: product}))
      return product
    })
    .catch((err) => {
      if (err.response) {
        errorHandling(err.response, PRODUCT_GET_FAILED)
      } else {
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const createProduct = (req: ProductFormInput) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.action}))
  return service
    .createOneProduct(req)
    .then((response) => {
      alertPromise.stop()
      const product = response
      dispatch(actions.createObject({data: product}))
      alertSuccess(PRODUCT_CREATE_SUCCESS)
    })
    .catch((err) => {
      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, PRODUCT_CREATE_FAILED)
      } else {
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const updateProduct = (req: ProductFormInput, id: string) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.action}))
  return service
    .updateOneProduct(req, id)
    .then((response) => {
      alertPromise.stop()
      const product = response
      dispatch(actions.updateObject({data: product}))
      alertSuccess(PRODUCT_EDIT_SUCCESS)
    })
    .catch((err) => {
      console.log(err)

      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, PRODUCT_EDIT_FAILED)
      } else {
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const deleteOneProduct = (id: string) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.action}))
  return service
    .deleteOneProductByID(id)
    .then(() => {
      alertPromise.stop()
      alertSuccess(PRODUCT_DELETE_SUCCESS)
      dispatch(actions.deleteObject({id}))
    })
    .catch((err) => {
      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, PRODUCT_DELETE_FAILED)
      } else {
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

// export const deleteBatchProduct = (ids) => (dispatch) => {
//   dispatch(controlAction.setLoading(true))
//   return productAPI
//     .deleteMany(ids)
//     .then(() => {
//       alertSuccess('Successful delete product')
//       dispatch(actions.deleteMany({ids}))
//       dispatch(controlAction.setLoading(false))
//     })
//     .catch((error) => {
//       error.clientMessage = "Can't delete products"
//       alertError(error.clientMessage)
//       dispatch(actions.catchError({error, callType: callTypes.action}))
//       dispatch(controlAction.setLoading(false))
//     })
// }
