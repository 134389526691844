import StudentRoleDataSource from '../../_data/dataSource/StudentRoleDataSoruce'
import {BaseDatatableRequest} from '../../_data/dataSource/request/BaseDatatableRequest'
import {
  GroupRoleCreateRequest,
  StudenRoleAppendRequest,
} from '../../_data/dataSource/request/StudentRoleRequest'
import {BaseDatatableResponse} from '../../_data/dataSource/response/BaseDatatableResponse'
import {
  StudentGroupRole,
  StudentGroupRoleBuilder,
  StudentRole,
  StudentRoleBuilder,
} from '../model/StudentRole'
import {StudentRoleRepository} from './StudentRoleRepository'

export class StudentRoleRepositoryImpl implements StudentRoleRepository {
  datasource: StudentRoleDataSource

  constructor(datasource: StudentRoleDataSource) {
    this.datasource = datasource
  }

  async changeGroupRole(user_id: number, group_id: string): Promise<StudentRole> {
    const role = await this.datasource.changeGroupRole(user_id, group_id)
    const response = new StudentRoleBuilder().SingleData(role).Result()
    return response
  }

  async getAll(params?: any): Promise<StudentGroupRole[]> {
    const response = await this.datasource.getAllGroupRole()
    const initBuilder = new StudentGroupRoleBuilder()
    const responses = initBuilder.ListData(response).ResultList()
    return responses
  }

  async updateGroupRole(id: string, req: GroupRoleCreateRequest): Promise<StudentGroupRole> {
    const role = await this.datasource.updateGroupRole(id, req)
    const response = new StudentGroupRoleBuilder().SingleData(role).Result()
    return response
  }

  async getOneGroupRole(id: string): Promise<StudentGroupRole> {
    const role = await this.datasource.getOneGroupRole(id)
    const response = new StudentGroupRoleBuilder().SingleData(role).Result()
    return response
  }

  async createGroupRole(req: GroupRoleCreateRequest): Promise<StudentGroupRole> {
    const role = await this.datasource.createGroupRole(req)
    const response = new StudentGroupRoleBuilder().SingleData(role).Result()
    return response
  }

  async getDatatable(req: BaseDatatableRequest): Promise<BaseDatatableResponse<StudentGroupRole>> {
    const data = await this.datasource.getDatatable(req)
    const initBuilder = new StudentGroupRoleBuilder()
    const entities = initBuilder.ListData(data.entities).ResultList()
    const totalCount = data.totalCount
    return {
      entities,
      totalCount,
      errorMessage: data.errorMessage,
    }
  }

  async updateRole(user_id: number, req: StudenRoleAppendRequest): Promise<StudentRole> {
    const role = await this.datasource.updateRole(user_id, req)
    const response = new StudentRoleBuilder().SingleData(role).Result()
    return response
  }

  async createRole(req: StudenRoleAppendRequest): Promise<StudentRole> {
    const role = await this.datasource.createRole(req)
    const response = new StudentRoleBuilder().SingleData(role).Result()
    return response
  }

  async getOneByUserId(user_id: number): Promise<StudentRole> {
    const role = await this.datasource.getOneByUserId(user_id)
    const response = new StudentRoleBuilder().SingleData(role).Result()
    return response
  }
}
