import axios from 'axios'
import {ScheduleResponse} from './response/ScheduleResponse'
import {ApiResponse} from './response/BaseResponse'
import {BaseDatatableRequest} from './request/BaseDatatableRequest'
import {BaseDatatableResponse} from './response/BaseDatatableResponse'
import SchedulePostTestDataSource from './SchedulePostTestDataSoruce'
import {MessageResponse} from './response/MessageResponse'
import {
  SchedulePostTestCreateRequest,
  SchedulePostTestUpdateRequest,
} from './request/SchedulePostTestRequest'

export default class SchedulePostTestDataSourceImpl implements SchedulePostTestDataSource {
  private POST_TEST_URL: string

  constructor() {
    const service_schedule_url = process.env.REACT_APP_SCHEDULE_SERVICE_URL || 'api/'
    this.POST_TEST_URL = `${service_schedule_url}schedules`
  }

  async uploadQuestions(id: string, file: any): Promise<void> {
    const payload = new FormData()
    payload.append('file', file)
    axios.put<ApiResponse<void>>(
      `${this.POST_TEST_URL}/${id}/file-questions?type_schedule=post_test`,
      payload,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
  }

  async getDatatable(req: BaseDatatableRequest): Promise<BaseDatatableResponse<ScheduleResponse>> {
    const response = await axios.post<ApiResponse<BaseDatatableResponse<ScheduleResponse>>>(
      `${this.POST_TEST_URL}/datatable?type_schedule=post_test`,
      req
    )

    const data = response.data.data
    return data
  }

  getAll(params?: any): Promise<ScheduleResponse[]> {
    throw new Error('Method not implemented.')
  }

  async getOne(id: string | number): Promise<ScheduleResponse> {
    const response = await axios.get<ApiResponse<ScheduleResponse>>(`${this.POST_TEST_URL}/${id}`)

    return response.data.data
  }

  async deleteOne(id: string | number): Promise<string> {
    const response = await axios.delete<MessageResponse>(
      `${this.POST_TEST_URL}/${id}?type_schedule=post_test`
    )
    return response.data.message
  }

  async create(req: SchedulePostTestCreateRequest): Promise<ScheduleResponse> {
    const response = await axios.post<ApiResponse<ScheduleResponse>>(
      `${this.POST_TEST_URL}?type_schedule=post_test`,
      req
    )

    return response.data.data
  }

  async update(
    req: SchedulePostTestUpdateRequest,
    id?: string | number
  ): Promise<ScheduleResponse> {
    const response = await axios.put<ApiResponse<ScheduleResponse>>(
      `${this.POST_TEST_URL}/${id}?type_schedule=post_test`,
      req
    )

    return response.data.data
  }
}
