import {saveObjectRequest} from '../../_data/dataSource/request/StorageRequest'

import {StorageSaveObjectResponse} from '../../_data/dataSource/response/StorageResponse'

import {StorageRepository} from '../repository/StorageRepository'

export interface StorageService {
  saveObjectService(req: saveObjectRequest): Promise<StorageSaveObjectResponse | undefined>
}

export class StorageServiceImpl implements StorageService {
  private repo: StorageRepository
  constructor(repo: StorageRepository) {
    this.repo = repo
  }

  async saveObjectService(body: saveObjectRequest): Promise<StorageSaveObjectResponse | undefined> {
    try {
      const response = await this.repo.saveObject(body)

      if (response) {
        return response
      } else {
        return undefined
      }
    } catch {
      console.error('an Error Occured')
    }
  }
}
