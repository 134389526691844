import {ReactNode, createContext, useCallback, useContext, useEffect, useState} from 'react'
import {Chapter} from '../_domain/model/Chapter'
import ChapterDataSourceImpl from '../_data/dataSource/ChapterDataSourceImpl'
import {
  ChapterFilterRequest,
  ChapterListNumberRequest,
} from '../_data/dataSource/request/ChapterRequest'
import {ChapterRepositoryImpl} from '../_domain/repository/ChapterRepositoryImpl'
import {CLASS_STUDENT} from '../helpers/UIConstanta'

type ChapterContextType = {
  listNumber: number[]
  getListNumberAvailable: (
    classIndex?: number,
    subject_id?: string,
    semester?: number,
    current_number?: number
  ) => {}
  listChapter: Chapter[]
  getListChapterFilter: (
    classIndex?: number,
    semester?: number,
    subject_id?: string,
    type_chapter?: string
  ) => {}
}

type props = {
  children: ReactNode
}

const ChapterUIContext = createContext<ChapterContextType>({
  listNumber: [],
  listChapter: [],
  getListChapterFilter: function (
    classIndex?: number | undefined,
    semester?: number | undefined,
    subject_id?: string | undefined,
    type_chapter?: string | undefined
  ): {} {
    throw new Error('Function not implemented.')
  },
  getListNumberAvailable: function (
    classIndex?: number | undefined,
    subject_id?: string | undefined,
    semester?: number | undefined,
    current_number?: number | undefined
  ): {} {
    throw new Error('Function not implemented.')
  },
})

export function useChapterUIContext() {
  return useContext(ChapterUIContext)
}

export const ChapterUIConsumer = ChapterUIContext.Consumer

export function ChapterUIProvider({children}: props) {
  const [listNumber, setListNumber] = useState<number[]>([])
  const [listChapter, setListChapter] = useState<Chapter[]>([])

  const dataSource = new ChapterDataSourceImpl()
  const repo = new ChapterRepositoryImpl(dataSource)

  const getListNumberAvailable = useCallback(
    async (
      classIndex?: number,
      subject_id?: string,
      semester?: number,
      current_number?: number
    ) => {
      if (classIndex && subject_id && semester) {
        const {major, grade} = CLASS_STUDENT[classIndex - 1]
        const params: ChapterListNumberRequest = {
          major: major,
          grade: grade === 'ALUMNI' ? 13 : +grade,
          subject_id: subject_id,
          semester: semester,
        }

        let response = await dataSource.getAvailableNumber(params)
        if (current_number) {
          response.unshift(current_number)
        }
        setListNumber(response)
      }
    },
    [setListNumber]
  )

  const getListChapterFilter = useCallback(
    async (classIndex?: number, semester?: number, subject_id?: string, type_chapter?: string) => {
      if (classIndex && semester && subject_id) {
        const {major, grade} = CLASS_STUDENT[classIndex - 1]
        const params: ChapterFilterRequest = {
          major: major,
          grade: grade === 'ALUMNI' ? 13 : +grade,
          subject_id: subject_id,
          semester: semester,
          type_chapter: type_chapter,
        }
        const response = await repo.getAll(params)
        setListChapter(response)
      }
    },
    [setListChapter]
  )

  const value: any = {
    listNumber,
    getListNumberAvailable,
    listChapter,
    getListChapterFilter,
  }
  return <ChapterUIContext.Provider value={value}>{children}</ChapterUIContext.Provider>
}
