export type BaseDatatableRequest = {
  filter: any
  sortOrder: string
  sortField: string
  pageNumber: number
  pageSize: number
  key?: number | string
}

export function defaultQueryParams(): BaseDatatableRequest {
  return {
    filter: {},
    sortOrder: 'asc',
    sortField: 'id',
    pageNumber: 1,
    pageSize: 100,
  }
}

export function prepareFilterBase(queryParams: BaseDatatableRequest, values: any) {
  const newQueryParams = {...queryParams}
  if (Object.values(values).length > 0) {
    newQueryParams.filter = {...newQueryParams.filter, ...values}
  } else {
    newQueryParams.filter = values
  }
  return newQueryParams
}
