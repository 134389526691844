import FacilityDataSource from '../../_data/dataSource/FacilityDataSoruce'
import {BaseDatatableRequest} from '../../_data/dataSource/request/BaseDatatableRequest'
import {
  FacilityCreateRequest,
  FacilityUpdateRequest,
} from '../../_data/dataSource/request/FacilityRequest'
import {BaseDatatableResponse} from '../../_data/dataSource/response/BaseDatatableResponse'
import {Facility, FacilityBuilder} from '../model/Facility'
import {FacilityRepository} from './FacilityRepository'

export class FacilityRepositoryImpl implements FacilityRepository {
  datasource: FacilityDataSource
  constructor(datasource: FacilityDataSource) {
    this.datasource = datasource
  }

  async updateOne(req: FacilityUpdateRequest, id?: string): Promise<Facility> {
    const response = await this.datasource.update(req, id)
    const initBuilder = new FacilityBuilder()
    const facility = initBuilder.SingleData(response).Result()
    return facility
  }

  async createOne(req: FacilityCreateRequest): Promise<Facility> {
    const response = await this.datasource.create(req)
    const initBuilder = new FacilityBuilder()
    const facility = initBuilder.SingleData(response).Result()
    return facility
  }

  async deleteOneById(id: string): Promise<string> {
    return this.datasource.deleteOne(id)
  }

  async getDatatable(req: BaseDatatableRequest): Promise<BaseDatatableResponse<Facility>> {
    const data = await this.datasource.getDatatable(req)
    const initBuilder = new FacilityBuilder()
    const entities = initBuilder.ListData(data.entities).ResultList()
    const totalCount = data.totalCount
    return {
      entities,
      totalCount,
      errorMessage: data.errorMessage,
    }
  }

  async getOneById(id: string): Promise<Facility> {
    const resposne = await this.datasource.getOne(id)
    const initBuilder = new FacilityBuilder()
    const facility = initBuilder.SingleData(resposne).Result()
    return facility
  }

  async getAll(): Promise<Facility[]> {
    const response = await this.datasource.getAll()
    const initBuilder = new FacilityBuilder()
    const facilities = initBuilder.ListData(response).ResultList()
    return facilities
  }
}
