import {SeriesResponse} from '../../_data/dataSource/response/SeriesResponse'

export type Series = {
  id: string
  name: string
  description?: string
}

export class SeriesBuilder {
  private SingleSeries!: Series
  private SeriesList!: Series[]
  constructor() {}

  SingleData(seriesResponse: SeriesResponse): SeriesBuilder {
    const series = mapingSeries(seriesResponse)
    this.SingleSeries = series
    return this
  }
  ListData(seriesResponse: SeriesResponse[]): SeriesBuilder {
    const seriess = mapingSeriess(seriesResponse)
    this.SeriesList = seriess
    return this
  }
  Result(): Series {
    return this.SingleSeries
  }
  ResultList(): Series[] {
    return this.SeriesList
  }
}

function mapingSeries(seriesResponse: SeriesResponse): Series {
  const series: Series = {
    id: seriesResponse._id,
    name: seriesResponse.name,
    description: seriesResponse.description,
  }
  return series
}

function mapingSeriess(seriessResponse: SeriesResponse[]): Series[] {
  const seriess = seriessResponse.map((item) => {
    return mapingSeries(item)
  })
  return seriess
}
