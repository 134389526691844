import {BaseDatatableRequest} from '../../_data/dataSource/request/BaseDatatableRequest'
import {
  mappingInputFormToPartnerCreateRequest,
  mappingInputFormToPartnerUpdateRequest,
} from '../../_data/dataSource/request/PartnerRequest'
import {BaseDatatableResponse} from '../../_data/dataSource/response/BaseDatatableResponse'
import {PartnerFormInput} from '../../helpers/UIPartnerConstanta'
import {Partner} from '../model/Partner'
import {PartnerRepository} from '../repository/PartnerRepository'

export interface PartnerService {
  getDatatablePartner(req: BaseDatatableRequest): Promise<BaseDatatableResponse<Partner>>
  getAllPartner(): Promise<Partner[]>
  getDetailPartnerByUserId(userId: string): Promise<Partner>
  deleteOnePartnerByID(id: string): Promise<string>
  createOnePartner(req: PartnerFormInput): Promise<Partner>
  updateOnePartner(req: PartnerFormInput, id: string): Promise<Partner>
}

export class PartnerServiceImpl implements PartnerService {
  private repo: PartnerRepository
  constructor(repo: PartnerRepository) {
    this.repo = repo
  }

  async updateOnePartner(req: PartnerFormInput, id: string): Promise<Partner> {
    const body = mappingInputFormToPartnerUpdateRequest(req)
    const partner = await this.repo.updateOne(body, id)
    return partner
  }

  async createOnePartner(req: PartnerFormInput): Promise<Partner> {
    const body = mappingInputFormToPartnerCreateRequest(req)
    const partner = await this.repo.createOne(body)
    return partner
  }

  async deleteOnePartnerByID(id: string): Promise<string> {
    const message = await this.repo.deleteOneById(id)
    return message
  }

  async getDatatablePartner(req: BaseDatatableRequest): Promise<BaseDatatableResponse<Partner>> {
    const partners = await this.repo.getDatatable(req)
    return partners
  }

  async getDetailPartnerByUserId(userId: string): Promise<Partner> {
    const partner = await this.repo.getOneByUserId(userId)
    return partner
  }

  async getAllPartner(): Promise<Partner[]> {
    const partners = await this.repo.getAll()
    return partners
  }
}
