import GroupClassDataSource from '../../_data/dataSource/GroupClassDataSoruce'
import {BaseDatatableRequest} from '../../_data/dataSource/request/BaseDatatableRequest'
import {
  GroupClassCreateRequest,
  GroupClassUpdateRequest,
} from '../../_data/dataSource/request/GroupClassRequest'
import {BaseDatatableResponse} from '../../_data/dataSource/response/BaseDatatableResponse'
import {GroupClass, GroupClassBuilder} from '../model/GroupClass'
import {Student, StudentBuilder} from '../model/Student'
import {GroupClassRepository} from './GroupClassRepository'

export class GroupClassRepositoryImpl implements GroupClassRepository {
  datasource: GroupClassDataSource
  constructor(datasource: GroupClassDataSource) {
    this.datasource = datasource
  }

  async getDatatableSearchStudent(
    req: BaseDatatableRequest,
    id?: number
  ): Promise<BaseDatatableResponse<Student>> {
    const data = await this.datasource.getDatatableSearchStudent(req, id)
    const initBuilder = new StudentBuilder()
    const entities = initBuilder.ListData(data.entities).ResultList()
    const totalCount = data.totalCount
    return {
      entities,
      totalCount,
      errorMessage: data.errorMessage,
    }
  }

  async getAllFilter(params: any[]): Promise<GroupClass[]> {
    const data = await this.datasource.getAllFilter(params)
    const initBuilder = new GroupClassBuilder()
    const list = initBuilder.ListData(data).ResultList()
    return list
  }

  async updateOne(req: GroupClassUpdateRequest, id?: string): Promise<GroupClass> {
    const response = await this.datasource.update(req, id)
    const initBuilder = new GroupClassBuilder()
    const groupClass = initBuilder.SingleData(response).Result()
    return groupClass
  }

  async createOne(req: GroupClassCreateRequest): Promise<GroupClass> {
    const response = await this.datasource.create(req)
    const initBuilder = new GroupClassBuilder()
    const groupClass = initBuilder.SingleData(response).Result()
    return groupClass
  }

  deleteOneById(id: string): Promise<string> {
    return this.datasource.deleteOne(id)
  }

  async getDatatable(req: BaseDatatableRequest): Promise<BaseDatatableResponse<GroupClass>> {
    const data = await this.datasource.getDatatable(req)
    const initBuilder = new GroupClassBuilder()
    const entities = initBuilder.ListData(data.entities).ResultList()
    const totalCount = data.totalCount
    return {
      entities,
      totalCount,
      errorMessage: data.errorMessage,
    }
  }

  async getOneById(id: string): Promise<GroupClass> {
    const resposne = await this.datasource.getOne(id)
    const initBuilder = new GroupClassBuilder()
    const groupClass = initBuilder.SingleData(resposne).Result()
        
    return groupClass
  }

  async getAll(params?: any): Promise<GroupClass[]> {
    const response = await this.datasource.getAll(params)
    const initBuilder = new GroupClassBuilder()
    const groupClasses = initBuilder.ListData(response).ResultList()
    return groupClasses
  }
}
