import {BaseDatatableRequest} from '../../_data/dataSource/request/BaseDatatableRequest'
import {
  OrderCreateRequest,
  OrderEditInstallmentRequest,
  OrderEditStatusInstallmentRequest,
  OrderUpdateRequest,
  mappingInputFormToOrderCreateRequest,
  mappingInputFormToOrderUpdateRequest,
} from '../../_data/dataSource/request/OrderRequest'
import {BaseDatatableResponse} from '../../_data/dataSource/response/BaseDatatableResponse'
import {currencyToNumberFormat} from '../../helpers/FuncHelpers'
import {
  OrderCreateFormInput,
  OrderEditFormInput,
  OrderEditInstallmentFormInput,
  OrderEditStatusInstallmentFormInput,
} from '../../helpers/UIOrderConstanta'
import {Order} from '../model/Order'
import {OrderRepository} from '../repository/OrderRepository'

export interface OrderService {
  getDatatableOrder(req: BaseDatatableRequest): Promise<BaseDatatableResponse<Order>>
  getAllOrder(): Promise<Order[]>
  getOneOrder(id: string): Promise<Order>
  editStatusInstallmentOrder(
    req: OrderEditStatusInstallmentFormInput,
    order_id: string
  ): Promise<Order>
  createOneOrder(req: OrderCreateFormInput): Promise<Order>
  updateOneOrder(req: OrderEditFormInput, id: string): Promise<Order>
  editInstallmentOrder(req: OrderEditInstallmentFormInput, order_id: string): Promise<Order>
  deleteInstallmentOrder(id: string, order_id: string): Promise<Order>
  cancelInstallmentOrder(id: string, order_id: string): Promise<Order>
  deleteOneOrderByID(id: string): Promise<string>
}

export class OrderServiceImpl implements OrderService {
  private repo: OrderRepository
  constructor(repo: OrderRepository) {
    this.repo = repo
  }

  async cancelInstallmentOrder(id: string, order_id: string): Promise<Order> {
    return await this.repo.cancelInstallmentOrder(id, order_id)
  }
  async deleteOneOrderByID(id: string): Promise<string> {
    const message = await this.repo.deleteOneById(id)
    return message
  }

  async createOneOrder(req: OrderCreateFormInput): Promise<Order> {
    const body: OrderCreateRequest = mappingInputFormToOrderCreateRequest(req)
    return await this.repo.createOne(body)
  }

  async getOneOrder(id: string): Promise<Order> {
    return await this.repo.getOneById(id)
  }

  async updateOneOrder(req: OrderEditFormInput, id: string): Promise<Order> {
    const body: OrderUpdateRequest = mappingInputFormToOrderUpdateRequest(req)
    return await this.repo.updateOne(body, id)
  }

  async editStatusInstallmentOrder(
    req: OrderEditStatusInstallmentFormInput,
    order_id: string
  ): Promise<Order> {
    const body: OrderEditStatusInstallmentRequest = {
      installment_id: req.installmentIdInput,
      status: req.statusInput,
    }
    return await this.repo.editStatusInstallmentOrder(body, order_id)
  }
  async deleteInstallmentOrder(id: string, order_id: string): Promise<Order> {
    return await this.repo.deleteInstallmentOrder(id, order_id)
  }

  async editInstallmentOrder(req: OrderEditInstallmentFormInput, order_id: string): Promise<Order> {
    const body: OrderEditInstallmentRequest = {
      installment_id: req.installmentIdInput,
      amount: currencyToNumberFormat(req.amountInput),
    }
    return await this.repo.editInstallmentOrder(body, order_id)
  }

  async getDatatableOrder(req: BaseDatatableRequest): Promise<BaseDatatableResponse<Order>> {
    const orders = await this.repo.getDatatable(req)
    return orders
  }

  async getAllOrder(): Promise<Order[]> {
    const orders = await this.repo.getAll()
    return orders
  }
}
