/* eslint-disable jsx-a11y/anchor-is-valid */
import { Outlet, Route, Routes } from 'react-router'
import { PageTitle } from '../../../../../_layout/core'
import { AuthGroupList } from './AuthGroupList'
import { deleteSectionContext, queryParamsContext } from './_outletContext'
import AuthGroupCreate from './AuthGroupCreate'
import AuthGroupEdit from './AuthGroupEdit'


const AuthGroupContent = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>{'Authorization Group'}</PageTitle>
      <Outlet context={{ queryParams: queryParamsContext(), deleteSection: deleteSectionContext() }} />
    </>
  )
}

const AuthGroup = () => {
  return (
    <>
      <Routes>
        <Route element={<AuthGroupContent />}>
          <Route index element={<AuthGroupList />} />
          <Route path='create' element={<AuthGroupCreate />} />
          <Route path='edit/:authGroupId' element={<AuthGroupEdit />} />
        </Route>
      </Routes>
    </>
  )
}

export { AuthGroup }
