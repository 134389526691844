const TEAM_DELETE_TITLE = 'Team Delete'
const TEAM_LIST_TITLE = 'Team List'
const TEAM_DELETE_LOADING = 'Team is deleting...'
const TEAM_DELETE_CONFIRM = 'Are you sure to permanently delete this team ?'
const TEAM_DELETE_SUCCESS = 'Successful delete team'
const TEAM_DELETE_FAILED = "Can't delete team"
const TEAM_CREATE_TITLE = 'Team Create'
const TEAM_CREATE_FAILED = "Can't create team"
const TEAM_CREATE_SUCCESS = 'Successful create team'
const TEAM_EDIT_TITLE = 'Team Edit'
const TEAM_CUSTOM_AUTH_TITLE = 'Team Custom Authorization Role'
const TEAM_EXTEND_ROLE_TITLE = 'Team Extend Role'
const TEAM_EDIT_SUCCESS = 'Successful update team'
const TEAM_EDIT_FAILED = "Can't update team"
const TEAM_GET_FAILED = "Can't get team"
export {
  TEAM_LIST_TITLE,
  TEAM_DELETE_TITLE,
  TEAM_DELETE_CONFIRM,
  TEAM_DELETE_LOADING,
  TEAM_DELETE_SUCCESS,
  TEAM_DELETE_FAILED,
  TEAM_CREATE_TITLE,
  TEAM_CREATE_SUCCESS,
  TEAM_CREATE_FAILED,
  TEAM_EDIT_TITLE,
  TEAM_EDIT_SUCCESS,
  TEAM_EDIT_FAILED,
  TEAM_GET_FAILED,
  TEAM_CUSTOM_AUTH_TITLE,
  TEAM_EXTEND_ROLE_TITLE
}
