import { FC } from 'react'
import BaseTable from '../BaseTable'
import { Student } from '../../../_domain/model/Student'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { toAbsoluteUrl } from '../../../_layout/helpers'
import SVG from 'react-inlinesvg'

type Props = {
  entities: Student[]
  totalCount: number,
  isDeleteAction?: boolean
  deleteAction?: (id: number) => void
}

const StudentSelectedTable: FC<Props> = ({ entities, totalCount, isDeleteAction, deleteAction }) => {

  let mutableTableData = [...entities ?? []]

  let columns = [
    {
      name: 'Name',
      selector: (row: Student) => row.name,
      // sortable: true,
    },
    {
      name: 'Id Qonstanta',
      selector: (row: Student) => row.id_qonstanta,
    },
    {
      name: 'Phone Number',
      selector: (row: Student) => row.phone_number,
    },
    {
      name: 'Gender',
      selector: (row: Student) => row.gender,
    },
    {
      name: 'Class',
      selector: (row: Student) => {
        return (
          <p>
            {row.grade}-{row.major}
          </p>
        )
      },
    },
    {
      name: isDeleteAction ? "Action" : "",
      width: '100px',
      // selector: (row: any) => (row.actionButton),
      cell: (row: Student) => {
        if (isDeleteAction) {
          return (
            <>
              <OverlayTrigger
                overlay={<Tooltip id='liveClass-edit-tooltip'>Delete this Student ?</Tooltip>}
              >
                <button
                  type='button'
                  onClick={() => {
                    if (deleteAction && confirm("Are you sure to delete this ?")) {
                      deleteAction(row.id);
                    }
                  }}
                  className='btn btn-icon btn-danger btn-sm mx-3'
                >
                  <span className='svg-icon svg-icon-md svg-icon-primary'>
                    <SVG src={toAbsoluteUrl('/media/svg/delete.svg')} />
                  </span>
                </button>
              </OverlayTrigger>
            </>
          )
        }
      }
    }
  ]

  // Use the state and functions returned from useTable to build your UI

  return (
    <div className='customized-datatable-style'>
      <BaseTable
        columns={columns}
        data={mutableTableData}
        countPerPage={100}
        totalRows={totalCount}
        selectableRows={false}
      />
    </div>
  )
}

export default StudentSelectedTable
