/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { PageTitle } from '../../../../../_layout/core'
import { getAllGroupClassDatatable } from './GroupClassAction'
import { Suspense, lazy } from 'react'
import CustomLoading from '../../../../../_component/customLoading'
import { useOutletContext } from 'react-router-dom'
import { BaseDatatableResponse } from '../../../../../_data/dataSource/response/BaseDatatableResponse'
import { useAuth } from '../../../../../_context/AuthContext'
import { checkAuthActionMenu } from '../../../../../helpers/AuthHelpers'
import { SLUG_MASTER_SUB_MENU } from '../../../../../helpers/UIAuthGroupConstanta'
import { GroupClass } from '../../../../../_domain/model/GroupClass'

const GroupClassListTable = React.lazy(() => import('./GroupClassListComponent/GroupClassListTable'))

const checkReduxData = () => {
  const outletContext = useOutletContext<any>().queryParams
  const { queryParams } = outletContext

  const [groupClassListData, setGroupClassListData] = useState<any>(null)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch<any>(getAllGroupClassDatatable(queryParams)).then((response: BaseDatatableResponse<GroupClass>) => {
      setGroupClassListData(response.entities)
    })
  }, [queryParams, getAllGroupClassDatatable])

  return groupClassListData
  // detect redux first before fetch API END
}

const GroupClassList = () => {
  let data = checkReduxData()
  const { currentUser, authorization } = useAuth()
  return checkAuthActionMenu(SLUG_MASTER_SUB_MENU.MASTER_GROUP_ROLE_MENU, "view", currentUser, authorization) ? (
    <>
      <PageTitle breadcrumbs={[]}>{'Student Group Class List'}</PageTitle>
      <Suspense fallback={<CustomLoading />}>
        {data ? <GroupClassListTable /> : null}
      </Suspense>
    </>

  ) : null
}

export { GroupClassList }
