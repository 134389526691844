import {all} from 'redux-saga/effects'
import {combineReducers} from 'redux'

import {
  tryoutQuestion,
  question,
  scheduleTO,
  chapter,
  facilities,
  promos,
  subject,
  studentClass,
  products,
  liveClass,
  // classGroup,
  teacher,
  team,
  student,
  userStudent,
  schedule,
  schedulePostTest,
  parents,
  order,
  postTest,
  bankQuestion,
  customQuestion,
  scheduleCustomTest,
  series,
  typeTO,
  school,
  result,
  banner,
  newRegistrant,
  authGroup,
  groupRole,
  control,
  partners,
  groupClass,
} from '../_redux_slice/index'

export const rootReducer = combineReducers({
  subjects: subject.slice.reducer,
  banners: banner.slice.reducer,
  newRegistrant: newRegistrant.slice.reducer,
  typeTOs: typeTO.slice.reducer,
  tryoutQuestion: tryoutQuestion.slice.reducer,
  series: series.slice.reducer,
  products: products.slice.reducer,
  liveClass: liveClass.slice.reducer,
  studentClass: studentClass.slice.reducer,
  teachers: teacher.slice.reducer,
  student: student.slice.reducer,
  chapter: chapter.slice.reducer,
  question: question.slice.reducer,
  authGroup: authGroup.slice.reducer,
  teams: team.slice.reducer,
  partner: partners.slice.reducer,
  groupClass: groupClass.slice.reducer,
  groupRole: groupRole.slice.reducer,
  userStudent: userStudent.slice.reducer,
  schedule: schedule.slice.reducer,
  schedulePostTest: schedulePostTest.slice.reducer,
  tryoutSchedule: scheduleTO.slice.reducer,
  parent: parents.slice.reducer,
  facilities: facilities.slice.reducer,
  promo: promos.slice.reducer,
  orders: order.slice.reducer,
  postTest: postTest.slice.reducer,
  bankQuestion: bankQuestion.slice.reducer,
  customQuestion: customQuestion.slice.reducer,
  scheduleCustomTest: scheduleCustomTest.slice.reducer,
  schools: school.slice.reducer,
  results: result.slice.reducer,
  control: control.slice.reducer,
})

export function* rootSaga() {
  yield all([])
}
