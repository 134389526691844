import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { getCSSVariableValue } from '../../_layout/assets/ts/_utils'
import { WithChildren } from '../../_layout/helpers'
import { Suspense, FC, lazy } from 'react'
import { TryoutWrapper } from '../pages/tryout/TryoutWrapper'
import { MasterWrapper } from '../pages/master/MasterWrapper'
import { TransactionWrapper } from '../pages/transaction/TransactionWrapper'
import { EduLiveWrapper } from '../pages/edulive/EduLiveWrapper'
import { useAuth } from '../../_context/AuthContext'
import { checkAuthLinkMenu } from '../../helpers/AuthHelpers'
import { SLUG_MENU } from '../../helpers/UIAuthGroupConstanta'


const PrivateRoutes = () => {
  const { currentUser, authorization } = useAuth()
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        {checkAuthLinkMenu(SLUG_MENU.MASTER_MENU, currentUser, authorization) && (
          <Route path='master/*' element={<MasterWrapper />} />
        )}
        {checkAuthLinkMenu(SLUG_MENU.TRYOUT_MENU, currentUser, authorization) && (
          <Route path='tryout/*' element={<TryoutWrapper />} />
        )}
        {checkAuthLinkMenu(SLUG_MENU.EDULIVE_MENU, currentUser, authorization) && (
          <Route path='edulive/*' element={<EduLiveWrapper />} />
        )}
        {checkAuthLinkMenu(SLUG_MENU.TRANSACTION_MENU, currentUser, authorization) && (
          <Route path='transaction/*' element={<TransactionWrapper />} />
        )}

        {/* <Route path='menu-test' element={<MenuTestPage />} /> */}
        {/* Lazy Modules */}
        {/* <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <TryoutWrapper />
            </SuspensedView>
          }
        /> */}
        {/* <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        /> */}
        {/* <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        /> */}
        {/* <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        /> */}
        {/* <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        /> */}
        {/* <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        /> */}
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
