import {AuthGroupFormInput} from '../../../helpers/UIAuthGroupConstanta'

export type AuthGroupCreateRequest = {
  name: string
  authorization: any
  main_role: string
}

export type AuthGroupUpdateRequest = {
  name: string
  authorization: any
  main_role: string
}

export function mappingInputFormToAuthGroupCreateRequest(
  req: AuthGroupFormInput
): AuthGroupCreateRequest {
  return {
    name: req.nameInput ?? '',
    authorization: req.authorizationInput,
    main_role: req.roleInput ?? '',
  }
}

export function mappingInputFormToAuthGroupUpdateRequest(
  req: AuthGroupFormInput
): AuthGroupUpdateRequest {
  return {
    name: req.nameInput ?? '',
    authorization: req.authorizationInput,
    main_role: req.roleInput ?? '',
  }
}
