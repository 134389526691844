import axios from 'axios'
import AuthDataSource from './AuthDataSoruce'
import {AuthLoginRequest, UserReferraRequest, UserUpdateRequest} from './request/AuthRequest'
import {ApiResponse} from './response/BaseResponse'
import {StudentResponse, UploadBatchResponse} from './response/StudentResponse'
import {UploadSelectedResponse, UserResponse} from './response/UserResponse'
import {BaseDatatableRequest} from './request/BaseDatatableRequest'
import {BaseDatatableResponse} from './response/BaseDatatableResponse'
import {MessageResponse} from './response/MessageResponse'
import fileDownload from 'js-file-download'

export default class AuthDataSourceImpl implements AuthDataSource {
  private USER_URL: string
  private BASE_URL: string

  constructor() {
    const service_user_url = process.env.REACT_APP_USER_SERVICE_URL || 'api/'
    const service_base_url = process.env.REACT_APP_BASE_SERVICE_URL || 'api/'
    this.USER_URL = `${service_user_url}users`
    this.BASE_URL = `${service_base_url}api`
  }

  async editRole(id: string | number, req: any): Promise<UserResponse> {
    const response = await axios.put<ApiResponse<UserResponse>>(`${this.USER_URL}/${id}/role`, req)

    const data = response.data.data

    return data
  }

  async resetPasswordMany(user_ids: number[]): Promise<string> {
    const response = await axios.put<MessageResponse>(`${this.USER_URL}/reset-password/many`, {
      user_ids: user_ids,
    })
    return response.data.message
  }
  exportExcel(param?: string): void {
    axios
      .get<any>(`${this.USER_URL}/referral?referral_id=${param}&export=true`, {
        responseType: 'blob',
      })
      .then((res) => {
        const contentDispositionHeader = res.headers['content-disposition']

        const matches = /filename="(.*)"/.exec(contentDispositionHeader)
        const fileName = matches ? matches[1] : 'referral.xlsx'

        fileDownload(res.data, fileName)
      })
  }

  async updateUserReferral(id: string | number, req: UserReferraRequest): Promise<UserResponse> {
    const response = await axios.put<ApiResponse<UserResponse>>(
      `${this.USER_URL}/${id}/referral`,
      req
    )

    const data = response.data.data

    return data
  }

  async createUserReferral(req: UserReferraRequest): Promise<UserResponse> {
    const response = await axios.post<ApiResponse<UserResponse>>(`${this.USER_URL}/referral`, req)

    const data = response.data.data

    return data
  }

  async updateOne(id: number, req: Partial<UserUpdateRequest>): Promise<UserResponse> {
    const response = await axios.put<ApiResponse<UserResponse>>(`${this.USER_URL}/${id}`, req)

    const data = response.data.data

    return data
  }

  async getDatatable(req: BaseDatatableRequest): Promise<BaseDatatableResponse<UserResponse>> {
    const response = await axios.post<ApiResponse<BaseDatatableResponse<UserResponse>>>(
      `${this.USER_URL}/datatable`,
      req
    )

    const data = response.data.data

    return data
  }
  async uploadBatch(file: any): Promise<UploadBatchResponse> {
    const payload = new FormData()
    payload.append('file', file)
    const response = await axios.post<ApiResponse<UploadBatchResponse>>(
      `${this.USER_URL}/batch-trial-tryout`,
      payload,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    return response.data.data
  }
  async uploadSelected(file: any): Promise<{students: StudentResponse[]; reject: any[]}> {
    const payload = new FormData()
    payload.append('file', file)
    const response = await axios.post<ApiResponse<UploadSelectedResponse>>(
      `${this.USER_URL}/selected`,
      payload,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    const {students, reject} = response.data.data

    return {students, reject}
  }
  async getOneByUserId(id: string | number): Promise<any> {
    const response = await axios.get<ApiResponse<any>>(`${this.USER_URL}/${id}`)
    return response.data.data
  }
  async login(params: AuthLoginRequest): Promise<any> {
    const response = await axios.post<ApiResponse<any>>(`${this.BASE_URL}/login`, params)
    return response.data.data
  }
}
