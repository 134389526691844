import axios from 'axios'
import {TeacherResponse} from './response/TeacherResponse'
import {ApiResponse} from './response/BaseResponse'
import {BaseDatatableRequest} from './request/BaseDatatableRequest'
import {BaseDatatableResponse} from './response/BaseDatatableResponse'
import {MessageResponse} from './response/MessageResponse'
// import {TeacherCreateRequest, TeacherGetAllParams, TeacherUpdateRequest} from './request/TeacherRequest'
import TeacherDataSource from './TeacherDataSoruce'

export default class TeacherDataSourceImpl implements TeacherDataSource {
  private TEACHER_URL: string

  constructor() {
    const service_student_url = process.env.REACT_APP_TEACHER_SERVICE_URL || 'api/'
    this.TEACHER_URL = `${service_student_url}teachers`
  }

  async uploadPhoto(id: string, file: any): Promise<void> {
    const payload = new FormData()
    payload.append('image', file)
    axios.put<ApiResponse<void>>(`${this.TEACHER_URL}/${id}/photo`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    // return response.data.data
  }

  async update(req: any, id: string): Promise<TeacherResponse> {
    const response = await axios.put<ApiResponse<TeacherResponse>>(`${this.TEACHER_URL}/${id}`, req)

    const data = response.data.data
    return data
  }

  async deleteOne(id: string): Promise<string> {
    const response = await axios.delete<MessageResponse>(`${this.TEACHER_URL}/${id}`)
    return response.data.message
  }

  async getDatatable(req: BaseDatatableRequest): Promise<BaseDatatableResponse<TeacherResponse>> {
    const response = await axios.post<ApiResponse<BaseDatatableResponse<TeacherResponse>>>(
      `${this.TEACHER_URL}/datatable`,
      req
    )

    const data = response.data.data

    return data
  }

  async getOne(id: string): Promise<TeacherResponse> {
    const response = await axios.get<ApiResponse<TeacherResponse>>(`${this.TEACHER_URL}/${id}`)
    const data = response.data.data
    return data
  }

  async getAll(params?: any): Promise<TeacherResponse[]> {
    let url = `${this.TEACHER_URL}`
    if (params?.city_id) {
      url = `${url}?city_id=${params.city_id}`
    }
    const response = await axios.get<ApiResponse<TeacherResponse[]>>(url)
    const data = response.data.data
    return data
  }

  async create(req: any): Promise<TeacherResponse> {
    const response = await axios.post<ApiResponse<TeacherResponse>>(`${this.TEACHER_URL}`, req)
    const data = response.data.data

    return data
  }
}
