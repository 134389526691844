import React, { FC, useMemo, useState } from 'react'
import BaseTable, { handleSelectedRowsChangeV2 } from '../BaseTable'
import { School } from '../../../_domain/model/School'
import useSchoolTable from '../SchoolTable/useSchoolTable'
import SchoolTableFilter from '../SchoolTable/SchoolTableFilter'
import { SchoolFilterRequest } from '../../../_data/dataSource/request/SchoolRequest'
import { BaseDatatableRequest } from '../../../_data/dataSource/request/BaseDatatableRequest'
import SchoolSelectedTable from './SchoolSelectedTable'
import { Nav } from 'react-bootstrap'

type Props = {
  selectedSchoolCallback: (val: any) => void
  singleSelected?: boolean
  initFilter?: BaseDatatableRequest
  isManualSelection?: boolean
  values?: School[]
}

const SchoolTable: FC<Props> = ({ selectedSchoolCallback, singleSelected, initFilter, isManualSelection, values }) => {
  const schoolTable = useSchoolTable({
    initQueryParams: initFilter
  })
  const [tab, setTab] = useState("list")
  const { entities, queryParams, totalCount, handlePageChange, handleSortChange, applyFilter, handleRowPerPageChange } = useMemo(() => {
    return {
      entities: schoolTable.entities,
      queryParams: schoolTable.queryParams,
      totalCount: schoolTable.totalCount,
      handlePageChange: schoolTable.handlePageChange,
      handleSortChange: schoolTable.handleSortChange,
      applyFilter: schoolTable.applyFilter,
      handleRowPerPageChange: schoolTable.handleRowPerPageChange
    }
  }, [schoolTable])

  let mutableTableData = [...entities]
  const columns = [
    {
      name: 'Name',
      selector: (row: School) => row.name,
      // sortable: true,
    },
    {
      name: 'City',
      selector: (row: School) => row.city_name,
      sortable: true,
    },
  ]

  return (
    <>
      <div className='d-flex justify-content-between align-items-center mb-4'>
        <SchoolTableFilter queryParams={queryParams} applyFilter={(val: SchoolFilterRequest) => {
          applyFilter(val)
        }} />
      </div>
      <Nav variant="pills" activeKey={tab} className='mb-4'>
        <Nav.Item>
          <Nav.Link eventKey={"list"} title="List School" onClick={() => setTab("list")}>
            List School
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey={"result"} title="Result School Selected" onClick={() => setTab("result")}>
            Result School Selected
          </Nav.Link>
        </Nav.Item>
      </Nav>
      {tab === "list" && (
        <div className='customized-datatable-style'>
          <BaseTable
            columns={columns}
            data={mutableTableData}
            countPerPage={queryParams.pageSize}
            totalRows={totalCount}
            handlePageChange={handlePageChange}
            handleSortChange={handleSortChange}
            handleSelectedRowsChange={(selectedItem: any) => {
              handleSelectedRowsChangeV2(entities, selectedSchoolCallback, selectedItem, values, singleSelected)
            }}
            initSelected={(row: School) => {
              if (values) {
                return values.some((obj) => obj.id === row.id)
              }
              return false
            }}
            handleRowPerPageChange={handleRowPerPageChange}
            singleSelected={singleSelected}
          />
        </div>
      )}
      {tab === "result" && (
        <SchoolSelectedTable entities={values ?? []} totalCount={values?.length ?? 0} />
      )}
    </>
  )
}

export default SchoolTable;