import { createSlice } from "@reduxjs/toolkit";

const initState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  entity: undefined,
  lastError: null,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const slice = createSlice({
  name: "question",
  initialState: initState,
  reducers: {
    // Redux for starting
    startCall: (state: any, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    stopCall: (state: any, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    catchError: (state: any, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    // GetQuestionMany
    saveList: (state: any, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // GetQuestionById
    saveObject: (state: any, action) => {
      state.actionsLoading = false;
      state.entity = action.payload.data;
      state.error = null;
    },
    // CreateQuestion
    createObject: (state: any, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.data);
    },
    // UpdateQuestion
    updateObject: (state: any, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity: any) => {
        if (entity._id === action.payload.data._id) {
          return action.payload.data;
        }
        return entity;
      });
    },
    // DeleteQuestion
    deleteObject: (state: any, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el: any) => el._id !== action.payload.id
      );
    },
    // deleteQuestionMany
    deleteManyObject: (state: any, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el: any) => !action.payload.ids.includes(el.id)
      );
    },
  },
});

export const question = {
  callTypes,
  slice,
};
