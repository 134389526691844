import React, {useMemo} from 'react'
import {Form} from 'react-bootstrap'
import {Controller, useForm} from 'react-hook-form'
import {ParentUpdateFormInput} from '../../../../../../../helpers/UIParentConstanta'
import {Parent} from '../../../../../../../_domain/model/Parent'
import {msgValidationError} from '../../../../../../../helpers/FuncHelpers'

type Props = {
  onSubmit: (req: any) => void
  parentData: Parent
}

export default function Profile(props: Props) {
  const {parentData, onSubmit} = props

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: {errors},
  } = useForm<ParentUpdateFormInput>({
    mode: 'onChange',
    defaultValues: useMemo(() => {
      return {
        nameInput: parentData.name,
        phoneNumberInput: parentData.phone_number.replace('+62', ''),
        typeParentInput: parentData.type,
      }
    }, []),
  })
  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Profile Details</h3>
        </div>
      </div>

      <div id='kt_account_profile_details' className='collapse show'>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className='card-body border-top p-9'>
            {/* Name Field */}
            <Controller
              name='nameInput'
              defaultValue=''
              control={control}
              rules={{required: true}}
              render={({field}) => (
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>Name</label>
                  <div className='col-lg-8 fv-row'>
                    <input
                      {...field}
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Parent name'
                    />
                    {errors.nameInput && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          {msgValidationError(errors.nameInput.type, 'Student Name')}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            />

            {/* Phone Number Field */}
            <Controller
              name='phoneNumberInput'
              defaultValue={parentData.phone_number.replace('+62', '')}
              control={control}
              rules={{required: true}}
              render={({field}) => (
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                    Phone Number
                  </label>
                  <div className='col-lg-8 fv-row'>
                    <div className='input-group mb-3'>
                      <span className='input-group-text'>+62</span>
                      <input
                        {...field}
                        type='text'
                        className='form-control form-control-lg form-control-solid '
                        id='newphone'
                      />
                    </div>
                    {errors.phoneNumberInput && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          {msgValidationError(errors.phoneNumberInput.type, 'Phone Number')}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            />

            {/* Gender Field */}
            <Controller
              name='typeParentInput'
              defaultValue=''
              control={control}
              rules={{required: true}}
              render={({field: {onChange, value}}) => (
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                    Parent Type
                  </label>

                  <div className='col-lg-8 fv-row'>
                    <div className='d-flex justify-content-start'>
                      <div className='form-check form-check-custom form-check-primary form-check-solid form-check-sm ms-10'>
                        <input
                          className='form-check-input'
                          type='radio'
                          value='mother'
                          checked={value === 'mother'}
                          name='type'
                          onChange={onChange}
                        />
                        <label className='form-check-label text-gray-700 fw-bold text-nowrap'>
                          Mother
                        </label>
                      </div>
                      <div className='form-check form-check-custom form-check-primary form-check-solid form-check-sm ms-10'>
                        <input
                          className='form-check-input'
                          type='radio'
                          value='father'
                          checked={value === 'father'}
                          name='type'
                          onChange={onChange}
                        />
                        <label className='form-check-label text-gray-700 fw-bold text-nowrap'>
                          Father
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            />
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary'>
              Save Change
            </button>
          </div>
        </Form>
      </div>
    </div>
  )
}
