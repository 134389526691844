import {CLASS_STUDENT} from '../../../helpers/UIConstanta'
import {ScheduleFormInput} from '../../../helpers/UIScheduleConstanta'

export type ScheduleLiveClassCreateRequest = {
  name: string
  subject_id: string
  chapter_ids: string[]
  target_user: string
  attendees?: {
    user_id: number
    name: string
  }[]
  school_ids?: {
    id: number
    name: string
  }[]
  class_student?: {
    grade: number
    major: string
    level: string
  }[]
  teacher?: {
    id: string
    name: string
    photo?: string
  }
  group_class_id: number
  date_start: string
  date_end: string
  time_start: string
  time_end: string
  link_room: string
}

export type ScheduleLiveClassUpdateRequest = {
  name: string
  subject_id: string
  chapter_ids: string[]
  target_user: string
  attendees?: {
    user_id: number
    name: string
  }[]
  school_ids?: {
    id: number
    name: string
  }[]
  class_student?: {
    grade: number
    major: string
    level: string
  }[]
  teacher?: {
    id: string
    name: string
    photo?: string
  }
  group_class_id: number
  date_start: string
  date_end: string
  time_start: string
  time_end: string
  link_room: string
}

export function mappingInputFormToScheduleLiveClassCreateRequest(
  req: ScheduleFormInput
): ScheduleLiveClassCreateRequest {
  return {
    name: req.nameInput ?? '',
    subject_id: req.subjectInput ?? '',
    chapter_ids: req.chaptersInput?.map((item) => item.id) ?? [],
    target_user: req.isIncreaseStudent === 'true' ? 'mix' : req.targetUserInput ?? 'all',
    date_start: req.dateStartInput ?? '',
    date_end: req.dateEndInput ?? '',
    time_start: req.timeStartInput ?? '',
    time_end: req.timeEndInput ?? '',
    link_room: req.linkRoomInput ?? '',
    group_class_id: req.groupClassInput ? +req.groupClassInput : 0,
    teacher: req.teacherInput?.at(0)
      ? {
          id: req.teacherInput.at(0).id,
          name: req.teacherInput.at(0).name,
          photo: req.teacherInput.at(0).photo,
        }
      : undefined,
    attendees: req.studentInput?.map((item) => {
      return {
        user_id: item.user_id,
        name: item.name,
      }
    }),
    school_ids:
      req.schoolInput?.map((item) => {
        return {
          id: item.id,
          name: item.name,
        }
      }) ?? [],
    class_student:
      req.classInput?.map((item) => {
        const {major, grade, level} = CLASS_STUDENT[+item.value]
        return {
          grade: grade === 'ALUMNI' ? 13 : +grade,
          major: major,
          level: level,
        }
      }) ?? [],
  }
}
export function mappingInputFormToScheduleLiveClassUpdateRequest(
  req: ScheduleFormInput
): ScheduleLiveClassUpdateRequest {
  return {
    name: req.nameInput ?? '',
    subject_id: req.subjectInput ?? '',
    chapter_ids: req.chaptersInput?.map((item) => item.id) ?? [],
    target_user: req.isIncreaseStudent === 'true' ? 'mix' : req.targetUserInput ?? 'all',
    date_start: req.dateStartInput ?? '',
    date_end: req.dateEndInput ?? '',
    time_start: req.timeStartInput ?? '',
    time_end: req.timeEndInput ?? '',
    link_room: req.linkRoomInput ?? '',
    group_class_id: req.groupClassInput ? +req.groupClassInput : 0,
    teacher: req.teacherInput?.at(0)
      ? {
          id: req.teacherInput.at(0).id,
          name: req.teacherInput.at(0).name,
          photo: req.teacherInput.at(0).photo,
        }
      : undefined,
    attendees: req.studentInput?.map((item) => {
      return {
        user_id: item.user_id,
        name: item.name,
      }
    }),
    school_ids:
      req.schoolInput?.map((item) => {
        return {
          id: item.id,
          name: item.name,
        }
      }) ?? [],
    class_student:
      req.classInput?.map((item) => {
        const {major, grade, level} = CLASS_STUDENT[+item.value]
        return {
          grade: grade === 'ALUMNI' ? 13 : +grade,
          major: major,
          level: level,
        }
      }) ?? [],
  }
}
