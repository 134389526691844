import {useCallback, useEffect, useState} from 'react'
import QuestionTryoutDataSourceImpl from '../_data/dataSource/QuestionTryoutDataSourceImpl'
import {QuestionTryoutNumberAvailableRequest} from '../_data/dataSource/request/QuestionTryoutRequest'
import { Series } from '../_domain/model/Series'
import { TypeTryout } from '../_domain/model/TypeTryout'
import SeriesDataSourceImpl from '../_data/dataSource/SeriesDataSourceImpl'
import TypeTryoutDataSourceImpl from '../_data/dataSource/TypeTryoutDataSourceImpl'
import { SeriesRepositoryImpl } from '../_domain/repository/SeriesRepositoryImpl'
import { TypeTryoutRepositoryImpl } from '../_domain/repository/TypeTryoutRepositoryImpl'

const useQuestionTryout = () => {
  const [listNumber, setListNumber] = useState<number[]>([])
  const [list, setList] = useState<Series[]>([])
  const [listTypeTryout, setListTypeTryout] = useState<TypeTryout[]>([])

  const dataSource = new QuestionTryoutDataSourceImpl()
  const dataSourceSeries = new SeriesDataSourceImpl()
  const dataSourceTypeTryout = new TypeTryoutDataSourceImpl()
  const repoSeries = new SeriesRepositoryImpl(dataSourceSeries)
  const repoTypeTryout = new TypeTryoutRepositoryImpl(dataSourceTypeTryout)

  const getListNumberAvailable = useCallback(
    async (series?: string, chapter_id?: string, current_number?: number) => {
      if (series && chapter_id) {
        const params: QuestionTryoutNumberAvailableRequest = {
          role: series,
          chapterId: chapter_id,
        }

        let response = await dataSource.getAvailableNumber(params)
        if (current_number) {
          response.unshift(current_number)
        }
        setListNumber(response)
      }
    },
    [setListNumber]
  )

  async function getAllSeries() {
    const response = await repoSeries.getAll()
    setList(response)
  }

  async function getAllTypeTryout() {
    const response = await repoTypeTryout.getAll()
    setListTypeTryout(response)
  }

  useEffect(() => {
    getAllSeries()
    getAllTypeTryout()
  }, [])

  return {
    listNumberQuestionTryout: listNumber,
    listSeries: list,
    getAllSeries,
    listTypeTryout,
    getAllTypeTryout,
    setListNumber,
    getListNumberQuestionTOAvailable: getListNumberAvailable,
  }
}

export default useQuestionTryout
