import React, {FC, useMemo} from 'react'
import BaseTable from '../../../../../../_component/Table/BaseTable'
import {Badge} from 'react-bootstrap'
import {GENDER} from '../../../../../../_domain/constanta'
import {useOutletContext} from 'react-router-dom'

type Props = {
  entities: any[]
  totalCount: number
}

const ParentStudentsList: FC<Props> = ({entities, totalCount}) => {
  const outletContext = useOutletContext<any>().addStudentSection
  const {showingAddStudentModal} = useMemo(() => {
    return {
      showingAddStudentModal: outletContext.showingAddStudentModal,
    }
  }, [outletContext])

  let mutableTableData = [...(entities ?? [])]
  const columns = [
    {
      name: 'Name',
      selector: (row: any) => {
        return (
          <>
            <span className='text-gray-800 fs-6 fw-bold'>{row.name}</span>
            <br />
            <span className='text-gray-500 fw-semibold'>{row.email}</span>
          </>
        )
      },
      sortable: true,
    },
    {
      name: 'School Name',
      selector: (row: any) => row.school_name,
      sortable: true,
      maxWidth: '200px',
    },
    {
      name: 'ID Qonstanta',
      selector: (row: any) => row.id_qonstanta,
      sortable: true,
      maxWidth: '150px',
    },
    {
      name: 'Phone Number',
      selector: (row: any) => row.phone_number,
      sortable: true,
      maxWidth: '150px',
    },
    {
      name: 'Gender',
      selector: (row: any) => {
        if (row.gender === GENDER.MALE) {
          return (
            <Badge bg='primary' text='white' className='text-capitalize'>
              {row.gender}
            </Badge>
          )
        } else {
          return (
            <Badge bg='warning' text='dark' className='text-capitalize'>
              {row.gender}
            </Badge>
          )
        }
      },
      sortable: true,
      maxWidth: '60px',
    },
    {
      name: 'Class',
      selector: (row: any) => row.grade + '-' + row.major,
      sortable: true,
      maxWidth: '60px',
    },
  ]

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>List Students</span>
        </h3>
        <div className='card-toolbar'>
          <button
            onClick={() => showingAddStudentModal()}
            type='button'
            className='btn btn-sm btn-primary'
          >
            Add New
          </button>
        </div>
      </div>
      <div className='card-body py-3'>
        <div className='customized-datatable-style'>
          <BaseTable
            columns={columns}
            data={mutableTableData}
            totalRows={totalCount}
            selectableRows={false}
          />
        </div>
      </div>
    </div>
  )
}

export default ParentStudentsList
