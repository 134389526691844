import AuthDataSource from '../../_data/dataSource/AuthDataSoruce'
import {UserReferraRequest, UserUpdateRequest} from '../../_data/dataSource/request/AuthRequest'
import {BaseDatatableRequest} from '../../_data/dataSource/request/BaseDatatableRequest'
import {BaseDatatableResponse} from '../../_data/dataSource/response/BaseDatatableResponse'
import {UserBuilder, UserModel} from '../model/Auth'
import {UserRepository} from './UserRepository'

export class UserRepositoryImpl implements UserRepository {
  private datasource!: AuthDataSource
  constructor(datasource: AuthDataSource) {
    this.datasource = datasource
  }

  async updateRole(id: string | number, req: any): Promise<UserModel> {
    const data = await this.datasource.editRole(id, req)
    const initBuilder = new UserBuilder()
    const user = initBuilder.SingleData(data).Result()
    return user
  }

  resetPasswordMany(user_ids: number[]): Promise<string> {
    return this.datasource.resetPasswordMany(user_ids)
  }

  async updateUserReferral(id: string | number, req: UserReferraRequest): Promise<UserModel> {
    const data = await this.datasource.updateUserReferral(id, req)
    const initBuilder = new UserBuilder()
    const user = initBuilder.SingleData(data).Result()
    return user
  }

  async createUserReferral(req: UserReferraRequest): Promise<UserModel> {
    const data = await this.datasource.createUserReferral(req)
    const initBuilder = new UserBuilder()
    const user = initBuilder.SingleData(data).Result()
    return user
  }

  async getDatatable(req: BaseDatatableRequest): Promise<BaseDatatableResponse<UserModel>> {
    const data = await this.datasource.getDatatable(req)
    const initBuilder = new UserBuilder()
    const entities = initBuilder.ListData(data.entities).ResultList()
    const totalCount = data.totalCount
    return {
      entities,
      totalCount,
      errorMessage: data.errorMessage,
    }
  }

  getAll(params?: any): Promise<UserModel[]> {
    throw new Error('Method not implemented.')
  }

  async getOneById(id: string | number): Promise<UserModel> {
    const data = await this.datasource.getOneByUserId(id)
    const initBuilder = new UserBuilder()
    const user = initBuilder.SingleData(data).Result()
    return user
  }

  deleteOneById(id: string | number, secondId?: string | number | undefined): Promise<string> {
    throw new Error('Method not implemented.')
  }
  createOne(req: any): Promise<UserModel> {
    throw new Error('Method not implemented.')
  }

  async updateOne(req: UserUpdateRequest, id?: string | number): Promise<UserModel> {
    const data = await this.datasource.updateOne(+id!, req)
    const initBuilder = new UserBuilder()
    const user = initBuilder.SingleData(data).Result()
    return user
  }
}
