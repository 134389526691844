const QUESTION_TRYOUT_DELETE_TITLE = 'Question Tryout Delete'
const QUESTION_TRYOUT_DELETE_LOADING = 'Question Tryout is deleting...'
const QUESTION_TRYOUT_DELETE_CONFIRM = 'Are you sure to permanently delete this question tryout ?'
const QUESTION_TRYOUT_DELETE_SUCCESS = 'Successful delete question tryout'
const QUESTION_TRYOUT_DELETE_FAILED = "Can't delete question tryout"
const QUESTION_TRYOUT_CREATE_TITLE = 'Question Tryout Create'
const QUESTION_TRYOUT_CREATE_FAILED = "Can't create question tryout"
const QUESTION_TRYOUT_CREATE_SUCCESS = 'Successful create question tryout'
const QUESTION_TRYOUT_EDIT_TITLE = 'Question Tryout Edit'
const QUESTION_TRYOUT_EDIT_SUCCESS = 'Successful update question tryout'
const QUESTION_TRYOUT_EDIT_FAILED = "Can't update question tryout"
const QUESTION_TRYOUT_GET_FAILED = "Can't get question tryout"
const QUESTION_TRYOUT_LIST_TITLE = 'Question Tryout List'
export {
  QUESTION_TRYOUT_DELETE_TITLE,
  QUESTION_TRYOUT_DELETE_CONFIRM,
  QUESTION_TRYOUT_DELETE_LOADING,
  QUESTION_TRYOUT_DELETE_SUCCESS,
  QUESTION_TRYOUT_DELETE_FAILED,
  QUESTION_TRYOUT_CREATE_TITLE,
  QUESTION_TRYOUT_CREATE_SUCCESS,
  QUESTION_TRYOUT_CREATE_FAILED,
  QUESTION_TRYOUT_EDIT_TITLE,
  QUESTION_TRYOUT_EDIT_SUCCESS,
  QUESTION_TRYOUT_EDIT_FAILED,
  QUESTION_TRYOUT_GET_FAILED,
  QUESTION_TRYOUT_LIST_TITLE,
}
