const SCHOOL_DELETE_TITLE = 'School Delete'
const SCHOOL_LIST_TITLE = 'School List'
const SCHOOL_DELETE_LOADING = 'School is deleting...'
const SCHOOL_DELETE_CONFIRM = 'Are you sure to permanently delete this school ?'
const SCHOOL_DELETE_SUCCESS = 'Successful delete school'
const SCHOOL_DELETE_FAILED = "Can't delete school"
const SCHOOL_CREATE_TITLE = 'School Create'
const SCHOOL_CREATE_FAILED = "Can't create school"
const SCHOOL_CREATE_SUCCESS = 'Successful create school'
const SCHOOL_EDIT_TITLE = 'School Edit'
const SCHOOL_EDIT_SUCCESS = 'Successful update school'
const SCHOOL_EDIT_FAILED = "Can't update school"
const SCHOOL_GET_FAILED = "Can't get school"
export {
  SCHOOL_LIST_TITLE,
  SCHOOL_DELETE_TITLE,
  SCHOOL_DELETE_CONFIRM,
  SCHOOL_DELETE_LOADING,
  SCHOOL_DELETE_SUCCESS,
  SCHOOL_DELETE_FAILED,
  SCHOOL_CREATE_TITLE,
  SCHOOL_CREATE_SUCCESS,
  SCHOOL_CREATE_FAILED,
  SCHOOL_EDIT_TITLE,
  SCHOOL_EDIT_SUCCESS,
  SCHOOL_EDIT_FAILED,
  SCHOOL_GET_FAILED,
}
