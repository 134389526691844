import {typeTO} from '../../../../../_redux_slice'
import TypeTryoutDataSourceImpl from '../../../../../_data/dataSource/TypeTryoutDataSourceImpl'
import {TypeTryoutRepositoryImpl} from '../../../../../_domain/repository/TypeTryoutRepositoryImpl'
import {TypeTryoutServiceImpl} from '../../../../../_domain/service/TypeTryoutService'
import {BaseDatatableRequest} from '../../../../../_data/dataSource/request/BaseDatatableRequest'
import {
  TYPE_TRYOUT_CREATE_FAILED,
  TYPE_TRYOUT_CREATE_SUCCESS,
  TYPE_TRYOUT_DELETE_FAILED,
  TYPE_TRYOUT_DELETE_SUCCESS,
  TYPE_TRYOUT_EDIT_FAILED,
  TYPE_TRYOUT_EDIT_SUCCESS,
  TYPE_TRYOUT_GET_FAILED,
} from '../../../../../_domain/constanta'
import {TypeTryoutFormInput} from '../../../../../helpers/UITypeTryoutConstanta'
import {alertError, alertSuccess, errorHandling} from '../../../../../helpers/FuncHelpers'
import {AlertPromise} from '../../../../../helpers/classHelpers'

const {actions} = typeTO.slice
// const controlAction = control.slice.actions
const callTypes = typeTO.callTypes
const dataSource = new TypeTryoutDataSourceImpl()
const repository = new TypeTryoutRepositoryImpl(dataSource)
const service = new TypeTryoutServiceImpl(repository)

const alertPromise = new AlertPromise()
export const getDatatableTypeTryout = (queryParams: BaseDatatableRequest) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.list}))

  return service
    .getDatatableTypeTryout(queryParams)
    .then((response) => {
      const {totalCount, entities} = response
      dispatch(actions.saveList({totalCount, entities}))

      alertPromise.success()
      return response
    })
    .catch((err) => {
      if (err.response) {
        errorHandling(err.response, TYPE_TRYOUT_GET_FAILED)
      } else {
        alertPromise.error()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

// export const fetchAllTypeTO = () => (dispatch) => {
//   // console.log(dispatch(actions.startCall({callType: callTypes.list})))
//   // dispatch(actions.saveList({totalCount: 12, entities: ['awdkoawd']}))
//   dispatch(actions.startCall({callType: callTypes.list}))
//   return typeTOAPI
//     .getAll()
//     .then((response) => {
//       let totalCount = response.data.data.length
//       let entities = response.data.data
//       dispatch(actions.saveList({totalCount, entities}))
//     })
//     .catch((error) => {
//       error.clientMessage = "Can't find type try out"
//       dispatch(actions.catchError({error, callType: callTypes.list}))
//     })
// }

export const getOneTypeTO = (id?: string) => (dispatch: any) => {
  if (!id) {
    return dispatch(actions.saveObject({data: undefined}))
  }

  dispatch(actions.startCall({callType: callTypes.action}))
  return service
    .getDetailTypeTryoutById(id)
    .then((response) => {
      const typeTO = response
      dispatch(actions.saveObject({data: typeTO}))
      return typeTO
    })
    .catch((err) => {
      if (err.response) {
        errorHandling(err.response, TYPE_TRYOUT_GET_FAILED)
      } else {
        console.error(err)
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

//need to set post loading

export const createTypeTO = (req: TypeTryoutFormInput) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.action}))

  return service
    .createOneTypeTryout(req)
    .then((response) => {
      alertPromise.stop()
      const typeTO = response
      dispatch(actions.createObject({data: typeTO}))
      alertSuccess(TYPE_TRYOUT_CREATE_SUCCESS)
    })
    .catch((err) => {
      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, TYPE_TRYOUT_CREATE_FAILED)
      } else {
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const updateTypeTO = (req: TypeTryoutFormInput, id: string) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.action}))

  return service
    .updateOneTypeTryout(req, id)
    .then((response) => {
      alertPromise.stop()
      const typeTO = response
      dispatch(actions.updateObject({data: typeTO}))
      alertSuccess(TYPE_TRYOUT_EDIT_SUCCESS)
    })
    .catch((err) => {
      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, TYPE_TRYOUT_EDIT_FAILED)
      } else {
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const deleteTypeTO = (id: string) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.action}))

  return service
    .deleteOneTypeTryoutByID(id)
    .then(() => {
      alertPromise.stop()
      alertSuccess(TYPE_TRYOUT_DELETE_SUCCESS)
      dispatch(actions.delete({id}))
    })
    .catch((err) => {
      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, TYPE_TRYOUT_DELETE_FAILED)
      } else {
        console.error(err)
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

// export const deleteTypeTOs = (ids) => (dispatch) => {
//   dispatch(actions.startCall({callType: callTypes.action}))
//   return typeTOAPI
//     .deleteMany(ids)
//     .then(() => {
//       alertSuccess('Successful delete typeTO')
//       dispatch(actions.deleteMany({ids}))
//     })
//     .catch((error) => {
//       error.clientMessage = "Can't delete typeTOs"
//       alertError(error.clientMessage)
//       dispatch(actions.catchError({error, callType: callTypes.action}))
//     })
// }

//need to set post loading END
