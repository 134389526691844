import {
  StudentGroupRoleResponse,
  StudentRoleResponse,
} from '../../_data/dataSource/response/StudentRoleResponse'

export type StudentRole = {
  id: string
  user_id: number
  group_id?: string
  roles?: {
    _id: any
    short_name: string
    name: string
    slug: string
    isLimitInput?: string
    special_limitations?: any
  }[]
}

export type StudentGroupRole = {
  id: string
  role_group_name: string
  product_id: string
  roles?: {
    _id: any
    short_name: string
    name: string
    slug: string
    isLimitInput?: string
    special_limitations?: any
  }[]
}

export class StudentRoleBuilder {
  SingleStudentRole!: StudentRole
  StudentRoleList!: StudentRole[]
  constructor() {}

  SingleData(studentRoleResponse: StudentRoleResponse): StudentRoleBuilder {
    const role = mapingStudentRole(studentRoleResponse)
    this.SingleStudentRole = role
    return this
  }
  ListData(studentRoleResponse: StudentRoleResponse[]): StudentRoleBuilder {
    const roles = mapingStudentRoles(studentRoleResponse)
    this.StudentRoleList = roles
    return this
  }
  Result(): StudentRole {
    return this.SingleStudentRole
  }
  ResultList(): StudentRole[] {
    return this.StudentRoleList
  }
}

export class StudentGroupRoleBuilder {
  SingleGroupRole!: StudentGroupRole
  GroupRoleList!: StudentGroupRole[]
  constructor() {}

  SingleData(groupRoleResponse: StudentGroupRoleResponse): StudentGroupRoleBuilder {
    const role = mapingStudentGroupRole(groupRoleResponse)
    this.SingleGroupRole = role
    return this
  }
  ListData(groupRoleResponse: StudentGroupRoleResponse[]): StudentGroupRoleBuilder {
    const roles = mapingStudentGroupRoles(groupRoleResponse)
    this.GroupRoleList = roles
    return this
  }
  Result(): StudentGroupRole {
    return this.SingleGroupRole
  }
  ResultList(): StudentGroupRole[] {
    return this.GroupRoleList
  }
}

function mapingStudentRole(studentRoleResponse: StudentRoleResponse): StudentRole {
  let role: StudentRole = studentRoleResponse
  role.group_id = role.group_id ?? ''
  const tmp = role.roles?.map((item) => {
    if (item.special_limitations) {
      return {
        ...item,
        isLimitInput: 'true',
      }
    } else {
      return {
        ...item,
        isLimitInput: 'false',
      }
    }
  })

  role.roles = tmp
  return role
}

function mapingStudentRoles(studentRolesResponse: StudentRoleResponse[]): StudentRole[] {
  const roles = studentRolesResponse.map((item) => {
    return mapingStudentRole(item)
  })
  return roles
}

function mapingStudentGroupRole(groupRoleResponse: StudentGroupRoleResponse): StudentGroupRole {
  let role: StudentGroupRole = {
    ...groupRoleResponse,
    id: groupRoleResponse._id,
  }
  const tmp = role.roles?.map((item) => {
    if (item.special_limitations) {
      return {
        ...item,
        isLimitInput: 'true',
      }
    } else {
      return {
        ...item,
        isLimitInput: 'false',
      }
    }
  })
  role.roles = tmp
  return role
}

function mapingStudentGroupRoles(
  groupRolesResponse: StudentGroupRoleResponse[]
): StudentGroupRole[] {
  const roles = groupRolesResponse.map((item) => {
    return mapingStudentGroupRole(item)
  })
  return roles
}
