import React from 'react'
import {StudentProdi as StudentProdiData} from '../../../../../../_domain/model/StudentProdi'

type Props = {
  entities: StudentProdiData[]
}
export default function StudentProdi(props: Props) {
  const {entities} = props
  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Student Prodi Selected</span>
        </h3>
        <div className='card-toolbar'></div>
      </div>
      <div className='card-body py-3'>
        {entities?.map((item, index) => (
          <div className='row w-full mb-7'>
            <div className='col-6'>
              <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                {item.prodi}
              </a>
              <span className='text-muted d-block fw-semibold'>{item.universitas}</span>
            </div>
            <div className='col-6 row'>
              <div className='col-4'>
                <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                  Batas Aman
                </a>
                <span className='text-muted d-block fw-semibold'>{item.batas_aman}</span>
              </div>
              <div className='col-4'>
                <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                  Keketatan
                </a>
                <span className='text-muted d-block fw-semibold'>{item.keketatan}</span>
              </div>
              <div className='col-4'>
                <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                  Peminat
                </a>
                <span className='text-muted d-block fw-semibold'>{item.peminat}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
