export type AuthGroupFormInput = {
  nameInput?: string
  authorizationInput?: any
  roleInput?: string
}

export enum SLUG_MENU {
  DASHBOARD_MENU = 'dashboard_menu',
  MASTER_MENU = 'master_menu',
  EDULIVE_MENU = 'edulive_menu',
  TRYOUT_MENU = 'tryout_menu',
  TRANSACTION_MENU = 'transaction_menu',
}

export enum SLUG_MASTER_SUB_MENU {
  MASTER_SUBJECT_MENU = 'master_subject_menu',
  MASTER_SCHOOL_MENU = 'master_school_menu',
  MASTER_CHAPTER_MENU = 'master_chapter_menu',
  MASTER_STUDENT_MENU = 'master_student_menu',
  MASTER_TEACHER_MENU = 'master_teacher_menu',
  MASTER_GROUP_ROLE_MENU = 'master_group_role_menu',
  MASTER_GROUP_CLASS_MENU = 'master_group_class_menu',
  MASTER_PRODUCT_MENU = 'master_product_menu',
  MASTER_FASILITY_MENU = 'master_fasility_menu',
  MASTER_AUTH_GROUP_MENU = 'master_auth_group_menu',
  MASTER_TEAM_MENU = 'master_team_menu',
  MASTER_PARTNER_MENU = 'master_partner_menu',
  MASTER_PARENT_MENU = 'master_parent_menu',
}

export enum SLUG_EDULIVE_SUB_MENU {
  EDULIVE_CLASS_MENU = 'edulive_class_menu',
  EDULIVE_POST_TEST_MENU = 'edulive_post_test_menu',
  EDULIVE_STUDENT_CLASS_MENU = 'edulive_student_class_menu',
}

export enum SLUG_TRYOUT_SUB_MENU {
  TRYOUT_TYPE_MENU = 'tryout_type_menu',
  TRYOUT_SERIES_MENU = 'tryout_series_menu',
  TRYOUT_QUESTION_MENU = 'tryout_question_menu',
  TRYOUT_SCHEDULE_MENU = 'tryout_schedule_menu',
  TRYOUT_RESULT_MENU = 'tryout_result_menu',
}

export enum SLUG_TRANSACTION_SUB_MENU {
  TRANSACTION_ORDER_MENU = 'transaction_order_menu',
  TRANSACTION_BANNER_MENU = 'transaction_banner_menu',
}

export const MenuRoles = [
  {
    menu_title: 'Dashboard Menu',
    menu_description: '',
    menu_slug: SLUG_MENU.DASHBOARD_MENU,
  },
  {
    menu_title: 'Master Menu',
    menu_description: '',
    menu_slug: SLUG_MENU.MASTER_MENU,
  },
  {
    menu_title: 'Master Subject Menu',
    menu_description: '',
    menu_slug: SLUG_MASTER_SUB_MENU.MASTER_SUBJECT_MENU,
  },
  {
    menu_title: 'Master School Menu',
    menu_description: '',
    menu_slug: SLUG_MASTER_SUB_MENU.MASTER_SCHOOL_MENU,
  },
  {
    menu_title: 'Master Chapter Menu',
    menu_description: '',
    menu_slug: SLUG_MASTER_SUB_MENU.MASTER_CHAPTER_MENU,
  },
  {
    menu_title: 'Master Student Menu',
    menu_description: '',
    menu_slug: SLUG_MASTER_SUB_MENU.MASTER_STUDENT_MENU,
  },
  {
    menu_title: 'Master Teacher Menu',
    menu_description: '',
    menu_slug: SLUG_MASTER_SUB_MENU.MASTER_TEACHER_MENU,
  },
  {
    menu_title: 'Master Group Role Menu',
    menu_description: '',
    menu_slug: SLUG_MASTER_SUB_MENU.MASTER_GROUP_ROLE_MENU,
  },
  {
    menu_title: 'Master Group Class Menu',
    menu_description: '',
    menu_slug: SLUG_MASTER_SUB_MENU.MASTER_GROUP_CLASS_MENU,
  },
  {
    menu_title: 'Master Product Menu',
    menu_description: '',
    menu_slug: SLUG_MASTER_SUB_MENU.MASTER_PRODUCT_MENU,
  },
  {
    menu_title: 'Master Product Fasility Menu',
    menu_description: '',
    menu_slug: SLUG_MASTER_SUB_MENU.MASTER_FASILITY_MENU,
  },
  {
    menu_title: 'Master Auth Group Menu',
    menu_description: '',
    menu_slug: SLUG_MASTER_SUB_MENU.MASTER_AUTH_GROUP_MENU,
  },
  {
    menu_title: 'Master Team Menu',
    menu_description: '',
    menu_slug: SLUG_MASTER_SUB_MENU.MASTER_TEAM_MENU,
  },
  {
    menu_title: 'Master Partner Menu',
    menu_description: '',
    menu_slug: SLUG_MASTER_SUB_MENU.MASTER_PARTNER_MENU,
  },
  {
    menu_title: 'Tryout Menu',
    menu_description: '',
    menu_slug: SLUG_MENU.TRYOUT_MENU,
  },
  {
    menu_title: 'Tryout Type Menu',
    menu_description: '',
    menu_slug: SLUG_TRYOUT_SUB_MENU.TRYOUT_TYPE_MENU,
  },
  {
    menu_title: 'Tryout Series Menu',
    menu_description: '',
    menu_slug: SLUG_TRYOUT_SUB_MENU.TRYOUT_SERIES_MENU,
  },
  {
    menu_title: 'Tryout Question Menu',
    menu_description: '',
    menu_slug: SLUG_TRYOUT_SUB_MENU.TRYOUT_QUESTION_MENU,
  },
  {
    menu_title: 'Tryout Schedule Menu',
    menu_description: '',
    menu_slug: SLUG_TRYOUT_SUB_MENU.TRYOUT_SCHEDULE_MENU,
  },
  {
    menu_title: 'Tryout Result Menu',
    menu_description: '',
    menu_slug: SLUG_TRYOUT_SUB_MENU.TRYOUT_RESULT_MENU,
  },
  {
    menu_title: 'EduLive Menu',
    menu_description: '',
    menu_slug: SLUG_MENU.EDULIVE_MENU,
  },
  {
    menu_title: 'EduLive CLass Menu',
    menu_description: '',
    menu_slug: SLUG_EDULIVE_SUB_MENU.EDULIVE_CLASS_MENU,
  },
  {
    menu_title: 'EduLive Post Test Menu',
    menu_description: '',
    menu_slug: SLUG_EDULIVE_SUB_MENU.EDULIVE_POST_TEST_MENU,
  },
  {
    menu_title: 'EduLive Student Class Menu',
    menu_description: '',
    menu_slug: SLUG_EDULIVE_SUB_MENU.EDULIVE_STUDENT_CLASS_MENU,
  },
  {
    menu_title: 'Transaction Menu',
    menu_description: '',
    menu_slug: SLUG_MENU.TRANSACTION_MENU,
  },
  {
    menu_title: 'Transaction Order Menu',
    menu_description: '',
    menu_slug: SLUG_TRANSACTION_SUB_MENU.TRANSACTION_ORDER_MENU,
  },
  {
    menu_title: 'Transaction Banner Menu',
    menu_description: '',
    menu_slug: SLUG_TRANSACTION_SUB_MENU.TRANSACTION_BANNER_MENU,
  },
]
