const SCHEDULE_TRYOUT_DELETE_TITLE = 'Schedule Tryout Delete'
const SCHEDULE_TRYOUT_DELETE_LOADING = 'Schedule Tryout is deleting...'
const SCHEDULE_TRYOUT_DELETE_CONFIRM = 'Are you sure to permanently delete this schedule tryout ?'
const SCHEDULE_TRYOUT_DELETE_SUCCESS = 'Successful delete schedule tryout'
const SCHEDULE_TRYOUT_DELETE_FAILED = "Can't delete schedule tryout"
const SCHEDULE_TRYOUT_CREATE_TITLE = 'Schedule Tryout Create'
const SCHEDULE_TRYOUT_CREATE_FAILED = "Can't create schedule tryout"
const SCHEDULE_TRYOUT_CREATE_SUCCESS = 'Successful create schedule tryout'
const SCHEDULE_TRYOUT_EDIT_TITLE = 'Schedule Tryout Edit'
const SCHEDULE_TRYOUT_EDIT_SUCCESS = 'Successful update schedule tryout'
const SCHEDULE_TRYOUT_EDIT_FAILED = "Can't update schedule tryout"
const SCHEDULE_TRYOUT_GET_FAILED = "Can't get schedule tryout"
const SCHEDULE_TRYOUT_LIST_TITLE = 'Schedule Tryout List'
export {
  SCHEDULE_TRYOUT_DELETE_TITLE,
  SCHEDULE_TRYOUT_DELETE_CONFIRM,
  SCHEDULE_TRYOUT_DELETE_LOADING,
  SCHEDULE_TRYOUT_DELETE_SUCCESS,
  SCHEDULE_TRYOUT_DELETE_FAILED,
  SCHEDULE_TRYOUT_CREATE_TITLE,
  SCHEDULE_TRYOUT_CREATE_SUCCESS,
  SCHEDULE_TRYOUT_CREATE_FAILED,
  SCHEDULE_TRYOUT_EDIT_TITLE,
  SCHEDULE_TRYOUT_EDIT_SUCCESS,
  SCHEDULE_TRYOUT_EDIT_FAILED,
  SCHEDULE_TRYOUT_GET_FAILED,
  SCHEDULE_TRYOUT_LIST_TITLE,
}
