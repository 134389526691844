const SERIES_DELETE_TITLE = 'Series Delete'
const SERIES_DELETE_LOADING = 'Series is deleting...'
const SERIES_DELETE_CONFIRM = 'Are you sure to permanently delete this series ?'
const SERIES_DELETE_SUCCESS = 'Successful delete series'
const SERIES_DELETE_FAILED = "Can't delete series"
const SERIES_LIST_TITLE = 'Series List'
const SERIES_CREATE_TITLE = 'Series Create'
const SERIES_CREATE_FAILED = "Can't create series"
const SERIES_CREATE_SUCCESS = 'Successful create series'
const SERIES_EDIT_TITLE = 'Series Edit'
const SERIES_EDIT_SUCCESS = 'Successful update series'
const SERIES_EDIT_FAILED = "Can't update series"
const SERIES_GET_FAILED = "Can't get series"
export {
  SERIES_DELETE_TITLE,
  SERIES_DELETE_CONFIRM,
  SERIES_DELETE_LOADING,
  SERIES_DELETE_SUCCESS,
  SERIES_DELETE_FAILED,
  SERIES_CREATE_TITLE,
  SERIES_CREATE_SUCCESS,
  SERIES_CREATE_FAILED,
  SERIES_EDIT_TITLE,
  SERIES_EDIT_SUCCESS,
  SERIES_EDIT_FAILED,
  SERIES_GET_FAILED,
  SERIES_LIST_TITLE,
}
