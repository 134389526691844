import {useOutletContext} from 'react-router-dom'
import {ModalDelete} from '../../../../../_component/Modal'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {useEffect, useMemo} from 'react'
import {deleteOneChapter, getAllChapterDatatable} from './ChapterAction'
import {
  CHAPTER_DELETE_CONFIRM,
  CHAPTER_DELETE_LOADING,
  CHAPTER_DELETE_TITLE,
} from '../../../../../_domain/constanta/ChapterConstant'

const ChapterDelete = () => {
  const deleteSectionContext = useOutletContext<any>().deleteSection
  const {closingDeleteModal, propsForDelete} = useMemo(() => {
    return {
      closingDeleteModal: deleteSectionContext.closingDeleteModal,
      propsForDelete: deleteSectionContext.propsForDelete,
    }
  }, [deleteSectionContext])

  const outletContext = useOutletContext<any>().queryParams
  const {queryParams} = useMemo(() => {
    return {
      queryParams: outletContext.queryParams,
    }
  }, [outletContext])

  // Chapter Custom Redux state
  const dispatch = useDispatch()
  const {isLoading} = useSelector(
    (state: any) => ({isLoading: state.chapter.actionsLoading}),
    shallowEqual
  )

  // if !id we should close modal
  useEffect(() => {
    if (!propsForDelete.id) {
      closingDeleteModal()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propsForDelete.id])

  const deleteAction = () => {
    try {
      // server request for deleting scheduleCustomTest by id
      dispatch<any>(deleteOneChapter(propsForDelete.id)).then(() => {
        // refresh list after deletion
        dispatch<any>(getAllChapterDatatable(queryParams))
        // closing delete modal
        closingDeleteModal()
      })
    } catch (error) {
      closingDeleteModal()
    }
  }

  return (
    <ModalDelete
      title={CHAPTER_DELETE_TITLE}
      confirmText={CHAPTER_DELETE_CONFIRM}
      loadingText={CHAPTER_DELETE_LOADING}
      show={propsForDelete.show}
      onHide={closingDeleteModal}
      deleteAction={deleteAction}
      isLoading={isLoading}
    />
  )
}

export default ChapterDelete
