import React, { Suspense, useMemo } from 'react'
import { GROUP_ROLE_CREATE_TITLE } from '../../../../../_domain/constanta'
import { Link, useNavigate, useOutletContext } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { PageTitle } from '../../../../../_layout/core'
import CustomLoading from '../../../../../_component/customLoading'
import { createGroupRole, getAllGroupRoleDatatable } from './GroupRoleAction'
import { GroupRoleFormInput } from '../../../../../helpers/UIGroupRoleConstanta'

const GroupRoleCreateForm = React.lazy(() => import('./GroupRoleCreateComponent/GroupRoleCreateForm'))

const GroupRoleCreate = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch();

  const filterContext = useOutletContext<any>().queryParams
  const { queryParams } = useMemo(() => {
    return {
      queryParams: filterContext.queryParams,
    };
  }, [filterContext]);

  const saveAction = (data: GroupRoleFormInput) => {
    dispatch<any>(createGroupRole(data)).then(() => {
      // refresh list after deletion
      dispatch<any>(getAllGroupRoleDatatable(queryParams));
      // Back
      navigate("/master/group-role")
    })
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>{GROUP_ROLE_CREATE_TITLE}</PageTitle>
      <div className='d-flex justify-content-between align-items-center mb-4'>
        <div className='w-full'></div>
        <div>
          <Link to={"/master/group-role"}>
            <button type='button'
              className='btn btn-secondary'>
              Back
            </button>
          </Link>
        </div>
      </div>
      <Suspense fallback={<CustomLoading />}>
        <GroupRoleCreateForm onSubmit={saveAction} />
      </Suspense>
    </>
  )
}

export default GroupRoleCreate 
