import React, { FC, useMemo } from 'react'
import { StudentUpdateFormInput } from '../../../../../../../helpers/UIStudentConstanta'
import { Controller, useForm } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import { msgValidationError } from '../../../../../../../helpers/FuncHelpers'
import { CLASS_STUDENT, TYPE_TRYOUT_MAJOR_UI } from '../../../../../../../helpers/UIConstanta'
import { Student } from '../../../../../../../_domain/model/Student'

type props = {
  onSubmit: (req: any) => void
  studentData: Student
}

const StudentProfile: FC<props> = ({ onSubmit, studentData }) => {
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<StudentUpdateFormInput>({
    mode: "onChange",
    defaultValues: useMemo(() => {
      return {
        nameInput: studentData.name,
        genderInput: studentData.gender.toString(),
        classInput: studentData.class_index?.toString(),
        typeTryoutInput: studentData.type_to,
        addressInput: studentData.address
      }
    }, [])
  })

  return <div className='card mb-5 mb-xl-10'>
    <div
      className='card-header border-0 cursor-pointer'
      role='button'
      data-bs-toggle='collapse'
      data-bs-target='#kt_account_profile_details'
      aria-expanded='true'
      aria-controls='kt_account_profile_details'
    >
      <div className='card-title m-0'>
        <h3 className='fw-bolder m-0'>Profile Details</h3>
      </div>
    </div>

    <div id='kt_account_profile_details' className='collapse show'>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className='card-body border-top p-9'>

          {/* Name Field */}
          <Controller
            name="nameInput"
            defaultValue=''
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Name</label>
                <div className='col-lg-8 fv-row'>
                  <input
                    {...field}
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Student name'
                  />
                  {errors.nameInput && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        {msgValidationError(errors.nameInput.type, 'Student Name')}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )} />

          {/* Gender Field */}
          <Controller
            name="genderInput"
            defaultValue=''
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Student Gender</label>

                <div className='col-lg-8 fv-row'>
                  <div className='d-flex justify-content-start'>
                    <div className='form-check form-check-custom form-check-primary form-check-solid form-check-sm ms-10'>
                      <input
                        className='form-check-input'
                        type='radio'
                        value='male'
                        checked={value === "male"}
                        id='check_male'
                        name='gender'
                        onChange={onChange}
                      />
                      <label
                        className='form-check-label text-gray-700 fw-bold text-nowrap'
                        htmlFor='check_male'
                      >
                        Male
                      </label>
                    </div>
                    <div className='form-check form-check-custom form-check-primary form-check-solid form-check-sm ms-10'>
                      <input
                        className='form-check-input'
                        type='radio'
                        value='female'
                        checked={value === "female"}
                        name='gender'
                        onChange={onChange}
                        id='check_female'
                      />
                      <label
                        className='form-check-label text-gray-700 fw-bold text-nowrap'
                        htmlFor='check_female'
                      >
                        Female
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            )} />

          {/* Class Field */}
          <Controller
            name="classInput"
            defaultValue=''
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>Student Class</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <select
                    {...field}
                    className='form-select form-select-solid form-select-lg'
                  >
                    <option value=''>Select a Class...</option>
                    {CLASS_STUDENT.map((item, index) => (
                      <option key={index} value={index}>
                        {item.grade}-{item.level}
                        {item.major !== 'ALL' ? `-${item.major}` : ''}
                      </option>
                    ))}
                  </select>
                  {errors.classInput && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        {msgValidationError(errors.classInput.type, 'Student Class')}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )} />

          {/* Major Tryout Field */}
          <Controller
            name="typeTryoutInput"
            defaultValue=''
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange } }) => (
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Student Tryout Major</label>

                <div className='col-lg-8 fv-row'>
                  <div className='d-flex justify-content-start'>
                    {TYPE_TRYOUT_MAJOR_UI.map((item, index) => (
                      <div key={index} className='form-check form-check-custom form-check-primary form-check-solid form-check-sm ms-10'>
                        <input
                          className='form-check-input'
                          type='radio'
                          value={item.value}
                          checked={value === item.value}
                          name='major_tryout'
                          onChange={onChange}
                        />
                        <label
                          className='form-check-label text-gray-700 fw-bold text-nowrap'
                        >
                          {item.name}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )} />

          {/* Class Field */}
          <Controller
            name="addressInput"
            defaultValue=''
            control={control}
            render={({ field }) => (
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span>Student Address</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <textarea {...field} className="form-control form-control-lg form-control-solid" id="exampleFormControlTextarea1" placeholder='Student Address' rows={3}>
                  </textarea>
                </div>
              </div>

            )} />
        </div>

        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary'>
            Save Change
          </button>
        </div>
      </Form>
    </div>
  </div>
}

export default StudentProfile