/* eslint-disable jsx-a11y/anchor-is-valid */
import { Navigate, Outlet, Route, Routes } from 'react-router'
import { PageTitle } from '../../../../../_layout/core'
import { StudentList } from './StudentList'

// outlet context bank
import { editSectionContext, queryParamsContext, selectedIdscontext, uploadBatchContext } from './_outletContext'
import StudentDetail from './StudentDetail'

const StudentContent = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>{'Student'}</PageTitle>
      <Outlet context={{ filter: queryParamsContext(), uploadBatch: uploadBatchContext(), editSection: editSectionContext(), selectedIds: selectedIdscontext() }} />
    </>
  )
}

const Student = () => {
  return (
    <>
      <Routes>
        <Route element={<StudentContent />}>
          <Route index element={<StudentList />} />
          <Route path='detail/:id' element={<StudentDetail />} />
          {/* <Route path='edit/:studentId/*' element={<StudentEdit />} />
          <Route path='create' element={<StudentCreate />} /> */}
          <Route path='*' element={<Navigate to='/error/404' />} />
        </Route>
      </Routes>
    </>
  )
}

export { Student }
