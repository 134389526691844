import {BaseDatatableRequest} from '../../_data/dataSource/request/BaseDatatableRequest'
import {
  mappingInputFormToTeamCreateRequest,
  mappingInputFormToTeamUpdateRequest,
} from '../../_data/dataSource/request/TeamRequest'
import {BaseDatatableResponse} from '../../_data/dataSource/response/BaseDatatableResponse'
import {TeamResponse} from '../../_data/dataSource/response/TeamResponse'
import {TeamFormInput} from '../../helpers/UITeamConstanta'
import {Team} from '../model/Team'
import {TeamRepository} from '../repository/TeamRepository'

export interface TeamService {
  getDatatableTeam(req: BaseDatatableRequest): Promise<BaseDatatableResponse<Team>>
  getAllTeam(): Promise<Team[]>
  getDetailTeamById(id: number): Promise<Team>
  deleteOneTeamByID(id: number): Promise<string>
  createOneTeam(req: TeamFormInput): Promise<Team>
  updateOneTeam(req: TeamFormInput, id?: string): Promise<Team>
  updateCustomRoleTeam(id: string, req: any): Promise<void>
}

export class TeamServiceImpl implements TeamService {
  private repo: TeamRepository
  constructor(repo: TeamRepository) {
    this.repo = repo
  }
  async updateCustomRoleTeam(id: string, req: any): Promise<void> {
    await this.repo.updateAuthGroup(
      {
        auth: req.authorizationInput,
      },
      id!
    )
  }

  async updateOneTeam(req: TeamFormInput, id?: string): Promise<Team> {
    const body = mappingInputFormToTeamUpdateRequest(req)
    const team = await this.repo.updateOne(body, id)
    if (req.authGroupInput) {
      await this.repo.updateAuthGroup(
        {
          auth_group_id: req.authGroupInput,
        },
        id!
      )
    }
    return team
  }

  async createOneTeam(req: TeamFormInput): Promise<Team> {
    const body = mappingInputFormToTeamCreateRequest(req)
    const team = await this.repo.createOne(body)
    if (req.authGroupInput) {
      await this.repo.updateAuthGroup(
        {
          auth_group_id: req.authGroupInput,
        },
        team.id!
      )
    }
    return team
  }

  async deleteOneTeamByID(id: number): Promise<string> {
    const message = await this.repo.deleteOneById(id)
    return message
  }

  async getDatatableTeam(req: BaseDatatableRequest): Promise<BaseDatatableResponse<Team>> {
    const teams = await this.repo.getDatatable(req)
    return teams
  }

  async getDetailTeamById(id: number): Promise<Team> {
    const team = await this.repo.getOneById(id)
    return team
  }

  async getAllTeam(): Promise<Team[]> {
    const teams = await this.repo.getAll()
    return teams
  }
}
