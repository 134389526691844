const PARENT_DELETE_TITLE = 'Parent Delete'
const PARENT_DELETE_LOADING = 'Parent is deleting...'
const PARENT_DELETE_CONFIRM = 'Are you sure to permanently delete this parent ?'
const PARENT_DELETE_SUCCESS = 'Successful delete parent'
const PARENT_DELETE_FAILED = "Can't delete parent"
const PARENT_CREATE_TITLE = 'Parent Create'
const PARENT_ADD_STUDENT_TITLE = "Add Parent's Student"
const PARENT_UPLOAD_BATCH_TITLE = 'Student Upload Batch'
const PARENT_CREATE_FAILED = "Can't create parent"
const PARENT_CREATE_SUCCESS = 'Successful create parent'
const PARENT_EDIT_TITLE = 'Parent Edit'
const PARENT_EDIT_SUCCESS = 'Successful update parent'
const PARENT_EDIT_FAILED = "Can't update parent"
const PARENT_GET_FAILED = "Can't get parent"
const PARENT_DETAIL_TITLE = 'Parent Detail'
export {
  PARENT_DELETE_TITLE,
  PARENT_UPLOAD_BATCH_TITLE,
  PARENT_DELETE_CONFIRM,
  PARENT_DELETE_LOADING,
  PARENT_DELETE_SUCCESS,
  PARENT_DELETE_FAILED,
  PARENT_CREATE_TITLE,
  PARENT_CREATE_SUCCESS,
  PARENT_CREATE_FAILED,
  PARENT_EDIT_TITLE,
  PARENT_EDIT_SUCCESS,
  PARENT_EDIT_FAILED,
  PARENT_GET_FAILED,
  PARENT_DETAIL_TITLE,
  PARENT_ADD_STUDENT_TITLE,
}
