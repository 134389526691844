import React, { FC, useEffect, useMemo } from 'react'
import { PageTitle } from '../../../../../_layout/core'
import { Suspense } from 'react'
import CustomLoading from '../../../../../_component/customLoading'
import { Link, useNavigate, useOutletContext, useParams } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { getAllTryoutScheduleBatchDatatable, getOneTryoutScheduleBatch, updateTryoutBatchSchedule } from './TryoutScheduleAction'
import { ScheduleTryoutFormInput } from '../../../../../helpers/UIScheduleTryoutConstanta'
import { SCHEDULE_TRYOUT_EDIT_TITLE } from '../../../../../_domain/constanta'

const TryoutScheduleEditBatchForm = React.lazy(() =>
  import('./TryoutScheduleEditComponent/TryoutScheduleEditBatchForm')
)

const TryoutScheduleBacthEdit = () => {
  const { tryoutScheduleId } = useParams()
  const navigate = useNavigate();

  const filterContext = useOutletContext<any>().filter
  const { queryParamsBatch } = useMemo(() => {
    return {
      queryParamsBatch: filterContext.queryParamsBatch,
    };
  }, [filterContext]);

  const dispatch = useDispatch();
  const { actionsLoading, entityBatch } = useSelector(
    (state: any) => ({
      actionsLoading: state.tryoutSchedule.actionsLoading,
      entityBatch: state.tryoutSchedule.entityBatch,
    }),
    shallowEqual
  );
  // if !id we should close modal
  useEffect(() => {
    dispatch<any>(getOneTryoutScheduleBatch(tryoutScheduleId))
    return () => {
      dispatch<any>(getOneTryoutScheduleBatch(undefined))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tryoutScheduleId]);

  const saveAction = (data: ScheduleTryoutFormInput) => {
    if (tryoutScheduleId) {
      dispatch<any>(updateTryoutBatchSchedule(data, tryoutScheduleId)).then(() => {
        // refresh list after deletion
        dispatch<any>(getAllTryoutScheduleBatchDatatable(queryParamsBatch));
        // Back
        navigate("/tryout/schedule")
      })
    }
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>{SCHEDULE_TRYOUT_EDIT_TITLE}</PageTitle>
      <div className='d-flex justify-content-between align-items-center mb-4'>
        <div className='w-full'></div>
        <div>
          <Link to={"/tryout/schedule"}>
            <button type='button'
              className='btn btn-secondary'>
              Back
            </button>
          </Link>
        </div>
      </div>
      <Suspense fallback={<CustomLoading />}>
        {entityBatch ? <TryoutScheduleEditBatchForm dataTryoutSchedule={entityBatch} onSubmit={saveAction} /> : null}
      </Suspense>
    </>
  )
}

export { TryoutScheduleBacthEdit }
