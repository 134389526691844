/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { PageTitle } from '../../../../../_layout/core'
import { getAllFacilityDatatable } from './FacilityAction'
import { Suspense, lazy } from 'react'
import CustomLoading from '../../../../../_component/customLoading'
import { useOutletContext } from 'react-router-dom'
import { BaseDatatableResponse } from '../../../../../_data/dataSource/response/BaseDatatableResponse'
import { Facility } from '../../../../../_domain/model/Facility'
import FacilityDelete from './FacilityDelete'
import { useAuth } from '../../../../../_context/AuthContext'
import { checkAuthActionMenu } from '../../../../../helpers/AuthHelpers'
import { SLUG_MASTER_SUB_MENU } from '../../../../../helpers/UIAuthGroupConstanta'

const FacilityListTable = React.lazy(() => import('./FacilityListComponent/FacilityListTable'))
const FacilityCreate = React.lazy(() => import('./FacilityCreate'))
const FacilityEdit = React.lazy(() => import('./FacilityEdit'))

const checkReduxData = () => {
  const outletContext = useOutletContext<any>().queryParams
  const { queryParams } = outletContext

  const [facilityListData, setFacilityListData] = useState<any>(null)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch<any>(getAllFacilityDatatable(queryParams)).then((response: BaseDatatableResponse<Facility>) => {
      setFacilityListData(response.entities)
    })
  }, [queryParams, getAllFacilityDatatable])

  return facilityListData
  // detect redux first before fetch API END
}

const FacilityList = () => {
  let data = checkReduxData()
  const { currentUser, authorization } = useAuth()
  return checkAuthActionMenu(SLUG_MASTER_SUB_MENU.MASTER_FASILITY_MENU, "view", currentUser, authorization) ? (
    <>
      <FacilityDelete />
      <FacilityCreate />
      <FacilityEdit />
      <PageTitle breadcrumbs={[]}>{'Facility List'}</PageTitle>
      <Suspense fallback={<CustomLoading />}>
        {data ? <FacilityListTable /> : null}
      </Suspense>
    </>
  ) : null
}

export { FacilityList }
