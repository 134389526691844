import ChapterDataSource from '../../_data/dataSource/ChapterDataSoruce'
import {BaseDatatableRequest} from '../../_data/dataSource/request/BaseDatatableRequest'
import {
  ChapterCreateRequest,
  ChapterFileRequest,
  ChapterFilterRequest,
  ChapterUpdateRequest,
} from '../../_data/dataSource/request/ChapterRequest'
import {BaseDatatableResponse} from '../../_data/dataSource/response/BaseDatatableResponse'
import {createChapterFileResponse} from '../../_data/dataSource/response/ChapterResponse'
import {IChapterfiles} from '../../types'
import {Chapter, ChapterBuilder} from '../model/Chapter'
import {ChapterRepository} from './ChapterRepository'

export class ChapterRepositoryImpl implements ChapterRepository {
  datasource: ChapterDataSource

  constructor(datasource: ChapterDataSource) {
    this.datasource = datasource
  }

  async updateOne(req: ChapterUpdateRequest, id: string): Promise<Chapter> {
    const data = await this.datasource.update(req, id)
    const initBuilder = new ChapterBuilder()
    const chapter = initBuilder.SingleData(data).Result()
    return chapter
  }

  async createOne(req: ChapterCreateRequest): Promise<Chapter> {
    const data = await this.datasource.create(req)
    const initBuilder = new ChapterBuilder()
    const chapter = initBuilder.SingleData(data).Result()
    return chapter
  }

  deleteOneById(id: string): Promise<string> {
    return this.datasource.deleteOne(id)
  }

  async getDatatable(req: BaseDatatableRequest): Promise<BaseDatatableResponse<Chapter>> {
    const data = await this.datasource.getDatatable(req)
    const initBuilder = new ChapterBuilder()
    const entities = initBuilder.ListData(data.entities).ResultList()
    const totalCount = data.totalCount

    return {
      entities,
      totalCount,
      errorMessage: '',
    }
  }

  async getOneById(id: string): Promise<Chapter> {
    const data = await this.datasource.getOne(id)
    const initBuilder = new ChapterBuilder()
    const chapter = initBuilder.SingleData(data).Result()
    return chapter
  }

  async getAll(params: ChapterFilterRequest): Promise<Chapter[]> {
    const response = await this.datasource.getAll(params)
    const initBuilder = new ChapterBuilder()
    const chapters = initBuilder.ListData(response).ResultList()
    return chapters
  }

  async createChapterFiles(
    req: ChapterFileRequest,
    type: 'ebook' | 'video'
  ): Promise<createChapterFileResponse> {
    const data = await this.datasource.createChapterFiles(req, type)

    return data
  }

  async getAllChapterFilesDatatable(
    req: BaseDatatableRequest,
    type: 'ebook' | 'video' | null
  ): Promise<BaseDatatableResponse<IChapterfiles>> {
    const data = await this.datasource.getAllChapterFilesDatatable(req, type)
    const entities = data.entities
    const totalCount = data.totalCount

    return {
      entities,
      totalCount,
      errorMessage: '',
    }
  }
}
