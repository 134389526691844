import axios from 'axios'
import StudentRoleDataSource from './StudentRoleDataSoruce'
import {StudentGroupRoleResponse, StudentRoleResponse} from './response/StudentRoleResponse'
import {ApiResponse} from './response/BaseResponse'
import {GroupRoleCreateRequest, StudenRoleAppendRequest} from './request/StudentRoleRequest'
import {BaseDatatableRequest} from './request/BaseDatatableRequest'
import {BaseDatatableResponse} from './response/BaseDatatableResponse'

export default class StudentRoleDataSourceImpl implements StudentRoleDataSource {
  private STUDENT_ROLE_URL: string
  private STUDENT_GROUP_ROLE_URL: string

  constructor() {
    const REACT_APP_STUDENT_ROLE_SERVICE_URL =
      process.env.REACT_APP_STUDENT_ROLE_SERVICE_URL || 'api/'
    this.STUDENT_ROLE_URL = `${REACT_APP_STUDENT_ROLE_SERVICE_URL}student-roles`
    this.STUDENT_GROUP_ROLE_URL = `${REACT_APP_STUDENT_ROLE_SERVICE_URL}group-roles`
  }
  async changeGroupRole(user_id: number, group_id: string): Promise<StudentRoleResponse> {
    const response = await axios.put<ApiResponse<StudentRoleResponse>>(
      `${this.STUDENT_ROLE_URL}/${user_id}/group-role`,
      {
        group_id,
      }
    )
    const data = response.data.data
    return data
  }

  async getAllGroupRole(params?: any): Promise<StudentGroupRoleResponse[]> {
    const response = await axios.get<ApiResponse<StudentGroupRoleResponse[]>>(
      `${this.STUDENT_GROUP_ROLE_URL}`
    )
    const data = response.data.data
    return data
  }

  async updateGroupRole(
    id: string,
    req: GroupRoleCreateRequest
  ): Promise<StudentGroupRoleResponse> {
    const response = await axios.put<ApiResponse<StudentGroupRoleResponse>>(
      `${this.STUDENT_GROUP_ROLE_URL}/${id}`,
      req
    )
    const data = response.data.data
    return data
  }

  async getOneGroupRole(id: string): Promise<StudentGroupRoleResponse> {
    const response = await axios.get<ApiResponse<StudentGroupRoleResponse>>(
      `${this.STUDENT_GROUP_ROLE_URL}/${id}`
    )
    const data = response.data.data
    return data
  }

  async createGroupRole(req: GroupRoleCreateRequest): Promise<StudentGroupRoleResponse> {
    const response = await axios.post<ApiResponse<StudentGroupRoleResponse>>(
      `${this.STUDENT_GROUP_ROLE_URL}`,
      req
    )
    const data = response.data.data
    return data
  }

  async getDatatable(
    req: BaseDatatableRequest
  ): Promise<BaseDatatableResponse<StudentGroupRoleResponse>> {
    const response = await axios.post<ApiResponse<BaseDatatableResponse<StudentGroupRoleResponse>>>(
      `${this.STUDENT_GROUP_ROLE_URL}/datatable`,
      req
    )

    const data = response.data.data

    return data
  }

  async updateRole(user_id: number, req: StudenRoleAppendRequest): Promise<StudentRoleResponse> {
    const response = await axios.put<ApiResponse<StudentRoleResponse>>(
      `${this.STUDENT_ROLE_URL}/${user_id}`,
      req
    )
    const data = response.data.data
    return data
  }

  async createRole(req: StudenRoleAppendRequest): Promise<StudentRoleResponse> {
    const response = await axios.post<ApiResponse<StudentRoleResponse>>(
      `${this.STUDENT_ROLE_URL}`,
      req
    )
    const data = response.data.data
    return data
  }

  async getOneByUserId(user_id: number): Promise<StudentRoleResponse> {
    const response = await axios.get<ApiResponse<StudentRoleResponse>>(
      `${this.STUDENT_ROLE_URL}/user/${user_id}`
    )
    const data = response.data.data
    return data
  }
}
