import React, { FC } from 'react'
import { useParams } from 'react-router-dom'
import { UserModel } from '../../../../../../_domain/model/Auth'
import OrderTable from '../../../../../../_component/Table/OrderTable/OrderTable'
import { defaultQueryParams } from '../../../../../../_data/dataSource/request/BaseDatatableRequest'

type props = {
  entityUser: UserModel
}
const init = defaultQueryParams()
init.pageSize = 10
init.sortField = "id"
init.sortOrder = "desc"
init.filter = {
  referral_id: -1
}
const PartnerOrder: FC<props> = ({ entityUser }) => {
  init.filter.referral_id = entityUser.referral?.id ?? -1;  

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Order's Referral</span>
        </h3>
        <div className='card-toolbar'>

        </div>
      </div>
      <div className='card-body py-3'>
        <OrderTable selectableRows={false} initFilter={init} selectedOrderCallback={function (val: any[]): void {
        }} singleSelected={true} />
      </div>
    </div>
  )
}

export default PartnerOrder