import {ProvinceResponse} from '../../_data/dataSource/response/ProvinceResponse'

export type Province = {
  id: string
  name: string
}

export class ProvinceBuilder {
  private SingleProvince!: Province
  private ProvinceList!: Province[]
  constructor() {}

  SingleData(provinceResponse: ProvinceResponse): ProvinceBuilder {
    const province = mapingProvince(provinceResponse)
    this.SingleProvince = province
    return this
  }
  ListData(provinceResponse: ProvinceResponse[]): ProvinceBuilder {
    const provinces = mapingProvinces(provinceResponse)
    this.ProvinceList = provinces
    return this
  }
  Result(): Province {
    return this.SingleProvince
  }
  ResultList(): Province[] {
    return this.ProvinceList
  }
}

function mapingProvince(provinceResponse: ProvinceResponse): Province {
  const province: Province = {
    id: provinceResponse.id,
    name: provinceResponse.name,
  }
  return province
}

function mapingProvinces(provincesResponse: ProvinceResponse[]): Province[] {
  const provinces = provincesResponse.map((item) => {
    return mapingProvince(item)
  })
  return provinces
}
