import React, { Suspense, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useNavigate, useOutletContext } from 'react-router-dom'
import { PageTitle } from '../../../../../_layout/core'
import { ORDER_CREATE_TITLE } from '../../../../../_domain/constanta'
import CustomLoading from '../../../../../_component/customLoading'
import { OrderCreateFormInput } from '../../../../../helpers/UIOrderConstanta'
import { createOrder, getAllOrderDatatable } from './OrderAction'

const OrderCreateForm = React.lazy(() => import('./OrderCreateComponent/OrderCreateForm'))

const OrderCreate = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const filterContext = useOutletContext<any>().queryParams
  const { queryParams } = useMemo(() => {
    return {
      queryParams: filterContext.queryParams,
    };
  }, [filterContext]);

  const saveAction = (data: OrderCreateFormInput) => {
    dispatch<any>(createOrder(data)).then(() => {
      // refresh list after deletion
      dispatch<any>(getAllOrderDatatable(queryParams));
      // Back
      navigate("/transaction/order")
    })
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>{ORDER_CREATE_TITLE}</PageTitle>
      <div className='d-flex justify-content-between align-items-center mb-4'>
        <div className='w-full'></div>
        <div>
          <Link to={"/transaction/order"}>
            <button type='button'
              className='btn btn-secondary'>
              Back
            </button>
          </Link>
        </div>
      </div>
      <Suspense fallback={<CustomLoading />}>
        <OrderCreateForm onSubmit={saveAction} />
      </Suspense>
    </>
  )
}

export default OrderCreate 