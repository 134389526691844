import CityDataSource from '../../_data/dataSource/CityDataSoruce'
import { BaseDatatableRequest } from '../../_data/dataSource/request/BaseDatatableRequest'
import { CityGetAllParams } from '../../_data/dataSource/request/CityRequest'
import { BaseDatatableResponse } from '../../_data/dataSource/response/BaseDatatableResponse'
import { City, CityBuilder } from '../model/City'
import {CityRepository} from './CityRepository'

export class CityRepositoryImpl implements CityRepository {
  datasource: CityDataSource

  constructor(datasource: CityDataSource) {
    this.datasource = datasource
  }
  getDatatable(req: BaseDatatableRequest): Promise<BaseDatatableResponse<City>> {
    throw new Error('Method not implemented.')
  }
  async getAll(params?: CityGetAllParams): Promise<City[]> {
    const response = await this.datasource.getAll(params)
    const initBuilder = new CityBuilder()
    const data = initBuilder.ListData(response).ResultList()
    return data
  }
  getOneById(id: string | number): Promise<City> {
    throw new Error('Method not implemented.')
  }
  deleteOneById(id: string | number): Promise<string> {
    throw new Error('Method not implemented.')
  }
  createOne(req: any): Promise<City> {
    throw new Error('Method not implemented.')
  }
  updateOne(req: any): Promise<City> {
    throw new Error('Method not implemented.')
  }
}
