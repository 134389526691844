import { useState } from "react"
import useFilterTable from "../../../../../_hooks/useFilterTable"
import useDeleteSelectionDialog from "../../../../../_hooks/useDeleteSelectionDialog"
import { defaultQueryParams } from "../../../../../_data/dataSource/request/BaseDatatableRequest"

const init = defaultQueryParams()
init.sortField = "id"
init.sortOrder = "desc"
export const queryParamsContext = () => {
    const value = useFilterTable({
        initQueryParams: init
    })

    return value
}

export const deleteSectionContext = () => {
    const value = useDeleteSelectionDialog()

    return value
}

export const editInstallmentContext = () => {
    const [propsForEditInstallment, setPropsForEditInstallment] = useState({
        id: '',
        order_id: '',
        amount: 0,
        show: false,
    })

    function showingEditInstallmentModal(id: string, order_id: string, amount: number) {
        setPropsForEditInstallment({
            id: id,
            order_id: order_id,
            amount: amount,
            show: true,
        })
    }

    function closingEditInstallmentModal() {
        setPropsForEditInstallment({
            id: '',
            order_id: '',
            amount: 0,
            show: false,
        })
    }

    return {
        propsForEditInstallment,
        setPropsForEditInstallment,
        showingEditInstallmentModal,
        closingEditInstallmentModal,
    }
}

export const deleteInstallmentContext = () => {
    const [propsForDeleteInstallment, setPropsForDeleteInstallment] = useState({
        id: '',
        order_id: '',
        show: false,
    })

    function showingDeleteInstallmentModal(id: string, order_id: string) {
        setPropsForDeleteInstallment({
            id: id,
            order_id: order_id,
            show: true,
        })
    }

    function closingDeleteInstallmentModal() {
        setPropsForDeleteInstallment({
            id: '',
            order_id: '',
            show: false,
        })
    }

    return {
        propsForDeleteInstallment,
        setPropsForDeleteInstallment,
        showingDeleteInstallmentModal,
        closingDeleteInstallmentModal,
    }
}

export const cancelInstallmentContext = () => {
    const [propsForCancelInstallment, setPropsForCancelInstallment] = useState({
        id: '',
        order_id: '',
        show: false,
    })

    function showingCancelInstallmentModal(id: string, order_id: string) {
        setPropsForCancelInstallment({
            id: id,
            order_id: order_id,
            show: true,
        })
    }

    function closingCancelInstallmentModal() {
        setPropsForCancelInstallment({
            id: '',
            order_id: '',
            show: false,
        })
    }

    return {
        propsForCancelInstallment,
        setPropsForCancelInstallment,
        showingCancelInstallmentModal,
        closingCancelInstallmentModal,
    }
}

export const statusInstallmentContext = () => {
    const [propsForStatusInstallment, setPropsForStatusInstallment] = useState({
        id: '',
        order_id: '',
        show: false,
    })

    function showingStatusInstallmentModal(id: string, order_id: string) {
        setPropsForStatusInstallment({
            id: id,
            order_id: order_id,
            show: true,
        })
    }

    function closingStatusInstallmentModal() {
        setPropsForStatusInstallment({
            id: '',
            order_id: '',
            show: false,
        })
    }

    return {
        propsForStatusInstallment,
        setPropsForStatusInstallment,
        showingStatusInstallmentModal,
        closingStatusInstallmentModal,
    }
}