import BannerDataSource from '../../_data/dataSource/BannerDataSoruce'
import {BaseDatatableRequest} from '../../_data/dataSource/request/BaseDatatableRequest'
import {
  BannerCreateRequest,
  BannerUpdateRequest,
} from '../../_data/dataSource/request/BannerRequest'
import {BaseDatatableResponse} from '../../_data/dataSource/response/BaseDatatableResponse'
import {Banner, BannerBuilder} from '../model/Banner'
import {BannerRepository} from './BannerRepository'

export class BannerRepositoryImpl implements BannerRepository {
  datasource: BannerDataSource
  constructor(datasource: BannerDataSource) {
    this.datasource = datasource
  }

  async updateOne(req: BannerUpdateRequest, id?: string): Promise<Banner> {
    const response = await this.datasource.update(req, id)
    const initBuilder = new BannerBuilder()
    const banner = initBuilder.SingleData(response).Result()
    return banner
  }

  async updateImage(id: string, files: any[], indexes: any[]): Promise<void> {
    this.datasource.uploadImage(id, files, indexes)
    // const initBuilder = new BannerBuilder()
    // const banner = initBuilder.SingleData(response).Result()
    // return banner
  }

  async createOne(req: BannerCreateRequest): Promise<Banner> {
    const response = await this.datasource.create(req)
    const initBuilder = new BannerBuilder()
    const banner = initBuilder.SingleData(response).Result()
    return banner
  }

  deleteOneById(id: string): Promise<string> {
    return this.datasource.deleteOne(id)
  }

  async getDatatable(req: BaseDatatableRequest): Promise<BaseDatatableResponse<Banner>> {
    const data = await this.datasource.getDatatable(req)
    const initBuilder = new BannerBuilder()
    const entities = initBuilder.ListData(data.entities).ResultList()
    const totalCount = data.totalCount
    return {
      entities,
      totalCount,
      errorMessage: data.errorMessage,
    }
  }

  async getOneById(id: string): Promise<Banner> {
    const resposne = await this.datasource.getOne(id)
    const initBuilder = new BannerBuilder()
    const banner = initBuilder.SingleData(resposne).Result()
    return banner
  }

  async getAll(): Promise<Banner[]> {
    const response = await this.datasource.getAll()
    const initBuilder = new BannerBuilder()
    const banners = initBuilder.ListData(response).ResultList()
    return banners
  }
}
