const GROUP_CLASS_DELETE_TITLE = 'Group Class Delete'
const GROUP_CLASS_DELETE_LOADING = 'Group Class is deleting...'
const GROUP_CLASS_DELETE_CONFIRM = 'Are you sure to permanently delete this group class ?'
const GROUP_CLASS_DELETE_SUCCESS = 'Successful delete group class'
const GROUP_CLASS_DELETE_FAILED = "Can't delete group class"
const GROUP_CLASS_CREATE_TITLE = 'Group Class Create'
const GROUP_CLASS_UPLOAD_BATCH_TITLE = 'Student Upload Batch'
const GROUP_CLASS_CREATE_FAILED = "Can't create group class"
const GROUP_CLASS_CREATE_SUCCESS = 'Successful create group class'
const GROUP_CLASS_EDIT_TITLE = 'Group Class Edit'
const GROUP_CLASS_EDIT_SUCCESS = 'Successful update group class'
const GROUP_CLASS_EDIT_FAILED = "Can't update group class"
const GROUP_CLASS_GET_FAILED = "Can't get group class"

export {
  GROUP_CLASS_DELETE_TITLE,
  GROUP_CLASS_UPLOAD_BATCH_TITLE,
  GROUP_CLASS_DELETE_CONFIRM,
  GROUP_CLASS_DELETE_LOADING,
  GROUP_CLASS_DELETE_SUCCESS,
  GROUP_CLASS_DELETE_FAILED,
  GROUP_CLASS_CREATE_TITLE,
  GROUP_CLASS_CREATE_SUCCESS,
  GROUP_CLASS_CREATE_FAILED,
  GROUP_CLASS_EDIT_TITLE,
  GROUP_CLASS_EDIT_SUCCESS,
  GROUP_CLASS_EDIT_FAILED,
  GROUP_CLASS_GET_FAILED,
}
