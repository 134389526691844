const AUTH_GROUP_DELETE_TITLE = 'Authorization Group Delete'
const AUTH_GROUP_DELETE_LOADING = 'Authorization Group is deleting...'
const AUTH_GROUP_DELETE_CONFIRM = 'Are you sure to permanently delete this authorization group ?'
const AUTH_GROUP_DELETE_SUCCESS = 'Successful delete authorization group'
const AUTH_GROUP_DELETE_FAILED = "Can't delete authorization group"
const AUTH_GROUP_CREATE_TITLE = 'Authorization Group Create'
const AUTH_GROUP_UPLOAD_BATCH_TITLE = 'Student Upload Batch'
const AUTH_GROUP_CREATE_FAILED = "Can't create authorization group"
const AUTH_GROUP_CREATE_SUCCESS = 'Successful create authorization group'
const AUTH_GROUP_EDIT_TITLE = 'Authorization Group Edit'
const AUTH_GROUP_EDIT_SUCCESS = 'Successful update authorization group'
const AUTH_GROUP_EDIT_FAILED = "Can't update authorization group"
const AUTH_GROUP_GET_FAILED = "Can't get authorization group"
export {
  AUTH_GROUP_DELETE_TITLE,
  AUTH_GROUP_UPLOAD_BATCH_TITLE,
  AUTH_GROUP_DELETE_CONFIRM,
  AUTH_GROUP_DELETE_LOADING,
  AUTH_GROUP_DELETE_SUCCESS,
  AUTH_GROUP_DELETE_FAILED,
  AUTH_GROUP_CREATE_TITLE,
  AUTH_GROUP_CREATE_SUCCESS,
  AUTH_GROUP_CREATE_FAILED,
  AUTH_GROUP_EDIT_TITLE,
  AUTH_GROUP_EDIT_SUCCESS,
  AUTH_GROUP_EDIT_FAILED,
  AUTH_GROUP_GET_FAILED,
}
