import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  entity: undefined,
  entityUser: undefined,
  lastError: null,
}

const callTypes = {
  list: 'list',
  action: 'action',
}

const slice = createSlice({
  name: 'partners',
  initialState: initialState,
  reducers: {
    // Redux for starting
    startCall: (state: any, action) => {
      state.error = null
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true
      } else {
        state.actionsLoading = true
      }
    },
    // Redux for stopping
    stopCall: (state: any, action) => {
      state.error = null
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false
      } else {
        state.actionsLoading = false
      }
    },
    catchError: (state: any, action) => {
      state.error = `${action.type}: ${action.payload.error}`
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false
      } else {
        state.actionsLoading = false
      }
    },
    // GetProductMany
    saveList: (state: any, action) => {
      const {totalCount, entities} = action.payload
      state.listLoading = false
      state.error = null
      state.entities = entities
      state.totalCount = totalCount
    },
    // GetProductById
    saveObject: (state: any, action) => {
      state.actionsLoading = false
      state.entity = action.payload.data
      state.error = null
    },
    saveObjectUser: (state: any, action) => {
      state.actionsLoading = false
      state.entityUser = action.payload.obj
      state.error = null
    },
    // CreateProduct
    createObject: (state: any, action) => {
      state.actionsLoading = false
      state.error = null
      state.entities.push(action.payload.data)
    },
    // UpdateProduct
    updateObject: (state: any, action) => {      
      state.error = null
      state.actionsLoading = false
      state.entities = state.entities.map((entity: any) => {
        if (entity.id === action.payload.data.id) {
          return action.payload.data
        }
        return entity
      })
    },
    // DeleteProduct
    deleteObject: (state: any, action) => {
      state.error = null
      state.actionsLoading = false
      state.entities = state.entities.filter((el: any) => el.id !== action.payload.id)
    },
    // deleteProductMany
    deleteMany: (state: any, action) => {
      state.error = null
      state.actionsLoading = false
      state.entities = state.entities.filter((el: any) => !action.payload.ids.includes(el.id))
    },
  },
})

export const partners = {
  callTypes,
  slice,
}
