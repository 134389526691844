const PARTNER_DELETE_TITLE = 'Partner Delete'
const PARTNER_DELETE_LOADING = 'Partner is deleting...'
const PARTNER_DELETE_CONFIRM = 'Are you sure to permanently delete this partner ?'
const PARTNER_DELETE_SUCCESS = 'Successful delete partner'
const PARTNER_DELETE_FAILED = "Can't delete partner"
const PARTNER_CREATE_TITLE = 'Partner Create'
const PARTNER_UPLOAD_BATCH_TITLE = 'Student Upload Batch'
const PARTNER_CREATE_FAILED = "Can't create partner"
const PARTNER_CREATE_SUCCESS = 'Successful create partner'
const PARTNER_EDIT_TITLE = 'Partner Edit'
const PARTNER_EDIT_SUCCESS = 'Successful update partner'
const PARTNER_EDIT_FAILED = "Can't update partner"
const PARTNER_GET_FAILED = "Can't get partner"
const PARTNER_DETAIL_TITLE = "Partner Detail"
export {
  PARTNER_DELETE_TITLE,
  PARTNER_UPLOAD_BATCH_TITLE,
  PARTNER_DELETE_CONFIRM,
  PARTNER_DELETE_LOADING,
  PARTNER_DELETE_SUCCESS,
  PARTNER_DELETE_FAILED,
  PARTNER_CREATE_TITLE,
  PARTNER_CREATE_SUCCESS,
  PARTNER_CREATE_FAILED,
  PARTNER_EDIT_TITLE,
  PARTNER_EDIT_SUCCESS,
  PARTNER_EDIT_FAILED,
  PARTNER_GET_FAILED,
  PARTNER_DETAIL_TITLE
}
