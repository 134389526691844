import {TeacherFormInput} from '../../../helpers/UITeacherConstanta'

export type TeacherCreateRequest = {
  name: string
  email: string
  password: string
  phone_number: string
  gender: string
  address: string
  status: boolean
  subject_ids: {
    _id: string
    name: string
  }[]
}

export type TeacherUpdateRequest = {
  name: string
  email: string
  gender: string
  phone_number: string
  address: string
  status: boolean
  subject_ids: {
    _id: string
    name: string
  }[]
}

export function mappingInputFormToTeacherCreateRequest(
  req: TeacherFormInput
): TeacherCreateRequest {
  return {
    name: req.nameInput ?? '',
    email: req.emailInput ?? '',
    password: 'qonstanta',
    phone_number: '+62' + req.phoneNumberInput ?? '',
    address: req.addressInput ?? '',
    gender: req.genderInput ?? 'male',
    status: true,
    subject_ids:
      req.subjectsInput?.map((item) => {
        return {
          _id: item.id,
          name: item.name,
        }
      }) ?? [],
  }
}
export function mappingInputFormToTeacherUpdateRequest(
  req: TeacherFormInput
): TeacherUpdateRequest {
  return {
    name: req.nameInput ?? '',
    email: req.emailInput ?? '',
    phone_number: '+62' + req.phoneNumberInput ?? '',
    address: req.addressInput ?? '',
    gender: req.genderInput ?? 'male',
    status: req.statusIbput === 'true' ? true : false,
    subject_ids:
      req.subjectsInput?.map((item) => {
        return {
          _id: item.id,
          name: item.name,
        }
      }) ?? [],
  }
}
