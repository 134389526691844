import {useCallback, useEffect, useState} from 'react'
import ScheduleTryoutDataSourceImpl from '../_data/dataSource/ScheduleTryoutDataSourceImpl'
import {ScheduleTryoutFilterRequest} from '../_data/dataSource/request/ScheduleTryoutRequest'
import {ScheduleTryoutRepositoryImpl} from '../_domain/repository/ScheduleTryoutRepositoryImpl'
import { Schedule } from '../_domain/model/Schedule'

type Props = {
  params?: ScheduleTryoutFilterRequest
}

const useScheduleTryout = (props?: Props) => {
  const [list, setList] = useState<Schedule[]>([])

  const dataSource = new ScheduleTryoutDataSourceImpl()
  const repo = new ScheduleTryoutRepositoryImpl(dataSource)

  async function getAllScheduleTryout(params?: ScheduleTryoutFilterRequest) {
    const response = await repo.getAllFilter(params!)
    setList(response)
  }
  async function getAllScheduleTryoutBatch(params?: ScheduleTryoutFilterRequest) {
    const response = await repo.getAllFilterBatch(params!)
    setList(response)
  }

  const filterSchedule = useCallback(
    (params?: ScheduleTryoutFilterRequest, typeResult?: string) => {
      if (typeResult === 'batch') {
        getAllScheduleTryoutBatch(params)
      } else {
        getAllScheduleTryout(params)
      }
    },
    [getAllScheduleTryout]
  )

  useEffect(() => {
    if (props?.params) {
      filterSchedule(props.params)
    }
  }, [])

  return {
    listScheduleTryout: list,
    getAllScheduleTryout,
    filterSchedule,
  }
}

export default useScheduleTryout
