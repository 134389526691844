import {BaseDatatableRequest} from '../../_data/dataSource/request/BaseDatatableRequest'
import {BaseDatatableResponse} from '../../_data/dataSource/response/BaseDatatableResponse'
import {ResultTryoutScoreResponse} from '../../_data/dataSource/response/ResultResponse'
import {Result} from '../model/Result'
import {ResultTryoutRepository} from '../repository/ResultTryoutRepository'

export interface ResultTryoutService {
  getDatatableResultTryout(req: BaseDatatableRequest): Promise<BaseDatatableResponse<Result>>
  getDatatableResultTryoutBatchScore(
    req: BaseDatatableRequest
  ): Promise<BaseDatatableResponse<ResultTryoutScoreResponse>>
  getDatatableResultTryoutBatch(req: BaseDatatableRequest): Promise<BaseDatatableResponse<Result>>
  getOneResult(scheduleId: string, userId: string): Promise<Result>
  calculate(id: string | number): Promise<string>
  calculateBatch(id: string | number): Promise<string>
  // getAllResultTryout(): Promise<ResultTryout[]>
  // getDetailResultTryoutById(id: string): Promise<ResultTryout>
  deleteOneResultTryoutByID(id: string): Promise<string>
  // createOneResultTryout(req: ResultTryout): Promise<ResultTryout>
  // updateOneResultTryout(req: ResultTryout): Promise<ResultTryout>
}

export class ResultTryoutServiceImpl implements ResultTryoutService {
  private repo: ResultTryoutRepository
  constructor(repo: ResultTryoutRepository) {
    this.repo = repo
  }
  async getOneResult(scheduleId: string, userId: string): Promise<Result> {
    return await this.repo.getOneResult(scheduleId, userId)
  }

  async calculate(id: string | number): Promise<string> {
    return await this.repo.calculate(id)
  }

  async calculateBatch(id: string | number): Promise<string> {
    return await this.repo.calculateBatch(id)
  }

  async deleteOneResultTryoutByID(id: string): Promise<string> {
    return await this.repo.deleteOneById(id)
  }

  async getDatatableResultTryoutBatchScore(
    req: BaseDatatableRequest
  ): Promise<BaseDatatableResponse<ResultTryoutScoreResponse>> {
    return await this.repo.getDatatableBatchScore(req)
  }

  async getDatatableResultTryout(
    req: BaseDatatableRequest
  ): Promise<BaseDatatableResponse<Result>> {
    const resultTryouts = await this.repo.getDatatable(req)
    return resultTryouts
  }

  async getDatatableResultTryoutBatch(
    req: BaseDatatableRequest
  ): Promise<BaseDatatableResponse<Result>> {
    const resultTryouts = await this.repo.getDatatableBatch(req)
    return resultTryouts
  }
}
