import React, { FC, useState } from 'react'
import { StudentAccountUpdateFormInput } from '../../../../../../../helpers/UIStudentConstanta'
import { Controller, useForm } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import { msgValidationError } from '../../../../../../../helpers/FuncHelpers'
import { KTIcon } from '../../../../../../../_layout/helpers'

type props = {
  onSubmit: (req: any) => void
}

const StudentPasswordForm: FC<props> = ({ onSubmit }) => {
  const [showPasswordForm, setShowPasswordForm] = useState(false)
  const [showPassword, setShowPassword] = useState(false)

  const { handleSubmit, control, formState: { errors } } = useForm<StudentAccountUpdateFormInput>({
    mode: "onChange",
  })
  return (
    <div className='d-flex flex-wrap align-items-center mb-10'>
      <div className={' ' + (showPasswordForm && 'd-none')}>
        <div className='fs-6 fw-bolder mb-1'>Password</div>
        <div className='fw-bold text-gray-600'>********</div>
      </div>

      <div
        className={'flex-row-fluid ' + (!showPasswordForm && 'd-none')}
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className='row mb-1'>
            {/* Password Field */}
            <Controller
              name="passwordInput"
              defaultValue=''
              control={control}
              rules={{ required: true, minLength: 8 }}
              render={({ field }) => (
                <div className='col-lg-12'>
                  <div className='fv-row mb-0'>
                    <label htmlFor='newpassword' className='form-label fs-6 fw-bolder mb-3'>
                      New Password
                    </label>
                    <div className="input-group mb-3">
                      <input
                        {...field}
                        type={showPassword ? "text" : "password"}
                        className='form-control form-control-lg form-control-solid '
                        id='newpassword'
                      />
                      <button onClick={() => setShowPassword(!showPassword)} className="btn btn-light-primary" type="button" id="button-addon2">
                        <KTIcon className='fs-1' iconName={showPassword ? "eye-slash" : "eye"} />
                      </button>
                    </div>
                    {errors.passwordInput && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          {msgValidationError(errors.passwordInput.type, 'Password')}
                        </div>
                      </div>
                    )}
                  </div>
                </div>

              )} />
          </div>
          <div className='form-text mb-5'>
            Password must be at least 8 character
          </div>

          <div className='d-flex'>
            <button
              type='submit'
              className='btn btn-primary me-2 px-6'
            >
              Update Password
            </button>
            <button
              onClick={() => {
                setShowPasswordForm(false)
              }}
              type='button'
              className='btn btn-color-gray-400 btn-active-light-primary px-6'
            >
              Cancel
            </button>
          </div>
        </Form>
      </div>

      <div
        className={'ms-auto ' + (showPasswordForm && 'd-none')}
      >
        <button
          onClick={() => {
            setShowPasswordForm(true)
          }}
          className='btn btn-light btn-active-light-primary'
        >
          Change Password
        </button>
      </div>
    </div>
  )
}
export default StudentPasswordForm