import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  totalCountBatch: 0,
  entities: [],
  entitiesBatch: [],
  entity: undefined,
  entityBatch: undefined,
  lastError: null,
}

const callTypes = {
  list: 'list',
  action: 'action',
}

const slice = createSlice({
  name: 'tryoutSchedule',
  initialState: initialState,
  reducers: {
    // Redux for starting
    startCall: (state: any, action) => {
      state.error = null
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true
      } else {
        state.actionsLoading = true
      }
    },
    stopCall: (state: any, action) => {
      state.error = null
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false
      } else {
        state.actionsLoading = false
      }
    },
    catchError: (state: any, action) => {
      state.error = `${action.type}: ${action.payload.error}`
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false
      } else {
        state.actionsLoading = false
      }
    },
    // GetScheduleCustomTestMany
    saveList: (state: any, action) => {
      const {totalCount, entities} = action.payload
      state.listLoading = false
      state.error = null
      state.entities = entities
      state.totalCount = totalCount
    },
    // GetScheduleCustomTestMany
    saveListBatch: (state: any, action) => {
      const {totalCount, entities} = action.payload
      state.listLoading = false
      state.error = null
      state.entitiesBatch = entities
      state.totalCountBatch = totalCount
    },
    // GetScheduleCustomTestById
    saveObject: (state: any, action) => {
      state.actionsLoading = false
      state.entity = action.payload.data
      state.error = null
    },
    // GetScheduleCustomTestById
    saveObjectBatch: (state: any, action) => {
      state.actionsLoading = false
      state.entityBatch = action.payload.data
      state.error = null
    },
    // CreateScheduleCustomTest
    createObject: (state: any, action) => {
      state.actionsLoading = false
      state.error = null
      state.entities.push(action.payload.data)
    },
    // UpdateScheduleCustomTest
    updateObject: (state: any, action) => {
      state.error = null
      state.actionsLoading = false
      state.entities = state.entities.map((entity: any) => {
        if (entity.id === action.payload.data.id) {
          return action.payload.data
        }
        return entity
      })
    },
    // DeleteScheduleCustomTest
    deleteObject: (state: any, action) => {
      state.error = null
      state.actionsLoading = false
      state.entities = state.entities.filter((el: any) => el.id !== action.payload.id)
    },
    // deleteScheduleCustomTestMany
    deleteManyObject: (state: any, action) => {
      state.error = null
      state.actionsLoading = false
      state.entities = state.entities.filter((el: any) => !action.payload.ids.includes(el.id))
    },
  },
})

export const scheduleTO = {
  callTypes,
  slice,
}
