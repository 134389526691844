import { useForm } from 'react-hook-form'
import Form from 'react-bootstrap/Form'
import { FC, useEffect, useRef, useState } from 'react'
import { AttendanceFormInput } from '../../../../../../helpers/UIScheduleConstanta'
import useStudentTable from '../../../../../../_component/Table/StudentTable/useStudentTable'
import StudentSelectedTable from '../../../../../../_component/Table/StudentTable/StudentSelectedTable'
import { UserAttendance } from '../../../../../../_domain/model/Schedule'

type props = {
  submitBtnRef: any
  onSubmit: (req: any) => void
  entity: UserAttendance
}

const LiveClassAttendanceForm: FC<props> = ({ submitBtnRef, onSubmit, entity }) => {
  const { handleUploadCheck, selected } = useStudentTable({})
  const inputFile: any = useRef();
  const [users, setUsers] = useState<any[]>([])

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<AttendanceFormInput>({
    mode: "onChange",
  })

  const midSubmit = (req: any) => {
    const userIds = users.map((item) => item.user_id);
    onSubmit({ user_ids: userIds })
  }

  useEffect(() => {
    setUsers(entity?.attendees ?? []);
  }, [entity])

  useEffect(() => {
    const tmp = selected.filter((item) => {
      if (users.findIndex((v) => v.id === item.id) === -1) {
        return true;
      }
      return false;
    })
    setUsers([...users, ...tmp])
  }, [selected])

  function deleteAction(id: number) {
    const tmp = users.filter((item) => {
      if (item.id !== id) {
        return true;
      }
      return false;
    })
    setUsers(tmp);
  }

  return (
    <Form onSubmit={handleSubmit(midSubmit)}>
      <Form.Group className='mb-3'>
        <Form.Label>Select User Attendees</Form.Label>
        <br />
        <div className='d-flex justify-content-between align-items-center mb-4'>
          <div className="btn-group">
            <button type="button" className="btn btn-sm btn-primary" onClick={(e) => {
              if (inputFile && inputFile.current) {
                inputFile.current.click();
              }
            }}>Upload File Selected</button>
            <button type="button" className="btn btn-sm btn-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
              Download Template
            </button>
            <ul className="dropdown-menu">
              <li><a className="dropdown-item" href="/media/template/Template_Attendees.xlsx">Excel Format</a></li>
            </ul>
          </div>
        </div>
        <input
          ref={inputFile}
          type="file"
          // accept=".csv"
          className="d-none"
          onChange={(e: any) => {
            const file = e.target.files[0];
            console.log(file);

            handleUploadCheck(file)
          }}
        />

        <StudentSelectedTable entities={users ?? []} totalCount={users?.length ?? 0} isDeleteAction={true} deleteAction={deleteAction} />
      </Form.Group>

      <div className='mt-4 d-flex justify-content-center d-none'>
        <button ref={submitBtnRef} className='btn btn-primary' type='submit'>
          Submit
        </button>
      </div>
    </Form>
  )
}

export default LiveClassAttendanceForm
