/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { PageTitle } from '../../../../../_layout/core'
import { getAllOrderDatatable } from './OrderAction'
import { Suspense, lazy } from 'react'
import CustomLoading from '../../../../../_component/customLoading'
import { useOutletContext } from 'react-router-dom'
import { BaseDatatableResponse } from '../../../../../_data/dataSource/response/BaseDatatableResponse'
import { Order } from '../../../../../_domain/model/Order'
import { ORDER_LIST_TITLE } from '../../../../../_domain/constanta'
import { useAuth } from '../../../../../_context/AuthContext'
import { checkAuthActionMenu } from '../../../../../helpers/AuthHelpers'
import { SLUG_TRANSACTION_SUB_MENU } from '../../../../../helpers/UIAuthGroupConstanta'

const OrderEditInstallment = React.lazy(() => import('./OrderEditInstallment'))
const OrderDeleteInstallment = React.lazy(() => import('./OrderDeleteInstallment'))
const OrderStatusInstallment = React.lazy(() => import('./OrderStatusInstallment'))
const OrderCancelInstallment = React.lazy(() => import('./OrderCancelInstallment'))
const OrderDelete = React.lazy(() => import('./OrderDelete'))
const OrderListTable = React.lazy(() => import('./OrderListComponent/OrderListTable'))

const checkReduxData = () => {
  const outletContext = useOutletContext<any>().queryParams
  const { queryParams } = outletContext

  const [orderListData, setOrderListData] = useState<any>(null)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch<any>(getAllOrderDatatable(queryParams)).then((response: BaseDatatableResponse<Order>) => {
      setOrderListData(response.entities)
    })
  }, [queryParams, getAllOrderDatatable])

  return orderListData
  // detect redux first before fetch API END
}

const OrderList = () => {
  let data = checkReduxData()
  const { currentUser, authorization } = useAuth()
  return checkAuthActionMenu(SLUG_TRANSACTION_SUB_MENU.TRANSACTION_ORDER_MENU, "view", currentUser, authorization) ? (
    <>
      <OrderEditInstallment />
      <OrderDeleteInstallment />
      <OrderStatusInstallment />
      <OrderCancelInstallment />
      <OrderDelete />
      <PageTitle breadcrumbs={[]}>{ORDER_LIST_TITLE}</PageTitle>
      <Suspense fallback={<CustomLoading />}>
        {data ? <OrderListTable /> : null}
      </Suspense>
    </>
  ) : null
}

export { OrderList }
