import {BaseDatatableRequest} from '../../_data/dataSource/request/BaseDatatableRequest'
import {
  mappingInputFormToGroupClassCreateRequest,
  mappingInputFormToGroupClassUpdateRequest,
} from '../../_data/dataSource/request/GroupClassRequest'
import {BaseDatatableResponse} from '../../_data/dataSource/response/BaseDatatableResponse'
import {school} from '../../_redux_slice'
import {GroupClassFormInput} from '../../helpers/UIGroupClassConstanta'
import {GroupClass} from '../model/GroupClass'
import {GroupClassRepository} from '../repository/GroupClassRepository'
import {SchoolRepository} from '../repository/SchoolRepository'

export interface GroupClassService {
  getDatatableGroupClass(req: BaseDatatableRequest): Promise<BaseDatatableResponse<GroupClass>>
  getAllGroupClass(): Promise<GroupClass[]>
  getDetailGroupClassById(id: string): Promise<GroupClass>
  deleteOneGroupClassByID(id: string): Promise<string>
  createOneGroupClass(req: GroupClassFormInput): Promise<GroupClass>
  updateOneGroupClass(req: GroupClassFormInput, id: string): Promise<GroupClass>
}

export class GroupClassServiceImpl implements GroupClassService {
  private repo: GroupClassRepository
  private repoSchool: SchoolRepository
  constructor(repo: GroupClassRepository, repoSchool: SchoolRepository) {
    this.repo = repo
    this.repoSchool = repoSchool
  }

  async updateOneGroupClass(req: GroupClassFormInput, id: string): Promise<GroupClass> {
    const body = mappingInputFormToGroupClassUpdateRequest(req)
    const groupClass = await this.repo.updateOne(body, id)
    return groupClass
  }

  async createOneGroupClass(req: GroupClassFormInput): Promise<GroupClass> {
    const body = mappingInputFormToGroupClassCreateRequest(req)
    const groupClass = await this.repo.createOne(body)
    return groupClass
  }

  async deleteOneGroupClassByID(id: string): Promise<string> {
    const message = await this.repo.deleteOneById(id)
    return message
  }

  async getDatatableGroupClass(
    req: BaseDatatableRequest
  ): Promise<BaseDatatableResponse<GroupClass>> {
    const groupClasss = await this.repo.getDatatable(req)
    return groupClasss
  }

  async getDetailGroupClassById(id: string): Promise<GroupClass> {
    let groupClass = await this.repo.getOneById(id)
    if (groupClass.schoolId) {
      const schoolDetail = await this.repoSchool.getOneById(groupClass.schoolId)
      if (schoolDetail.id) {
        groupClass = {
          ...groupClass,
          school: {
            ...schoolDetail,
          },
        }
      }
    }
    return groupClass
  }

  async getAllGroupClass(): Promise<GroupClass[]> {
    const groupClasss = await this.repo.getAll()
    return groupClasss
  }
}
