import ProvinceDataSource from '../../_data/dataSource/ProvinceDataSoruce'
import { BaseDatatableRequest } from '../../_data/dataSource/request/BaseDatatableRequest'
import { BaseDatatableResponse } from '../../_data/dataSource/response/BaseDatatableResponse'
import { Province, ProvinceBuilder } from '../model/Province'
import {ProvinceRepository} from './ProvinceRepository'

export class ProvinceRepositoryImpl implements ProvinceRepository {
  datasource: ProvinceDataSource

  constructor(datasource: ProvinceDataSource) {
    this.datasource = datasource
  }
  getDatatable(req: BaseDatatableRequest): Promise<BaseDatatableResponse<Province>> {
    throw new Error('Method not implemented.')
  }
  async getAll(): Promise<Province[]> {
    const response = await this.datasource.getAll()
    const initBuilder = new ProvinceBuilder()
    const data = initBuilder.ListData(response).ResultList()
    return data
  }
  getOneById(id: string | number): Promise<Province> {
    throw new Error('Method not implemented.')
  }
  deleteOneById(id: string | number): Promise<string> {
    throw new Error('Method not implemented.')
  }
  createOne(req: any): Promise<Province> {
    throw new Error('Method not implemented.')
  }
  updateOne(req: any): Promise<Province> {
    throw new Error('Method not implemented.')
  }
}
