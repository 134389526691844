import {useEffect, useState} from 'react'
import ExamProgresstDataSourceImpl from '../_data/dataSource/ExamProgressDataSourceImpl'
import {
  BaseDatatableRequest,
  defaultQueryParams,
} from '../_data/dataSource/request/BaseDatatableRequest'
import useFilterTable from './useFilterTable'

const init = defaultQueryParams()
init.pageSize = 10
init.sortField = 'examProgressData'
init.sortOrder = 'desc'

const useExamProgress = () => {
  const value = useFilterTable({initQueryParams: init})

  const [list, setList] = useState<any>([])
  const [count, setCount] = useState<number>(0)

  const dataSource = new ExamProgresstDataSourceImpl('exam_progress')

  async function getAllExamProgress() {
    const res = await dataSource.getDatatable(value.queryParams)
    setList({
      entities: res.entities,
      totalCount: res.totalCount,
    })
    // onSnapshot(query(collection(dataSource.db, dataSource.collection_name)), (item) => {
    //   const data = item.docs.map((doc) => doc.data())
    //   setList(data)
    // })
    // const response = await dataSource.getAll()
    // setList(response)
  }

  async function getNext() {
    const res = await dataSource.getNextDatatable(value.queryParams)
    setList({
      entities: res.entities,
      totalCount: res.totalCount,
    })
  }

  async function getPrev() {
    const res = await dataSource.getPrevDatatable(value.queryParams)
    setList({
      entities: res.entities,
      totalCount: res.totalCount,
    })
  }

  async function getSearch(search: string) {
    if (search) {
      const res = await dataSource.getSearchDatatable({...value.queryParams, filter: {search}})
      setList({
        entities: res.entities,
        totalCount: res.totalCount,
      })
    } else {
      const res = await dataSource.getDatatable({...value.queryParams, filter: {}})
      setList({
        entities: res.entities,
        totalCount: res.totalCount,
      })
    }
  }

  async function getCount() {
    const count = await dataSource.getCount()
    setCount(count)
  }

  async function submitData(id: string) {
    await dataSource.submitData(id)
  }
  // async function submitAllData() {
  //   await dataSource.submitAllData()
  // }

  useEffect(() => {
    getAllExamProgress()
    getCount()
  }, [])

  return {
    ...value,
    listExamProgress: list,
    getAllExamProgress,
    getNext,
    getPrev,
    getSearch,
    count,
    submitData,
    // submitAllData
  }
}

export default useExamProgress
