import React, { useCallback, useState } from 'react'
// import { prepareFilterDefault } from '../../../../../helpers/ShareVar'
import { isEqual, isFunction } from 'lodash'
import { defaultQueryParams, prepareFilterBase } from '../../../../../_data/dataSource/request/BaseDatatableRequest'
import useFilterTable from '../../../../../_hooks/useFilterTable'
import useDeleteSelectionDialog from '../../../../../_hooks/useDeleteSelectionDialog'

const initBatch = defaultQueryParams()
initBatch.sortField = "id"
initBatch.sortOrder = "desc"
initBatch.pageSize = 20
const init = defaultQueryParams()
init.sortField = "id"
init.sortOrder = "desc"
init.pageSize = 20
init.filter = {
  is_batch: false
}

export const queryParamsContext = () => {
  const value = useFilterTable({ initQueryParams: init })
  const [queryParamsBatch, setQueryParamsBatchBase] = useState(initBatch)

  const setQueryParamsBatch = useCallback((nextQueryParams: any) => {
    setQueryParamsBatchBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const handlePageBatchChange = (page: number, perPage: number) => {
    const tmp = { ...queryParamsBatch }
    tmp.pageNumber = page
    setQueryParamsBatch(tmp)
  }

  const applyFilterBatch = (
    values: object
  ) => {
    const newQueryParams = prepareFilterBase(queryParamsBatch, values)

    if (!isEqual(newQueryParams, queryParamsBatch)) {
      newQueryParams.pageNumber = 1
      // update list by queryParams
      setQueryParamsBatch(newQueryParams)
    }
  }


  return {
    ...value,
    queryParamsBatch,
    setQueryParamsBatchBase,
    setQueryParamsBatch,
    handlePageBatchChange,
    applyFilterBatch
  }
}

export const deleteSectionContext = () => {
  const value = useDeleteSelectionDialog()

  const [propsForDeleteBatch, setPropsForDeleteBatch] = useState({
    id: "",
    show: false,
  });

  function showingDeleteBatchModal(id: string) {
    setPropsForDeleteBatch({
      id: id,
      show: true
    })
  }

  function closingDeleteBatchModal() {
    setPropsForDeleteBatch({
      id: "",
      show: false
    })
  }

  return {
    ...value,
    propsForDeleteBatch,
    setPropsForDeleteBatch,
    showingDeleteBatchModal,
    closingDeleteBatchModal
  }
}