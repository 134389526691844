import {LEVEL_STUDENT, MAJOR, TYPE_CHAPTER} from '../../../_domain/constanta'
import {typeChapterSwitch, typeLevelSwitch, typeMajorSwitch} from '../../../helpers/FuncHelpers'
import {ChapterFormInput} from '../../../helpers/UIChapterConstanta'
import {IFilesData} from '../../../types'
import {CLASS_STUDENT} from '../../../helpers/UIConstanta'

export type ChapterCreateRequest = {
  subject_id: string
  number_chapter: number
  name: string
  type_chapter: TYPE_CHAPTER
  semester: number
  grade: number
  major: MAJOR
  level: LEVEL_STUDENT
  status: 'publish' | 'draft'
  files: any[]
  videos?: {
    chapter_file_id: string
    title: string
    description: string
  }[]
}

export type ChapterUpdateRequest = {
  subject_id: string
  number_chapter: number
  name: string
  type_chapter: TYPE_CHAPTER
  semester: number
  grade: number
  major: MAJOR
  level: LEVEL_STUDENT
  status: 'publish' | 'draft'
  files: any[]
  videos?: {
    chapter_file_id: string
    title: string
    description: string
  }[]
}

export type ChapterListNumberRequest = {
  major: string
  grade: number
  subject_id: string
  semester: number
  type_chapter?: string
}

export type ChapterFilterRequest = {
  grade?: number
  major?: string
  semester?: number
  subject_id?: string
  class_index?: number
  type_chapter?: string
}

export type ChapterFileRequest = {
  path?: string
  url?: string
  url_public: string
  code_file?: string
}

export function mappingInputFormToChapterCreateRequest(
  req: ChapterFormInput
): ChapterCreateRequest {
  const {major, grade, level} = CLASS_STUDENT[+req.classInput!]

  return {
    subject_id: req.subjectInput ?? '',
    number_chapter: req.numberInput ? +req.numberInput : 0,
    name: req.nameInput ?? '',
    type_chapter: typeChapterSwitch(req.typeInput!),
    semester: req.semesterInput ? +req.semesterInput : 1,
    grade: grade === 'ALUMNI' ? 13 : +grade,
    major: typeMajorSwitch(major),
    level: typeLevelSwitch(level),
    status: 'publish',
    files:
      req.filesInput?.filter((item) => {
        if (item.chapter_file_id === '') {
          return false
        }
        return true
      }) ?? [],
    videos: req.videoInput?.filter((item) => {
      if (item.chapter_file_id === '') {
        return false
      }
      return true
    }),
  }
}

export function mappingInputFormToChapterUpdateRequest(
  req: ChapterFormInput
): ChapterUpdateRequest {
  const {major, grade, level} = CLASS_STUDENT[+req.classInput!]

  return {
    subject_id: req.subjectInput ?? '',
    number_chapter: req.numberInput ? +req.numberInput : 0,
    name: req.nameInput ?? '',
    type_chapter: typeChapterSwitch(req.typeInput!),
    semester: req.semesterInput ? +req.semesterInput : 1,
    grade: grade === 'ALUMNI' ? 13 : +grade,
    major: typeMajorSwitch(major),
    level: typeLevelSwitch(level),
    status: 'publish',
    files:
      req.filesInput?.filter((item) => {
        if (item.chapter_file_id === '') {
          return false
        }
        return true
      }) ?? [],
    videos: req.videoInput?.filter((item) => {
      if (item.chapter_file_id === '') {
        return false
      }
      return true
    }),
  }
}
