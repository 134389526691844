/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {PageTitle} from '../../../../../_layout/core'
import {Suspense, lazy} from 'react'
import CustomLoading from '../../../../../_component/customLoading'
import {useOutletContext} from 'react-router-dom'
import {STUDENT_CLASS_LIST_TITLE} from '../../../../../_domain/constanta'
import {useAuth} from '../../../../../_context/AuthContext'
import {checkAuthActionMenu} from '../../../../../helpers/AuthHelpers'
import {SLUG_EDULIVE_SUB_MENU} from '../../../../../helpers/UIAuthGroupConstanta'
import {StudentClass} from '../../../../../_domain/model/StudentClass'
import {getOneStudentClass} from './StudentClassAction'

const StudentClassListTable = React.lazy(
  () => import('./StudentClassListComponent/StudentClassListTable')
)

const checkReduxData = () => {
  const outletContext = useOutletContext<any>().queryParams
  const {queryParams, setStudents} = outletContext

  const [studentClassListData, setStudentClassListData] = useState<any>(null)
  const dispatch = useDispatch()

  useEffect(() => {
    if (queryParams.filter.id) {
      dispatch<any>(getOneStudentClass(queryParams.filter.id)).then((response: StudentClass) => {
        setStudentClassListData(response?.students ?? [])
      })
    } else {
      dispatch<any>(getOneStudentClass(0))
      setStudentClassListData([])
    }
    setStudents([])
  }, [queryParams, getOneStudentClass])

  return studentClassListData
}

const StudentClassList = () => {
  let data = checkReduxData()

  const {currentUser, authorization} = useAuth()

  return checkAuthActionMenu(
    SLUG_EDULIVE_SUB_MENU.EDULIVE_STUDENT_CLASS_MENU,
    'view',
    currentUser,
    authorization
  ) ? (
    <>
      <PageTitle breadcrumbs={[]}>{STUDENT_CLASS_LIST_TITLE}</PageTitle>
      <Suspense fallback={<CustomLoading />}>{data ? <StudentClassListTable /> : null}</Suspense>
    </>
  ) : null
}

export {StudentClassList}
