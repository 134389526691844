import React, {Suspense, useEffect, useMemo} from 'react'
import {STUDENT_CLASS_CREATE_TITLE} from '../../../../../_domain/constanta'
import {Link, useNavigate, useOutletContext, useParams} from 'react-router-dom'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {PageTitle} from '../../../../../_layout/core'
import CustomLoading from '../../../../../_component/customLoading'
import {createStudentClass, getOneStudentClass} from './StudentClassAction'
import {StudentClassFormInput} from '../../../../../helpers/UIStudentClassConstanta'

const StudentClassAddForm = React.lazy(
  () => import('./StudentClassAddComponent/StudentClassAddForm')
)

const StudentClassAdd = () => {
  const {id} = useParams()

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const filterContext = useOutletContext<any>().queryParams
  const {queryParams} = useMemo(() => {
    return {
      queryParams: filterContext.queryParams,
    }
  }, [filterContext])

  const {entity} = useSelector(
    (state: any) => ({
      entity: state.studentClass.entity,
    }),
    shallowEqual
  )

  useEffect(() => {
    if(id){
      dispatch<any>(getOneStudentClass(+id))
    }
    return () => {
      dispatch<any>(getOneStudentClass(undefined))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const saveAction = (data: StudentClassFormInput) => {
    if (id) {
      dispatch<any>(createStudentClass(+id, data)).then(() => {
        dispatch<any>(getOneStudentClass(+id))
        navigate('/edulive/student-class')
      })
    }
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>{STUDENT_CLASS_CREATE_TITLE}</PageTitle>
      <div className='d-flex justify-content-between align-items-center mb-4'>
        <div className='w-full'></div>
        <div>
          <Link to={'/edulive/student-class'}>
            <button type='button' className='btn btn-secondary'>
              Back
            </button>
          </Link>
        </div>
      </div>
      <Suspense fallback={<CustomLoading />}>
        {entity ? (
          <StudentClassAddForm onSubmit={saveAction} entity={entity} id={id ? +id : 0} />
        ) : null}
      </Suspense>
    </>
  )
}

export default StudentClassAdd
