import { Controller, useForm } from 'react-hook-form'
import Form from 'react-bootstrap/Form'
import { FC, useMemo } from 'react'
import { TypeTryout } from '../../../../../../_domain/model/TypeTryout'
import { msgValidationError } from '../../../../../../helpers/FuncHelpers'
import { TypeTryoutFormInput } from '../../../../../../helpers/UITypeTryoutConstanta'
import { CALCULATE_TYPE } from '../../../../../../_domain/constanta'

type props = {
  submitBtnRef: any
  onSubmit: (req: any) => void
  dataTypeTO: TypeTryout
}

const TryoutTypeEditForm: FC<props> = ({ submitBtnRef, onSubmit, dataTypeTO }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<TypeTryoutFormInput>({
    mode: "onChange",
    defaultValues: useMemo(() => {
      return {
        nameInput: dataTypeTO.name,
        descriptionInput: dataTypeTO.description,
        calculateTypeInput: dataTypeTO.calculate_type
      }
    }, [dataTypeTO])
  })

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {/* Name Field */}
      <Controller
        name="nameInput"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <>
            <Form.Group className='mb-3'>
              <Form.Label>Type Tryout Name</Form.Label>
              <Form.Control
                {...field}
                type='text'
                placeholder='UTBK 2023'
                className='mb-3'
                isInvalid={errors.nameInput ? true : false}
              />
              {errors.nameInput && (
                <Form.Control.Feedback type='invalid'>
                  {msgValidationError(errors.nameInput.type, 'Tryout Type Name')}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </>
        )} />

      {/* Description Field */}
      <Controller
        name="descriptionInput"
        control={control}
        render={({ field }) => (
          <>
            <Form.Group className='mb-3'>
              <Form.Label>Type Tryout Description</Form.Label>
              <Form.Control
                {...field}
                as="textarea"
                className='mb-3'
              />
            </Form.Group>
          </>
        )} />

      {/* Calculate Type Field */}
      <Controller
        name="calculateTypeInput"
        control={control}
        defaultValue=''
        rules={{ required: true }}
        render={({ field }) => (
          <>
            <Form.Group className='mb-3'>
              <Form.Label>Calculate Type</Form.Label>
              <Form.Select
                {...field}
                className='mb-3'
                isInvalid={errors.calculateTypeInput ? true : false}
              >
                <option value=''> --Please Select Calculate Type-- </option>
                {Object.values(CALCULATE_TYPE).map((val, idx) => (
                  <option key={idx} value={val}>{val}</option>
                ))}
              </Form.Select>
              {errors.calculateTypeInput && (
                <Form.Control.Feedback type='invalid'>
                  {msgValidationError(errors.calculateTypeInput.type, 'Calculate Type')}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </>
        )} />

      <div className='mt-4 d-flex justify-content-center d-none'>
        <button ref={submitBtnRef} className='btn btn-primary' type='submit'>
          Submit
        </button>
      </div>
    </Form>
  )
}

export default TryoutTypeEditForm
