/* eslint-disable jsx-a11y/anchor-is-valid */
import { Outlet, Route, Routes } from 'react-router'
import { PageTitle } from '../../../../../_layout/core'
import { TryoutSeriesList } from './TryoutSeriesList'
import { createSectionContext, deleteSectionContext, editSectionContext, queryParamsContext } from './_outletContext'

const TryoutSeriesContent = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>{'Tryout Series'}</PageTitle>
      <Outlet context={{ queryParams: queryParamsContext(), deleteSection: deleteSectionContext(), createSection: createSectionContext(), editSection: editSectionContext() }} />
    </>
  )
}

const TryoutSeries = () => {
  return (
    <>
      <Routes>
        <Route element={<TryoutSeriesContent />}>
          <Route index element={<TryoutSeriesList />} />
          {/* <Route path='create' element={<TryoutSeriesCreate />} />
          <Route path='edit/:tryoutSeriesId' element={<TryoutSeriesEdit />} /> */}
        </Route>
      </Routes>
    </>
  )
}

export { TryoutSeries }
