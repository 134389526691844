import React, { FC, useMemo } from 'react'
import { Form } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { SchoolFilterTableFormInput } from '../../../helpers/UISchoolConstanta';
import { BaseDatatableRequest } from '../../../_data/dataSource/request/BaseDatatableRequest';
import { SchoolFilterRequest } from '../../../_data/dataSource/request/SchoolRequest';
import { CityGetAllParams } from '../../../_data/dataSource/request/CityRequest';
import useProvince from '../../../_hooks/useProvince';
import useCity from '../../../_hooks/useCity';

type Props = {
  queryParams: BaseDatatableRequest
  applyFilter: (val: SchoolFilterRequest) => void,
}
const SchoolTableFilter: FC<Props> = ({ queryParams, applyFilter }) => {

  const { listProvince } = useProvince()
  const { listCity, changeProvinceId } = useCity({
    params: {
      province_id: queryParams.filter.province_id
    }
  })

  const {
    control,
    setValue,
    getValues,
    watch,
  } = useForm<SchoolFilterTableFormInput>({
    mode: "onChange",
    defaultValues: useMemo(() => {
      return {
        provinceInput: queryParams.filter.province_id ?? "",
        cityInput: queryParams.filter.city_id ?? "",
        searchInput: queryParams.filter.search_text ?? "",
      }
    }, [])
  })

  function changeFilterState() {
    const cityValue = getValues("cityInput")
    const searchTextValue = getValues("searchInput")
    const filter: SchoolFilterRequest = {
      city_id: cityValue === "" ? undefined : cityValue,
      search_text: searchTextValue
    }
    applyFilter(filter)
  }

  return (
    <div className='row w-100'>
      {/* Province Field */}
      <div className='col-2'>
        <Controller
          name="provinceInput"
          defaultValue=''
          control={control}
          render={({ field }) => (
            <Form.Group>
              <Form.Label>School Province</Form.Label>
              <Form.Select
                {...field}
                className='mb-3'
                onChange={(e) => {
                  const params: CityGetAllParams = {
                    province_id: e.target.value
                  };
                  setValue("provinceInput", e.target.value)
                  changeProvinceId(params)
                }}
              >
                <option value=""> --Please Select Province-- </option>
                {listProvince?.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          )} />
      </div>
      {/* Semester Field */}
      <div className='col-2'>
        <Controller
          name="cityInput"
          control={control}
          render={({ field }) => (
            <Form.Group>
              <Form.Label>School City</Form.Label>
              <Form.Select
                {...field}
                className='mb-3'
                onChange={(e) => {
                  setValue("cityInput", e.target.value)
                  changeFilterState()
                }}
              >
                <option value=""> --Please Select City-- </option>
                {listCity?.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          )} />
      </div>
      {/* Search Field */}
      <div className='col-3'>
        <Controller
          name="searchInput"
          defaultValue=""
          control={control}
          render={({ field }) => (
            <Form.Group>
              <Form.Label>Search</Form.Label>
              <Form.Control
                {...field}
                type='text'
                placeholder='Search'
                className='mb-3'
                onChange={(e) => {
                  setValue("searchInput", e.target.value)
                  changeFilterState()
                }}
              />
            </Form.Group>
          )} />
      </div>
    </div>

  )
}

export default SchoolTableFilter;