/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { PageTitle } from '../../../../../_layout/core'
import { getAllTryoutScheduleBatchDatatable, getAllTryoutScheduleDatatable } from './TryoutScheduleAction'
import { Suspense } from 'react'
import CustomLoading from '../../../../../_component/customLoading'
import { Link, useOutletContext } from 'react-router-dom'
import { Schedule } from '../../../../../_domain/model/Schedule'
import { BaseDatatableResponse } from '../../../../../_data/dataSource/response/BaseDatatableResponse'
import { SCHEDULE_TRYOUT_LIST_TITLE } from '../../../../../_domain/constanta/ScheduleTryoutConstant'
import { Nav } from 'react-bootstrap'
import { useAuth } from '../../../../../_context/AuthContext'
import { checkAuthActionMenu } from '../../../../../helpers/AuthHelpers'
import { SLUG_TRYOUT_SUB_MENU } from '../../../../../helpers/UIAuthGroupConstanta'
import { ScheduleBatch } from '../../../../../_domain/model/ScheduleBatch'
const TryoutScheduleDelete = React.lazy(() => import('./TryoutScheduleDelete'))
const TryoutScheduleBatchDelete = React.lazy(() => import('./TryoutScheduleBatchDelete'))

const TryoutScheduleListTable = React.lazy(() =>
  import('./TryoutScheduleListComponent/TryoutScheduleListTable')
)
const TryoutScheduleListBatchTable = React.lazy(() =>
  import('./TryoutScheduleListComponent/TryoutScheduleListBatchTable')
)

const checkReduxData = () => {
  const outletContext = useOutletContext<any>().filter
  const { queryParams } = outletContext

  // detect redux first before fetch API
  const [tryoutScheduleListData, setTryoutScheduleListData] = useState<Schedule[] | null>(null)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch<any>(getAllTryoutScheduleDatatable(queryParams)).then((response: BaseDatatableResponse<Schedule>) => {
      setTryoutScheduleListData(response.entities)
    })
  }, [queryParams, getAllTryoutScheduleDatatable])

  return tryoutScheduleListData
  // detect redux first before fetch API END
}

const checkReduxDataBatch = () => {
  const outletContext = useOutletContext<any>().filter
  const { queryParamsBatch } = outletContext

  const [tryoutScheduleListDataBatch, setTryoutScheduleListDataBatch] = useState<ScheduleBatch[] | null>(null)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch<any>(getAllTryoutScheduleBatchDatatable(queryParamsBatch)).then((response: BaseDatatableResponse<ScheduleBatch>) => {
      setTryoutScheduleListDataBatch(response.entities)
    })
  }, [queryParamsBatch, getAllTryoutScheduleBatchDatatable])

  return tryoutScheduleListDataBatch
}

const TryoutScheduleList = () => {
  const [tab, setTab] = useState("single")

  let data = checkReduxData()
  const { currentUser, authorization } = useAuth()
  let dataBatch = checkReduxDataBatch()

  return checkAuthActionMenu(SLUG_TRYOUT_SUB_MENU.TRYOUT_SCHEDULE_MENU, "view", currentUser, authorization) ? (
    <>
      <PageTitle breadcrumbs={[]}>{SCHEDULE_TRYOUT_LIST_TITLE}</PageTitle>
      <div className='d-flex justify-content-between align-items-center mb-4'>
        <Nav variant="pills" activeKey={tab} className='mb-4'>
          <Nav.Item>
            <Nav.Link eventKey={"single"} title="Single Schedule" onClick={() => setTab("single")}>
              Single Schedule
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey={"batch"} title="Batch Schedule" onClick={() => setTab("batch")}>
              Batch Schedule
            </Nav.Link>
          </Nav.Item>
        </Nav>
        {checkAuthActionMenu(SLUG_TRYOUT_SUB_MENU.TRYOUT_SCHEDULE_MENU, "create", currentUser, authorization) && (
          <Link to={"/tryout/schedule/create"}>
            <button type='button'
              className='btn btn-sm btn-primary'>
              Add New
            </button>
          </Link>
        )}
      </div>
      <Suspense fallback={<CustomLoading />}>

        <TryoutScheduleDelete />
        <TryoutScheduleBatchDelete />
        {tab === "single" && data && (
          <TryoutScheduleListTable />
        )}
        {tab === "batch" && dataBatch && (
          <TryoutScheduleListBatchTable />
        )}
      </Suspense>
    </>
  ) : null
}

export { TryoutScheduleList }
