/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { PageTitle } from '../../../../../_layout/core'
import { getAllSubjectDatatable } from './SubjectAction'
import { Suspense, lazy } from 'react'
import CustomLoading from '../../../../../_component/customLoading'
import { useOutletContext } from 'react-router-dom'
import { BaseDatatableResponse } from '../../../../../_data/dataSource/response/BaseDatatableResponse'
import { Subject } from '../../../../../_domain/model/Subject'
import SubjectDelete from './SubjectDelete'
import { useAuth } from '../../../../../_context/AuthContext'
import { checkAuthActionMenu } from '../../../../../helpers/AuthHelpers'
import { SLUG_MASTER_SUB_MENU } from '../../../../../helpers/UIAuthGroupConstanta'

const SubjectListTable = React.lazy(() => import('./SubjectListComponent/SubjectListTable'))
const SubjectCreate = React.lazy(() => import('./SubjectCreate'))
const SubjectEdit = React.lazy(() => import('./SubjectEdit'))

const checkReduxData = () => {
  const outletContext = useOutletContext<any>().queryParams
  const { queryParams } = outletContext

  const [subjectListData, setSubjectListData] = useState<any>(null)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch<any>(getAllSubjectDatatable(queryParams)).then((response: BaseDatatableResponse<Subject>) => {
      setSubjectListData(response.entities)
    })
  }, [queryParams, getAllSubjectDatatable])

  return subjectListData
  // detect redux first before fetch API END
}

const SubjectList = () => {
  let data = checkReduxData()
  const { currentUser, authorization } = useAuth()
  return checkAuthActionMenu(SLUG_MASTER_SUB_MENU.MASTER_SUBJECT_MENU, "view", currentUser, authorization) ? (
    <>
      <SubjectDelete />
      <SubjectCreate />
      <SubjectEdit />
      <PageTitle breadcrumbs={[]}>{'Subject List'}</PageTitle>
      <Suspense fallback={<CustomLoading />}>
        {data ? <SubjectListTable /> : null}
      </Suspense>
    </>

  ) : null
}

export { SubjectList }
