import {BannerFormInput} from '../../../helpers/UIBannerConstanta'

export type BannerCreateRequest = {
  name: string
  links: string[]
  titles: string[]
  descriptions: string[]
}

export type BannerUpdateRequest = {
  name: string
  delete_image_current?: boolean
  status: string
  links: string[]
  titles: string[]
  descriptions: string[]
}

export function mappingInputFormToBannerCreateRequest(req: BannerFormInput): BannerCreateRequest {
  return {
    name: req.nameInput,
    links: req.linksInput,
    titles: req.titlesInput,
    descriptions: req.descriptionsInput,
  }
}

export function mappingInputFormToBannerUpdateRequest(req: BannerFormInput): BannerUpdateRequest {
  return {
    name: req.nameInput,
    status: req.statusInput === 'true' ? 'active' : 'non_active',
    links: req.linksInput,
    titles: req.titlesInput,
    descriptions: req.descriptionsInput,
  }
}
