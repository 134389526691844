import useFilterTable from '../../../../../_hooks/useFilterTable'
import useDeleteSelectionDialog from '../../../../../_hooks/useDeleteSelectionDialog'
import useEditSelectionDialog from '../../../../../_hooks/useEditSelectionDialog'
import { useState } from 'react'


export const queryParamsContext = () => {
  const value = useFilterTable()

  return value
}

export const deleteSectionContext = () => {
  const value = useDeleteSelectionDialog()

  return value
}

export const attendanceSectionContext = () => {
  const value = useEditSelectionDialog()

  return value
}

export const attendanceSectionV2Context = () => {
  const [propsForEdit, setPropsForEdit] = useState({
    id: '',
    show: false,
  })

  function showingEditModal(id: string) {
    setPropsForEdit({
      id: id,
      show: true,
    })
  }

  function closingEditModal() {
    setPropsForEdit({
      id: '',
      show: false,
    })
  }

  return {
    propsForEdit,
    setPropsForEdit,
    showingEditModal,
    closingEditModal,
  }
}