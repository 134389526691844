const STUDENT_CLASS_DELETE_TITLE = 'Student Class Delete'
const STUDENT_CLASS_DELETE_LOADING = 'Student Class is deleting...'
const STUDENT_CLASS_DELETE_CONFIRM = 'Are you sure to permanently delete this student class ?'
const STUDENT_CLASS_DELETE_SUCCESS = 'Successful delete student class'
const STUDENT_CLASS_DELETE_FAILED = "Can't delete student class"
const STUDENT_CLASS_CREATE_TITLE = 'Add Student'
const STUDENT_CLASS_CREATE_FAILED = "Can't create student class"
const STUDENT_CLASS_CREATE_SUCCESS = 'Successful create student class'
const STUDENT_CLASS_EDIT_TITLE = 'Student Class Edit'
const STUDENT_CLASS_EDIT_SUCCESS = 'Successful update student class'
const STUDENT_CLASS_EDIT_FAILED = "Can't update student class"
const STUDENT_CLASS_GET_FAILED = "Can't get student class"
const STUDENT_CLASS_ROLE_GET_FAILED = "Can't get student class role"
const STUDENT_CLASS_LIST_TITLE = 'Student Class List'
const STUDENT_CLASS_RESET_TITLE = 'Student Class Reset Password'
const STUDENT_CLASS_RESET_LOADING = 'Student Class is resetting password...'
const STUDENT_CLASS_RESET_CONFIRM = 'Are you sure to permanently reset password this student class ?'
const STUDENT_CLASS_RESET_SUCCESS = 'Successful reset password student class'
const STUDENT_CLASS_RESET_FAILED = "Can't reset password student class"
const STUDENT_CLASS_DETAIL_TITLE = 'Student Class Detail'
export {
  STUDENT_CLASS_DELETE_TITLE,
  STUDENT_CLASS_DELETE_CONFIRM,
  STUDENT_CLASS_DELETE_LOADING,
  STUDENT_CLASS_DELETE_SUCCESS,
  STUDENT_CLASS_DELETE_FAILED,
  STUDENT_CLASS_CREATE_TITLE,
  STUDENT_CLASS_CREATE_SUCCESS,
  STUDENT_CLASS_CREATE_FAILED,
  STUDENT_CLASS_EDIT_TITLE,
  STUDENT_CLASS_EDIT_SUCCESS,
  STUDENT_CLASS_EDIT_FAILED,
  STUDENT_CLASS_GET_FAILED,
  STUDENT_CLASS_LIST_TITLE,
  STUDENT_CLASS_DETAIL_TITLE,
  STUDENT_CLASS_ROLE_GET_FAILED,
  STUDENT_CLASS_RESET_TITLE,
  STUDENT_CLASS_RESET_LOADING,
  STUDENT_CLASS_RESET_CONFIRM,
  STUDENT_CLASS_RESET_SUCCESS,
  STUDENT_CLASS_RESET_FAILED,
}
