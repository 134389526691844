import {BaseDatatableRequest} from '../../_data/dataSource/request/BaseDatatableRequest'
import {
  mappingInputFormToFacilityCreateRequest,
  mappingInputFormToFacilityUpdateRequest,
} from '../../_data/dataSource/request/FacilityRequest'
import {BaseDatatableResponse} from '../../_data/dataSource/response/BaseDatatableResponse'
import {FacilityFormInput} from '../../helpers/UIFacilityConstanta'
import {Facility} from '../model/Facility'
import {FacilityRepository} from '../repository/FacilityRepository'

export interface FacilityService {
  getDatatableFacility(req: BaseDatatableRequest): Promise<BaseDatatableResponse<Facility>>
  getAllFacility(): Promise<Facility[]>
  getDetailFacilityById(id: string): Promise<Facility>
  deleteOneFacilityByID(id: string): Promise<string>
  createOneFacility(req: FacilityFormInput): Promise<Facility>
  updateOneFacility(req: FacilityFormInput, id: string): Promise<Facility>
}

export class FacilityServiceImpl implements FacilityService {
  private repo: FacilityRepository
  constructor(repo: FacilityRepository) {
    this.repo = repo
  }

  async updateOneFacility(req: FacilityFormInput, id: string): Promise<Facility> {
    const body = mappingInputFormToFacilityUpdateRequest(req)
    const facility = await this.repo.updateOne(body, id)
    return facility
  }

  async createOneFacility(req: FacilityFormInput): Promise<Facility> {
    const body = mappingInputFormToFacilityCreateRequest(req)
    const facility = await this.repo.createOne(body)
    return facility
  }

  async deleteOneFacilityByID(id: string): Promise<string> {
    const message = await this.repo.deleteOneById(id)
    return message
  }

  async getDatatableFacility(req: BaseDatatableRequest): Promise<BaseDatatableResponse<Facility>> {
    const facilities = await this.repo.getDatatable(req)
    return facilities
  }

  async getDetailFacilityById(id: string): Promise<Facility> {
    const facility = await this.repo.getOneById(id)
    return facility
  }

  async getAllFacility(): Promise<Facility[]> {
    const facilities = await this.repo.getAll()
    return facilities
  }
}
