import {chapter} from '../../../../../_redux_slice'
import ChapterDataSourceImpl from '../../../../../_data/dataSource/ChapterDataSourceImpl'
import {ChapterRepositoryImpl} from '../../../../../_domain/repository/ChapterRepositoryImpl'
import {ChapterServiceImpl} from '../../../../../_domain/service/ChapterService'
import {BaseDatatableRequest} from '../../../../../_data/dataSource/request/BaseDatatableRequest'
import {
  CHAPTER_CREATE_FAILED,
  CHAPTER_CREATE_SUCCESS,
  CHAPTER_DELETE_FAILED,
  CHAPTER_DELETE_SUCCESS,
  CHAPTER_EDIT_FAILED,
  CHAPTER_EDIT_SUCCESS,
  CHAPTER_GET_FAILED,
} from '../../../../../_domain/constanta'
import {ChapterFormInput} from '../../../../../helpers/UIChapterConstanta'
import {alertError, alertSuccess, errorHandling} from '../../../../../helpers/FuncHelpers'
import {AlertPromise} from '../../../../../helpers/classHelpers'
import StorageDataSourceImpl from '../../../../../_data/dataSource/StorageDataSourceImpl'
import {StorageRepositoryImpl} from '../../../../../_domain/repository/StorageRepositoryImpl'
import {StorageServiceImpl} from '../../../../../_domain/service/StorageService'
import {saveObjectRequest} from '../../../../../_data/dataSource/request/StorageRequest'
import {ChapterFileRequest} from '../../../../../_data/dataSource/request/ChapterRequest'
import {createChapterFileResponse} from '../../../../../_data/dataSource/response/ChapterResponse'

const {actions} = chapter.slice
// const controlAction = control.slice.actions
const callTypes = chapter.callTypes
const dataSource = new ChapterDataSourceImpl()
const repository = new ChapterRepositoryImpl(dataSource)
const service = new ChapterServiceImpl(repository)
const storageDataSource = new StorageDataSourceImpl()
const storageRepository = new StorageRepositoryImpl(storageDataSource)
const storageService = new StorageServiceImpl(storageRepository)

const alertPromise = new AlertPromise()
export const getAllChapterDatatable = (queryParams: BaseDatatableRequest) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.list}))

  return service
    .getDatatableChapter(queryParams)
    .then((response) => {
      const {totalCount, entities} = response
      dispatch(actions.saveList({totalCount, entities}))

      alertPromise.success()
      return response
    })
    .catch((err) => {
      if (err.response) {
        errorHandling(err.response, CHAPTER_GET_FAILED)
      } else {
        alertPromise.error()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const getOneChapter = (id?: string) => (dispatch: any) => {
  if (!id) {
    return dispatch(actions.saveObject({data: undefined}))
  }

  dispatch(actions.startCall({callType: callTypes.action}))
  return service
    .getDetailChapterById(id)
    .then((response) => {
      const chapter = response
      dispatch(actions.saveObject({data: chapter}))
      return chapter
    })
    .catch((err) => {
      if (err.response) {
        errorHandling(err.response, CHAPTER_GET_FAILED)
      } else {
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const createChapter = (req: ChapterFormInput) => async (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.action}))
  return service
    .createOneChapter(req)
    .then((response) => {
      const chapter = response
      dispatch(actions.createObject({data: chapter}))
      alertPromise.stop()
      alertSuccess(CHAPTER_CREATE_SUCCESS)
    })
    .catch((err) => {
      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, CHAPTER_CREATE_FAILED)
      } else {
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const updateChapter = (req: ChapterFormInput, id: string) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.action}))
  return service
    .updateOneChapter(req, id)
    .then((response) => {
      const chapter = response
      dispatch(actions.updateObject({data: chapter}))
      alertPromise.stop()
      alertSuccess(CHAPTER_EDIT_SUCCESS)
    })
    .catch((err) => {
      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, CHAPTER_EDIT_FAILED)
      } else {
        console.error(err)
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const deleteOneChapter = (id: string) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.action}))

  return service
    .deleteOneChapterByID(id)
    .then(() => {
      alertPromise.stop()
      alertSuccess(CHAPTER_DELETE_SUCCESS)
      dispatch(actions.delete({id}))
    })
    .catch((err) => {
      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, CHAPTER_DELETE_FAILED)
      } else {
        console.error(err)
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

// export const deleteBatchChapter = (ids) => (dispatch) => {
//   dispatch(controlAction.setLoading(true))
//   return chapterAPI
//     .deleteMany(ids)
//     .then(() => {
//       alertSuccess('Successful delete chapter')
//       dispatch(actions.deleteMany({ids}))
//       dispatch(controlAction.setLoading(false))
//     })
//     .catch((error) => {
//       error.clientMessage = "Can't delete chapter"
//       alertError(error.clientMessage)
//       dispatch(actions.catchError({error, callType: callTypes.action}))
//       dispatch(controlAction.setLoading(false))
//     })
// }

// export const deleteFileAction = (chapterId, fileId) => (dispatch) => {
//   dispatch(actions.startCall({callType: callTypes.action}))
//   return chapterAPI
//     .deleteFile(chapterId, fileId)
//     .then((response) => {
//       const chapter = response.data.data
//       alertSuccess('Successful delete chapter')
//       dispatch(actions.saveObject({data: chapter}))
//     })
//     .catch((error) => {
//       error.clientMessage = "Can't update chapter"
//       alertError(error.clientMessage)
//       dispatch(actions.catchError({error, callType: callTypes.action}))
//     })
// }

// export const deleteAnswerDocumentAction = (chapterId, answerDocumentId) => (dispatch) => {
//   dispatch(actions.startCall({callType: callTypes.action}))
//   return chapterAPI
//     .deleteAnswerDocument(chapterId, answerDocumentId)
//     .then((response) => {
//       const chapter = response.data.data
//       alertSuccess('Successful delete answer doc chapter')
//       dispatch(actions.saveObject({data: chapter}))
//     })
//     .catch((error) => {
//       error.clientMessage = "Can't update chapter"
//       alertError(error.clientMessage)
//       dispatch(actions.catchError({error, callType: callTypes.action}))
//     })
// }
