/* eslint-disable jsx-a11y/anchor-is-valid */
import { Outlet, Route, Routes } from 'react-router'
import { PageTitle } from '../../../../../_layout/core'
import { PostTestList } from './PostTestList'
import { deleteSectionContext, queryParamsContext } from './_outletContext'
import PostTestCreate from './PostTestCreate'
import PostTestEdit from './PostTestEdit'


const PostTestContent = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>{'PostTest'}</PageTitle>
      <Outlet context={{ queryParams: queryParamsContext(), deleteSection: deleteSectionContext() }} />
    </>
  )
}

const PostTest = () => {
  return (
    <>
      <Routes>
        <Route element={<PostTestContent />}>
          <Route index element={<PostTestList />} />
          <Route path='create' element={<PostTestCreate />} />
          <Route path='edit/:postTestId' element={<PostTestEdit />} />
        </Route>
      </Routes>
    </>
  )
}

export { PostTest }
