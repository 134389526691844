import {BaseDatatableRequest} from '../../_data/dataSource/request/BaseDatatableRequest'
import {
  TypeTryoutCreateRequest,
  TypeTryoutUpdateRequest,
  mappingInputFormToTypeCreateRequest,
  mappingInputFormToTypeUpdateRequest,
} from '../../_data/dataSource/request/TypeTryoutRequest'
import {BaseDatatableResponse} from '../../_data/dataSource/response/BaseDatatableResponse'
import {TypeTryoutFormInput} from '../../helpers/UITypeTryoutConstanta'
import {TypeTryout} from '../model/TypeTryout'
import {TypeTryoutRepository} from '../repository/TypeTryoutRepository'

export interface TypeTryoutService {
  getDatatableTypeTryout(req: BaseDatatableRequest): Promise<BaseDatatableResponse<TypeTryout>>
  getAllTypeTryout(): Promise<TypeTryout[]>
  getDetailTypeTryoutById(id: string): Promise<TypeTryout>
  deleteOneTypeTryoutByID(id: string): Promise<string>
  createOneTypeTryout(req: TypeTryoutFormInput): Promise<TypeTryout>
  updateOneTypeTryout(req: TypeTryoutFormInput, id: string): Promise<TypeTryout>
}

export class TypeTryoutServiceImpl implements TypeTryoutService {
  private repo: TypeTryoutRepository
  constructor(repo: TypeTryoutRepository) {
    this.repo = repo
  }

  async updateOneTypeTryout(req: TypeTryoutFormInput, id: string): Promise<TypeTryout> {
    const body = mappingInputFormToTypeUpdateRequest(req)
    const typeTryout = await this.repo.updateOne(body, id)
    return typeTryout
  }

  async createOneTypeTryout(req: TypeTryoutFormInput): Promise<TypeTryout> {
    const body = mappingInputFormToTypeCreateRequest(req)
    const typeTryout = await this.repo.createOne(body)
    return typeTryout
  }

  async deleteOneTypeTryoutByID(id: string): Promise<string> {
    const message = await this.repo.deleteOneById(id)
    return message
  }

  async getDatatableTypeTryout(
    req: BaseDatatableRequest
  ): Promise<BaseDatatableResponse<TypeTryout>> {
    const typeTryouts = await this.repo.getDatatable(req)
    return typeTryouts
  }

  async getDetailTypeTryoutById(id: string): Promise<TypeTryout> {
    const typeTryout = await this.repo.getOneById(id)
    return typeTryout
  }

  async getAllTypeTryout(): Promise<TypeTryout[]> {
    const typeTryouts = await this.repo.getAll()
    return typeTryouts
  }
}
