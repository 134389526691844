import {FacilityResponse} from '../../_data/dataSource/response/FacilityResponse'
import {facilityStatusSwitch} from '../../helpers/FuncHelpers'
import {FACILITY_STATUS} from '../constanta'

export type Facility = {
  id: string
  short_name: string
  name: string
  slug: string
  status: FACILITY_STATUS
  special_limitations?: any
}

export class FacilityBuilder {
  private SingleFacility!: Facility
  private FacilityList!: Facility[]
  constructor() {}

  SingleData(facilityResponse: FacilityResponse): FacilityBuilder {
    const facility = mapingFacility(facilityResponse)
    this.SingleFacility = facility
    return this
  }
  ListData(facilityResponse: FacilityResponse[]): FacilityBuilder {
    const facilities = mapingFacilities(facilityResponse)
    this.FacilityList = facilities
    return this
  }
  Result(): Facility {
    return this.SingleFacility
  }
  ResultList(): Facility[] {
    return this.FacilityList
  }
}

function mapingFacility(facilityResponse: FacilityResponse): Facility {
  const facility: Facility = {
    id: facilityResponse.id,
    short_name: facilityResponse.short_name,
    name: facilityResponse.name,
    slug: facilityResponse.slug,
    status: facilityStatusSwitch(facilityResponse.status),
    special_limitations: facilityResponse.special_limitations,
  }
  return facility
}

function mapingFacilities(facilitiesResponse: FacilityResponse[]): Facility[] {
  const facilities = facilitiesResponse.map((item) => {
    return mapingFacility(item)
  })
  return facilities
}
