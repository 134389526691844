import AuthGroupDataSource from '../../_data/dataSource/AuthGroupDataSoruce'
import {BaseDatatableRequest} from '../../_data/dataSource/request/BaseDatatableRequest'
import {
  AuthGroupCreateRequest,
  AuthGroupUpdateRequest,
} from '../../_data/dataSource/request/AuthGroupRequest'
import {BaseDatatableResponse} from '../../_data/dataSource/response/BaseDatatableResponse'
import {AuthGroup, AuthGroupBuilder} from '../model/AuthGroup'
import {AuthGroupRepository} from './AuthGroupRepository'

export class AuthGroupRepositoryImpl implements AuthGroupRepository {
  datasource: AuthGroupDataSource
  constructor(datasource: AuthGroupDataSource) {
    this.datasource = datasource
  }

  async updateOne(req: AuthGroupUpdateRequest, id?: string): Promise<AuthGroup> {
    const response = await this.datasource.update(req, id)
    const initBuilder = new AuthGroupBuilder()
    const authGroup = initBuilder.SingleData(response).Result()
    return authGroup
  }

  async createOne(req: AuthGroupCreateRequest): Promise<AuthGroup> {
    const response = await this.datasource.create(req)
    const initBuilder = new AuthGroupBuilder()
    const authGroup = initBuilder.SingleData(response).Result()
    return authGroup
  }

  deleteOneById(id: string): Promise<string> {
    return this.datasource.deleteOne(id)
  }

  async getDatatable(req: BaseDatatableRequest): Promise<BaseDatatableResponse<AuthGroup>> {
    const data = await this.datasource.getDatatable(req)
    const initBuilder = new AuthGroupBuilder()
    const entities = initBuilder.ListData(data.entities).ResultList()
    const totalCount = data.totalCount
    return {
      entities,
      totalCount,
      errorMessage: data.errorMessage,
    }
  }

  async getOneById(id: string): Promise<AuthGroup> {
    const resposne = await this.datasource.getOne(id)
    const initBuilder = new AuthGroupBuilder()
    const authGroup = initBuilder.SingleData(resposne).Result()
    return authGroup
  }

  async getAll(params?: any): Promise<AuthGroup[]> {
    const response = await this.datasource.getAll(params)
    const initBuilder = new AuthGroupBuilder()
    const authGroups = initBuilder.ListData(response).ResultList()
    return authGroups
  }
}
