import {PartnerFormInput} from '../../../helpers/UIPartnerConstanta'

export type PartnerCreateRequest = {
  name: string
  email: string
  phone_number: string
}

export type PartnerUpdateRequest = {
  name: string
  email: string
  phone_number: string
}

export function mappingInputFormToPartnerCreateRequest(
  req: PartnerFormInput
): PartnerCreateRequest {
  return {
    name: req.nameInput ?? '',
    email: req.emailInput ?? '',
    phone_number: req.phoneNumberInput ? '+62' + req.phoneNumberInput : '+628xxxxxxx',
  }
}

export function mappingInputFormToPartnerUpdateRequest(
  req: PartnerFormInput
): PartnerUpdateRequest {
  return {
    name: req.nameInput ?? '',
    email: req.emailInput ?? '',
    phone_number: req.phoneNumberInput ? '+62' + req.phoneNumberInput : '+628xxxxxxx',
  }
}
