/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Navigate, Outlet, Route, Routes} from 'react-router'
import {PageTitle} from '../../../_layout/core'
import {LiveClass} from './pages/LiveClass/LiveClass'
import {PostTest} from './pages/PostTest/PostTest'
import {useAuth} from '../../../_context/AuthContext'
import {checkAuthLinkMenu} from '../../../helpers/AuthHelpers'
import {SLUG_EDULIVE_SUB_MENU} from '../../../helpers/UIAuthGroupConstanta'
import {StudentClass} from './pages/StudentClass/StudentClass'
// import { PostTestQuestion } from './pages/PostTestQuestion/PostTestQuestion'
// import { SchedulePostTest } from './pages/SchedulePostTest/SchedulePostTest'

const EduLiveWrapperContent: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>{'Master Main Menu'}</PageTitle>
      <Outlet />
    </>
  )
}

const EduLiveWrapper: FC = () => {
  const {currentUser, authorization} = useAuth()

  return (
    <>
      <Routes>
        <Route element={<EduLiveWrapperContent />}>
          <Route index element={<EduLiveWrapperContent />} />
          {checkAuthLinkMenu(
            SLUG_EDULIVE_SUB_MENU.EDULIVE_CLASS_MENU,
            currentUser,
            authorization
          ) && <Route path='live-class/*' element={<LiveClass />} />}
          {checkAuthLinkMenu(
            SLUG_EDULIVE_SUB_MENU.EDULIVE_POST_TEST_MENU,
            currentUser,
            authorization
          ) && <Route path='post-test/*' element={<PostTest />} />}
          {checkAuthLinkMenu(
            SLUG_EDULIVE_SUB_MENU.EDULIVE_STUDENT_CLASS_MENU,
            currentUser,
            authorization
          ) && <Route path='student-class/*' element={<StudentClass />} />}
          {/* <Route path='student-class/*' element={<StudentClass />} /> */}
          {/* <Route path='post-test/*' element={<PostTestQuestion />} />
          <Route path='schedule-post-test/*' element={<SchedulePostTest />} /> */}
          <Route path='*' element={<Navigate to='/error/404' />} />
        </Route>
      </Routes>
    </>
  )
}

export {EduLiveWrapper}
