import ScheduleTryoutDataSource from '../../_data/dataSource/ScheduleTryoutDataSoruce'
import {BaseDatatableRequest} from '../../_data/dataSource/request/BaseDatatableRequest'
import {
  ScheduleTryoutCreateBatchRequest,
  ScheduleTryoutCreateRequest,
  ScheduleTryoutFilterRequest,
  ScheduleTryoutUpdateBatchRequest,
  ScheduleTryoutUpdateRequest,
} from '../../_data/dataSource/request/ScheduleTryoutRequest'
import {BaseDatatableResponse} from '../../_data/dataSource/response/BaseDatatableResponse'
import {Schedule, ScheduleBuilder} from '../model/Schedule'
import {ScheduleBatch, ScheduleBatchBuilder} from '../model/ScheduleBatch'
import {ScheduleTryoutRepository} from './ScheduleTryoutRepository'

export class ScheduleTryoutRepositoryImpl implements ScheduleTryoutRepository {
  datasource: ScheduleTryoutDataSource

  constructor(datasource: ScheduleTryoutDataSource) {
    this.datasource = datasource
  }

  async getAllFilterBatch(req: ScheduleTryoutFilterRequest): Promise<Schedule[]> {
    const response = await this.datasource.getAllFilterBatch(req)
    let tmp: Record<string, string> = {}
    const result = response.filter((item) => {
      const batch_id = item.batch_id
      if (batch_id) {
        if (!tmp[batch_id]) {
          tmp[batch_id] = batch_id
          return true
        }
      }
      return false
    })
    const initBuilder = new ScheduleBuilder()
    const data: Schedule[] = initBuilder.ListData(result).ResultList()
    return data
  }

  async getAllFilter(req: ScheduleTryoutFilterRequest): Promise<Schedule[]> {
    const response = await this.datasource.getAllFilter(req)
    const initBuilder = new ScheduleBuilder()
    const data: Schedule[] = initBuilder.ListData(response).ResultList()
    return data
  }

  async updateBatch(
    req: ScheduleTryoutUpdateBatchRequest,
    scheduleBatchId: string
  ): Promise<Schedule | undefined> {
    const response = await this.datasource.updateBatch(req, scheduleBatchId)
    return undefined
  }

  async getOneBatchById(scheduleBatchId: string | number): Promise<ScheduleBatch> {
    const response = await this.datasource.getOneBatch(scheduleBatchId)
    const initBuilder = new ScheduleBatchBuilder()
    const data: ScheduleBatch = initBuilder.SingleData(response).Result()
    return data
  }

  async getDatatableBatch(
    req: BaseDatatableRequest
  ): Promise<BaseDatatableResponse<ScheduleBatch>> {
    const response = await this.datasource.getDatatableBatch(req)
    const initBuilder = new ScheduleBatchBuilder()
    const entities: ScheduleBatch[] = initBuilder.ListData(response.entities).ResultList()
    const errorMessage = response.errorMessage
    const totalCount = response.totalCount

    return {
      entities,
      errorMessage,
      totalCount,
    }
  }

  async createBatch(req: ScheduleTryoutCreateBatchRequest): Promise<Schedule | undefined> {
    const response = await this.datasource.createBatch(req)
    return undefined
  }

  async getDatatable(req: BaseDatatableRequest): Promise<BaseDatatableResponse<Schedule>> {
    const response = await this.datasource.getDatatable(req)
    const initBuilder = new ScheduleBuilder()
    const entities: Schedule[] = initBuilder.ListData(response.entities).ResultList()
    const errorMessage = response.errorMessage
    const totalCount = response.totalCount

    return {
      entities,
      errorMessage,
      totalCount,
    }
  }

  getAll(): Promise<Schedule[]> {
    throw new Error('Method not implemented.')
  }

  async getOneById(id: string | number): Promise<Schedule> {
    const response = await this.datasource.getOne(id)
    const initBuilder = new ScheduleBuilder()
    const data: Schedule = initBuilder.SingleData(response).Result()
    return data
  }

  async deleteOneById(id: string | number): Promise<string> {
    const response = await this.datasource.deleteOne(id)
    return response
  }

  async deleteOneBatchById(scheduleBatchId: string | number): Promise<string> {
    const response = await this.datasource.deleteOneBatch(scheduleBatchId)
    return response
  }

  async createOne(req: ScheduleTryoutCreateRequest): Promise<Schedule> {
    const response = await this.datasource.create(req)
    const initBuilder = new ScheduleBuilder()
    const data: Schedule = initBuilder.SingleData(response).Result()
    return data
  }

  async updateOne(req: ScheduleTryoutUpdateRequest): Promise<Schedule> {
    const response = await this.datasource.update(req, undefined)
    const initBuilder = new ScheduleBuilder()
    const data: Schedule = initBuilder.SingleData(response).Result()
    return data
  }
}
