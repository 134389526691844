import {groupRole} from '../../../../../_redux_slice'
import {StudentRoleRepositoryImpl} from '../../../../../_domain/repository/StudentRoleRepositoryImpl'
import StudentRoleDataSourceImpl from '../../../../../_data/dataSource/StudentRoleDataSourceImpl'
import {StudentRoleServiceImpl} from '../../../../../_domain/service/StudentRoleService'
import {BaseDatatableRequest} from '../../../../../_data/dataSource/request/BaseDatatableRequest'
import {
  GROUP_ROLE_CREATE_FAILED,
  GROUP_ROLE_CREATE_SUCCESS,
  GROUP_ROLE_EDIT_FAILED,
  GROUP_ROLE_EDIT_SUCCESS,
  GROUP_ROLE_GET_FAILED,
} from '../../../../../_domain/constanta'
import {alertError, alertSuccess, errorHandling} from '../../../../../helpers/FuncHelpers'
import {AlertPromise} from '../../../../../helpers/classHelpers'
import {GroupRoleFormInput} from '../../../../../helpers/UIGroupRoleConstanta'

const {actions} = groupRole.slice
// const controlAction = control.slice.actions
const callTypes = groupRole.callTypes
const dataSource = new StudentRoleDataSourceImpl()
const repository = new StudentRoleRepositoryImpl(dataSource)
const service = new StudentRoleServiceImpl(repository)

const alertPromise = new AlertPromise()

export const getAllGroupRoleDatatable = (queryParams: BaseDatatableRequest) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.list}))

  return service
    .getDatatableGroupRole(queryParams)
    .then((response) => {
      const {totalCount, entities} = response
      dispatch(actions.saveList({totalCount, entities}))

      alertPromise.success()

      return response
    })
    .catch((err) => {
      if (err.response) {
        errorHandling(err.response, GROUP_ROLE_GET_FAILED)
      } else {
        alertPromise.error()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const createGroupRole = (req: GroupRoleFormInput) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.action}))
  return service
    .createGroupRole(req)
    .then((response) => {
      alertPromise.stop()
      const groupRole = response
      dispatch(actions.createObject({data: groupRole}))
      alertSuccess(GROUP_ROLE_CREATE_SUCCESS)
    })
    .catch((err) => {
      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, GROUP_ROLE_CREATE_FAILED)
      } else {
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const updateGroupRole = (req: GroupRoleFormInput, id: string) => (dispatch: any) => {
  alertPromise.start()
  dispatch(actions.startCall({callType: callTypes.action}))
  return service
    .updateGroupRole(id, req)
    .then((response) => {
      alertPromise.stop()
      const groupRole = response
      dispatch(actions.updateObject({data: groupRole}))
      alertSuccess(GROUP_ROLE_EDIT_SUCCESS)
    })
    .catch((err) => {
      console.log(err)

      alertPromise.stop()
      if (err.response) {
        errorHandling(err.response, GROUP_ROLE_EDIT_FAILED)
      } else {
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}

export const getOneGroupRole = (id: string | undefined) => (dispatch: any) => {
  if (!id) {
    return dispatch(actions.saveObject({data: undefined}))
  }

  dispatch(actions.startCall({callType: callTypes.action}))
  return service
    .getOneGroupRole(id)
    .then((response) => {
      const groupRole = response
      dispatch(actions.saveObject({data: groupRole}))
      return groupRole
    })
    .catch((err) => {
      if (err.response) {
        errorHandling(err.response, GROUP_ROLE_GET_FAILED)
      } else {
        alertError()
      }
      dispatch(actions.catchError({err, callType: callTypes.action}))
    })
}
