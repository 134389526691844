/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { PageTitle } from '../../../../../_layout/core'
import { getAllChapterDatatable } from './ChapterAction'
import { Suspense } from 'react'
import { useOutletContext } from 'react-router-dom'
import { Chapter } from '../../../../../_domain/model/Chapter'
import { BaseDatatableResponse } from '../../../../../_data/dataSource/response/BaseDatatableResponse'
import CustomLoading from '../../../../../_component/customLoading'
import ChapterDelete from './ChapterDelete'
import { CHAPTER_LIST_TITLE } from '../../../../../_domain/constanta'
import { useAuth } from '../../../../../_context/AuthContext'
import { checkAuthActionMenu } from '../../../../../helpers/AuthHelpers'
import { SLUG_MASTER_SUB_MENU } from '../../../../../helpers/UIAuthGroupConstanta'

const ChapterListTable = React.lazy(() => import('./ChapterListComponent/ChapterListTable'))

const checkReduxData = () => {
  const outletContext = useOutletContext<any>().queryParams
  const { queryParams } = outletContext

  const [chapterListData, setChapterListData] = useState<Chapter[] | null>(null)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch<any>(getAllChapterDatatable(queryParams)).then((response: BaseDatatableResponse<Chapter>) => {
      setChapterListData(response.entities)
    })
  }, [queryParams, getAllChapterDatatable])

  return chapterListData
  // detect redux first before fetch API END
}

const ChapterList = () => {
  let data = checkReduxData()
  const { currentUser, authorization } = useAuth()

  return checkAuthActionMenu(SLUG_MASTER_SUB_MENU.MASTER_CHAPTER_MENU, "view", currentUser, authorization) ? (
    <>
      <ChapterDelete />
      <PageTitle breadcrumbs={[]}>{CHAPTER_LIST_TITLE}</PageTitle>
      <Suspense fallback={<CustomLoading />}>
        {data ? <ChapterListTable /> : null}
      </Suspense>
    </>
  ) : null
}

export { ChapterList }
