import axios from 'axios'
import AuthGroupDataSource from './AuthGroupDataSoruce'
import {AuthGroupResponse} from './response/AuthGroupResponse'
import {ApiResponse} from './response/BaseResponse'
import {BaseDatatableRequest} from './request/BaseDatatableRequest'
import {BaseDatatableResponse} from './response/BaseDatatableResponse'
import {MessageResponse} from './response/MessageResponse'
import {AuthGroupCreateRequest, AuthGroupUpdateRequest} from './request/AuthGroupRequest'

export default class AuthGroupDataSourceImpl implements AuthGroupDataSource {
  private AUTH_GROUP_URL: string
  constructor() {
    const service_teacher_url = process.env.REACT_APP_TEACHER_SERVICE_URL || 'api/'
    this.AUTH_GROUP_URL = `${service_teacher_url}auth_groups`
  }

  async update(req: AuthGroupUpdateRequest, id: string): Promise<AuthGroupResponse> {
    const response = await axios.put<ApiResponse<AuthGroupResponse>>(
      `${this.AUTH_GROUP_URL}/${id}`,
      req
    )

    const data = response.data.data
    return data
  }

  async deleteOne(id: string): Promise<string> {
    const response = await axios.delete<MessageResponse>(`${this.AUTH_GROUP_URL}/${id}`)
    return response.data.message
  }

  async getDatatable(req: BaseDatatableRequest): Promise<BaseDatatableResponse<AuthGroupResponse>> {
    const response = await axios.post<ApiResponse<BaseDatatableResponse<AuthGroupResponse>>>(
      `${this.AUTH_GROUP_URL}/datatable`,
      req
    )

    const data = response.data.data

    return data
  }

  async getOne(id: string): Promise<AuthGroupResponse> {
    const response = await axios.get<ApiResponse<AuthGroupResponse>>(`${this.AUTH_GROUP_URL}/${id}`)
    const data = response.data.data
    return data
  }

  async getAll(params?: any): Promise<AuthGroupResponse[]> {
    let queryParams = {}
    if (params) {
      queryParams = {
        ...params,
      }
    }
    const response = await axios.get<ApiResponse<AuthGroupResponse[]>>(`${this.AUTH_GROUP_URL}`, {
      params: queryParams,
    })
    const data = response.data.data
    return data
  }

  async create(req: AuthGroupCreateRequest): Promise<AuthGroupResponse> {
    const response = await axios.post<ApiResponse<AuthGroupResponse>>(
      `${this.AUTH_GROUP_URL}/`,
      req
    )
    const data = response.data.data

    return data
  }
}
