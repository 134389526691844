/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {SidebarMenuItem} from './SidebarMenuItem'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {useAuth} from '../../../../_context/AuthContext'
import {checkAuthLinkMenu} from '../../../../helpers/AuthHelpers'
import {
  SLUG_EDULIVE_SUB_MENU,
  SLUG_MASTER_SUB_MENU,
  SLUG_MENU,
  SLUG_TRANSACTION_SUB_MENU,
  SLUG_TRYOUT_SUB_MENU,
} from '../../../../helpers/UIAuthGroupConstanta'

const SidebarMenuMain = () => {
  const {currentUser, authorization} = useAuth()

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='abstract-33'
        title={'Dashboard'}
        fontIcon='bi-app-indicator'
      />

      {checkAuthLinkMenu(SLUG_MENU.MASTER_MENU, currentUser, authorization) && (
        <SidebarMenuItemWithSub to='/master' title='Master' hasBullet={true}>
          {checkAuthLinkMenu(
            SLUG_MASTER_SUB_MENU.MASTER_SUBJECT_MENU,
            currentUser,
            authorization
          ) && (
            <SidebarMenuItem
              to='/master/subject'
              icon='book'
              title={'Subject'}
              fontIcon='bi-app-indicator'
            />
          )}
          {checkAuthLinkMenu(
            SLUG_MASTER_SUB_MENU.MASTER_SCHOOL_MENU,
            currentUser,
            authorization
          ) && (
            <SidebarMenuItem
              to='/master/school'
              icon='classmates'
              title={'School'}
              fontIcon='bi-app-indicator'
            />
          )}
          {checkAuthLinkMenu(
            SLUG_MASTER_SUB_MENU.MASTER_CHAPTER_MENU,
            currentUser,
            authorization
          ) && (
            <SidebarMenuItem
              to='/master/chapter'
              icon='book'
              title={'Chapter'}
              fontIcon='bi-app-indicator'
            />
          )}
          {checkAuthLinkMenu(
            SLUG_MASTER_SUB_MENU.MASTER_STUDENT_MENU,
            currentUser,
            authorization
          ) && (
            <SidebarMenuItem
              to='/master/student'
              icon='user'
              title={'Student'}
              fontIcon='bi-app-indicator'
            />
          )}
          {checkAuthLinkMenu(
            SLUG_MASTER_SUB_MENU.MASTER_TEACHER_MENU,
            currentUser,
            authorization
          ) && (
            <SidebarMenuItem
              to='/master/teacher'
              icon='teacher'
              title={'Teacher'}
              fontIcon='bi-app-indicator'
            />
          )}
          {checkAuthLinkMenu(
            SLUG_MASTER_SUB_MENU.MASTER_PARENT_MENU,
            currentUser,
            authorization
          ) && (
            <SidebarMenuItem
              to='/master/parents'
              icon='user-square'
              title={'Student Parent'}
              fontIcon='bi-app-indicator'
            />
          )}
          {checkAuthLinkMenu(
            SLUG_MASTER_SUB_MENU.MASTER_GROUP_ROLE_MENU,
            currentUser,
            authorization
          ) && (
            <SidebarMenuItem
              to='/master/group-role'
              icon='key'
              title={'Group Role'}
              fontIcon='bi-app-indicator'
            />
          )}
          {checkAuthLinkMenu(
            SLUG_MASTER_SUB_MENU.MASTER_PRODUCT_MENU,
            currentUser,
            authorization
          ) && (
            <SidebarMenuItem
              to='/master/product'
              icon='package'
              title={'Product'}
              fontIcon='bi-app-indicator'
            />
          )}
          {checkAuthLinkMenu(
            SLUG_MASTER_SUB_MENU.MASTER_FASILITY_MENU,
            currentUser,
            authorization
          ) && (
            <SidebarMenuItem
              to='/master/product_facility'
              icon='pointers'
              title={'Product Facility'}
              fontIcon='bi-app-indicator'
            />
          )}
          {checkAuthLinkMenu(
            SLUG_MASTER_SUB_MENU.MASTER_AUTH_GROUP_MENU,
            currentUser,
            authorization
          ) && (
            <SidebarMenuItem
              to='/master/auth-group'
              icon='lock'
              title={'Authorization Group'}
              fontIcon='bi-app-indicator'
            />
          )}
          {checkAuthLinkMenu(SLUG_MASTER_SUB_MENU.MASTER_TEAM_MENU, currentUser, authorization) && (
            <SidebarMenuItem
              to='/master/team'
              icon='category'
              title={'Team'}
              fontIcon='bi-app-indicator'
            />
          )}
          {checkAuthLinkMenu(
            SLUG_MASTER_SUB_MENU.MASTER_PARTNER_MENU,
            currentUser,
            authorization
          ) && (
            <SidebarMenuItem
              to='/master/partner'
              icon='courier'
              title={'Partner'}
              fontIcon='bi-app-indicator'
            />
          )}
          {checkAuthLinkMenu(
            SLUG_MASTER_SUB_MENU.MASTER_GROUP_CLASS_MENU,
            currentUser,
            authorization
          ) && (
            <SidebarMenuItem
              to='/master/group-class'
              icon='abstract-24'
              title={'Group Class'}
              fontIcon='bi-app-indicator'
            />
          )}
        </SidebarMenuItemWithSub>
      )}

      {checkAuthLinkMenu(SLUG_MENU.EDULIVE_MENU, currentUser, authorization) && (
        <SidebarMenuItemWithSub to='/edulive' title='EduLive' hasBullet={true}>
          {checkAuthLinkMenu(
            SLUG_EDULIVE_SUB_MENU.EDULIVE_CLASS_MENU,
            currentUser,
            authorization
          ) && (
            <SidebarMenuItem
              to='/edulive/live-class'
              icon='monitor-mobile'
              title={'Live Class'}
              fontIcon='bi-app-indicator'
            />
          )}
          {checkAuthLinkMenu(
            SLUG_EDULIVE_SUB_MENU.EDULIVE_POST_TEST_MENU,
            currentUser,
            authorization
          ) && (
            <SidebarMenuItem
              to='/edulive/post-test'
              icon='pencil'
              title={'Post Test'}
              fontIcon='bi-app-indicator'
            />
          )}
          {checkAuthLinkMenu(
            SLUG_EDULIVE_SUB_MENU.EDULIVE_STUDENT_CLASS_MENU,
            currentUser,
            authorization
          ) && (
            <SidebarMenuItem
              to='/edulive/student-class'
              icon='classmates'
              title={'Student Class'}
              fontIcon='bi-app-indicator'
            />
          )}
        </SidebarMenuItemWithSub>
      )}

      {checkAuthLinkMenu(SLUG_MENU.TRYOUT_MENU, currentUser, authorization) && (
        <SidebarMenuItemWithSub to='/tryout' title='Tryout' hasBullet={true}>
          {checkAuthLinkMenu(SLUG_TRYOUT_SUB_MENU.TRYOUT_TYPE_MENU, currentUser, authorization) && (
            <SidebarMenuItem
              to='/tryout/type'
              icon='bookmark-2'
              title={'Tryout Type'}
              fontIcon='bi-app-indicator'
            />
          )}
          {checkAuthLinkMenu(
            SLUG_TRYOUT_SUB_MENU.TRYOUT_SERIES_MENU,
            currentUser,
            authorization
          ) && (
            <SidebarMenuItem
              to='/tryout/series'
              icon='bookmark-2'
              title={'Tryout Series'}
              fontIcon='bi-app-indicator'
            />
          )}
          {checkAuthLinkMenu(
            SLUG_TRYOUT_SUB_MENU.TRYOUT_QUESTION_MENU,
            currentUser,
            authorization
          ) && (
            <SidebarMenuItem
              to='/tryout/question'
              icon='question-2'
              title={'Tryout Question'}
              fontIcon='bi-app-indicator'
            />
          )}
          {checkAuthLinkMenu(
            SLUG_TRYOUT_SUB_MENU.TRYOUT_SCHEDULE_MENU,
            currentUser,
            authorization
          ) && (
            <SidebarMenuItem
              to='/tryout/schedule'
              icon='calendar'
              title={'Tryout Schedule'}
              fontIcon='bi-app-indicator'
            />
          )}
          {checkAuthLinkMenu(
            SLUG_TRYOUT_SUB_MENU.TRYOUT_RESULT_MENU,
            currentUser,
            authorization
          ) && (
            <SidebarMenuItem
              to='/tryout/result'
              icon='document'
              title={'Tryout Result'}
              fontIcon='bi-app-indicator'
            />
          )}
        </SidebarMenuItemWithSub>
      )}

      {checkAuthLinkMenu(SLUG_MENU.TRANSACTION_MENU, currentUser, authorization) && (
        <SidebarMenuItemWithSub to='/transaction' title='Transaction' hasBullet={true}>
          {checkAuthLinkMenu(
            SLUG_TRANSACTION_SUB_MENU.TRANSACTION_ORDER_MENU,
            currentUser,
            authorization
          ) && (
            <SidebarMenuItem
              to='/transaction/order'
              icon='dollar'
              title={'Order'}
              fontIcon='bi-app-indicator'
            />
          )}
          {checkAuthLinkMenu(
            SLUG_TRANSACTION_SUB_MENU.TRANSACTION_BANNER_MENU,
            currentUser,
            authorization
          ) && (
            <SidebarMenuItem
              to='/transaction/banner'
              icon='dollar'
              title={'Banner'}
              fontIcon='bi-app-indicator'
            />
          )}
        </SidebarMenuItemWithSub>
      )}
    </>
  )
}

export {SidebarMenuMain}
