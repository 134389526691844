import React, { FC, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { StudentAccountUpdateFormInput } from '../../../../../../../helpers/UIStudentConstanta'
import { Form } from 'react-bootstrap'
import { msgValidationError } from '../../../../../../../helpers/FuncHelpers'

type props = {
  emailData: string,
  onSubmit: (req: any) => void
}
const StudentEmailForm: FC<props> = ({ emailData, onSubmit }) => {
  const [showEmailForm, setShowEmailForm] = useState(false)
  const { handleSubmit, control, formState: { errors } } = useForm<StudentAccountUpdateFormInput>({
    mode: "onChange",
  })
  return (
    <div className='d-flex flex-wrap align-items-center'>
      <div className={' ' + (showEmailForm && 'd-none')}>
        <div className='fs-6 fw-bolder mb-1'>Email Address</div>
        <div className='fw-bold text-gray-600'>{emailData}</div>
      </div>

      <div className={'flex-row-fluid ' + (!showEmailForm && 'd-none')}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          {/* Email Field */}
          <Controller
            name='emailInput'
            defaultValue={emailData}
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <div className='row mb-6'>
                <div className='col-lg-12 mb-4 mb-lg-0'>
                  <div className='fv-row mb-0'>
                    <label htmlFor='emailaddress' className='form-label fs-6 fw-bolder mb-3'>
                      Enter New Email Address
                    </label>
                    <input
                      {...field}
                      type='email'
                      className='form-control form-control-lg form-control-solid'
                      id='emailaddress'
                      placeholder='Email Address'
                    />
                    {errors.emailInput && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          {msgValidationError(errors.emailInput.type, 'Email Address')}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          />
          <div className='d-flex'>
            <button type='submit' className='btn btn-primary  me-2 px-6'>
              Update Email
            </button>
            <button
              type='button'
              onClick={() => {
                setShowEmailForm(false)
              }}
              className='btn btn-color-gray-400 btn-active-light-primary px-6'
            >
              Cancel
            </button>
          </div>
        </Form>
      </div>

      <div className={'ms-auto ' + (showEmailForm && 'd-none')}>
        <button
          onClick={() => {
            setShowEmailForm(true)
          }}
          className='btn btn-light btn-active-light-primary'
        >
          Change Email
        </button>
      </div>
    </div>
  )
}

export default StudentEmailForm
