import {QuestionTryoutResponse} from '../../_data/dataSource/response/QuestionTryoutResponse'
import {roleQuestionSwitch, typeMajorSwitch, typeQuestionSwitch} from '../../helpers/FuncHelpers'
import {ROLE_QUESTION, TYPE_QUESTION} from '../constanta'
import {Chapter} from './Chapter'
import {Series} from './Series'
import {TypeTryout} from './TypeTryout'

export type QuestionTryout = {
  id: string
  ref_id: string
  type_question: TYPE_QUESTION
  role: ROLE_QUESTION
  class_index?: number
  exam?: Series
  type_tryout?: TypeTryout
  number_question: number
  group_id: string
  question_text: string
  opsi_a?: string
  opsi_b?: string
  opsi_c?: string
  opsi_d?: string
  opsi_e?: string
  answer_key?: string
  answer_desc?: string
  answer_sentence_text?: string
  answer_sentence_key?: string[]
  answer_videos?: {
    url: string
  }[]
  sub_question?: {
    title?: string
    description?: string
    titleTrueFalse?: string[]
    questions?: Questions[]
  }
  chapter_detail?: Omit<Chapter, 'code' | 'type_chapter' | 'status' | 'files'>
}
type Questions = {
  question?: string
  answer_key?: boolean
}

export class QuestionTryoutBuilder {
  private SingleQuestionTryout!: QuestionTryout
  private QuestionTryoutList!: QuestionTryout[]
  constructor() {}

  SingleData(questionTryoutResponse: QuestionTryoutResponse): QuestionTryoutBuilder {
    const questionTryout = mapingQuestionTryout(questionTryoutResponse)
    this.SingleQuestionTryout = questionTryout
    return this
  }
  ListData(questionTryoutResponse: QuestionTryoutResponse[]): QuestionTryoutBuilder {
    const questionTryouts = mapingQuestionTryouts(questionTryoutResponse)
    this.QuestionTryoutList = questionTryouts
    return this
  }
  Result(): QuestionTryout {
    return this.SingleQuestionTryout
  }
  ResultList(): QuestionTryout[] {
    return this.QuestionTryoutList
  }
}

function mapingQuestionTryout(questionTryoutResponse: QuestionTryoutResponse): QuestionTryout {
  const questionTryout: QuestionTryout = {
    id: questionTryoutResponse._id,
    ref_id: questionTryoutResponse.ref_id,
    type_question: typeQuestionSwitch(questionTryoutResponse.type_question),
    role: roleQuestionSwitch(questionTryoutResponse.role),
    exam: {
      id: questionTryoutResponse.exam?._id ?? '',
      name: questionTryoutResponse.exam?.name ?? '',
    },
    type_tryout: {
      id: questionTryoutResponse.type_tryout?._id ?? '',
      name: questionTryoutResponse.type_tryout?.name ?? '',
    },
    number_question: questionTryoutResponse.nomor,
    group_id: questionTryoutResponse.group_id,
    question_text: questionTryoutResponse.question_text,
    opsi_a: questionTryoutResponse.opsi_a,
    opsi_b: questionTryoutResponse.opsi_b,
    opsi_c: questionTryoutResponse.opsi_c,
    opsi_d: questionTryoutResponse.opsi_d,
    opsi_e: questionTryoutResponse.opsi_e,
    answer_key: questionTryoutResponse.answer_key,
    answer_desc: questionTryoutResponse.answer_desc,
    answer_videos: questionTryoutResponse.answer_videos,
    answer_sentence_key: questionTryoutResponse.answer_sentence_key,
    answer_sentence_text: questionTryoutResponse.answer_sentence_text,
    chapter_detail: {
      id: questionTryoutResponse.detail_chapter._id,
      subject_detail: {
        id: questionTryoutResponse.detail_chapter.subject_id._id,
        name: questionTryoutResponse.detail_chapter.subject_id.name,
      },
      number_chapter: 0,
      name: questionTryoutResponse.detail_chapter.name,
      grade: questionTryoutResponse.detail_chapter.grade,
      major: typeMajorSwitch(questionTryoutResponse.detail_chapter.major),
      semester: questionTryoutResponse.detail_chapter.semester,
    },
    sub_question: {
      title: questionTryoutResponse.sub_question?.title,
      description: questionTryoutResponse.sub_question?.description,
      titleTrueFalse: [
        questionTryoutResponse.sub_question?.titleTrueFalse?.at(0) ?? '',
        questionTryoutResponse.sub_question?.titleTrueFalse?.at(1) ?? '',
      ],
      questions: questionTryoutResponse.sub_question?.questions,
    },
  }
  return questionTryout
}

function mapingQuestionTryouts(
  questionTryoutsResponse: QuestionTryoutResponse[]
): QuestionTryout[] {
  const questionTryouts = questionTryoutsResponse.map((item) => {
    return mapingQuestionTryout(item)
  })
  return questionTryouts
}
